import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { startDateTarification } from '@omedom/data';

@Component({
    selector: 'omedom-cgv',
    templateUrl: './cgv.component.html',
    styleUrls: ['./cgv.component.scss'],
})
export class CgvComponent implements OnInit {
    /**
     * @description Boolean to know if the cgv is displayed in a modal or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 25/07/2024
     * @type {boolean}
     * @memberof CgvComponent
     */
    @Input()
    public isModal: boolean = false;

    public displayNewPricing = new Date(startDateTarification).getTime() <= new Date().getTime();

    /**
     * @description Boolean to know if the modal is for the new cgv or not
     * @author Didier Pascarel
     * @type {boolean}
     * @memberof CgvComponent
     */
    @Input() public isNewCgvModal: boolean = false;

    /**
     * @description The current date to display in the cgv modal
     * @author Didier Pascarel
     * @type {string}
     * @memberof CgvComponent
     */
    @Input()
    public cgvCurrentDate!: string;

    protected cgvCurrentDateStr!: string;

    constructor(protected modalController: ModalController) {}

    ngOnInit() {
        this.cgvCurrentDateStr = this.cgvCurrentDate?.split('-').reverse().join('/');
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LevelEventEntity, levelEvents, UserEntity } from '@omedom/data';
import { UserService } from '@omedom/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'omedom-level-assignments',
    templateUrl: './level-assignments.component.html',
    styleUrls: ['./level-assignments.component.scss'],
})
export class LevelAssignmentsComponent implements OnInit, OnDestroy {
    /**
     * @description List of events of levels
     * @author Jérémie Lopez
     * @memberof LevelAssignmentsComponent
     */
    public levelEvents = levelEvents;

    /**
     * @description List of categories of events of levels
     * @author Jérémie Lopez
     * @memberof LevelAssignmentsComponent
     */
    public categories: string[] = this.levelEvents
        .map((event) => event.category)
        .filter((category, index, array) => array.indexOf(category) === index);

    /**
     * @description User of the app
     * @author Jérémie Lopez
     * @type {UserEntity}
     * @memberof LevelAssignmentsComponent
     */
    public user!: UserEntity;

    private subscriptions: Subscription[] = [];

    constructor(private userService: UserService, private modalController: ModalController) {}

    ngOnInit(): void {
        const user$ = this.userService.user$.subscribe((user) => {
            this.user = user;

            this.categories.sort(
                (a, b) =>
                    (this.isCategoryComplete(a) ? 1 : 0) - (this.isCategoryComplete(b) ? 1 : 0)
            );
        });

        this.subscriptions.push(user$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * @description Close the modal
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof LevelAssignmentsComponent
     */
    public async close(): Promise<void> {
        await this.modalController.dismiss();
    }

    /**
     * @description Get the icon of the category of events of levels
     * @author Jérémie Lopez
     * @param {string} category
     * @return {*}  {string}
     * @memberof LevelAssignmentsComponent
     */
    public getIcon(category: string): string {
        return this.levelEvents?.find((event) => event.category === category)?.icon || '';
    }

    /**
     * @description Get the events of the category of events of levels
     * @author Jérémie Lopez
     * @param {string} category
     * @return {*}  {LevelEventEntity[]}
     * @memberof LevelAssignmentsComponent
     */
    public getEvents(category: string): LevelEventEntity[] {
        return this.levelEvents.filter((event) => event.category === category);
    }

    /**
     * @description Check if the category event is complete for the user of the app
     * @author Jérémie Lopez
     * @param {string} category
     * @return {*}  {boolean}
     * @memberof LevelAssignmentsComponent
     */
    public isCategoryComplete(category: string): boolean {
        return this.getEvents(category).every((event) => this.isEventComplete(event));
    }

    /**
     * @description Check if the event is complete for the user of the app
     * @author Jérémie Lopez
     * @param {LevelEventEntity} event
     * @return {*}  {boolean}
     * @memberof LevelAssignmentsComponent
     */
    public isEventComplete(event: LevelEventEntity): boolean {
        return (this.user?.eventsDom?.findIndex((e) => e.name === event.name) ?? -1) !== -1;
    }

    /**
     * @description Toggle the collapse of the category of events of levels
     * @author Jérémie Lopez
     * @param {string} category
     * @memberof LevelAssignmentsComponent
     */
    public toggleCollapse(category: string) {
        const content = document.getElementById(category);
        const icon = document.getElementById(`${category}-icon`);
        content?.classList.toggle('collapsed');

        if (icon) {
            icon.classList.toggle('collapsed-icon');
        }
    }
}

import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { LoanEntity } from '@omedom/data';
import { OmedomChart } from '@omedom/utils';

/**
 * @description Layout of the loan synthesis container (default, mobile)
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 01/07/2024
 * @export
 * @enum {number}
 */
export enum LoanSynthesisLayout {
    DEFAULT = 'default',
    MOBILE = 'mobile',
}


@Component({
    selector: 'omedom-loan-synthesis',
    templateUrl: './loan-synthesis.container.html',
    styleUrls: ['./loan-synthesis.container.scss'],
})
export class LoanSynthesisContainer implements OnInit {
    /**
     * @description Loans of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/07/2024
     * @type {LoanEntity[]}
     * @memberof LoanSynthesisContainer
     */
    @Input({ required: true })
    public loans: LoanEntity[] = [];

    /**
     * @description Layout of the loan synthesis container
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/07/2024
     * @type {LoanSynthesisLayout}
     * @memberof LoanSynthesisContainer
     */
    @Input()
    public layout: LoanSynthesisLayout = LoanSynthesisLayout.DEFAULT;

    /**
     * @description Show the graph or not in the loan synthesis container (default: true) - if false, the graph is not displayed in the template
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 25/10/2024
     * @memberof LoanSynthesisContainer
     */
    @Input()
    public showGraph = true;

    /**
     * @description Title of the loan synthesis container (default: 'Synthèse des prêts') - if not defined, the title is not displayed in the template
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 25/10/2024
     * @type {string}
     * @memberof LoanSynthesisContainer
     */
    @Input()
    public title?: string;

    /**
     * @description Icon of the title of the loan synthesis container (default: 'icon-loan') - if not defined, the icon is not displayed in the template
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 25/10/2024
     * @type {string}
     * @memberof LoanSynthesisContainer
     */
    @Input()
    public titleIcon?: string;

    constructor(
        private readonly ref: ElementRef
    ) { }

    /**
     * @description Total remaining capital of the user loans
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/07/2024
     * @returns {number}
     * @memberof LoanSynthesisContainer
     */
    public get totalRemaining(): number {
        return this.loans.reduce((acc, loan) => acc + (loan.remainingCapital || 0), 0);
    }

    /**
     * @description Total repaid capital of the user loans
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/07/2024
     * @readonly
     * @type {number}
     * @memberof LoanSynthesisContainer
     */
    public get totalRepaid(): number {
        return this.loans.reduce((acc, loan) => acc + (loan.repaidCapital || 0), 0);
    }

    /**
     * @description Percentage of repaid capital of the user loans compared to the remaining capital of the user loans
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/07/2024
     * @readonly
     * @type {number}
     * @memberof LoanSynthesisContainer
     */
    public get percentageRepaid(): number {
        if (this.totalRepaid === 0) {
            return 0;
        }

        return Math.round((this.totalRepaid / this.totalRemaining) * 100);
    }

    ngOnInit(): void {
        // Add the layout class to ref
        this.ref.nativeElement.classList.add(`layout-${this.layout}`);
    }

    /**
     * @description Percentage of repaid capital of a loan compared to the remaining capital of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/07/2024
     * @param {LoanEntity} loan
     * @returns {number}
     * @memberof LoanSynthesisContainer
     */
    public getPercentage(loan: LoanEntity): number {
        // If the loan is not defined, we return 0
        if (!loan) {
            return 0;
        }

        // If repaid capital is 0 or undefined, we return 0
        if (!loan.repaidCapital || loan.repaidCapital === 0) {
            return 0;
        }

        return Math.round((loan.repaidCapital / this.totalRepaid) * 100);
    }

    /**
     * @description Get a color for a loan based on his name
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/07/2024
     * @param {LoanEntity} loan
     * @returns {string}
     * @memberof LoanSynthesisContainer
     */
    public getColor(loan: LoanEntity): string {
        // Get colors from OmedomChart
        const colors = OmedomChart.colors;

        // Get order in the loans list
        const order = this.loans.indexOf(loan);

        // Return the color based on the order
        return colors[order];
    }
}

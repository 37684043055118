import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'omedom-select-property-card',
    templateUrl: './select-property-card.component.html',
    styleUrls: ['./select-property-card.component.scss'],
})
export class SelectPropertyCardComponent {
    @Input() key?: string;
    @Input() icon?: string;
    @Input() title?: string;
    @Input() selected: boolean = false;

    get keyWithoutUpperCase(): string {
        if (!this.key) {
            return '';
        }
        const keyArray = this.key?.split(' ') || [];
        const firstWord = keyArray[0].toLowerCase();
        const otherWords = keyArray
            .slice(1)
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        const finalKey = [firstWord, ...otherWords].join('').replace('/', '');
        return finalKey;
    }
    @Output() clickEvent: EventEmitter<string> = new EventEmitter<string>();
}

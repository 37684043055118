<div class="omedom-modal">
    <h2>Modifier l'avatar du bien</h2>
    <button class="close-button" (click)="dismiss()">
        <i class="uil uil-times-circle"></i>
    </button>
    <div class="omedom-modal-content">
        <div class="avatar-button-container flex-1">
            @if (property?.photo) {
            <img
                class="margin-right-2"
                [src]="property?.photo"
                alt="image représentant la propriété"
            />
            } @else {
            <div class="avatar margin-right-2">
                <i class="uil uil-home ng-star-inserted"></i>
            </div>
            }
            <ng-template #avatar>
                <div class="avatar margin-right-2">
                    <i class="uil uil-home ng-star-inserted"></i>
                </div>
            </ng-template>
            <!-- <div class="button-container flex-1"> -->
            <!-- <button
      class="omedom-button-primary flex-1 margin-bottom-1"
      (click)="uploadAvatar()"
      >
      <i class="uil uil-import"></i>
      <span>Choisir un avatar</span>
    </button> -->
            <button
                class="omedom-button-primary flex-1"
                (click)="choosePicture()"
            >
                <i class="uil uil-image-download"></i>
                <span>Importer une photo</span>
            </button>
            <!-- </div> -->
        </div>
    </div>
    <button class="omedom-button-secondary w-100" (click)="dismiss()">
        Annuler
    </button>
</div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    BankTransactionEntity,
    BankTransactionRecurrenceBase,
    BankTransactionRecurrenceForm,
    BankTransactionRecurrenceResult,
    ChargeEntity,
    ChargePeriodicity,
    IncomeEntity,
    IncomePeriodicity,
} from '@omedom/data';
import { BankTransactionService, ChargeService, IncomeService } from '@omedom/services';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { elementAnimation } from '../../../../animations';

@Component({
    selector: 'omedom-bank-transaction-recurrence-step',
    templateUrl: './bank-transaction-recurrence-step.container.html',
    styleUrls: ['./bank-transaction-recurrence-step.container.scss'],
    animations: [elementAnimation],
})
export class BankTransactionRecurrenceStepContainer implements OnInit, OnDestroy {
    /**
     * @description The transaction to associate
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @type {BankTransactionEntity}
     * @memberof BankTransactionRecurrenceStepContainer
     */
    @Input({ required: true })
    public transaction?: BankTransactionEntity;

    /**
     * @description The treasury uid to associate with the transaction
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @type {string}
     * @memberof BankTransactionRecurrenceStepContainer
     */
    @Input()
    public treasuryUID?: string;

    /**
     * @description The periodicity of the base of the recurrence
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 16/05/2024
     * @type {(ChargePeriodicity | IncomePeriodicity)}
     * @memberof BankTransactionRecurrenceStepContainer
     */
    @Input()
    public periodicity?: ChargePeriodicity | IncomePeriodicity;

    /**
     * @description The treasury to associate with the transaction
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @type {Observable<ChargeEntity | IncomeEntity>}
     * @memberof BankTransactionRecurrenceStepContainer
     */
    public treasury$?: Observable<ChargeEntity | IncomeEntity>;

    /**
     * @description The base of the recurrence of the transaction to associate with the treasury items of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 16/05/2024
     * @type {BehaviorSubject<BankTransactionRecurrenceBase | null>}
     * @memberof BankTransactionRecurrenceStepContainer
     */
    public base$ = new BehaviorSubject<BankTransactionRecurrenceBase | null>(null);

    /**
     * @description The subscriptions of the component to unsubscribe when the component is destroyed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @private
     * @type {Subscription[]}
     * @memberof BankTransactionRecurrenceStepContainer
     */
    private subscriptions: Subscription[] = [];

    /**
     * @description The reccurences of the transaction
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @type {BankTransactionRecurrenceResult[]}
     * @memberof BankTransactionRecurrenceStepContainer
     */
    private reccurences: BankTransactionRecurrenceResult[] = [];

    /**
     * @description The transactions of the recurrence
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @type {Observable<BankTransactionEntity[]>}
     * @memberof BankTransactionRecurrenceStepContainer
     */
    public transactions$?: Observable<BankTransactionEntity[]>;

    /**
     * @description The form to associate the transactions recurrence with the treasury item
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @type {FormGroup<{
     *         transactionUIDs: FormControl<string[]>;
     *     }>}
     * @memberof BankTransactionRecurrenceStepContainer
     */
    @Input({ required: true })
    public form?: FormGroup<BankTransactionRecurrenceForm>;

    /**
     * @description State of the component (pending, ok, error) to display the right content to the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @memberof BankTransactionRecurrenceStepContainer
     */
    public state$ = new BehaviorSubject<string>('pending');

    constructor(
        private chargeService: ChargeService,
        private incomeService: IncomeService,
        private bankTransactionService: BankTransactionService
    ) {}

    ngOnInit(): void {
        this.getBase();
        this.searchForRecurrence();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * @description Get the base of the recurrence of the transaction to associate with the treasury items of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @private
     * @returns {void}
     * @memberof BankTransactionRecurrenceStepContainer
     */
    private getBase(): void {
        // Check if the treasuryUID is defined
        if (this.treasuryUID) {
            return this.getTreasury();
        }

        // Check if the periodicity is defined
        if (!this.periodicity) {
            return;
        }

        // Check if the transaction is defined
        if (!this.transaction) {
            return;
        }

        // Get the right base
        this.setBase({
            amount: this.transaction.amount,
            date: this.transaction.date,
            category: this.transaction.categoryID,
            periodicity: this.periodicity,
            description: this.transaction.cleanDescription,
        });
    }

    /**
     * @description Get the treasury associated with the transaction if the treasuryUID is defined
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @private
     * @returns {void}
     * @memberof BankTransactionRecurrenceStepContainer
     */
    private getTreasury(): void {
        // Check if the treasuryUID is defined
        if (!this.treasuryUID || !this.transaction) {
            return;
        }

        // Get the right service by checking the type of the transaction
        const service = this.transaction.amount < 0 ? this.chargeService : this.incomeService;

        // Get the treasury
        this.treasury$ = service._get(this.treasuryUID) as Observable<ChargeEntity | IncomeEntity>;

        this.subscriptions.push(
            this.treasury$.subscribe((treasury) => {
                if (!this.transaction) {
                    return;
                }
                this.setBase({
                    amount: this.transaction.amount,
                    date: this.transaction.date,
                    category: this.transaction.categoryID,
                    periodicity: treasury.periodicity,
                    description: this.transaction.cleanDescription,
                });
            })
        );
    }

    /**
     * @description Search for the recurrence of the transaction in the treasury items of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 16/05/2024
     * @private
     * @returns {void}
     * @memberof BankTransactionRecurrenceStepContainer
     */
    private searchForRecurrence(): void {
        // Init state
        this.state$.next('pending');

        this.transactions$ = this.base$.pipe(
            switchMap((base) => {
                // Check if base is not defined
                if (!base) {
                    return of([]);
                }

                if (!this.transaction) {
                    return of([]);
                }

                // Check if periodicity is not punctual
                if (base?.periodicity === 'punctual') {
                    return of([]);
                }

                return this.bankTransactionService._searchForRecurrence(this.transaction, base);
            }),
            switchMap((reccurences) => {
                this.reccurences = reccurences;

                // Get the transactions of the recurrence
                const array = this.reccurences.map((recurrence) =>
                    this.bankTransactionService._get(recurrence.transactionUID)
                );

                // Check if there is no recurrence
                if (array.length === 0) {
                    return of([]);
                }

                return combineLatest(array) as Observable<BankTransactionEntity[]>;
            }),
            tap((transactions) => {
                this.preSelectTransactions(transactions);

                // Set the state to ok if base is defined
                if (this.base$.getValue()) {
                    this.state$.next('ok');

                    if (transactions.length === 0) {
                        this.selected.setValue([]);
                    }
                }
            })
        );
    }

    /**
     * @description Set the base of the recurrence of the transaction to associate with the treasury items of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @private
     * @param {BankTransactionRecurrenceBase} base
     * @returns {void}
     * @memberof BankTransactionRecurrenceStepContainer
     */
    private setBase(base: BankTransactionRecurrenceBase): void {
        // Check if the base is not the same
        const currentBase = this.base$.getValue();

        const isSameAmount = base.amount === currentBase?.amount;
        const isSameDate = base.date === currentBase?.date;
        const isSameCategory = base.category === currentBase?.category;
        const isSamePeriodicity = base.periodicity === currentBase?.periodicity;
        const isSameDescription = base.description === currentBase?.description;

        if (
            isSameAmount &&
            isSameDate &&
            isSameCategory &&
            isSamePeriodicity &&
            isSameDescription
        ) {
            return;
        }

        this.base$.next(base);
    }

    /**
     * @description Preselect the transactions that have a score of 95% or more to associate with the transaction to associate with the treasury items
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @private
     * @param {BankTransactionEntity[]} transactions
     * @memberof BankTransactionRecurrenceStepContainer
     */
    private preSelectTransactions(transactions: BankTransactionEntity[]): void {
        // Check if there is already selected transactions
        if (this.selected.value.length !== 0) {
            return;
        }

        const uids = transactions
            .filter((transaction) => {
                // Get recurrence of the transaction
                const recurrence = this.reccurences.find(
                    (recurrence) => recurrence.transactionUID === transaction.uid
                );

                // Check if recurrence is defined
                if (!recurrence) {
                    return false;
                }

                // Check if this recurrence is the only one on this day
                const sameDayRecurrences = this.reccurences.filter((recurrence) => {
                    return (
                        recurrence.details.date.transaction.getTime() ===
                            transaction.date.getTime() &&
                        recurrence.transactionUID !== transaction.uid
                    );
                });

                return recurrence.score >= 95 && sameDayRecurrences.length === 0;
            })
            .map((transaction) => transaction.uid);

        // Set the selected transactions if there isn't any selected
        this.selected.setValue(uids);
    }

    /**
     * @description Get the selected transactions to associate with the transaction to associate with the treasury items of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @readonly
     * @private
     * @type {FormControl<string[]>}
     * @memberof BankTransactionRecurrenceStepContainer
     */
    private get selected(): FormControl<string[]> {
        return this.form?.get('transactionUIDs') as FormControl<string[]>;
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { AssetTypes, Sharing } from '@omedom/data';

@Component({
    selector: 'omedom-family-share-card',
    templateUrl: './family-share-card.component.html',
    styleUrls: ['./family-share-card.component.scss'],
})
export class FamilyShareCardComponent {
    @Input() share?: Sharing;

    public societyType = AssetTypes.society;

    @Input() public editable = true;

    @Input() public deletable = true;

    @Input() public isShared = false;

    @Output() public onRemove = new EventEmitter<Sharing>();

    async openDeleteModal(modal: IonModal): Promise<void> {
        await modal.present();
    }

    async delete(modal: IonModal): Promise<void> {
        this.onRemove.emit(this.share);
        await modal.dismiss();
    }

    edit() {}
}

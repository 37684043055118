<div class="omedom-page-container">
    @if (modalLabel) {
    <h2>{{ modalLabel }}</h2>
    }
    <div
        class="omedom-page-container-content flex-1 margin-top-1 padding-bottom-1"
    >
        @if (emptyTreasury) {
        <omedom-info
            @element
            iconStart="uil uil-euro-circle"
            information="Vous n'avez pas encore enregistré de revenus pour ce mois."
            class="w-100"
        ></omedom-info>
        } @for (incomeByDay of treasuryByDay; track $index) {
        <div [@list]="treasuryByDay.length" class="w-100">
            <p class="margin-0 margin-bottom-2">
                {{ incomeByDay.day | date : 'dd/MM/YYYY' }}
            </p>
            <div
                class="incomes-container padding-left-1 margin-bottom-2 w-100"
                [@transaction]="incomeByDay.treasury.length"
            >
                @for (income of incomeByDay.treasury; track $index) {
                <omedom-treasury-card
                    [treasury]="income"
                    [avatar]="avatar"
                    [currentUserUID]="user?.uid"
                    [image]="income.propertyImg"
                    [name]="income.propertyName"
                    [icon]="getIncomeInfo(income.propertyUID).icon"
                    [isCharge]="false"
                    (treasuryClicked)="incomeClicked(income)"
                ></omedom-treasury-card>
                }
            </div>
        </div>
        }

        <div class="omedom-sticky-button-container w-100 flex-1 flex-end">
            @if (currentDate) {
            <omedom-date
                class="flex-1"
                [type]="omedomDateType.month"
                [date]="currentDate"
                (dateChange)="dateChange($event)"
            ></omedom-date>
            } @if (roleRight.create) {
            <button
                class="omedom-icon-button-primary margin-left-2"
                (click)="addClicked()"
            >
                <i class="uil uil-plus"></i>
            </button>
            }
        </div>
    </div>
    <ng-template #defaultAvatar>
        <div class="header-icon margin-right-1">
            <i class="uil uil-home"></i>
        </div>
    </ng-template>
</div>

import { Component, Input } from '@angular/core';
import { Color } from '@omedom/data';

@Component({
    selector: 'omedom-bank-info',
    templateUrl: './bank-info.component.html',
    styleUrls: ['./bank-info.component.scss'],
})
export class BankInfoComponent {
    /**
     * @description Icon from IconScout (https://iconscout.com/)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/04/2024
     * @type {string}
     * @memberof BankInfoComponent
     */
    @Input()
    public icon: string = 'info';

    /**
     * @description Color of the icon
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/04/2024
     * @type {Color}
     * @memberof BankInfoComponent
     */
    @Input()
    public color: Color = Color.blue;

    /**
     * @description Title of the info
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/04/2024
     * @type {string}
     * @memberof BankInfoComponent
     */
    @Input()
    public title: string = `Titre de l'info`;

    /**
     * @description Content of the info
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/04/2024
     * @type {string}
     * @memberof BankInfoComponent
     */
    @Input()
    public content: string = `Contenu de l'info`;
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { ShareConditionsComponent } from '../share-conditions/share-conditions.component';
import { ClientEntity, ProEntity, UserEntity } from '@omedom/data';
import { ClientService, UserService } from '@omedom/services';
import { Subscription, filter } from 'rxjs';
import { showLoading } from '../../functions';

@Component({
    selector: 'omedom-accept-share-condition',
    templateUrl: './accept-share-condition.component.html',
    styleUrls: ['./accept-share-condition.component.scss'],
})
export class AcceptShareConditionComponent implements OnInit, OnDestroy {
    @Input({ required: true }) pro!: ProEntity;

    @Input({ required: true }) client!: ClientEntity;

    private loadingModal?: HTMLIonLoadingElement;

    user?: UserEntity;

    subscriptions: Subscription[] = [];

    get hasAgreedToShareToPro(): boolean {
        return this.client?.hasAgreedToShareToPro ?? false;
    }

    set hasAgreedToShareToPro(value: boolean) {
        if (this.client) {
            this.client.hasAgreedToShareToPro = value;
        }
        this.toggleShareConditions();
    }

    constructor(
        private modalController: ModalController,
        private userService: UserService,
        private loadingController: LoadingController,
        private clientService: ClientService
    ) {}

    ngOnInit() {
        const user$ = this.userService.user$.subscribe((user) => {
            if (!user) {
                return;
            }
            this.user = user;
        });

        this.subscriptions.push(user$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    async toggleShareConditions() {
        if (!this.hasAgreedToShareToPro && this.client) {
            await this.updateClientShareCondition(this.client);
            return;
        }

        const modal = await this.modalController.create({
            component: ShareConditionsComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                modalController: this.modalController,
                isModal: true,
            },
        });

        modal.present();

        modal.onDidDismiss().then(async (data) => {
            if (!this.client) {
                await this.createClient();
                return;
            }
            await this.updateClientShareCondition(this.client);
        });
    }

    private async createClient() {
        if (!this.user || !this.pro) {
            return;
        }
        this.loadingModal = await showLoading(
            'Création de votre connexion avec le pro...',
            this.loadingController
        );
        await this.clientService.connectUserToPro(this.user.uid, this.pro.uid, true);
        this.loadingModal.dismiss();
    }

    private async updateClientShareCondition(client: ClientEntity) {
        if (!client) {
            return;
        }

        const clientValuesToUpdate = {
            uid: client.uid,
            userUID: client.userUID,
            hasAgreedToShareToPro: client.hasAgreedToShareToPro || false,
        };
        try {
            await this.clientService.update(clientValuesToUpdate);
        } catch (error) {
            console.error(error);
        }
    }

    async showMention() {
        const modal = await this.modalController.create({
            component: ShareConditionsComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                modalController: this.modalController,
                isModal: true,
            },
        });

        modal.present();
    }
}

/**
 * @description Status of the refresh of a bank item in Bridge
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 04/03/2024
 * @export
 * @enum {number}
 */
export enum BankItemRefreshStatusCode {
    /**
     * @description The item is connecting to the bank.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/03/2024
     */
    Authenticating = "authenticating",

    /**
     * @description refreshed_accounts_count and total_accounts_count will indicate the progression of the refresh.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/03/2024
     */
    RetrievingData = "retrieving_data",

    /**
     * @description Connector requires a One Time Password (OTP) or an app to app validation.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/03/2024
     */
    InfoRequired = "info_required",

    /**
     * @description Refresh process is done. All data are up to date and available.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/03/2024
     */
    Finished = "finished",

    /**
     * @description Provided credentials are invalid. Corresponding's item status is 402.
     * @see BankStatusCode for more details on the 402 item status code.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/03/2024
     */
    InvalidCredentials = "invalid_creds",

    /**
     * @description An error occurred while refreshing the item. Check its status (429, 1003, ...).
     * @see BankStatusCode for more details on the different status codes.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/03/2024
     */
    FinishedError = "finished_error",
}

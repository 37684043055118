/**
 * @description Data to calculate the recurrence of a transaction for a treasury
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 18/03/2024
 * @interface BankTransactionRecurrenceDetail
 * @template T
 */
export interface BankTransactionRecurrenceDetail<T = number | string | Date> {
    /**
     * @description The treasury data to compare with the transaction to calculate the recurrence
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 18/03/2024
     * @type {T}
     * @memberof BankTransactionRecurrenceDetail
     */
    treasury: T;

    /**
     * @description The transaction data to compare with the treasury to calculate the recurrence
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 18/03/2024
     * @type {T}
     * @memberof BankTransactionRecurrenceDetail
     */
    transaction: T;

    /**
     * @description The proximity of the transaction and the treasury data, the higher the proximity, the more likely the transaction is recurrent.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 18/03/2024
     * @type {number}
     * @memberof BankTransactionRecurrenceDetail
     */
    proximity: number;
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DocumentEntity, SocietyEntity } from '@omedom/data';
import { AnalyticsService, DocumentService } from '@omedom/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'omedom-document-single-society',
    templateUrl: './document-single-society.component.html',
    styleUrls: ['./document-single-society.component.scss'],
})
export class DocumentSingleSocietyComponent implements OnInit, OnDestroy {
    @Input()
    public society: SocietyEntity = {} as SocietyEntity;

    @Input()
    public editable = true;

    /**
     * @description Emit event to open document form page with society uid
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 12/06/2024
     * @memberof DocumentSingleSocietyComponent
     */
    @Output() addDocumentClicked = new EventEmitter<string>();

    /**
     * @description List of documents of the society
     * @author Jérémie Lopez
     * @type {DocumentEntity[]}
     * @memberof DocumentSingleSocietyComponent
     */
    public documents: DocumentEntity[] = [];

    /**
     * @description If true, the view has been init a first time
     * @author Jérémie Lopez
     * @private
     * @memberof DocumentSingleSocietyComponent
     */
    private firstViewInit = false;

    private subcriptions: Subscription[] = [];

    constructor(
        private documentService: DocumentService,
        private platform: Platform,
        private analyticsService: AnalyticsService
    ) { }

    ngOnInit(): void {
        this.searchEntities();
        this.firstViewInit = true;
    }

    ngOnDestroy(): void {
        this.subcriptions.forEach((sub) => sub.unsubscribe());
    }
    /**
     * @description If true, the app is in desktop mode
     * @author Jérémie Lopez
     * @readonly
     * @type {boolean}
     * @memberof DocumentSingleSocietyComponent
     */
    public get isDesktop(): boolean {
        return this.platform.is('desktop');
    }

    /**
     * @description Ionic Hook Life cycle when the page will be display an other time
     * @author Jérémie Lopez
     * @memberof DocumentSingleSocietyComponent
     */
    ionViewWillEnter() {
        if (this.firstViewInit) {
            this.searchEntities();
        }
    }

    ionViewDidEnter(): void {
        this.analyticsService.setCurrentScreen('Document single society tab');
    }

    /**
     * @description Search user's societies by owned and shared societies
     * @author Jérémie Lopez
     * @return {*}  {void}
     * @memberof DocumentSingleSocietyComponent
     */
    _searchDocuments(): void {
        const _docs = this.documentService._getSocietyDocuments(this.society.uid);
        const documents$ = _docs.subscribe((societyDocuments) => {
            this.documents = [];
            // TODO : Change to get directly documents of the society
            societyDocuments.forEach((document: DocumentEntity) => {
                this.documents.push(document);
            });
        });

        this.subcriptions.push(documents$);
    }

    /**
     * @description Search entities
     * @author Jérémie Lopez
     * @param {*} [fromRefresher]
     * @return {*}  {Promise<void>}
     * @memberof DocumentSingleSocietyComponent
     */
    searchEntities(): void {
        this._searchDocuments();
    }

    /**
     * @description Emit event to open document form page with society uid
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 12/06/2024
     * @returns {*}  {void}
     * @memberof DocumentSingleSocietyComponent
     */
    public goToDocumentForm(): void {
        if (this.society?.uid) {
            this.addDocumentClicked.emit(this.society.uid);
        }
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'omedom-questions',
    templateUrl: './questions.component.html',
    styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent {
    @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() qa: any;
    @Input() selected: boolean = false;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyType, SelectOption } from '@omedom/data';
import { SelectBottomSheetComponent } from 'libs/ui/src/lib/components';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'omedom-registration-first-step',
    templateUrl: './registration-first-step.component.html',
    styleUrls: ['./registration-first-step.component.scss'],
})
export class RegistrationFirstStepComponent {
    @Output() nextEnabled = new EventEmitter<boolean>();
    @Output() selectPropertyType = new EventEmitter<PropertyType>();

    @Input() selectedProperty?: string;

    protected propertyTypes = PropertyType;

    typeBienOptions: SelectOption[] = [
        {
            id: PropertyType.batimentAgricole,
            label: PropertyType.batimentAgricole,
        },
        {
            id: PropertyType.bureaux,
            label: PropertyType.bureaux,
        },
        {
            id: PropertyType.chalet,
            label: PropertyType.chalet,
        },
        {
            id: PropertyType.hotel,
            label: PropertyType.hotel,
        },
        {
            id: PropertyType.other,
            label: PropertyType.other,
        },
    ];

    constructor(private modalController: ModalController) {}

    selectProperty($event: string) {
        if (this.selectedProperty === $event) {
            this.selectedProperty = undefined;
            this.nextEnabled.emit(false);
            return;
        }
        this.selectedProperty = $event;
        this.selectPropertyType.emit($event as PropertyType);
        this.nextEnabled.emit(true);
    }

    isSelected(property: string): boolean {
        if (
            property == PropertyType.other &&
            this.typeBienOptions.find((typeBien) => typeBien.id === this.selectedProperty)
        ) {
            return true;
        }
        return this.selectedProperty === property;
    }

    async clickMore($event: string) {
        const modal = await this.modalController.create({
            component: SelectBottomSheetComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            componentProps: {
                options: this.typeBienOptions,
                selectTitle: 'Type de bien',
                isMultiple: false,
            },
        });

        modal.onDidDismiss().then((x) => {
            if (x.data) {
                this.selectProperty(x.data[0].id);
            }
        });

        await modal.present();
    }

    protected readonly PropertyType = PropertyType;
}

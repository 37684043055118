@for (option of options; track option.label) {
<button
    [disabled]="disabled"
    (click)="toggleValue(option.value)"
    [ngClass]="{
        'omedom-button-primary': value === option.value,
        'omedom-button-secondary': value !== option.value
    }"
>
    @if (option.icon && (value === option.value)) {
    <i class="uil uil-{{ option.icon }}"></i>
    }
    {{ option.label }}
</button>
}

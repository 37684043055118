<div class="omedom-modal">
  <div class="omedom-modal-header">
    <div class="omedom-modal-header-title">
      <i
        class="uil uil-link-broken color-light-black background-color-light-orange"
      ></i>
      <h3>Dissocier ce mouvement</h3>
    </div>
    <i class="uil uil-times-circle" (click)="dismiss()"></i>
  </div>
  <div class="omedom-modal-content">
    <p @element>Êtes-vous sûr.e de vouloir disscoier ce mouvement ?</p>
    @if (transaction) {
      <omedom-timeline [date]="transaction.date">
        <omedom-bank-transaction
          @element
          [transaction]="transaction"
          [showActions]="false"
        ></omedom-bank-transaction>
      </omedom-timeline>
    }

    @if (treasury) {
      <p @element>
        {{ transaction && transaction.amount > 0 ? 'Revenu associé' :
        'Charge associée' }} :
      </p>
      <omedom-treasury-card2
        @element
        [treasury]="treasury"
      ></omedom-treasury-card2>
      <!-- <div class="treasury"
      @element>
      <div class="treasury-icon">
        <i class="uil uil-{{ getCategoryInfo(treasury).icon }}"></i>
      </div>
      <div class="treasury-content">
        <div class="treasury-name">
          <strong class="treasury-title">{{ getCategoryInfo(treasury).label }}</strong>
          <span class="treasury-periodicity">{{ getPeriodicityInfo(treasury).label }}</span>
          <span class="treasury-notes"
          *ngIf="treasury.notes">{{ treasury.notes }}</span>
        </div>
        <div class="treasury-amount">{{ treasury.amount | omedomNumber }} €</div>
      </div>
    </div> -->
  } @else {
    <p @element class="not-found">{{ notFoundMessage$ | async }}</p>
  }
</div>
<div class="omedom-modal-buttons">
  <button
    class="omedom-button-secondary"
    [disabled]="(state$ | async) === 'pending'"
    (click)="dismiss()"
    >
    Annuler
  </button>
  <button
    class="omedom-button-warn"
    [disabled]="(state$ | async) === 'pending'"
    (click)="delete()"
    >
    <i class="uil uil-trash-alt"></i>
    {{ message$ | async }}
    @if ((state$ | async) === 'pending') {
      <ion-spinner></ion-spinner>
    }
  </button>
</div>
</div>


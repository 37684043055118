import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ChartDoughnutData } from '@omedom/data';
import { OmedomChart, OmedomDoughnutChart, OmedomRandom } from '@omedom/utils';
import { BaseChartDirective } from 'ng2-charts';

import { OmedomChartLegend } from '../chart-legend';

@Component({
    selector: 'omedom-circular-graph',
    templateUrl: './circular-graph.component.html',
    styleUrls: ['./circular-graph.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class CircularGraphComponent implements OnInit, OnChanges {
    /**
     * @description Label of the graph (optional) (default: undefined)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {string}
     * @memberof CircularGraphComponent
     */
    @Input()
    public label?: string;

    /**
     * @description Position of the label of the graph (optional) (default: 'top')
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @type {('top' | 'right')}
     * @memberof CircularGraphComponent
     */
    @Input()
    public labelPosition: 'top' | 'right' = 'top';

    /**
     * @description Icon in the label of the graph (optional) (default: undefined)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {string}
     * @memberof CircularGraphComponent
     */
    @Input()
    public icon?: string;

    /**
     * @description Data to display in the graph (required) (default: [])
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {ChartDoughnutData[]}
     * @memberof CircularGraphComponent
     */
    @Input()
    public data: ChartDoughnutData[] = [];

    /**
     * @description Show legend of the graph(optional) (default: true)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @memberof CircularGraphComponent
     */
    @Input()
    public showLegend = true;

    /**
     * @description Show total of the graph (optional) (default: true)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @memberof CircularGraphComponent
     */
    @Input()
    public showTotal = true;

    /**
     * @description Gap between charts (optional) (default: 40)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @memberof CircularGraphComponent
     */
    @Input()
    public gap = 40;

    /**
     * @description Get charts from the view to update them when data change
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {QueryList<BaseChartDirective>}
     * @memberof CircularGraphComponent
     */
    @ViewChildren(BaseChartDirective)
    public charts?: QueryList<BaseChartDirective>;

    @ViewChild('doughnutChart')
    public doughnutChart?: ElementRef<HTMLCanvasElement>;

    /**
     * @description Chart to update when data change (optional) (default: undefined)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {BaseChartDirective}
     * @memberof CircularGraphComponent
     */
    public chart?: BaseChartDirective;

    /**
     * @description Data to display in the graph
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @memberof CircularGraphComponent
     */
    public doughnutChartData = { ...OmedomDoughnutChart.doughnutChartData };

    /**
     * @description Options of the graph (colors, spacing, etc.)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @memberof CircularGraphComponent
     */
    public doughnutChartOptions = { ...OmedomDoughnutChart.doughnutChartOptions };

    /**
     * @description Id of the graph to get it from the view to update it when data change
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @memberof CircularGraphComponent
     */
    public id = `circular-graph-${Math.floor(OmedomRandom.getRandomNumber() * 1000)}`;

    /**
     * @description Legends to display under the graph (optional) (default: [])
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {OmedomChartLegend[]}
     * @memberof CircularGraphComponent
     */
    public legends: OmedomChartLegend[] = [];

    /**
     * @description Total data of the graph
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {number}
     * @memberof CircularGraphComponent
     */
    public total: number = 0;

    constructor(private elementRef: ElementRef) {}

    ngOnInit(): void {
        this.updateChart();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data']) {
            this.updateChart();
        }

        if (changes['gap']) {
            this.updateGap();
        }
    }

    /**
     * @description Update chart when data change (labels, data, legends, etc.) (private) (default: void)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @private
     * @returns {*}  {void}
     * @memberof CircularGraphComponent
     */
    private updateChart(retry?: number): void {
        // Check if data is empty
        if (!this.data.length) {
            this.resetChart();
            return;
        }

        // Remove space between charts if there is only one
        if (this.data.length === 1) {
            this.doughnutChartOptions = {
                ...OmedomDoughnutChart.doughnutChartOptions,
                spacing: 0,
                elements: { arc: { borderWidth: 0, borderRadius: 0 } },
            };
        } else {
            this.doughnutChartOptions = {
                ...OmedomDoughnutChart.doughnutChartOptions,
                spacing: OmedomDoughnutChart.doughnutChartOptions.spacing,
                elements: OmedomDoughnutChart.doughnutChartOptions.elements,
            };
        }

        // Get chart
        this.chart = this.charts?.find((c) => c.chart?.canvas.id === this.id);

        // Check if chart is defined
        if (!this.chart) {
            // Check if retry is defined
            if (retry ?? 0 >= 3) {
                // Stop retrying
                return;
            } else {
                // Retry to get chart
                setTimeout(() => this.updateChart(retry ? retry + 1 : 1), 500);
                return;
            }
        }

        // Set total
        this.total = this.data.reduce((acc, curr) => acc + curr.value, 0);

        // Set labels and data
        this.doughnutChartData.labels = this.data.map((d) => d.label);
        this.doughnutChartData.datasets = [
            {
                data: this.data.map((d) => {
                    if (this.total) {
                        return Math.round((d.value * 100) / this.total);
                    } else {
                        return d.value;
                    }
                }),
                backgroundColor: this.data.map((d) => {
                    if (d.hatch) {
                        return OmedomChart.hatchColor(d.color);
                    } else {
                        return d.color;
                    }
                }),
                hoverBackgroundColor: this.data.map((d) => {
                    if (d.hatch) {
                        return OmedomChart.hatchColor(d.color);
                    } else {
                        return d.color;
                    }
                }),
            },
        ];

        // Set legends
        this.legends = this.data.map((d) => {
            return new OmedomChartLegend({
                label: d.label,
                amount: d.value,
                color: d.hatch ? (OmedomChart.hatchColor(d.color, 'legend') as string) : d.color,
            });
        });

        // Update chart
        this.chart.update();
    }

    /**
     * @description Reset chart when data is empty
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @private
     * @memberof CircularGraphComponent
     */
    private resetChart(): void {
        this.doughnutChartData = { ...OmedomDoughnutChart.doughnutChartData };
        this.doughnutChartData.labels = [];

        this.total = 0;
        this.legends = [];
    }

    /**
     * @description Update gap between charts when gap change
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @private
     * @memberof CircularGraphComponent
     */
    private updateGap(): void {
        this.elementRef.nativeElement.style.setProperty('--gap', `${this.gap}px`);
    }
}

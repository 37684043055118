import { Timestamp } from 'firebase/firestore';

import { BankItemRefreshStatusCode } from '../../enums';
import { BankItemMFA } from '../../interfaces';
import { RestEntity } from '../rest.entity';

export class BankItemRefreshStatusEntity extends RestEntity {
    /**
     * @description The ID of the item in Bridge, the item the status is linked to
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/03/2024
     * @type {number}
     * @memberof BankItemRefreshStatusEntity
     */
    public itemID!: number;

    /**
     * @description The status of the refresh of the item in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/03/2024
     * @type {BankItemRefreshStatusCode}
     * @memberof BankItemRefreshStatusEntity
     */
    public status!: BankItemRefreshStatusCode;

    /**
     * @description Information to display to the client when the bank requires a Strong Customer Authentication (SCA). This information is only available when the status is info_required.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {BankItemMFA}
     * @memberof BankItemRefreshStatusEntity
     */
    public mfa?: BankItemMFA;

    /**
     * @description The date of the last refresh of the item in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/03/2024
     * @type {Date}
     * @memberof BankItemRefreshStatusEntity
     */
    public refreshedAt!: Date;

    /**
     * @description Count how many accounts of the item have been refreshed. This information is only available when the status is retreiving_data.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankItemRefreshStatusEntity
     */
    public refreshedAccountsCount?: number;

    /**
     * @description Count the total number of accounts in the item. This information is only available when the status is retreiving_data.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankItemRefreshStatusEntity
     */
    public totalAccountsCount?: number;

    constructor(data: Partial<BankItemRefreshStatusEntity>) {
        super(data);

        if (data.refreshedAt instanceof Timestamp) {
            this.refreshedAt = data.refreshedAt.toDate();
        }
    }
}

import { AutomationActionType } from '../../enums';
import { AutomationActionSendEmail, AutomationActionSendNotification } from './';

/**
 * @description Data of an automation action (send notification, send email) to display in a form or in a chart
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 28/11/2023
 * @export
 * @interface AutomationActionData
 */
export interface AutomationActionData {
    [AutomationActionType.sendNotification]: AutomationActionSendNotification;
    [AutomationActionType.sendEmail]: AutomationActionSendEmail;
}

import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AnalyticsService } from '@omedom/services';
import { BehaviorSubject } from 'rxjs';

import { elementAnimation } from '../../../animations';

@Component({
    selector: 'omedom-help-faq',
    templateUrl: './help-faq.component.html',
    styleUrls: ['./help-faq.component.scss'],
    animations: [elementAnimation],
})
export class HelpFaqComponent implements OnInit {
    /**
     * @description Display/reduce the answerfor each question
     * @author Hanane Djeddal
     * @memberof HelpFaqComponent
     */
    public displayQuestion = [
        new BehaviorSubject<boolean>(false),
        new BehaviorSubject<boolean>(false),
        new BehaviorSubject<boolean>(false),
        new BehaviorSubject<boolean>(false),
        new BehaviorSubject<boolean>(false),
        new BehaviorSubject<boolean>(false),
        new BehaviorSubject<boolean>(false),
        new BehaviorSubject<boolean>(false),
    ];

    public isAppleDevice: boolean = false;

    constructor(
        private platform: Platform,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit() {
        this.isAppleDevice = this.platform.is('ios');
    }
    ionViewDidEnter() {
        this.analyticsService.setCurrentScreen('Help FAQ Page');
    }
}

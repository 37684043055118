import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OmedomDateType } from '@omedom/data';

import { DatePickerComponent } from './../date-picker/date-picker.component';

@Component({
    selector: 'omedom-date',
    templateUrl: './date.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DateComponent implements OnInit, OnChanges {
    @Input() date: Date = new Date().toUTC();

    /**
     * @description The max date that can be selected in the date picker modal: first day of the month of the max date (e.g. new Date('2200-12-01'))
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {Date}
     * @memberof DateComponent
     * @default new Date('2200-12-01')
     * @example new Date('2200-12-01')
     */
    @Input()
    public maxDate: Date = new Date('2200-12-01');

    /**
     * @description Emit the selected date when the date picker modal is closed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @memberof DateComponent
     */
    @Output()
    public dateChange = new EventEmitter<Date>();

    /**
     * @description The displayed date info (month or year) according to the type of the date component (e.g. 'Janvier 2023' or '2023' if the type is OmedomDateType.month or OmedomDateType.year respectively) (default: undefined)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @type {string}
     * @memberof DateComponent
     */
    public displayedDate?: string;

    /**
     * @description The months of the year (e.g. ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre','Octobre', 'Novembre', 'Décembre']) (default: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre','Octobre', 'Novembre', 'Décembre'])
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @private
     * @memberof DateComponent
     */
    private readonly months = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ];

    /**
     * @description The type of the date component (OmedomDateType.month or OmedomDateType.year) (default: OmedomDateType.month)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @private
     * @type {OmedomDateType}
     * @memberof DateComponent
     */
    private _type?: OmedomDateType;

    /**
     * @description Return the type of the date component (default: OmedomDateType.month)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @readonly
     * @type {OmedomDateType}
     * @memberof DateComponent
     */
    public get type(): OmedomDateType {
        return this._type || OmedomDateType.month;
    }

    /**
     * @description Set the type of the date component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @memberof DateComponent
     */
    @Input()
    public set type(value: OmedomDateType) {
        this._type = value;
        this.updateDateInfo();
    }

    private toInit = true;

    constructor(private modalController: ModalController) { }

    ngOnInit(): void {
        this.init();
    }

    ngOnChanges(): void {
        if (!this.toInit) {
            return;
        }
        this.init();
    }

    /**
     * @description Init the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @private
     * @memberof DateComponent
     */
    private init(): void {
        this.date = this.date.getUTCFirstDayOfMonth();
        this.date = this.date.getUTCDateWithoutTime();
        this.updateDateInfo();
    }

    /**
     * @description Go to the previous month or year
     * @author Jérémie Lopez
     * @memberof DateComponent
     */
    public previousClicked(): void {
        this.toInit = false;
        this.date = this.date.subUTCMonths(
            this.type === OmedomDateType.month ? 1 : 12
        );
        this.updateDateInfo();
        this.dateChange.next(this.date);
        this.toInit = true;
    }

    /**
     * @description Open the date picker modal
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof DateComponent
     */
    public async triggerDatePicker(): Promise<void> {
        const modal = await this.modalController.create({
            component: DatePickerComponent,
            initialBreakpoint: 1,
            breakpoints: [1],
            handle: false,
            componentProps: {
                maxDate: this.maxDate,
            },
        });
        await modal.present();
        modal.onDidDismiss().then((x) => {
            if (!x.data) {
                return;
            }
            this.date = x.data;
            this.updateDateInfo();
            this.dateChange.next(this.date);
        });
    }

    /**
     * @description Go to the next month or year
     * @author Jérémie Lopez
     * @memberof DateComponent
     */
    public nextClicked(): void {
        this.toInit = false;
        this.date = this.date.addUTCMonths(
            this.type === OmedomDateType.month ? 1 : 12
        );
        this.updateDateInfo();
        this.dateChange.next(this.date);
        this.toInit = true;
    }

    /**
     * @description Update the displayed date info (month or year) according to the type of the date component
     * @author Jérémie Lopez
     * @private
     * @return {*}  {void}
     * @memberof DateComponent
     */
    private updateDateInfo(): void {
        if (!this.date) {
            return;
        }
        this.displayedDate =
            this.type === OmedomDateType.month
                ? `${this.months[this.date.getUTCMonth()]
                } ${this.date.getUTCFullYear()}`
                : this.date.getUTCFullYear().toString();
    }
}

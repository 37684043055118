import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyEntity, SelectOption } from '@omedom/data';
import { ChargeService, PropertyService } from '@omedom/services';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'omedom-asset-filter-building',
    templateUrl: './asset-filter-building.component.html',
    styleUrls: ['./asset-filter-building.component.scss'],
})
export class AssetFilterBuildingComponent implements OnInit {
    @Input() buildingUid!: string;

    @Output() assetSelectedChange = new EventEmitter();

    buildingOptions$ = new BehaviorSubject<SelectOption[]>([]);

    allBuildingOptions: SelectOption[] = [];

    constructor(private propertyService: PropertyService) {}

    async ngOnInit() {
        this.allBuildingOptions = await this.propertyService.getBuildingPropertiesOptions(
            this.buildingUid
        );
        this.buildingOptions$.next(this.allBuildingOptions);
    }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { LoanEntity } from '@omedom/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root',
})
export class LoanService extends RestService<LoanEntity> {
    protected override builder = LoanEntity;

    constructor(protected override firestore: AngularFirestore) {
        super(firestore, 'loans');
    }

    /**
     * @description Get loans of the user in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @param {string} userUID
     * @returns {Observable<LoanEntity[]>}
     * @memberof LoanService
     */
    public _getLoansFromUser(userUID: string): Observable<LoanEntity[]> {
        return this._search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            },
        ]);
    }

    /**
     * @description Get loans of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @param {string} userUID
     * @returns {*}  {Promise<LoanEntity[]>}
     * @memberof LoanService
     */
    public getLoansFromUser(userUID: string): Promise<LoanEntity[]> {
        return this.search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            },
        ]);
    }

    /**
     * @description Get loans of the user without accountID (manual loans) in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @param {string} userUID
     * @returns {Observable<LoanEntity[]>}
     * @memberof LoanService
     */
    public _getLoansManualFromUser(userUID: string): Observable<LoanEntity[]> {
        return this._search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            },
        ]).pipe(
            map((loans) => {
                return loans.filter((loan) => !loan.accountID);
            })
        );
    }

    /**
     * @description Get loans of the user without accountID (manual loans)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @param {string} userUID
     * @returns {Promise<LoanEntity[]>}
     * @memberof LoanService
     */
    public getLoansManualFromUser(userUID: string): Promise<LoanEntity[]> {
        return this.search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            },
        ]).then((loans) => {
            return loans.filter((loan) => !loan.accountID);
        });
    }
}

import { Component, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { BankAccountEntity, BankItemEntity } from '@omedom/data';
import { BankItemService } from '@omedom/services';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'omedom-bank-delete-confirm',
    templateUrl: './bank-delete-confirm.container.html',
    styleUrls: ['./bank-delete-confirm.container.scss'],
})
export class BankDeleteConfirmContainer {
    /**
     * @description Bank item data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @type {BankItemEntity}
     * @memberof BankDeleteConfirmContainer
     */
    @Input({ required: true })
    public item?: BankItemEntity;

    /**
     * @description Bank accounts of the item to delete
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @type {BankAccountEntity[]}
     * @memberof BankDeleteConfirmContainer
     */
    @Input({ required: true })
    public accounts?: BankAccountEntity[];

    /**
     * @description Message to display in the confirm button of the modal component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @memberof BankDeleteConfirmContainer
     */
    public message$ = new BehaviorSubject<string>('Oui, supprimer');

    /**
     * @description State of the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @memberof BankDeleteConfirmContainer
     */
    public state$ = new BehaviorSubject<string>('ok');

    constructor(
        private modalController: ModalController,
        private bankItemService: BankItemService,
        private toastController: ToastController
    ) {}

    /**
     * @description Dismiss the modal component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @returns {Promise<void>}
     * @memberof BankDeleteConfirmContainer
     */
    public async dismiss(): Promise<void> {
        // Check if the state is pending
        if (this.state$.value === 'pending') {
            return;
        }

        await this.modalController.dismiss();
    }

    /**
     * @description Delete the bank item and dismiss the modal component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @returns {Promise<void>}
     * @memberof BankDeleteConfirmContainer
     */
    public async delete(): Promise<void> {
        // Disable dismiss for the modal component
        const modal = await this.modalController.getTop();

        if (modal) {
            modal.backdropDismiss = false;
        }

        // Set the state to pending
        this.state$.next('pending');

        // Set the message to display in the confirm button
        this.message$.next('Suppression...');

        if (this.item) {
            await this.bankItemService.delete(this.item.uid);

            // Set the state to ok
            this.state$.next('ok');

            // Dismiss the modal component
            await this.dismiss();

            // Display a toast message
            const toast = await this.toastController.create({
                message: 'La connexion à cette banque bien été supprimée',
                duration: 3000,
                color: 'success',
            });

            await toast.present();
        } else {
            // Display a toast message
            const toast = await this.toastController.create({
                message: 'Impossible de supprimer la connexion à cette banque',
                duration: 3000,
                color: 'danger',
            });

            await toast.present();

            // Set the state to ok
            this.state$.next('ok');
        }

        // Set the message to display in the confirm button
        this.message$.next('Oui, supprimer');

        // Enable dismiss for the modal component
        if (modal) {
            modal.backdropDismiss = true;
        }
    }
}

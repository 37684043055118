import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'omedom-range',
    templateUrl: './range.component.html',
    styleUrls: ['./range.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RangeComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RangeComponent),
            multi: true,
        },
    ],
})
export class RangeComponent implements ControlValueAccessor, Validator {
    @Input() placeholder?: string;

    @Input() label?: string;

    @Input() name: string = '';

    @Input() required: boolean = false;

    @Input() debounce = 0;

    @Input() min?: number;

    @Input() max?: number;

    @Input() error?: string;

    @Input() warning?: string;

    @Input() boldLabel?: boolean;

    @Input() step?: number;

    value: any;

    isDisabled: boolean = false;

    private onChangeCallback: any;

    public isFocused = new BehaviorSubject<boolean>(false);

    constructor() {
    }

    validate(): null {
        return null;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(): void { }

    writeValue(newValue: any): void {
        if (newValue !== this.value) {
            this.value = newValue;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        // Ne fonctionne que si c'est du template driven
        this.isDisabled = isDisabled;
    }

    valueChange(value: any) {
        this.writeValue(value);
        this.onChangeCallback(value);
    }
}

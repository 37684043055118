/**
 * @description Abstract Rest Entity
 * @author Jérémie Lopez
 * @export
 * @abstract
 * @class RestEntity
 */
export abstract class RestEntity {
    /**
     * @description UID of the entity
     * @author Jérémie Lopez
     * @type {string}
     * @memberof RestEntity
     */
    public uid!: string;

    /**
     * @description Created date of the entity
     * @author Jérémie Lopez
     * @type {Date}
     * @memberof RestEntity
     */
    public created: Date = new Date();

    /**
     * @description Updated date of the entity
     * @author Jérémie Lopez
     * @type {Date}
     * @memberof RestEntity
     */
    public updated: Date = new Date();

    /**
     * @description Updated date of the entity
     * @author Vidjay Seganti (Keleo)
     * @type {string}
     * @memberof RestEntity
     */
    public docName?: string;

    constructor(
        protected data?: {
            [key: string]: any;
        }
    ) {
        if (this.data) {
            const keys = Object.keys(this.data);

            for (const key of keys) {
                let value = this.data[key];

                if (key === 'created' || key === 'updated') {
                    // Check if date is Timestamp
                    if ((value as any).toDate && typeof (value as any).toDate === 'function') {
                        value = (value as any).toDate();
                    }
                }

                if (value) {
                    (this as any)[key] = value;
                }
            }
        }

        delete this.data;
    }
}

<div class="section color-light-black">
  <span>Détention</span>
  <span>
    <strong>{{ property?.purchaseDetails?.owning || '-' }}</strong>
  </span>
</div>

<hr class="omedom-divider" />

<div class="section color-light-black">
  <span>Usage du bien</span>
  <span>
    <strong>{{ propertyUse || '-' }}</strong>
  </span>
</div>
<div class="section color-light-black">
  <span>Mode de détention</span>
  <span>
    <strong>{{ property?.purchaseDetails?.detention || '-' }}</strong>
  </span>
</div>

<hr class="omedom-divider" />
<div
  class="form-collapse"
  (click)="displayFinancialData.next(!displayFinancialData.value)"
  >
  <img
    src="/assets/icon/tax-fonciere.svg"
    alt="données financières"
    title="données financières"
    class="info-icon margin-right-1"
    />
  <span>Données financières</span>
  <i
    class="uil"
    [class.uil-angle-down]="!displayFinancialData.value"
    [class.uil-angle-up]="displayFinancialData.value"
  ></i>
</div>
@if (displayFinancialData | async) {
  <div @slideYInOut>
    <div class="section color-light-black">
      <span>Année d'acquisition</span>
      <span>
        <strong>{{ property?.purchaseDetails?.year || '-' }}</strong>
      </span>
    </div>
    <div class="section color-light-black">
      <span>Prix d'acquisition</span>
      <span>
        <strong>
          {{
          property?.purchaseDetails?.price ||
          property?.purchaseDetails?.price === 0
          ? (property?.purchaseDetails?.price || 0
          | omedomNumber) + ' €'
          : '-'
          }}
        </strong>
      </span>
    </div>
    <div class="section color-light-black">
      <span>Valeur vénale</span>
      <span>
        <strong>{{
          property?.purchaseDetails?.marketValue ||
          property?.purchaseDetails?.marketValue === 0
          ? (property?.purchaseDetails?.marketValue || 0
          | omedomNumber) + ' €'
          : '-'
        }}</strong>
      </span>
    </div>
    <div class="section color-light-black">
      <span>Frais de notaire</span>
      <span>
        <strong>{{
          property?.purchaseDetails?.notaryFees ||
          property?.purchaseDetails?.notaryFees === 0
          ? (property?.purchaseDetails?.notaryFees || 0
          | omedomNumber) + ' €'
          : '-'
        }}</strong>
      </span>
    </div>
    <div class="section color-light-black">
      <span>Frais d'agence</span>
      <span>
        <strong>{{
          property?.purchaseDetails?.agencyFees ||
          property?.purchaseDetails?.agencyFees === 0
          ? (property?.purchaseDetails?.agencyFees || 0
          | omedomNumber) + ' €'
          : '-'
        }}</strong>
      </span>
    </div>
    <div class="section color-light-black">
      <span>Taxe foncière N-1</span>
      <span>
        <strong>{{
          property?.purchaseDetails?.propertyTax ||
          property?.purchaseDetails?.propertyTax === 0
          ? (property?.purchaseDetails?.propertyTax || 0
          | omedomNumber) + ' €'
          : '-'
        }}</strong>
      </span>
    </div>
    <div class="section color-light-black">
      <span>Taxe d'habitation N-1</span>
      <span>
        <strong>{{
          property?.purchaseDetails?.housingTax ||
          property?.purchaseDetails?.housingTax === 0
          ? (property?.purchaseDetails?.housingTax || 0
          | omedomNumber) + ' €'
          : '-'
        }}</strong>
      </span>
    </div>
  </div>
}
<hr class="omedom-divider" />

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientEntity, UserEntity } from '@omedom/data';

@Component({
    selector: 'omedom-chat-client-list',
    templateUrl: './chat-client-list.component.html',
    styleUrls: ['./chat-client-list.component.scss'],
})
export class ChatClientListComponent {
    /**
     * @description List of clients
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/08/2024
     * @type {ClientEntity[]}
     * @memberof ChatClientListComponent
     */
    @Input() clientsWithUser: { client: ClientEntity; user: UserEntity }[] = [];

    /**
     * @description Selected client entity
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/08/2024
     * @type {ClientEntity}
     * @memberof ChatClientListComponent
     */
    @Input() selectedClient?: ClientEntity;

    /**
     * @description Selected client
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/08/2024
     * @memberof ChatClientListComponent
     */
    @Output() clientSelected = new EventEmitter<ClientEntity>();
}

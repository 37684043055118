import { PASSValue } from '../constants/calc-sim.constant';
import { IRTrancheValue, SARLRegime } from '../enums/calc-sim.enum';
import { CalcSimResult, SocialChargesOnRemuneration } from '../interfaces/calc-sim.interface';

export enum TNSActivity {
    //Artisan
    artisan = 'Artisan',
    //Commerçant
    merchant = 'Commerçant',
    //Prof. Lib non réglementée
    nonRegulatedLibPro = 'Profession Libérale non réglementée',
}

// Key of the TNSActivity enum
export type KeyTNSActivity = keyof typeof TNSActivity;

export class Tns {
    /**
     * @description Calculate the TNS (Travailleur Non Salarié)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @static
     * @param {number} remunerationSubmitted
     * @param {KeyTNSActivity} tnsActivity
     * @param {boolean} hasCollaboratingSpouse
     * @returns {*}  {Result}
     * @memberof Tns
     */
    public static calculateTNS(
        remunerationSubmitted: number,
        tnsActivity: KeyTNSActivity,
        hasCollaboratingSpouse: boolean,
        sarlRegime: SARLRegime,
        irTrancheValue: IRTrancheValue = 0,
        dividendsPaid: number = 0,
        socialCapital: number = 0,
        issuePremiums: number = 0,
        checkingAccount: number = 0
    ): CalcSimResult {
        const isSARLIS = sarlRegime === SARLRegime.SARLIS;
        const income = isSARLIS
            ? remunerationSubmitted +
              (dividendsPaid - 0.1 * (socialCapital + issuePremiums + checkingAccount))
            : remunerationSubmitted;
        const socialChargesOnRemuneration = this.calculateSocialChargesOnRemuneration(
            income,
            tnsActivity,
            hasCollaboratingSpouse
        );

        const retirementPension =
            socialChargesOnRemuneration.basicRetirementPension +
            socialChargesOnRemuneration.complementaryRetirementPension;

        const netRemuneration = remunerationSubmitted - socialChargesOnRemuneration.total;

        const taxesOfRemuneration = (netRemuneration * irTrancheValue) / 100;

        const taxesOfDividends = isSARLIS ? (dividendsPaid * 12.8) / 100 : 0;

        const taxes = {
            partOfDividends: taxesOfDividends,
            partOfRemuneration: taxesOfRemuneration,
            total: taxesOfDividends + taxesOfRemuneration,
        };

        const afterTaxIncome = netRemuneration - taxes.total + (isSARLIS ? dividendsPaid : 0);

        const companyCostOnDividends = isSARLIS
            ? dividendsPaid + dividendsPaid * (0.25 / (1 - 0.25))
            : 0;

        const companyCostRemuneration = remunerationSubmitted + socialChargesOnRemuneration.total;
        const companyCostDividends = isSARLIS ? companyCostOnDividends : 0;

        const companyCost = {
            partOfDividends: companyCostDividends,
            partOfRemuneration: companyCostRemuneration,
            total: companyCostRemuneration + companyCostDividends,
        };

        return {
            companyCost,
            afterTaxIncome,
            netRemuneration,
            retirementPension,
            socialChargesOnRemuneration,
            taxes,
        };
    }

    /**
     * @description Calculate the social charges of an assimilated employee
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @param {KeyCompanySize} companySize
     * @param {boolean} hasAdditionalEmploymentContract
     * @param {boolean} companyIsNoSubjectToVAT
     * @returns {*}  {SocialCharges}
     * @memberof AssimilatedEmployee
     */
    private static calculateSocialChargesOnRemuneration(
        remunerationSubmitted: number,
        tnsActivity: KeyTNSActivity,
        hasCollaboratingSpouse: boolean
    ): SocialChargesOnRemuneration {
        // Get all contributions in an array of Contribution objects
        const allContributions = this.getAllContributionsArray(
            remunerationSubmitted,
            tnsActivity,
            hasCollaboratingSpouse
        );

        // Calculate the sum of employer
        const employerContribution = allContributions.reduce((acc, contribution) => {
            return acc + contribution;
        }, 0);

        // Calculate the total of contributions
        const total = employerContribution;

        // Calculate the foresight
        const foresight = this.calculateDisabilityDeath(remunerationSubmitted);

        // Calculate the capped  old age insurance contributions
        const basicRetirement = this.calculateBaseRetirement(remunerationSubmitted);

        // Calculate the basic retirement pension
        const basicRetirementPension = (Math.min(remunerationSubmitted, PASSValue) * 0.5 * 4) / 172;

        // Calculate the complementary retirement
        const complementaryRetirement = this.calculateComplementaryRetirement(
            remunerationSubmitted,
            tnsActivity
        );

        // Calculate the complementary retirement pension
        const complementaryRetirementPension = (complementaryRetirement / 20.734) * 1.327;

        return {
            total,
            employerContribution,
            employeeContribution: 0,
            foresight,
            basicRetirement,
            basicRetirementPension,
            complementaryRetirement,
            complementaryRetirementPension,
        };
    }

    static getAllContributionsArray(
        remunerationSubmitted: number,
        tnsActivity: KeyTNSActivity,
        hasCollaboratingSpouse: boolean
    ): number[] {
        const firstContributions = [
            this.calculateIllnessMaternity(remunerationSubmitted),
            this.calculateBaseRetirement(remunerationSubmitted),
            this.calculateComplementaryRetirement(remunerationSubmitted, tnsActivity),
            this.calculateDisabilityDeath(remunerationSubmitted),
            this.calculateFamilyBenefits(remunerationSubmitted),
            this.calculateContributionCFP(tnsActivity, hasCollaboratingSpouse),
        ];

        const sumFirstContributions = firstContributions.reduce((acc, contribution) => {
            return acc + contribution;
        });

        const secondContributions = [
            this.calculateDectuctibleCSG(remunerationSubmitted, sumFirstContributions),
            this.calculateNoDeductibleCSG(remunerationSubmitted, sumFirstContributions),
            this.calculateCRDS(remunerationSubmitted, sumFirstContributions),
        ];
        return [...firstContributions, ...secondContributions];
    }

    /**
     * @description Calculate the illness and maternity contribution of the TNS
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {number}
     * @memberof Tns
     */
    private static calculateIllnessMaternity(remunerationSubmitted: number): number {
        let contribution = 0;

        if (remunerationSubmitted >= PASSValue * 0.4 && remunerationSubmitted < PASSValue * 0.6) {
            // If the remuneration is upper than 40% of the PASS and lower than 60% of the PASS

            // Formule : 4 / (0.2 * PASS) * (Rémunération - 0.4 * PASS) + 0.5
            contribution =
                (remunerationSubmitted *
                    ((4 / (0.2 * PASSValue)) * (remunerationSubmitted - 0.4 * PASSValue) + 0.5)) /
                100;
        } else if (
            remunerationSubmitted >= PASSValue * 0.6 &&
            remunerationSubmitted < PASSValue * 1.1
        ) {
            // If the remuneration is upper than 60% of the PASS and lower than 110% of the PASS

            // Formule : 2.7 / (0.5 * PASS) * (Rémunération - 0.6 * PASS) + 4.5
            contribution =
                (remunerationSubmitted *
                    ((2.7 / (0.5 * PASSValue)) * (remunerationSubmitted - 0.6 * PASSValue) + 4.5)) /
                100;
        } else if (remunerationSubmitted >= PASSValue * 1.1) {
            // If the remuneration is upper than 110% of the PASS and lower than 5 times the PASS
            const firstContributionBase = Math.min(remunerationSubmitted, PASSValue * 5);

            contribution = (7.2 * firstContributionBase) / 100;
        }

        // Part of the remuneration that is upper than 5 times the PASS
        const secondContributionBase = Math.max(0, remunerationSubmitted - PASSValue * 5);

        if (secondContributionBase > 0) {
            // Formule : 6.5 % * PASS * (Rémunération - 5 * PASS)
            contribution += (6.5 * secondContributionBase) / 100;
        }

        return contribution;
    }

    /**
     * @description Calculate the base retirement contribution of the TNS
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {number}
     * @memberof Tns
     */
    private static calculateBaseRetirement(remunerationSubmitted: number): number {
        let contribution = 0;
        const firstContributionBase = Math.min(remunerationSubmitted, PASSValue);

        contribution = (17.75 * firstContributionBase) / 100;

        const secondContributionBase = Math.max(0, remunerationSubmitted - PASSValue);

        if (secondContributionBase > 0) {
            contribution += (0.6 * secondContributionBase) / 100;
        }

        return contribution;
    }

    /**
     * @description Calculate the complementary retirement contribution of the TNS
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @param {KeyTNSActivity} tnsActivity
     * @returns {*}  {number}
     * @memberof Tns
     */
    private static calculateComplementaryRetirement(
        remunerationSubmitted: number,
        tnsActivity: KeyTNSActivity
    ): number {
        let contribution = 0;
        if (tnsActivity === 'artisan' || tnsActivity === 'merchant') {
            const firstContributionBase = Math.min(remunerationSubmitted, 42946);
            contribution = (7 * firstContributionBase) / 100;

            const secondContributionBase = Math.max(
                0,
                Math.min(remunerationSubmitted, 4 * PASSValue) - 42946
            );

            if (secondContributionBase > 0) {
                contribution += (8 * secondContributionBase) / 100;
            }
        } else if (tnsActivity === 'nonRegulatedLibPro') {
            const contributionBase = Math.max(
                0,
                Math.min(remunerationSubmitted, 4 * PASSValue) - PASSValue
            );

            contribution = (14 * contributionBase) / 100;
        }
        return contribution;
    }

    /**
     * @description Calculate the disability and death contribution of the TNS
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {number}
     * @memberof Tns
     */
    private static calculateDisabilityDeath(remunerationSubmitted: number): number {
        const contributionBase = Math.min(remunerationSubmitted, PASSValue);

        return (1.3 * contributionBase) / 100;
    }

    /**
     * @description Calculate the family benefits contribution of the TNS
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {number}
     * @memberof Tns
     */
    private static calculateFamilyBenefits(remunerationSubmitted: number): number {
        if (remunerationSubmitted < PASSValue * 1.1) {
            return 0;
        } else if (
            remunerationSubmitted >= PASSValue * 1.1 &&
            remunerationSubmitted <= PASSValue * 1.4
        ) {
            return (
                (remunerationSubmitted *
                    ((3.1 / (PASSValue * 0.3)) * (remunerationSubmitted - 1.1 * PASSValue))) /
                100
            );
            // Formule : 3.1 / (PASS * 0.3) * (Rémunération - 1.1 * PASS)
        } else {
            return (3.1 * remunerationSubmitted) / 100;
        }
    }

    /**
     * @description Calculate the contribution to the CFP of the TNS
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {KeyTNSActivity} tnsActivity
     * @param {boolean} hasCollaboratingSpouse
     * @returns {*}  {number}
     * @memberof Tns
     */
    private static calculateContributionCFP(
        tnsActivity: KeyTNSActivity,
        hasCollaboratingSpouse: boolean
    ): number {
        let contribution = 0;

        if (tnsActivity === 'artisan') {
            contribution = (0.29 * PASSValue) / 100;
        } else if (tnsActivity === 'merchant' || tnsActivity === 'nonRegulatedLibPro') {
            if (hasCollaboratingSpouse) {
                contribution = (0.34 * PASSValue) / 100;
            } else {
                contribution = (0.25 * PASSValue) / 100;
            }
        }

        return contribution;
    }

    /**
     * @description Calculate the deductible CSG of the TNS
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @param {number} compulsorySocialContributions
     * @returns {*}  {number}
     * @memberof Tns
     */
    private static calculateDectuctibleCSG(
        remunerationSubmitted: number,
        compulsorySocialContributions: number
    ): number {
        return (6.8 * (remunerationSubmitted + compulsorySocialContributions)) / 100;
    }

    /**
     * @description Calculate the no-deductible CSG of the TNS
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @param {number} deductibleCSG
     * @returns {*}  {number}
     * @memberof Tns
     */
    private static calculateNoDeductibleCSG(
        remunerationSubmitted: number,
        deductibleCSG: number
    ): number {
        return (2.4 * (remunerationSubmitted + deductibleCSG)) / 100;
    }

    /**
     * @description Calculate the CRDS of the TNS
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @param {number} deductibleCSG
     * @returns {*}  {number}
     * @memberof Tns
     */
    private static calculateCRDS(remunerationSubmitted: number, deductibleCSG: number): number {
        return (0.5 * (remunerationSubmitted + deductibleCSG)) / 100;
    }
}

import { Timestamp } from 'firebase/firestore';

import { BankLoanDetails } from '../interfaces';
import { RestEntity } from './rest.entity';

export class LoanEntity extends RestEntity implements BankLoanDetails {
    /**
     * @description The next payment date of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {Date}
     * @memberof BankLoanDetails
     */
    public nextPaymentDate?: Date;

    /**
     * @description The next amount of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankLoanDetails
     */
    public nextPaymentAmount?: number;

    /**
     * @description End date of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {Date}
     * @memberof BankLoanDetails
     */
    public maturityDate?: Date;

    /**
     * @description Start date of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {Date}
     * @memberof BankLoanDetails
     */
    public openingDate?: Date;

    /**
     * @description Interest rate in percent
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankLoanDetails
     */
    public interestRate?: number;

    /**
     * @description Type of loan given by the bank app
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof BankLoanDetails
     */
    public type?: string;

    /**
     * @description Amount of the capital that was borrowed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankLoanDetails
     */
    public borrowedCapital?: number;

    /**
     * @description Amount of capital that has already repaid
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankLoanDetails
     */
    public repaidCapital?: number;

    /**
     * @description Amount of capital left to repay
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankLoanDetails
     */
    public remainingCapital?: number;

    /**
     * @description ID of the account linked to the loan in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 24/06/2024
     * @type {number}
     * @memberof LoanEntity
     */
    public accountID?: number;

    /**
     * @description ID of the bank linked to the loan in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 24/06/2024
     * @type {number}
     * @memberof LoanEntity
     */
    public bankID?: number;

    /**
     * @description UID of the user linked to the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 24/06/2024
     * @type {string}
     * @memberof LoanEntity
     */
    public userUID!: string;

    /**
     * @description Name of the loan given by the bank app
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 24/06/2024
     * @type {string}
     * @memberof LoanEntity
     */
    public name!: string;

    /**
     * @description Guarantee of the loan given by the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @type {string}
     * @memberof LoanEntity
     */
    public guarantee?: string;

    /**
     * @description Is the loan shared to the pro account or not (if any) for the loan account
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {boolean}
     * @memberof LoanEntity
     */
    sharedToPro?: boolean;

    /**
     * @description Assurance of the loan given by the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @type {string}
     * @memberof LoanEntity
     */
    public assurance?: string;

    constructor(data?: Partial<LoanEntity>) {
        super(data);

        if (data?.openingDate instanceof Timestamp) {
            this.openingDate = new Date(data.openingDate.seconds * 1000);
        }

        if (data?.maturityDate instanceof Timestamp) {
            this.maturityDate = new Date(data.maturityDate.seconds * 1000);
        }

        if (data?.nextPaymentDate instanceof Timestamp) {
            this.nextPaymentDate = new Date(data.nextPaymentDate.seconds * 1000);
        }
    }
}

<div class="omedom-modal modal" style="padding: 0 !important">
  <div class="stories-header">
    <i
      class="uil uil-times-circle stories-steps-close stories-icon"
      (click)="dismiss()"
    ></i>

    <!-- <ng-container *ngIf="canNavigate">
    <div
      *ngFor="let story of stories; let index = index;"
      class="stories-steps-element"
      [class.selected]="selectedIndex === index"
      (click)="updateSelectedIndex(index)"
    ></div>
  </ng-container> -->
</div>
@if (canNavigate) {
  <div class="stories-footer">
    <i
      class="uil uil-angle-left stories-icon"
      (click)="updateSelectedIndex(selectedIndex - 1)"
    ></i>
    <i
      class="uil uil-angle-right stories-icon"
      (click)="updateSelectedIndex(selectedIndex + 1)"
    ></i>
  </div>
}
<div class="stories">
  @if (selectedStory) {
    <omedom-story-detail
      #omedomStoryDetail
      class="stories-content w-100 flex-1 margin-bottom-1"
      [class.margin-bottom-2]="!canNavigate"
      [story]="selectedStory"
      (nextStory)="dismiss()"
      (exitStories)="dismiss()"
      (swipe)="onSwipe($event)"
    ></omedom-story-detail>
  }
</div>
</div>

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PropertyType, SelectOption, SocietyType } from '@omedom/data';
import { of } from 'rxjs';

import { OmedomRadioOption } from '../../components/radio';

@Component({
    selector: 'omedom-property-society-filter',
    templateUrl: './property-society-filter.component.html',
    styleUrls: ['./property-society-filter.component.scss'],
})
export class PropertySocietyFilterComponent implements OnInit {
    /**
     * @description Placeholder of select component
     * @author Jérémie Lopez
     * @memberof PropertySocietyFilterComponent
     */
    public typePropertyPlaceholder = {
        id: null,
        label: 'Type de bien',
    } as SelectOption;

    /**
     * @description List type of property in the select component
     * @author Jérémie Lopez
     * @memberof PropertySocietyFilterComponent
     */
    public typePropertyOptions$ = of([
        {
            id: PropertyType.appartement,
            label: PropertyType.appartement,
        } as SelectOption,
        {
            id: PropertyType.batimentAgricole,
            label: PropertyType.batimentAgricole,
        } as SelectOption,
        {
            id: PropertyType.bureaux,
            label: PropertyType.bureaux,
        } as SelectOption,
        { id: PropertyType.chalet, label: PropertyType.chalet } as SelectOption,
        { id: PropertyType.hotel, label: PropertyType.hotel } as SelectOption,
        {
            id: PropertyType.immeuble,
            label: PropertyType.immeuble,
        } as SelectOption,
        {
            id: PropertyType.localCommercial,
            label: PropertyType.localCommercial,
        } as SelectOption,
        { id: PropertyType.maison, label: PropertyType.maison } as SelectOption,
        {
            id: PropertyType.parkingBox,
            label: PropertyType.parkingBox,
        } as SelectOption,
        {
            id: PropertyType.terrain,
            label: PropertyType.terrain,
        } as SelectOption,
        { id: PropertyType.other, label: PropertyType.other } as SelectOption,
    ]);

    /**
     * @description Placeholder of select component
     * @author Jérémie Lopez
     * @memberof PropertySocietyFilterComponent
     */
    public typeSocietyPlaceholder = {
        id: null,
        label: 'Type de société',
    } as SelectOption;

    /**
     * @description List type of society in the select component
     * @author Jérémie Lopez
     * @memberof PropertySocietyFilterComponent
     */
    public typeSocietyOptions$ = of([
        { id: SocietyType.sci, label: SocietyType.sci } as SelectOption,
        { id: SocietyType.sas, label: SocietyType.sas } as SelectOption,
        { id: SocietyType.sarl, label: SocietyType.sarl } as SelectOption,
        {
            id: SocietyType.familySarl,
            label: SocietyType.familySarl,
        } as SelectOption,
    ]);

    /**
     * @description Rentability options
     * @author Jérémie Lopez
     * @type {OmedomRadioOption[]}
     * @memberof PropertySocietyFilterComponent
     */
    public rentabilityOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({ id: 'negative', label: 'Négatives' }),
        new OmedomRadioOption({ id: 'positive', label: 'Positives' }),
        // new OmedomRadioOption({ id: 'all', label: 'Toutes' }),
    ];

    public entityType?: 'property' | 'society' | null;
    public type?: SelectOption;
    public rentability?: 'negative' | 'positive';

    constructor(private modalController: ModalController) {}

    ngOnInit(): void {
        this.initState();
    }

    /**
     * @description Init state with default value
     * @author Jérémie Lopez
     * @private
     * @memberof PropertySocietyFilterComponent
     */
    private initState(): void {
        // const clone = Object.assign({}, this.options.find(element => element.id.where === this.selectedOption.where && element.id.order === this.selectedOption.order));
        // delete this.selectedOption;
        // setTimeout(() => {
        //     this.selectedOption = clone.id;
        // });
    }

    /**
     * @description Close modal
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof PropertySocietyFilterComponent
     */
    public async close(data?: any): Promise<void> {
        await this.modalController.dismiss(data);
    }

    public changeEntityType(value: 'property' | 'society'): void {
        this.entityType = this.entityType === value ? null : value;
    }

    public async sendFilter(): Promise<void> {
        const filter = [];

        if (this.entityType) {
            filter.push({
                where: 'entityType',
                value: this.entityType,
            });
        }

        if (this.rentability) {
            filter.push({
                where: 'rentability',
                value: this.rentability,
            });
        }

        if (this.type) {
            filter.push({
                where: 'type',
                value: this.type?.id,
            });
        }

        await this.close({ filter });
    }
}

<h1 class="register-title">Bienvenue chez vous ! Vous avez les clés !</h1>

<form class="register-form" (ngSubmit)="submit()" [formGroup]="registerForm">
  <div class="register-providers-container">
    <button
      class="gsi-material-button"
      [class]="'gsi-material-button-' + mode"
      type="button"
      (click)="loginGoogle()"
      >
      <div class="gsi-material-button-state"></div>
      <div class="gsi-material-button-content-wrapper">
        <span class="gsi-material-button-contents"
          >Inscrivez-vous avec Google
        </span>
        <span style="display: none">Inscrivez-vous avec Google </span>
        <div class="gsi-material-button-icon">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="display: block"
            >
            <path
              fill="#EA4335"
              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
            ></path>
            <path
              fill="#4285F4"
              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
            ></path>
            <path
              fill="#FBBC05"
              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
            ></path>
            <path
              fill="#34A853"
              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
            ></path>
            <path fill="none" d="M0 0h48v48H0z"></path>
          </svg>
        </div>
      </div>
    </button>

    @if (isAppleSignInAvailable) {
      <button
        class="signin-button-apple"
        [class]="'signin-button-apple-' + mode"
        expand="full"
        type="button"
        (click)="loginApple()"
        >
        <span>Inscrivez-vous avec Apple</span>
        <ion-icon
          style="font-size: 25px"
          name="logo-apple"
          slot="start"
        ></ion-icon>
      </button>
    }
  </div>
  <div class="text-center">Ou</div>
  <div style="width: 100%">
    <omedom-input
      [mode]="mode"
      name="email"
      icon="uil-envelope"
      placeholder="Email *"
      [required]="true"
      autocomplete="email"
      formControlName="email"
      [error]="getError('email')"
      type="email"
      [pattern]="emailRegex"
    ></omedom-input>
    <div class="email-domain-container">
      @for (emailDomain of emailDomainFiltered; track emailDomain) {
        <button
          class="omedom-button-primary domain-button"
          [class.omedom-button-desktop]="mode === allMode.desktop"
          type="button"
          (click)="setEmailDomain(emailDomain)"
          >
          {{ emailDomain }}
        </button>
      }
    </div>
  </div>

  <omedom-input
    [mode]="mode"
    name="password"
    icon="uil-padlock"
    placeholder="Mot de passe *"
    autocomplete="new-password"
    [required]="true"
    formControlName="password"
    [enableShowPassword]="true"
    [error]="getError('password')"
    type="password"
  ></omedom-input>

  <div class="security">
    <div class="security-bar">
      @for (item of [1, 2, 3, 4, 5]; track item) {
        <div
          class="security-bar-item margin-right-1"
                [ngClass]="{
                    'security-bar-item--weak':
                        passwordStrengthExplicit === 'Faible' &&
                        passwordStrength >= item,
                    'security-bar-item--medium':
                        passwordStrengthExplicit === 'Moyen' &&
                        passwordStrength >= item,
                    'security-bar-item--strong':
                        passwordStrengthExplicit === 'Fort' &&
                        passwordStrength >= item
                }"
        ></div>
      }
      <span class="security-bar-text">{{
        passwordStrengthExplicit
      }}</span>
    </div>

    <div class="security-text">
      <div class="security-text-title">
        <i class="uil uil-info-circle margin-right-1"></i>
        <span>Votre mot de passe doit comporter au moins :</span>
      </div>
      <ul class="security-text-list">
        <li
                    [ngClass]="{
                        'security-text-item--valid': numberOfCaracters === 1,
                        'security-text-item--invalid': numberOfCaracters === 0
                    }"
          >
          8 caractères minimum
        </li>
        <li
                    [ngClass]="{
                        'security-text-item--valid': hasMajorLetter === 1,
                        'security-text-item--invalid': hasMajorLetter === 0
                    }"
          >
          1 majuscule
        </li>
        <li
                    [ngClass]="{
                        'security-text-item--valid': hasMinorLetter === 1,
                        'security-text-item--invalid': hasMinorLetter === 0
                    }"
          >
          1 miniscule
        </li>
        <li
                    [ngClass]="{
                        'security-text-item--valid': hasSpecialCaracters === 1,
                        'security-text-item--invalid': hasSpecialCaracters === 0
                    }"
          >
          1 caractère spécial
        </li>
        <li
                    [ngClass]="{
                        'security-text-item--valid': hasNumber === 1,
                        'security-text-item--invalid': hasNumber === 0
                    }"
          >
          1 nombre
        </li>
      </ul>
    </div>
  </div>

  <hr class="omedom-divider omedom-no-margin" />

  <div class="checkbox-container">
    <ion-checkbox
      class="omedom-checkBox"
      formControlName="conditions"
    ></ion-checkbox>
    <span class="checkbox-text">
      Je déclare avoir pris connaissance et accepter les
      <span class="link" (click)="showMention('cgv')"
        >Conditions générales de vente</span
        >
        et la
        <span class="link" (click)="showMention('confidentiality')"
          >Politique de confidentialité</span
          >
          .
        </span>
      </div>

      <div class="checkbox-container">
        <ion-checkbox
          class="omedom-checkBox"
          formControlName="newsletter"
        ></ion-checkbox>
        <span class="checkbox-text"
          >Je souhaite rester informé des actualités Omedom.</span
          >
        </div>

        <button
          class="omedom-button-primary w-100"
          [class]="'omedom-button-' + mode"
          type="submit"
          [disabled]="registerForm.invalid || pending"
          >
          <i class="uil uil-register register-icon"></i>
          S'inscrire
          @if (pending) {
            <ion-spinner
              color="primary"
              class="register-spinner"
            ></ion-spinner>
          }
        </button>
      </form>

<div class="omedom-modal">
    <div class="omedom-modal-header">
        <div class="level-header-title">
            <svg
                width="20"
                id="firework"
                height="19"
                class="margin-right-1"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.0183 4.76515C17.0875 5.29603 16.7132 5.78249 16.1823 5.85169C15.8826 5.89076 15.5557 6.0879 15.2827 6.36239C15.339 6.53884 15.3738 6.72716 15.3725 6.92054C15.3708 7.16012 15.3112 7.47233 15.0903 7.74954C14.8581 8.04104 14.538 8.18058 14.2359 8.21996C13.9306 8.25976 13.4737 8.20742 13.148 7.81255C12.8677 7.47257 12.8623 7.08165 12.8812 6.8745C12.8938 6.73655 12.9243 6.59805 12.9675 6.46214C12.9054 6.43755 12.862 6.43618 12.8353 6.43967C12.8348 6.43973 12.8342 6.43977 12.8335 6.43983C12.8183 6.44096 12.7436 6.44654 12.6446 6.67778C12.5383 6.92632 12.498 7.25091 12.502 7.46252C12.5122 7.9978 12.0865 8.43996 11.5512 8.45011C11.0159 8.46026 10.5738 8.03455 10.5636 7.49928C10.556 7.09606 10.6194 6.48261 10.8621 5.91522C11.1075 5.34167 11.6248 4.64229 12.5847 4.51717C13.1528 4.44312 13.6535 4.59926 14.0616 4.84938C14.5459 4.40662 15.181 4.02705 15.9317 3.92919C16.4626 3.86 16.9491 4.23427 17.0183 4.76515Z"
                    fill="#04151C"
                />
                <path
                    d="M7.82519 0.272025C8.21033 -0.0998529 8.82401 -0.0891021 9.19589 0.296037L9.19689 0.297078L9.19798 0.298208L9.20039 0.300731L9.20618 0.306833L9.22144 0.32322C9.23319 0.335981 9.24804 0.352443 9.26551 0.37245C9.30038 0.412392 9.34605 0.466903 9.39839 0.534732C9.50233 0.669426 9.63673 0.861979 9.76546 1.10213C10.0181 1.5734 10.2828 2.2929 10.1736 3.14006C10.0647 3.98468 9.6222 4.60921 9.31963 5.01964C9.28135 5.07156 9.24613 5.11892 9.21363 5.16261C9.10205 5.31264 9.0217 5.42067 8.95309 5.53138C8.87365 5.65955 8.86516 5.70845 8.86516 5.70845C8.83327 5.95584 8.86316 6.12525 8.88762 6.21478C8.89914 6.25697 8.91011 6.28359 8.91467 6.29386C9.17381 6.74109 9.03603 7.31716 8.59585 7.59717C8.14413 7.88453 7.54499 7.75128 7.25764 7.29956L7.25662 7.29797L7.25559 7.29633L7.25344 7.29291L7.24886 7.28552L7.23862 7.26854C7.23133 7.25625 7.22315 7.24199 7.21426 7.22579C7.19648 7.19339 7.17582 7.15313 7.15381 7.10518C7.10978 7.00929 7.06014 6.88224 7.01735 6.72558C6.93113 6.40988 6.87509 5.98209 6.94234 5.46033C6.99249 5.07118 7.15678 4.74947 7.30513 4.51009C7.42175 4.32192 7.56644 4.1279 7.68439 3.96972C7.71085 3.93425 7.73596 3.90058 7.7591 3.86918C8.04876 3.47627 8.21088 3.20133 8.25071 2.89224C8.29022 2.58569 8.19808 2.2818 8.05673 2.01811C7.98847 1.89079 7.91659 1.78797 7.86349 1.71916C7.83732 1.68524 7.81678 1.66097 7.80507 1.64757L7.79522 1.6365C7.42942 1.251 7.44211 0.641906 7.82519 0.272025Z"
                    fill="#04151C"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.4526 11.7629L13.4534 11.7621L13.4551 11.7603L13.4559 11.7595"
                    fill="#04151C"
                />
                <path
                    d="M12.0562 10.418C11.686 10.8048 11.6994 11.4184 12.0861 11.7886C12.4729 12.1588 13.0253 11.9847 13.0253 11.9847L13.4534 11.7621C13.4559 11.7596 13.4619 11.7537 13.4713 11.7448C13.49 11.727 13.5216 11.698 13.5643 11.662C13.6509 11.5889 13.7762 11.4932 13.9275 11.4039C14.2472 11.2152 14.5765 11.1165 14.874 11.1693C15.1783 11.2232 15.4536 11.4255 15.8649 11.8192C15.8972 11.8501 15.9317 11.8836 15.968 11.9187C16.1293 12.075 16.3259 12.2655 16.5192 12.4201C16.762 12.6144 17.1069 12.8444 17.5478 12.9226C18.1261 13.0252 18.6072 12.9602 18.9593 12.8559C19.1336 12.8042 19.2744 12.7435 19.3788 12.6907C19.4311 12.6643 19.4747 12.6397 19.5091 12.6189C19.5264 12.6084 19.5414 12.599 19.5542 12.5906L19.5716 12.579L19.5791 12.574L19.5825 12.5716L19.5842 12.5705L19.5858 12.5694C20.0246 12.2627 20.1318 11.6584 19.8252 11.2195C19.5255 10.7907 18.9417 10.6785 18.5057 10.9597L18.5043 10.9604C18.4917 10.9668 18.4591 10.982 18.4084 10.997C18.3114 11.0258 18.1382 11.0571 17.8935 11.0149C17.8856 11.0116 17.8355 10.9904 17.7306 10.9064C17.6156 10.8144 17.5042 10.7068 17.3508 10.5586C17.3063 10.5156 17.2582 10.4691 17.2055 10.4187C16.7967 10.0274 16.1485 9.42627 15.2126 9.26028C14.27 9.0931 13.4558 9.43112 12.9422 9.73419C12.6767 9.89084 12.4629 10.0547 12.3145 10.1798C12.2397 10.2429 12.1797 10.2976 12.1364 10.3387C12.1147 10.3593 12.0971 10.3767 12.0836 10.39L12.0667 10.4072L12.0606 10.4134L12.0582 10.4159L12.0562 10.418Z"
                    fill="#04151C"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.67217 6.79646C5.98424 6.74953 6.29962 6.85745 6.51752 7.08573L11.3644 12.1634C11.5791 12.3883 11.6729 12.7026 11.6166 13.0083C11.5603 13.3141 11.3607 13.5743 11.08 13.708L1.38617 18.3241C1.01255 18.502 0.567182 18.4231 0.277391 18.1277C-0.0123993 17.8323 -0.0827026 17.3855 0.102365 17.0153L4.94927 7.32154C5.0904 7.03928 5.3601 6.84338 5.67217 6.79646ZM6.06689 9.4215L3.09649 15.3623L9.03728 12.5333L6.06689 9.4215Z"
                    fill="#04151C"
                />
                <path
                    d="M5.81629 2.90814C5.81629 3.44352 5.38228 3.87752 4.8469 3.87752C4.31153 3.87752 3.87752 3.44352 3.87752 2.90814C3.87752 2.37277 4.31153 1.93876 4.8469 1.93876C5.38228 1.93876 5.81629 2.37277 5.81629 2.90814Z"
                    fill="#04151C"
                />
                <path
                    d="M14.5407 0.969382C14.5407 1.50476 14.1067 1.93876 13.5713 1.93876C13.036 1.93876 12.602 1.50476 12.602 0.969382C12.602 0.434008 13.036 9.28449e-07 13.5713 9.28449e-07C14.1067 9.28449e-07 14.5407 0.434008 14.5407 0.969382Z"
                    fill="#04151C"
                />
                <path
                    d="M19.3876 7.75505C19.3876 8.29042 18.9536 8.72443 18.4182 8.72443C17.8829 8.72443 17.4489 8.29042 17.4489 7.75505C17.4489 7.21967 17.8829 6.78567 18.4182 6.78567C18.9536 6.78567 19.3876 7.21967 19.3876 7.75505Z"
                    fill="#04151C"
                />
                <path
                    d="M16.4795 14.5407C16.4795 15.0761 16.0455 15.5101 15.5101 15.5101C14.9747 15.5101 14.5407 15.0761 14.5407 14.5407C14.5407 14.0053 14.9747 13.5713 15.5101 13.5713C16.0455 13.5713 16.4795 14.0053 16.4795 14.5407Z"
                    fill="#04151C"
                />
            </svg>

            <h1>Niveau {{ level.order }}</h1>
        </div>
        <i class="uil uil-times-circle" (click)="dismiss()"></i>
    </div>

    <div class="omedom-modal-content">
        <p class="level-text">
            Felicitations ! <br />Vous venez d'atteindre le niveau :
        </p>

        <div class="level-display-container">
            <div class="level-order">
                <h3>{{ level.order }}</h3>
            </div>
            <div
                class="level-icon"
                [style.mask]="getLevelMask(level.order)"
                [style.-webkit-mask]="getLevelMask(level.order)"
            ></div>
            <strong class="level-name">{{ level.name }}</strong>
        </div>
    </div>
    <div class="omedom-modal-buttons-wide">
        <button class="omedom-button-primary w-100" (click)="dismiss()">
            Continuer
        </button>
    </div>
</div>

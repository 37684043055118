<omedom-modal-header>Acheter des licences</omedom-modal-header>

<div class="container">
    <div class="licence-form margin-bottom-2">
        <h3>Quantité souhaitée</h3>
        <div class="licence-form-group">
            <omedom-input
                type="number"
                [min]="0"
                [max]="200"
                [(ngModel)]="numberOfLicences"
                (ngModelChange)="checkNumberOfLicences($event)"
            ></omedom-input>
            <omedom-range
                [min]="0"
                [max]="200"
                [step]="1"
                [(ngModel)]="numberOfLicences"
                (ngModelChange)="checkNumberOfLicences($event)"
            ></omedom-range>
        </div>
    </div>

    <div class="licence-display">
        <h3>Total</h3>
        <div class="licence-price-list">
            <!-- Price by year -->
            <div class="licence-price">
                <div class="value">
                    <span class="number"
                        >{{ pricePerYear | number : '0.2-2' }}€</span
                    >
                    <span class="taxes">ht</span>
                </div>
                <div class="content">Total/an</div>
            </div>

            <!-- Price by month -->
            <!-- <div class="licence-price">
      <div class="value">
        <span class="number">{{ pricePerMonth | number : '0.2-2' }}€</span>
        <span class="taxes">ht</span>
      </div>
      <div class="content">
        Total/mois
      </div>
    </div> -->
        </div>
    </div>

    <!-- <p>Moyen de paiement possibles : CB, virement</p> -->

    <div class="actions">
        <button class="omedom-button-secondary" (click)="close()">
            Annuler
        </button>
        <button
            class="omedom-button-yellow"
            [disabled]="isOrderDisabled"
            (click)="order()"
        >
            <i class="uil uil-message"></i>
            Envoyer ma demande @if (pending) {
            <ion-spinner></ion-spinner>
            }
        </button>
    </div>
</div>

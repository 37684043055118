import { Component, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { TenantEntity } from '@omedom/data';
import { TenantService } from '@omedom/services';
import { OmedomRegex } from '@omedom/utils';

@Component({
    selector: 'omedom-receipt-relaunch-missing-tenant-email',
    templateUrl: './receipt-relaunch-missing-tenant-email.component.html',
    styleUrls: ['./receipt-relaunch-missing-tenant-email.component.scss'],
})
export class ReceiptRelaunchMissingTenantEmailComponent {
    @Input()
    public tenants: TenantEntity[] = [];

    @Input({ required: false })
    public isPayed!: boolean;

    public emailRegex: string = OmedomRegex.emailRegex;

    public loading = {
        delete: false,
        save: false,
        send: false,
        generate: [],
    }; // remplacer par behaviourSubject

    constructor(
        private tenantService: TenantService,
        private modalController: ModalController,
        private toastController: ToastController
    ) { }

    public async close(): Promise<void> {
        this.dismiss(false);
    }

    private async dismiss(emailIsSendable: boolean): Promise<void> {
        await this.modalController.dismiss(emailIsSendable);
    }

    public async updateTenants(): Promise<void> {
        try {
            this.loading.save = true;

            await Promise.all(
                this.tenants.map(async (tenant) => {
                    if (tenant) {
                        await this.tenantService.update(tenant);
                    }
                })
            );

            this.dismiss(true);
        } catch (error) {
            console.error(error);
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });

            this.dismiss(false);
            await toast.present();
        }
    }
}

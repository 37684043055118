import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BankItemEntity } from '@omedom/data';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RestService } from './rest.service';

@Injectable({ providedIn: 'root' })
export class BankItemService extends RestService<BankItemEntity> {
    protected override builder = BankItemEntity;

    constructor(
        protected override firestore: AngularFirestore,
        private functions: AngularFireFunctions
    ) {
        super(firestore, 'bankItems');
    }

    /**
     * @description Return bank items of the user in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/04/2024
     * @param {string} userUID
     * @returns {Observable<BankItemEntity[]>}
     * @memberof BankItemService
     */
    public _getUserBankItems(userUID: string): Observable<BankItemEntity[]> {
        return this._search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            },
        ]);
    }

    /**
     * @description Return the last bank item of the user in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @param {string} userUID
     * @returns {Observable<BankItemEntity>}
     * @memberof BankItemService
     */
    public _getUserBankLastItem(userUID: string): Observable<BankItemEntity> {
        return this._search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            },
            {
                sortBy: 'created',
                direction: 'desc',
                limit: 1,
            },
        ]).pipe(map((items) => items[0]));
    }

    /**
     * @description Generate a bank sync session and return the link to the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/04/2024
     * @returns {Promise<string>}
     * @memberof BankItemService
     */
    public async generateBankSyncSession(): Promise<string> {
        const generateBankSyncSession = this.functions.httpsCallable<undefined, { link: string }>(
            'generateBankSyncSession'
        );
        const response = await lastValueFrom(generateBankSyncSession(undefined));

        return response.link;
    }

    /**
     * @description Create a brige user if it doesn't exist
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/04/2024
     * @returns {Promise<void>}
     * @memberof BankItemService
     */
    public async patchBridgeUser(): Promise<void> {
        const patchBridgeUser = this.functions.httpsCallable<undefined, void>('patchBridgeUser');
        await lastValueFrom(patchBridgeUser(undefined));
    }

    /**
     * @description Generate a bank edit session and return the link to the user to edit his bank item informations
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @param {BankItemEntity} item
     * @returns {Promise<string>}
     * @memberof BankItemService
     */
    public async generateBankEditSession(item: BankItemEntity): Promise<string> {
        const generateBankEditSession = this.functions.httpsCallable<
            { item: BankItemEntity },
            { link: string }
        >('generateBankEditSession');
        const response = await lastValueFrom(generateBankEditSession({ item }));

        return response.link;
    }

    /**
     * @description Generate a bank SCA session and return the link to the user to validate the SCA process of his bank item informations
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @param {BankItemEntity} item
     * @returns {Promise<string>}
     * @memberof BankItemService
     */
    public async generateBankSCASession(item: BankItemEntity): Promise<string> {
        const generateBankSCASession = this.functions.httpsCallable<
            { item: BankItemEntity },
            { link: string }
        >('generateBankSCASession');
        const response = await lastValueFrom(generateBankSCASession({ item }));

        return response.link;
    }

    /**
     * @description Generate a bank pro session and return the link to the user to validate the pro process of his bank item informations
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @returns {Promise<string>}
     * @memberof BankItemService
     */
    public async generateBankProSession(): Promise<string> {
        const generateBankProSession = this.functions.httpsCallable<undefined, { link: string }>(
            'generateBankProSession'
        );
        const response = await lastValueFrom(generateBankProSession(undefined));

        return response.link;
    }
}

import { Sharing, SocietyRoleMember } from '../entities';

/**
 * @description Interface use in backend to send email when sharing change
 * @author ANDRE Felix
 * @export
 * @interface EmailSharingInfos
 */
export interface EmailSharingInfos {
    sharingInfo: Sharing;
    propertyName: string;
    userUID: string;
    templateId: number;
    message: string;
    role?: SocietyRoleMember;
}

<ng-container [ngTemplateOutlet]="content"></ng-container>

<ng-template #content>
  @if ((transactions$ | async); as transactions) {
    @if (form && transactions.length > 0) {
      <ng-container
        [formGroup]="form"
        >
        <omedom-bank-transaction-list
          [transactions]="transactions"
          introduction="Nous avons identifié des mouvements similaires. Vous pouvez les associer en même temps."
          [selectable]="true"
          formControlName="transactionUIDs"
          [showActions]="false"
        ></omedom-bank-transaction-list>
      </ng-container>
    } @else {
      @switch (state$ | async) {
        @case ('pending') {
          <ng-container *ngTemplateOutlet="pending"></ng-container>
        }
        @default {
          <ng-container *ngTemplateOutlet="empty"></ng-container>
        }
      }
    }
  } @else {
    @switch (state$ | async) {
      @case ('pending') {
        <ng-container *ngTemplateOutlet="pending"></ng-container>
      }
      @default {
        <ng-container *ngTemplateOutlet="empty"></ng-container>
      }
    }
  }
</ng-template>


<ng-template #pending>
  <div @element class="pending-container">
    <span>Recherche de récurrence...</span>
    <ion-spinner></ion-spinner>
  </div>
</ng-template>

<ng-template #empty>
  <div class="empty-container" @element>
    <i class="uil uil-info-circle empty-icon"></i>
    <span class="empty-text"
      >Aucune mouvement récurrent n'a été trouvé.</span
      >
    </div>
  </ng-template>

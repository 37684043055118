<ng-content></ng-content>
<a class="type-toggle" (click)="toggleShow()">
    <ion-icon
        class="show-option"
        [hidden]="showPassword"
        name="eye-off-outline"
    ></ion-icon>
    <ion-icon
        class="hide-option"
        [hidden]="!showPassword"
        name="eye-outline"
    ></ion-icon>
</a>

import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AllAssetTypeName } from '@omedom/data';
import { ValidationModalComponent } from '../validation-modal/validation-modal.component';

@Component({
    selector: 'omedom-share-to-pro-card',
    templateUrl: './share-to-pro-card.component.html',
    styleUrls: ['./share-to-pro-card.component.scss'],
})
export class ShareToProCardComponent implements OnChanges {
    @HostListener('click')
    public onClick(): void {
        this.openConfirmationModal();
    }

    @Input({ required: true })
    public assetUID!: string;

    /**
     * @description List of asset uids that are selected (are in client.propertiesUIDs, client.societiesUIDs, ...)
     * @author ANDRE Felix
     * @type {string}
     * @memberof ShareToProCardComponent
     */
    @Input() selectedUIDs: string[] = [];

    @Input({ required: true }) title!: string;
    @Input({ required: true }) subTitle!: string;

    /**
     * @description Photo of the asset
     * @author ANDRE Felix
     * @type {string}
     * @memberof ShareToProCardComponent
     */
    @Input() photo?: string;

    @Input() proLogo?: string;

    /**
     * @description Asset type of this card
     * @author ANDRE Felix
     * @type {AllAssetTypeName}
     * @memberof ShareToProCardComponent
     */
    @Input({ required: true }) assetType: AllAssetTypeName = AllAssetTypeName.property;

    @Output()
    public selectedChange = new EventEmitter<boolean>();

    defaultIcon = 'home';

    public selected: boolean = false;

    /**
     * @description Text to display in modal
     * @author ANDRE Felix
     * @private
     * @memberof ShareToProCardComponent
     */
    private modalMessage = {
        share: {
            info: 'Vous êtes sur le point de partager à un pro',
            message:
                "Vous certifiez être seul(e) propriétaire/titulaire ou avoir reçu l'accord des co-propriétaires/co-titulaires avant de partager ces éléments à votre Pro de confiance ?",
            title: 'Partager à un pro',
        },
        unShare: {
            info: '',
            message: 'Vous êtes sur le point de ne plus partager à un pro.',
            title: 'Ne plus partager à un pro',
        },
    };

    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        private modalController: ModalController
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.setSelected();
        this.setDefaultIcon();
    }

    setSelected() {
        this.selected = this.selectedUIDs.includes(this.assetUID);
        if (this.selected) {
            this.elementRef.nativeElement.classList.add('selected');
            return;
        }
        this.elementRef.nativeElement.classList.remove('selected');
    }

    private async openConfirmationModal() {
        const message = this.selected
            ? this.modalMessage.unShare.message
            : this.modalMessage.share.message;
        const information = this.selected
            ? this.modalMessage.unShare.info
            : this.modalMessage.share.info;
        const title = this.selected
            ? this.modalMessage.unShare.title
            : this.modalMessage.share.title;

        const modal = await this.modalController.create({
            component: ValidationModalComponent,
            canDismiss: true,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            backdropDismiss: true,
            componentProps: {
                title,
                information,
                message,
                iconInfo: '',
                validateButtonMessage: 'Oui',
                cancelButtonMessage: 'Non',
            },
        });
        await modal.present();

        modal.onDidDismiss().then((res) => {
            const confirmation = res.data;
            if (confirmation) {
                this.selectedChange.emit(!this.selected);
            }
        });
    }

    private setDefaultIcon() {
        switch (this.assetType) {
            case AllAssetTypeName.property:
                this.defaultIcon = 'home';
                break;
            case AllAssetTypeName.building:
                this.defaultIcon = 'building';
                break;
            case AllAssetTypeName.society:
                this.defaultIcon = 'suitcase';
                break;
            case AllAssetTypeName.saving:
                this.defaultIcon = 'wallet';
                break;
            case AllAssetTypeName.loan:
                this.defaultIcon = 'euro-circle';
        }
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ProEntity,
    SubscriptionEntity,
    SubscriptionRank,
    UserEntity,
    UserFamilyInvitation,
} from '@omedom/data';
import { ProService, SubscriptionService, UserService } from '@omedom/services';
import { Timestamp } from 'firebase-admin/firestore';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'omedom-subscription-card-trial',
    templateUrl: './subscription-card-trial.component.html',
    styleUrls: ['./subscription-card-trial.component.scss'],
})
export class SubscriptionCardTrialComponent implements OnInit {
    /**
     * @description Subscription of the user
     * @author Jérémie Lopez
     * @type {SubscriptionEntity}
     * @memberof SubscriptionCardTrialComponent
     */
    public subscription?: SubscriptionEntity;

    /**
     * @description User of the application
     * @author Jérémie Lopez
     * @type {UserEntity}
     * @memberof SubscriptionCardTrialComponent
     */
    public user!: UserEntity;

    /**
     * @description Date of the day (today) in the application (used to calculate the number of days remaining before the end of the trial period)
     * @author Jérémie Lopez
     * @memberof SubscriptionCardTrialComponent
     */
    public now = new Date();

    /**
     * @description Display the price of the subscription (true) or not (false) in the component (used to display the price in the subscription card component but not in the subscription card trial component for example) (default: false) (optional)
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof SubscriptionCardTrialComponent
     */
    @Input()
    public displayPrice: boolean = false;

    /**
     * @description Pro in the subscription if exist
     * @author Jérémie Lopez
     * @type {ProEntity}
     * @memberof SubscriptionCardTrialComponent
     */
    public pro?: ProEntity;

    /**
     * @description Loading of the component (true) or not (false) (default: true) (optional)
     * @author Jérémie Lopez
     * @memberof SubscriptionCardTrialComponent
     */
    public ready$ = new BehaviorSubject<boolean>(false);

    @Input() familyInvitations!: UserFamilyInvitation[];

    @Output() manageSubscription: EventEmitter<void> = new EventEmitter<void>();

    @Output() manageFamily: EventEmitter<void> = new EventEmitter<void>();

    public trialPeriod = '2 mois';

    constructor(
        private subscriptionService: SubscriptionService,
        private userService: UserService,
        private proService: ProService
    ) {}

    ngOnInit(): void {
        this.userService.user$.subscribe((user) => {
            this.user = user;
            this._getSubscription();
        });
    }

    /**
     * @description Get the subscription
     * @author Jérémie Lopez
     * @private
     * @memberof SubscriptionCardTrialComponent
     */
    private _getSubscription(): void {
        // Init loading
        this.ready$.next(false);

        // Get the subscription of the user
        this.subscriptionService
            .getSubscriptionByUser(this.user.uid)
            .subscribe(async (subscription) => {
                this.subscription = subscription;

                // Calculate the number of months between the creation of the subscription and the renew date
                const subscriptionCreated = (
                    this.subscription.created as any as Timestamp
                ).toDate();

                // Get the renew date of the subscription
                const renewDateArray: string[] = this.subscription.renewDate.split('/');

                // Get the date, month and year of the renew date
                const day = Number.parseInt(renewDateArray[0], 10);
                const month = Number.parseInt(renewDateArray[1], 10);
                const year = Number.parseInt(renewDateArray[2], 10);

                // Create a date object with the renew date
                const renewDateDate = new Date(year, month - 1, day);

                // Get the difference between the creation of the subscription and the renew date
                const diff = Math.abs(renewDateDate.getTime() - subscriptionCreated.getTime());

                // Get the number of months between the creation of the subscription and the renew date
                const months = Math.ceil(diff / (1000 * 3600 * 24 * 30));
                const days = Math.ceil(diff / (1000 * 3600 * 24));

                // Set the trial period of the subscription
                if (months > 1 || days > 28) {
                    this.trialPeriod = months + ' mois';
                } else {
                    this.trialPeriod = days + ' jours';
                }

                // Get the pro of the subscription if exist
                if (this.subscription.proUID) {
                    this.pro = await this.proService.get(this.subscription.proUID);
                }

                // Stop loading
                this.ready$.next(true);
            });
    }

    public get subscriptionType(): string {
        if (this.subscription?.subscriptionType === 'MONTHLY') {
            return 'Abonnement mensuel';
        } else {
            return 'Abonnement annuel';
        }
    }

    /**
     * @description Get the number of days remaining before the end of the trial period (used to display the number of days remaining before the end of the trial period in the subscription card trial component) (default: 0) (optional)
     * @author Jérémie Lopez
     * @readonly
     * @type {number}
     * @memberof SubscriptionCardTrialComponent
     */
    public get count(): number {
        if (!this.subscription) {
            return 0;
        }
        const day = Number.parseInt(this.subscription.renewDate.split('/')[0], 10);
        const month = Number.parseInt(this.subscription.renewDate.split('/')[1], 10);
        const year = Number.parseInt(this.subscription.renewDate.split('/')[2], 10);
        const renewDate = new Date(year, month - 1, day);
        const diff = Math.abs(renewDate.getTime() - this.now.getTime());
        return Math.ceil(diff / (1000 * 3600 * 24));
    }

    public manageSub(): void {
        this.manageSubscription.emit();
    }

    public manageFam(): void {
        this.manageFamily.emit();
    }

    protected readonly SubscriptionRank = SubscriptionRank;
}

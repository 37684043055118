<omedom-document-list
  [isPropertyTab]="true"
  [documents]="documents"
></omedom-document-list>

@if (!documents || documents.length === 0) {
  <omedom-create-first-item-button
    buttonIcon="uil-file"
    message="Ajouter votre 1er document"
    (buttonClicked)="goToDocumentForm()"
  ></omedom-create-first-item-button>
} @else {
  @if (editable) {
    <div class="omedom-sticky-button-container w-100 top-100">
      <button
        class="omedom-button-primary w-100"
        (click)="goToDocumentForm()"
        >
        <i class="uil uil-file-plus-alt"></i>
        Ajouter un document
      </button>
    </div>
  }
}


import { Component, Input } from '@angular/core';
import { RentabilityTypeEnum } from '@omedom/data';

@Component({
    selector: 'omedom-widget-rentability',
    templateUrl: './widget-rentability.component.html',
    styleUrls: ['./widget-rentability.component.scss'],
})
export class WidgetRentabilityComponent {
    @Input({ required: true }) value!: number;

    @Input({ required: true }) type!: RentabilityTypeEnum;

    @Input({ required: true }) isProperty!: boolean;

    protected rentabilityTypeEnum = RentabilityTypeEnum;
}

<omedom-document-search
    [filterData]="filterData"
    [sortData]="sortData"
    [isPropertyTab]="isPropertyTab"
    [properties]="properties"
    [societies]="societies"
    [addButtonInSearchBar]="addButtonInSearchBar && addButton"
    [DocumentTypeUsed]="DocumentTypeUsed"
    [isTypeFree]="isTypeFree"
    (sort)="sort($event)"
    (filter)="filter($event)"
    (search)="search($event)"
    (add)="goToDocumentForm()"
></omedom-document-search>
<omedom-storage-progress [user$]="user$"></omedom-storage-progress>

<div class="list" [@list]="filteredDocuments.length">
    @if (displayAddButtonInList && documents.length) {
    <div
        class="form-document"
        [@element]="documents.length"
        (click)="goToDocumentForm()"
    >
        <i class="uil uil-plus"></i>
    </div>
    } @for (document of filteredDocuments; track document.uid) {
    <omedom-document-card
        (click)="documentClicked(document.uid)"
        [document]="document"
    ></omedom-document-card>
    }
</div>

@if (!documents || documents.length === 0) { @if (roleState.create) {
<omedom-create-first-item-button
    buttonIcon="uil-file"
    message="Ajouter votre 1er document"
    (buttonClicked)="goToDocumentForm()"
></omedom-create-first-item-button>
} } @else { @if (!addButtonInSearchBar && addButton) {
<div class="omedom-sticky-button-container w-100 top-100">
    <button class="omedom-button-primary w-100" (click)="goToDocumentForm()">
        <i class="uil uil-file-plus-alt"></i>
        Ajouter un document
    </button>
</div>
} }

<div class="data-table">
  <div class="item">
    <strong>{{ used }}</strong> Activées
  </div>
  <div class="item">
    <strong>{{ sended }}</strong> En attente d'activation
  </div>
  <div class="item">
    <strong>{{ available }}</strong> Disponibles
  </div>
  <div class="item">
    Total <strong>{{ total }}</strong>
  </div>
</div>

@if (displayOrderButton) {
  <div class="action">
    <button class="omedom-button-primary" (click)="order.emit()">
      <i class="uil uil-tag-alt"></i>
      Commander
    </button>
  </div>
}

import { EventEmitter, Injectable, OnDestroy, OnInit, Output, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
    CategoryInfo,
    ChargeEntity,
    ChargeListModel,
    ChargePeriodicity,
    ChargeUpdateType,
    IncomeEntity,
    IncomeListModel,
    IncomePeriodicity,
    IncomeUpdateType,
    OmedomDateType,
    PeriodicityInfo,
    PropertyEntity,
    SocietyEntity,
    Story,
    TreasuryListModel,
    UserEntity,
} from '@omedom/data';
import { PropertyService, SocietyService, UserService } from '@omedom/services';
import { OmedomStory, OmedomTreasury } from '@omedom/utils';
import { combineLatest, Subscription } from 'rxjs';

import { ChargeEditTypeComponent, IncomeEditTypeComponent } from '../components';

/* eslint-disable @angular-eslint/contextual-lifecycle */
@Injectable()
export abstract class TreasuryList<
    TEntity extends ChargeEntity | IncomeEntity,
    TCategory,
    TPeriodicity
> implements OnInit, OnDestroy
{
    treasuryByDay: {
        day: Date;
        treasury: TreasuryListModel<TCategory, TPeriodicity>[];
    }[] = [];

    get emptyTreasury(): boolean {
        return (
            this.treasuryByDay.length === 0 ||
            this.treasuryByDay.every((x) => x.treasury.length === 0)
        );
    }

    now = new Date();

    currentDate?: Date;

    omedomDateType = OmedomDateType;

    editable = true;

    notes?: string;

    protected propertyUid?: string;

    protected societyUid?: string;

    private subscription?: Subscription;

    private userSubscription?: Subscription;

    protected properties: PropertyEntity[] = [];

    protected societies: SocietyEntity[] = [];

    private storyModal?: HTMLIonModalElement;

    user?: UserEntity;

    @Output() editClickedEvent = new EventEmitter<Story>();

    protected constructor(
        protected userService: UserService,
        protected propertyService: PropertyService,
        private activatedRoute: ActivatedRoute,
        protected modalController: ModalController,
        private router: Router,
        protected societyService: SocietyService
    ) {}

    ngOnInit(): void {
        const user$ = this.userService.user$;
        this.userSubscription = user$.subscribe((user) => {
            this.user = user;
            const properties$ = this.propertyService._getUserPropertiesAndSharedAccessible(
                this.user.uid
            );
            const societies$ = this.societyService._getUserSocietiesAndShared(this.user.uid);
            this.subscription = combineLatest([
                this.activatedRoute.paramMap,
                this.activatedRoute.fragment,
                this.activatedRoute.queryParams,
                properties$,
                societies$,
            ]).subscribe(async ([paramMap, fragment, queryParams, properties, societies]) => {
                const date = paramMap.get('date') ?? queryParams['date'];
                const currentDate = date ? new Date(date ?? 0) : new Date();
                const property = paramMap.get('propertyUid');
                const society = paramMap.get('societyUid');
                const editable = fragment === 'view';

                this.currentDate = currentDate;
                this.propertyUid = property ?? undefined;
                this.societyUid = society ?? undefined;
                this.properties = properties ?? [];
                this.societies = societies ?? [];
                this.editable = !editable;

                this.updateData();
            });
        });
    }

    async ionViewWillEnter(): Promise<void> {
        if (this.user) {
            await this.updateData(); // fix refreshing of list
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
        this.userSubscription?.unsubscribe();
    }

    abstract loadTreasury(userUid: string): Promise<TEntity[]>;

    abstract getCategoryInfo(category: string): CategoryInfo<TCategory>;

    abstract getPeriodicityInfo(periodicity: string): PeriodicityInfo<TPeriodicity>;

    async dateChange(date: Date): Promise<void> {
        this.currentDate = date;
        await this.updateData();
    }

    protected async showDeleteComponent<T>(
        type: Type<T>,
        // treasuryListModel: TreasuryListModel<TCategory, TPeriodicity>
        treasuryListModel: ChargeListModel | IncomeListModel
    ): Promise<HTMLIonModalElement> {
        const modal = await this.modalController.create({
            component: type,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
            componentProps: {
                treasuryListModel,
                label: 't',
            },
        });

        await modal.present();

        modal.onDidDismiss().then(() => {
            this.updateData();
            this.modalController.dismiss();
        });
        return modal;
    }

    protected async showEditComponent<T>(type: Type<T>): Promise<HTMLIonModalElement> {
        const modal = await this.modalController.create({
            component: type,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
        });

        await modal.present();

        return modal;
    }

    protected async updateData(): Promise<void> {
        if (!this.user) {
            return;
        }

        const allEntities = await this.loadTreasury(this.user.uid);
        const entities = allEntities.filter((x) => !x.isDeleted);
        const startDate = this.currentDate?.getUTCFirstDayOfMonth();
        const endDate = this.currentDate?.getUTCLastDayOfMonth();
        if (!startDate || !endDate) {
            return;
        }
        const filteredData = OmedomTreasury.filterTreasury<TEntity>(
            entities,
            startDate.getUTCDateWithoutTime(),
            endDate.getUTCDateWithoutTime()
        );
        const propertiesTreasuryByMonth = this.getMonthPropertyTreasury(
            filteredData,
            startDate,
            endDate
        );
        const societyTreasuryByMonth = await this.getMonthSocietyTreasury(
            filteredData,
            startDate,
            endDate
        );
        const treasuryByDay = [...propertiesTreasuryByMonth, ...societyTreasuryByMonth].reduce(
            (
                tbt: {
                    [key: string]: TreasuryListModel<TCategory, TPeriodicity>[];
                },
                treasury
            ) => ({
                ...tbt,
                [treasury.date.toString()]: [...(tbt[treasury.date.toString()] || []), treasury],
            }),
            {}
        );

        const sortedTreasuryByDay = Object.keys(treasuryByDay).sort(
            (a, b) => new Date(a).getTime() - new Date(b).getTime()
        );

        this.treasuryByDay = sortedTreasuryByDay.map((date) => ({
            day: new Date(date),
            treasury: treasuryByDay[date],
        }));
    }

    private getMonthPropertyTreasury(
        entities: TEntity[],
        startDate: Date,
        endDate: Date
    ): TreasuryListModel<TCategory, TPeriodicity>[] {
        const treasuryByMonth: TreasuryListModel<TCategory, TPeriodicity>[] = [];
        entities.forEach((entity) => {
            const property = this.properties.find((x) => x.uid === entity.propertyUID);
            if (!property) {
                return;
            }

            const treasury = this.transformEntityInTreasury(entity, property, startDate, endDate);
            treasuryByMonth.push(...treasury);
        });
        return treasuryByMonth;
    }

    private async getMonthSocietyTreasury(
        entities: TEntity[],
        startDate: Date,
        endDate: Date
    ): Promise<TreasuryListModel<TCategory, TPeriodicity>[]> {
        const treasuryByMonth: TreasuryListModel<TCategory, TPeriodicity>[] = [];

        const promises = entities.map(async (entity) => {
            if (!entity.societyUID) {
                return;
            }
            const society = await this.societyService.get(entity.societyUID);

            if (!society) {
                return;
            }

            const treasury = this.transformEntityInTreasury(entity, society, startDate, endDate);

            treasuryByMonth.push(...treasury);
        });

        await Promise.all(promises);

        return treasuryByMonth;
    }

    /**
     * @description Transform a charge or income in treasuryByMonth
     * @author ANDRE Felix
     * @private
     * @param {(ChargeEntity | IncomeEntity)} entity
     * @param {(PropertyEntity | SocietyEntity)} asset
     * @param {Date} startDate
     * @param {Date} endDate
     * @returns {*}
     * @memberof TreasuryList
     */
    private transformEntityInTreasury(
        entity: ChargeEntity | IncomeEntity,
        asset: PropertyEntity | SocietyEntity,
        startDate: Date,
        endDate: Date
    ): TreasuryListModel<TCategory, TPeriodicity>[] {
        const history = entity.history?.filter((x) => x.date.toDate().between(startDate, endDate));

        const futurepayments = entity.futurPayment?.filter(
            (futurPayment) =>
                (futurPayment.date.toDate().getFirstDayOfMonth().getTime() <=
                    startDate.getFirstDayOfMonth().getTime() &&
                    futurPayment.isForNext) ||
                (futurPayment.date.toDate().between(startDate, endDate) && !futurPayment.isForNext)
        );

        const commonTreasury = {
            categoryInfo: this.getCategoryInfo(entity.category),
            uid: entity.uid,
            periodicityInfo: this.getPeriodicityInfo(entity.periodicity),
            designation: entity.designation,
            propertyName: asset.name,
            propertyImg: asset.photo,
            propertyOwnerUID: asset.userUID,
            propertyUID: asset.uid,
            withNotif: true,
            userUID: entity.userUID,
            notes: OmedomTreasury.getMonthNotes(entity, startDate),
        };

        if (history?.length) {
            const treasury: TreasuryListModel<TCategory, TPeriodicity>[] = history.map(
                (history) =>
                    ({
                        ...commonTreasury,
                        categoryInfo: this.getCategoryInfo(entity.category),
                        amount: Math.abs(history.amount),
                        date: history.date.toDate(),
                        isPayed: history.isPayed,
                    } as TreasuryListModel<TCategory, TPeriodicity>)
            );
            return treasury;
        }
        if (futurepayments?.length) {
            const treasury = futurepayments.slice(-1).map(
                (h) =>
                    ({
                        ...commonTreasury,
                        amount: Math.abs(h.amount),
                        date:
                            entity.periodicity === 'punctual'
                                ? entity.debitDate?.toDate()
                                : OmedomTreasury.getMonthHistoryDate(entity, startDate.toUTC()),
                        isPayed: false,
                    } as TreasuryListModel<TCategory, TPeriodicity>)
            );
            return treasury;
        }

        const treasury = [
            {
                ...commonTreasury,
                amount: OmedomTreasury.getMonthAmount(entity, startDate),
                date:
                    entity.periodicity === 'punctual'
                        ? entity.debitDate?.toDate()
                        : OmedomTreasury.getMonthHistoryDate(entity, startDate.toUTC()),
                isPayed: OmedomTreasury.isTreasuryPayed(entity, startDate, endDate),
            } as TreasuryListModel<TCategory, TPeriodicity>,
        ];
        return treasury;
    }

    /**
     * @description Open a modal for a Story
     * @author ANDRE Felix
     * @protected
     * @template T
     * @param {Type<T>} type
     * @param {Story} stories
     * @returns {*}  {Promise<HTMLIonModalElement>}
     * @memberof TreasuryList
     */
    protected async showStoryModal<T>(type: Type<T>, story: Story): Promise<HTMLIonModalElement> {
        const treasuryByDayFlat = this.treasuryByDay.flatMap((x) => x.treasury);
        const selectedIndex = treasuryByDayFlat.findIndex((x) => x.uid === story.uid);
        const isCharge = story.isCharge;

        const storiesOfTreasury = treasuryByDayFlat.map((x) =>
            OmedomStory.transformTreasuryModelListToStory(
                x as ChargeListModel | IncomeListModel,
                isCharge
            )
        );

        this.storyModal = await this.modalController.create({
            component: type,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
            componentProps: {
                stories: [...storiesOfTreasury],
                selectedIndex: selectedIndex,
                canNavigate: true,
            },
        });

        await this.storyModal.present();

        return this.storyModal;
    }

    async editClicked(treasury: Story): Promise<void> {
        this.editClickedEvent.emit(treasury);
        const treasuryString = treasury.isCharge ? 'charge' : 'income';
        const tresuryUpdateType = treasury.isCharge ? ChargeUpdateType : IncomeUpdateType;
        const treasuryPeriodicity = treasury.isCharge ? ChargePeriodicity : IncomePeriodicity;
        const isPunctual = treasury.periodicityInfo.periodicity === treasuryPeriodicity.punctual;
        if (isPunctual) {
            await this.modalController.dismiss();
            await this.modalController.dismiss({
                updateType: tresuryUpdateType.thisOneAndNext,
                treasuryUid: treasury.uid,
                currentDate: new Date(treasury.date),
                isCharge: treasury.isCharge,
            });
            return;
        }
        let modal: HTMLIonModalElement;
        if (treasury.isCharge) {
            modal = await this.showEditComponent(ChargeEditTypeComponent);
        } else {
            modal = await this.showEditComponent(IncomeEditTypeComponent);
        }

        await modal.present();
        modal.onDidDismiss().then(async (x) => {
            if (!x.data) {
                return;
            }

            if (this.propertyUid) {
                this.router.navigate([
                    `/tabs/property/info/${this.propertyUid}/${treasuryString}/edit/${treasury.uid}/${x.data}/${treasury.date}`,
                ]);
            } else {
                this.router.navigate([
                    `/tabs/treasury/${treasuryString}/edit/${treasury.uid}/${x.data}/${treasury.date}`,
                ]);
            }

            // Close all modal
            await this.modalController.dismiss({
                updateType: x.data,
                treasuryUid: treasury.uid,
                currentDate: new Date(treasury.date),
                isCharge: treasury.isCharge,
            });
        });
    }
    // async deleteClicked(treasury: Story) {}
}

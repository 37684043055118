import { Component, Input } from '@angular/core';

@Component({
    selector: 'omedom-form-section-title',
    templateUrl: './form-section-title.component.html',
    styleUrls: ['./form-section-title.component.scss'],
})
export class FormSectionTitleComponent {
    /**
     * @description Title of Form Section
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {string}
     * @memberof FormSectionTitleComponent
     */
    @Input() title?: string;
}

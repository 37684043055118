/**
 * @description The type of multi factor authentication required for the item in Bridge
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 05/03/2024
 * @export
 * @enum {number}
 */
export enum BankItemMFAType {
    /**
     * @description The multi factor authentication is a SMS code
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     */
    SMS = 'SMS',

    /**
     * @description The multi factor authentication is a token code given by the user's bank app
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     */
    AppToApp = 'APP_TO_APP',
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'omedom-story-footer',
    templateUrl: './story-footer.component.html',
    styleUrls: ['./story-footer.component.scss'],
})
export class StoryFooterComponent {
    @Input() isCharge = true;
    @Input() isDateBeforeToday = false;
    @Input() isBankingMovment = false;
    @Input() canUpdateStory = true;
    @Input() isWaitingData = true;

    @Output() storyValidateClick = new EventEmitter();
    @Output() storyNotValidateClick = new EventEmitter();
}

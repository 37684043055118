/**
 * @description StoryParams interface for story object options
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 17/11/2023
 * @export
 * @interface StoryOptionParams
 */
export interface StoryOptionParams {
    payedTreasury: boolean; // includes payed treasury
    notPayedTreasury: boolean; // includes not payed treasury
    futureStories: boolean; // includes future stories
    withNotificationOnly: boolean; // only stories with notification
}

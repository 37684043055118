<div class="modal-content modal">
    <div class="content">
        <div class="header">
            <span> Informations du locataire </span>
            <i class="uil uil-times-circle" (click)="dismiss()"> </i>
        </div>
        @if (tenantForm) {
        <form [formGroup]="tenantForm" class="w-100 flex-1">
            <omedom-input
                [required]="true"
                formControlName="lastname"
                name="lastname"
                placeholder="Nom"
                icon="uil uil-user-circle"
                label="Nom"
            >
            </omedom-input>

            <omedom-input
                [required]="true"
                formControlName="firstname"
                name="firstname"
                placeholder="Prénom"
                icon="uil uil-user-circle"
                label="Prénom"
                class="margin-top-2"
            >
            </omedom-input>

            <omedom-input
                [required]="true"
                type="date"
                formControlName="entryDate"
                name="entryDate"
                placeholder="jj/mm/aaaa"
                icon="uil uil-calendar-alt"
                label="Date d'entrée du locataire"
                class="margin-top-2"
            >
            </omedom-input>

            <omedom-input
                type="date"
                formControlName="exitDate"
                name="exitDate"
                placeholder="jj/mm/aaaa"
                icon="uil uil-calendar-alt"
                label="Date de sortie du locataire"
                class="margin-top-2"
            >
            </omedom-input>

            <omedom-input
                type="email"
                formControlName="email"
                name="email"
                [pattern]="emailRegex"
                placeholder="E-mail"
                icon="uil uil-envelope"
                label="E-mail"
                class="margin-top-2"
            >
            </omedom-input>

            <omedom-input
                type="tel"
                formControlName="phone"
                name="phone"
                [pattern]="phoneRegex"
                placeholder="+33"
                icon="uil uil-phone-volume"
                label="Téléphone"
                class="margin-top-2"
            >
            </omedom-input>

            <div
                class="form-collapse"
                (click)="displayGarant.next(!displayGarant.value)"
            >
                <span>
                    <i class="uil-book-reader"> </i>
                    Garant
                </span>
                <i
                    class="uil"
                    [class.uil-angle-down]="!displayGarant.value"
                    [class.uil-angle-up]="displayGarant.value"
                    class="arrow-icon"
                >
                </i>
            </div>
            @if ((displayGarant | async) && tenant.warrantor) {
            <form class="sub-form" formGroupName="warrantor">
                <omedom-input
                    name="warrantorLastname"
                    placeholder="Nom"
                    icon="uil uil-user-circle"
                    label="Nom"
                    formControlName="warrantorLastname"
                >
                </omedom-input>
                <omedom-input
                    name="warrantorFirstname"
                    placeholder="Prénom"
                    icon="uil uil-user-circle"
                    label="Prénom"
                    formControlName="warrantorFirstname"
                    class="margin-top-2"
                >
                </omedom-input>
                <omedom-input
                    type="email"
                    name="warrantorEmail"
                    [pattern]="emailRegex"
                    placeholder="Mail"
                    icon="uil uil-envelope"
                    label="Mail"
                    formControlName="warrantorEmail"
                    class="margin-top-2"
                >
                </omedom-input>
                <omedom-input
                    type="tel"
                    name="warrantorPhone"
                    placeholder="+33"
                    [pattern]="phoneRegex"
                    icon="uil uil-phone-volume"
                    label="Téléphone"
                    formControlName="warrantorPhone"
                    class="margin-top-2"
                >
                </omedom-input>
            </form>
            }

            <div
                class="form-collapse"
                (click)="displayaid.next(!displayaid.value)"
            >
                <span>
                    <i class="uil-money-withdraw"> </i>
                    Aides
                </span>
                <i
                    class="uil"
                    [class.uil-angle-down]="!displayaid.value"
                    [class.uil-angle-up]="displayaid.value"
                    class="arrow-icon"
                >
                </i>
            </div>
            @if (displayaid | async) {
            <div class="sub-form">
                <form #aidForm="ngForm">
                    <omedom-select
                        [(ngModel)]="selectedAid"
                        name="aidIncome"
                        [placeholder]="aidPlaceholder"
                        [options]="aidOptions || []"
                    >
                    </omedom-select>
                </form>

                <!-- <div class="text-center margin-top-2 margin-bottom-2" style="text-align: center;">Ou</div>
          <button class="omedom-button-primary w-100"
            (click)="addAid()">
            <i class="uil uil-plus"></i>
            <span>Ajouter une aide</span>
          </button> -->
            </div>
            }
        </form>
        }
        <div class="omedom-modal-buttons">
            <button class="omedom-button-secondary flex-1" (click)="dismiss()">
                Annuler
            </button>
            <button
                class="omedom-button-green flex-1"
                (click)="saveTenant()"
                [disabled]="tenantFormIsInvalid"
            >
                <i class="uil uil-save"></i>
                <span>Enregistrer</span>
            </button>
        </div>
    </div>
</div>

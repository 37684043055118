<!-- En cas de modifiication de ce code, modifier le code de
./directory-card.brevo.html et  mettre à jour le template 242 de BREVO -->

<div class="container" (click)="onDetailClicked($event)">
    <div class="pro-logo-container">
        <img class="pro-logo" [src]="pro.logo" alt="logo du professionnel" />
    </div>
    <div class="info-container">
        <div>{{ pro.proName }} {{ pro.proFirstName }}</div>
        <div class="text-info-bold">{{ pro.position }}</div>
        <div>{{ pro.name }}</div>
    </div>
    <div class="opinion-container flex-1">
        @if(pro.rate ) {
        <div class="rating margin-right-1">
            <i class="uil uil-star"></i>{{ pro.rate }}
        </div>
        }
        <div class="charter">
            <i class="uil uil-check-circle"></i>Charte éthique
        </div>
    </div>

    <div class="value-container">
        <span class="value">{{ values }}</span>
    </div>
</div>

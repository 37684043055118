/**
 * @description Action that a user can do on a document or a project
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 10/08/2023
 * @export
 * @enum {number}
 */
export enum Action {
    create = 'create',
    read = 'read',
    update = 'update',
    delete = 'delete',
    import = 'import',
    export = 'export',
    share = 'share',
}

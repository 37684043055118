<i class="{{ iconStart ?? 'uil uil-info-circle' }}"></i>
<span [innerHTML]="information" [style.color]="color ? color : null"></span>
@if (hasButton) {
  <button
    (click)="buttonClick.emit()"
    class="omedom-icon-button-primary"
    [routerLink]="routerLink"
    >
    @if (!icon?.startsWith('omedom-icon')) {
      <i class="uil uil-{{ icon }}"></i>
    }
    @if (icon?.startsWith('omedom-icon')) {
      <i class="{{ icon }}"></i>
    }
    {{ buttonLabel }}
  </button>
}

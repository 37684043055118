import { AllChargeCategories, ChargePeriodicity } from '../entities/charge.entity';
import {
    AllIncomeCategories,
    CategoryInfo,
    IncomePeriodicity,
    PeriodicityInfo,
} from '../entities/income.entity';

/**
 * @description Story model
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 02/08/2023
 * @export
 * @class Story
 */
export class Story {
    /**
     * @description Url of the image of the property linked to the story
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {string}
     * @memberof Story
     */
    propertyImg!: string;

    /**
     * @description Name of the property linked to the story
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {string}
     * @memberof Story
     */
    propertyName!: string;

    /**
     * @description UID of the owner of the property of this charge or revenus
     * @author ANDRE Felix
     * @type {string}
     * @memberof Story
     */
    ownerUID?: string;

    /**
     * @description UID of the charge or income linked to the story
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {string}
     * @memberof Story
     */
    uid!: string;

    /**
     * @description Category of the story (rent, charge, etc...). See CategoryInfo interface for more details
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {CategoryInfo<ChargeCategoryProperty | IncomeCategoryProperty>}
     * @memberof Story
     */
    categoryInfo!: CategoryInfo<AllChargeCategories | AllIncomeCategories>;

    /**
     * @description Periodicity of the story (monthly, yearly, etc...). See PeriodicityInfo interface for more details
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {PeriodicityInfo<ChargePeriodicity | IncomePeriodicity>}
     * @memberof Story
     */
    periodicityInfo!: PeriodicityInfo<ChargePeriodicity | IncomePeriodicity>;

    /**
     * @description Amount of the story
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {number}
     * @memberof Story
     */
    amount!: number;

    /**
     * @description Charge of rent if the story is a rent with charges
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {number}
     * @memberof Story
     */
    rentWithCharges?: number;

    /**
     * @description Return true if the treasury is a charge, false if it's an income
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {boolean}
     * @memberof Story
     */
    isCharge!: boolean;

    /**
     * @description Return true if the story is already readed by the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {boolean}
     * @memberof Story
     */
    isReaded!: boolean;

    /**
     * @description Return true if the treasury is already payed or received by the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {boolean}
     * @memberof Story
     */
    isPayed!: boolean;

    /**
     * @description Designation of the treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {string}
     * @memberof Story
     */
    designation!: string;

    /**
     * @description Date of the story
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {Date}
     * @memberof Story
     */
    date!: Date;

    /**
     * @description Notes of the treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {string}
     * @memberof Story
     */
    notes?: string;

    /**
     * @description Return true if the treasury is linked to a banking movement
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {boolean}
     * @memberof Story
     */
    isTransaction?: boolean;

    /**
     * @description Name of the bank account linked to the treasury if it's linked to a banking movement
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {string}
     * @memberof Story
     */
    bankAccountName?: string;

    /**
     * @description Waiting of the user to validate the assigning of the story to a transaction
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {boolean}
     * @memberof Story
     */
    validateAssigning?: boolean;

    /**
     * @description Currency of the treasury (€, $, etc...)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {string}
     * @memberof Story
     */
    currency?: string;

    /**
     * @description Verify if receipt is sent
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {boolean}
     * @memberof Story
     */
    isSentReceipt?: boolean;

    /**
     * @description Verify if relaunch is sent
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {boolean}
     * @memberof Story
     */
    isSentRelaunch?: boolean;

    /**
     * @description Uid of the user linked to the story (owner of the property linked to the story or shared of the property linked to the story)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {string}
     * @memberof Story
     */
    userUID!: string;

    /**
     * @description Story has notification or not
     * @author ANDRE Felix
     * @type {boolean}
     * @memberof Story
     */
    withNotif?: boolean;

    constructor(init?: Partial<Story>) {
        Object.assign(this, init);
    }
}

import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Color } from '@omedom/data';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'omedom-bank-empty',
    templateUrl: './bank-empty.container.html',
    styleUrls: ['./bank-empty.container.scss'],
    animations: [
        trigger('element', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(15px)' }),
                animate('0.25s', style({ opacity: 1, transform: 'translateY(0px)' })),
            ]),
        ]),
    ]
})
export class BankEmptyComponent {
    /**
     * @description Color access for template
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/04/2024
     * @memberof BankEmptyComponent
     */
    public colors = Color;

    /**
     * @description State of the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/04/2024
     * @memberof BankEmptyComponent
     */
    public state$ = new BehaviorSubject<string>('ok');

    /**
     * @description Message to display to the user in the template
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/04/2024
     * @memberof BankEmptyComponent
     */
    public message$ = new BehaviorSubject<string>('Connecter mes banques');

    constructor(
    ) { }
}

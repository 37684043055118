import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'omedom-info',
    templateUrl: './info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class InfoComponent {
    @Input() information?: string;
    @Input() hasButton?: boolean;
    @Input() icon?: string;
    @Input() iconStart?: string;
    @Input() buttonLabel?: string;
    @Input() routerLink?: string;
    @Input() color?: string;
    @Output() buttonClick = new EventEmitter();
}

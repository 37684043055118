<button class="omedom-button-primary margin-bottom-2" (click)="addReview()">
    Ajoutez un avis
</button>
@if(pro.rate || reviews.length) {
<div class="margin-bottom-1">
    Note du pro :
    {{ pro.rate }}
</div>

<div>Nombre d'avis : {{ reviews.length }}</div>
} @else {
<div class="bold">Soyez le premier à donner un avis</div>
}

<hr class="omedom-divider" />
@for(review of reviews; track review.uid) {
<omedom-review-display [review]="review"></omedom-review-display>
}

<div class="omedom-modal">
    <div class="omedom-modal-header">
        <div class="omedom-modal-header-title">
            <div class="header-icon margin-top-1 background-color-light-green">
                <i class="uil uil-check color-light-black"></i>
            </div>
            <h3>{{ title }}</h3>
        </div>
        <i class="uil uil-times-circle" (click)="dismissModal(false)"></i>
    </div>
    @if (message || inconInfo || information) {
    <div class="omedom-modal-content">
        @if(information) {
        <omedom-info
            class="margin-bottom-2 custom-app-info"
            [information]="information"
            [iconStart]="inconInfo"
            color="grey"
        >
        </omedom-info>
        }

        <span class="confirmation-text">
            {{ message }}
        </span>
    </div>
    }
    <div class="omedom-modal-buttons">
        @if (cancelButtonMessage) {
        <button class="omedom-button-secondary" (click)="dismissModal(false)">
            {{ cancelButtonMessage }}
        </button>
        }
        <button class="omedom-button-primary" (click)="dismissModal(true)">
            {{ validateButtonMessage }}
        </button>
    </div>
</div>

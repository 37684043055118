import { BankCapability, BankChannelType, BankCountryCode } from '../../enums';
import { Bridge } from '../../interfaces';
import { RestEntity } from '../rest.entity';

/**
 * @description Bank entity
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 01/03/2024
 * @export
 * @class BankEntity
 * @extends {RestEntity}
 */
export class BankEntity extends RestEntity implements Bridge {
    /**
     * @description ID of the bank in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {number}
     * @memberof BankEntity
     */
    public bridgeID!: number;

    /**
     * @description Name of the bank
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {string}
     * @memberof BankEntity
     * @example Crédit Agricole Alpes Provence
     */
    public name!: string;

    /**
     * @description If the bank is a subsidiary, we have the parent name
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {string}
     * @memberof BankEntity
     * @example Crédit Agricole
     */
    public parentName?: string;

    /**
     * @description Bank's ISO 3166-1 alpha-2 country code
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {string}
     * @memberof BankEntity
     */
    public countryCode: BankCountryCode = BankCountryCode.FR;

    /**
     * @description Logo's URL of the bank
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {string}
     * @memberof BankEntity
     */
    public logoUrl?: string;

    /**
     * @description Deep link for Android to open the bank's app directly from Bridge (if available)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {string}
     * @memberof BankEntity
     */
    public deeplinkAndroid?: string;

    /**
     * @description Deep link for iOS to open the bank's app directly from Bridge (if available)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {string}
     * @memberof BankEntity
     */
    public deeplinkIOS?: string;

    /**
     * @description List of capabilities enabled for the bank with Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {BankCapability[]}
     * @memberof BankEntity
     */
    public capabilities: BankCapability[] = [];

    /**
     * @description List of channel types enabled for the bank with Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {BankChannelType[]}
     * @memberof BankEntity
     */
    public channelType: BankChannelType[] = [];

    constructor(data: Partial<BankEntity>) {
        super(data);

        if (data.capabilities) {
            this.capabilities = data.capabilities as BankCapability[];
        }

        if (data.channelType) {
            this.channelType = data.channelType as BankChannelType[];
        }

        if (data.countryCode) {
            this.countryCode = data.countryCode as BankCountryCode;
        }
    }

    /**
     * @description Check if a capability is enabled for the bank with Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @param {BankCapability} capability
     * @returns {boolean}
     * @memberof BankEntity
     */
    public isCapabilityEnabled(capability: BankCapability): boolean {
        return this.capabilities.includes(capability);
    }

    /**
     * @description Check if a channel type is enabled for the bank with Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @param {BankChannelType} channelType
     * @returns {boolean}
     * @memberof BankEntity
     */
    public isChannelTypeEnabled(channelType: BankChannelType): boolean {
        return this.channelType.includes(channelType);
    }
}

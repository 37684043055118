/**
 * @description AbsoluteRole translate in French
 * @author ANDRE Felix
 * @export
 * @enum {number}
 */
export enum RoleFr {
    editor = 'éditeur',
    admin = 'administrateur',
    owner = 'propriétaire',
    reader = 'lecteur',
    none = 'aucun',
}

import { NotificationActions } from '../enums';
import { RestEntity } from './rest.entity';

/**
 * @description Notification entity
 * @author Jérémie Lopez
 * @export
 * @class NotificationEntity
 * @extends {RestEntity}
 */
export class NotificationEntity extends RestEntity {
    /**
     * @description Title of the notification
     * @author Jérémie Lopez
     * @type {string}
     * @memberof NotificationEntity
     */
    public title!: string;

    /**
     * @description Body of the notification
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 15/12/2023
     * @type {string}
     * @memberof NotificationEntity
     */
    public body!: string;

    /**
     * @description Image of the notification (optional)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 15/12/2023
     * @type {string}
     * @memberof NotificationEntity
     */
    public image?: string;

    /**
     * @description Analytics label of the notification (optional) (for tracking purpose) (ex: "new_message")
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 15/12/2023
     * @type {string}
     * @memberof NotificationEntity
     */
    public analyticsLabel?: string;

    /**
     * @description Actions of the notification (optional) (ex: [{ action: 'open', title: 'Ouvrir' }])
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 15/12/2023
     * @type {{ action: NotificationActions, title: string; }[]}
     * @memberof NotificationEntity
     */
    public actions?: {
        action: NotificationActions;
        title: string;
        params?: {
            [key: string]: string | number | boolean | Date;
        };
    }[];

    /**
     * @description Link the notification to a user
     * @author Jérémie Lopez
     * @type {string}
     * @memberof NotificationEntity
     */
    public userUID!: string;

    /**
     * @description Is the notification read by the user? (default: false)
     * @default false
     * @type {boolean}
     * @memberof NotificationEntity
     */
    public isRead!: boolean;

    /**
     * @description Data of the notification (optional) (ex: { type: 'message', id: '123' }) (for tracking purpose) (ex: "new_message")
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/01/2024
     * @type {{ [key: string]: string; }}
     * @memberof NotificationEntity
     */
    public override data?: { [key: string]: string; };

    /**
     * @description Date to send the notification (optional) (default: now)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/08/2024
     * @type {Date}
     * @memberof NotificationEntity
     */
    public dateToSend?: Date;

    /**
     * @description Is the notification sent? (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/08/2024
     * @type {boolean}
     * @memberof NotificationEntity
     */
    public sent?: boolean = false;

    constructor(data?: Partial<NotificationEntity>) {
        super(data);

        // Check type of dateToSend
        if (this.dateToSend && !(this.dateToSend instanceof Date)) {
            this.dateToSend = new Date((this.dateToSend as any).seconds * 1000);
        }
    }
}

import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'omedom-button-toggle',
    templateUrl: './button-toggle.component.html',
    styleUrls: ['./button-toggle.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ButtonToggleComponent),
            multi: true,
        },
    ],
})
export class ButtonToggleComponent<T> implements ControlValueAccessor {
    /**
     * @description Options to display
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/05/2024
     * @type {{ value: T; label: string; icon?: string }[]}
     * @memberof ButtonToggleComponent
     */
    @Input({ required: true })
    public options: { value: T; label: string; icon?: string; }[] = [];

    /**
     * @description Value selected by default (if any) or undefined if none selected
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/05/2024
     * @type {T}
     * @memberof ButtonToggleComponent
     */
    @Input()
    public value?: T;

    /**
     * @description Whether the button is disabled or not. Default is false. If disabled, the button will not trigger any event when clicked. The button will also have a different style to indicate that it is disabled. This can be overridden using the disabled property in the button's style object
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/05/2024
     * @type {boolean}
     * @memberof ButtonToggleComponent
     */
    @Input()
    public disabled: boolean = false;

    /**
     * @description Callback function to call when the value changes. This is called by the ControlValueAccessor when the value changes. This is used to update the form model (if used inside a form) when the value changes. This is also used to update the value of the button when the form model changes. This is a required method to implement the ControlValueAccessor interface. This is called by the ControlValueAccessor when the value changes. This is used to update the form model (if used inside a form) when the value changes. This is also used to update the value of the button when the form model changes. This is a required method to implement the ControlValueAccessor interface
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/05/2024
     * @private
     * @param {T} value
     * @type {Function}
     * @memberof ButtonToggleComponent
     */
    private onChange: Function = (value: T) => { };

    /**
     * @description Callback function to call when the button is touched. This is called by the ControlValueAccessor when the button is touched. This is used to update the form model (if used inside a form) when the button is touched. This is a required method to implement the ControlValueAccessor interface. This is called by the ControlValueAccessor when the button is touched. This is used to update the form model (if used inside a form) when the button is touched. This is a required method to implement the ControlValueAccessor interface
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/05/2024
     * @private
     * @type {Function}
     * @memberof ButtonToggleComponent
     */
    private onTouched: Function = () => { };

    writeValue(value: T): void {
        this.value = value;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * @description Toggle the value of the button. This is called when the button is clicked.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/05/2024
     * @param {T} value
     * @returns {*}  {void}
     * @memberof ButtonToggleComponent
     */
    public toggleValue(value: T): void {
        // If the button is disabled, do nothing
        if (this.disabled) {
            return;
        }

        // If the button is already selected, unselect it
        if (this.value === value) {
            this.value = undefined;
            this.onChange(undefined);
            this.onTouched();
            return;
        } else {
            // Else, select the button
            this.value = value;
            this.onChange(value);
            this.onTouched();
        }
    }
}

import { BankStatusCode } from '../../enums';

/**
 * @description Error message from the bank's API when the status code is different from 0 (BankStatusCode.OK)
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 22/04/2024
 * @export
 * @interface BankErrorMessage
 */
export interface BankErrorMessage {
    status: BankStatusCode;
    message: string;
    actionEnabled: boolean;
    actionMessage?: string;
}

import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { NavController } from '@ionic/angular';
import { EventNotification, events, NotificationEventName, UserEntity } from '@omedom/data';
import { NotificationService, SmartService, UserService } from '@omedom/services';
import { Subscription } from 'rxjs';

import { elementAnimation, listAnimation } from '../../animations';

@Component({
    selector: 'omedom-menu-settings',
    templateUrl: './menu-settings.component.html',
    styleUrls: ['./menu-settings.component.scss'],
    animations: [listAnimation, elementAnimation],
})
export class MenuSettingsComponent implements OnChanges, OnDestroy {
    /**
     * @description User data
     * @author Jérémie Lopez
     * @type {UserEntity}
     * @memberof MenuSettingsComponent
     */
    @Input() public user?: UserEntity;

    /**
     * @description Has smart account or not
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof MenuSettingsComponent
     */
    public hasSmart?: boolean;

    /**
     * @description List of subscriptions to delete after destroy component
     * @author Jérémie Lopez
     * @private
     * @type {Subscription[]}
     * @memberof MenuSettingsComponent
     */
    private subscriptions: Subscription[] = [];

    /**
     * @description List of events to display in the notification settings page
     * @author Jérémie Lopez
     * @memberof MenuSettingsComponent
     */
    public events = events;

    constructor(
        private userService: UserService,
        private navController: NavController,
        private smartService: SmartService,
        private notificationService: NotificationService
    ) {}

    ngOnChanges(): void {
        if (!this.user) {
            console.error('No user found');
            return;
        }

        // Create the notification event list if didn't exist
        if (!this.user.notificationsEvent) {
            this.user.notificationsEvent = [];
        }
        if (!this.user.notificationsEnabled) {
            this.user.notificationsEnabled = false;
        }
        if (!this.user.notificationPushEnabled) {
            this.user.notificationPushEnabled = true;
        }
        if (!this.user.notificationEmailEnabled) {
            this.user.notificationEmailEnabled = true;
        }

        if (this.user.notificationsEnabled && this.user.notificationPushEnabled) {
            this.notificationService.initPush();
        }

        const smart$ = this.smartService.hasSmart$.subscribe((smart) => {
            this.hasSmart = smart;
        });
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions.push(smart$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * @description Update user data
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof MenuSettingsComponent
     */
    public async update(): Promise<void> {
        if (!this.user) {
            console.error('No user found');
            return;
        }
        await this.userService.update(this.user);
    }

    /**
     * @description Toggle Event Notification
     * @author Jérémie Lopez
     * @param {string} title
     * @param {('push' | 'email')} type
     * @return {*}  {Promise<void>}
     * @memberof MenuSettingsComponent
     */
    public async toggleEvent(
        name: NotificationEventName | undefined,
        title: string,
        type: 'push' | 'email'
    ): Promise<void> {
        if (!this.user || !name) {
            console.error('No user found');
            return;
        }
        // Seaerch the notification event
        const index = this.user.notificationsEvent?.findIndex((element) => element.name === name);

        // Apply toggle to the event or create event
        if (index && index !== -1 && this.user.notificationsEvent) {
            this.user.notificationsEvent[index].email =
                type === 'email'
                    ? !this.user.notificationsEvent[index].email
                    : this.user.notificationsEvent[index].email;

            this.user.notificationsEvent[index].push =
                type === 'push'
                    ? !this.user.notificationsEvent[index].push
                    : this.user.notificationsEvent[index].push;
        } else {
            const eventNotification: EventNotification = {
                name,
                title,
                email: type === 'email',
                push: type === 'push',
            };
            this.user.notificationsEvent?.push(eventNotification);
        }

        await this.update();
    }

    /**
     * @description Get event notification
     * @author Jérémie Lopez
     * @param {string} title
     * @return {*}  {(EventNotification | undefined)}
     * @memberof MenuSettingsComponent
     */
    public getEventNotification(
        name: NotificationEventName | undefined,
        type: 'push' | 'email'
    ): boolean {
        if (!this.user || !name) {
            return false;
        }
        // Seaerch the notification event
        if (!this.user.notificationsEvent) {
            console.error('No notification event found');
            this.user.notificationsEvent = [];
        }
        const index = this.user.notificationsEvent.findIndex((element) => element.name === name);

        // Apply toggle to the event or create event
        if (index !== -1) {
            return type === 'email'
                ? this.user.notificationsEvent[index].email
                : this.user.notificationsEvent[index].push;
        } else {
            return false;
        }
    }

    /**
     * @description Navigate back
     * @author Jérémie Lopez
     * @memberof MenuSettingsComponent
     */
    public back(): void {
        this.navController.pop();
    }
}

import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@omedom/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'isAuthorised',
})
@Injectable({ providedIn: 'root' })
export class OmedomIsAuthorisedPipe implements PipeTransform {
    constructor(private configurationService: ConfigurationService) {}

    transform(accessValue: string): Observable<boolean> {
        return this.configurationService.getUserConfiguration().pipe(
            map((config) => {
                return config.canAccess?.includes(accessValue) || false;
            })
        );
    }
}

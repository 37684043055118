/**
 * @description Bank capability enable with Bridge
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 01/03/2024
 * @export
 * @enum {number}
 */
export enum BankCapability {
    AIS = 'ais', // Retrieve accounts and transactions informations
    AccountCheck = 'account_check', // Check if an account exists and retrieve details of the owner
    SingleTransfer = 'single_transfer', // Initiate a single bank transfers
    BulkTransfer = 'bulk_transfer', // Initiate multiple bank transfers
    SinglePayment = 'single_payment', // Initiate a single payment
    SinglePaymentScheduled = 'single_payment_scheduled', // Initiate a single payment scheduled
    BulkPayment = 'bulk_payment', // Initiate multiple payments
    BulkPaymentScheduled = 'bulk_payment_scheduled', // Initiate multiple payments scheduled
}

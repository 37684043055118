import { Bridge, Translation } from '../../interfaces';
import { RestEntity } from '../rest.entity';

/**
 * @description The bank transaction category entity in Bridge, like "Groceries", "Salary", "Rent", etc.
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 05/03/2024
 * @export
 * @class BankTransactionCategory
 * @extends {RestEntity}
 */
export class BankTransactionCategoryEntity extends RestEntity implements Bridge {
    /**
     * @description The ID of the bank transaction category in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankTransactionCategory
     */
    public bridgeID!: number;

    /**
     * @description Name the category in different languages
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {Translation}
     * @memberof BankTransactionCategory
     */
    public name!: Translation;

    /**
     * @description ID of the parent category, if the category is a subcategory, like "Fruits" is a subcategory of "Groceries" for example, the parentID of "Fruits" will be the ID of "Groceries" category in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankTransactionCategory
     */
    public parentID?: number;

    constructor(data: Partial<BankTransactionCategoryEntity>) {
        super(data);
    }

    /**
     * @description Check if the category is a root category, meaning it has no parent category, like "Groceries" for example
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @returns {boolean}
     * @memberof BankTransactionCategory
     */
    public isRoot(): boolean {
        return !this.parentID;
    }
}

/**
 * @description Appearance of a button (primary, secondary, success, danger, warning) to display in the table or in a form
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 12/09/2023
 * @export
 * @enum {number}
 */
export enum Appearance {
    primary = 'primary',
    secondary = 'secondary',
    success = 'success',
    danger = 'warn',
    warning = 'yellow',
    icon = 'icon',
    dangerSecondary = 'warn-secondary',
}

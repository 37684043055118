export interface SocialChargesOnRemuneration {
    /**
     * @description Total of social charges
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @type {number}
     * @memberof SocialCharges
     */
    total: number;

    /**
     * @description Part of employer contribution in social charges
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/10/2024
     * @type {number}
     * @memberof SocialCharges
     */
    employerContribution: number;

    /**
     * @description Part of employer contribution in social charges
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/10/2024
     * @type {number}
     * @memberof SocialCharges
     */
    employeeContribution: number;

    /**
     * @description Part of foresight in social charges
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @type {number}
     * @memberof SocialCharges
     */
    foresight: number;

    /**
     * @description Part of basic retirement in social charges
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @type {number}
     * @memberof SocialCharges
     */
    basicRetirement: number;

    /**
     * @description Part of complementary retirement in social charges
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @type {number}
     * @memberof SocialCharges
     */
    complementaryRetirement: number;

    /**
     * @description Pension of basic retirement pension
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @type {number}
     * @memberof SocialCharges
     */
    basicRetirementPension: number;

    /**
     * @description Pension of complementary retirement pension
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @type {number}
     * @memberof SocialCharges
     */
    complementaryRetirementPension: number;
}

export interface Repartition {
    total: number;
    partOfDividends: number;
    partOfRemuneration: number;
}

export interface CalcSimResult {
    /**
     * @description Company cost of remuneration of manager in the simulation
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @type {Repartition}
     * @memberof CalcSimResult
     */
    companyCost: Repartition;

    /**
     * @description Social charges of remuneration of manager in the simulation
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @type {SocialCharges}
     * @memberof CalcSimResult
     */
    socialChargesOnRemuneration: SocialChargesOnRemuneration;

    /**
     * @description Social charges of dividends of manager in the simulation
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 30/10/2024
     * @type {number}
     * @memberof CalcSimResult
     */
    socialChargesOnDividends?: number;

    /**
     * @description Net remuneration of manager in the simulation
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @type {number}
     * @memberof CalcSimResult
     */
    netRemuneration: number;

    /**
     * @description Taxes of remuneration of manager in the simulation
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @type {Repartition}
     * @memberof CalcSimResult
     */
    taxes: Repartition;

    /**
     * @description After tax income of manager in the simulation
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @type {number}
     * @memberof CalcSimResult
     */
    afterTaxIncome: number;

    /**
     * @description Retirement pension of manager in the simulation
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @type {number}
     * @memberof CalcSimResult
     */
    retirementPension: number;
}

export interface Contribution {
    /**
     * @description Employer contribution of calculation
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @type {number}
     * @memberof Contribution
     */
    employerContribution: number;

    /**
     * @description Employee contribution of calculation
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @type {number}
     * @memberof Contribution
     */
    employeeContribution: number;
}

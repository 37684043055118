<div class="container" id="asset-slider-container">
    <div class="asset-slider" (scroll)="onScroll($event)" id="asset-slider">
        <div class="asset-actions">
            <button
                class="omedom-button-outline margin-right-1 main-button"
                routerLink="/tabs/property"
            >
                <i class="uil uil-home"></i>
                <!-- <i class="uil uil-suitcase"></i> -->
            </button>
            @if(isEssential){<button
                class="omedom-button-outline margin-right-1 main-button"
                routerLink="/tabs/menu/subscription/"
            >
                <i class="omedom-icon-crown"></i></button
            >}
            <div [style.opacity]="focus ? 1 : 0" class="fade-left"></div>
        </div>
        @if (assets && assets.length) {
        <ng-container>
            @for (asset of assets; track asset.uid; let i = $index) {
            <ng-container>
                @if (getAssetType(asset) === assetTypes.property ||
                getAssetType(asset) === assetTypes.building) {
                <omedom-property-card
                    [property]="$any(asset)"
                    [listView]="false"
                    [layout]="assetCardLayout.CardMini"
                    [showPastAndFutureMovements]="false"
                    (propertyClick)="assetClicked(asset)"
                ></omedom-property-card>
                } @if (getAssetType(asset) === 'society') {
                <omedom-society-card
                    [society]="$any(asset)"
                    [listView]="false"
                    [layout]="assetCardLayout.CardMini"
                    [showPastAndFutureMovements]="false"
                    (societyClick)="assetClicked(asset)"
                ></omedom-society-card>
                }
            </ng-container>
            }
        </ng-container>
        } @else if(emptyListMessage) {
        <omedom-info [information]="emptyListMessage"></omedom-info>
        } @else {
        <ion-spinner class="spinner"></ion-spinner>
        }
    </div>
</div>

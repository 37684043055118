import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubscriptionDto, SubscriptionPrices, SubscriptionRank } from '@omedom/data';

@Component({
    selector: 'omedom-subscription-slide',
    templateUrl: './subscription-slide.component.html',
    styleUrls: ['./subscription-slide.component.scss'],
})
export class SubscriptionSlideComponent {
    @Output() chooseEvent = new EventEmitter();
    @Output() cancelEvent = new EventEmitter();

    @Input() subscriptionColor: string = 'white';
    @Input() subscriptionName: SubscriptionRank = SubscriptionRank.ESSENTIAL;
    @Input() subscriptionSubTitle: string = '';
    @Input() subscriptionPrice?: SubscriptionPrices;
    @Input() currentSubscription?: SubscriptionDto;


    @Input() public displayButton = false;
    @Input() public displayPrice = false;
    @Input() public hideHeader = false;

    protected readonly SubscriptionRank = SubscriptionRank;
}

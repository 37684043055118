import {
    BankTransactionRecurrenceBase,
    BankTransactionRecurrenceDetail,
    BankTransactionRecurrenceResult,
    Bridge,
} from '../../interfaces';
import { RestEntity } from '../rest.entity';

/**
 * @description The bank transaction entity in Bridge
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 05/03/2024
 * @export
 * @class BankTransaction
 * @extends {RestEntity}
 * @implements {Bridge}
 */
export class BankTransactionEntity extends RestEntity implements Bridge {
    /**
     * @description The ID of the bank transaction in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankTransaction
     */
    public bridgeID!: number;

    /**
     * @description UID of the user in OMEDOM's database
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof BankTransaction
     */
    public userUID!: string;

    /**
     * @description The ID of the bank account in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankTransaction
     */
    public accountID!: number;

    /**
     * @description The ID of the category in Bridge, the category of the transaction, like "Groceries", "Salary", "Rent", etc.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankTransaction
     */
    public categoryID!: number;

    /**
     * @description Description of the transaction given by Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof BankTransaction
     */
    public cleanDescription!: string;

    /**
     * @description Description of the transaction given by the bank
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof BankTransaction
     */
    public bankDescription!: string;

    /**
     * @description Amount of the transaction in the bank's currency, in cents, like 1000 for 10.00€ or 1000 for 10.00$
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankTransaction
     */
    public amount!: number;

    /**
     * @description Date of the transaction
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {Date}
     * @memberof BankTransaction
     */
    public date!: Date;

    /**
     * @description Currency code of the transaction, like "EUR" or "USD", the most of the time the currency code is the same as the bank's currency code
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof BankTransaction
     */
    public currencyCode!: string;

    /**
     * @description If true, the transaction has been rejected or deleted by Bridge for an other reason
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {boolean}
     * @memberof BankTransaction
     */
    public isDeleted!: boolean;

    /**
     * @description If true, the transaction is masked in the bank transactions list
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @type {boolean}
     * @memberof BankTransactionEntity
     */
    public masked?: boolean;

    /**
     * @description UID of the treasury in OMEDOM's database, the treasury is the charge or the income that the transaction is related to
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @type {string}
     * @memberof BankTransactionEntity
     */
    public treasuryUID?: string;

    constructor(data: Partial<BankTransactionEntity>) {
        super(data);

        // Check if date is Timestamp
        if ((this.date as any).toDate && typeof (this.date as any).toDate === 'function') {
            this.date = (this.date as any).toDate();
        }

        // Check if amount is defined
        if (this.amount === undefined) {
            this.amount = 0;
        }
    }

    /**
     * @description Calculate the amount detail, the method will return the treasury amount, the transaction amount and the proximity of the amounts in percent, the proximity is calculated by the difference between the amounts in percent, if the amounts are at the same sign, the proximity will be 100
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 18/03/2024
     * @private
     * @param {BankTransactionRecurrenceBase} base
     * @returns {BankTransactionRecurrenceDetail<number>}
     * @memberof BankTransactionEntity
     */
    private calculateAmountDetail(base: BankTransactionRecurrenceBase): BankTransactionRecurrenceDetail<number> {
        const canBeCharge = this.amount < 0;
        const canBeIncome = this.amount > 0;

        // Check if the transaction can be a charge or an income
        if (!canBeCharge && !canBeIncome) {
            return {
                treasury: this.amount,
                transaction: base.amount,
                proximity: 0,
            };
        }

        // Define the weight of the amount
        const weight = 0.05;

        // Calculate the amount proximity in percent
        const proximity = Math.max(100 - Math.abs(Math.abs(this.amount) - Math.abs(base.amount)) / Math.abs(base.amount * weight) * 100, 0);

        // Create the detail
        return {
            treasury: base.amount,
            transaction: this.amount,
            proximity,
        };
    }

    /**
     * @description Calculate the date detail, the method will return the treasury date, the transaction date and the proximity of the dates in percent, the proximity is calculated by the difference between the dates in percent, if the dates are at the same sign, the proximity will be 100, the proximity is calculated by the difference between the dates in percent, if the dates are at the same sign, the proximity will be 100
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 18/03/2024
     * @private
     * @param {BankTransactionRecurrenceBase} base
     * @returns {BankTransactionRecurrenceDetail<Date>}
     * @memberof BankTransactionEntity
     */
    private calculateDateDetail(base: BankTransactionRecurrenceBase): BankTransactionRecurrenceDetail<Date> {
        // Initialize the histories
        const histories: { amount: number, date: Date; }[] = [];

        // Init the base history
        let history = histories[0];

        // Generate history for 12 iterations before and after the base date until today depending on the periodicity
        const addHistory = (iteration: number, before: boolean) => {
            const date = new Date(base.date.getTime());

            switch (base.periodicity) {
                case 'monthly':
                    date.setMonth(date.getMonth() + (before ? -iteration : iteration));
                    break;
                case 'bimonthly':
                    date.setMonth(date.getMonth() + (before ? -iteration * 2 : iteration * 2));
                    break;
                case 'quarterly':
                    date.setMonth(date.getMonth() + (before ? -iteration * 3 : iteration * 3));
                    break;
                case 'half-yearly':
                    date.setMonth(date.getMonth() + (before ? -iteration * 6 : iteration * 6));
                    break;
                case 'yearly':
                    date.setFullYear(date.getFullYear() + (before ? -iteration : iteration));
                    break;
                default:
                    date.setDate(date.getDate() + (before ? -iteration : iteration));
                    break;
            }

            histories.push({
                amount: base.amount,
                date
            });
        };

        for (let i = 1; i <= 12; i++) {
            addHistory(i, true);
            addHistory(i, false);
        }

        // Get the closest history
        history = histories.reduce((closest, current) => {
            // Check if current exists
            if (!current) {
                return closest;
            }

            // Check if closest exists
            if (!closest) {
                return current;
            }

            const closestGap = Math.abs(this.date.getTime() - closest.date.getTime());
            const currentGap = Math.abs(this.date.getTime() - current.date.getTime());

            return currentGap < closestGap ? current : closest;
        }, history);


        // Calculate the date proximity in percent
        let gap = 1 * 24 * 60 * 60 * 1000;

        switch (base.periodicity) {
            case 'monthly':
                gap *= 7;
                break;
            case 'bimonthly':
                gap *= 10;
                break;
            case 'quarterly':
                gap *= 13;
                break;
            case 'half-yearly':
                gap *= 16;
                break;
            case 'yearly':
                gap *= 19;
                break;
            default:
                gap *= 7;
                break;
        }

        // Calculate the proximity
        const proximity = Math.max(100 - (Math.abs((this.date.getTime() - history.date.getTime()) / gap) * 100), 0);

        // Create the detail
        return {
            treasury: history.date,
            transaction: this.date,
            proximity,
        };
    }

    /**
     * @description Calculate the category detail, the method will return the treasury category, the transaction category and the proximity of the categories in percent, the proximity is calculated by the difference between the categories in percent, if the categories are at the same sign, the proximity will be 100, if the categoryID is not defined, the method will return 0 for the treasury and the transaction and 0 for the proximity, if the transaction categoryID is not defined, the method will return the categoryID for the treasury and 0 for the transaction and 0 for the proximity
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 18/03/2024
     * @private
     * @param {BankTransactionRecurrenceBase} base
     * @returns {BankTransactionRecurrenceDetail<number>}
     * @memberof BankTransactionEntity
     */
    private calculateCategoryDetail(base: BankTransactionRecurrenceBase): BankTransactionRecurrenceDetail<number> {
        return {
            treasury: base.category,
            transaction: this.categoryID,
            proximity: base.category === this.categoryID ? 100 : 0,
        };
    }

    /**
     * @description Calculate the recurrence score with a base
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 18/03/2024
     * @private
     * @param {BankTransactionEntity} transaction
     * @returns {BankTransactionRecurrenceResult}
     * @memberof BankTransactionEntity
     */
    public calculateRecurrenceScore(base: BankTransactionRecurrenceBase): BankTransactionRecurrenceResult {
        // Initialize the score
        let score = 0;

        // Get details of the transaction
        const amountDetail = this.calculateAmountDetail(base);
        const dateDetail = this.calculateDateDetail(base);
        const categoryDetail = this.calculateCategoryDetail(base);

        // Get proximity of the transaction
        const amountProximity = amountDetail.proximity;
        const dateProximity = dateDetail.proximity;
        const categoryProximity = categoryDetail.proximity;

        // Calculate the score
        score = (2 * amountProximity + dateProximity + categoryProximity * 0.5) / 3.5;

        // Create the result
        const result: BankTransactionRecurrenceResult = {
            transactionUID: this.uid,
            score,
            details: {
                amount: amountDetail,
                date: dateDetail,
                category: categoryDetail,
            },
        };

        return result;
    }

    /**
     * @description Find the recurrence of the transactions, the method will return an array of BankTransactionRecurrenceResult, each result will contain the score of the recurrence and the details of the recurrence calculation, the amount, the date and the category of the transaction and the treasury are compared to calculate the recurrence score.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 18/03/2024
     * @param {BankTransactionRecurrenceBase} base The base to compare to calculate the recurrence score
     * @param {BankTransactionEntity[]} transactions The transactions to find the recurrence
     * @returns {BankTransactionRecurrenceResult[]}
     * @memberof BankTransaction
     */
    public static findRecurrence(base: BankTransactionRecurrenceBase, transactions: BankTransactionEntity[]): BankTransactionRecurrenceResult[] {
        // Check if the transactions are not empty
        if (transactions.length === 0) {
            return [];
        }

        // Check if treasury is not punctual
        if (base.periodicity === 'punctual') {
            return [];
        }

        // Create an array to store the results
        const results: BankTransactionRecurrenceResult[] = transactions.map((transaction) => {
            return transaction.calculateRecurrenceScore(base);
        });

        // Sort the results
        results.sort((a, b) => b.score - a.score);

        return results;
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { UserEntity } from '@omedom/data';
import { AnalyticsService, UserService } from '@omedom/services';
import { OmedomRegex, OmedomTransformer } from '@omedom/utils';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { elementAnimation } from '../../animations';
import { MenuAccountDeleteComponent } from '../menu-account-delete/menu-account-delete.component';

@Component({
    selector: 'omedom-menu-account-form',
    templateUrl: './menu-account-form.component.html',
    styleUrls: ['./menu-account-form.component.scss'],
    animations: [elementAnimation],
})
export class MenuAccountFormComponent {
    @Output() passwordChange = new EventEmitter<string>();

    @Output() passwordConfirmChange = new EventEmitter<string>();

    @Output() oldPasswordChange = new EventEmitter<string>();

    /**
     * @description User data
     * @author Jérémie Lopez
     * @type {UserEntity}
     * @memberof MenuAccountDisplayComponent
     */
    @Input() user?: UserEntity;

    /**
     * @description Pending state of the update
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/06/2024
     * @type {BehaviorSubject<boolean>}
     * @memberof MenuAccountPage
     */
    public pending$ = new BehaviorSubject<boolean>(false);

    password: string = '';

    passwordConfirm: string = '';

    oldPassword: string = '';

    /**
     * @description If true, the app is pending to upload photo
     * @author Jérémie Lopez
     * @memberof MenuAccountFormComponent
     */
    public pendingPhoto$ = new BehaviorSubject<boolean>(false);

    private _editable: boolean = false;

    private _isEmailPasswordUser: boolean = false;

    public phoneRegex: RegExp = OmedomRegex.phoneRegex;

    public bdayRegex: RegExp = OmedomRegex.birthdateRegex;

    public postalCodeRegex: RegExp = OmedomRegex.postalCodeRegex;

    public isCapacitor = this.platform.is('capacitor') || false;

    public isAndroid = this.platform.is('android') || false;

    @Input() set editable(value: boolean) {
        this._editable = value;
    }

    @Input() set isEmailPasswordUser(value: boolean) {
        this._isEmailPasswordUser = value;
    }

    get editable(): boolean {
        return this._editable;
    }

    get isEmailPasswordUser(): boolean {
        return this._isEmailPasswordUser;
    }

    /**
     * @description Show the current password input field or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/06/2024
     * @memberof MenuAccountFormComponent
     */
    public showCurrentPassword = false;

    /**
     * @description Show the new password input field or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/06/2024
     * @memberof MenuAccountFormComponent
     */
    public showNewPassword = false;

    /**
     * @description Show the confirm password input field or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/06/2024
     * @memberof MenuAccountFormComponent
     */
    public showPasswordConfirm = false;

    public get isFormValid(): boolean {
        if (!this.user) {
            return false;
        }
        const emptyFields =
            this.user.name !== '' &&
            this.user.firstname !== '' &&
            this.user.address?.postalCode !== '';
        const birthdate = this.user.birthdate
            ? OmedomRegex.birthdateRegex.test(this.user.birthdate)
            : true;
        const postalCode = OmedomRegex.postalCodeRegex.test(this.user.address?.postalCode ?? '');
        const phone = this.user.tel ? OmedomRegex.phoneRegex.test(this.user.tel) : true;
        const valid = emptyFields && birthdate && postalCode && phone;

        return valid;
    }

    constructor(
        private storage: AngularFireStorage,
        private userService: UserService,
        private platform: Platform,
        private toastController: ToastController,
        private analyticsService: AnalyticsService,
        protected modalController: ModalController
    ) {}

    ionViewDidEnter() {
        this.analyticsService.setCurrentScreen('User Information Page');
    }

    /**
     * @description Delete the picture of the User
     * @author Jérémie Lopez
     * @memberof MenuAccountFormComponent
     */
    public deletePicture(): void {
        if (this.user) {
            this.user.avatar = '';
        }
    }

    /**
     * @description Upload photo to firebase storage
     * @author Jérémie Lopez, Didier Pascarel
     * @return {*}  {Promise<void>}
     * @memberof MenuAccountFormComponent
     */
    public async uploadAvatar(): Promise<void> {
        if (this.isCapacitor) {
            const photoIsAllowed = await Camera.checkPermissions().then(async (result) => {
                if (result.camera === 'granted' && result.photos === 'granted') {
                    return true;
                } else {
                    const permission = await Camera.requestPermissions();
                    if (permission.photos === 'granted' && permission.camera === 'granted') {
                        return true;
                    } else {
                        return false;
                    }
                }
            });
            if (!photoIsAllowed) {
                const toast = await this.toastController.create({
                    position: 'top',
                    color: 'warning',
                    duration: 3000,
                    message: 'Accès aux fichiers refusé.',
                });

                await toast.present();
                return;
            }
        }
        let image: Photo;
        if (this.isCapacitor && this.isAndroid) {
            image = await Camera.getPhoto({
                quality: 70,
                allowEditing: false,
                resultType: CameraResultType.Base64,
                source: CameraSource.Prompt,
                promptLabelPhoto: 'Depuis la galerie',
                promptLabelPicture: 'Prendre une photo',
                saveToGallery: true,
            });
        } else {
            image = await Camera.getPhoto({
                quality: 70,
                allowEditing: false,
                resultType: CameraResultType.Base64,
                source: CameraSource.Photos,
                saveToGallery: true,
            });
        }
        const myId = uuidv4();

        if (!image || !image.base64String) {
            return;
        }

        const file = OmedomTransformer.base64ToFile(image.base64String, myId + '.jpg');
        const path = `assets/images/${file.name}`;
        this.pendingPhoto$.next(true);

        //The main task
        const task = await this.storage.upload(path, file);
        // The file's download URL
        try {
            await task.ref.getDownloadURL().then(async (url) => {
                if (url && this.user) {
                    this.user.avatar = url;
                    await this.userService.update({
                        avatar: url,
                        uid: this.user.uid,
                    });
                }
            });
        } catch (error) {
            console.error(error);
        }
        this.pendingPhoto$.next(false);
    }

    keyPress(event: KeyboardEvent) {
        if (event.key !== 'Backspace' && this.user) {
            let bday = this.user.birthdate;
            if (bday && (bday.length === 2 || bday.length === 5)) {
                bday = bday + '/';
                this.user.birthdate = bday;
            }
        }
    }

    enableTwoFactor(method: 'email' | 'phone') {
        if (!this.user) {
            return;
        }
        this.user.multiFactor = {
            ...(this.user.multiFactor ?? {}),
        };

        if (method === 'email') {
            this.user.multiFactor.email = {
                enabled: true,
            };
        } else if (method === 'phone') {
            this.user.multiFactor.phone = {
                enabled: true,
            };
        }
    }

    /**
     * @description Update user data and display success message if successful, error message otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/06/2024
     * @returns {Promise<void>}
     * @memberof MenuAccountPage
     */
    public async update(): Promise<void> {
        // Set pending state to true
        this.pending$.next(true);

        // Check if password has been changed
        try {
            await this.checkPasswordChangement();
        } catch (error) {
            this.pending$.next(false);

            return;
        }
        const existingToast = await this.toastController.getTop();
        // Wait last toast to be dismissed
        await new Promise((resolve) =>
            setTimeout(resolve, existingToast ? existingToast.duration : 0)
        );

        // Update user
        try {
            if (!this.user) {
                throw new Error('User not found');
            }
            await this.userService.update(this.user);
        } catch (e) {
            // Display error message if update fails
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: 'Une erreur est survenue lors de la mise à jour de vos informations',
            });

            await toast.present();
            this.pending$.next(false);
            return;
        }

        // Display success message
        const toast = await this.toastController.create({
            position: 'top',
            color: 'success',
            duration: 4000,
            message: 'Vos informations ont été mises à jour',
        });

        await toast.present();

        // Set pending state to false
        this.pending$.next(false);
    }

    private async checkPasswordChangement(): Promise<void> {
        // Check if any password fields are filled
        if (!this.password || !this.passwordConfirm || !this.oldPassword) {
            return;
        }

        // Check if new password and confirmation match
        if (this.password !== this.passwordConfirm) {
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message:
                    'La confirmation de votre nouveau mot de passe ne correspond pas à votre nouveau mot de passe',
            });

            await toast.present();

            // Throw error to stop the process
            throw new Error('Passwords do not match');
        }

        // Check if password has minimum 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character
        if (!OmedomRegex.passwordRegex.test(this.password)) {
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message:
                    'Votre mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial',
            });

            await toast.present();

            // Throw error to stop the process
            throw new Error('Password does not match the requirements');
        }

        try {
            if (!this.user) {
                return;
            }
            // Update password
            await this.userService.updatePassword(this.user.email, this.oldPassword, this.password);

            // Display success message
            const toast = await this.toastController.create({
                position: 'top',
                color: 'success',
                duration: 4000,
                message: 'Votre mot de passe a été mis à jour',
            });

            await toast.present();
        } catch (e) {
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "L'ancien mot de passe n'est pas valide",
            });

            await toast.present();

            // Throw error to stop the process
            throw new Error('Old password is not valid');
        }
    }

    public async openModalDeleteAccount(): Promise<void> {
        const modal = await this.modalController.create({
            component: MenuAccountDeleteComponent,
            canDismiss: true,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
        });

        await modal.present();
    }
}

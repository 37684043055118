import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ProAlertEntity } from '@omedom/data';

import { RestService } from './rest.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProAlertService extends RestService<ProAlertEntity> {
    protected override builder = ProAlertEntity;

    constructor(protected override firestore: AngularFirestore) {
        super(firestore, 'proAlerts');
    }

    /**
     * @description Return alerts of the user in async mode
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/08/2024
     * @param {string} userUID
     * @returns {*}  {Promise<ProAlertEntity[]>}
     * @memberof ProAlertService
     * @example
     * const alerts = await proAlertService.getUserAlerts(userUID);
     * console.log(alerts);
     */
    public async getUserAlerts(userUID: string): Promise<ProAlertEntity[]> {
        return this.search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            },
        ]);
    }

    /**
     * @description Return alerts of the user in real time
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/08/2024
     * @param {string} userUID
     * @returns {*}  {Observable<ProAlertEntity[]>}
     * @memberof ProAlertService
     * @example
     * const alerts = proAlertService._getUserAlerts(userUID).subscribe(alerts => {
     *   console.log(alerts);
     * });
     */
    public _getUserAlerts(userUID: string): Observable<ProAlertEntity[]> {
        return this._search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            },
        ]);
    }

    /**
     * @description Return alerts of the user linked to the client in async mode
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/08/2024
     * @param {string} userUID
     * @param {string} clientUID
     * @returns {*}  {Promise<ProAlertEntity[]>}
     * @memberof ProAlertService
     * @example
     * const alerts = await proAlertService.getUserAlertsOfClient(userUID, clientUID);
     * console.log(alerts);
     */
    public async getUserAlertsOfClient(
        userUID: string,
        clientUID: string
    ): Promise<ProAlertEntity[]> {
        return this.search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            },
            {
                where: 'clientUID',
                operator: '==',
                value: clientUID,
            },
        ]);
    }

    /**
     * @description Return alerts of the user linked to the client in real time
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 13/08/2024
     * @param {string} userUID
     * @param {string} clientUID
     * @returns {*}  {Observable<ProAlertEntity[]>}
     * @memberof ProAlertService
     * @example
     * const alerts = proAlertService._getUserAlertsOfClient(userUID, clientUID).subscribe(alerts => {
     *  console.log(alerts);
     * });
     */
    public _getUserAlertsOfClient(
        userUID: string,
        clientUID: string
    ): Observable<ProAlertEntity[]> {
        return this._search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            },
            {
                where: 'clientUID',
                operator: '==',
                value: clientUID,
            },
        ]);
    }
}

import { PropertyType, SocietyType, SubscriptionEntity, UseProperty, UserEntity } from '@omedom/data';

export const metrics: Metrics = {
    appMetrics: {
        appRegistryPlatformMetrics: {
            appPlatformsMetrics: {
                androidMetric: 0,
                iosMetric: 0,
                webMetric: 0
            }
        },
        appDownloadMetrics: {
            androidMetric: 0,
            iosMetric: 0
        },
        appUninstallMetrics: {
            androidMetric: 0,
            iosMetric: 0
        }
    },
    accountsMetrics: {
        usersAccountsFinalised: {
            total: 0,
            android: 0,
            ios: 0,
            web: 0,
            unknown: 0,
        },
        usersAccountsUnfinalised: {
            total: 0,
            android: 0,
            ios: 0,
            web: 0,
            unknown: 0,
        },
        usersAccountsDeleted: {
            total: 0,
            android: 0,
            ios: 0,
            web: 0,
            unknown: 0,
        },
        usersAccountsCreated: {
            total: 0,
            android: 0,
            ios: 0,
            web: 0,
            unknown: 0,
        },
        subscriptions: {
            total: 0,
            apple: {
                total: 0,
                list: [
                    {
                        uid: '',
                        created: new Date(),
                        renewDate: '',
                        cancelSubscription: false,
                        userUID: '',
                    },
                ],
            },
            payplug: {
                total: 0,
                list: [
                    {
                        uid: '',
                        created: new Date(),
                        renewDate: '',
                        cancelSubscription: false,
                        userUID: '',
                    },
                ],
            },
        }
    },
    contactsMetrics: {
        brevosTotalContacts: 0,
        usersContactsFinalised: {
            total: 0,
            android: 0,
            ios: 0,
            web: 0,
            unknown: 0,

        },
        usersContactsUnfinalised: {
            total: 0,
            android: 0,
            ios: 0,
            web: 0,
            unknown: 0,

        },
        usersContactsDeleted: {
            total: 0,
            android: 0,
            ios: 0,
            web: 0,
            unknown: 0,

        },
        usersContactsCreated: {
            total: 0,
            android: 0,
            ios: 0,
            web: 0,
            unknown: 0,

        }
    },
    propertiesMetrics: {
        total: 0,
        uses: {
            totalSimpleResidentialUse: 0,
            totalSimpleRentalUse: 0,
            totalBuildingRentalUse: 0,
            totalBuildingResidentialUse: 0,
            totalBuildingMultipleUses: 0,
            totalOtherUse: 0,
        },
        propertiesByUserMetrics: {
            ratioForAllUsers: 0,
            ratioForUserWithProperties: 0,
        },
        propertiesByTypeMetrics: [
            {
                type: PropertyType.immeuble,
                total: 1,
                uses: [
                    {
                        total: 0,
                        use: 'Multiple',
                    },
                ],
                propertyUIDs: [''],
            },
        ],
        sharedWithUser: 0,
        totalSharedWithUser: 0,
        sharedWithPro: 0,
        valuation: {
            total: 0,
            median: 0,
            average: 0,
            proprertiesWithValuation: 0,
        },
    },
}


/**
 * @description All B2C app, users and properies metrics
 * @author Didier Pascarel
 * @interface Metrics
 */
export interface Metrics {
    appMetrics?: AppMetrics;

    accountsMetrics?: AccountsMetrics;

    contactsMetrics?: ContactsMetrics;

    propertiesMetrics?: PropertiesMetrics;

    societiesMetrics?: SocietiesMetrics;

    costMetrics?: CostMetrics;
}

/**
 * @description Mobile app metrics. Data from Stores consoles an Firebase
 * @author Didier Pascarel
 * @interface AppDowndloadMetrics
*/
export interface AppMetrics {
    appRegistryPlatformMetrics: AppRegistryPlatformMetrics;
    appDownloadMetrics: AppDowndloadMetrics;
    appUninstallMetrics: AppUninstallMetrics;
}
export interface AppRegistryPlatformMetrics {
    appPlatformsMetrics: AppPlatformsMetrics;
}
export interface AppDowndloadMetrics extends AppStoresMetrics {
}
export interface AppUninstallMetrics extends AppStoresMetrics {
}
export interface AppPlatformsMetrics extends AppStoresMetrics {
    webMetric: number;
}
export interface AppStoresMetrics {
    androidMetric: number;
    iosMetric: number;
}
/**
 * @description User account metrics. Data from Firebase
 * @author Didier Pascarel
 * @interface UsersAccountsFinalisedMetrics
 * @extends {UsersAccountsMetrics}
*/
export interface AccountsMetrics {
    usersAccountsFinalised?: UsersAccountsMetrics;
    usersAccountsUnfinalised?: UsersAccountsMetrics;
    usersAccountsDeleted?: UsersAccountsMetrics;
    usersAccountsCreated?: UsersAccountsMetrics;
    subscriptions?: SubscriptionsMetrics;
    subscriptionsAccountsFinalised?: UsersAccountsMetrics;
}

export enum UsersAccountsStates {
    finalised = 'finalised',
    unfinalised = 'unfinalised',
    deleted = 'deleted',
    created = 'created',
}
export interface UsersAccountsMetrics {
    total: number;
    android: number;
    ios: number;
    web: number;
    unknown: number;
}
export interface SubscriptionsMetrics {
    total: number;
    apple: SubscriptionsByPaiementPlatformsMetrics;
    payplug: SubscriptionsByPaiementPlatformsMetrics;
}
export interface SubscriptionsByPaiementPlatformsMetrics {
    total: number;
    list: Partial<SubscriptionEntity>[];
}
export enum PaimentPlatforms {
    apple = 'Apple',
    payplug = 'Payplug'
}

/**
 * @description User contacts metrics. Data from Brevo
 * @author Didier Pascarel
 * @interface UsersContactsFinalisedMetrics
*/
export interface ContactsMetrics {
    brevosTotalContacts: number;
    usersContactsFinalised: UsersContactsMetrics;
    usersContactsUnfinalised: UsersContactsMetrics;
    usersContactsDeleted: UsersContactsMetrics;
    usersContactsCreated: UsersContactsMetrics;
}
export interface UsersContactsMetrics {
    total: number;
    android: number;
    ios: number;
    web: number;
    unknown: number;
}

/**
 * @description User properties metrics. Data from Firebase
 * @author Didier Pascarel
 * @interface PropertiesMetrics
 * @schema
 *  {
 *     total: number,
 *     uses: {
 *         totalSimpleResidentialUse: number,
 *         totalSimpleRentalUse: number,
 *         totalBuildingRentalUse: number,
 *         totalBuildingResidentialUse: number,
 *         totalBuildingMultipleUses: number,
 *         totalOtherUse: number,
 *     }
 *   propertiesByUserMetrics: {
 *         ratioForAllUsers: number,
 *         ratioForUserWithProperties: number,
 *     }
 *   propertiesByTypeMetrics: {
 *         type: PropertyType,
 *         total: number,
 *         uses: {
 *             total: number,
 *             use: UseProperty | 'Multiple' | 'Multi locatifs' | 'Multi résidentiels' | 'Autre'  // Mutiple is used for building with multiple uses
 *   propertyUIDs?: string[],
 *         }[],
 *     }[],
 *     sharedWithUser: number,
 *     totalSharedWithUser: number,
 *     sharedWithPro: number,
 *     valuation: {
 *         total: number,
 *         median: number,
 *         average: number,
 *         proprertiesWithValuation: number,
 *     };
 * }
 */
export interface PropertiesMetrics {
    total: number; // Total number of properties in database
    uses: PropertiesUsesMetrics;
    propertiesByUserMetrics?: PropertiesByUserMetrics;
    propertiesByTypeMetrics: PropertiesByTypeMetrics[];
    sharedWithUser: number;
    totalSharedWithUser: number;
    sharedWithPro: number;
    valuation: PropertiesValuationMetrics;
}

export interface PropertiesUsesMetrics {
    totalSimpleResidentialUse: number;
    totalSimpleRentalUse: number;
    totalBuildingRentalUse: number;
    totalBuildingResidentialUse: number;
    totalBuildingMultipleUses: number;
    totalOtherUse: number;
}
export interface PropertiesByUserMetrics {
    ratioForAllUsers: number; // Properties by users
    ratioForUserWithProperties: number; // Properties by users with at least one property
}
export interface PropertiesByTypeMetrics {
    type: PropertyType | null;
    total: number;
    uses: PropertiesByUsesMetrics[];
    propertyUIDs: string[]; // used for filtering properties with bad type
}
export interface PropertiesByUsesMetrics {
    total: number;
    use: UseProperty | 'Multiple' | 'Multi locatifs' | 'Multi résidentiels' | 'Autre'  // Mutiple is used for building with multiple uses
}
export interface PropertiesValuationMetrics {
    total: number;
    median: number;
    average: number;
    proprertiesWithValuation: number;
}

/**
 * @description Societies metrics. Data from Firebase
 * @interface SocietiesMetrics
 */
export interface SocietiesMetrics {
    total: number;
    withMembers: number;
    types: SocietiesByTypesMetrics[];
}
export interface SocietiesByTypesMetrics {
    type: SocietyType;
    total: number;
}

/**
 * @description User cost metrics. Data from Firebase
 * @interface CostMetrics
 */
export interface CostMetrics {
    averageIncomesMetrics?: AverageIncomesMetrics;
}
export interface AverageIncomesMetrics {
    averageSmartIncomebyUserWithProperties?: number;
    averageSmartIncomebyUserWithSocieties?: number;
}

// Other interfaces
// TODO: fix type for property "fromBrevo"
export interface ValidatedUsers {
    fromFirestore: UserEntity[],
    // fromBrevo: sendinblue.GetContactDetails[];
    fromBrevo: any[];
}

import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { OmedomChartLegend } from './omedom-chart-legend';

@Component({
    selector: 'omedom-chart-legend',
    templateUrl: './chart-legend.component.html',
    styleUrls: ['./chart-legend.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChartLegendComponent implements OnInit {
    @Output() legendClicked = new EventEmitter<OmedomChartLegend>();

    @Input() legends: OmedomChartLegend[] = [];

    @Input() disablePercentage = false;

    /**
     * @description Used to display the forecast on the year if user has the good subscription plan
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 19/06/2024
     * @memberof ChartLegendComponent
     */
    @Input() forecastAuthorised = false;

    @Output() forecastClicked = new EventEmitter<void>();

    @Input() isLegendClickable?: boolean;

    ngOnInit() {
        this.isLegendClickable = this.isLegendClickable || false;
    }
}

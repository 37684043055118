import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
    BankAccountEntity,
    BankTransactionByDay,
    BankTransactionEntity,
    ChargeEntity,
    IncomeEntity,
} from '@omedom/data';
import { ChargeService, IncomeService } from '@omedom/services';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { elementAnimation, listAnimation, transactionAnimation } from '../../../animations';

@Component({
    selector: 'omedom-bank-history',
    templateUrl: './bank-history.container.html',
    styleUrls: ['./bank-history.container.scss'],
    animations: [elementAnimation, listAnimation, transactionAnimation],
})
export class BankHistoryContainer implements OnChanges {
    /**
     * @description List of transactions to display in the container
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @type {BankTransactionEntity[]}
     * @memberof BankHistoryContainer
     */
    @Input({ required: true })
    public transactions: BankTransactionEntity[] = [];

    /**
     * @description Bank account entity to filter the transactions to display in the container
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @type {BankAccountEntity}
     * @memberof BankHistoryContainer
     */
    @Input()
    public account?: BankAccountEntity;

    /**
     * @description List of charges and incomes to display in the container
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @type {(Observable<(ChargeEntity | IncomeEntity)[]>)}
     * @memberof BankHistoryContainer
     */
    public treasuries$: Observable<(ChargeEntity | IncomeEntity)[]> = of([]);

    /**
     * @description List of transactions grouped by day to display in the container
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @type {Observable<BankTransactionByDay[]>}
     * @memberof BankHistoryContainer
     */
    public transactionByDay$: Observable<BankTransactionByDay[]> = of([]);

    public state$ = new BehaviorSubject<string>('pending');

    public message: string = 'Aucune charge ou revenu à afficher.';

    public icon: string = 'history-alt';

    constructor(
        private readonly chargeService: ChargeService,
        private readonly incomeService: IncomeService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['transactions'] && changes['transactions'].currentValue) {
            this.state$.next('pending');
            this.getTreasuries();
            this.getTransactionByDay();
        }
    }

    /**
     * @description Get the charges and incomes of the bank account depending on the transactions list and the account entity passed as input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @private
     * @memberof BankHistoryContainer
     */
    private getTreasuries(): void {
        const uids = this.transactions
            .filter((transaction) => {
                // Filter transactions by account
                if (this.account) {
                    return transaction.accountID === this.account.bridgeID;
                }

                return true;
            })
            .filter((transaction) => transaction.treasuryUID)
            // Filter duplicated treasury UID
            .filter(
                (transaction, index, self) =>
                    self.findIndex((t) => t.treasuryUID === transaction.treasuryUID) === index
            )
            // Get the treasury UID and type of transaction
            .map((transaction) => {
                return {
                    treasuryUID: transaction.treasuryUID,
                    amount: transaction.amount,
                };
            });

        // Check if there are uids to get the charges and incomes
        if (!uids.length) {
            return;
        }

        // Get the charges of the bank account
        const charges = uids
            .filter((transaction) => transaction.amount < 0)
            .map((transaction) => {
                if (!transaction.treasuryUID) {
                    return of(null);
                }
                return this.chargeService._get(transaction.treasuryUID);
            });

        // Get the incomes of the bank account
        const incomes = uids
            .filter((transaction) => transaction.amount > 0)
            .map((transaction) => {
                if (!transaction.treasuryUID) {
                    return of(null);
                }
                return this.incomeService._get(transaction.treasuryUID);
            });

        // Combine charges and incomes to display them in the same list
        const treasuries = [...charges, ...incomes];

        // Combine all the observables to display them in the same list
        this.treasuries$ = combineLatest(treasuries).pipe(
            map((data) => {
                return data.filter((treasury) => !!treasury) as (ChargeEntity | IncomeEntity)[];
            })
        );
    }

    /**
     * @description Group transactions by day in the transactionByDay property of the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @memberof BankHistoryContainer
     */
    public getTransactionByDay(): void {
        this.transactionByDay$ = this.treasuries$.pipe(
            map((treasuries) => {
                let transactionByDay: BankTransactionByDay[] = [];

                // Group transactions by day
                this.transactions.forEach((transaction) => {
                    const date = new Date(transaction.date);
                    const day = date.getDate();
                    const month = date.getMonth();
                    const year = date.getFullYear();

                    const dayIndex = transactionByDay.findIndex((d) => {
                        return (
                            d.day.getDate() === day &&
                            d.day.getMonth() === month &&
                            d.day.getFullYear() === year
                        );
                    });

                    if (dayIndex === -1) {
                        transactionByDay.push({
                            day: new Date(year, month, day),
                            transactions: [transaction],
                        });
                    } else {
                        transactionByDay[dayIndex].transactions.push(transaction);
                    }
                });

                // Filter transactions by treasury
                transactionByDay = transactionByDay.map((day) => {
                    day.transactions = day.transactions.filter((transaction) => {
                        return !!treasuries.find(
                            (treasury) => treasury.uid === transaction.treasuryUID
                        );
                    });

                    return day;
                });

                // Filter days without transactions
                transactionByDay = transactionByDay.filter((day) => day.transactions.length);

                // Sort transactions by day
                transactionByDay = transactionByDay.sort((a, b) => {
                    return b.day.getTime() - a.day.getTime();
                });

                return transactionByDay;
            }),
            tap(() => {
                this.state$.next('ok');
            })
        );
    }

    /**
     * @description Track by function for the ngFor directive in the template to avoid re-rendering of the component when the transactions are updated
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/04/2024
     * @param {number} index
     * @param {BankTransactionEntity} transaction
     * @returns {string}
     * @memberof BankHistoryContainer
     */
    public trackByTransaction(_: number, transaction: BankTransactionEntity): string {
        return transaction.uid;
    }

    /**
     * @description Get the charge or income of a transaction depending on the treasury UID of the transaction and the list of charges and incomes passed as input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @param {BankTransactionEntity} transaction
     * @param {((ChargeEntity | IncomeEntity)[])} treasuries
     * @returns {(ChargeEntity | IncomeEntity | undefined)}
     * @memberof BankHistoryContainer
     */
    public getTreasury(
        transaction: BankTransactionEntity,
        treasuries: (ChargeEntity | IncomeEntity)[]
    ): ChargeEntity | IncomeEntity {
        return treasuries.find((treasury) => treasury.uid === transaction.treasuryUID) as
            | ChargeEntity
            | IncomeEntity;
    }
}

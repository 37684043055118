import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { ContactEntity, SelectOption, UserEntity } from '@omedom/data';
import { AnalyticsService, ContactService } from '@omedom/services';
import { of } from 'rxjs';

import { elementAnimation } from '../../../animations';
import { AlertComponent } from '../../../components/alert/alert.component';

@Component({
    selector: 'omedom-help-feedback',
    templateUrl: './help-feedback.component.html',
    styleUrls: ['./help-feedback.component.scss'],
    animations: [elementAnimation],
})
export class HelpFeedbackComponent implements OnInit {
    /**
     * @description the current user information: for sending the email
     * @author Hanane Djeddal
     * @type {UserEntity}
     * @memberof HelpFeedbackComponent
     */
    @Input() public user?: UserEntity;

    /**
     * @description the text message of the email
     * @author Hanane Djeddal
     * @type {string}
     * @memberof HelpFeedbackComponent
     */
    public message?: string;

    /**
     * @description the email subject
     * @author Hanane Djeddal
     * @type {SelectOption}
     * @memberof HelpFeedbackComponent
     */
    public subject?: SelectOption;

    /**
     * @description the type of the email subject: Bug or Improvement
     * @author Hanane Djeddal
     * @memberof HelpFeedbackComponent
     */
    subjectOptions$ = of([] as SelectOption[]);

    /**
     * @description the place holder of the subject selector
     * @author Hanane Djeddal
     * @memberof HelpFeedbackComponent
     */
    subjectPlaceholder = { label: 'Objet' } as SelectOption;

    /**
     * @description storing the email entity
     * @author Hanane Djeddal
     * @private
     * @type {Partial<ContactEntity>}
     * @memberof HelpFeedbackComponent
     */
    private email: Partial<ContactEntity> = {};

    constructor(
        private modalController: ModalController,
        private navController: NavController,
        private toast: ToastController,
        private contactService: ContactService,
        private analyticsService: AnalyticsService
    ) {}

    ngOnInit() {
        const subjectOptions = [
            {
                id: 1,
                label: 'Bug',
                icon: 'uil uil-constructor',
            } as SelectOption,
            {
                id: 2,
                label: 'Amélioration',
                icon: 'uil uil-paint-tool',
            } as SelectOption,
        ];
        this.subject = subjectOptions[0];
        this.subjectOptions$ = of(subjectOptions);
    }

    ionViewDidEnter() {
        this.analyticsService.setCurrentScreen('Help feedback Page');
    }

    async existClicked(label: string, icon: string, img: string | null) {
        const modal = await this.modalController.create({
            component: AlertComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
            componentProps: { title: label, avatar: icon, image: img },
        });

        modal.present();
    }

    back(): void {
        this.navController.pop();
    }

    public async submit(): Promise<void> {
        if (!this.user || !this.subject) {
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
            return;
        }
        this.email.senderEmail = this.user.email;
        this.email.userUID = this.user.uid;
        this.email.senderName = this.user.name;
        this.email.senderFirstName = this.user.firstname;
        this.email.message = this.message;
        this.email.sendto = 'serviceclient@omedom.com';
        this.email.subject = this.subject.label;
        try {
            await this.contactService.create(this.email);
            const toast = await this.toast.create({
                position: 'top',
                color: 'primary',
                duration: 4000,
                message: 'Votre message a bien été envoyé.',
            });

            await toast.present();
            this.back();
        } catch (error) {
            console.error(error);
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
        }
    }
}

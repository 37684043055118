import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'omedom-modal-header',
    templateUrl: './modal-header.component.html',
    styleUrls: ['./modal-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent {
    constructor(
        private modalController: ModalController,
    ) { }

    /**
     * @description Close the modal
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 18/09/2023
     * @memberof ModalHeaderComponent
     */
    public close(): void {
        this.modalController.dismiss();
    }
}

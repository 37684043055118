import { Component, Input } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { PropertyEntity } from '@omedom/data';
import { PropertyService } from '@omedom/services';
import { OmedomTransformer } from '@omedom/utils';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'omedom-choose-avatar-type',
    templateUrl: './choose-avatar-type.component.html',
    styleUrls: ['./choose-avatar-type.component.scss'],
})
export class ChooseAvatarTypeComponent {
    @Input() property?: PropertyEntity;

    public isCapacitor = this.platform.is('capacitor') || false;

    public isAndroid = this.platform.is('android') || false;

    /**
     * @description If true, the app is pending an action
     * @author Jérémie Lopez
     * @memberof DocumentFormPage
     */
    public pendingPhoto$ = new BehaviorSubject<boolean>(false);

    constructor(
        private modalController: ModalController,
        private propertyService: PropertyService,
        private storage: AngularFireStorage,
        private platform: Platform,
        private toastController: ToastController
    ) {}

    public async dismiss(): Promise<void> {
        await this.modalController.dismiss();
    }

    /**
     * @description Upload photo to firebase storage
     * @author Jérémie Lopez<jeremie.lopez@omedom.com>, Didier Pascarel<didier.pascarel@omedom.com>
     * @return {*}  {Promise<void>}
     * @memberof MenuAccountFormComponent
     */
    public async choosePicture(): Promise<void> {
        if (this.isCapacitor) {
            const photoIsAllowed = await Camera.checkPermissions().then(async (result) => {
                if (result.camera === 'granted' && result.photos === 'granted') {
                    return true;
                } else {
                    const permission = await Camera.requestPermissions();
                    if (permission.photos === 'granted' && permission.camera === 'granted') {
                        return true;
                    } else {
                        return false;
                    }
                }
            });
            if (!photoIsAllowed) {
                const toast = await this.toastController.create({
                    position: 'top',
                    color: 'warning',
                    duration: 3000,
                    message: 'Accès aux fichiers refusé.',
                });

                await toast.present();
                return;
            }
        }
        let image: Photo;
        if (this.isCapacitor && this.isAndroid) {
            image = await Camera.getPhoto({
                quality: 70,
                allowEditing: false,
                resultType: CameraResultType.Base64,
                source: CameraSource.Prompt,
                promptLabelPhoto: 'Depuis la galerie',
                promptLabelPicture: 'Prendre une photo',
                saveToGallery: true,
            });
        } else {
            image = await Camera.getPhoto({
                quality: 70,
                allowEditing: false,
                resultType: CameraResultType.Base64,
                source: CameraSource.Photos,
                saveToGallery: true,
            });
        }
        const imgageBase64 = image.base64String;
        const myId = uuidv4();

        if (!imgageBase64) {
            return;
        }
        const file = OmedomTransformer.base64ToFile(imgageBase64, myId + '.jpg');
        const path = `assets/images/${file.name}`;
        this.pendingPhoto$.next(true);

        //The main task
        const task = await this.storage.upload(path, file);

        // The file's download URL
        try {
            await task.ref.getDownloadURL().then(async (url) => {
                if (url && this.property) {
                    this.property.photo = url;
                    await this.propertyService.update({
                        uid: this.property.uid,
                        photo: url,
                    });
                }
            });
            this.dismiss();
        } catch (error) {
            console.error(error);
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
        }
        this.pendingPhoto$.next(false);
    }
}

import { RoleAbsolute, RoleRightInterface, RoleRightTranslated } from '@omedom/data';

export class RoleRightAdapter {
    private roleRightTranslated: RoleRightTranslated;
    private essentialRole: RoleRightInterface;
    private smartRole: RoleRightInterface;

    constructor(roleRightTranslated: RoleRightTranslated) {
        this.roleRightTranslated = roleRightTranslated;
        this.essentialRole = {
            admin: [
                this.roleRightTranslated.read,
                this.roleRightTranslated.create,
                this.roleRightTranslated.change,
                this.roleRightTranslated.delete,
            ],
            editor: [
                this.roleRightTranslated.read,
                this.roleRightTranslated.change,
                this.roleRightTranslated.validate,
            ],
            reader: [this.roleRightTranslated.read],
            none: [this.roleRightTranslated.none],
        };

        this.smartRole = {
            admin: [this.roleRightTranslated.documentComplete, this.roleRightTranslated.send],
            editor: [this.roleRightTranslated.documentComplete],
            reader: [this.roleRightTranslated.documentPartial],
        };
    }
    public getAdminEssantialRight() {
        return this.essentialRole.admin;
    }
    public getAdminSmartRight() {
        return this.smartRole.admin;
    }

    public getOwnerEssantialRight() {
        return this.essentialRole.admin;
    }
    public getOwnerSmartRight() {
        return this.smartRole.admin;
    }

    public getEditorEssantialRight() {
        return this.essentialRole.editor;
    }
    public getEditorSmartRight() {
        return this.smartRole.editor;
    }

    public getReaderEssantialRight() {
        return this.essentialRole.reader;
    }
    public getReaderSmartRight() {
        return this.smartRole.reader;
    }

    public getNoneRight() {
        return this.essentialRole.none;
    }

    public getAdminCompleteDescription() {
        return [
            ...this.getAdminEssantialRight(),
            this.roleRightTranslated.smartAdminOptions,
            ...this.getAdminSmartRight(),
        ];
    }
    public getOwnerCompleteDescription() {
        return this.getAdminCompleteDescription();
    }

    public getEditorCompleteDescription() {
        return [
            ...this.getEditorEssantialRight(),
            this.roleRightTranslated.smartAdminOptions,
            ...this.getEditorSmartRight(),
        ];
    }

    public getReaderCompleteDescription() {
        return [
            ...this.getReaderEssantialRight(),
            this.roleRightTranslated.smartReaderOptions,
            ...this.getReaderSmartRight(),
        ];
    }

    public getRightsByRole(role: RoleAbsolute) {
        switch (role) {
            case 'owner':
                return this.getAdminCompleteDescription();
            case 'admin':
                return this.getAdminCompleteDescription();
            case 'editor':
                return this.getEditorCompleteDescription();
            case 'reader':
                return this.getReaderCompleteDescription();
            default:
                return this.getNoneRight();
        }
    }
    // TODO: dans popup voir dans figme, ou input pour enlever icone et marge left
}

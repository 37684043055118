/* eslint-disable no-unused-vars */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import {
    DocumentEntity,
    DocumentTypeClient,
    DocumentTypeUsed,
    EntityTypes,
    PropertyEntity,
    RoleState,
    SelectOption,
    SocietyEntity,
    UserEntity,
} from '@omedom/data';
import {
    AnalyticsService,
    DocumentService,
    LoanService,
    PropertyService,
    RoleService,
    SavingService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { OmedomStorage } from '@omedom/utils';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'omedom-document-form',
    templateUrl: './document-form.component.html',
    styleUrls: ['./document-form.component.scss'],
})
export class DocumentFormComponent implements OnInit, OnDestroy {
    /**
     * @description Property uid of the document form
     * @author Brisset Killian
     * @date 19/06/2024
     * @type {string}
     * @memberof DocumentFormComponent
     */
    @Input()
    propertyUid?: string;

    /**
     * @description Society uid of the document form
     * @author Brisset Killian
     * @date 19/06/2024
     * @type {string}
     * @memberof DocumentFormComponent
     */
    @Input()
    societyUid?: string;

    /**
     * @description Document uid if the document already exist
     * @author Brisset Killian
     * @date 19/06/2024
     * @type {string}
     * @memberof DocumentFormComponent
     */
    @Input()
    documentUid?: string;

    @Input()
    loanUid: any;

    @Input()
    savingUid: any;

    @Input() navigationAfterSubmit = true;

    /**
     * @description The type of document used, like for client, pro and importable, or pro and not importable,
     *  that will allow to display the correct type
     * @author ANDRE Felix
     * @type {DocumentTypeUsed}
     * @memberof DocumentFormComponent
     */
    @Input() DocumentTypeUsed: DocumentTypeUsed = DocumentTypeUsed.client;

    /**
     * @description is user creating document is a pro
     * @author ANDRE Felix
     * @memberof DocumentFormComponent
     */
    @Input() isPro = false;

    /**
     * @description
     * @author ANDRE Felix
     * @memberof DocumentFormComponent
     */
    @Input() isDocumentTypeFree = false;

    /**
     * @description Document form group
     * @author Jérémie Lopez
     * @type {FormGroup}
     * @memberof DocumentFormComponent
     */
    public documentForm?: UntypedFormGroup;

    /**
     * @description Date of the day
     * @author Jérémie Lopez
     * @private
     * @memberof DocumentFormPage
     */
    private now = new Date().toUTC();

    /**
     * @description Property list of the user
     * @author Jérémie Lopez
     * @type {PropertyEntity[]}
     * @memberof DocumentFormComponent
     */
    public properties: PropertyEntity[] = [];

    /**
     * @description Type of docs placeholder
     * @author Jérémie Lopez
     * @memberof DocumentFormComponent
     */
    public typePlaceholder = { label: 'Type de document' } as SelectOption;

    /**
     * @description If true, we are dragging a file in the drop zone
     * @type {boolean}
     * @author Brisset Killian
     * @date 19/06/2024
     * @memberof DocumentFormComponent
     */
    public isDragOver: boolean = false;

    /**
     * @description is user on desktop
     * @author ANDRE Felix
     * @memberof DocumentFormComponent
     */
    isDesktop = true;

    /**
     * @description Type of docs list
     * @author Jérémie Lopez
     * @memberof DocumentFormComponent
     */
    public typeOptions = DocumentEntity.getDocumentTypeOptionsByTypeUsed(this.DocumentTypeUsed);

    /**
     * @description Placeholder of the property input
     * @author Jérémie Lopez
     * @memberof DocumentFormComponent
     */
    public propertyPlaceholder = {
        label: 'Bien',
        icon: 'uil uil-home',
    } as SelectOption;

    /**
     * @description Options of the property input
     * @author Brisset Killian
     * @date 18/06/2024
     * @type {SelectOption[]}
     * @memberof DocumentFormComponent
     */
    public assetOptions: SelectOption[] = [];

    /**
     * @description Placeholder of the loan input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @memberof DocumentFormPage
     */
    public loanPlaceholder = {
        label: 'Crédit',
        icon: 'uil uil-euro-circle',
    } as SelectOption;

    /**
     * @description Options of the loan input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @memberof DocumentFormPage
     */
    public loanOptions: SelectOption[] = [];

    /**
     * @description Placeholder of the saving input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @memberof DocumentFormPage
     */
    public savingPlaceholder = {
        label: 'Épargne',
        icon: 'uil uil-wallet',
    } as SelectOption;

    /**
     * @description Options of the saving input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @memberof DocumentFormPage
     */
    public savingOptions: SelectOption[] = [];

    /**
     * @description User data
     * @author Jérémie Lopez
     * @type {UserEntity}
     * @memberof DocumentFormComponent
     */
    public user?: UserEntity;

    /**
     * @description File in the app
     * @author Jérémie Lopez
     * @type {File}
     * @memberof DocumentFormComponent
     */
    public file?: File;

    /**
     * @description If true, the app is pending an action
     * @author Jérémie Lopez
     * @memberof DocumentFormComponent
     */
    public pending$ = new BehaviorSubject<boolean>(false);

    /**
     * @description If true, the file is uploaded in the app
     * @author Jérémie Lopez
     * @memberof DocumentFormComponent
     */
    public uploaded$ = new BehaviorSubject<boolean>(false);

    /**
     * @description List of subscriptions to delete after component destroy
     * @author Jérémie Lopez
     * @private
     * @type {Subscription[]}
     * @memberof DocumentFormComponent
     */
    private subscriptions: Subscription[] = [];

    /**
     * @description Document passed by the router
     * @author Jérémie Lopez
     * @type {DocumentEntity}
     * @memberof DocumentFormComponent
     */
    public document?: DocumentEntity;

    /**
     * @description Role right of the user on the story
     * @author Didier Pascarel
     * @type {RoleState}
     * @memberof  DocumentCardComponent
     */
    roleRight?: RoleState;
    /**
     * @description True if the type is different from Img
     * @author ANDRE Felix
     * @memberof DocumentFormComponent
     */
    isValidFileType = true;

    public user$: Observable<UserEntity>;

    private propertiesOptions: SelectOption[] = [];

    private societiesOptions: SelectOption[] = [];

    isOptionPropertyReady = false;
    isOptionLoanReady = false;
    isOptionSavingReady = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private userService: UserService,
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private storage: AngularFireStorage,
        private toast: ToastController,
        private documentService: DocumentService,
        private navController: NavController,
        private route: ActivatedRoute,
        private roleService: RoleService,
        private platform: Platform,
        private analyticsService: AnalyticsService,
        private modalController: ModalController,
        private readonly loanService: LoanService,
        private readonly savingService: SavingService
    ) {
        this.user$ = this.userService.user$;
        this.isDesktop = this.platform.is('desktop');
    }

    async ngOnInit(): Promise<void> {
        this.typeOptions = DocumentEntity.getDocumentTypeOptionsByTypeUsed(this.DocumentTypeUsed);

        // Get the document passed by the router
        const documentId = this.documentUid ?? this.route.snapshot.params['uid'];
        if (documentId) {
            this.document = await this.documentService.get(documentId);
            if (this.document) {
                await this.updateRoleRight();
            }
        }

        const user$ = combineLatest([
            this.userService.user$,
            this.route.queryParams.pipe(startWith(this.route.snapshot.queryParams)),
        ]).subscribe(async ([user, queryParams]) => {
            this.user = user;

            this.initForm();

            await this.setassetOptions(user);

            await this.setLoanOptions(user);
            await this.setSavingOptions(user);

            // Retrieve the property uid from the route
            this.propertyUid =
                this.document?.propertyUID ?? this.propertyUid ?? queryParams['propertyUid'];
            this.societyUid =
                this.document?.societyUID ?? this.societyUid ?? queryParams['societyUid'];

            this.loanUid = this.document?.loanUID ?? this.loanUid ?? queryParams['loanUid'];
            this.savingUid = this.document?.savingUID ?? this.savingUid ?? queryParams['savingUd'];

            if (this.propertyUid) {
                this.documentForm?.get('assetUID')?.setValue(this.propertyUid);
            }

            if (this.societyUid) {
                this.documentForm?.get('assetUID')?.setValue(this.societyUid);
            }

            if (this.loanUid) {
                const loanOption = this.loanOptions.find((option) => option.id === this.loanUid);
                this.documentForm?.get('loanUID')?.setValue(loanOption?.id ?? null);
            }

            if (this.savingUid) {
                const savingOption = this.savingOptions.find(
                    (option) => option.id === this.savingUid
                );
                this.documentForm?.get('savingUID')?.setValue(savingOption?.id ?? null);
            }

            if (this.document) {
                this.documentForm
                    ?.get('assetUID')
                    ?.setValue(
                        this.assetOptions.find(
                            (option) =>
                                option.id === this.document?.propertyUID ||
                                option.id === this.document?.societyUID
                        )?.id ?? null
                    );

                this.documentForm
                    ?.get('loanUID')
                    ?.setValue(
                        this.loanOptions.find((option) => option.id === this.document?.loanUID)
                            ?.id ?? null
                    );

                this.documentForm
                    ?.get('savingUID')
                    ?.setValue(
                        this.savingOptions.find((option) => option.id === this.document?.savingUID)
                            ?.id ?? null
                    );
            }
            this.isOptionPropertyReady = false;
            this.isOptionLoanReady = false;
            this.isOptionSavingReady = false;
        });
        this.subscriptions.push(user$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    ionViewDidEnter() {
        this.analyticsService.logEvent('document form page opened');
        this.analyticsService.setCurrentScreen('Document Page');
    }

    ionViewWillLeave() {
        this.deleteFile();
    }

    private async setassetOptions(user: UserEntity) {
        const properties = await this.propertyService.getUserPropertiesAndSharedAccessible(
            user.uid
        );

        const filteredProperties = (
            await Promise.all(
                properties.map(async (property) => {
                    const roleState = await this.roleService.getRoleState(
                        property,
                        EntityTypes.property
                    );
                    return roleState?.update ? property : null;
                })
            )
        ).filter((x) => x) as PropertyEntity[];

        const societies = !this.propertyUid
            ? await this.societyService.getUserSocietiesAndShared(user.uid)
            : [];

        const filteredSocieties = (
            await Promise.all(
                societies.map(async (society) => {
                    const roleState = await this.roleService.getRoleState(
                        society,
                        EntityTypes.society
                    );
                    return roleState?.update ? society : null;
                })
            )
        ).filter((x) => x) as SocietyEntity[];

        this.propertiesOptions = filteredProperties
            .filter(
                (x) =>
                    this.propertyService.isPropertyAccessible(x) &&
                    (!this.propertyUid ||
                        x.uid === this.propertyUid ||
                        x.parentPropertyUID === this.propertyUid) &&
                    (!this.societyUid || x.societyUID === this.societyUid)
            )
            .map((property) => property.toSelectOption());

        this.societiesOptions = filteredSocieties
            .map((society) => {
                if (society.toSelectOption) {
                    return society.toSelectOption();
                }
                return null;
            })
            .filter((x) => x) as SelectOption[];

        this.assetOptions = [...this.propertiesOptions, ...this.societiesOptions];
    }

    /**
     * @description Set the loan options of the user in the form if the user has loans, else set an empty array in the form options of the loan input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @private
     * @param {UserEntity} user
     * @memberof DocumentFormPage
     */
    private async setLoanOptions(user: UserEntity): Promise<void> {
        this.loanOptions = (await this.loanService.getLoansFromUser(user.uid)).map((loan) => {
            return {
                id: loan.uid,
                label: loan.name,
                icon: 'uil uil-euro-circle',
            };
        });
    }

    /**
     * @description Set the saving options of the user in the form if the user has savings, else set an empty array in the form options of the saving input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/07/2024
     * @private
     * @param {UserEntity} user
     * @returns {Promise<void>}
     * @memberof DocumentFormPage
     */
    private async setSavingOptions(user: UserEntity): Promise<void> {
        this.savingOptions = (await this.savingService.getSavingsFromUser(user.uid)).map(
            (saving) => {
                return {
                    id: saving.uid,
                    label: saving.name,
                    icon: 'uil uil-wallet',
                };
            }
        );
    }

    public getFileName(name: string, extensionName: string): string {
        return `${name.toLowerCase().replace(/ /g, '_')}.${extensionName}`;
    }

    /**
     * @description Create the form
     * @author Jérémie Lopez
     * @private
     * @memberof DocumentFormComponent
     */
    private initForm(): void {
        this.documentForm = this.formBuilder.group({
            name: [this.document?.name ?? '', Validators.required],
            type: [this.document?.type ?? ''],
            date: [this.document?.date ?? null],
            assetUID: [
                this.document?.propertyUID ??
                    this.document?.societyUID ??
                    this.propertyUid ??
                    this.societyUid ??
                    null,
            ],
            loanUID: [
                this.document?.loanUID
                    ? this.loanOptions.find((option) => option.id === this.document?.loanUID)?.id ??
                      null
                    : null,
            ],
            savingUID: [
                this.document?.savingUID
                    ? this.savingOptions.find((option) => option.id === this.document?.savingUID)
                          ?.id ?? null
                    : null,
            ],
        });
    }

    /**
     * @description Keep the file in the app via the input
     * @author Jérémie Lopez
     * @param {FileList} event
     * @return {*}  {void}
     * @memberof DocumentFormComponent
     */
    public keepFile(event: FileList | null): void {
        if (!event?.item(0)) {
            this.uploaded$.next(false);
            return;
        }
        this.isValidFileType = true;

        // The File object
        this.file = event.item(0) ?? undefined;
        this.uploaded$.next(true);
    }

    /**
     * @description Delete the file in the app
     * @author Jérémie Lopez
     * @memberof DocumentFormComponent
     */
    public deleteFile(): void {
        delete this.file;
        this.isValidFileType = true;
    }

    /**
     * @description Send file
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof DocumentFormComponent
     */
    public async submit(): Promise<void> {
        this.analyticsService.logEvent('document submitted');
        this.pending$.next(true);

        try {
            // Upload file to firebase storage
            let url;
            let extensionName;

            if (this.file) {
                extensionName = this.getExtensionFromFile(this.file);
                if (extensionName) {
                    const task = await this.storeFile(extensionName);
                    url = await task.ref.getDownloadURL();
                }
            }

            const assetUID = this.documentForm?.get('assetUID')?.value;

            const propertyUID = this.propertiesOptions.find((x) => x.id === assetUID)?.id ?? null;
            const societyUID = this.societiesOptions.find((x) => x.id === assetUID)?.id ?? null;

            // Create entity
            const document: Partial<DocumentEntity> = {
                name: this.documentForm?.get('name')?.value,
                date: this.documentForm?.get('date')?.value
                    ? new Date(this.documentForm?.get('date')?.value)
                    : undefined,
                type: this.documentForm?.get('type')?.value ?? null,
                propertyUID: propertyUID ?? this.document?.propertyUID ?? null,
                societyUID: societyUID ?? this.document?.societyUID ?? null,
                loanUID: this.loanUid
                    ? this.loanUid
                    : this.documentForm?.get('loanUID')?.value ?? null,
                savingUID: this.savingUid
                    ? this.savingUid
                    : this.documentForm?.get('savingUID')?.value ?? null,
                fileURL: url ?? this.document?.fileURL ?? undefined,
                weight: (this.file ? this.file.size : this.document?.weight) ?? 0,
                extension: (this.file ? this.file.type : this.document?.extension) ?? undefined,
                extensionName:
                    (this.file ? extensionName : this.document?.extensionName) ?? undefined,
                userUID: this.document?.userUID ?? this.user?.uid,
                isForPro: this.isPro,
            };

            // Remove all undefined values
            Object.keys(document).forEach(
                (key) => (document as any)[key] === undefined && delete (document as any)[key]
            );

            if (this.document) {
                document.uid = this.document.uid;
                await this.documentService.update(document);
            } else {
                await this.documentService.create(document);
            }

            // Send to user the info that the doc is created
            this.pending$.next(false);
            const toast = await this.toast.create({
                position: 'top',
                color: 'primary',
                duration: 4000,
                message: this.document
                    ? 'Vous avez modifié un document.'
                    : 'Vous avez créé un document.',
            });
            await toast.present();

            this.navigationBack();
        } catch (error) {
            console.error(error);
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
            this.pending$.next(false);
        }
    }

    public async navigationBack(): Promise<void> {
        if (this.navigationAfterSubmit) {
            this.navController.back();
        }
        if (await this.modalController.getTop()) {
            await this.modalController.dismiss();
        }
    }

    private getExtensionFromFile(file: File) {
        return this.file?.name.split('.').pop() ?? null;
    }
    private async storeFile(FileExtention: string) {
        const uid = uuidv4();

        return await this.storage.upload(
            `documents/${uid}/${this.getFileName(
                this.documentForm?.get('name')?.value,
                FileExtention
            )}`,
            this.file
        );
    }

    /**
     * @description Get the staging of the document in the app or in the database if the document is already created in the database or 0 if the document is not created in the app or in the database yet
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/09/2023
     * @readonly
     * @type {number}
     * @memberof DocumentFormComponent
     */
    public get staging(): number {
        // Check if the document is created in the app
        const initialWeight = this.document?.weight ?? 0;

        if (this.file) {
            return Math.max(this.file.size - initialWeight, 0);
        }

        return initialWeight;
    }

    /**
     * @description Disable submit button if the form is invalid or if the user is null or if the storage is full or if the file is not uploaded or if the app is pending an action
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/09/2023
     * @returns {boolean} If the submit button is disabled
     * @memberof DocumentFormComponent
     */
    get disableSubmit(): boolean {
        // Check if the user is null
        if (!this.user) {
            return true;
        }

        // Check if the storage is full
        if (OmedomStorage.isStorageFull(this.user, this.staging)) {
            return true;
        }

        // Check if the form is valid
        if (this.documentForm?.invalid) {
            return true;
        }

        // Check if the file is uploaded
        if (!this.file && !this.document?.fileURL) {
            return true;
        }

        // Check if the app is pending an action
        if (this.pending$.value) {
            return true;
        }

        return false;
    }

    async updateRoleRight() {
        if (this.document) {
            const roleState$ = this.roleService
                ._getRoleState(this.document, EntityTypes.document)
                .subscribe((roleState) => {
                    this.roleRight = roleState;
                });
            this.subscriptions.push(roleState$);
        }
    }

    /**
     * @description Fire when file is dropped in the drop zone
     * @author ANDRE Felix
     * @param {DragEvent} event
     * @memberof DocumentFormComponent
     */
    dropHandler(event: DragEvent) {
        event.preventDefault();
        this.isDragOver = false;
        const dropFiles = event.dataTransfer?.files;
        if (!dropFiles) {
            return;
        }
        const imgFile = this.getFirstImgFile(dropFiles);
        this.setDropFile(imgFile);
    }

    setDropFile(file: File | undefined) {
        if (file) {
            this.isValidFileType = true;
            this.file = file;
            this.uploaded$.next(true);
        } else {
            this.isValidFileType = false;
        }
    }

    /**
     * @description Event fire when dragOver
     * @author ANDRE Felix
     * @param {DragEvent} event
     * @memberof DocumentFormComponent
     */
    dragOverHandler(event: DragEvent) {
        event.preventDefault();
        this.isDragOver = true;
    }

    getFirstImgFile(filesUpload: FileList) {
        var allowedExtensions = /(\jpg|\jpeg|\png|\tiff|\tif|\pdf)$/i;

        for (let index = 0; index < filesUpload.length; index++) {
            if (allowedExtensions.exec(filesUpload[index].type)) {
                return filesUpload[index];
            }
        }

        return undefined;
    }
}

/**
 * @description Primitive type of a data (string, number, boolean, date, array, object) to display in the table or in a form
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 12/09/2023
 * @export
 * @enum {number}
 */
export enum Primitive {
    string = 'string',
    number = 'number',
    boolean = 'boolean',
    date = 'date',
    array = 'array',
    object = 'object',
    picture = 'picture',
}

import { FormControl } from '@angular/forms';
import { ChargePeriodicity, IncomePeriodicity } from '@omedom/data';

/**
 * @description Bank transaction details form type
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 20/05/2024
 * @type {BankTransactionDetailsForm}
 * @memberof BankTransactionDetailsStepContainer
 */
export type BankTransactionDetailsForm = {
    /**
     * @description Is the transaction a new treasury to create (charge or income)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @type {FormControl<boolean>}
     */
    isNewTreasury: FormControl<boolean>;

    /**
     * @description Treasury UID (mandatory if isNewTreasury is false) to associate the transaction to a treasury (charge or income)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @type {FormControl<string>}
     */
    treasuryUID: FormControl<string | null>;

    /**
     * @description Periodicity of the transaction (mandatory if isNewTreasury is true)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @type {(FormControl<ChargePeriodicity | IncomePeriodicity>)}
     */
    periodicity: FormControl<ChargePeriodicity | IncomePeriodicity>;

    /**
     * @description Notes of the transaction (optional) to add a description or a comment to the transaction details (charge or income) to display
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @type {FormControl<string>}
     */
    notes: FormControl<string>;
};

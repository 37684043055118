import { RestEntity } from './rest.entity';
import { SubscriptionRank } from '../enums';
import { Sharing } from './property.entity';

export type SubscriptionDefinition = {
    subscriptionName: SubscriptionRank;
    subscriptionAppleId?: string;
    subscriptionColor: string;
    subscriptionSubTitle: string;
    subscriptionPrice: string;
};

export enum SubscriptionType {
    MONTHLY = 'MONTHLY',
    ANNUALLY = 'ANNUALLY',
}

export type SubscriptionPrices = {
    [key in SubscriptionType]?: number;
};

export enum AppleIDs {
    SMART1 = 'SMART1',
    PREMIUM1 = 'PREMIUM1',
    PREMIUM2 = 'PREMIUM2',
    FAMILY1 = 'FAMILY1',
    FAMILY2 = 'FAMILY2',
};

export type SubscriptionAppleIDs = {
    [key in SubscriptionType]?: AppleIDs;
};

export class SubscriptionEntity extends RestEntity {
    /**
     * @description Amount of the subscription
     * @author Jérémie Lopez
     * @type {number}
     * @memberof SubscriptionEntity
     */
    amount!: number;

    /**
     * @description Currency of the subscription (always 'EUR')
     * @author Jérémie Lopez
     * @type {string}
     * @memberof SubscriptionEntity
     */
    currency = 'EUR';

    /**
     * @description Type of the subscription
     * @author Jérémie Lopez
     * @type {SubscriptionType}
     * @memberof SubscriptionEntity
     */
    subscriptionType!: SubscriptionType;

    /**
     * @description Properties assigned to the subscription
     * @author Jérémie Lopez
     * @type {string[]}
     * @memberof SubscriptionEntity
     */
    propertiesUID!: string[];

    /**
     * @description User assigned to the subscription
     * @author Jérémie Lopez
     * @type {string}
     * @memberof SubscriptionEntity
     */
    userUID!: string;

    /**
     * @description Date to renew the subscription
     * @author Jérémie Lopez
     * @type {string}
     * @memberof SubscriptionEntity
     */
    renewDate!: string;

    /**
     * @description Is the subscription has been asked to be canceled by the user
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof SubscriptionEntity
     */
    cancelSubscription?: boolean;

    /**
     * @description Card ID in payplug
     * @author Jérémie Lopez
     * @type {(string | null)}
     * @memberof SubscriptionEntity
     */
    card!: string | null;

    /**
     * @description Is the subscription from Apple Store
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof SubscriptionEntity
     */
    isApple?: boolean;

    /**
     * @description Is the subscription a trial
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof SubscriptionEntity
     */
    trial?: boolean;

    /**
     * @description Apple infos (if the subscription is from Apple Store) (optional field) (if the subscription is from Apple Store, this field is filled with the infos from Apple Store, if not, this field is empty)
     * @author Jérémie Lopez
     * @type {{
     *         transactionId: string;
     *         receipt: string;
     *         signature: string;
     *         productType: string;
     *     }}
     * @memberof SubscriptionEntity
     */
    appleInfos?: {
        transactionId: string;
        receipt: string;
        signature: string;
        productType: string;

    };

    /**
     * @description ID of the apple subscription (if the subscription is from Apple Store) (optional field) (if the subscription is from Apple Store, this field is filled with the infos from Apple Store, if not, this field is empty)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 16/01/2024
     * @type {string}
     * @memberof SubscriptionEntity
     */
    transactionId?: string;

    /**
     * @description If the user is assigned to a pro via a licence
     * @author Jérémie Lopez
     * @type {string}
     * @memberof SubscriptionEntity
     */
    proUID?: string;

    /**
     * @description Desciption of paied subscription
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {string}
     * @memberof SubscriptionEntity
     */
    description?: string;

    /**
     * @description If the user has a licence assigned to his subscription
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @type {string}
     * @memberof SubscriptionEntity
     */
    licenceUID?: string;

    /**
     * @description Subscription rank of the user
     * @author Vidjay Seganti <vidjay.seganti.external@omedom.com>
     * @date 15/01/2024
     * @type {SubscriptionRank}
     * @memberof SubscriptionEntity
     */
    rank?: SubscriptionRank;

    /**
     * @description Future subscription rank of the user
     * @author Vidjay Seganti <vidjay.seganti.external@omedom.com>
     * @date 15/01/2024
     * @type {SubscriptionRank}
     * @memberof SubscriptionEntity
     */
    futureRank?: SubscriptionRank;

    /**
     * @description Billing informations of the user
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 24/11/2019
     * @type {string}
     * @memberof SubscriptionEntity
     */
    billingInformations?: BillingInformation;

    /**
     * @description: Get the subscription rank of the user
     * @author Vidjay SEGANTI (Keleo)
     * @memberof SubscriptionEntity
     */
    family?: Sharing[];

    /**
     * @description Transform the renewDate from a string to a Date object
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/02/2024
     * @static
     * @param {string} renewDate
     * @returns {*}  {Date}
     * @memberof SubscriptionEntity
     */
    public static transformRenewDate(renewDate: string): Date {
        const split = renewDate.split('/');

        return new Date(parseInt(split[2], 10), parseInt(split[1], 10) - 1, parseInt(split[0], 10));
    }
}

export type BillingInformation = {
    address: string;
    city: string;
    postalCode: string;
};

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
    AllChargeCategories,
    AllIncomeCategories,
    AssetTypes,
    BankAccountEntity,
    BankTransactionEntity,
    BankTransactionRecurrenceBase,
    BankTransactionRecurrenceResult,
    ChargePeriodicity,
    IncomePeriodicity,
} from '@omedom/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RestService, WhereSearchParameter } from './rest.service';

@Injectable({ providedIn: 'root' })
export class BankTransactionService extends RestService<BankTransactionEntity> {
    protected override builder = BankTransactionEntity;

    constructor(
        protected override firestore: AngularFirestore,
        private functions: AngularFireFunctions
    ) {
        super(firestore, 'bankTransactions');
    }

    /**
     * @description Return bank items of the user in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/04/2024
     * @param {BankAccountEntity} account
     * @returns {Observable<BankTransactionEntity[]>}
     * @memberof BankTransactionService
     */
    public _getBankTransactionsFromAccount(
        account: BankAccountEntity,
        userUID?: string
    ): Observable<BankTransactionEntity[]> {
        // Define filters
        const filters: WhereSearchParameter[] = [
            {
                where: 'accountID',
                operator: '==',
                value: account.bridgeID,
            },
        ];

        // Add user filter if needed
        if (userUID) {
            filters.push({
                where: 'userUID',
                operator: '==',
                value: userUID,
            });
        }

        return this._search(filters);
    }

    public _getBankTransactionsBetweenDates(
        account: BankAccountEntity,
        start: Date,
        end: Date,
        userUID?: string
    ): Observable<BankTransactionEntity[]> {
        // Define filters
        const filters: WhereSearchParameter[] = [
            {
                where: 'accountID',
                operator: '==',
                value: account.bridgeID,
            },
            {
                where: 'date',
                operator: '>=',
                value: start,
            },
            {
                where: 'date',
                operator: '<=',
                value: end,
            },
        ];

        if (userUID) {
            filters.push({
                where: 'userUID',
                operator: '==',
                value: userUID,
            });
        }

        return this._search(filters);
    }

    /**
     * @description Search for recurrence of a transaction in the treasury items of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 14/05/2024
     * @param {BankTransactionEntity} transaction
     * @param {BankTransactionRecurrenceBase} base
     * @returns {Observable<BankTransactionRecurrenceResult[]>}
     * @memberof BankTransactionService
     */
    public _searchForRecurrence(
        transaction: BankTransactionEntity,
        base: BankTransactionRecurrenceBase
    ): Observable<BankTransactionRecurrenceResult[]> {
        return this.functions
            .httpsCallable<
                { transaction: BankTransactionEntity; base: BankTransactionRecurrenceBase },
                BankTransactionRecurrenceResult[]
            >('searchForRecurrence')({
                transaction,
                base,
            })
            .pipe(
                map((result) => {
                    // Map the result to the right entity
                    result.forEach((item) => {
                        item.details.date.transaction = new Date(item.details.date.transaction);
                        item.details.date.treasury = new Date(item.details.date.treasury);
                    });

                    return result;
                })
            );
    }

    /**
     * @description Mask a transaction to hide it from the user view
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @param {BankTransactionEntity} transaction
     * @returns {Promise<void>}
     * @memberof BankTransactionService
     */
    public async mask(transaction: BankTransactionEntity): Promise<void> {
        transaction.masked = true;

        await this.update({
            uid: transaction.uid,
            masked: true,
        });
    }

    /**
     * @description Unmask a transaction to show it to the user view again
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @param {BankTransactionEntity} transaction
     * @returns {Promise<void>}
     * @memberof BankTransactionService
     */
    public async unmask(transaction: BankTransactionEntity): Promise<void> {
        transaction.masked = false;

        await this.update({
            uid: transaction.uid,
            masked: false,
        });
    }

    /**
     * @description Associate a transaction to a treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/05/2024
     * @param {({
     *         patrimonyUID: string;
     *         patrimonyType: AssetTypes;
     *         transactionUID: string;
     *         treasuryUID?: string;
     *         periodicity: ChargePeriodicity | IncomePeriodicity;
     *         category: AllChargeCategories | AllIncomeCategories;
     *         notes?: string,
     *         reccurences: string[];
     *     })} props
     * @returns {Observable<void>}
     * @memberof BankTransactionService
     */
    public _associate(props: {
        patrimonyUID: string;
        patrimonyType: AssetTypes;
        transactionUID: string;
        treasuryUID?: string;
        periodicity: ChargePeriodicity | IncomePeriodicity;
        category: AllChargeCategories | AllIncomeCategories;
        notes?: string;
        reccurences: string[];
    }): Observable<void> {
        return this.functions.httpsCallable<
            {
                patrimonyUID: string;
                patrimonyType: AssetTypes;
                transactionUID: string;
                treasuryUID?: string;
                periodicity: ChargePeriodicity | IncomePeriodicity;
                category: AllChargeCategories | AllIncomeCategories;
                notes?: string;
                reccurences: string[];
            },
            void
        >('associateTransaction')(props);
    }

    /**
     * @description Dissociate a transaction from a treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/05/2024
     * @param {BankTransactionEntity} transaction
     * @returns {Observable<void>}
     * @memberof BankTransactionService
     */
    public dissociate(transaction: BankTransactionEntity): Observable<void> {
        return this.functions.httpsCallable<
            {
                transactionUID: string;
                treasuryUID?: string;
            },
            void
        >('dissociateTransactionToTreasury')({
            transactionUID: transaction.uid,
            treasuryUID: transaction.treasuryUID,
        });
    }
}

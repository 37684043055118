import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SortParameter } from '@omedom/data';
import { OmedomRadioOption } from '../radio/omedom-radio-option';

@Component({
    selector: 'omedom-property-society-sort',
    templateUrl: './property-society-sort.component.html',
    styleUrls: ['./property-society-sort.component.scss'],
})
export class PropertySocietySortComponent implements OnInit {
    /**
     * @description Sort options
     * @author Jérémie Lopez
     * @type {OmedomRadioOption[]}
     * @memberof PropertySocietySortComponent
     */
    public options: OmedomRadioOption[] = [
        new OmedomRadioOption({
            id: { where: 'name', order: 'asc' },
            label: 'Ordre alphabétique',
        }),
        new OmedomRadioOption({
            id: { where: 'created', order: 'desc' },
            label: 'Date de création : du plus récent au plus ancien',
        }),
        new OmedomRadioOption({
            id: { where: 'created', order: 'asc' },
            label: 'Date de création : du plus ancien au plus récent',
        }),
        // new OmedomRadioOption({
        //     id: { where: 'rentability', order: 'desc' },
        //     label: 'Rentabilité : du plus au moins rentable',
        // }),
        // new OmedomRadioOption({
        //     id: { where: 'rentability', order: 'asc' },
        //     label: 'Rentabilité : du moins au plus rentable',
        // }),
    ];

    /**
     * @description Selected option
     * @author Jérémie Lopez
     * @memberof PropertySocietySortComponent
     */
    public selectedOption?: SortParameter;

    constructor(private modalController: ModalController) { }

    ngOnInit(): void {
        this.initState();
    }

    /**
     * @description Init state with default value
     * @author Jérémie Lopez
     * @private
     * @memberof PropertySocietySortComponent
     */
    private initState(): void {
        const clone = Object.assign(
            {},
            this.options.find(
                (element) =>
                    element.id.where === this.selectedOption?.where &&
                    element.id.order === this.selectedOption?.order
            )
        );
        delete this.selectedOption;
        setTimeout(() => {
            this.selectedOption = clone.id;
        });
    }

    /**
     * @description Close modal
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof PropertySocietySortComponent
     */
    public async close(data?: any): Promise<void> {
        await this.modalController.dismiss(data);
    }

    public async sendSort(): Promise<void> {
        await this.close({ sort: this.selectedOption });
    }
}

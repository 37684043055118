import { Timestamp } from 'firebase/firestore';

import { SavingType } from '../enums';
import { SavingExitConstraint } from '../interfaces';
import { RestEntity } from './rest.entity';

export class SavingEntity extends RestEntity {
    /**
     * @description Account linked to the saving
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {number}
     * @memberof SavingEntity
     */
    public accountID?: number;

    /**
     * @description Bank linked to the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {number}
     * @memberof SavingEntity
     */
    public bankID?: number;

    /**
     * @description Etablissement name linked to the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 15/07/2024
     * @type {string}
     * @memberof SavingEntity
     */
    public etablissement?: string;

    /**
     * @description Owner of the saving
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {string}
     * @memberof SavingEntity
     */
    public userUID!: string;

    /**
     * @description Name of the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {string}
     * @memberof SavingEntity
     */
    public name!: string;

    /**
     * @description Opening date of the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {Date}
     * @memberof SavingEntity
     */
    public openingDate?: Date;

    /**
     * @description Balance of the saving on the account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {number}
     * @memberof SavingEntity
     */
    public balance!: number;

    /**
     * @description Limit of the saving
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {number}
     * @memberof SavingEntity
     */
    public limit?: number;

    /**
     * @description
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {number}
     * @memberof SavingEntity
     */
    public interestRate?: number;

    /**
     * @description Interest amount of the saving account (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @type {number}
     * @memberof SavingEntity
     */
    public interestAmount?: number;

    /**
     * @description Type of saving
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {SavingType}
     * @memberof SavingEntity
     */
    public type?: SavingType;

    /**
     * @description Saving exit constraint (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {SavingExitConstraint}
     * @memberof SavingEntity
     */
    public exitConstraint?: SavingExitConstraint;

    /**
     * @description Amount to deposit in the saving account (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/07/2024
     * @type {number}
     * @memberof SavingEntity
     */
    public depositAmount?: number;

    /**
     * @description Amount to withdraw from the saving account (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/07/2024
     * @type {number}
     * @memberof SavingEntity
     */
    public withdrawAmount?: number;

    /**
     * @description Next deposit date for the saving account (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/07/2024
     * @type {Date}
     * @memberof SavingEntity
     */
    public nextDepositDate?: Date;

    /**
     * @description Next deposit amount for the saving account (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/07/2024
     * @type {number}
     * @memberof SavingEntity
     */
    public nextDepositAmount?: number;

    /**
     * @description Is the saving shared to the pro account or not (if any) for the saving account
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @type {boolean}
     * @memberof SavingEntity
     */
    sharedToPro?: boolean;

    constructor(data: Partial<SavingEntity>) {
        if (data?.openingDate instanceof Timestamp) {
            data.openingDate = new Date(data.openingDate.seconds * 1000);
        }

        if (data?.nextDepositDate instanceof Timestamp) {
            data.nextDepositDate = new Date(data.nextDepositDate.seconds * 1000);
        }

        if (data?.exitConstraint) {
            // Check if the minimum date is a timestamp
            if (data.exitConstraint.minimumDate instanceof Timestamp) {
                data.exitConstraint.minimumDate = new Date(
                    data.exitConstraint.minimumDate.seconds * 1000
                );
            }
        }

        super(data);
    }
}

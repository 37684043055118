import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LevelEntity } from '@omedom/data';
import { OmedomConfetti } from '@omedom/utils';

@Component({
    selector: 'omedom-level-up',
    templateUrl: './level-up.component.html',
    styleUrls: ['./level-up.component.scss'],
})
export class LevelUpComponent implements OnInit {
    /**
     * @description Level to display in the modal
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @type {LevelEntity}
     * @memberof LevelUpComponent
     */
    @Input()
    public level!: LevelEntity;

    constructor(private modalController: ModalController) {}

    ngOnInit() {
        OmedomConfetti.addConfetti();
    }

    /**
     * @description Dismiss the modal when the user click on the close button or on the background of the modal
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @memberof LevelUpComponent
     * @returns {void}
     * @example
     * this.dismiss();
     */
    public dismiss(): void {
        this.modalController.dismiss();
    }

    /**
     * @description Get the mask of the level to display on map of levels
     * @author Jérémie Lopez
     * @param {number} level Level to display
     * @return {string} Url of the mask
     * @memberof LevelUpComponent
     * @example
     * this.getLevelMask(1);
     */
    public getLevelMask(level: number): string {
        return `url("/assets/levels/${level}.svg") no-repeat center`;
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
    OwningType,
    PropertyEntity,
    PropertyPurchaseDetails,
    PropertyType,
    SelectOption,
    UseProperty,
} from '@omedom/data';
import { OmedomRegex } from '@omedom/utils';

@Component({
    selector: 'omedom-registration-second-step',
    templateUrl: './registration-second-step.component.html',
    styleUrls: ['./registration-second-step.component.scss'],
})
export class RegistrationSecondStepComponent implements OnInit {
    @Output() nextEnabled: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input({ required: true }) property!: PropertyEntity;

    get selectedDetention(): OwningType | undefined {
        return this.property?.purchaseDetails?.owning ?? this._selectedDetention;
    }
    set selectedDetention(owning: OwningType | undefined) {
        if (!owning) {
            return;
        }
        Object.assign(this.property, {
            purchaseDetails: {
                ...(this.property?.purchaseDetails ? this.property.purchaseDetails : {}),
                owning: owning,
            },
        });
        this._selectedDetention = owning;
        this.formValid();
    }

    _selectedDetention: OwningType | undefined;

    addressForm: FormGroup | undefined;

    formIsValid: boolean = false;

    isBuilding: boolean = false;

    codepostalRegex = OmedomRegex.postalCodeRegex;

    useOptions: SelectOption[] = [
        {
            id: UseProperty.principale,
            label: UseProperty.principale,
        },
        {
            id: UseProperty.secondaire,
            label: UseProperty.secondaire,
        },
        {
            id: UseProperty.locatifParticulier,
            label: UseProperty.locatifParticulier,
        },
        {
            id: UseProperty.locatifCommercial,
            label: UseProperty.locatifCommercial,
        },
        {
            id: UseProperty.locatifPro,
            label: UseProperty.locatifPro,
        },
    ];

    usePlaceholder = {
        id: null,
        label: 'Usage du bien',
    } as SelectOption;

    owningOptions: SelectOption[] = [
        {
            id: OwningType.proper,
            label: OwningType.proper,
        },
        {
            id: OwningType.society,
            label: OwningType.society,
        },
        {
            id: OwningType.community,
            label: OwningType.community,
        },
        {
            id: OwningType.indivision,
            label: OwningType.indivision,
        },
    ];

    owningPlaceholder = {
        id: null,
        label: 'Détention',
    } as SelectOption;

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.initPropertyForm();
        this.isBuilding = this.property?.type === PropertyType.immeuble;
        if (this.formValid()) {
            this.nextEnabled.emit(true);
        }
    }

    formValid(): boolean {
        const isValid = !!this.property?.purchaseDetails?.use && !!this.property?.name;

        if (this.formIsValid !== isValid) {
            this.formIsValid = isValid;
            this.nextEnabled.emit(isValid);
        }

        return isValid;
    }

    private initPropertyForm() {
        if (!this.property?.purchaseDetails) {
            Object.assign(this.property, {
                purchaseDetails: {} as PropertyPurchaseDetails,
            });
        }
        // TODO, l'uitiliser, et voir comment faire passer les données au parent,
        // La les données du @Input sont modifier vers le parent en utilisant la référence
        this.addressForm = this.formBuilder.group({
            streetNumber: [this.property?.address?.streetNumber ?? null, []],
            street: [this.property?.address?.street ?? '', []],
            addressLine2: [this.property?.address?.addressLine2 ?? '', []],
            postalCode: [this.property?.address?.postalCode ?? '', []],
            city: [this.property?.address?.city ?? '', []],
        });
    }
}

<div class="content">
    <div class="main-title" style="margin-bottom: 10px">
        <h3>
            <span>Conditions de Partage</span>
        </h3>
        @if (isModal) {
        <i
            class="uil uil-times-circle close"
            (click)="modalController.dismiss()"
        ></i>
        }
    </div>

    <h3 class="titles">AVEC QUI OMEDOM PARTAGE MES DONNÉES ?</h3>
    <div class="text">
        <p>
            L’Utilisateur peut choisir de partager certaines informations de son
            compte OMEDOM avec des tiers privés ou professionnels, membres de sa
            famille, associés d’une SCI, CGP (Conseiller en Gestion de
            Patrimoine), Notaire, Expert-Comptable, etc.
        </p>
        <p>
            Si l’Utilisateur est en relation avec un professionnel de la gestion
            patrimoniale, tel qu’un Conseiller en Gestion de Patrimoine, agent
            immobilier, un notaire etc. (ci-après le « Professionnel »), il peut
            avoir reçu de ce Professionnel des accès à la solution OMEDOM
            (ci-après « la Solution ») lui permettant de bénéficier, pendant une
            durée déterminée, des fonctionnalités de la Solution.
        </p>
        <p>
            L’Utilisateur peut également avoir sollicité la mise en relation
            avec un Professionnel grâce à l’Annuaire Pro accessible dans la
            Solution. Dans ce cas, si l’Utilisateur sélectionne ce Professionnel
            pour le partage d’informations, le Professionnel aura accès aux
            informations et documents partagés, à la condition que le
            Professionnel atteste d’une relation contractuelle avec
            l’Utilisateur.
        </p>
    </div>

    <h3 class="titles">
        DANS QUELLES CONDITIONS MES DONNÉES SONT PARTAGÉES&nbsp;?
    </h3>
    <div class="text">
        <p>
            OMEDOM ne partage les données de ses Utilisateurs que dans certains
            cas de figure (par exemple quand l’Utilisateur utilise la Solution
            grâce au Professionnel) et seulement si l’Utilisateur a consenti à
            ce partage.
        </p>
        <p>
            Autrement dit, si l’Utilisateur ne souhaite pas que le Professionnel
            ait accès à ses données, OMEDOM ne permettra pas un tel accès.
            L’Utilisateur n’est en aucun cas obligé de partager ses données avec
            le Professionnel. L’Utilisateur choisit seul les Professionnels
            auxquels il souhaite partager informations et documents, et il
            choisit les éléments patrimoniaux qu’il souhaite effectivement
            partager (par exemple un bien immobilier, une épargne, une société
            immobilière, etc.).
        </p>
        <p>
            Attention : lorsqu’un Utilisateur choisit de partager les
            informations relatives à un élément de son patrimoine avec un tiers
            ou un Professionnel, les documents attachés à ce patrimoine seront
            également partagés.
        </p>
        <p>
            Si l’Utilisateur choisit de partager ses données avec le
            Professionnel, il pourra décider de partager les données relatives à
            tous ses biens immobiliers, sociétés immobilières ou éléments
            financiers, ou seulement à ceux qu’il aura sélectionnés.
        </p>
        <p>
            Si l’Utilisateur décide en cours de route de ne plus partager ces
            informations et documents, il pourra révoquer les accès au tiers ou
            au Professionnel.
        </p>
    </div>

    <h3 class="titles">QUELLES SONT LES DONNÉES PARTAGÉES&nbsp;?</h3>
    <div class="text">
        <p>
            Les données auxquelles le Professionnel aura accès concernant
            l’Utilisateur sont les suivantes :
        </p>
        <ul>
            <li>Nom et prénom de l'Utilisateur</li>
            <li>
                Adresse et n° de téléphone de l'Utilisateur, si renseignés dans
                la Solution
            </li>
            <li>Adresse mail</li>
            <li>
                Liste des éléments patrimoniaux financiers et des biens
                partagés, ainsi que les informations et documents afférents à
                chaque bien ou produit partagé (trésorerie globale, charges
                globales, revenus globaux)
            </li>
            <li>Fiche récapitulative par bien</li>
            <li>Trésorerie par bien (charges et revenus)</li>
            <li>
                Documents par bien ou produit (contrats, factures, déclarations
                fiscales, quittances, etc.)
            </li>
        </ul>
    </div>

    <h3 class="titles">QUELS SONT MES DROITS&nbsp;?</h3>
    <div class="text">
        <p>
            L'Utilisateur est invité à consulter les informations relatives à la
            protection de ses données, ainsi qu'aux droits qu'il peut exercer à
            l'égard de ses données, en prenant connaissance de la
            <span class="omedom-link" (click)="showMention('confidentiality')"
                >Politique de confidentialité</span
            >.
        </p>
        <p>
            L'Utilisateur peut adresser toute question relative à ses données au
            Délégué à la Protection des Données d'OMEDOM à l'adresse :
            dpo&#64;omedom.com.
        </p>
    </div>
</div>


import { OmedomRegex } from "./regex";

interface OmedomFirebaseDynamicLinkInterface {
    provider: 'adjust' | 'appsflyer' | 'branch' | 'firebase';
    name: string;
    url: string;
    deeplink: string;
    route: string;
};


export class OmedomDeepLink {

    /**
     * @description Retrieve the route from the URL provided based on the app URL provided by Firbase Dynamic Links
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 11/10/2024
     * @static
     * @param {string} url
     * @param {string} appURL
     * @returns {*}  {string}
     * @memberof OmedomDeepLink
     * @example
     * const route = OmedomDeepLink.retrieveRoute('https://app.omedom.com/user/reset-password?email=prenom.nom%20test@omedom.com&code=aBcDeF');
     * expected route = '/user/reset-password?email=email=prenom.nom+test@omedom.com&code=1aB2Ce3f'
     *
     * const route = OmedomDeepLink.retrieveRoute('https://app.omedom.com/links/solution');
     * expected route = ''
     *
     * const route = OmedomDeepLink.retrieveRoute('https://app.omedom.com/links/website');
      * expected route = '/user/welcome'
     *
     * const route = OmedomDeepLink.retrieveRoute('https://app.omedom.com/links/?link=https://app.omedom.com/user/reset-password?email%3Dprenom.nom%20test@omedom.com%26code%3D1aB2Ce3f&apn=com.djbuch.omedom&isi=6443843556&ibi=com.omedom.omedom&utm_campaign=+LRMDP+-+LIEN+RINITIALISATION+MOT+DE+PASSE+-+Mot+de+passe+oubli+-+Brevo++microservice+code&utm_medium=email&utm_source=brevo&cid=2920857862331410563&_fpb=COoGEJIDGgVlbi1HQg==&_cpt=cpit&_iumenbl=1&_iumchkactval=1&_plt=440&_uit=2580&_cpb=1);
     * expected route = '/user/reset-password?email=email=prenom.nom+test@omedom.com&code=1aB2Ce3f'*        let route = url;
    */
    public static retrieveRoute(url: string, appURL: string): string {
        let route = url;

        // console.log('#URL: ' + url);
        const regExps = OmedomRegex.omedomFirebaseDeepLinksRegExps(appURL);


        switch (true) {
            case regExps[0].test(route):
                route = route.replace(regExps[0], '').split('&')[0].replace(regExps[1], '');
                break;
            case regExps[1].test(route):
                route = route.replace(regExps[1], '');
                break;
            case regExps[2].test(route):
                route = this.getRoute(route, appURL)
                break;
            default:
                route = ''
                break;
        }

        // Decode the route

        route = decodeURIComponent(route);
        // console.log('ROUTE', route);

        // Replace spaces by '+' in the route in case of emails with a "+" inside
        if (route.includes(' ')) {
            route = route.replace(/ /g, '+');
            // console.log('ROUTE after + treatment', route);
        }
        return route;
    }

    /**
     * @description Get the route from the dynamic links list based on the URL provided
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 11/10/2024
     * @private
     * @static
     * @param {string} url
     * @param {string} appURL
     * @returns {*}  {string}
     * @memberof OmedomDeepLink
     */
    private static getRoute(url: string, appURL: string): string {
        const dynamicLinks: OmedomFirebaseDynamicLinkInterface[] = [
            {
                provider: 'firebase',
                name: 'Solution Omedom',
                url: `${appURL}/links/solution`,
                deeplink: appURL,
                route: '',
            },
            {
                provider: 'firebase',
                name: 'Website',
                url: `${appURL}/links/website`,
                deeplink: `${appURL}/user/welcome`,
                route: '/user/welcome',
            },
            {
                provider: 'firebase',
                name: 'Change Subscription',
                url: `${appURL}/links/`,
                deeplink: `${appURL}/tabs/menu/subscription`,
                route: '/tabs/menu/subscription',
            },
            {
                provider: 'firebase',
                name: 'Subscription Error',
                url: `${appURL}/links/subscriptionError`,
                deeplink: `${appURL}/tabs/menu/subscription?state=error`,
                route: '/tabs/menu/subscription?state=error',
            },
            {
                provider: 'firebase',
                name: 'Subscription Success',
                url: `${appURL}/links/subscriptionSuccess`,
                deeplink: `${appURL}/tabs/menu/subscription?state=success`,
                route: '/tabs/menu/subscription?state=success',
            },
            {
                provider: 'firebase',
                name: 'Email Verification',
                url: `${appURL}/links/verifyEmail`,
                deeplink: `${appURL}`,
                route: '',
            },
        ];

        let slug = '';
        // console.log('URL', url);

        dynamicLinks.forEach((dynamicLink) => {
            // console.log(url, dynamicLink.url);
            if (url === dynamicLink.url) {
                // console.log('MATCH', dynamicLink.name);
                slug = dynamicLink.route;
            }
        });

        return slug;
    };
}

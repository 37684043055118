import { RestEntity } from './rest.entity';
import { SubscriptionAppleIDs, SubscriptionPrices, SubscriptionType } from './subscription.entity';

export class ConfigurationEntity extends RestEntity {

    /**
     * @description
     * @author Vidjay Seganti (Keleo)
     * @type {string}
     */
    public order?: number;

    /**
     * @description
     * @author Vidjay Seganti (Keleo)
     * @type {string}
     */
    public subTitle?: string;

    /**
     * @description
     * @author Vidjay Seganti (Keleo)
     * @type {string}
     */
    public isActive?: boolean;

    /**
     * @description The limit of properties available
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 19/06/2024
     * @type {number}
     * @memberof ConfigurationEntity
     */
    public propertyLimit?: number;

    /**
     * @description number of property that can be shared by the user (-1 for unlimited)
     * @author Vidjay Seganti (Keleo)
     * @type {number}
     */
    public propertyShare?: number;

    /**
     * @description Price list of the configuration
     * @author Vidjay Seganti (Keleo)
     * @type {number}
     */
    public price?: SubscriptionPrices;

    /**
     * @description array of key string that the user can access with this configuration
     * @author Vidjay Seganti (Keleo)
     * @type {string[]}
     */
    public canAccess?: string[];

    /**
     * @description color of the configuration
     * @author Vidjay Seganti (Keleo)
     * @type {string}
     */
    public color?: string;


    /**
     * @description Id of the subscription on apple
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {SubscriptionAppleIDs}
     */
    public appleIds?: SubscriptionAppleIDs;

    /**
     * @description Enable accessing object using string key
     * @author Vidjay Seganti (Keleo)
     * @type {any}
     */
    [key: string]: any;
}

import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';

@Component({
    selector: 'omedom-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
})
export class CheckboxComponent implements ControlValueAccessor, Validator {
    @Input() name: string = '';

    @Input() labelNotClickable = false;

    public value: boolean = false;

    public isDisabled: boolean = false;

    private onChangeCallback: any;

    validate(): null {
        return null;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(): void {}

    writeValue(newValue: boolean): void {
        if (newValue !== this.value) {
            this.value = newValue;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    valueChange(value: any) {
        if (this.labelNotClickable) {
            return;
        }
        this.writeValue(value);
        this.onChangeCallback(value);
    }
}

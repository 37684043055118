export enum PropertyTab {
    info = 'info',
    charge = 'charge',
    income = 'income',
    liquidity = 'liquidity',
    tenant = 'tenant',
    document = 'document',
    share = 'share',
    banking = 'banking',
    property = 'property',
    building = 'building',
}

import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayFilter',
})
@Injectable({ providedIn: 'root' })
export class OmedomArrayFilterPipe implements PipeTransform {


    transform(items: any[], property: string, value: any, isEqual: boolean = true): any[] {
        if (!items || !property || value === undefined) return items;

        return items.filter(item => {
            if (isEqual) {
                return item[property] === value;
            } else {
                return item[property] !== value;
            }
        });
    }
}

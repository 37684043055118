/**
 * @description Bank status code in Bridge
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 01/03/2024
 * @export
 * @enum {number}
 */
export enum BankStatusCode {
    /**
     * @description Everything is OK
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     */
    OK = 0,

    /**
     * @description The item was recently added, and the item's refresh is in progress
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     */
    JustAdded = -2,

    /**
     * @description The credentials were recently updated, and the item's refresh is in progress
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     */
    JustEdited = -3,

    /**
     * @description Wrong credentials were provided
     */
    LoginFailed = 402,

    /**
     * @description An action from the user is required within the online banking of the user. This can occur when the user needs to accept new Terms of Service, change their credentials, or when it's their first time connecting to the bank's website.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     */
    NeedsHumanAction = 429,

    /**
     * @description The bank's website is temporarily unavailable. The user should try again later.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     */
    CouldNotRefresh = 1003,

    /**
     * @description The account is not supported by Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     */
    NotSupported = 1005,

    /**
     * @description Bridge disable temporarily the refreshing of the account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     */
    DisabledTemporarily = 1007,

    /**
     * @description The account balance has changed, but no new transactions were found. This can happen with stock or life insurance accounts.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     */
    Incomplete = 1009,

    /**
     * @description The item wasn't refreshed successfully because it required a Multi-Factor Authentication (MFA) or One-Time Password (OTP) that wasn't provided.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     */
    NeedsManuelRefresh = 1010,

    /**
     * @description The item is being migrated to another bank.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     */
    Migration = 1099,

    /**
     * @description Pro accounts have been detected on this Item and it needs validation. Otherwise the data will be obfuscated.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     */
    ProAccountLocked = 1100,
}

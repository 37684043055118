// informations from pricehubble: https://docs.pricehubble.com/types#address

import { AtticType, GlazingType, IsolationRenovationPeriod } from '../entities';

export interface PricehubbleProperty {
    location: PricehubbleLocation;
    propertyType: PricehubblePropertyType;
    buildingYear?: number | undefined; //m2
    livingArea?: number | undefined; //m2
    landArea?: number | undefined; //m2 min: 10, max: 100'000
    balconyArea?: number; //m2
    gardenArea?: number; //m2
    numberOfRooms?: number | undefined;
    numberOfBathrooms?: number;
    numberOfIndoorParkingSpaces?: number | undefined; // 0 min - 6 max
    numberOfOutdoorParkingSpaces?: number | undefined; // 0 min - 6 max
    floorNumber?: number;
    hasLift?: boolean;
    energyLabel?: string;
    heatingType?: PricehubbleHeatingType;
    heatingGenerationType?: string; // not use for valuation in FR
    hotWaterType?: PricehubbleHeatingType;
    hotWaterGenerationType?: string; // not use for valuation
    numberOfExternalFacingWalls?: number; // not use for valuation
    isolationRenovationPeriod?: IsolationRenovationPeriod; // not use for valuation
    numberOfWindows?: number; // not use for valuation
    glazingType?: GlazingType; // not use for valuation
    hasSauna?: boolean; // only for house
    hasPool?: boolean; // only for house
    numberOfFloorsInBuilding?: number;
    atticType?: AtticType;
    isFurnished?: boolean;
    isNew?: boolean;
    condition?: {
        bathrooms?: PricehubbleCondition;
        kitchen?: PricehubbleCondition;
        flooring?: PricehubbleCondition;
        windows?: PricehubbleCondition;
        masonry?: PricehubbleCondition; // only for house
    };
    quality?: {
        bathrooms?: PricehubbleQuality;
        kitchen?: PricehubbleQuality;
        flooring?: PricehubbleQuality;
        windows?: PricehubbleQuality;
        masonry?: PricehubbleQuality; // only for house
    };
}

export interface PricehubbleLocation {
    coordinates?: PricehubbleCoordinate;
    address?: PricehubbleAddress;
    uprn?: number;
}

export interface PricehubbleAddress {
    postCode: string;
    city: string;
    street: string;
    houseNumber: string;
    buildingName?: string;
    unitIdentifier?: string;
}

export interface PricehubbleCoordinate {
    latitude: number;
    longitude: number;
}

export interface PricehubblePropertyType {
    code: PrichubblePropertyType;
    subcode?: string; // not relevent in FR
}

export interface PricehubbleRequiredDataCreatingFolder {
    buildingYear: boolean;
    livingArea: boolean;
    address: PricehubbleMissingAddress;
}

export interface MissingDataHouseRequired extends PricehubbleRequiredDataCreatingFolder {
    landArea: boolean;
}

export interface MissingDataGeneralOptional {
    roomCount?: boolean;
    floor?: boolean;
    energyLabel?: boolean;
    numberOfExternalFacingWalls?: boolean;
    isolationRenovationPeriod?: boolean;
    numberOfWindows?: boolean;
    glazingType?: boolean;
}

export interface MissingDataApartementOptional extends MissingDataGeneralOptional {
    floorNumber?: boolean; // Represent the number of floor in the building
    hasLift?: boolean;
}

export interface MissingDataHouseOptional extends MissingDataGeneralOptional {
    hasPool?: boolean;
    atticType?: boolean;
}

export interface PricehubbleMissingAddress {
    postCode: boolean;
    city: boolean;
    street: boolean;
    houseNumber: boolean;
    buildingName?: boolean;
    unitIdentifier?: boolean;
}

export enum PrichubblePropertyType {
    apartment = 'apartment',
    house = 'house',
    multiFamilyHouse = 'multi_family_house',
}

export enum PricehubbleHeatingType {
    individual = 'individual',
    collective = 'collective',
}

export enum PricehubbleCondition {
    renovationNeeded = 'renovation_needed',
    wellMaintained = 'well_maintained',
    newOrRecentlyRenovated = 'new_or_recently_renovated',
}

export enum PricehubbleQuality {
    simple = 'simple',
    normal = 'normal',
    highQuality = 'high_quality',
    luxury = 'luxury',
}

export enum PricehubbleGlazingType {
    simple = 'simple',
    double = 'double',
    triple = 'triple',
}

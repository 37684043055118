import {
    AllChargeCategories,
    AllIncomeCategories,
    CategoryInfo,
    ChargePeriodicity,
    IncomePeriodicity,
    PeriodicityInfo,
} from '../entities';

export abstract class TreasuryListModel<TCategory, TPeriodicity> {
    public categoryInfo!: CategoryInfo<TCategory>;
    public amount!: number;
    public uid!: string;
    public periodicityInfo!: PeriodicityInfo<TPeriodicity>;
    public date!: Date;
    public designation!: string;
    public isPayed!: boolean;
    public propertyName!: string;
    public propertyImg!: string;
    public propertyOwnerUID!: string;
    public propertyUID!: string;
    public userUID!: string;
    public notes!: string;
    public isCharge?: boolean;
    public withNotif?: boolean;
}

export class ChargeListModel extends TreasuryListModel<AllChargeCategories, ChargePeriodicity> {
    constructor(init?: Partial<ChargeListModel>) {
        super();
        Object.assign(this, init);
    }
}

export class IncomeListModel extends TreasuryListModel<AllIncomeCategories, IncomePeriodicity> {
    constructor(init?: Partial<IncomeListModel>) {
        super();
        Object.assign(this, init);
    }
}

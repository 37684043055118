import {
    MAX_BUILDING_YEAR,
    MAX_LAND_AREA,
    MIN_BUILDING_YEAR,
    MIN_LAND_AREA,
    PostalAddress,
    MissingDataHouseOptional,
    MissingDataGeneralOptional,
    PricehubbleMissingAddress,
    MissingDataHouseRequired,
    PricehubbleRequiredDataCreatingFolder,
    MissingDataApartementOptional,
    PropertyEntity,
} from '@omedom/data';

export class OmedomPricehubbleMissingData {
    public static missingRequiredFieldAppartment(property: Partial<PropertyEntity>) {
        const missingRequiredData = this.missingRequiredDataFolder(property);
        return missingRequiredData;
    }

    public static missingDataApartementOptional(property: PropertyEntity) {
        const missingOptionalGeneralData = this.valuationMissingDataGeneralOptional(property);
        const missingOptionalDataForApartementt =
            this.ValuationMissingDataOptionalAppartement(property);

        return {
            ...missingOptionalGeneralData,
            ...missingOptionalDataForApartementt,
        };
    }

    public static missingOptionalFieldAppartment(property: Partial<PropertyEntity>) {
        const missingOptionalGeneralData = this.valuationMissingDataGeneralOptional(property);
        const missingOptionalDataForApartement =
            this.ValuationMissingDataOptionalAppartement(property);
        // TODO je veux avoir les données glazing type et compagnies
        // const missingOptionalDataForApartementt =
        // this.MissingDataOptionalAppartement(property);

        return {
            ...missingOptionalGeneralData,
            ...missingOptionalDataForApartement,
        };
    }

    public static missingRequiredFieldHouse(property: Partial<PropertyEntity>) {
        const missingRequiredData = this.missingRequiredDataFolder(property);

        const isValidLandArea =
            property.landArea &&
            property.landArea <= MAX_LAND_AREA &&
            property.landArea >= MIN_LAND_AREA;

        const missingRequiredDataHouse: MissingDataHouseRequired = {
            ...missingRequiredData,
            landArea: !isValidLandArea,
        };
        return missingRequiredDataHouse;
    }

    public static missingOptionalFieldHouse(property: Partial<PropertyEntity>) {
        const missingOptionalGeneralData = this.valuationMissingDataGeneralOptional(property);
        const missingOptionalDataForHouse = this.ValuationMissingDataOptionalHouse(property);

        return {
            ...missingOptionalGeneralData,
            ...missingOptionalDataForHouse,
        };
    }

    private static missingGeneralFieldEstimationGeneral(property: Partial<PropertyEntity>) {
        const missingOptionalDataEstimation: MissingDataGeneralOptional = {
            glazingType: !OmedomPricehubbleMissingData.hasGlazingType(property),
            numberOfExternalFacingWalls: property.dpeDetails?.estimationInformations
                ?.numberOfExternalFacingWalls
                ? false
                : true,
            isolationRenovationPeriod: property.dpeDetails?.estimationInformations
                ?.isolationRenovationPeriod
                ? false
                : true,
            numberOfWindows: property.dpeDetails?.estimationInformations?.numberOfWindows
                ? false
                : true,
        };

        return missingOptionalDataEstimation;
    }

    private static hasGlazingType(property: Partial<PropertyEntity>) {
        if (!property?.dpeDetails?.estimationInformations?.glazingType) {
            return false;
        }

        return property.dpeDetails.estimationInformations.glazingType;
    }

    private static missingRequiredDataFolder(property: Partial<PropertyEntity>) {
        const missingAddress = this.valuationMissingAddressData(property);
        const livingSpace =
            property.livingSpace && property.livingSpace >= 5 && property.livingSpace <= 1000;

        const isValidBuildingYear =
            property.buildingYear &&
            property.buildingYear >= MIN_BUILDING_YEAR &&
            property.buildingYear <= MAX_BUILDING_YEAR;

        const missingdata: PricehubbleRequiredDataCreatingFolder = {
            buildingYear: !isValidBuildingYear,
            livingArea: !livingSpace,
            address: missingAddress,
        };
        return missingdata;
    }

    private static valuationMissingAddressData(property: Partial<PropertyEntity>) {
        const address: PostalAddress | undefined = property.address;
        const missingAddress: PricehubbleMissingAddress = {
            street: address?.street ? false : true,
            postCode: address?.postalCode ? false : true,
            city: address?.city ? false : true,
            houseNumber: address?.streetNumber ? false : true,
        };
        return missingAddress;
    }

    private static valuationMissingDataGeneralOptional(property: Partial<PropertyEntity>) {
        const missingData: MissingDataGeneralOptional = {
            roomCount: property?.roomCount ? false : true,
            floor: property?.floor ? false : true,
            energyLabel: property?.dpeDetails?.dpeScore ? false : true,
        };

        return missingData;
    }

    private static ValuationMissingDataOptionalAppartement(property: Partial<PropertyEntity>) {
        const isFloorNumberValid = property.floorNumber && property.floorNumber > 0;
        const missingDataAppartement: MissingDataApartementOptional = {
            floorNumber: property?.floorNumber ? false : true, // Represent the number of floor in the building
            hasLift: property?.amenities?.includes('Ascenseur') ? false : true,
            glazingType: !OmedomPricehubbleMissingData.hasGlazingType(property),
            numberOfExternalFacingWalls: property.dpeDetails?.estimationInformations
                ?.numberOfExternalFacingWalls
                ? false
                : true,
            isolationRenovationPeriod: property.dpeDetails?.estimationInformations
                ?.isolationRenovationPeriod
                ? false
                : true,
            numberOfWindows: property.dpeDetails?.estimationInformations?.numberOfWindows
                ? false
                : true,
        };

        return missingDataAppartement;
    }

    private static ValuationMissingDataOptionalHouse(property: Partial<PropertyEntity>) {
        const missingDataHouse: MissingDataHouseOptional = {
            hasPool: property?.amenities?.includes('Piscine') ? false : true,
            glazingType: !OmedomPricehubbleMissingData.hasGlazingType(property),
            numberOfExternalFacingWalls: property.dpeDetails?.estimationInformations
                ?.numberOfExternalFacingWalls
                ? false
                : true,
            isolationRenovationPeriod: property.dpeDetails?.estimationInformations
                ?.isolationRenovationPeriod
                ? false
                : true,
            numberOfWindows: property.dpeDetails?.estimationInformations?.numberOfWindows
                ? false
                : true,
        };
        return missingDataHouse;
    }

    public static EPCMissingData() {}

    public static hasMissingProperty(obj: { [key: string]: any }) {
        // this funcion indicate if there is at least one property missing in an object (and return true in that case)
        for (let key in obj) {
            // Check if property is an object
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                // Appel récursif pour parcourir l'objet imbriqué
                if (this.hasMissingProperty(obj[key])) {
                    return true;
                }
            } else if (obj[key] === true) {
                return true;
            }
        }
        return false;
    }
}

/**
 * @description Color enum to display a color in the table
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 13/09/2023
 * @export
 * @enum {number}
 */
export enum DataTableColor {
    green = 'green',
    red = 'red',
    yellow = 'yellow',
    grey = 'grey'
}

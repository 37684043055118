import { Injectable } from '@angular/core';
import { SubscriptionEntity, UserEntity } from '@omedom/data';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SubscriptionService, UserService } from '../core';

@Injectable({
    providedIn: 'root',
})
export class SmartService {
    /**
     * @description Current date
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @private
     * @memberof SmartService
     */
    private now = new Date().toUTC();

    /**
     * @description State of the service (has smart or not ?) (true = has smart, false = no smart)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @memberof SmartService
     */
    public hasSmart$ = new BehaviorSubject<boolean>(false);

    /**
     * @description Subscription of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @memberof SmartService
     */
    public subscription$ = new BehaviorSubject<SubscriptionEntity | undefined>(undefined);

    /**
     * @description User data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @type {UserEntity}
     * @memberof SmartService
     */
    public user?: UserEntity;

    constructor(
        private userService: UserService,
        private subscriptionService: SubscriptionService
    ) {
        this.userService.user$.subscribe((user) => {
            this.user = user;
            this._hasSmart();
        });
    }

    /**
     * @description Check if the user has smart
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @private
     * @memberof SmartService
     * @returns {void}
     * @example
     * this.smartService._hasSmart();
     */
    private _hasSmart(): void {
        // Check if the user exists
        // If the user exists, check if he has a subscription
        if (this.user) {
            this.subscriptionService
                .getSubscriptionByUser(this.user.uid)
                .pipe(
                    tap((subscription) => {
                        // Update the subscription
                        this.subscription$.next(subscription);

                        // Check if the subscription is expired
                        if (subscription) {
                            // Split the date
                            const renewDateSplit = subscription.renewDate.split('/');

                            // Create all elements of date
                            const year = Number.parseInt(renewDateSplit[2], 10);
                            const month = Number.parseInt(renewDateSplit[1], 10);
                            const day = Number.parseInt(renewDateSplit[0], 10);

                            // Create the end date
                            const end = new Date(year, month - 1, day).toUTC();

                            // Update the hasSmart state
                            this.hasSmart$.next(end > this.now);
                        } else {
                            this.hasSmart$.next(false);
                        }
                    })
                )
                .subscribe();
        } else {
            this.hasSmart$.next(false);
        }
    }

    /**
     * @description Check if the property has smart (true = has smart, false = no smart) (Observable version)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @param {string} propertyUID UID of the property to check
     * @returns {Observable<boolean>}
     * @memberof SmartService
     * @example
     * this.smartService.hasSmartProperty(propertyUID);
     */
    public hasSmartProperty(propertyUID: string): Observable<boolean> {
        return this.subscriptionService
            .getSubscriptionByProperty(propertyUID)
            .pipe(
                map((subscription) => {
                    if (subscription) {
                        // Split the date
                        const renewDateSplit = subscription.renewDate.split('/');

                        // Create all elements of date
                        const year = Number.parseInt(renewDateSplit[2], 10);
                        const month = Number.parseInt(renewDateSplit[1], 10);
                        const day = Number.parseInt(renewDateSplit[0], 10);

                        // Create the end date
                        const end = new Date(year, month - 1, day).toUTC();

                        // Return the hasSmart state
                        return end > this.now;
                    } else {
                        return false;
                    }
                })
            );
    }

    /**
     * @description Check if the property has smart (true = has smart, false = no smart) (Promise version)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @param {string} propertyUID UID of the property to check
     * @returns {Promise<boolean>} Promise of the hasSmart state
     * @memberof SmartService
     * @example
     * const hasSmart = await this.smartService.hasSmartPropertyPromise(propertyUID);
     */
    public async hasSmartPropertyPromise(
        propertyUID: string
    ): Promise<boolean> {
        const res =
            await this.subscriptionService.getSubscriptionByPropertyPromise(
                propertyUID
            );

        if (res) {
            // Split the date
            const renewDateSplit = res.renewDate.split('/');

            // Create all elements of date
            const year = Number.parseInt(renewDateSplit[2], 10);
            const month = Number.parseInt(renewDateSplit[1], 10);
            const day = Number.parseInt(renewDateSplit[0], 10);

            // Create the end date
            const end = new Date(year, month - 1, day).toUTC();

            // Return the hasSmart state
            return end > this.now;
        } else {
            return false;
        }
    }

    /**
     * @description Check if the user has smart (true = has smart, false = no smart) (Promise version)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @param {string} userUID UID of the user to check
     * @returns {Promise<boolean>} Promise of the hasSmart state
     * @memberof SmartService
     */
    public async hasSmartUserPromise(userUID: string): Promise<boolean> {
        const res = await this.subscriptionService.getSubscriptionByUserPromise(
            userUID
        );

        if (res) {
            // Split the date
            const renewDateSplit = res.renewDate.split('/');

            // Create all elements of date
            const year = Number.parseInt(renewDateSplit[2], 10);
            const month = Number.parseInt(renewDateSplit[1], 10);
            const day = Number.parseInt(renewDateSplit[0], 10);

            // Create the end date
            const end = new Date(year, month - 1, day);

            // Return the hasSmart state
            return end > this.now;
        } else {
            return false;
        }
    }
}

import { FormControl } from '@angular/forms';

/**
 * @description Loan infos form type
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 09/07/2024
 * @type {LoanInfosFormType}
 */
export type LoanInfosFormType = {
    /**
     * @description Name of the loan (mandatory)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {FormControl<string>}
     */
    name: FormControl<string>,

    /**
     * @description ID of the bank linked to the loan in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<number | undefined>)}
     */
    bankID: FormControl<number | undefined>,

    /**
     * @description Borrowed capital of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<number | undefined>)}
     */
    borrowedCapital: FormControl<number | undefined>,

    /**
     * @description Repaid capital of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<number | undefined>)}
     */
    repaidCapital: FormControl<number | undefined>,

    /**
     * @description Remaining capital of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<number | undefined>)}
     */
    remainingCapital: FormControl<number | undefined>,

    /**
     * @description Interest rate of the loan (in %)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<number | undefined>)}
     */
    interestRate: FormControl<number | undefined>,

    /**
     * @description Assurance name of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<string | undefined>)}
     */
    assurance: FormControl<string | undefined>,

    /**
     * @description Guarantee of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<string | undefined>)}
     */
    guarantee: FormControl<string | undefined>,

    /**
     * @description Type of loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<string | undefined>)}
     */
    type: FormControl<string | undefined>,
};

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Appearance } from '@omedom/data';

interface ButtonConfig {
    text: string;
    color: Appearance;
    icon?: string;
}


@Component({
    selector: 'omedom-modal-confirm',
    templateUrl: './modal-confirm.component.html',
    styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent implements OnInit {
    /**
     * @description Title of the modal
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/02/2024
     * @type {string}
     * @memberof ModalConfirmComponent
     */
    @Input({ required: true, })
    public title!: string;

    /**
     * @description Message of the modal
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/02/2024
     * @type {string}
     * @memberof ModalConfirmComponent
     */
    @Input({ required: true, })
    public message!: string;

    /**
     * @description Confirm button config of the modal
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/02/2024
     * @type {ButtonConfig}
     * @memberof ModalConfirmComponent
     */
    @Input()
    public confirm: ButtonConfig = {
        text: 'Confirmer',
        color: Appearance.warning,
    };

    /**
     * @description Cancel button config of the modal
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/02/2024
     * @type {ButtonConfig}
     * @memberof ModalConfirmComponent
     */
    @Input()
    public cancel: ButtonConfig = {
        text: 'Annuler',
        color: Appearance.secondary,
    };

    constructor(
        private readonly modalController: ModalController,
    ) { }

    ngOnInit(): void {
        // Check if the modal is correctly initialized
        if (!this.title || !this.message) {
            throw new Error('ModalConfirmComponent must have a title and a message');
        }
    }

    /**
     * @description Dismiss the modal with the confirm value as parameter
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/02/2024
     * @param {boolean} confirm
     * @returns {Promise<void>}
     * @memberof ModalConfirmComponent
     */
    public async dismissModal(confirm: boolean): Promise<void> {
        await this.modalController.dismiss(confirm);

    }
}

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: '[appOmedomNumber]' })
export class OmedomNumberDirective {
    private ionInput: any;

    constructor(element: ElementRef) {
        this.ionInput = element.nativeElement;
    }

    @HostListener('keypress', ['$event'])
    onInput(event: KeyboardEvent) {
        const inputChar = String.fromCharCode(event.which ? event.which : event.keyCode);
        const value = this.ionInput.getElementsByTagName('input')[0].value;
        const fullValue = `${value}${inputChar}`.split(' ').join(''); // Supprime les espaces

        const isValidNumber = (val: string) => {
            const [integerPart, decimalPart] = val.split(',');

            // Check if integral part is a valid number
            if (!integerPart || !integerPart.split('').every((char) => !isNaN(parseInt(char)))) {
                return false;
            }

            // Check if decimal part is a valid number
            if (decimalPart && !decimalPart.split('').every((char) => !isNaN(parseInt(char)))) {
                return false;
            }

            return true;
        };

        if (!isValidNumber(fullValue)) {
            event.preventDefault();
            return false;
        }

        return true;
    }
}

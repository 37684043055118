/**
 * @description Omedom colors enum to display a color in the application
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 27/09/2024
 * @export
 * @enum {number}
 * Important note: Replicate changes in libs/ui/src/lib/assets/style/variable/colors.scss
 */
export enum OmedomColorsEnum {
    veryLightGrey = '#f5f5f5',
    veryLightBlue = '#b3d6ef',
    darkGrey = '#606060',

    lightBlack = '#04151c',
    lightWhite = '#ffffff',
    lightGrey = '#eeeeee',
    lightBlue = '#0076c8',
    lightGreen = '#00c29a',
    lightOrange = '#ff5641',
    lightYellow = '#ffbe00',

    mediumGrey = '#a8adaf',

    darkBlue = '#3491d2',
    darkGreen = '#2dc2a4',
    darkOrange = '#ff8273',
    darkYellow = '#ffd456',

    black = '#000000',
    green = '#01856e',
    red = '#cd493a',
    yellow = '#cc9900',
    grey = '#737677',

    proGold = '#ffbe00',
    proBrown = '#864231',
    proGrey = '#bbb4a9',
    proYellow = '#fde3a0',
}

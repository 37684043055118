@if (!isDesktop) {
<ion-refresher slot="fixed" #refresher (ionRefresh)="refresh($event)">
    <ion-refresher-content> </ion-refresher-content>
</ion-refresher>
}
<omedom-sub-tab
    [subTabs]="subTabs"
    [(selectedSubTab)]="selectedSubTab"
></omedom-sub-tab>
@switch (selectedSubTab.id) {
<!-- Details -->
@case (propertyInfoTab.details) {
<div>
    <omedom-property-info-details
        class="content"
        [edit]="editable"
        [property]="property"
    ></omedom-property-info-details>
    <!-- Détention -->
    <omedom-property-info-possession
        class="content"
        [property]="property"
    ></omedom-property-info-possession>
    <!-- Paramètres -->
    <omedom-property-info-settings
        class="content"
        [property]="property"
    ></omedom-property-info-settings>
    <div class="actions flex-1 margin-top-2">
        <button
            class="omedom-button-primary flex-1 w-100"
            (click)="createPropertyPDF()"
            [disabled]="!('accessCreatePDF' | isAuthorised | async)"
        >
            <i class="uil uil-file-alt"></i>
            <span> Générer un pdf </span>
        </button>
    </div>
</div>
} @case (propertyInfoTab.valuation) {
<div>
    <omedom-lock
        [isLocked]="!('accessValorisation' | isAuthorised | async)"
        type="view"
    >
        <ng-container omedom-lock-content>
            @if (!!property) {
            <omedom-valuation
                class="w-100"
                [property]="property"
                [canAccessValuation]="
                    !!('accessValorisation' | isAuthorised | async)
                "
                [missingDataInView]="missingDataInView"
                (goToMissingDataView)="goToMissingDataView.emit()"
            ></omedom-valuation>
            }
        </ng-container>
        <ng-container omedom-lock-fallback>
            <omedom-action
                (actionClicked)="goToSub()"
                class="w-100 margin-top-1 margin-bottom-1"
                text="Fonctionnalité Premium, changez d'abonnement."
                actionIcon="omedom-icon-crown"
            ></omedom-action>
        </ng-container>
    </omedom-lock>
</div>
} }

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    AllChargeCategories,
    AllIncomeCategories,
    BankTransactionCategoryForm,
    BankTransactionDetailsForm,
    BankTransactionEntity,
    BankTransactionPatrimonyForm,
    BankTransactionRecurrenceForm,
    ChargeCategoryInfo,
    ChargeCategoryProperty,
    ChargeEntity,
    ChargePeriodicity,
    ChargePeriodicityInfo,
    IncomeCategoryInfo,
    IncomeEntity,
    IncomePeriodicity,
    IncomePeriodicityInfo,
    PropertyEntity,
    SocietyEntity,
} from '@omedom/data';
import {
    BankTransactionService,
    ChargeService,
    IncomeService,
    PropertyService,
    SocietyService,
} from '@omedom/services';
import { combineLatest, Observable, of } from 'rxjs';

import { elementAnimation } from '../../../../animations';

@Component({
    selector: 'omedom-bank-transaction-confirmation-step',
    templateUrl: './bank-transaction-confirmation-step.container.html',
    styleUrls: ['./bank-transaction-confirmation-step.container.scss'],
    animations: [elementAnimation],
})
export class BankTransactionConfirmationStepContainer implements OnInit {
    /**
     * @description The transaction to confirm
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @type {BankTransactionEntity}
     * @memberof BankTransactionConfirmationStepContainer
     */
    @Input({ required: true })
    public transaction?: BankTransactionEntity;

    /**
     * @description The patrimony form to check the patrimony of the user before the transaction is confirmed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @type {FormGroup<BankTransactionPatrimonyForm>}
     * @memberof BankTransactionConfirmationStepContainer
     */
    @Input({ required: true })
    public patrimonyForm?: FormGroup<BankTransactionPatrimonyForm>;

    /**
     * @description The category form to check the category of the transaction before the transaction is confirmed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @type {FormGroup<BankTransactionCategoryForm>}
     * @memberof BankTransactionConfirmationStepContainer
     */
    @Input({ required: true })
    public categoryForm?: FormGroup<BankTransactionCategoryForm>;

    /**
     * @description The details form to check the details of the transaction before the transaction is confirmed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @type {FormGroup<BankTransactionDetailsForm>}
     * @memberof BankTransactionConfirmationStepContainer
     */
    @Input({ required: true })
    public detailsForm?: FormGroup<BankTransactionDetailsForm>;

    /**
     * @description The recurrence form to check the recurrence of the transaction before the transaction is confirmed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @type {FormGroup<BankTransactionRecurrenceForm>}
     * @memberof BankTransactionConfirmationStepContainer
     */
    @Input({ required: true })
    public recurrenceForm?: FormGroup<BankTransactionRecurrenceForm>;

    public patrimony$: Observable<PropertyEntity | SocietyEntity | null> = of(null);

    public category$: Observable<ChargeCategoryInfo | IncomeCategoryInfo | null> = of(null);

    public periodicity$: Observable<ChargePeriodicityInfo | IncomePeriodicityInfo | null> =
        of(null);

    public notes$: Observable<string | null> = of(null);

    public treasury$: Observable<ChargeEntity | IncomeEntity | null> = of(null);

    public recurrencies$: Observable<BankTransactionEntity[] | null> = of(null);

    constructor(
        private readonly propertyService: PropertyService,
        private readonly societyService: SocietyService,
        private readonly chargeService: ChargeService,
        private readonly incomeService: IncomeService,
        private readonly bankTransactionService: BankTransactionService
    ) {}

    ngOnInit(): void {
        this.getPatrimony();
        this.getCategory();
        this.getPeriodicity();
        this.getNotes();
        this.getTreasury();
        this.getRecurrencies();
    }

    /**
     * @description Get the patrimony selected previously from the patrimony form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @private
     * @memberof BankTransactionConfirmationStepContainer
     */
    private getPatrimony(): void {
        const propertyUID = this.patrimonyForm?.get('propertyUID')?.value;
        const societyUID = this.patrimonyForm?.get('societyUID')?.value;

        if (!propertyUID && !societyUID) {
            this.patrimony$ = of(null);
            return;
        }

        this.patrimony$ = propertyUID
            ? (this.propertyService._get(propertyUID) as Observable<PropertyEntity>)
            : societyUID
            ? (this.societyService._get(societyUID) as Observable<SocietyEntity>)
            : of(null);
    }

    /**
     * @description Get the category selected previously from the category form and create the category info instance from it
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @private
     * @memberof BankTransactionConfirmationStepContainer
     */
    private getCategory(): void {
        // Check if the transaction is a charge or an income
        if (!this.transaction) {
            return;
        }

        const isCharge = this.transaction.amount < 0;

        // Get the category form value
        const form = this.categoryForm?.value;

        // Check if the category is defined
        if (!form?.category) {
            return;
        }

        // Get the category info
        const instance = isCharge
            ? new ChargeCategoryInfo(form.category as AllChargeCategories)
            : new IncomeCategoryInfo(form.category as AllIncomeCategories);

        this.category$ = of(instance);
    }

    /**
     * @description Get the periodicity selected previously from the details form and create the periodicity info instance from it
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @private
     * @memberof BankTransactionConfirmationStepContainer
     */
    private getPeriodicity(): void {
        if (!this.transaction) {
            return;
        }

        const isCharge = this.transaction.amount < 0;

        // Get the periodicity form value
        const form = this.detailsForm?.value;

        // Check if the periodicity is defined
        if (!form?.periodicity) {
            return;
        }

        // Get the periodicity info
        const instance = isCharge
            ? new ChargePeriodicityInfo(form.periodicity as ChargePeriodicity)
            : new IncomePeriodicityInfo(form.periodicity as IncomePeriodicity);

        this.periodicity$ = of(instance);
    }

    /**
     * @description Get the notes entered previously from the details form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @private
     * @memberof BankTransactionConfirmationStepContainer
     */
    private getNotes(): void {
        const form = this.detailsForm?.value;

        // Check if the notes are defined
        if (!form?.notes) {
            return;
        }

        this.notes$ = of(form.notes);
    }

    /**
     * @description Get the treasury selected previously from the details form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @private
     * @memberof BankTransactionConfirmationStepContainer
     */
    private getTreasury(): void {
        if (!this.transaction) {
            return;
        }
        const isCharge = this.transaction.amount < 0;

        // Get the treasury form value
        const form = this.detailsForm?.value;

        // Check if the treasury is defined
        if (!form?.treasuryUID) {
            return;
        }

        // Get the treasury
        this.treasury$ = isCharge
            ? (this.chargeService._get(form.treasuryUID) as Observable<ChargeEntity>)
            : (this.incomeService._get(form.treasuryUID) as Observable<IncomeEntity>);
    }

    /**
     * @description Get the recurrences selected previously from the recurrence form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @private
     * @memberof BankTransactionConfirmationStepContainer
     */
    private getRecurrencies(): void {
        const form = this.recurrenceForm?.value;

        // Check if the recurrences are defined
        if (!form?.transactionUIDs || form.transactionUIDs.length === 0) {
            this.recurrencies$ = of(null);
            return;
        }

        // Get the recurrences
        this.recurrencies$ = combineLatest([
            ...form.transactionUIDs.map(
                (uid: string) =>
                    this.bankTransactionService._get(uid) as Observable<BankTransactionEntity>
            ),
        ] as Observable<BankTransactionEntity>[]);
    }

    /**
     * @description Check if the patrimony is a property or a society to display the right information in the confirmation step
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @readonly
     * @type {boolean}
     * @memberof BankTransactionConfirmationStepContainer
     */
    public get isProperty(): boolean {
        return !!this.patrimonyForm?.get('propertyUID')?.value;
    }

    /**
     * @description Get the property from the patrimony form to display the right information in the confirmation step
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @readonly
     * @type {(Observable<PropertyEntity | undefined>)}
     * @memberof BankTransactionConfirmationStepContainer
     */
    public get property$(): Observable<PropertyEntity | undefined> {
        if (this.isProperty) {
            return of();
        }

        return this.patrimony$ as Observable<PropertyEntity>;
    }

    /**
     * @description Get the society from the patrimony form to display the right information in the confirmation step
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @readonly
     * @type {(Observable<SocietyEntity | undefined>)}
     * @memberof BankTransactionConfirmationStepContainer
     */
    public get society$(): Observable<SocietyEntity | undefined> {
        if (!this.isProperty) {
            return of();
        }

        return this.patrimony$ as Observable<SocietyEntity>;
    }

    /**
     * @description Get the category info from the category form to display the right information in the confirmation step
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @param {(ChargeEntity | IncomeEntity)} treasury
     * @returns {(ChargeCategoryInfo | IncomeCategoryInfo)}
     * @memberof BankTransactionConfirmationStepContainer
     */
    public getCategoryInfo(
        treasury: ChargeEntity | IncomeEntity
    ): ChargeCategoryInfo | IncomeCategoryInfo {
        if (!this.transaction) {
            return new ChargeCategoryInfo(ChargeCategoryProperty.various);
        }
        const isCharge = this.transaction.amount < 0;

        return isCharge
            ? new ChargeCategoryInfo(treasury.category as AllChargeCategories)
            : new IncomeCategoryInfo(treasury.category as AllIncomeCategories);
    }

    /**
     * @description Get the periodicity info from the details form to display the right information in the confirmation step
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @param {(ChargeEntity | IncomeEntity)} treasury
     * @returns {(ChargePeriodicityInfo | IncomePeriodicityInfo)}
     * @memberof BankTransactionConfirmationStepContainer
     */
    public getPeriodicityInfo(
        treasury: ChargeEntity | IncomeEntity
    ): ChargePeriodicityInfo | IncomePeriodicityInfo {
        return treasury instanceof ChargeEntity
            ? new ChargePeriodicityInfo(treasury.periodicity)
            : new IncomePeriodicityInfo(treasury.periodicity);
    }
}

import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'omedomNumber' })
@Injectable({ providedIn: 'root' })
export class OmedomNumberPipe implements PipeTransform {
    private decimalSeparator = ',';
    // private thousandsSeparator = ' ';

    transform(value: number | string): string {
        let splitSeparator = this.decimalSeparator;

        if (typeof value === 'number') {
            splitSeparator = '.';
        }

        // const regex = new RegExp(this.thousandsSeparator, 'g');
        const strValue = (value === undefined || value === null ? '' : value)
            .toString()
        //     .replace(regex, '');

        let [leftValue, decimal = ''] = strValue.split(splitSeparator);

        decimal = `${strValue.endsWith(',') || decimal.length > 0 ? this.decimalSeparator : ''
            }${decimal}`.slice(0, 3);
        // leftValue = leftValue.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandsSeparator);

        return `${leftValue}${decimal}`;
    }

    parse(value: string): string {
        value = typeof value != 'string' ? String(value) : value;
        let [leftValue, decimal = ''] = (value || '').split(this.decimalSeparator);

        // leftValue = leftValue.replace(new RegExp(this.thousandsSeparator, 'g'), '');
        decimal = decimal.length > 0 ? `.${decimal}` : '';

        return `${leftValue}${decimal}`;
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChargeListModel, IncomeListModel } from '@omedom/data';

/**
 * @description Component to display a treasury card (charge or income) in the application
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 27/05/2024
 * @export
 * @class TreasuryCardComponent
 * @deprecated
 */
@Component({
    selector: 'omedom-treasury-card',
    templateUrl: './treasury-card.component.html',
    styleUrls: ['./treasury-card.component.scss'],
})
export class TreasuryCardComponent {
    /**
     * @description Treasury to display (charge or income)
     * @author Brisset Killian
     * @date 02/05/2024
     * @type {(ChargeListModel | IncomeListModel)}
     * @memberof TreasuryCardComponent
     */
    @Input() treasury?: ChargeListModel | IncomeListModel;

    /**
     * @description Icon to display on the card, (depends on assets type)
     * @author Brisset Killian
     * @date 02/05/2024
     * @type {string}
     * @memberof TreasuryCardComponent
     */
    @Input() icon?: string;

    /**
     * @description Name to display on the card
     * @author Brisset Killian
     * @date 02/05/2024
     * @type {string}
     * @memberof TreasuryCardComponent
     */
    @Input() name?: string;

    /**
     * @description Image to display on the card (image of property or society)
     * @author Brisset Killian
     * @date 02/05/2024
     * @type {string}
     * @memberof TreasuryCardComponent
     */
    @Input() image?: string;

    /**
     * @description Boolean to display the avatar of the user who created the treasury
     * @author Brisset Killian
     * @date 02/05/2024
     * @type {boolean}
     * @memberof TreasuryCardComponent
     */
    @Input() avatar?: boolean;

    /**
     * @description UID of the current user
     * @author Brisset Killian
     * @date 02/05/2024
     * @type {string}
     * @memberof TreasuryCardComponent
     */
    @Input() currentUserUID?: string;

    /**
     * @description Boolean to display the charge card (true) or the income card (false)
     * @author Brisset Killian
     * @date 03/05/2024
     * @memberof TreasuryCardComponent
     */
    @Input() isCharge = false;

    /**
     * @description Event emitted when the card is clicked
     * @author Brisset Killian
     * @date 02/05/2024
     * @memberof TreasuryCardComponent
     */
    @Output() treasuryClicked = new EventEmitter();

    /**
     * @description Now date
     * @author Brisset Killian
     * @date 02/05/2024
     * @memberof TreasuryCardComponent
     */
    now = new Date();
}

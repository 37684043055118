import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientEntity, UserEntity } from '@omedom/data';

@Component({
    selector: 'omedom-chat-client-card',
    templateUrl: './chat-client-card.component.html',
    styleUrls: ['./chat-client-card.component.scss'],
})
export class ChatClientCardComponent {
    @Input({ required: true }) client!: ClientEntity;

    @Input({ required: true }) user!: UserEntity;

    @Input() isSelected: boolean = false;

    @Output() clientSelected = new EventEmitter<ClientEntity>();

    get displayName(): string {
        const firstname = this.client.firstname || this.user.firstname || '';
        const name = this.client.name || this.user.name || '';
        return `${firstname} ${name}`;
    }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonDatetime, ModalController } from '@ionic/angular';

@Component({
    selector: 'omedom-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
    /**
     * @description The max date that can be selected in the date picker modal
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @type {Date}
     * @memberof DatePickerComponent
     */
    @Input()
    public maxDate: Date = new Date('2200-12-31');

    /**
     * @description The max date that can be selected in the date picker modal: first day of the month of the max date (e.g. '2200-12-01')
     * @author Didier Pascarel
     * @type {string}
     * @memberof DateComponent
     */
    public dateMax?: string;

    /**
     * @description Ref to Ion Datetime component in the template (required) (default: undefined)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @type {IonDatetime}
     * @memberof DatePickerComponent
     */
    @ViewChild('datetimepicker')
    public datetimepicker?: IonDatetime;

    constructor(private modalController: ModalController) {
    }

    ngOnInit(): void {
        this.dateMax = this.maxDate.toISOString().split('T')[0];
    }

    /**
     * @description Update the date picker value and close the modal (if the date picker value is valid)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @returns {*}  {Promise<void>}
     * @memberof DatePickerComponent
     */
    public async updateConfirmed(): Promise<void> {
        // Check if the date picker is valid
        if (!this.datetimepicker) {
            return;
        }

        // Check if the date picker has a value
        if (!this.datetimepicker.value) {
            return;
        }

        // Check if the date picker value is valid
        if (this.datetimepicker.value === 'Invalid Date') {
            return;
        }

        // Check if the date picker value is a string
        if (typeof this.datetimepicker.value !== 'string') {
            return;
        }

        // Confirm the date picker
        await this.datetimepicker.confirm();

        // Get the first day of the month
        const date = new Date(this.datetimepicker.value).getUTCFirstDayOfMonth();

        // Close the modal
        await this.modalController.dismiss(date);
    }

    /**
     * @description Dismiss the date picker modal
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @returns {*}  {Promise<void>}
     * @memberof DatePickerComponent
     */
    public async dismiss(): Promise<void> {
        // Check if the date picker is valid
        if (!this.datetimepicker) {
            return;
        }

        // Close the modal
        await this.modalController.dismiss();

        // Cancel the date picker
        this.datetimepicker.cancel();
    }
}

import {
    AutocompleteOption,
    LoanEntity,
    ProEntity,
    PropertyEntity,
    SocietyEntity,
    UserEntity,
} from '@omedom/data';

/**
 * @description Utils to manage autocomplete data from data array
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 27/10/2023
 * @export
 * @class OmedomAutocomplete
 */
export class OmedomAutocomplete {
    /**
     * @description Return an array of object with value and label from data.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/10/2023
     * @static
     * @template T Type of data
     * @param {T[]} data
     * @returns {AutocompleteOption<T>[]}
     * @memberof OmedomAutocomplete
     * @example
     * // In component
     * this.data = OmedomAutocomplete.getAutocompleteData<UserEntity>(this.users);
     *
     * // In template
     * <omedom-autocomplete [autocomplete]="data"></omedom-autocomplete>
     */
    public static getAutocompleteData<T>(data: T[]): AutocompleteOption<T>[] {
        // Init array
        const options: AutocompleteOption<T>[] = [];

        // Loop on data
        for (const item of data) {
            // Get label and value
            const label = this.getLabel<T>(item);
            const value = item;

            // Create option
            const option = new AutocompleteOption<T>(label, value);

            options.push(option);
        }

        return options;
    }

    /**
     * @description Return label for data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/10/2023
     * @private
     * @static
     * @template T Type of data
     * @param {T} data Data to get label
     * @returns {string}
     * @memberof OmedomAutocomplete
     */
    private static getLabel<T>(data: T): string {
        // Check if data is instance of UserEntity
        if (data instanceof UserEntity) {
            return `${data.firstname} ${data.name} (${data.email}))`;
        }

        // Check if data is instance of PropertyEntity
        if (data instanceof PropertyEntity) {
            let label = `${data.name}`;

            if (data.address?.streetNumber) {
                label += ` (${data.address?.streetNumber}`;
            }

            if (data.address?.street) {
                label += ` (${data.address?.street}`;

                if (data.address?.postalCode) {
                    label += `, ${data.address?.postalCode}`;

                    if (data.address?.city) {
                        label += ` ${data.address?.city}`;
                    }
                }

                label += ')';
            }

            return label;
        }

        // Check if data is instance of SocietyEntity
        if (data instanceof SocietyEntity) {
            return `${data.name}`;
        }

        // Check if data is instance of ProEntity
        if (data instanceof ProEntity) {
            return `${data.name} (${data.email})`;
        }

        // Check if data is instance of LoanEntity
        if (data instanceof LoanEntity) {
            return `${data.name}`;
        }

        throw new Error('Instance of data is not supported');
    }

    /**
     * @description Transform data for autocomplete value.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/10/2023
     * @static
     * @template T
     * @param {T} data
     * @returns {*}  {*}
     * @memberof OmedomAutocomplete
     * @example
     * // In component
     * public transformer(user: UserEntity): string {
     *    return OmedomAutocomplete.transformData<UserEntity>(user);
     * }
     *
     * // In template
     * <omedom-autocomplete [transformValue]="transformer"></omedom-autocomplete>
     */
    public static transformData<T>(data: T): any {
        // Check if data is instance of UserEntity
        if (data instanceof UserEntity) {
            return data.uid;
        }

        throw new Error('Instance of data is not supported');
    }
}

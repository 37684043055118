import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OmedomColorsEnum } from '@omedom/data';

/**
 * @description Interface of the progress bar data to display in the widget (input value, label, progress bar color, etc.)
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 23/09/2024
 * @export
 * @interface ProgressBarDataInterface
 */
export interface ProgressBarDataInterface {
    label?: {
        value: string;
        inGraph: {
            display: boolean;
        };
        inLegend: {
            textColor: string;
            display: boolean;
        };
    };
    input: {
        value: number;
        inGraph: {
            display: boolean;
            textType?: 'number' | 'percentage' | 'amount';
        };
        inLegend: {
            display: boolean;
            textColor?: string;
            textType?: 'number' | 'percentage' | 'amount';
        };
    };
    progressBar?: {
        textColor: string;
        backgroundColor: string;
    };
}

/**
 * @description Layout of the progress bar widget (default, dashboard or mobile)
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 23/09/2024
 * @export
 * @enum {number}
 */
export enum ProgressBarCardLayout {
    DEFAULT = 'default',
    DASHBOARD = 'dashboard',
    MOBILE = 'mobile',
}

/**
 * @description  Type for progress values to display the progress bar
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 23/09/2024
 * @type {ProgressValuesType}
 */
type ProgressValuesType = {
    stringifiedValue: string;
    percentage: number;
    backgroundColor: string;
    textColor: string;
};

/**
 * @description Type for legends to display in the widget
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 23/09/2024
 * @type {legendType}
 */
type legendType = {
    label: {
        value: string;
        color: string;
    };
    input: {
        value: string;
        color: string;
    };
};

@Component({
    selector: 'omedom-widget-progress-bar',
    templateUrl: './widget-progress-bar.component.html',
    styleUrls: ['./widget-progress-bar.component.scss'],
})
export class WidgetProgressBarComponent implements OnInit, OnChanges {
    /**
     * @description Title of the progress bar widget
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/09/2024
     * @memberof WidgetProgressBarComponent
     */
    @Input() title = '';

    /**
     * @description Icon of the progress bar widget
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/09/2024
     * @type {string}
     * @memberof WidgetProgressBarComponent
     */
    @Input() icon?: string;

    /**
     * @description Data to display in the widget and format it
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/09/2024
     * @type {ProgressBarDataInterface[]}
     * @memberof WidgetProgressBarComponent
     */
    @Input() progressBarData: ProgressBarDataInterface[] = [];

    /**
     * @description Layout of the progress bar widget (default, dashboard or mobile)
     * @type {ProgressBarCardLayout}
     * @memberof WidgetProgressBarComponent
     */
    @Input() layout: ProgressBarCardLayout = ProgressBarCardLayout.DEFAULT;

    /**
     * @description Progress values to display the progress bar
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/09/2024
     * @protected
     * @type {ProgressValuesType[]}
     * @memberof WidgetProgressBarComponent
     */
    protected progressValues: ProgressValuesType[] = [];

    /**
     * @description Legends to display inthe widget
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/09/2024
     * @protected
     * @type {legendType[]}
     * @memberof WidgetProgressBarComponent
     */
    protected legends: legendType[] = [];

    constructor(
        private decimalPipe: DecimalPipe,
        private currencyPipe: CurrencyPipe,
        private readonly ref: ElementRef
    ) {}

    ngOnInit(): void {
        // Add the layout class to ref
        this.ref.nativeElement.classList.add(`layout-${this.layout}`);

        // Compute values
        this.updateData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['progressBarData'] ||
            changes['progressValues']
            // && !this.deactivateNoSmart
        ) {
            this.updateData();
        }
    }

    private async updateData(): Promise<void> {
        this.computeValues();
    }

    /**
     * @description Compute values to display in the widget (progress bar and legends) from the input data
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 24/09/2024
     * @private
     * @memberof WidgetProgressBarComponent
     */
    private computeValues() {
        const totalValue = this.progressBarData.map((data) => data.input.value).sum();

        this.progressValues = [];
        this.legends = [];

        this.progressBarData.forEach((data) => {
            /* LEGEND construction */
            const percentage = (data.input.value / totalValue) * 100 || null;
            if (data.input.inLegend.display || data.label?.inLegend.display) {
                const legendLabel = {
                    value: '',
                    color: data.label?.inLegend.textColor || OmedomColorsEnum.lightBlack,
                };
                const legendValue = {
                    value: '',
                    color: data.input.inLegend.textColor || OmedomColorsEnum.lightBlack,
                };

                if (data.input.inLegend.display) {
                    switch (data.input.inLegend.textType) {
                        case 'percentage':
                            legendValue.value = percentage ? percentage.toFixed(2) : '';
                            break;
                        case 'number':
                            legendValue.value =
                                this.decimalPipe.transform(data.input.value) || '0,00';
                            break;
                        case 'amount':
                            legendValue.value =
                                this.currencyPipe.transform(data.input.value, 'EUR') || '0,00 €';
                            break;
                        default:
                            legendValue.value = data.input.value.toString();
                            break;
                    }
                }
                if (data.label?.inLegend.display) {
                    legendLabel.value = data.label.value;
                }

                this.legends.push({ label: legendLabel, input: legendValue });
            }
            /* #END LEGEND */

            /* PROGRESS BAR construction */
            if (data.input.inGraph.display) {
                const backgroundColor = percentage
                    ? data.progressBar?.backgroundColor || '#3491d2'
                    : 'transparent';
                const textColor = percentage
                    ? data.progressBar?.textColor || OmedomColorsEnum.lightBlack
                    : 'transparent';
                let stringifiedValue = '';

                switch (data.input.inGraph?.textType) {
                    case 'percentage':
                        // YOU CAN'T DIVIDE BY 0 (BASIC MATH)
                        stringifiedValue =
                            totalValue === 0
                                ? '0 %'
                                : Math.round((data.input.value / totalValue) * 100) + ' %';
                        break;
                    case 'number':
                        stringifiedValue = this.decimalPipe.transform(data.input.value) || '0,00';
                        break;
                    case 'amount':
                        stringifiedValue =
                            this.currencyPipe.transform(data.input.value, 'EUR') || '0,00 €';
                        break;
                    default:
                        stringifiedValue = data.input.value.toString();
                        break;
                }

                this.progressValues.push({
                    percentage: percentage ? percentage : 0,
                    backgroundColor,
                    textColor,
                    stringifiedValue,
                });
            }
            /* # END PROGRESS BAR */
        });
    }
}

export interface emailMessageToPro {
    proFirstname: string;
    proEmail: string;
    clientFirstname: string;
    message: string;
    messageDate: Date;
    isFirstChatMessageToThisPro: boolean;
}

export interface emailReviewToPro {
    proFirstname: string;
    proEmail: string;
    clientFirstname: string;
    reviewMessage: string;
    reviewRate: number;
}

export interface emailProInDirectory {
    proFirstname: string;
    proName: string;
    proEmail: string;
    name: string; // name of the company;
    values: string; // pro values (honesty, ...)
    position?: string; // pro position
    proLogoURL: string;
}

export interface emailProNewEventFromClient {
    proFirstname: string;
    proEmail: string;
    clientFirstname: string;
    clientName: string;
    eventMessage: string;
}

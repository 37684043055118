<form [formGroup]="form"
      [@list]="keys">
    <omedom-input [boldLabel]="true"
                  formControlName="minimumDate"
                  label="Date de sortie minimum"
                  placeholder="01/09/2034"
                  type="date"></omedom-input>

    <omedom-input [boldLabel]="true"
                  formControlName="otherConstraint"
                  label="Autre contrainte"
                  placeholder="Départ à la retraite"
                  type="text"></omedom-input>
</form>

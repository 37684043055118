import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationActions, NotificationEntity } from '@omedom/data';
import { NotificationService } from '@omedom/services';

@Component({
    selector: 'omedom-notification-card',
    templateUrl: './notification-card.component.html',
    styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent {
    /**
     * @description The notification to display
     * @author Brisset Killian
     * @date 14/05/2024
     * @type {NotificationEntity}
     * @memberof NotificationCardComponent
     */
    @Input() notification?: NotificationEntity;

    @Input() hasEnableActions = true;

    @Output() notificationActionClick = new EventEmitter<{
        action: NotificationActions;
        params?: { [key: string]: string | number | boolean | Date };
    }>();

    @Output() openSetting = new EventEmitter();

    constructor(private notificationService: NotificationService) {}

    /**
     * @description Enable actions for the notification card
     * @author Brisset Killian
     * @date 14/05/2024
     * @readonly
     * @type {boolean}
     * @memberof NotificationCardComponent
     */
    @Input()
    public get enableActions(): boolean {
        if (
            this.notification &&
            this.notification.actions &&
            this.notification.actions?.length > 0 &&
            this.notification.actions[0].title &&
            this.notification.actions[0].action &&
            this.hasEnableActions
        ) {
            const action = this.notification.actions[0].action;
            const params = this.notification.actions[0].params;
            switch (action) {
                case NotificationActions.OPEN_DOCUMENT:
                    return params?.['documentUID'] ? true : false;
                case NotificationActions.OPEN_PROPERTY:
                    return params?.['propertyUID'] ? true : false;
                case NotificationActions.OPEN_EXTERN_URL:
                    return params?.['externUrl'] ? true : false;
                case NotificationActions.RENEW_SUBSCRIPTION:
                    return true;
                case NotificationActions.OPEN:
                    return true;
                case NotificationActions.SEND_RECEIPT:
                    return params?.['propertyUID'] ? true : false;
                case NotificationActions.SEND_REMINDER:
                    return params?.['propertyUID'] ? true : false;
                case NotificationActions.MARK_INCOME_RECEIVED:
                    return false;
                case NotificationActions.MARK_CHARGE_PAID:
                    return false;
                case NotificationActions.OPEN_INCOME:
                    return false;
                default:
                    return false;
            }
        }
        return false;
    }

    /**
     * @description The title of the notification card action button
     * @author Brisset Killian
     * @date 14/05/2024
     * @readonly
     * @type {string}
     * @memberof NotificationCardComponent
     */
    public get actionTitle(): string {
        if (
            this.notification &&
            this.notification.actions &&
            this.notification.actions?.length > 0
        ) {
            return this.notification.actions[0].title;
        }
        return '';
    }

    /**
     * @description The icon of the notification card action button
     * @author Brisset Killian
     * @date 14/05/2024
     * @readonly
     * @memberof NotificationCardComponent
     */
    public get action(): {
        action?: () => void;
        icon: string;
    } {
        if (
            this.notification &&
            this.notification.actions &&
            this.notification.actions?.length > 0
        ) {
            const action = this.notification.actions[0].action;
            const params = this.notification.actions[0].params;
            return { action: () => this.notificationActions(action, params), icon: '' };
        }
        return {
            icon: '',
        };
    }

    /**
     * @description Get the formatted date of the notification card
     * @author Brisset Killian
     * @date 14/05/2024
     * @returns {*}
     * @memberof NotificationCardComponent
     */
    getFormattedDate() {
        const date = this.notification
            ? new Date((this.notification.created as any).seconds * 1000)
            : new Date();
        const actualDate = new Date();
        const diff = actualDate.getTime() - date.getTime();
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        const diffHours = Math.ceil(diff / (1000 * 3600));
        const diffMinutes = Math.ceil(diff / (1000 * 60));
        const diffSeconds = Math.ceil(diff / 1000);
        if (diffDays > 1) {
            return `Il y a ${diffDays} jours`;
        }
        if (diffHours > 1) {
            return `Il y a ${diffHours} heures`;
        }
        if (diffMinutes > 1) {
            return `Il y a ${diffMinutes} minutes`;
        }
        if (diffSeconds > 1) {
            return `Il y a ${diffSeconds} secondes`;
        }
        if (diffSeconds === 1) {
            return 'Il y a 1 seconde';
        }

        return '';
    }

    /**
     * @description Delete the notification card from the list of notifications
     * @author Brisset Killian
     * @date 14/05/2024
     * @memberof NotificationCardComponent
     */
    deleteNotification() {
        if (this.notification) {
            this.notificationService.delete(this.notification.uid);
        }
    }

    /**
     * @description Enable actions for the notification card
     * @author Brisset Killian
     * @date 14/05/2024
     * @param {NotificationActions} action
     * @param {({ [key: string]: string | number | boolean | Date })} [params]
     * @memberof NotificationCardComponent
     */
    notificationActions(
        action: NotificationActions,
        params?: { [key: string]: string | number | boolean | Date }
    ) {
        this.notificationActionClick.emit({
            action,
            params,
        });
    }
}

import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { BankAccountEntity } from '@omedom/data';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'omedom-bank-account-select',
    templateUrl: './bank-account-select.component.html',
    styleUrls: ['./bank-account-select.component.scss'],
    animations: [
        trigger('element', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(15px)' }),
                animate('0.25s', style({ opacity: 1, transform: 'translateY(0px)' })),
            ]),
        ]),
        trigger('list', [
            transition('* => *', [
                // each time the binding value changes
                query(
                    ':enter',
                    [
                        style({ opacity: 0, transform: 'translateY(15px)' }),
                        stagger(100, [
                            animate(
                                '0.25s',
                                style({
                                    opacity: 1,
                                    transform: 'translateY(0px)',
                                })
                            ),
                        ]),
                    ],
                    { optional: true }
                ),
            ]),
        ]),
    ]
})
export class BankAccountSelectComponent implements OnInit {
    /**
     * @description Bank accounts to display in the select component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @type {BankAccountEntity[]}
     * @memberof BankAccountSelectComponent
     */
    @Input({ required: true })
    public accounts: BankAccountEntity[] = [];

    /**
     * @description Selected bank account to display in the select component by default when the component is loaded
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @type {BankAccountEntity}
     * @memberof BankAccountSelectComponent
     */
    @Input()
    public selectedAccount?: BankAccountEntity;

    /**
     * @description Opened state of the select component to display the list of bank accounts or not in the template
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @memberof BankAccountSelectComponent
     */
    public opened$ = new BehaviorSubject<boolean>(false);

    /**
     * @description Max height of the select component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankAccountSelectComponent
     */
    @HostBinding('style.max-height')
    public maxHeight = '70px';

    /**
     * @description Event emitter to emit the selected bank account when the user select a bank account in the select component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @memberof BankAccountSelectComponent
     */
    @Output()
    public selectedAccountChange = new EventEmitter<BankAccountEntity>();

    /**
     * @description Open the select component by default
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/05/2024
     * @type {boolean}
     * @memberof BankAccountSelectComponent
     */
    @Input()
    public openByDefault: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
        if (this.openByDefault && !this.selectedAccount) {
            this.opened$.next(true);
        }

        this.opened$.subscribe(opened => {
            this.maxHeight = opened ? '700px' : '70px';
        });
    }

    /**
     * @description Select a bank account in the select component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @param {BankAccountEntity} account
     * @memberof BankAccountSelectComponent
     */
    public select(account: BankAccountEntity): void {
        if (this.selectedAccount !== account) {
            this.selectedAccount = account;
            this.selectedAccountChange.emit(account);
        }

        // Close the select component
        this.opened$.next(false);
    }

    /**
     * @description Toggle the select component to display the list of bank accounts or not in the template
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @memberof BankAccountSelectComponent
     */
    public toggle(): void {
        this.opened$.next(!this.opened$.value);
    }
}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'omedom-smart-popover',
    templateUrl: './smart-popover.component.html',
    styleUrls: ['./smart-popover.component.scss'],
})
export class SmartPopoverComponent {
    /**
     * @description Text of the smart popover
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {string}
     * @memberof SmartPopoverComponent
     */
    @Input() text?: string;
}

import { Component, EventEmitter, Output } from '@angular/core';
import { questions } from './questions.const';

@Component({
    selector: 'omedom-registration-third-step',
    templateUrl: './registration-third-step.component.html',
    styleUrls: ['./registration-third-step.component.scss'],
})
export class RegistrationThirdStepComponent {
    @Output() nextEnabled: EventEmitter<boolean> = new EventEmitter<boolean>();

    protected readonly questions = questions;

    selectedQuestion: any;

    selectQuestion($event: any) {
        this.nextEnabled.emit(true);
        this.selectedQuestion = $event;
    }
}

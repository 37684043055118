<div class="margin-bottom-2">
    Lot <span style="font-weight: 700">{{ propertyInfo?.propertyLabel }}</span>
</div>

<div class="account-split-field margin-bottom-2">
    <omedom-input
        name="percentage"
        type="number"
        label="Pourcentage"
        inputmode="decimal"
        [min]="0"
        [max]="180"
        step="0.01"
        icon="uil uil-percentage"
        [(ngModel)]="percentage"
        (ngModelChange)="percentageChange($event)"
        [disabled]="disabled"
        [debounce]="300"
    ></omedom-input>

    <omedom-input
        name="amount"
        type="number"
        label="Montant"
        [ngModel]="amount"
        inputmode="decimal"
        icon="uil uil-euro"
        [disabled]="true"
        [debounce]="300"
    ></omedom-input>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { LicenceEntity, ProEntity, SubscriptionEntity, UserEntity } from '@omedom/data';
import { LicenceService, ProService, SubscriptionService, UserService } from '@omedom/services';
import { BehaviorSubject, lastValueFrom, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ShareConditionsComponent } from '../../../components/share-conditions/share-conditions.component';
import { ModalController } from '@ionic/angular';
import { ValidationModalComponent } from '../../../components/validation-modal/validation-modal.component';

@Component({
    selector: 'omedom-licence-gift',
    templateUrl: './licence-gift.component.html',
    styleUrls: ['./licence-gift.component.scss'],
})
export class LicenceGiftComponent implements OnInit, OnDestroy {
    /**
     * @description Licence gifted to the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/02/2024
     * @private
     * @type {LicenceEntity}
     * @memberof LicenceGiftComponent
     */
    private licence?: LicenceEntity;

    /**
     * @description Pro data to display logo
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/02/2024
     * @type {ProEntity}
     * @memberof LicenceGiftComponent
     */
    public pro?: ProEntity;

    /**
     * @description User data to validate step
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/02/2024
     * @type {UserEntity}
     * @memberof LicenceGiftComponent
     */
    public user?: UserEntity;

    /**
     * @description Subscription to destroy when the component is destroyed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/02/2024
     * @private
     * @type {Subscription[]}
     * @memberof LicenceGiftComponent
     */
    private subscriptions: Subscription[] = [];

    public pending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly userService: UserService,
        private readonly subscriptionService: SubscriptionService,
        private readonly licenceService: LicenceService,
        private readonly proService: ProService,
        private readonly router: Router,
        private readonly functions: AngularFireFunctions,
        private readonly modalController: ModalController
    ) {}

    ngOnInit() {
        // TODO: attention au message affiché dans le html, si on partage à utilisateur avec ou non subscription valide, c'est différent
        const licence$ = this.userService.user$
            .pipe(
                switchMap((user) => {
                    // Check if the user is connected
                    if (!user) {
                        return of<undefined>(undefined);
                    }

                    this.user = user;
                    return this.licenceService._getLicencesByUserEmail(user.email);
                })
            )
            .subscribe(async (licences: LicenceEntity[] | undefined) => {
                if (!licences || licences.length === 0) {
                    return;
                }
                this.licence = licences[0];

                if (licences) {
                    this.pro = await this.proService.get(licences[0].proUID);
                }
            });

        this.subscriptions.push(licence$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * @description Go to the next step of the tunnel
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/02/2024
     * @returns {*}  {Promise<void>}
     * @memberof LicenceGiftComponent
     */
    public async acceptLicence(): Promise<void> {
        this.pending$.next(true);
        // Check if the licence and user are defined
        if (!this.licence || !this.user) {
            this.router.navigate(['/']);
            return;
        }

        const callable = this.functions.httpsCallable('licenceValidateByUser');
        await lastValueFrom(
            callable({
                licence: this.licence,
            })
        );

        this.pending$.next(false);
        this.router.navigate(['/tabs/menu/subscription']);
    }

    public async refuseLicence() {
        this.pending$.next(true);
        const refuseValidationModal = await this.modalController.create({
            component: ValidationModalComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                title: 'Etes-vous sûr de refuser le partage à un pro ?',
                message: `En refusant le partage, celà entrainera le refus de l'offre prenium mis à disposition par ${
                    this.pro?.name ? this.pro.name : 'le professionnel'
                }`,
                validateButtonMessage: 'Refuser la licence',
                cancelButtonMessage: 'Annuler',
            },
        });
        refuseValidationModal.present();

        refuseValidationModal.onDidDismiss().then(async (res) => {
            const hasValidated = res.data;
            if (hasValidated) {
                if (!this.licence) {
                    return;
                }
                // We don't cancel Licence now, we keep it to inform pro
                const licenceUpdated: LicenceEntity = { ...this.licence, validationPending: false };
                await this.licenceService.licenceRefusedByClient(licenceUpdated);
                this.router.navigate(['/']);
                this.pending$.next(false);
            } else {
                this.pending$.next(false);
            }
        });
    }

    public async showMention(): Promise<void> {
        const modal = await this.modalController.create({
            component: ShareConditionsComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                modalController: this.modalController,
                isModal: true,
            },
        });

        modal.present();
    }
}

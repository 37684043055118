@if (selectable) {
  <omedom-checkbox
    [disabled]="disabled"
  [(ngModel)]="value"></omedom-checkbox>
}

<div class="bank-transaction-container"
  [class.selectable]="selectable">
  <div class="bank-transaction-header">
    <span class="bank-transaction-header-title">{{ transaction.cleanDescription }}</span>
    <span class="bank-transaction-header-amount">{{ transaction.amount | omedomNumber }} €</span>
  </div>

  @if (account) {
    <div class="bank-transaction-account"
      >
      <i class="uil uil-university"></i>
      <span class="bank-transaction-account-name">{{ account.name }}</span>
    </div>
  }

  @if (showActions) {
    <div class="bank-transaction-footer"
      >
      <ng-container
      [ngTemplateOutlet]="(associated$ | async) ? associated : (transaction.masked ? masked : unmasked)"></ng-container>
    </div>
  }
</div>

<ng-template #associated>
  <button class="omedom-button-secondary"
    (click)="dissociateTransaction()">
    <i class="uil uil-link-broken"></i>
    <span>Dissocier</span>
  </button>
</ng-template>

<ng-template #unmasked>
  <button class="omedom-button-secondary"
    (click)="maskTransaction()">
    <i class="uil uil-eye-slash"></i>
    <span>Masquer</span>
  </button>

  <button class="omedom-button-primary"
    (click)="associateTransaction()">
    <i class="uil uil-link-alt"></i>
    <span>Associer</span>
  </button>
</ng-template>

<ng-template #masked>
  <button class="omedom-button-primary"
    (click)="unmaskTransaction()">
    <i class="uil uil-eye"></i>
    <span>Montrer</span>
  </button>
</ng-template>

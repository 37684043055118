import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'payplugNumberToPrice',
})
@Injectable({ providedIn: 'root' })
export class OmedomPayplugNumberToPricePipe implements PipeTransform {

    transform(number: number | undefined): string {
        if (number === undefined) {
            return '0,00';
        }
        const price = number / 100;
        return price.toFixed(2).replace('.', ',');
    }
}

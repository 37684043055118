import {
    NotificationEvent,
    NotificationEventName,
} from '../interfaces/notification-event.interface';

/**
 * @description Notification events list
 * @export
 * @type {NotificationEvent[]}
 */
export const events: NotificationEvent[] = [
    {
        name: NotificationEventName.mySubscription,
        content: 'Mon abonnement',
        subtitle: 'Renouvellement, problème de paiement',
    },
    {
        name: NotificationEventName.newSharedProperty,
        content: "Un bien m'a été partagé",
    },
    {
        name: NotificationEventName.newDocument,
        content: 'Nouveau document sur un bien partagé',
    },
    // {
    //     content: 'Liquidité négative sur les 6 mois à venir',
    //     smart: true,
    // },
    // {
    //     content: 'Liquidité négative sur les 12 mois à venir',
    //     smart: true,
    // },
    // {
    //     content: 'Ecart de trésorerie dans le prévisionnel',
    //     smart: true
    // },
    {
        name: NotificationEventName.unpayedCharge,
        content: 'Charge impayée',
        subtitle: '(J+1)',
    },
    {
        name: NotificationEventName.unpayedIncome,
        content: 'Revenu non perçu',
        subtitle: '(J+1)',
    },
    // {
    //     name: NotificationEventName.payedIncome,
    //     content: 'Réception d\'un paiement',
    // },
    {
        name: NotificationEventName.sendRentalReceipt,
        content: 'Rappel envoi lettre de relance à mon locataire',
        subtitle: '(loyer impayé J+3)',
    },
    {
        name: NotificationEventName.sendRentalRelaunch,
        content: 'Rappel envoi quittance à mon locataire',
        subtitle: '(loyer payé à date)',
    },
    {
        name: NotificationEventName.updateLoanAccount,
        content: 'Mise à jour de mes prêts',
        subtitle: 'Nouvelle échéance, taux, etc.'
    },
    {
        name: NotificationEventName.updateSavingAccount,
        content: 'Mise à jour de mes épargnes',
        subtitle: 'Nouveau dépôt, taux, etc.'
    },
    // {
    //     content: 'Rentabilité en baisse',
    // },
    // {
    //     content: 'Informations et actualités',
    //     subtitle: 'Taxes à venir, Bilan DPE, etc.'
    // }
    {
        name: NotificationEventName.omdeomNews,
        content: 'Actualités  d\'OMEDOM',
        subtitle: 'Nouveautés, évènements, etc.'
    }
];

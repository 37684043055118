import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AllIncomeCategories,
    IncomeCategoryInfo,
    IncomeCategoryProperty,
    IncomeEntity,
    Mode,
    RoleState,
} from '@omedom/data';
import {
    AnalyticsService,
    PropertyService,
    RefreshService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { OmedomDate } from '@omedom/utils';

import { TreasuryBase } from '../../class';

@Component({
    selector: 'omedom-income',
    templateUrl: './income.component.html',
    styleUrls: ['./income.component.scss'],
})
export class IncomeComponent extends TreasuryBase<IncomeEntity, AllIncomeCategories> {
    @Input() public override hideSticker: boolean = false;

    public override alreadyClicGraph: boolean = false;

    @Input()
    hideFilter: boolean = false;

    @Input()
    mode: Mode = Mode.app;

    @Input()
    showDateBar: boolean = true;

    @Input()
    incomes: IncomeEntity[] = [];

    @Input() roleState?: RoleState;

    @Output()
    navigateToTreasuryDetailEvent: EventEmitter<string> = new EventEmitter();

    @Output()
    navigateToAddTreasuryEvent: EventEmitter<void> = new EventEmitter();

    @Output()
    navigateToCategoryDetailEvent: EventEmitter<{
        category: IncomeCategoryProperty;
        date: string;
    }> = new EventEmitter();

    constructor(
        userService: UserService,
        decimalPipe: DecimalPipe,
        refreshService: RefreshService,
        activeRouter: ActivatedRoute,
        propertyService: PropertyService,
        societyService: SocietyService,
        router: Router,
        private analyticsService: AnalyticsService
    ) {
        super(
            userService,
            decimalPipe,
            refreshService,
            activeRouter,
            propertyService,
            societyService,
            router
        );
        this.treasuryType = 'income';
    }

    ionViewDidEnter() {
        this.analyticsService.setCurrentScreen('Income with graph');
    }

    navigateToIncomesDetail(): void {
        const dateISOFormat = OmedomDate.formatDateToISO(this.currentDate);
        this.navigateToTreasuryDetailEvent.emit(dateISOFormat);
    }

    addClicked(): void {
        this.navigateToAddTreasuryEvent.emit();
    }

    navigateToDetail(category: IncomeCategoryProperty): void {
        this.navigateToCategoryDetailEvent.emit({
            category,
            date: OmedomDate.formatDateToISO(this.currentDate),
        });
    }

    async loadTreasury(userUid: string): Promise<IncomeEntity[]> {
        let propertiesIncomes: IncomeEntity[] = [];
        let societiesIncomes: IncomeEntity[] = [];

        const isFilteredUsed = this.selectedProperties.length || this.selectedSocieties.length;

        const propertiesUid = isFilteredUsed
            ? this.selectedProperties.map((x) => x.id)
            : this.properties.map((x) => x.uid);
        const societiesUid = isFilteredUsed
            ? this.selectedSocieties.map((x) => x.id)
            : this.societies.map((x) => x.uid);

        propertiesIncomes = this.incomes.filter((income) =>
            propertiesUid.includes(income.propertyUID)
        );
        societiesIncomes = this.incomes.filter((income) =>
            societiesUid.includes(income.societyUID)
        );

        return [...propertiesIncomes, ...societiesIncomes];
    }

    transformCategoryToLabel(category: string): IncomeCategoryInfo {
        return new IncomeCategoryInfo(category as AllIncomeCategories);
    }
}

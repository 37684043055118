<div class="omedom-modal">
    <div class="omedom-modal-header">
        <i class="uil uil-times-circle"
           (click)="dismiss()"></i>
    </div>
    <div class="omedom-modal-content">
        <ion-datetime #datetimepicker
                      size="auto"
                      presentation="month-year"
                      [max]="dateMax"
                      locale="fr">
        </ion-datetime>
    </div>

    <div class="omedom-modal-buttons">
        <button class="omedom-button-secondary"
                (click)="dismiss()">
            Annuler
        </button>
        <button class="omedom-button-primary"
                (click)="updateConfirmed()">
            Confirmer
        </button>
    </div>
</div>

import { Component } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { LicenceService, UserService } from '@omedom/services';

interface LicenceSendForm {
    email: FormControl<string>;
}

@Component({
    selector: 'omedom-licence-send',
    templateUrl: './licence-send.component.html',
    styleUrls: ['./licence-send.component.scss'],
})
export class LicenceSendComponent {
    /**
     * @description Send form (email) (default: undefined)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @type {FormGroup<LicenceSendForm>}
     * @memberof LicenceSendComponent
     */
    public sendForm: FormGroup<LicenceSendForm> = new FormGroup({
        email: new FormControl<string>('', {
            nonNullable: true,
            validators: [Validators.required, Validators.email],
        }),
    });

    /**
     * @description Pending state (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @type {boolean}
     * @memberof LicenceSendComponent
     */
    public pending: boolean = false;

    /**
     * @description Error message (default: '')
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @type {string}
     * @memberof LicenceSendComponent
     */
    public errorMessage: string = '';

    public infoMessageSubscriptionAlreadyValid =
        // "Contactez l'utilisateur pour qu'il puisse vous ajoutez dans son annuaire de 'pros de confiance', il pourra ensuite vous partager ses biens, vous n'aurez pas à utiliser de licence";
        "Pour que l'utiliteur puisse vous partager ses biens, contactez le pour qu'il puisse vous ajoutez dans son annuaire de 'pros de confiance, il pourra ensuite vous les partager, vous n'aurez pas à utiliser de licence";
    /**
     * @description True if pro try to send licence to an user with an ongoing subscription
     * @author ANDRE Felix
     * @memberof LicenceSendComponent
     */
    public errorUserCantHaveLicence = false;

    constructor(
        private readonly modalController: ModalController,
        private readonly toastController: ToastController,
        private readonly licenceService: LicenceService,
        private readonly userService: UserService,
        private readonly functions: AngularFireFunctions
    ) {}

    /**
     * @description Send the licence to an email address
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @returns {Promise<void>}
     * @memberof LicenceSendComponent
     */
    public async send(): Promise<void> {
        this.errorUserCantHaveLicence = false;
        const isUserAuth = await this.userService.getAuthState();

        if (!isUserAuth) {
            return;
        }
        // Set the pending state to true
        this.pending = true;

        // Check if the form is valid
        if (!this.sendForm.valid) {
            this.pending = false;
            return;
        }

        // Get the email and the message
        const { email } = this.sendForm.value;

        // Check if the email is defined
        if (!email) {
            this.errorMessage = "L'email n'est pas défini.";
            this.pending = false;
            return;
        }

        this.errorUserCantHaveLicence = !(await this.checkUserCantHaveLicence(email));

        if (this.errorUserCantHaveLicence) {
            // if subscription is still valid, we can't
            this.pending = false;
            this.errorUserCantHaveLicence = true;
            this.errorMessage = "L'utilisateur a déjà un abonnement";
            return;
        }

        let sendLicence: {
            sended: boolean;
            message?: string;
        };

        // Send the licence
        try {
            sendLicence = await this.licenceService.send(email);
        } catch (error: any) {
            this.errorMessage = error.message;

            this.pending = false;

            return;
        }

        // Check if the licence has been shared
        if (!sendLicence.sended) {
            this.errorMessage =
                sendLicence.message ||
                "Une erreur est survenue lors de l'activation de la licence.";

            this.pending = false;

            return;
        }

        // Close the modal
        this.modalController.dismiss();

        // Set the pending state to false
        this.pending = false;

        // Reset the form
        this.sendForm.reset();

        // Display a success message
        const toast = await this.toastController.create({
            message: 'La licence a bien été activée.',
            duration: 3000,
            color: 'success',
            position: 'top',
        });

        toast.present();
    }

    private async checkUserCantHaveLicence(clientEmail: string) {
        const callable = this.functions.httpsCallable('getUserSubscriptionCanHaveLicenceByEmail');
        const userCanHaveLicence = await callable({ userEmail: clientEmail }).toPromise();
        return userCanHaveLicence;
    }
}

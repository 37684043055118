/**
 * @description Saving details of a bank account (unknown for now) in Bridge
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 05/03/2024
 * @export
 * @interface BankSavingsDetails
 */
export interface BankSavingsDetails {
    [x: string]: any;
}

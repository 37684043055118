import { Route } from '@angular/router';
import { RouteData } from '@omedom/data';

import { AuthGuard, EmailVerificationGuard, ProGuard, TemporaryPasswordGuard } from './guards';
import { RestrictedSpaceGuard } from './guards/restricted-space.guard';

export const navigation: RouteData[] = [
    // {
    //     title: 'Accueil',
    //     icon: 'grid',
    //     isOmedomIcon: true,
    //     path: '/dashboard',
    //     index: 1,
    //     type: 'primary',
    // },
    {
        title: 'Clients',
        icon: 'users-alt',
        path: '/clients',
        index: 2,
        type: 'primary',
    },
    {
        title: 'Licences',
        icon: 'tag-alt',
        path: '/licences',
        index: 3,
        type: 'primary',
    },
    {
        title: 'Messagerie',
        icon: 'envelope',
        path: '/messaging',
        index: 4,
        type: 'primary',
    },
    {
        title: 'Compte',
        icon: 'user-circle',
        path: '/user/account',
        index: 5,
        type: 'primary',
    },
    // Secondary navigation
    {
        title: 'Support',
        icon: 'question-circle',
        path: '/support',
        index: 0,
        type: 'secondary',
    },
];

export const appRoutes: Route[] = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'clients',
    },
    // {
    //     path: 'dashboard',
    //     canActivate: [AuthGuard, EmailVerificationGuard, ProGuard, TemporaryPasswordGuard],
    //     loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    // },
    {
        path: 'clients',
        canActivate: [
            AuthGuard,
            RestrictedSpaceGuard,
            EmailVerificationGuard,
            ProGuard,
            TemporaryPasswordGuard,
        ],
        loadChildren: () => import('./client/client.module').then((m) => m.ClientModule),
    },
    {
        path: 'messaging',
        canActivate: [
            AuthGuard,
            RestrictedSpaceGuard,
            EmailVerificationGuard,
            ProGuard,
            TemporaryPasswordGuard,
        ],
        loadChildren: () => import('./chat/chat.module').then((m) => m.ChatModule),
    },
    {
        path: 'user',
        loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    },
    {
        path: 'licences',
        canActivate: [AuthGuard, EmailVerificationGuard, ProGuard, TemporaryPasswordGuard],
        loadChildren: () => import('./license/license.module').then((m) => m.LicenseModule),
    },
    {
        path: 'support',
        loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
    },
    {
        path: '**',
        redirectTo: 'clients',
    },
];

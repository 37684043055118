<div class="margin-bottom-2">
    <omedom-directory-search
        (searchChanged)="searchChanged($event)"
    ></omedom-directory-search>
</div>
<div
    class="card-container"
    [style]=" {
        'max-width': 'min(100%, ' + ((prosFiltered.length ) * 300 )  + 'px)',
    } "
>
    @for(pro of prosFiltered; track pro.uid) {
    <omedom-directory-card
        [pro]="pro"
        (detailClicked)="detailClicked($event)"
    ></omedom-directory-card>
    }
</div>

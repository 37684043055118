import { DataTableColor } from '../enums';
import { Primitive } from '../enums/primitive.enum';

/**
 * @description Column of a data table (label, key, type, transformer, validator) to display in the table or in a form
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 12/09/2023
 * @export
 * @interface DataTableColumn
 * @template T
 */
export interface DataTableColumn<T> {
    label: string;
    key: keyof T;
    type: Primitive;
    sortable?: boolean;
    width?: string;
    colspan?: number;
    transformer?: (data: T) => any;
    validator?: (data: T) => DataTableColor | undefined;
    sorter?: (data: T) => any;
}

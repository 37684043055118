import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ClientEntity, PDFUsage } from '@omedom/data';

import { RestService } from './rest.service';
import { lastValueFrom, map, Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { PdfService } from '../services/pdf.service';

interface ParamsGeneratePDFSummaryClient {
    clientUID?: string;
    fluxChart: {
        dataURL?: string;
        legendRigth?: {
            label: string;
            legends: any[];
        };
        legendLeft?: {
            label: string;
            legends: any[];
        };
    };
    debtChartDataURL: {
        dataURL?: string;
        legends?: any[];
        total?: number;
    };
    treasuryChartDataURL: {
        dataURL?: string;
        legends?: any[];
        total?: number;
    };
}

@Injectable({
    providedIn: 'root',
})
export class ClientService extends RestService<ClientEntity> {
    constructor(
        protected override firestore: AngularFirestore,
        private readonly functions: AngularFireFunctions,
        private readonly pdfService: PdfService
    ) {
        super(firestore, 'clients');
    }

    /**
     * @description Get the client of a user by his UID
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @param {string} userUID
     * @returns {*}  {Promise<ClientEntity[]>}
     * @memberof ClientService
     */
    public async getUserClient(userUID: string): Promise<ClientEntity> {
        // Get the client of the user
        const clients = await this.search([{ where: 'userUID', operator: '==', value: userUID }]);

        // Return the client
        return clients[0];
    }

    /**
     * @description Get the client of a user by his UID - Observable version
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/08/2024
     * @param {string} userUID
     * @returns {*}  {Observable<ClientEntity>}
     * @memberof ClientService
     */
    public _getUserClient(userUID: string): Observable<ClientEntity> {
        return this._search([{ where: 'userUID', operator: '==', value: userUID }]).pipe(
            map((clients) => clients[0])
        );
    }

    public _getUserClients(userUID: string): Observable<ClientEntity[]> {
        return this._search([{ where: 'userUID', operator: '==', value: userUID }]);
    }

    /**
     * @description Get the client link to a pro and a user by their UID
     * @author ANDRE Felix
     * @param {string} userUID
     * @param {string} proUID
     * @returns {*}  {Promise<ClientEntity>}
     * @memberof ClientService
     */
    public async getClientByUserUIDAndProUID(
        userUID: string,
        proUID: string
    ): Promise<ClientEntity> {
        const client = await this.search([
            { where: 'userUID', operator: '==', value: userUID },
            { where: 'proUID', operator: '==', value: proUID },
        ]);

        return client[0];
    }

    /**
     * @description Get the client link to a pro and a user by their UID - Observable version
     * @author ANDRE Felix
     * @param {string} userUID
     * @param {string} proUID
     * @returns {*}
     * @memberof ClientService
     */
    public _getClientByUserUIDAndProUID(userUID: string, proUID: string) {
        return this._search([
            { where: 'userUID', operator: '==', value: userUID },
            { where: 'proUID', operator: '==', value: proUID },
        ]).pipe(map((clients) => clients[0]));
    }

    public async generatePDFSummaryClient(params: ParamsGeneratePDFSummaryClient): Promise<any> {
        // Get the client
        return this.pdfService.createPDF(params, PDFUsage.summaryClient);
    }

    public async connectUserToPro(
        userUID: string,
        proUID: string,
        hasAgreedToShareToPro = false
    ): Promise<ClientEntity> {
        // Create the client
        const callback = this.functions.httpsCallable('connectUserToPro');

        // Call the function
        const result = await lastValueFrom(callback({ userUID, proUID, hasAgreedToShareToPro }));

        // Return the client
        return result;
    }
}

import { ChargePeriodicity, IncomePeriodicity } from '../../entities';

/**
 * @description Element to compare to calculate a recurrence score between a transaction and a treasury item
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 16/05/2024
 * @export
 * @interface BankTransactionRecurrence
 */
export interface BankTransactionRecurrenceBase {
    /**
     * @description The amount of the treasury item
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 16/05/2024
     * @type {number}
     * @memberof BankTransactionRecurrenceBase
     */
    amount: number,

    /**
     * @description The date of the treasury item
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 16/05/2024
     * @type {Date}
     * @memberof BankTransactionRecurrenceBase
     */
    date: Date,

    /**
     * @description The category of the treasury item
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 16/05/2024
     * @type {number}
     * @memberof BankTransactionRecurrenceBase
     */
    category: number,

    /**
     * @description
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 16/05/2024
     * @type {(ChargePeriodicity | IncomePeriodicity)}
     * @memberof BankTransactionRecurrenceBase
     */
    periodicity: ChargePeriodicity | IncomePeriodicity,

    /**
     * @description The description of the treasury item
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 16/05/2024
     * @type {string}
     * @memberof BankTransactionRecurrenceBase
     */
    description?: string,
}

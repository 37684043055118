<div class="omedom-checkbox-container">
    <ion-checkbox
        class="omedom-checkbox"
        [name]="name"
        [ngModel]="value"
        (ngModelChange)="valueChange($event)"
    ></ion-checkbox>
    <label
        [for]="name"
        (click)="valueChange(!value)"
        class="omedom-checkbox-text"
        [class.not-clickable]="labelNotClickable"
    >
        <ng-content></ng-content>
    </label>
</div>

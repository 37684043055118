<ion-content [fullscreen]="true">
  <div class="header">
    <div class="header-icon">
      @switch (currentStep) {
        @case (1) {
          <i class="uil uil-house-user"></i>
        }
        @case (2) {
          <i class="uil uil-comment-message"></i>
        }
        @case (3) {
          <i class="uil uil-question-circle"></i>
        }
      }
    </div>
    <div class="header-text">
      <p>ETAPE {{ currentStep }}/3</p>
      @switch (currentStep) {
        @case (1) {
          <p>
            <strong>A quel bien allons-nous nous intéresser ?</strong>
          </p>
        }
        @case (2) {
          <p>
            <strong>Racontez nous votre bien</strong>
          </p>
        }
        @case (3) {
          <p>
            <strong>Quel est votre besoin ?</strong>
          </p>
        }
      }
    </div>
  </div>

  <omedom-progress-bar [step]="currentStep"></omedom-progress-bar>

  <div class="content">
    @switch (currentStep) {
      @case (1) {
        <omedom-registration-first-step
          [selectedProperty]="property.type"
          (nextEnabled)="setNextEnabled($event)"
          (selectPropertyType)="selectPropertyType($event)"
        ></omedom-registration-first-step>
      }
      @case (2) {
        <omedom-registration-second-step
          class="margin-bottom-1"
          [property]="property"
          (nextEnabled)="setNextEnabled($event)"
        ></omedom-registration-second-step>
      }
      @case (3) {
        <omedom-registration-third-step
          (nextEnabled)="setNextEnabled($event)"
        ></omedom-registration-third-step>
      }
      @default {
        <div>Une erreur est survenue.</div>
      }
    }

    <div class="buttons margin-top-1">
      <!-- class="back-button" -->
      @if (currentStep !== 1) {
        <button
          class="omedom-button-secondary bottom-button"
          (click)="previousClick()"
          [disabled]="currentStep === 1"
          >
          Précédent
        </button>
      }
      <!-- class="next-button" -->
      <button
        class="omedom-button-primary bottom-button"
        (click)="nextClick()"
        [disabled]="!nextEnabled"
        >
        Suivant
      </button>
    </div>
  </div>
</ion-content>

<ion-modal #modalEndInscription [canDismiss]="true" style="--max-width: 400px">
  <ng-template>
    <div class="omedom-modal">
      <div class="omedom-modal-header">
        <div class="omedom-modal-header-title">
          <h3>Inscrivez-vous et retrouvez votre bien.</h3>
        </div>
      </div>

      <div class="omedom-modal-buttons">
        <button
          class="omedom-button-primary"
          (click)="modalEndInscription.dismiss()"
          >
          Ok
        </button>
      </div>
    </div>
  </ng-template>
</ion-modal>

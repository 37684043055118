<div class="transaction" @element>
  <strong>Désignation</strong>
  @if (transaction) {
    <omedom-bank-transaction
      [transaction]="transaction"
      [showActions]="false"
    ></omedom-bank-transaction>
  }
</div>

<ng-container [ngTemplateOutlet]="form ? content : error"></ng-container>

<ng-template #content>
  @if (form) {
    <ng-container [formGroup]="form">
      @if (user$ | async) {
        @if (treasuriesOptions.length > 0) {
          <omedom-select
            [options]="treasuriesOptions"
            [boldLabel]="true"
            formControlName="treasuryUID"
            [label]="selectLabel"
            [placeholder]="treasuriesPlaceholder"
            @element
            [detailedOptions]="true"
            [required]="!isNewTreasury"
          ></omedom-select>
        } @else {
          <div class="empty-container" @element>
            <p>{{ emptyTreasuriesLabel }}</p>
          </div>
        }
      } @else {
        <div @element class="pending-container">
          <span>{{ pendingLabel }}</span>
          <ion-spinner></ion-spinner>
        </div>
      }
      @if (isAlreadyAssociated && transaction) {
        <omedom-info
          iconStart="uil uil-exclamation-triangle"
          @element
          information="Vous avez sélectionné {{ transaction.amount > 0 ? 'un revenu' : 'une charge' }} déjà associé à une transaction. Si vous continuez, cela peut entrainer la dissociation de l'ancienne transaction."
        ></omedom-info>
      }
      @if (!isTreasurySelected) {
        @if (treasuriesOptions.length > 0) {
          <span
            class="separator"
            @element
            >Ou</span
            >
          }
          <omedom-checkbox name="new" @element formControlName="isNewTreasury"
            >{{ checkboxLabel }}</omedom-checkbox
            >
          }
          @if (isNewTreasury) {
            <omedom-select
              [options]="periodicityOptions"
              @element
              [boldLabel]="true"
              formControlName="periodicity"
              [required]="true"
              label="Périodicité"
            ></omedom-select>
            <omedom-input
              [boldLabel]="true"
              @element
              formControlName="notes"
              label="Notes"
              icon="uil uil-notes"
              placeholder="Complément d'informations"
            ></omedom-input>
          }
        </ng-container>
      }
    </ng-template>

    <ng-template #error> Error </ng-template>

    <ng-template #pending>
      <div @element class="pending-container">
        <span>{{ pendingLabel }}</span>
        <ion-spinner></ion-spinner>
      </div>
    </ng-template>


import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PropertyEntity } from '@omedom/data';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'omedom-property-profil-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyProfilInfoComponent implements OnInit {
    /**
     * @description Property to display
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {PropertyEntity}
     * @memberof InfoComponent
     */
    @Input()
    public property?: PropertyEntity;

    /**
     * @description Indoor stuff to display
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {string[]}
     * @memberof InfoComponent
     */
    public indoorLayouts: string[] = [];

    /**
     * @description Outdoor stuff to display
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {string[]}
     * @memberof InfoComponent
     */
    public outdoorLayouts: string[] = [];

    /**
     * @description Building stuff to display
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {string[]}
     * @memberof InfoComponent
     */
    public buildingLayouts: string[] = [];

    /**
     * @description
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {string[]}
     * @memberof InfoComponent
     */
    public heatLayouts: string[] = [];

    public displayDiagnosticCollapse = new BehaviorSubject<boolean>(false);

    public displayRentCollapse = new BehaviorSubject<boolean>(false);

    public displayAmentiesCollapse = new BehaviorSubject<boolean>(false);

    ngOnInit(): void {
        if (!!this.property?.amenities?.length) {
            this.indoorLayouts = this.property.amenities.filter((x) =>
                ['Cuisine équipée', 'Cheminée', 'Climatisation', 'Alarme'].includes(x)
            );
            this.outdoorLayouts = this.property.amenities.filter((x) =>
                [
                    'Balcon / Terrasse',
                    'Jardin / Cour',
                    'Garage / Parking',
                    'Cave / Sous sol',
                    'Piscine',
                    'Dépendance(s)',
                ].includes(x)
            );
            this.buildingLayouts = this.property.amenities.filter((x) =>
                ['Ascenseur', 'Gardien / Concierge', 'Digicode', 'Interphone'].includes(x)
            );
            this.heatLayouts = this.property.amenities
                .filter((x) =>
                    [
                        'Chauffage collectif',
                        'Chauffage individuel',
                        'Chauffage au gaz',
                        'Chauffage éléctrique',
                        'Chauffage au fuel',
                    ].includes(x)
                )
                .map((x) => x.split(' ').splice(-1)[0]);
        } else {
            this.indoorLayouts = [];
            this.outdoorLayouts = [];
            this.buildingLayouts = [];
            this.heatLayouts = [];
        }
    }
}

import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
    ChargeCategoryProperty,
    ChargeDeleteType,
    ChargeEntity,
    ChargePeriodicity,
} from '@omedom/data';
import { ChargeService } from '@omedom/services';

import { TreasuryDelete } from '../../class';
import { OmedomRadioOption } from '../radio/omedom-radio-option';

@Component({
    selector: 'omedom-charge-delete',
    templateUrl: './charge-delete.component.html',
    styleUrls: ['./charge-delete.component.scss'],
})
export class ChargeDeleteComponent extends TreasuryDelete<
    ChargeEntity,
    ChargeCategoryProperty,
    ChargePeriodicity
> {
    deleteOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({
            id: ChargeDeleteType.onlyThisOne,
            label: 'Cette charge',
        }),
        new OmedomRadioOption({
            id: ChargeDeleteType.thisOneAndNext,
            label: 'Cette charge et les suivantes',
        }),
        new OmedomRadioOption({
            id: ChargeDeleteType.all,
            label: 'Toutes les charges de la série',
        }),
    ];

    selectedDeleteOption: ChargeDeleteType = null as any;

    constructor(modalController: ModalController, private chargeService: ChargeService) {
        super(modalController);
    }

    getTreasury(treasuryUid: string): Promise<ChargeEntity | undefined> {
        return this.chargeService.get(treasuryUid);
    }

    updateTreasury(treasury: ChargeEntity): Promise<void> {
        return this.chargeService.update(treasury);
    }

    deleteTreasury(treasuryUid: string): Promise<void> {
        return this.chargeService.delete(treasuryUid);
    }
}

import { FormControl } from '@angular/forms';

/**
 * @description Saving movements form type
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 09/07/2024
 * @type {SavingMovementsFormType}
 */
export type SavingMovementsFormType = {
    /**
     * @description Deposit amount for the saving account (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<number | undefined>)}
     */
    depositAmount: FormControl<number | undefined>,

    /**
     * @description Withdraw amount for the saving account (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @type {(FormControl<number | undefined>)}
     */
    withdrawAmount: FormControl<number | undefined>,

    /**
     * @description Next deposit date for the saving account (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @type {(FormControl<Date | undefined>)}
     */
    nextDepositDate: FormControl<Date | undefined>,

    /**
     * @description Next deposit amount for the saving account (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @type {(FormControl<number | undefined>)}
     */
    nextDepositAmount: FormControl<number | undefined>,
};

import { Component, Input } from '@angular/core';

@Component({
    selector: 'omedom-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
    @Input({ required: true }) avatar!: string;
    @Input() defaultIcon = 'uil-user-circle';
}

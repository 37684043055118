import { ChargePeriodicity, IncomePeriodicity } from '@omedom/data';

export class OmedomPeriodicity {
    public static getPeriodicityLabel(periodicity: IncomePeriodicity | ChargePeriodicity): string {
        switch (periodicity) {
            case IncomePeriodicity.punctual:
            case ChargePeriodicity.punctual:
                return 'Ponctuel';
            case IncomePeriodicity.monthly:
            case ChargePeriodicity.monthly:
                return 'Mensuel';
            case IncomePeriodicity.bimonthly:
            case ChargePeriodicity.bimonthly:
                return 'Bimestriel';
            case IncomePeriodicity.quarterly:
            case ChargePeriodicity.quarterly:
                return 'Trimestriel';
            case IncomePeriodicity.halfYearly:
            case ChargePeriodicity.halfYearly:
                return 'Semestriel';
            case IncomePeriodicity.yearly:
            case ChargePeriodicity.yearly:
                return 'Annuel';
            default:
                throw new Error('Periodicity not found');
        }
    }

    public static getNumberOfMonthStep(periodicity: IncomePeriodicity | ChargePeriodicity): number {
        switch (periodicity) {
            case IncomePeriodicity.punctual:
            case ChargePeriodicity.punctual:
                return 0;
            case IncomePeriodicity.monthly:
            case ChargePeriodicity.monthly:
                return 1;
            case IncomePeriodicity.bimonthly:
            case ChargePeriodicity.bimonthly:
                return 2;
            case IncomePeriodicity.quarterly:
            case ChargePeriodicity.quarterly:
                return 3;
            case IncomePeriodicity.halfYearly:
            case ChargePeriodicity.halfYearly:
                return 6;
            case IncomePeriodicity.yearly:
            case ChargePeriodicity.yearly:
                return 12;
            default:
                throw new Error('Periodicity not found');
        }
    }

    public static fiscaleNumberOfDays(periodicity: IncomePeriodicity | ChargePeriodicity): number {
        switch (periodicity) {
            case IncomePeriodicity.punctual:
            case ChargePeriodicity.punctual:
                return 1;
            case IncomePeriodicity.monthly:
            case ChargePeriodicity.monthly:
                return 30;
            case IncomePeriodicity.bimonthly:
            case ChargePeriodicity.bimonthly:
                return 60;
            case IncomePeriodicity.quarterly:
            case ChargePeriodicity.quarterly:
                return 90;
            case IncomePeriodicity.halfYearly:
            case ChargePeriodicity.halfYearly:
                return 180;
            case IncomePeriodicity.yearly:
            case ChargePeriodicity.yearly:
                return 365;
            default:
                return 30;
        }
    }
}

@if (isLocked) {
  <div class="{{ type }}-overlay" @overlay></div>
  <div class="{{ type }}-fallback" [class.bottom-auto]="bottomAuto" @element>
    <ng-content select="[omedom-lock-fallback]"></ng-content>
  </div>
}

<div class="content">
  <ng-content select="[omedom-lock-content]"></ng-content>
</div>

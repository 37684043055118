import { Observable } from "rxjs";
import { FormItemName } from "../enums";

export interface FormItem {
    id: FormItemName;
    icon: string;
    label: string;
    disabled?: boolean;
    isLocked$?: Observable<boolean>;
    isOmedomIcon?: boolean; // console.log( ???
    type: 'primary' | 'secondary';
}

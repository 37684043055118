/**
 * @description Type of a node (start, action, condition, branch, end) to display in the chart or in a form
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 27/11/2023
 * @export
 * @enum {number}
 */
export enum AutomationNodeType {
    start = 'start',
    action = 'action',
    condition = 'condition',
    branch = 'branch', // branch is a condition with a true and false output, only used in the chart
    end = 'end',
}

@if (legends && legends.length > 0) { @for (legend of legends; track $index) {
<div class="omedom-chart-legend-item" (click)="legendClicked.next(legend)">
    @if (legend.previsionLabel) {
    <div class="chart-legend-label">
        <div class="chart-legend-prevColors">
            <div class="chart-legend-icon">
                <div class="chart-legend-firstRow">
                    <span
                        class="chart-legend-provision"
                        [style.background-color]="
                            !forecastAuthorised ? '#A8ADAF' : '#0076C8'
                        "
                    ></span>
                    <span
                        class="chart-legend-provision"
                        [style.background-color]="
                            !forecastAuthorised ? '#A8ADAF' : '#0076C8'
                        "
                    ></span>
                </div>
                <div class="chart-legend-firstRow">
                    <span
                        class="chart-legend-provision"
                        [style.background-color]="
                            !forecastAuthorised ? '#A8ADAF' : '#FFBE00'
                        "
                    ></span>
                    <span
                        class="chart-legend-provision clickable"
                        [style.background-color]="
                            !forecastAuthorised ? '#A8ADAF' : '#FFBE00'
                        "
                    ></span>
                </div>
            </div>
            <span
                class="omedom-chart-legend-item-label clickable"
                [style.color]="!forecastAuthorised ? '#A8ADAF' : '#04151C'"
                >{{ legend.label }}</span
            >
        </div>
        @if (!forecastAuthorised) {
        <button
            class="omedom-icon-button-primary"
            style="width: 40px; height: 40px"
            (click)="forecastClicked.next()"
        >
            <i class="omedom-icon-crown"></i>
        </button>
        }
    </div>
    } @if (!legend.previsionLabel) {
    <span
        class="omedom-chart-legend-item-color"
        [style.background]="legend.color"
    ></span>
    } @if (!legend.previsionLabel) {
    <span
        class="omedom-chart-legend-item-label {{
            isLegendClickable ? 'clickable' : ''
        }}"
        >{{ legend.label }}</span
    >
    } @if (legend.percent !== undefined || legend.amount !== undefined) {
    <span class="omedom-chart-legend-item-percent">
        @if (legend.percent !== undefined && !disablePercentage) {
        <span>{{ legend.percent }}%</span>
        } @if (legend.amount !== undefined) {
        <strong>{{ legend.amount | number : '0.2-2' }}&nbsp;€</strong>
        }
    </span>
    }
</div>
} } @else {
<div class="omedom-chart-legend-empty">
    <i class="uil uil-info-circle"></i>
    <span class="omedom-chart-legend-empty-text">Aucune donnée disponible</span>
</div>
}

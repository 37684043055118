import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AssetTypes, SelectOption, SocietyEntity } from '@omedom/data';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PropertyService } from './property.service';
import { RestService } from './rest.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class SocietyService extends RestService<SocietyEntity> {
    protected override builder = SocietyEntity;

    constructor(
        protected override firestore: AngularFirestore,
        private userService: UserService,
        private readonly functions: AngularFireFunctions,
        private propertyService: PropertyService
    ) {
        super(firestore, 'societies');
    }

    /**
     * @description Check if the society exist with his uid
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/10/2023
     * @param {string} uid
     * @returns {Observable<boolean>}
     * @memberof UserService
     * @example
     * // Use in validator
     * ...
     * this.formBuilder.group({
     *    societyUID: new FormControl<string>(pro?.userUID ?? '', {
     *       validators: [Validators.required],
     *      asyncValidators: [OmedomValidators.isUserExist(this.userService)],
     *   })
     * });
     *
     */
    public isSocietyExist(uid: string): Observable<boolean> {
        return this.functions
            .httpsCallable<{ uid: string }, { isSocietyExist: boolean }>('isSocietyExist')({ uid })
            .pipe(map((data) => data.isSocietyExist));
    }

    public _getUserSocietiesAndShared(userUID: string): Observable<SocietyEntity[]> {
        // Get user properties
        const userSocieties$ = this._search([
            { where: 'userUID', operator: '==', value: userUID },
        ]) as Observable<SocietyEntity[]>;

        // Get user data
        const user$ = this.userService._get(userUID);

        // Get shared societies
        const sharedSocieties$ = user$.pipe(
            switchMap((user) => {
                // Get shared societies of the user
                if (!user || !user.sharedSocietiesUID || user.sharedSocietiesUID.length === 0) {
                    return of([]);
                }

                const sharedSocieties$ = user.sharedSocietiesUID.map((x) => this._get(x));

                // Combine all shared properties
                return combineLatest(sharedSocieties$ as Observable<SocietyEntity>[]);
            }),
            catchError((error) => {
                console.error('Error : ', error);
                return of([]);
            })
        );

        // Filter property without UID
        const filteredSharedProperties$ = sharedSocieties$.pipe(
            map((societies) => {
                return societies.filter((x) => x);
            })
        );

        // Merge all properties and apply filters
        const merge = combineLatest([userSocieties$, filteredSharedProperties$]).pipe(
            map(([userProperties, filteredSharedProperties]) => {
                // Merge all properties
                const allSocities = [...userProperties, ...filteredSharedProperties];

                // Remove undefiened
                const propertiesExists = allSocities.filter((x) => x);

                // Remove duplicates
                const societies = propertiesExists.filter(
                    (v, i, a) => a.findIndex((t) => t.uid === v.uid) === i
                );

                // Return properties
                return societies ?? [];
            })
        );

        return merge;
    }

    public async getUserSocietiesAndShared(userUID: string): Promise<SocietyEntity[]> {
        // Get user data
        const user = await this.userService.get(userUID);

        // Check if user exist
        if (!user) {
            return [];
        }

        // Get user properties
        const userSocieties = await this.search([
            { where: 'userUID', operator: '==', value: userUID },
        ]);

        // Get shared properties
        const sharedSocieties = await Promise.all(
            user.sharedSocietiesUID?.map(async (society) => await this.get(society)) ?? []
        );

        // Filter property without UID
        const filteredSharedSocieties = sharedSocieties.filter((x) => x);

        // Get society shared properties
        // const societySharedProperties: SocietyEntity[] = [];
        // if (user.memberSocietiesUID) {
        //     for (const societyUID of user.memberSocietiesUID) {
        //         // Check if societyUID is defined
        //         if (!societyUID) {
        //             continue;
        //         }

        //         // Get society properties
        //         const societyProperties = await this.getPropertiesBySociety(societyUID);

        //         // Add society properties to societySharedProperties
        //         societySharedProperties.push(...societyProperties);
        //     }
        // }

        // Merge all properties
        const allSocieties = [
            ...userSocieties,
            ...filteredSharedSocieties,
            // ...societySharedProperties,
        ];

        // Remove undefiened
        const societiesExists = allSocieties.filter((x) => x) as SocietyEntity[];

        // Remove duplicates
        const societies = societiesExists.filter(
            (v, i, a) => a.findIndex((t) => t.uid === v.uid) === i
        );
        return societies;
    }

    public async getUserSocietiesAndSharedOptions(userUID: string): Promise<SelectOption[]> {
        const societies = (await this.getUserSocietiesAndShared(userUID))
            .filter((x) => x)
            .map(
                (x) =>
                    ({
                        id: x?.uid,
                        label: x?.name,
                        image: x?.photo,
                        isAccesible: true,
                        purchaseYear: undefined,
                        assetType: AssetTypes.society,
                    } as SelectOption)
            );

        const sortProperties = societies.sort((a, b) => a.label.localeCompare(b.label));

        return sortProperties;
    }

    public async getUserSocieties(userUID: string): Promise<SocietyEntity[]> {
        return this.search([{ where: 'userUID', operator: '==', value: userUID }]);
    }

    public _getUserSocieties(userUID: string): Observable<SocietyEntity[]> {
        return this._search([{ where: 'userUID', operator: '==', value: userUID }]);
    }

    public async getUserSocietiesOptions(userUID: string): Promise<SelectOption[]> {
        const societies = await this.getUserSocieties(userUID);
        const societiesOption = societies.map(
            (x) =>
                ({
                    id: x.uid,
                    label: x.name,
                    image: x.photo,
                    icon: !x.photo ? 'uil uil-suitecase' : undefined,
                    isAccesible: true,
                    purchaseYear: undefined,
                    assetType: AssetTypes.society,
                } as SelectOption)
        );

        const sortProperties = societiesOption.sort((a, b) => a.label.localeCompare(b.label));

        return sortProperties;
    }

    /**
     * @description get society option and all the property linked to this society
     * @author ANDRE Felix
     * @param {string} userUID
     * @returns {*}  {Promise<SelectOption[]>}
     * @memberof SocietyService
     */
    public async getSocietyOptions(societyUID: string): Promise<SelectOption[]> {
        if (!societyUID) {
            return [];
        }
        const society = await this.get(societyUID);

        // Check if society exist
        if (!society) {
            return [];
        }

        const societyOption = {
            id: society.uid,
            label: society.name,
            image: society.photo,
            purchaseYear: undefined,
            assetType: AssetTypes.society,
        };

        const properties = await this.propertyService.search([
            { where: 'societyUID', operator: '==', value: societyUID },
        ]);
        const propertiesOption = properties.map(
            (x) =>
                ({
                    id: x.uid,
                    label: x.name,
                    image: x.photo,
                    isAccesible: true,
                    purchaseYear: undefined,
                    assetType: AssetTypes.property,
                } as SelectOption)
        );

        const sortAssets = [societyOption, ...propertiesOption].sort((a, b) =>
            a.label.localeCompare(b.label)
        );

        return sortAssets;
    }

    public getSocietiesOptions(societies: SocietyEntity[]): SelectOption[] {
        const societiesOption = societies.map(
            (x) =>
                ({
                    id: x.uid,
                    label: x.name,
                    image: x.photo,
                    icon: !x.photo ? 'uil uil-suitecase' : undefined,
                    isAccesible: true,
                    purchaseYear: undefined,
                    assetType: AssetTypes.society,
                } as SelectOption)
        );

        const sortProperties = societiesOption.sort((a, b) => a.label.localeCompare(b.label));

        return sortProperties;
    }
}

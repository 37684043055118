<!-- Content -->

@if (user) {
<div class="content">
    <div class="list">
        <div class="list-item">
            <div class="list-item-header" @element>
                <div class="list-item-title">Recevoir des notifications</div>
                <div class="list-item-star">
                    <ion-toggle
                        color="secondary"
                        [(ngModel)]="user.notificationsEnabled"
                        (ngModelChange)="update()"
                    ></ion-toggle>
                </div>
            </div>
        </div>
    </div>
    <div class="grid" [@list]="events.length">
        @for (event of events; track $index) {
        <div class="grid-item">
            <div class="grid-item-container">
                <div class="grid-item-content">{{ event.content }}</div>
                @if (event.subtitle) {
                <div class="grid-item-subtitle">
                    {{ event.subtitle }}
                </div>
                }
            </div>
        </div>
        <div class="grid-item" data-push="true">
            <div class="grid-item-star">
                @if (!event.smart || hasSmart) {
                <ion-checkbox
                    class="omedom-checkBox"
                    [disabled]="
                        !user.notificationPushEnabled ||
                        !user.notificationsEnabled
                    "
                    [checked]="getEventNotification(event.name, 'push')"
                    (ionChange)="toggleEvent(event.name, event.content, 'push')"
                ></ion-checkbox>
                }
            </div>
        </div>
        }
    </div>
</div>
}

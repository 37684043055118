import { ClientTabTree } from '../models';

export const defaultTreasuryTree: ClientTabTree[] = [
    new ClientTabTree({
        label: "Biens d'usage",
        name: 'personalProperties',
        level: 0,
        childrens: [
            new ClientTabTree({
                label: 'Résidence principale',
                name: 'mainResidence',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
            new ClientTabTree({
                label: 'Voitures',
                name: 'cars', // The best movie of vroum vroum
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
        ],
    }),
    new ClientTabTree({
        label: 'Biens Professionnels',
        name: 'professionalProperties',
        level: 0,
        childrens: [
            new ClientTabTree({
                label: 'Parts de sociétés',
                name: 'companyShares',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
        ],
    }),
    new ClientTabTree({
        label: 'Immobilier de Rapport',
        name: 'rentalProperties',
        level: 0,
        childrens: [
            new ClientTabTree({
                label: 'Location Meublée Tourisme',
                name: 'furnishedTourismRental',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
            new ClientTabTree({
                label: 'Appartement en Location Nue',
                name: 'nakedRentalApartment',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
            new ClientTabTree({
                label: 'Vignes Louées BLT',
                name: 'leasedVinesBLT',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
        ],
    }),
    new ClientTabTree({
        label: 'Épargne Retraite',
        name: 'retirementSavings',
        level: 0,
        childrens: [
            new ClientTabTree({
                label: 'Madelin Retraite',
                name: 'madelin',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
            new ClientTabTree({
                label: 'PERP',
                name: 'perp',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
        ],
    }),

    new ClientTabTree({
        label: 'Épargne Financière',
        name: 'financialSavings',
        level: 0,
        childrens: [
            new ClientTabTree({
                label: 'Compte Titre Actions',
                name: 'stockAccount',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
            new ClientTabTree({
                label: 'PEA',
                name: 'pea',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
            new ClientTabTree({
                label: 'Assurance Vie',
                name: 'lifeInsurance',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
        ],
    }),
    new ClientTabTree({
        label: 'Épargne Projet',
        name: 'projectSavings',
        level: 0,
        childrens: [
            new ClientTabTree({
                label: 'PEL',
                name: 'pel',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
        ],
    }),
    new ClientTabTree({
        label: 'Disponibilités',
        name: 'availability',
        level: 0,
        childrens: [
            new ClientTabTree({
                label: 'Livrets',
                name: 'booklets',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
            new ClientTabTree({
                label: 'Compte Courant',
                name: 'currentAccount',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
        ],
    }),
];

export const defaultDebtsTree: ClientTabTree[] = [
    new ClientTabTree({
        label: 'Emprunts résidence principale',
        name: 'mainResidenceLoans',
        level: 0,
        value: null,
        canChangeValue: true,
        canAddChildrens: true,
    }),
    new ClientTabTree({
        label: 'Emprunts Conso',
        name: 'consumerLoans',
        level: 0,
        value: null,
        canChangeValue: true,
        canAddChildrens: true,
    }),
    new ClientTabTree({
        label: 'Emprunts Fonciers Locatif',
        name: 'rentalLandLoans',
        level: 0,
        value: null,
        canChangeValue: true,
        canAddChildrens: true,
    }),
    new ClientTabTree({
        label: 'Emprunts Professionnels',
        name: 'professionalLoans',
        level: 0,
        value: null,
        canChangeValue: true,
        canAddChildrens: true,
    }),
];

export const defaultIncomeTree: ClientTabTree[] = [
    new ClientTabTree({
        label: 'Revenus professionnels, retraites',
        name: 'professionalIncomes',
        level: 0,
        childrens: [
            new ClientTabTree({
                label: 'Salaire',
                name: 'salary',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
            new ClientTabTree({
                label: 'Bénéfices agricoles',
                name: 'agriculturalProfits',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
        ],
    }),
    new ClientTabTree({
        label: 'Revenus locatifs',
        name: 'rentalIncomes',
        level: 0,
        childrens: [
            new ClientTabTree({
                label: "Loyers de location d'appartements",
                name: 'apartmentRents',
                level: 1,

                value: null,
                canCollapseChildrens: true,
                collapsedChildrens: true,
                canChangeValue: true,
                canAddChildrens: true,
            }),
            new ClientTabTree({
                label: 'Fermages',
                name: 'farmRents',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
        ],
    }),
    new ClientTabTree({
        label: 'Revenus de placements financiers',
        name: 'financialIncomes',
        level: 0,
        childrens: [
            new ClientTabTree({
                label: 'Dividendes',
                name: 'dividends',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
        ],
    }),
    new ClientTabTree({
        label: 'Revenus prestations sociales',
        name: 'socialIncomes',
        level: 0,
        value: null,
        canChangeValue: true,
        canAddChildrens: true,
    }),
];

export const defaultChargesTree: ClientTabTree[] = [
    new ClientTabTree({
        label: "Mensualités d'emprunts",
        name: 'loanMonthlyPayments',
        level: 0,
        value: null,
        canChangeValue: true,
        canAddChildrens: true,
    }),
    new ClientTabTree({
        label: "Assurances d'emprunts",
        name: 'loanInsurances',
        level: 0,
        value: null,
        canChangeValue: true,
        canAddChildrens: true,
    }),
    new ClientTabTree({
        label: 'Charges locatives',
        name: 'rentalCharges',
        level: 0,
        childrens: [
            new ClientTabTree({
                label: 'Frais de gestion',
                name: 'managementFees',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
        ],
    }),
    new ClientTabTree({
        label: 'Charges diverses',
        name: 'variousCharges',
        level: 0,
        childrens: [
            new ClientTabTree({
                label: 'Pension alimentaire',
                name: 'alimony',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
            new ClientTabTree({
                label: "Frais d'études des enfants",
                name: 'childrenStudies',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
        ],
    }),
    new ClientTabTree({
        label: 'Impôts et taxes',
        name: 'taxes',
        level: 0,
        childrens: [
            new ClientTabTree({
                label: "Taxe Locales (Taxe Foncière, Taxe d'habitation)",
                name: 'propertyTax',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
            new ClientTabTree({
                label: 'Impôt sur le revenu',
                name: 'incomeTax',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
            new ClientTabTree({
                label: 'Prélèvements sociaux',
                name: 'socialLevies',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
            new ClientTabTree({
                label: 'I.F.I (Impôt sur la Fortune Immobilière, ex I.S.F)',
                name: 'ifi',
                level: 1,
                value: null,
                canChangeValue: true,
                canAddChildrens: true,
            }),
        ],
    }),
];

export const defaultSavingCapacityTree: ClientTabTree[] = [
    new ClientTabTree({
        label: 'Capacité d’épargne',
        name: 'savingCapacity',
        level: 0,
        value: null,
        canChangeValue: true,
        canAddChildrens: true,
    }),
];

import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { OmedomTab } from './omedom-tab';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'omedom-tab',
    templateUrl: './tab.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class TabComponent {
    /**
     * @description Tabs of the tab component
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {OmedomTab[]}
     * @memberof TabComponent
     */
    @Input() tabs: OmedomTab[] = [];

    /**
     * @description Selected tab of the tab component
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {OmedomTab}
     * @memberof TabComponent
     */
    @Input() selectedTab?: OmedomTab;

    /**
     * @description Emit event when the selected tab is changed
     * @author Brisset Killian
     * @date 26/04/2024
     * @memberof TabComponent
     */
    @Output() selectedTabChange = new EventEmitter<OmedomTab>();

    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.route.queryParamMap.subscribe((params: any) => {
            const tabParam = params.params.tab;
            //
            if (tabParam) {
                this.tabs.forEach((tab: OmedomTab, index: number) => {
                    if (tab.id.toString() === tabParam.toString()) {
                        this.selectedTab = this.tabs[index];
                        this.selectedTabChange.next(this.selectedTab);
                    }
                });
                return;
            }
            this.selectedTab = this.tabs[0];
            this.selectedTabChange.next(this.selectedTab);
        });
    }

    /**
     * @description Triggered when a tab is clicked, change the selected tab and
     * emit the event selectedTabChange with the new selected tab as parameter
     * @author Brisset Killian
     * @date 26/04/2024
     * @param {OmedomTab} tab
     * @memberof TabComponent
     */
    async tabClicked(tab: OmedomTab) {
        await this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                tab: tab.id,
            },
            // queryParamsHandling: 'merge',
            // preserve the existing query params in the route
            // skipLocationChange: true,
            // do not trigger navigation
        });
        // Keep it after route param change
        this.selectedTab = tab;
        this.selectedTabChange.next(tab);
    }
}

<p>
  Dites-nous tout sur votre bien ! Plus vous serez précis, plus notre solution
  pourra vous aider au quotidien.
</p>
<!-- <form *ngIf="addressForm" [formGroup]="addressForm" style="width: 100%">
<div class="form-flex">
  <omedom-input
    style="width: 120px"
    class="margin-bottom-1"
    style="width: 140px"
    name="streetNumber"
    [min]="0"
    [max]="9999"
    type="number"
    formControlName="streetNumber"
    placeholder="N°"
    autocomplete="off"
    [debounce]="300"
    label="Numéro logement"
  ></omedom-input>

  <omedom-input
    style="flex: 1"
    class="margin-bottom-1"
    name="street"
    type="text"
    formControlName="street"
    placeholder="Rue"
    autocomplete="off"
    [debounce]="300"
    label="Rue"
  ></omedom-input>
</div>

<omedom-input
  class="margin-bottom-1"
  name="addressLine2"
  type="text"
  formControlName="addressLine2"
  placeholder="Complément d'adresse"
  autocomplete="off"
  [debounce]="300"
  label="Complément d'adresse"
></omedom-input>

<omedom-input
  class="margin-bottom-1"
  name="postalCode"
  type="number"
  formControlName="postalCode"
  placeholder="Code postal"
  autocomplete="off"
  [debounce]="300"
  label="Code postal"
></omedom-input>

<omedom-input
  class="margin-bottom-1"
  name="city"
  type="text"
  formControlName="city"
  placeholder="Ville"
  autocomplete="off"
  [debounce]="300"
  label="Ville"
></omedom-input>
</form> -->

<omedom-input
  label="Nom de la propriété"
  [required]="true"
  [(ngModel)]="property.name"
  (ngModelChange)="formValid()"
></omedom-input>
@if (property.address) {
  <div class="form-flex">
    <omedom-input
      style="width: 120px"
      label="N° rue"
      placeholder="N°"
      type="number"
      [required]="false"
      [(ngModel)]="property.address.streetNumber"
    ></omedom-input>
    <omedom-input
      class="margin-bottom-1"
      style="width: 130px"
      name="suffixNumber"
      type="text"
      [(ngModel)]="property.address.suffixNumber"
      placeholder="bis, ter"
      autocomplete="off"
      [required]="false"
      [debounce]="300"
      label="Suffixe"
    ></omedom-input>
    <omedom-input
      style="flex: 1"
      label="Rue"
      placeholder="Rue"
      [required]="false"
      [(ngModel)]="property.address.street"
    ></omedom-input>
  </div>
  <omedom-input
    label="Complément d'adresse"
    placeholder="Complément d'adresse"
    [required]="false"
    [(ngModel)]="property.address.addressLine2"
  ></omedom-input>
  <div class="postal-code-line">
    <omedom-input
      label="Code postal"
      [required]="false"
      placeholder="Code postal"
      type="text"
      [pattern]="codepostalRegex"
      [(ngModel)]="property.address.postalCode"
    ></omedom-input>
    <omedom-input
      label="Ville"
      placeholder="Ville"
      [required]="false"
      [(ngModel)]="property.address.city"
    ></omedom-input>
  </div>
}
<omedom-select
  [(ngModel)]="selectedDetention"
  label="Détention"
  class="margin-top-1"
  [placeholder]="owningPlaceholder"
  [options]="owningOptions"
></omedom-select>

@if (property.purchaseDetails) {
  <omedom-select
    label="Usage"
    [required]="true"
    [(ngModel)]="property.purchaseDetails.use"
    (ngModelChange)="formValid()"
    [placeholder]="usePlaceholder"
    [options]="useOptions"
    [isMultiple]="isBuilding"
  ></omedom-select>
}

import { SafeResourceUrl } from '@angular/platform-browser';

import { RestEntity } from './rest.entity';

export enum TutorialVideoProvider {
    youtube = 'youtube',
    storage = 'storage',
}

export class TutorialVideoEntity extends RestEntity {
    /**
     * @description Title of the video
     * @author Brisset Killian
     * @date 18/07/2024
     * @type {string}
     * @memberof VideoEntity
     */
    public title!: string;

    /**
     * @description Description of the video
     * @author Brisset Killian
     * @date 18/07/2024
     * @type {string}
     * @memberof VideoEntity
     */
    public description!: string;

    /**
     * @description Provider of the video (youtube, storage, etc.)
     * @author Brisset Killian
     * @date 18/07/2024
     * @type {VideoProvider}
     * @memberof VideoEntity
     * @example
     * ```typescript
     * provider = VideoProvider.youtube;
     * ```
     */
    public provider!: TutorialVideoProvider;

    /**
     * @description iframe source of the video (youtube, storage, etc.)<br>
     * @author Brisset Killian
     * @date 18/07/2024
     * @type {string}
     * @memberof VideoEntity
     * @example
     * ```html
     * <iframe [src]="iframeSource"></iframe>
     * ```
     */
    public iframeSource!: string | SafeResourceUrl;

    /**
     * @description Icon of the video (youtube, storage, etc.)<br>
     * @author Brisset Killian
     * @date 18/07/2024
     * @type {string}
     * @memberof TutorialVideoEntity
     */
    public icon!: string;

    /**
     * @description If the video should be played automatically or not
     * @author Brisset Killian
     * @date 18/07/2024
     * @type {boolean}
     * @memberof VideoEntity
     */
    autoPlay?: boolean;

    /**
     * @description If the video should be looped or not
     * @author Brisset Killian
     * @date 18/07/2024
     * @type {boolean}
     * @memberof VideoEntity
     */
    loop?: boolean;

    /**
     * @description Order of the video in the list of videos
     * @author Brisset Killian
     * @date 19/07/2024
     * @type {number}
     * @memberof TutorialVideoEntity
     */
    order!: number;
}

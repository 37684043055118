<div class="card">
    @if (type === 'rentability') {
    <div class="card-header w-100">
        <i class="uil uil-arrow-growth"></i>
        <span class="card-title">
            Rentabilité{{ !isProperty ? ' totale' : '' }}
        </span>
        <omedom-popover
            [content]="
                isProperty
                    ? 'Ratio entre le prix d\'acquisition de votre bien locatif (incluant les frais) et le total des loyers nets annuels.'
                    : 'Ratio entre le prix d\'acquisition de vos biens locatifs (incluant les frais) et le total des loyers nets annuels.'
            "
        >
        </omedom-popover>
    </div>
    } @if (type === 'liquidity') {
    <div class="card-header w-100">
        <i class="uil uil-graph-bar"></i>
        <span class="card-title"> Trésorerie </span>
        <omedom-popover
            content="Différence entre le total des revenus et le total des charges de votre parc locatif."
        >
        </omedom-popover>
    </div>
    }

    <div class="footer">
        <span
            class="price"
            [class.price-negative]="value < 0"
            [class.price-positive]="value > 0"
        >
            {{
                type === rentabilityTypeEnum.LIQUIDITY
                    ? (value | currency : 'EUR')
                    : ''
            }}
            {{
                type === rentabilityTypeEnum.RENTABILITY
                    ? (value | number) + ' % brut'
                    : ''
            }}
        </span>
    </div>
</div>

import { RestEntity } from './rest.entity';
export class TrialEntity extends RestEntity {
    public email!: string;
    public name!: string;
    public firstname!: string;
    /**
     * @description number of months
     */
    public trialPeriod!: number;
    public promoDEscription?: string;
    public userUID?: string;
}

<div class="smart-option-container">
    <omedom-action
        class="margin-top-2 w-100"
        text="{{ text }}"
        (actionClicked)="goSmart()"
        actionIcon="omedom-icon-crown"
    >
    </omedom-action>
    <button class="omedom-button-primary w-100" (click)="goSmart()">
        Changer mon abonnement
    </button>
</div>

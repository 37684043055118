import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { LicenceEntity, ProEntity } from '@omedom/data';
import { OmedomEnvironment } from '@omedom/environment';
import { lastValueFrom, Observable } from 'rxjs';

import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root',
})
export class LicenceService extends RestService<LicenceEntity> {
    protected override builder = LicenceEntity;

    constructor(
        protected override firestore: AngularFirestore,
        private functions: AngularFireFunctions
    ) {
        super(firestore, 'licences');
    }

    /**
     * @description Get the licences of a pro by the pro UID
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/09/2023
     * @param {string} proUID
     * @returns {*}  {Observable<LicenceEntity[]>}
     * @memberof LicenceService
     */
    public _getLicencesByPro(proUID: string): Observable<LicenceEntity[]> {
        return this._search([
            {
                where: 'proUID',
                operator: '==',
                value: proUID,
            },
        ]);
    }

    public _getLicencesByUserEmail(email: string): Observable<LicenceEntity[]> {
        return this._search([
            {
                where: 'userEmail',
                operator: '==',
                value: email,
            },
        ]);
    }

    /**
     * @description Send the licence to an email address
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @param {ProEntity} pro The pro of the licence
     * @param {string} email The email to send the licence
     * @param {LicenceEntity} [licence] The licence that will be sent (optional)
     * @returns {Promise<{sended: boolean; message?: string}>}
     * @memberof LicenceService
     */
    public async send(
        email: string,
        licence?: LicenceEntity
    ): Promise<{ sended: boolean; message?: string }> {
        // Check if the email is defined
        if (!email) {
            throw new Error('The email is not defined');
        }

        // Check if the licence is valid
        if (licence) {
            // Check if the licence is used
            if (licence.used) {
                throw new Error('The licence is already used');
            }
        }

        // Share the licence
        const callable = this.functions.httpsCallable<
            {
                email: string;
                licenceUID?: string;
            },
            {
                sended: boolean;
                message?: string;
            }
        >('sendLicence');

        // Call the function
        let result: {
            sended: boolean;
            message?: string;
        };

        try {
            result = await lastValueFrom(callable({ email, licenceUID: licence?.uid }));
        } catch (error) {
            // Log the error
            if (OmedomEnvironment.currentEnvionment === 'development') {
                console.error('Impossible to call the "Send Licence" function', error);
            }

            // Throw the error
            throw error;
        }

        // Check if the licence has been shared
        if (!result) {
            throw new Error('Impossible to share the licence');
        }

        // Return the result
        return result;
    }

    /**
     * @description Send the licence to a new email address
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/02/2024
     * @param {string} oldEmail The old email
     * @param {string} newEmail The new email
     * @returns {Promise<{sended: boolean; message?: string}>}
     * @memberof LicenceService
     */
    public async exchange(
        licence: LicenceEntity,
        oldEmail: string,
        newEmail: string
    ): Promise<{ sended: boolean; message?: string }> {
        // Check if the old email is defined and if the new email is defined
        if (!oldEmail || !newEmail) {
            throw new Error('The email is not defined');
        }

        // Share the licence
        const callable = this.functions.httpsCallable<
            {
                licenceUID: string;
                oldEmail: string;
                newEmail: string;
            },
            {
                sended: boolean;
                message?: string;
            }
        >('exchangeLicence');

        // Call the function
        let result: {
            sended: boolean;
            message?: string;
        };

        try {
            result = await lastValueFrom(callable({ licenceUID: licence.uid, oldEmail, newEmail }));
        } catch (error) {
            // Log the error
            if (OmedomEnvironment.currentEnvionment === 'development') {
                console.error('Impossible to call the "Exchange Licence" function', error);
            }

            // Throw the error
            throw error;
        }

        // Check if the licence has been exchanged
        if (!result) {
            throw new Error('Impossible to exchange the licence');
        }

        // Return the result
        return result;
    }

    /**
     * @description Order licences and send mail to manager
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/09/2023
     * @param {number} numberOfLicences The number of licences to order
     * @returns {Promise<{ordered: boolean; message?: string}>}
     * @memberof LicenceService
     */
    public async order(numberOfLicences: number): Promise<{ ordered: boolean; message?: string }> {
        // Check if the number of licences is defined
        if (!numberOfLicences) {
            throw new Error('The number of licences is not defined');
        }

        // Check if the number of licences is valid
        if (numberOfLicences <= 0) {
            throw new Error('The number of licences is not valid');
        }

        // Order the licences
        const callable = this.functions.httpsCallable<
            {
                numberOfLicences: number;
            },
            {
                ordered: boolean;
                message?: string;
            }
        >('orderLicences');

        // Call the function
        let result: {
            ordered: boolean;
            message?: string;
        };

        try {
            result = await lastValueFrom(callable({ numberOfLicences }));
        } catch (error) {
            // Log the error
            if (OmedomEnvironment.currentEnvionment === 'development') {
                console.error('Impossible to call the "Order" function', error);
            }

            // Throw the error
            throw error;
        }

        // Check if the licences has been ordered
        if (!result) {
            throw new Error('Impossible to order the licences');
        }

        // Return the result
        return result;
    }

    /**
     * @description Cancel a licence
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/02/2024
     * @param {LicenceEntity} licence The licence to cancel
     * @returns {Promise<{ canceled: boolean; message?: string }>}
     * @memberof LicenceService
     */
    public async cancel(licence: LicenceEntity): Promise<{ canceled: boolean; message?: string }> {
        // Check if the licence is used
        if (licence.used) {
            throw new Error('The licence is already used');
        }

        // Share the licence
        const callable = this.functions.httpsCallable<
            {
                licenceUID: string;
            },
            {
                canceled: boolean;
                message?: string;
            }
        >('cancelLicence');

        // Call the function
        let result: {
            canceled: boolean;
            message?: string;
        };

        try {
            result = await lastValueFrom(callable({ licenceUID: licence.uid }));
        } catch (error) {
            // Log the error
            if (OmedomEnvironment.currentEnvionment === 'development') {
                console.error('Impossible to call the "Cancel Licence" function', error);
            }

            // Throw the error
            throw error;
        }

        // Check if the licence has been canceled
        if (!result) {
            throw new Error('Impossible to cancel the licence');
        }

        // Return the result
        return result;
    }
    public async licenceRefusedByClient(licence: LicenceEntity) {
        const callable = this.functions.httpsCallable('LicenceRefusedByClient');
        await callable({ licence: licence }).toPromise();
    }

    /**
     * @description Resend the licence to the email address of the licence owner
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/02/2024
     * @param {LicenceEntity} licence
     * @returns {Promise<{ resended: boolean; message?: string; }>}
     * @memberof LicenceService
     */
    public async resend(licence: LicenceEntity): Promise<{ resended: boolean; message?: string }> {
        // Check if the licence is used
        if (licence.used) {
            throw new Error('The licence is already used');
        }

        // Resend the licence
        const callable = this.functions.httpsCallable<
            {
                licenceUID: string;
            },
            {
                resended: boolean;
                message?: string;
            }
        >('resendLicence');

        // Call the function
        let result: {
            resended: boolean;
            message?: string;
        };

        try {
            result = await lastValueFrom(callable({ licenceUID: licence.uid }));
        } catch (error) {
            // Log the error
            if (OmedomEnvironment.currentEnvionment === 'development') {
                console.error('Impossible to call the "Resend Licence" function', error);
            }

            // Throw the error
            throw error;
        }

        // Check if the licence has been resended
        if (!result) {
            throw new Error('Impossible to resend the licence');
        }

        // Return the result
        return result;
    }
}

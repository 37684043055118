import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'omedom-toggle-with-icon',
    templateUrl: './toggle-with-icon.component.html',
    styleUrls: ['./toggle-with-icon.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleWithIconComponent),
            multi: true,
        },
    ],
})
export class ToggleWithIconComponent implements ControlValueAccessor {
    /**
     * @description Left switch content of the toggle with icon component
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {{ icon?: string; text?: string }}
     * @memberof ToggleWithIconComponent
     */
    @Input() switchContent1?: { icon?: string; text?: string };

    /**
     * @description Right switch content of the toggle with icon component
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {{ icon?: string; text?: string }}
     * @memberof ToggleWithIconComponent
     */
    @Input() switchContent2?: { icon?: string; text?: string };

    /**
     * @description Value of the toggle with icon component
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {boolean}
     * @memberof ToggleWithIconComponent
     */
    value?: boolean;

    /**
     * @description Callback function for the control value accessor interface
     * @author Brisset Killian
     * @date 26/04/2024
     * @private
     * @type {*}
     * @memberof ToggleWithIconComponent
     */
    private onChangeCallback: any;

    /**
     * @description Write a new value to the toggle with icon component
     * (required by the ControlValueAccessor interface)
     * @author Brisset Killian
     * @date 26/04/2024
     * @param {boolean} newValue
     * @memberof ToggleWithIconComponent
     */
    writeValue(newValue: boolean): void {
        this.value = newValue;
    }

    /**
     * @description Register a callback function to be called when the value of the toggle with icon component changes
     * (required by the ControlValueAccessor interface)
     * @author Brisset Killian
     * @date 26/04/2024
     * @param {*} fn
     * @memberof ToggleWithIconComponent
     */
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    /**
     * @description Register a callback function to be called when the toggle with icon component is touched
     * (required by the ControlValueAccessor interface)
     * @author Brisset Killian
     * @date 26/04/2024
     * @memberof ToggleWithIconComponent
     */
    registerOnTouched(): void {}

    /**
     * @description Toggle the value of the toggle with icon component
     * @author Brisset Killian
     * @date 26/04/2024
     * @memberof ToggleWithIconComponent
     */
    toggle(): void {
        const newValue = !this.value;
        this.writeValue(newValue);
        this.onChangeCallback(newValue);
    }
}

<div class="header">
    <div class="title">
        <i class="{{ icon }}"></i>
        <strong class="title-text layout-{{ layout }}">{{ title }}</strong>
    </div>
    <div
        class="amount layout-{{ layout }} {{
            overallBalance >= 0 ? 'color-positive' : 'color-negative'
        }}"
    >
        {{ overallBalance ? (overallBalance | currency : 'EUR') : '--,-- €' }}
    </div>
</div>
@if (banks?.length) {
<div class="content">
    @for (bank of banks; track $index) {
    <img
        class="logo"
        [alt]="'Logo de la banque ' + bank.name"
        [src]="bank.logoUrl"
    />
    }
</div>
}

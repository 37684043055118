<div class="omedom-modal">
    <div class="omedom-modal-header">
        <div class="omedom-modal-header-title">
            <i
                class="uil uil-envelope-check color-light-black background-color-light-orange"
            ></i>
        </div>
        <i class="uil uil-times-circle" (click)="close()"></i>
    </div>
    <omedom-info
        class="margin-top-2"
        information="Des champs sont manquants afin d'envoyer une {{
            isPayed ? 'quittance' : 'relance'
        }} à votre locataire.<br />
        <br />
        Veuillez compléter les informations ci-dessous.<br />
        Elles ne vous seront plus demandées les prochaines fois."
    ></omedom-info>
    <div class="margin-bottom-2">
        <form #form="ngForm">
            @for (tenant of tenants; track tenant.email; let i = $index) {
            <div>
                <hr class="omedom-divider w-100" />
                <h2>
                    <i class="uil uil-users-alt uil-icon"></i>
                    {{ tenant.firstname }} {{ tenant.lastname }}
                </h2>
                <div>
                    <omedom-input
                        name="{{ 'email-' + (i + 1) }}"
                        type="text"
                        label="Email"
                        type="email"
                        [pattern]="emailRegex"
                        autocomplete="off"
                        icon="uil-envelope"
                        placeholder="E-mail"
                        [required]="true"
                        [(ngModel)]="tenant.email"
                        [debounce]="300"
                    ></omedom-input>
                </div>
            </div>
            }
        </form>
    </div>
    <div class="omedom-sticky-button-container w-100">
        <button
            class="omedom-button-green inline-block flex-1"
            (click)="updateTenants()"
            [disabled]="loading.save || loading.send || form.invalid"
        >
            @switch (loading.send) { @case (false) {
            <span>
                <i class="uil uil-envelope-upload"></i>
                Envoyer
            </span>
            } @case (true) {
            <span>
                <ion-spinner
                    class="fixed-top omedom-upload-spinner"
                ></ion-spinner>
            </span>
            } }
        </button>
    </div>
</div>

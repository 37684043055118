/**
 * @description Data to display in a doughnut chart (label, value, color) to display in the chart or in a form
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 29/09/2023
 * @export
 * @interface ChartStackedBarData
 */
export interface ChartStackedBarData {
    label: string;
    datasetLabel: string;
    value: number;
    color: string;
    hatch?: boolean;
}

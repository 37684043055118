import { BankItemMFAType } from '../../enums';

/**
 * @description The multi factor authentication required for the item in Bridge
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 05/03/2024
 * @export
 * @interface BankItemMFA
 */
export interface BankItemMFA {
    /**
     * @description The type of multi factor authentication required for the item in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {BankItemMFAType}
     * @memberof BankItemMFA
     */
    type: BankItemMFAType;

    /**
     * @description Message to display to the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof BankItemMFA
     */
    description: string;

    /**
     * @description Message to display to the user by default if we don't have the description from the bank
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof BankItemMFA
     */
    label: string;

    /**
     * @description True if the OTP is numeric (0-9) only
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {boolean}
     * @memberof BankItemMFA
     */
    isNumeric: boolean;
}

/**
 * @description Interface for the saving exit constraint
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 09/07/2024
 * @export
 * @interface SavingExitConstraint
 */
export interface SavingExitConstraint {
    /**
     * @description Minimum date to exit the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {Date}
     * @memberof SavingExitConstraint
     */
    minimumDate?: Date;

    /**
     * @description Other constraint for the saving exit (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {string}
     * @memberof SavingExitConstraint
     */
    otherConstraint?: string;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientEntity, ProEntity, UserEntity } from '@omedom/data';
import { ClientService, ProService, UserService } from '@omedom/services';
import { Subscription, filter, switchMap } from 'rxjs';

@Component({
    selector: 'omedom-directory-trusted-pro-list-component',
    templateUrl: './directory-trusted-pro-list.component.html',
    styleUrls: ['./directory-trusted-pro-list.component.scss'],
})
export class DirectoryTrustedProListComponent implements OnInit, OnDestroy {
    pros: ProEntity[] = [];
    subscriptions: Subscription[] = [];
    constructor(
        private userService: UserService,
        private clientService: ClientService,
        private proService: ProService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        const user$ = this.userService.user$
            .pipe(
                filter((user) => !!user),
                switchMap((user) => {
                    return this.clientService._getUserClients(user.uid);
                }),
                switchMap((clients) => {
                    const proUIDsFiltered = this.getProUIDsFromClients(clients);
                    return this.proService._getProByUIDs(proUIDsFiltered);
                })
            )
            .subscribe((pros) => {
                this.pros = pros;
            });

        this.subscriptions.push(user$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    private getProUIDsFromClients(clients: ClientEntity[]) {
        const proUIDsFiltered = clients.reduce((clientUIDs: string[], client) => {
            // TODO: define what is mandatory, by witch value a pro is manatory
            if (!client.isMandated) {
                return clientUIDs;
            }
            clientUIDs.push(client.proUID);
            return clientUIDs;
        }, []);

        return proUIDsFiltered;
    }

    detailClicked(proUID: string) {
        if (!proUID) {
            return;
        }
        this.router.navigate([`pro-detail`], {
            relativeTo: this.route,
            queryParams: { proUID },
        });
    }
}

@switch (state$ | async) { @case ('pending') {
<ng-container *ngTemplateOutlet="pending"></ng-container>
} @default {
<ng-container *ngTemplateOutlet="content"></ng-container>
} }

<ng-template #content>
    @if (bank) {
    <div class="card-header" (click)="toggle()">
        <div class="bank-header">
            <img
                class="bank-logo"
                [src]="bank.logoUrl"
                [alt]="'Logo de la banque ' + bank.name"
                [title]="bank.name"
            />
            <h3 class="bank-name">{{ bank.name }}</h3>
        </div>
        <div class="bank-balance">
            <h3 class="bank-balance-title">
                @if (item.status !== statusCode.OK) {
                <i class="uil uil-exclamation-triangle color-yellow"></i>
                } @if (balance) {
                <span
                    >{{
                        showBalance ? (balance | omedomNumber) : '--,--'
                    }}
                    €</span
                >
                }
            </h3>
            @if (canSeeMore) {
            <i
                class="uil uil-angle-down bank-balance-icon"
                [class.bank-balance-rotate]="seeMore"
            ></i>
            }
        </div>
    </div>
    @if (seeMore) {
    <div class="card-content margin-top-1" [@list]="accounts.length">
        @if (errorMessage$ | async; as errorMessage) {
        <div class="card-error-message">
            <p class="omedom-no-margin">{{ errorMessage }}</p>
        </div>
        } @if (item.status === statusCode.OK) {
        <div class="card-actions margin-bottom-1">
            @if (showSynthesisButton) {
            <button
                class="omedom-button-secondary card-action-extend"
                id="synthesis"
                (click)="goToSynthesis()"
            >
                Mouvements bancaires
            </button>
            }
            <ng-container [ngTemplateOutlet]="delete"></ng-container>
        </div>
        } @else {
        <div class="card-actions margin-bottom-1">
            @if (actionEnabled$ | async) {
            <button
                class="omedom-button-secondary card-action-extend"
                [disabled]="(actionState$ | async) === 'pending'"
                (click)="action()"
            >
                {{ actionMessage$ | async }}
                @if ((actionState$ | async) === 'pending') {
                <ion-spinner></ion-spinner>
                }
            </button>
            }
            <ng-container [ngTemplateOutlet]="delete"></ng-container>
        </div>
        } @for (type of accountsByType; track type.type) { @if
        (type.accounts.length > 0) {
        <div class="account-type">
            <h4 class="account-type-title">{{ getTypeLabel(type.type) }}</h4>
            <span class="account-type-title"
                >{{ type.balance | omedomNumber }} €</span
            >
        </div>
        @for (account of type.accounts; track account.bridgeID) {
        <div class="account-line margin-bottom-1">
            <span class="account-name">{{ account.name }}</span>
            <span>{{ account.balance | omedomNumber }} €</span>
        </div>
        } } }
    </div>
    } } @else {
    <i class="uil uil-exclamation-triangle"></i>
    <span>Impossible de récupérer les données bancaires</span>
    <div class="card-actions margin-bottom-1">
        <ng-container [ngTemplateOutlet]="delete"></ng-container>
    </div>
    }
</ng-template>

<ng-template #pending>
    <div class="pending-container">
        <span>Chargement des données bancaires...</span>
        <ion-spinner></ion-spinner>
    </div>
</ng-template>

<ng-template #delete>
    @if (showDeleteButton) {
    <button class="omedom-button-warn-secondary" (click)="deleteItem()">
        <i class="uil uil-trash-alt"></i>
    </button>
    }
</ng-template>

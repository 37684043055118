<div class="header">
    <div class="title">
        @if (icon) {
        <i class="{{ icon }} "></i>
        }
        <strong class="title-text">{{ title }}</strong>
    </div>
</div>

<div class="content">
    @if(legends && legends.length > 0){
    <div class="legend-container">
        @for (legend of legends; track $index) {
        <div class="legend">
            <span class="label" [style.color]="legend.label.color">
                {{ legend.label.value }}
            </span>
            <span class="value" [style.color]="legend.input.color">
                <strong>{{ legend.input.value }}</strong>
            </span>
        </div>
        }
    </div>
    }

    <div class="progress-bar">
        @for (progress of progressValues; track $index) {
        <div
            class="progress-value"
            [style.width]="progress.percentage + '%'"
            [style.background]="progress.backgroundColor"
            [style.color]="progress.textColor"
        >
            @if (progress.percentage >= 15){
            <span class="progress-text">{{ progress.stringifiedValue }}</span>
            }
        </div>
        @if (progress.percentage < 15){
        <span class="progress-text">{{ progress.stringifiedValue }}</span>
        } }
    </div>
</div>

import { Edge } from '@kr0san89/ngx-graph';

import { AutomationNode } from './automation-node.interface';

/**
 * @description The sequence of an automation (the nodes and links of the graph) as an object with a nodes and links property
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 28/11/2023
 * @export
 * @interface AutomationSequence
 */
export interface AutomationSequence {
    nodes: AutomationNode[];
    links: Edge[];
}

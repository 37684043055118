export class ClientTabTree {
    /**
     * @description Label of the tab tree displayed in the UI
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/08/2024
     * @type {string}
     * @memberof ClientTabTree
     */
    label!: string;

    /**
     * @description The name of the tab tree
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/08/2024
     * @type {string}
     * @memberof ClientTabTree
     */
    name!: string;

    /**
     * @description The level of the tab tree in the tree structure. The root is at level 0, its children are at level 1, etc...
     * The level is used to display the tab tree in the UI with the correct indentation.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/08/2024
     * @type {number}
     * @memberof ClientTabTree
     */
    level!: number;

    /**
     * @description The value in database of client.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/08/2024
     * @type {number}
     * @memberof ClientTabTree
     */
    clientValue?: number;

    /**
     * @description The note entered by the pro user. Default is an empty string. The note is displayed in the UI when the user clicks on the info icon.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 27/08/2024
     * @type {string}
     * @memberof ClientTabTree
     */
    note?: string;

    /**
     * @description The value entered by the pro user.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/08/2024
     * @type {(number | null)}
     * @memberof ClientTabTree
     */
    value?: number | null;

    /**
     * @description If the value can be changed by the pro user. Default is false.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/08/2024
     * @type {boolean}
     * @memberof ClientTabTree
     */
    canChangeValue?: boolean;

    /**
     * @description If the childrens of the tab tree can be collapsed. Default is false.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/08/2024
     * @type {boolean}
     * @memberof ClientTabTree
     */
    canCollapseChildrens?: boolean;

    /**
     * @description If the label can be changed by the pro user. Default is false.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 05/09/2024
     * @type {boolean}
     * @memberof ClientTabTree
     */
    canChangeLabel?: boolean;

    /**
     * @description If the tab tree can have childrens. Default is false.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 05/09/2024
     * @type {boolean}
     * @memberof ClientTabTree
     */
    canAddChildrens?: boolean;

    /**
     * @description If the childrens of the tab tree are collapsed. Default is false.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/08/2024
     * @type {boolean}
     * @memberof ClientTabTree
     */
    collapsedChildrens?: boolean;

    /**
     * @description The childrens of the tab tree. Default is an empty array.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/08/2024
     * @type {ClientTabTree[]}
     * @memberof ClientTabTree
     */
    childrens?: ClientTabTree[];

    /**
     * @description The text displayed in the popover when the user clicks on the info icon.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/08/2024
     * @type {string | string[] | Iterable<string>}
     * @memberof ClientTabTree
     */
    popoverContent?: string | string[] | Iterable<string> = '';

    constructor(init?: Partial<ClientTabTree>) {
        // create a copy of the object
        Object.assign(this, init);
        this.childrens = this.childrens?.map((child) => new ClientTabTree(child));
    }

    /**
     * @description Get the client value of the tab tree.
     * If the tab tree has childrens, the client value is the sum of the client values of the childrens.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/08/2024
     * @returns {*}  {(number | null)}
     * @memberof ClientTabTree
     */
    public getClientValue(): number | null {
        if (this.childrens && this.childrens.length > 0) {
            return this.childrens.reduce((acc: number | null, curr) => {
                if (curr.childrens) {
                    const value = curr.getClientValue();
                    if (value || value === 0) {
                        return (acc ?? 0) + value;
                    } else {
                        return acc;
                    }
                } else if (curr.clientValue || curr.clientValue === 0) {
                    return (acc ?? 0) + curr.clientValue;
                } else {
                    return acc;
                }
            }, null);
        } else if (this.clientValue || this.clientValue === 0) {
            return this.clientValue;
        }
        return null;
    }

    /**
     * @description Get the value of the tab tree.
     * If the tab tree has childrens, the value is the sum of the values of the childrens.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/08/2024
     * @memberof ClientTabTree
     */
    public getValue(): number | null {
        if (this.childrens && this.childrens.length > 0) {
            return this.childrens.reduce((acc: number | null, curr) => {
                if (curr.childrens && !curr.canChangeValue) {
                    const value = curr.getValue();
                    if (value || value === 0) {
                        return (acc ?? 0) + value;
                    } else {
                        return acc;
                    }
                } else if (curr.value || curr.value === 0) {
                    return (acc ?? 0) + curr.value;
                } else {
                    return acc;
                }
            }, null);
        } else if (this.value || this.value === 0) {
            return this.value;
        }
        return null;
    }

    /**
     * @description Get the full value of the tab tree.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 27/08/2024
     * @returns {*}  {(number | null)}
     * @memberof ClientTabTree
     */
    public getFullValue(): number | null {
        if (
            this.childrens &&
            this.childrens.length > 0 &&
            !(this.canChangeValue && (this.value || this.value === 0))
        ) {
            return this.childrens.reduce((acc: number | null, curr) => {
                if (curr.childrens && !(curr.canChangeValue && (curr.value || curr.value === 0))) {
                    const value = curr.getFullValue();
                    if (value || value === 0) {
                        return (acc ?? 0) + value;
                    } else {
                        return acc;
                    }
                } else if (
                    curr.value ||
                    curr.value === 0 ||
                    curr.clientValue ||
                    curr.clientValue === 0
                ) {
                    const value = curr.value || curr.clientValue || 0;
                    return (acc ?? 0) + value;
                } else {
                    return acc;
                }
            }, null);
        } else if (this.value || this.value === 0 || this.clientValue || this.clientValue === 0) {
            return this.value || this.clientValue || 0;
        }
        return null;
    }

    /**
     * @description Get the value of the tab tree.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 27/08/2024
     * @returns {*}  {*}
     * @memberof ClientTabTree
     */
    public transformTreeToObj(): any {
        const obj: any = {};
        if (this.childrens && this.childrens.length > 0 && !this.canChangeValue) {
            if (this.canAddChildrens) {
                this.childrens
                    .filter((child) => child.canChangeLabel)
                    .forEach((child) => {
                        obj[child.name] = child.transformTreeToObj();
                    });
            } else {
                this.childrens.forEach((child) => {
                    obj[child.name] = child.transformTreeToObj();
                });
            }
        } else {
            if (this.value !== undefined) obj.value = this.value;
            if (this.canChangeLabel && this.label !== undefined) obj.label = this.label;
        }
        if (this.note !== undefined) obj.note = this.note;
        return obj;
    }

    /**
     * @description Transform the object to a tree structure.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 27/08/2024
     * @param {*} obj
     * @returns {*}
     * @memberof ClientTabTree
     */
    public transformObjToTree(obj: any): void {
        if (!obj[this.name]) {
            return;
        }

        const objectChildrens = Object.keys(obj[this.name]).filter((key) =>
            key.includes(this.name + 'Children')
        );

        // if can change value && object at children
        if (this.canChangeValue && objectChildrens.length > 0) {
            this.childrens = objectChildrens
                .sort((a, b) => {
                    const indexA = Number(a.split(this.name + 'Children')[1]);
                    const indexB = Number(b.split(this.name + 'Children')[1]);
                    return indexA - indexB;
                })
                .map((key) => {
                    const child = new ClientTabTree({
                        level: this.level + 1,
                        name: key,
                        label: obj[this.name][key].label || null,
                        canChangeLabel: true,
                        canAddChildrens: false,
                        canChangeValue: true,
                        value: obj[this.name][key].value || null,
                        note: obj[this.name][key].note || null,
                    });

                    return child;
                });
            this.canChangeValue = false;
            this.canCollapseChildrens = true;
            this.collapsedChildrens = true;
        } else if (this.childrens && this.childrens.length > 0 && !this.canChangeValue) {
            this.childrens.forEach((child) => {
                child.transformObjToTree(obj[this.name]);
            });
        } else {
            this.value = obj[this.name].value;
        }
        this.note = obj[this.name].note;
    }

    /**
     * @description Get the height of the tab tree.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 27/08/2024
     * @returns {*}  {number}
     * @memberof ClientTabTree
     */
    public getHeigh(): number {
        if (this.childrens && this.childrens.length > 0) {
            return (
                this.childrens.reduce((acc: number, curr) => {
                    return Math.max(acc, curr.getHeigh());
                }, 0) + 1
            );
        } else {
            return 1;
        }
    }

    /**
     * @description Add a new children to the tab tree.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 06/09/2024
     * @memberof ClientTabTree
     */
    public addNewChildrenToTree() {
        this.canCollapseChildrens = true;
        this.collapsedChildrens = false;
        this.canChangeValue = false;

        const previousAddChildren =
            this.childrens?.filter((x) => x.name.includes(this.name + 'Children')) || [];
        const indexAddedChildrens = previousAddChildren.map((x) => {
            const index = x.name.split(this.name + 'Children')[1];
            if (isNaN(Number(index))) return 0;
            return Number(index);
        });

        const index = (indexAddedChildrens.length > 0 ? Math.max(...indexAddedChildrens) : 0) + 1;
        const newChildren = new ClientTabTree({
            level: this.level + 1,
            name: this.name + 'Children' + index,
            canChangeLabel: true,
            canAddChildrens: false,
            canChangeValue: true,
            value: null,
        });

        delete this.value;

        if (!this.childrens) this.childrens = [newChildren];
        else this.childrens.push(newChildren);
    }
}

import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
    IncomeCategoryProperty,
    IncomeDeleteType,
    IncomeEntity,
    IncomePeriodicity,
} from '@omedom/data';
import { IncomeService } from '@omedom/services';

import { TreasuryDelete } from '../../class/treasury-delete';
import { OmedomRadioOption } from '../radio/omedom-radio-option';

@Component({
    selector: 'omedom-income-delete',
    templateUrl: './income-delete.component.html',
    styleUrls: ['./income-delete.component.scss'],
})
export class IncomeDeleteComponent extends TreasuryDelete<
    IncomeEntity,
    IncomeCategoryProperty,
    IncomePeriodicity
> {
    deleteOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({
            id: IncomeDeleteType.onlyThisOne,
            label: 'Ce revenu',
        }),
        new OmedomRadioOption({
            id: IncomeDeleteType.thisOneAndNext,
            label: 'Ce revenu et les suivants',
        }),
        new OmedomRadioOption({
            id: IncomeDeleteType.all,
            label: 'Tous les revenus de la série',
        }),
    ];

    selectedDeleteOption: IncomeDeleteType = null as any;

    constructor(modalController: ModalController, private incomeService: IncomeService) {
        super(modalController);
    }

    getTreasury(treasuryUid: string): Promise<IncomeEntity | undefined> {
        return this.incomeService.get(treasuryUid);
    }

    updateTreasury(treasury: IncomeEntity): Promise<void> {
        return this.incomeService.update(treasury);
    }

    deleteTreasury(treasuryUid: string): Promise<void> {
        return this.incomeService.delete(treasuryUid);
    }
}

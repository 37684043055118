@for (tab of tabs; track tab.id) {
<div
    (click)="tabClicked(tab)"
    class="omedom-tab"
    [class]="'omedom-tab-' + tab.type"
    [class.selected]="selectedTab?.id === tab.id"
>
    <i [class]="tab.icon" class="omedom-tab-icon"></i>
    @if (selectedTab?.id === tab.id) {
    <h3 class="omedom-tab-label">
        {{ tab.label }}
    </h3>
    }
</div>
}

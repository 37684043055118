/**
 * @description Action that a user can do on a notification
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 22/01/2024
 * @export
 * @enum {number}
 */
export enum NotificationActions {
    OPEN = 'open',
    OPEN_EXTERN_URL = 'open_extern_url',
    MARK_CHARGE_PAID = 'mark_charge_paid',
    OPEN_DOCUMENT = 'open_document',
    MARK_INCOME_RECEIVED = 'mark_income_received',
    SEND_REMINDER = 'send_reminder',
    SEND_RECEIPT = 'send_receipt',
    OPEN_INCOME = 'open_income',
    OPEN_PROPERTY = 'open_property',
    RENEW_SUBSCRIPTION = 'renew_subscription',
}

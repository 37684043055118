import {
    Story,
    UserEntity,
    PropertyEntity,
    IncomeEntity,
} from "@omedom/data";

export interface RentCardProps {
    story: Story;
    user: UserEntity;
    property: PropertyEntity;
    income: IncomeEntity;
}

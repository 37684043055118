/**
 * @description List of relevant bank categories
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 19/03/2024
 * @export
 * @type {number[]}
 */
export const BANK_CATEGORIES_RELEVANT = [
    2, // Entrées d'argent
    3, // Autres entrées
    80, // Intérêts financiers
    231, // Ventes de biens
    232, // Ventes de services
    271, // Dépôt d'argent
    282, // Virements internes
    283, // Remboursement pro
    289, // Economies
    314, // Loyers reçus
    327, // Allocations et pensions
    441893, // Subventions
    441894, // Prêt
    159, // Impots & taxes
    206, // Impots & taxes - autres
    208, // IS
    209, // CFE
    302, // Taxes
    441988, // TVA
    276, // Autres dépenses
    278, // Assurance
    161, // Logement
    216, // Loyer / Locaux
    217, // Electricité
    218, // Gaz
    220, // Bureaux - autres
    221, // Décoration
    222, // Entretien
    246, // Assurance habitation
    293, // Eau
    323, // Exterieur et jardin
    328, // Charge diverses
    162, // Achats & shopping
    184, // Matériel
    186, // Achat & shopping - autres
    441888, // Licences
    164, // Banque
    89, // Remboursement emprunt
    194, // Hypothèque
    195, // Banque - autres
    166, // Dépenses pro
    90, // Frais généraux - autres
    203, // Maintenance bureux
    270, // Services en ligne
    441886, // Comptabilité
    441891, // Rémunérations dirigeants
    441895, // Conseils
    441899, // Frais juridique
    241, // Logement étudiant
    171, // Abonnements
    180, // Internet
    219, // Câble / Satellite
    258, // Téléphone fixe
    280, // Abonnements - autres
    303, // Retraits, Chèques et Virements
    78, // Virements
    85, // Retraits
    88, // Chèques
    326, // Virements internes
];

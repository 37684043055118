import { AutomationSchedule, AutomationStatus } from '../enums';
import { AutomationSequence } from '../interfaces';
import { RestEntity } from './rest.entity';

export class AutomationEntity extends RestEntity {
    /**
     * @description The name of the automation
     * @author Jérémie Lopez
     * @type {string}
     * @memberof AutomationEntity
     */
    name!: string;

    /**
     * @description The description of the automation
     * @author Jérémie Lopez
     * @type {string}
     * @memberof AutomationEntity
     */
    description!: string;

    /**
     * @description The categories of the automation (used for filtering)
     * @author Jérémie Lopez
     * @type {string[]}
     * @memberof AutomationEntity
     */
    categories!: string[];

    /**
     * @description The sequence of the automation
     * @author Jérémie Lopez
     * @type {AutomationSequence}
     * @memberof AutomationEntity
     */
    sequence!: AutomationSequence;

    /**
     * @description The schedule of the automation (when it should be executed) as a date string
     * @author Jérémie Lopez
     * @type {string}
     * @memberof AutomationEntity
     */
    schedule!: AutomationSchedule;

    /**
     * @description Defines if the automation is enabled or not (if it should be executed or not) as a boolean value
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof AutomationEntity
     */
    enabled!: boolean;

    /**
     * @description The last time the automation was executed as a Date or null if it was never executed before
     * @author Jérémie Lopez
     * @type {Date}
     * @memberof AutomationEntity
     */
    lastRun?: Date;

    /**
     * @description Next date the automation should be executed as a Date or null if it should not be executed anymore
     * @author Jérémie Lopez
     * @type {(Date | null)}
     * @memberof AutomationEntity
     */
    nextRun?: Date;

    /**
     * @description The status of the automation (if it is currently running or not) as an AutomationStatus enum value
     * @author Jérémie Lopez
     * @type {AutomationStatus}
     * @memberof AutomationEntity
     */
    status?: AutomationStatus;

    /**
     * @description The date (1-31) of run automation for monthly schedule
     * @author Brisset Killian
     * @date 08/04/2024
     * @type {number}
     * @memberof AutomationEntity
     */
    runDate?: number;

    /**
     * @description The last status of the automation (if it is currently running or not) as an AutomationStatus enum value or null if it was never executed before or if it is currently running (status = running) or if it is disabled (enabled = false) or if it is paused (status = paused) or if it is unknown (status = unknown) or if it is idle (status = idle) or if it is successful (status = success)
     * @author Jérémie Lopez
     * @type {AutomationStatus}
     * @memberof AutomationEntity
     */
    lastRunStatus?: AutomationStatus;

    /**
     * @description The last message of the automation (if it is currently running or not) as a string or null if it was never executed before or if it is currently running (status = running) or if it is disabled (enabled = false) or if it is paused (status = paused) or if it is unknown (status = unknown) or if it is idle (status = idle) or if it is successful (status = success) or if it is in error (status = error)
     * @author Jérémie Lopez
     * @type {string}
     * @memberof AutomationEntity
     */
    lastRunMessage?: string;

    /**
     * @description The last duration of the automation as a number or null if it was never executed before
     * @author Jérémie Lopez
     * @type {number}
     * @memberof AutomationEntity
     */
    lastRunDuration?: number;
}

import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'omedom-action',
    templateUrl: './action.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ActionComponent {
    /**
     * @description text of the action button
     * @author Brisset Killian
     * @date 25/04/2024
     * @type {string}
     * @memberof ActionComponent
     */
    @Input() text?: string;

    /**
     * @description icon of the action button
     * @author Brisset Killian
     * @date 25/04/2024
     * @type {string}
     * @memberof ActionComponent
     */
    @Input() actionIcon?: string;

    /**
     * @description simple icon of the action button (no text) - default false - if true, the text is not displayed and the icon is centered in the button
     * @author Brisset Killian
     * @date 25/04/2024
     * @type {boolean}
     * @memberof ActionComponent
     */
    @Input() simpleIcon: boolean = false;

    /**
     * @description action clicked event - emit when the action button is clicked - return the event to the parent component
     * @author Brisset Killian
     * @date 25/04/2024
     * @memberof ActionComponent
     */
    @Output() actionClicked = new EventEmitter();
}

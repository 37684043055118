import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';

@Component({
    selector: 'omedom-toggle',
    templateUrl: './toggle.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ToggleComponent),
            multi: true,
        },
    ],
})
export class ToggleComponent implements ControlValueAccessor, Validator {
    /**
     * @description Label of the toggle component
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {string}
     * @memberof ToggleComponent
     */
    @Input() label?: string;

    /**
     * @description Value of the toggle component
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {boolean}
     * @memberof ToggleComponent
     */
    value?: boolean;

    /**
     * @description onChange callback function of the toggle component
     * @author Brisset Killian
     * @date 26/04/2024
     * @private
     * @type {*}
     * @memberof ToggleComponent
     */
    private onChangeCallback: any;

    /**
     * @description Validate the toggle component (required by the Validator interface)
     * @author Brisset Killian
     * @date 26/04/2024
     * @returns {*}
     * @memberof ToggleComponent
     */
    validate(): null {
        return null;
    }

    /**
     * @description Register the onChange callback function of the toggle component
     * (required by the ControlValueAccessor interface)
     * @author Brisset Killian
     * @date 26/04/2024
     * @param {*} fn
     * @memberof ToggleComponent
     */
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    /**
     * @description  Register the onTouched callback function of the toggle component
     * (required by the ControlValueAccessor interface)
     * @author Brisset Killian
     * @date 26/04/2024
     * @memberof ToggleComponent
     */
    registerOnTouched(): void {}

    /**
     * @description Write the value of the toggle component
     * (required by the ControlValueAccessor interface)
     * @author Brisset Killian
     * @date 26/04/2024
     * @param {boolean} newValue
     * @memberof ToggleComponent
     */
    writeValue(newValue: boolean): void {
        if (newValue !== this.value) {
            this.value = newValue;
        }
    }

    /**
     * @description Change the value of the toggle component and call the onChange callback function
     * (required by the ControlValueAccessor interface)
     * @author Brisset Killian
     * @date 26/04/2024
     * @param {*} value
     * @memberof ToggleComponent
     */
    valueChange(value: any) {
        this.writeValue(value);
        this.onChangeCallback(value);
    }
}

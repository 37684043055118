import {
    DateAndLocation,
    DateInterval,
    IncomeEntity,
    IncomePeriodicity,
    LeaseEntity,
    PostalAddress,
    PropertyEntity,
    SocietyEntity,
    Story,
    TenantEntity,
    UserEntity,
} from '@omedom/data';

/**
 * @description Rent receipt and relaunch props to be used to generate a receipt or relaunch document for a rent
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 26/03/2024
 * @export
 * @interface RentReceiptAndRelaunchProps
 */
export interface RentReceiptAndRelaunchProps {
    user: UserEntity;
    income: IncomeEntity;
    story: Story;
    lease: LeaseEntity;
    property: PropertyEntity;
    tenants: TenantEntity[];
    society?: SocietyEntity;
}

/**
 * @description Data to verify before generating the receipt or relaunch PDF
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 12/04/2024
 * @export
 * @interface RentReceiptAndRelaunchDataToVerify
 */
export interface RentReceiptAndRelaunchDataToVerify {
    user: UserEntity,
    property: PropertyEntity,
    tenants: TenantEntity[],
    society?: SocietyEntity,
}
/**
 * @description Rent data to be used to generate a receipt or relaunch document
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 07/02/2024
 * @export
 * @interface RentReceiptAndRelaunchData
*/
export interface RentReceiptAndRelaunchData {
    documentDate: string; // e.g. Février 2024 (for monthly) or 7 février 2024 (for punctual) or Année 2024 (for yearly)
    lessorInformations: LessorInformations;
    societyInformations?: SocietyInformations;
    tenantsInformations: TenantInformations[];
    propertyAddress: PostalAddress;
    dateAndLocation: DateAndLocation;
    paiementDateAndReccurency: PaiementDateAndReccurency;
    rentAmounts: RentAmounts;
    documentPeriod: DateInterval;
}

/**
 * @description Amounts of the rent and charges to be paid by the tenant for a given period of time
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 07/02/2024
 * @export
 * @interface RentAmounts
 */
export interface RentAmounts {
    rentAmount: number;
    chargesAmount: number;
    totalAmount: number;
}

/**
 * @description Date and reccurency of the rent paiement by the tenant for a given period of time
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 07/02/2024
 * @export
 * @interface PaiementDateAndReccurency
 * @example
 * - paiementDate: new Date('2024-02-07')
 * - reccurency: Reccurency.monthly
 */
export interface PaiementDateAndReccurency {
    paiementDate: Date;
    reccurency: Reccurency;
}

/**
 * @description Reccurency of the rent paiement
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 07/02/2024
 * @export
 * @enum {number}
 * @example
 * - punctual usage: 'le 7 février 2024'
 * - monthly usage: 'le 7 de chaque mois'
 * - bimonthly usage: 'le 7 chaque deux mois'
 * - quarterly usage: 'le 7 chaque trimestre'
 * - halfYearly usage: 'le 7 chaque semestre'
 * - yearly usage: 'le 7 février de chaque année'
 */
export enum Reccurency {
    punctual = '',
    monthly = 'de chaque mois',
    bimonthly = 'chaque deux mois',
    quarterly = 'chaque trimestre',
    'half-yearly' = 'chaque semestre',
    yearly = 'de chaque année'
}


/**
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 04/03/2024
 * @export
 * @interface SocietyInformations
 */
export interface SocietyInformations {
    name: string;
    postalAddress: PostalAddress;
    societyManagerInformations?: SocietyManagerInformations;
}

/**
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 04/03/2024
 * @export
 * @interface SocietyManagerInformations
 */
export interface SocietyManagerInformations extends PersonInformations {
}

/**
 *
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 07/02/2024
 * @export
 * @interface LessorInformations
 * @extends {PersonInformations}
 */
export interface LessorInformations {
    uid?: string;
    name: string;
    postalAddress: PostalAddress;
    email?: string;
}

/**
 *
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 07/02/2024
 * @export
 * @interface TenantInformations
 * @extends {PersonInformations}
 */
export interface TenantInformations extends PersonInformations { }

/**
 * @description Person informations to be used in rent receipt and relaunch data to generate the suitable documents
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 07/02/2024
 * @interface PersonInformations
*/
interface PersonInformations {
    uid?: string;
    firstname: string;
    lastname: string;
    email?: string;
}

export interface TenantPDF {
    uid: string;
    lastname: string;
    firstname: string;
    email: string;
}

interface LessorAndSocietyPDF {
    uid: string;
    email: string;
    name: string;
    street: string;
    city: string;
    postalCode: string;
}
export interface LessorPDF extends LessorAndSocietyPDF {
    firstname: string;
}

export interface SocietyPDF extends LessorAndSocietyPDF {
}

export interface PropertyPDF {
    uid: string;
    street: string;
    city: string;
    postalCode: string;
}

export interface DatePDF {
    startDateIncome: Date;
    startDateStory: Date;
    startDateLease: Date;
    debitDateIncome: Date;
    today?: string;
};

export interface LeasePDF {
    uid: string;
    storyUid: string;
    incomeUid: string;
    amount: number;
    withCharges: number;
    periodicityInfo: IncomePeriodicity;
}

export interface ReceiptAndRelaunchEmailProps {
    tenants: TenantEntity[],
    documentName: string,
    documentURL: string,
    user: UserEntity,
    story: Story,
    society?: SocietyEntity,
}

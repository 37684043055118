import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { LicenceEntity } from '@omedom/data';
import { LicenceService } from '@omedom/services';

interface LicenceExchangeForm {
    oldEmail: FormControl<string>;
    newEmail: FormControl<string>;
}

@Component({
    selector: 'omedom-licence-exchange',
    templateUrl: './licence-exchange.component.html',
    styleUrls: ['./licence-exchange.component.scss'],
})
export class LicenceExchangeComponent implements OnInit {
    /**
     * @description Licence to exchange
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/02/2024
     * @type {LicenceEntity}
     * @memberof LicenceExchangeComponent
     */
    @Input({ required: true })
    public licence!: LicenceEntity;

    /**
     * @description Exchange form (old email, new email)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/02/2024
     * @type {FormGroup<LicenceSendForm>}
     * @memberof LicenceExchangeComponent
     */
    public exchangeForm: FormGroup<LicenceExchangeForm> = new FormGroup({
        oldEmail: new FormControl<string>({
            disabled: true,
            value: '',
        }, {
            nonNullable: true,
            validators: [Validators.required, Validators.email]
        }),
        newEmail: new FormControl<string>('', {
            nonNullable: true,
            validators: [Validators.required, Validators.email]
        }),
    });

    /**
     * @description Pending state (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/02/2024
     * @type {boolean}
     * @memberof LicenceExchangeComponent
     */
    public pending: boolean = false;

    /**
     * @description Error message (default: '')
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/02/2024
     * @type {string}
     * @memberof LicenceExchangeComponent
     */
    public errorMessage: string = '';

    constructor(
        private readonly modalController: ModalController,
        private readonly toastController: ToastController,
        private readonly licenceService: LicenceService,
    ) { }

    ngOnInit(): void {
        // Set the old email
        this.exchangeForm.controls.oldEmail.setValue(this.licence.userEmail);
    }

    /**
     * @description Send the licence to a new email address
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/02/2024
     * @returns {Promise<void>}
     * @memberof LicenceExchangeComponent
     */
    public async exchange(): Promise<void> {
        // Set the pending state to true
        this.pending = true;

        // Check if the form is valid
        if (!this.exchangeForm.valid) {
            this.pending = false;

            return;
        }

        // Get the old email and the new email
        const { oldEmail, newEmail } = this.exchangeForm.getRawValue();

        // Check if the old email is defined and if the new email is defined
        if (!oldEmail || !newEmail) {
            this.errorMessage = 'L\'email n\'est pas défini.';
            this.pending = false;

            return;
        }

        let exchangeLicence: {
            sended: boolean;
            message?: string;
        };

        // Exchange the licence
        try {
            exchangeLicence = await this.licenceService.exchange(this.licence, oldEmail, newEmail);
        } catch (error: any) {
            this.errorMessage = error.message;

            this.pending = false;

            return;
        }

        // Check if the licence has been sended
        if (!exchangeLicence.sended) {
            this.errorMessage = exchangeLicence.message || 'Une erreur est survenue lors de l\'activation de la licence.';

            this.pending = false;

            return;
        }

        // Close the modal
        this.modalController.dismiss();

        // Set the pending state to false
        this.pending = false;

        // Reset the form
        this.exchangeForm.reset();

        // Display a success message
        const toast = await this.toastController.create({
            message: 'La licence a bien été échangée.',
            duration: 3000,
            color: 'success',
            position: 'top'
        });

        toast.present();
    }
}

/**
 * @description Status of an automation (idle, running, error, paused, disabled, unknown, success)
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 28/11/2023
 * @export
 * @enum {number}
 */
export enum AutomationStatus {
    idle = 'IDLE',
    running = 'RUNNING',
    error = 'ERROR',
    paused = 'PAUSED',
    disabled = 'DISABLED',
    unknown = 'UNKNOWN',
    success = 'SUCCESS',
}

<form [formGroup]="form"
      [@list]="keys">
    <omedom-input [boldLabel]="true"
                  formControlName="name"
                  label="Nom"
                  placeholder="Mon épargne"
                  [required]="true"
                  type="text"></omedom-input>

    <omedom-input [boldLabel]="true"
                  formControlName="etablissement"
                  label="Etablissement"
                  placeholder="Generali"
                  type="text"></omedom-input>

    <omedom-select [boldLabel]="true"
                   formControlName="type"
                   label="Type de placement"
                   [placeholder]="typePlaceholder"
                   [options]="typeOptions"></omedom-select>

    <omedom-input [boldLabel]="true"
                  formControlName="openingDate"
                  label="Date de souscription"
                  placeholder="01/01/2021"
                  type="date"></omedom-input>

    <hr class="omedom-divider omedom-no-margin">

    <omedom-input [boldLabel]="true"
                  formControlName="balance"
                  label="Solde"
                  placeholder="10 000"
                  iconEnd="uil uil-euro"
                  [required]="true"
                  type="number"></omedom-input>

    <omedom-input [boldLabel]="true"
                  formControlName="interestRate"
                  label="Taux d'intérêt"
                  placeholder="1.5"
                  iconEnd="uil uil-percentage"
                  type="number"></omedom-input>

    <omedom-input [boldLabel]="true"
                  formControlName="interestAmount"
                  label="Montant des intérêts"
                  placeholder="150"
                  iconEnd="uil uil-euro"
                  type="number"></omedom-input>

    <omedom-input [boldLabel]="true"
                  formControlName="limit"
                  label="Plafond"
                  placeholder="12 000"
                  iconEnd="uil uil-euro"
                  type="number"></omedom-input>
</form>

<i class="uil uil-angle-left clickable"
(click)="previousClicked()"></i>
<div class="omedom-date-info">
  <div id="datetrigger"
    (click)="triggerDatePicker()">
    <i class="uil uil-calendar-alt omedom-date-info-icon"></i>

    <span class="omedom-date-info-date">{{ displayedDate }}</span>
  </div>
</div>
@if (date.getUTCLastDayOfMonth().getTime() < maxDate.getTime()) {
  <i
    class="uil uil-angle-right clickable"
  (click)="nextClicked()"></i>
}

import { BehaviorSubject, Subject } from 'rxjs';

export class RefreshService {
    /**
     * @description Force refresh of the current page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @memberof RefreshService
     */
    public forceRefresh$ = new Subject<void>();

    /**
     * @description Retrieve event when the view is entered (ionViewDidEnter) to refresh the page if needed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @memberof RefreshService
     */
    public viewDidEnter$ = new BehaviorSubject<void>(undefined);
}

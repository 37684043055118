import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { getMessaging, getToken, onMessage } from '@angular/fire/messaging';
import {
    ActionPerformed,
    PushNotifications,
    PushNotificationSchema,
    Token,
} from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { NotificationEntity, NotificationReceived, UserEntity } from '@omedom/data';
import { OmedomEnvironment } from '@omedom/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { RestService, UserService } from '../core';

@Injectable({
    providedIn: 'root',
})
export class NotificationService extends RestService<NotificationEntity> {
    /**
     * @description User entity
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @private
     * @type {UserEntity}
     * @memberof NotificationService
     */
    private user?: UserEntity;

    /**
     * @description Notifications to display in the app when the user receive a push notification
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/01/2024
     * @private
     * @type {NotificationReceived[]}
     * @memberof NotificationService
     */
    private notificationsToDisplay$ = new BehaviorSubject<NotificationReceived[]>([]);

    public notificationsList$: Observable<NotificationEntity[]> = of([]);

    constructor(
        protected override firestore: AngularFirestore,
        private userService: UserService,
        private platform: Platform
    ) {
        super(firestore, 'notifications');
        this.userService.user$.subscribe((user) => {
            this.user = user;
            if (user && user.uid) {
                this.notificationsList$ = this._search([
                    {
                        where: 'userUID',
                        operator: '==',
                        value: user.uid,
                    },
                    {
                        sortBy: 'created',
                        direction: 'desc',
                    },
                ]);
            }
        });
    }

    /**
     * @description Initialize push notifications
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @returns {Promise<void>}
     * @memberof NotificationService
     * @example
     * await this.notificationService.initPush();
     */
    public async initPush(): Promise<void> {
        if (this.platform.is('capacitor')) {
            this.initRegistrationListener();
            this.initPushNotificationListener();
            this.initPushNotificationActionPerformedListener();
            this.requestPermission();
        } else {
            await this.initWebPush();
        }
    }

    /**
     * @description Initialize push notifications registration listener
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @private
     * @memberof NotificationService
     * @example
     * this.initRegistrationListener();
     */
    private initRegistrationListener(): void {
        PushNotifications.addListener('registration', async (token: Token) => {
            // Check if the token is not null
            if (!token.value) {
                return;
            }

            // Get the device type
            const device = this.platform.is('capacitor')
                ? this.platform.is('ios')
                    ? 'ios'
                    : 'android'
                : 'web';

            // Add the device token to the user
            await this.addDeviceTokenToUser(device, token.value);
        });

        PushNotifications.addListener('registrationError', (error: any) => {
            console.error('Error on registration: ' + JSON.stringify(error));
        });
    }

    /**
     * @description Initialize push notifications listener
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @private
     * @memberof NotificationService
     * @example
     * this.initPushNotificationListener();
     */
    private initPushNotificationListener(): void {
        PushNotifications.addListener(
            'pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                // Display notification
                if (notification?.title) {
                    const notificationReceived: NotificationReceived = {
                        id: notification.id,
                        title: notification.title,
                        body: notification.body ?? '',
                    };

                    // Check if the notification is not already in the list
                    if (
                        this.notificationsToDisplay$.value.some(
                            (notificationToDisplay) =>
                                notificationToDisplay.id === notificationReceived.id
                        )
                    ) {
                        return;
                    }

                    this.notificationsToDisplay$.next([
                        ...this.notificationsToDisplay$.value,
                        notificationReceived,
                    ]);
                }
            }
        );
    }

    /**
     * @description Initialize push notifications action performed listener
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @private
     * @memberof NotificationService
     * @example
     * this.initPushNotificationActionPerformedListener();
     */
    private initPushNotificationActionPerformedListener(): void {
        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (notification: ActionPerformed) => {}
        );
    }

    /**
     * @description Request permission to receive push notifications (iOS only) and register the device to receive push notifications (iOS and Android only)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @private
     * @memberof NotificationService
     * @example
     * this.requestPermission();
     */
    private requestPermission(): void {
        PushNotifications.requestPermissions()
            .then(async (result) => {
                if (result.receive === 'granted') {
                    await PushNotifications.register();
                } else {
                    // Show some error
                    console.warn('User does not have permission to receive push notifications');
                }
            })
            .catch((err) => {
                console.error('Error requesting permissions', err);
            });
    }

    /**
     * @description Initialize web push notifications (web only) and register the device to receive push notifications (web only) and store the token in the database
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @private
     * @memberof NotificationService
     */
    private async initWebPush(): Promise<void> {
        // Get the token
        const messaging = getMessaging();
        const token = await getToken(messaging, {
            vapidKey: OmedomEnvironment[OmedomEnvironment.currentEnvionment].firebase.vapidKey,
        });

        // Check if the token is not null
        if (!token) {
            return;
        }

        // Get the device type
        const device = 'web';

        // Add the device token to the user
        await this.addDeviceTokenToUser(device, token);

        // Listen for messages
        onMessage(messaging, (payload) => {
            if (this.platform.is('capacitor')) {
                return;
            }

            // Display notification
            if (payload?.notification) {
                const notificationReceived: NotificationReceived = {
                    title: payload.notification.title ?? '',
                    body: payload.notification.body ?? '',
                };

                this.notificationsToDisplay$.next([
                    ...this.notificationsToDisplay$.value,
                    notificationReceived,
                ]);
            }
        });
    }

    /**
     * @description Get the list of notifications to display in the app when the user receive a push notification (iOS and Android only) and in the list of notifications to display in the app when the user receive a push notification (iOS and Android only) and in the list of notifications to display in the app when the user receive a push notification (iOS and Android only) and in the list of notifications to display in the app when the user receive a push notification (iOS and Android only) and in the list of notifications to display in the app when the user receive a push notification (iOS and Android only) and in the list of notifications to display in the app when the user receive a push notification (iOS and Android only) and in the list of notifications to display in the app when the user receive a push notification (iOS and Android only) and in the list of notifications to display in the app when the user receive a push notification (iOS and Android only) and in the list of notific
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/01/2024
     * @readonly
     * @type {Observable<NotificationReceived[]>}
     * @memberof NotificationService
     */
    public get notificationsToDisplay(): Observable<NotificationReceived[]> {
        return this.notificationsToDisplay$.asObservable();
    }

    /**
     * @description Remove the notification from the list of notifications to display in the app when the user receive a push notification (iOS and Android only) and in the list of notific
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/01/2024
     * @param {number} index
     * @memberof NotificationService
     */
    public removeNotificationToDisplay(index: number): void {
        const notificationsToDisplay = this.notificationsToDisplay$.value;
        notificationsToDisplay.splice(index, 1);
        this.notificationsToDisplay$.next(notificationsToDisplay);
    }

    /**
     * @description Add the device token to the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/01/2024
     * @private
     * @param {('android' | 'ios' | 'web')} device
     * @param {string} token
     * @returns {*}  {Promise<void>}
     * @memberof NotificationService
     */
    private async addDeviceTokenToUser(
        device: 'android' | 'ios' | 'web',
        token: string
    ): Promise<void> {
        // Check if the user is logged in
        if (!this.user) {
            return;
        }

        const notification = this.user?.notification ?? {};

        // Get the device token
        const deviceToken = notification?.[device];

        // Check if the device token is not already stored
        if (deviceToken === token) {
            return;
        }

        // Store the device token in the database
        notification[device] = token;

        await this.userService.update({
            uid: this.user.uid,
            notification,
        });
    }
}

import { AllChargeCategories, ChargePeriodicity } from '../entities/charge.entity';
import { AllIncomeCategories, IncomePeriodicity } from '../entities/income.entity';

/**
 * @description Treasury streamer model to broadcast treasury data between stories and logics
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 02/08/2023
 * @export
 * @class TreasuryStreamer
 */
export class TreasuryStreamer {
    /**
     * @description UID of the treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {string}
     * @memberof TreasuryStreamer
     */
    uid!: string;

    /**
     * @description Amount of the treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {number}
     * @memberof TreasuryStreamer
     */
    amount!: number;

    /**
     * @description Category of the treasury (rent, charge, etc...). See Category enum for more details
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {(ChargeCategoryProperty | IncomeCategoryProperty)}
     * @memberof TreasuryStreamer
     */
    category!: AllChargeCategories | AllIncomeCategories;

    /**
     * @description Debit date of the treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {Date}
     * @memberof TreasuryStreamer
     */
    debitDate!: Date;

    /**
     * @description Return true if the treasury is payed or received by the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {boolean}
     * @memberof TreasuryStreamer
     */
    isPayed!: boolean;

    /**
     * @description Return true if the treasury is already readed by the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {boolean}
     * @memberof TreasuryStreamer
     */
    isReaded!: boolean;

    /**
     * @description Notes of the treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {string}
     * @memberof TreasuryStreamer
     */
    notes?: string;

    /**
     * @description Designation of the treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {string}
     * @memberof TreasuryStreamer
     */
    designation?: string;

    /**
     * @description Periodicity of the treasury (monthly, yearly, etc...). See Periodicity enum for more details
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {(ChargePeriodicity | IncomePeriodicity)}
     * @memberof TreasuryStreamer
     */
    periodicity!: ChargePeriodicity | IncomePeriodicity;

    /**
     * @description Uid of the property linked to the treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {string}
     * @memberof TreasuryStreamer
     */
    propertyUID?: string;

    /**
     * @description Uid of the society linked to the treasury
     * @author ANDRE Felix
     * @type {string}
     * @memberof TreasuryStreamer
     */
    societyUID?: string;

    /**
     * @description Return true if the treasury is deleted
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {boolean}
     * @memberof TreasuryStreamer
     */
    isDeleted!: boolean;

    /**
     * @description Return true if the treasury is a charge (false if it's an income)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {boolean}
     * @memberof TreasuryStreamer
     */
    isCharge!: boolean;

    /**
     * @description Uid of the user owner of the treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @type {string}
     * @memberof TreasuryStreamer
     */
    userUID?: string;

    /**
     * @description if the treasury as notification
     * @author ANDRE Felix
     * @type {boolean}
     * @memberof TreasuryStreamer
     */
    notification?: boolean;

    /**
     * @description Verify if receipt is sent
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 25/04/2024
     * @type {boolean}
     * @memberof TreasuryStreamer
     */
    isSentReceipt?: boolean;

    /**
     * @description Verify if relaunch is sent
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 25/04/2024
     * @type {boolean}
     * @memberof TreasuryStreamer
     */
    isSentRelaunch?: boolean;

    /**
     * @description Verify if relaunch is sent
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 02/05/2024
     * @type {number}
     * @memberof TreasuryStreamer
     */
    rentWithCharges?: number;
}

<!-- Transaction -->
@if (transaction) {
<div class="container" @element>
    <h3>Mouvement à associer</h3>
    <omedom-bank-transaction
        [transaction]="transaction"
        [showActions]="false"
    ></omedom-bank-transaction>
</div>
}

<!-- Patrimony -->
@if (patrimony$ | async; as patrimony) {
<div class="container" @element>
    <h3>{{ isProperty ? 'Bien associé' : 'Société associée' }}</h3>
    <div class="patrimony">
        <ng-container
            [ngTemplateOutlet]="patrimony.photo ? photo : icon"
        ></ng-container>
        <ng-template #icon>
            <div class="patrimony-icon">
                <i class="uil uil-{{ isProperty ? 'home' : 'suitcase' }}"></i>
            </div>
        </ng-template>
        <ng-template #photo>
            <img
                class="patrimony-photo"
                [src]="patrimony.photo"
                [alt]="patrimony.name"
            />
        </ng-template>
        <div class="patrimony-content">
            <div class="patrimony-name">{{ patrimony.name }}</div>
        </div>
    </div>
</div>
}

<!-- Category -->
@if (category$ | async; as category) {
<div class="container" @element>
    <h3>Catégorie</h3>
    <div class="option selected">
        <i class="option-icon {{ category.icon }}"></i>
        <span class="option-name">{{ category.label }}</span>
    </div>
</div>
}

<!-- Treasury -->
@if (treasury$ | async; as treasury) {
<div class="container" @element>
    <h3>
        {{ transaction && transaction.amount > 0 ? 'Revenu associé' : 'Charge
        associée' }}
    </h3>
    <!-- <div class="treasury">
    <div class="treasury-icon">
      <i class="uil uil-{{ getCategoryInfo(treasury).icon }}"></i>
    </div>
    <div class="treasury-content">
      <div class="treasury-name">
        <strong class="treasury-title">{{ getCategoryInfo(treasury).label }}</strong>
        <span class="treasury-periodicity">{{ getPeriodicityInfo(treasury).label }}</span>
        <span class="treasury-notes"
        *ngIf="treasury.notes">{{ treasury.notes }}</span>
      </div>
      <div class="treasury-amount">{{ treasury.amount | omedomNumber }} €</div>
    </div>
  </div> -->
    <omedom-treasury-card2 [treasury]="treasury"></omedom-treasury-card2>
</div>
}

<!-- Periodicity -->
@if (periodicity$ | async; as periodicity) {
<div class="container" @element>
    <h3>Périodicité</h3>
    <div class="option selected">
        <span>{{ periodicity.label }}</span>
    </div>
</div>
}

<!-- Notes -->
@if (notes$ | async; as notes) {
<div class="container" @element>
    <h3>Notes</h3>
    <p class="omedom-no-margin">{{ notes }}</p>
</div>
}

<!-- Recurrencies -->
@if (recurrencies$ | async; as recurrencies) {
<div class="container" @element>
    <h3>Récurrences</h3>
    <omedom-bank-transaction-list
        [transactions]="recurrencies"
        [showActions]="false"
    ></omedom-bank-transaction-list>
</div>
}

export enum RoleRightFrEnum {
    change = "- Modifier les informations relatives au bien partagé (fiche du bien, charges, revenus, date et type de bail, coordonnées d'un locataire, aide)",
    read = "- Visualiser les données du bien partagé (informations du bien, charges, revenus, trésorerie, date et type de bail, coordonnées d'un locataire, aide)",
    validate = '- Valider les alertes au bien partagé (charges ; revenus)',
    create = '- Créer et valider les alertes au bien partagé (charges ; revenus)',
    delete = '- Supprimer le bien partagé et les données associées.',
    send = '- Envoyer une quittance ou une lettre de relance au(x) locataire(s) du bien partagé',
    documentPartial = '- Visualiser et exporter un document du bien partagé',
    documentComplete = '- Visualiser, importer et/ou exporter un document dans le bien partagé',
    smartReaderOptions = "Pour la partie 'Documents', l'abonnement 'PREMIUM' est nécessaire:",
    smartAdminOptions = "Pour les parties 'Documents' et 'Loyers', l'abonnement 'PREMIUM' est nécessaire :",
    none = "n'a aucun droit",
}

import {
    EstimationInformation,
    PricehubbleAddress,
    PricehubbleGlazingType,
    PricehubbleHeatingType,
    PricehubbleLocation,
    PricehubbleProperty,
    PrichubblePropertyType,
    PropertyEntity,
    PropertyType,
} from '@omedom/data';

export class OmedomPricehubble {
    public static isPropertyAssimilateHouse(propertyType: PropertyType | undefined) {
        if (!propertyType) {
            return false;
        }
        return (
            propertyType === PropertyType.maison ||
            propertyType === PropertyType.chalet ||
            propertyType === PropertyType.hotel ||
            propertyType === PropertyType.immeuble
        );
    }

    public static isPropertyAssimilateAppartement(propertyType: PropertyType | undefined) {
        return propertyType === PropertyType.appartement;
    }

    public static transformPropertyEntityInFolder(propertyEntity: Partial<PropertyEntity>) {
        const partialPropertyFolder: Partial<PricehubbleProperty> = {
            numberOfOutdoorParkingSpaces: this.checkParkingAmenity(propertyEntity),
        };

        const propertyBuildingYear = this.adaptBuildingYear(propertyEntity);
        const propertyRoomCount = this.adaptRoomCount(propertyEntity);
        const propertyLivingArea = this.adaptLivingArea(propertyEntity);
        const propertyDpeScore = this.adaptDpeScore(propertyEntity);
        const propertyPool = this.checkPool(propertyEntity);
        const propertyLift = this.checkLift(propertyEntity);
        const propertyType = this.adaptPropertyType(propertyEntity);
        const propertyAddress = this.adaptPropertyAddress(propertyEntity);
        const landArea = this.adaptLandArea(propertyEntity);
        const propertyIsNew = this.adaptIsNew(propertyEntity);
        // const propertyGlazingType = this.adaptGlazingType(propertyEntity);
        const propertyHeatingType = this.adaptHeatingType(propertyEntity);
        const propertyEstimation = this.adaptEstimationData(propertyEntity);

        const propertyFolder: PricehubbleProperty = {
            ...propertyBuildingYear,
            ...propertyRoomCount,
            ...propertyLivingArea,
            ...partialPropertyFolder,
            location: propertyAddress,
            ...landArea,
            ...propertyType,
            ...propertyLift,
            ...propertyPool,
            ...propertyDpeScore,
            ...propertyIsNew,
            // ...propertyGlazingType,
            ...propertyHeatingType,
            ...propertyEstimation,
        };

        return propertyFolder;
    }

    private static adaptBuildingYear(property: Partial<PropertyEntity>) {
        if (!property.buildingYear) {
            return {};
        }
        return {
            buildingYear: property.buildingYear,
        };
    }

    private static adaptLivingArea(property: Partial<PropertyEntity>) {
        if (!property.livingSpace) {
            return {};
        }
        return {
            livingArea: property.livingSpace,
        };
    }

    private static adaptRoomCount(property: Partial<PropertyEntity>) {
        if (!property.roomCount) {
            return {};
        }
        return {
            numberOfRooms: property.roomCount,
        };
    }
    private static adaptPropertyType(property: Partial<PropertyEntity>) {
        if (this.isPropertyTypeApartment(property)) {
            return { propertyType: { code: PrichubblePropertyType.apartment } };
        }
        return {
            propertyType: { code: PrichubblePropertyType.house },
        };
    }
    private static isPropertyTypeApartment(property: Partial<PropertyEntity>) {
        if (!property.type) {
            return true;
        }
        if (this.isPropertyAssimilateHouse(property.type)) {
            return false;
        }
        return true;
    }

    private static adaptPropertyAddress(property: Partial<PropertyEntity>): PricehubbleLocation {
        if (!property || !property.address?.street) {
            const emptyAddressFolder: PricehubbleAddress = {
                city: '',
                street: '',
                postCode: '',
                houseNumber: '0',
            };
            return { address: emptyAddressFolder };
        }

        const addressFolder: PricehubbleAddress = {
            city: property.address?.city ?? '',
            street: property.address?.street ?? '',
            postCode: property.address?.postalCode?.toString() ?? '',
            houseNumber: property.address?.streetNumber?.toString() ?? '0',
        };
        return { address: addressFolder };
    }

    private static checkPool(property: Partial<PropertyEntity>) {
        if (this.isPropertyTypeApartment(property)) {
            return {};
        }

        if (!property.amenities || property.amenities.length === 0) {
            return {};
        }
        if (property.amenities.includes('Piscine')) {
            return { hasPool: true };
        }

        return {};
    }
    private static checkLift(property: Partial<PropertyEntity>) {
        if (!this.isPropertyTypeApartment(property)) {
            return {};
        }

        if (!property.amenities || property.amenities.length === 0) {
            return {};
        }
        if (property.amenities.includes('Ascenseur')) {
            return { hasLift: true };
        }

        return {};
    }

    private static checkParkingAmenity(property: Partial<PropertyEntity>) {
        if (!property.amenities || property.amenities.length === 0) {
            return 0;
        }
        return property.amenities.includes('Garage / Parking') ? 1 : 0;
    }

    private static adaptLandArea(property: Partial<PropertyEntity>) {
        if (property.type === PropertyType.appartement || !property.landArea) {
            return {};
        }
        if (property.landArea < 10) {
            return {};
        }
        return { landArea: property.landArea };
    }

    private static adaptDpeScore(property: Partial<PropertyEntity>) {
        if (!property?.dpeDetails?.dpeScore) {
            return {};
        }
        return { energyLabel: property.dpeDetails.dpeScore.toLowerCase() };
    }

    private static adaptGlazingType(property: Partial<PropertyEntity>) {
        if (!property.amenities || property.amenities?.length === 0) {
            return {};
        }
        const tripleVitrage = property.amenities.includes('Triple vitrage');
        if (tripleVitrage) {
            return { glazingType: PricehubbleGlazingType.triple };
        }

        const doubleVitrage = property.amenities.includes('Double vitrage');
        if (doubleVitrage) {
            return { glazingType: PricehubbleGlazingType.double };
        }

        const simpleVitrage = property.amenities.includes('Simple vitrage');
        if (simpleVitrage) {
            return { glazingType: PricehubbleGlazingType.simple };
        }
        return {};
    }

    private static adaptHeatingType(property: Partial<PropertyEntity>) {
        if (!property.amenities || property.amenities?.length === 0) {
            return {};
        }

        const collectiveHeating = property.amenities.includes('Chauffage collectif');
        if (collectiveHeating) {
            return { heatingType: PricehubbleHeatingType.collective };
        }

        const individualHeating = property.amenities.includes('Chauffage individuel');
        if (individualHeating) {
            return { heatingType: PricehubbleHeatingType.individual };
        }
        return {};
    }

    private static adaptIsNew(property: Partial<PropertyEntity>) {
        const currentYear = new Date().getFullYear();

        const yearToConsiderPropertyNew = 5;

        if (
            !property.buildingYear ||
            currentYear - property.buildingYear > yearToConsiderPropertyNew
        ) {
            return { isNew: false };
        }
        return { isNew: true };
    }

    private static adaptEstimationData(property: Partial<PropertyEntity>) {
        if (!property.dpeDetails?.estimationInformations) {
            return {};
        }
        let isolationRenovationPeriod = {};
        let numberOfExternalFacingWalls = {};
        let numberOfWindows = {};
        let atticType = {};
        let glazingType = {};
        const propertyEstimation = property.dpeDetails.estimationInformations;
        const isAssimilateHouse = OmedomPricehubble.isPropertyAssimilateHouse(property?.type);

        if (propertyEstimation.isolationRenovationPeriod) {
            isolationRenovationPeriod = {
                isolationRenovationPeriod: propertyEstimation.isolationRenovationPeriod,
            };
        }
        if (propertyEstimation.numberOfExternalFacingWalls) {
            numberOfExternalFacingWalls = {
                numberOfExternalFacingWalls: propertyEstimation.numberOfExternalFacingWalls,
            };
        }
        if (propertyEstimation.numberOfWindows) {
            numberOfWindows = { numberOfWindows: propertyEstimation.numberOfWindows };
        }
        if (propertyEstimation.atticType && isAssimilateHouse) {
            atticType = { atticType: propertyEstimation.atticType };
        }
        if (propertyEstimation.glazingType) {
            glazingType = { glazingType: propertyEstimation.glazingType };
        }

        const estimationData: EstimationInformation = {
            ...isolationRenovationPeriod,
            ...numberOfExternalFacingWalls,
            ...numberOfWindows,
            ...atticType,
            ...glazingType,
        };

        return estimationData;
    }

    public static canUsePricehubble(property: Partial<PropertyEntity>) {
        // TODO: Ajouter si l'utilisateur est en prenium (plus ajouter la vérification en backend)

        const propertyType = property?.type;
        if (!propertyType) {
            return false;
        }

        const isAssimilateHouse = OmedomPricehubble.isPropertyAssimilateHouse(propertyType);
        const isAssimilateAppartement =
            OmedomPricehubble.isPropertyAssimilateAppartement(propertyType);

        return isAssimilateAppartement || isAssimilateHouse;
    }
}

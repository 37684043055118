import { Component, Input } from '@angular/core';
import { ReviewEntity } from '@omedom/data';

@Component({
    selector: 'omedom-review-display',
    templateUrl: './review-display.component.html',
    styleUrls: ['./review-display.component.scss'],
})
export class ReviewDisplayComponent  {
    @Input({ required: true }) review!: ReviewEntity;

    adaptDate(date: any) {
        return date.toDate().toLocaleDateString();
    }
}

<div class="omedom-modal">
    <div class="omedom-modal-header">
        <div class="omedom-modal-header-title">
            <i
                class="uil uil-chart-pie-alt color-light-black background-color-light-grey"
            ></i>
            <h3>Modification de revenu</h3>
        </div>
        <i class="uil uil-times-circle" (click)="dismiss()"></i>
    </div>
    <div class="omedom-modal-content">
        <omedom-radio
            [options]="updateOptions"
            type="column"
            name="updateOption"
            label="Souhaitez-vous modifier ce revenu ?"
            [(ngModel)]="selectedUpdateOption"
        ></omedom-radio>
    </div>
    <div class="omedom-modal-buttons">
        <button class="omedom-button-secondary" (click)="dismiss()">
            Annuler
        </button>
        <button
            class="omedom-button-primary"
            [disabled]="!selectedUpdateOption"
            (click)="updateConfirmed()"
        >
            <i class="uil uil-check-circle"></i>
            Valider
        </button>
    </div>
</div>

/**
 * @description Enum of storage limit in bytes for a user in a project
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 05/09/2023
 * @export
 * @enum {number}
 */
export enum Storage {
    limit = 2 * 10 ** 9
}

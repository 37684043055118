/**
 * @description Role of a user in a project
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 10/08/2023
 * @export
 * @enum {number}
 */
export enum RoleAbsolute {
    owner = 'owner',
    admin = 'admin',
    editor = 'editor',
    reader = 'reader',
    none = 'none',
}

import { Component, OnInit } from '@angular/core';
import { NotificationReceived } from '@omedom/data';
import { NotificationService } from '@omedom/services';
import { Observable } from 'rxjs';

@Component({
    selector: 'omedom-notification-container',
    templateUrl: './notification-container.component.html',
    styleUrls: ['./notification-container.component.scss'],
})
export class NotificationContainerComponent implements OnInit {
    /**
     * @description Notifications to display in the app when the user receive a push notification
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/01/2024
     * @memberof NotificationContainerComponent
     */
    public notificationsToDisplay$: Observable<NotificationReceived[]> = this.notificationService.notificationsToDisplay;

    constructor(private notificationService: NotificationService) { }

    ngOnInit(): void {

    }

    /**
     * @description Remove the notification from the list of notifications to display in the popup
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/01/2024
     * @param {number} index
     * @memberof NotificationContainerComponent
     */
    public removeNotification(index: number): void {
        this.notificationService.removeNotificationToDisplay(index);
    }
}

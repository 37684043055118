import { Component, OnInit } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { ProEntity, SubscriptionDto } from '@omedom/data';
import { ProService, SubscriptionService, UserService } from '@omedom/services';
import {
    Observable,
    Subscription,
    combineLatest,
    filter,
    map,
    of,
    startWith,
    switchMap,
} from 'rxjs';

@Component({
    selector: 'omedom-directory-pro-list',
    templateUrl: './directory-pro-list.component.html',
    styleUrls: ['./directory-pro-list.component.scss'],
})
export class DirectoryProListComponent implements OnInit {
    /**
     * @description list of Pro, i don't know which one for the moment
     * @author ANDRE Felix
     * @type {ProEntity[]}
     * @memberof DirectoryProListComponent
     */
    pros: ProEntity[] = [];

    public subscription$: Observable<SubscriptionDto | undefined> = toObservable(
        this.subscriptionService.subscription
    );

    /**
     * @description pros filtered depending on search
     * @author ANDRE Felix
     * @memberof DirectoryProListComponent
     */
    prosFiltered: ProEntity[] = [];

    subscriptions: Subscription[] = [];
    constructor(
        private userService: UserService,
        private subscriptionService: SubscriptionService,
        private proService: ProService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        // get pro
        const myPro$ = this.subscription$.pipe(
            switchMap((subscription) => {
                const proUID = subscription?.proUID;
                if (proUID) {
                    return this.proService._get(proUID);
                }
                return of(undefined);
            }),
            startWith(undefined)
        );

        // get pro list
        const pros$ = this.proService._list();
        const user$ = combineLatest([pros$, myPro$]).subscribe(([pros, myPro]) => {
            if (pros) {
                this.pros = pros.filter(
                    (pro) =>
                        pro.uid === myPro?.uid ||
                        (!pro.disabledInDirectory &&
                            (myPro ? myPro.position !== pro.position : true))
                );
                this.prosFiltered = this.pros;
            }
        });

        this.subscriptions.push(user$);
    }

    detailClicked(proUID: string) {
        if (!proUID) {
            return;
        }
        this.router.navigate([`pro-detail`], {
            relativeTo: this.route,
            queryParams: { proUID },
        });
    }

    searchChanged(searchValue: string) {
        if (searchValue === '' || searchValue === undefined) {
            this.prosFiltered = this.pros;
            return;
        }

        this.prosFiltered = this.pros.filter((pro) => {
            const isInProfession = pro.position?.toLowerCase().includes(searchValue);
            const isInExpertiseField = this.isIncludeInExpertiseField(pro, searchValue);

            return !!isInProfession || !!isInExpertiseField;
        });
    }

    isIncludeInExpertiseField(pro: ProEntity, searchValue: string) {
        if (!pro?.expertiseField || pro.expertiseField.length === 0) {
            return false;
        }
        const indexFind = pro.expertiseField.findIndex((expertiseField) => {
            return expertiseField.toLowerCase().includes(searchValue);
        });
        if (indexFind === -1) {
            return false;
        }
        return true;
    }
}

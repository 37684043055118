<p class="margin-bottom-2">
    Sélectionnez votre type de bien (pour Hôtel particulier rendez-vous dans
    'Autres') :
</p>
<div class="property-list">
    <omedom-select-property-card
        (clickEvent)="selectProperty($event)"
        [selected]="isSelected(propertyTypes.appartement)"
        [key]="propertyTypes.appartement"
        [title]="'Appartement'"
    ></omedom-select-property-card>
    <omedom-select-property-card
        (clickEvent)="selectProperty($event)"
        [selected]="isSelected(propertyTypes.maison)"
        [key]="propertyTypes.maison"
        [title]="'Maison'"
    ></omedom-select-property-card>
    <omedom-select-property-card
        (clickEvent)="selectProperty($event)"
        [selected]="isSelected(propertyTypes.localCommercial)"
        [key]="propertyTypes.localCommercial"
        [title]="'Local Commercial'"
    ></omedom-select-property-card>
    <omedom-select-property-card
        (clickEvent)="selectProperty($event)"
        [selected]="isSelected(propertyTypes.parkingBox)"
        [key]="propertyTypes.parkingBox"
        [title]="'Parking'"
    ></omedom-select-property-card>
    <omedom-select-property-card
        (clickEvent)="selectProperty($event)"
        [selected]="isSelected(propertyTypes.immeuble)"
        [key]="propertyTypes.immeuble"
        [title]="'Immeuble'"
    ></omedom-select-property-card>
    <omedom-select-property-card
        (clickEvent)="selectProperty($event)"
        [selected]="isSelected(propertyTypes.terrain)"
        [key]="propertyTypes.terrain"
        [title]="'Terrain'"
    ></omedom-select-property-card>
    <omedom-select-property-card
        (clickEvent)="clickMore($event)"
        [selected]="isSelected(propertyTypes.other)"
        [title]="isSelected(propertyTypes.other) ? selectedProperty : 'Autre'"
        [icon]="'comment-dots'"
    ></omedom-select-property-card>
</div>

import JSConfetti from 'js-confetti';

/**
 * @description Add confetti on the page (only frontend)
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 02/08/2023
 * @export
 * @class OmedomConfetti
 */
export class OmedomConfetti {
    /**
     * @description Add confetti on the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @memberof OmedomConfetti
     * @example
     * OmedomConfetti.addConfetti();
     */
    public static addConfetti(): void {
        const jsConfetti = new JSConfetti();
        jsConfetti.addConfetti({
            confettiColors: ['#FFFFFF', '#FFBE00', '#00C29A', '#0076C8'],
            confettiNumber: 750,
            confettiRadius: 4,
        });
    }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ChatEntity } from '@omedom/data';

import { RestService } from './rest.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ChatService extends RestService<ChatEntity> {
    constructor(protected override firestore: AngularFirestore) {
        super(firestore, 'chats');
    }

    /**
     * @description Get the chats of a user. Can be filtered by userUID.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/08/2024
     * @param {string} userUID
     * @returns {*}  {Promise<ChatEntity[]>}
     * @memberof ChatService
     */
    async getChatsByUser(userUID: string): Promise<ChatEntity[]> {
        return this.search([
            {
                where: 'userUIDs',
                operator: 'array-contains',
                value: userUID,
            },
        ]);
    }

    /**
     * @description Get the chats of a user. Can be filtered by userUID. (Observable version)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/08/2024
     * @param {string} userUID
     * @returns {*}  {Observable<ChatEntity[]>}
     * @memberof ChatService
     */
    _getChatsByUser(userUID: string): Observable<ChatEntity[]> {
        return this._search([
            {
                where: 'userUIDs',
                operator: 'array-contains',
                value: userUID,
            },
        ]);
    }
}

//firestore rules
// /match /databases/{database}/documents {
//     match /{document=**} {
//         allow read, write: false;
//     }
//     match /chats/{chatUID} {
//         allow get: if request.auth.uid in resource.data.userUIDs;
//         allow list: if request.auth.uid in resource.data.userUIDs;

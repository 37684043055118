import {
    Component,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { BankAccountEntity, BankTransactionEntity } from '@omedom/data';
import { BankAccountService, BankTransactionService, UserService } from '@omedom/services';
import { BehaviorSubject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'omedom-bank-transaction',
    templateUrl: './bank-transaction.component.html',
    styleUrls: ['./bank-transaction.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BankTransactionComponent),
            multi: true,
        },
    ],
})
export class BankTransactionComponent
    implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
    /**
     * @description Transaction to display in the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @type {BankTransactionEntity}
     * @memberof BankTransactionComponent
     */
    @Input({ required: true })
    public transaction!: BankTransactionEntity;

    /**
     * @description
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @type {BankAccountEntity}
     * @memberof BankTransactionComponent
     */
    @Input()
    public account?: BankAccountEntity;

    /**
     * @description If true, the user will be able to associate the transaction to a charge or income.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {boolean}
     * @memberof BankTransactionComponent
     */
    @Input()
    public showActions: boolean = true;

    /**
     * @description Show the radio button to select the item (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @memberof BankTransactionComponent
     */
    @Input()
    public selectable: boolean = false;

    /**
     * @description Value of the component (used by the ControlValueAccessor) (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @type {boolean}
     * @memberof BankTransactionComponent
     */
    @Input()
    public value: boolean = false;

    /**
     * @description Whether the select is disabled or not. Default is false. If disabled, the select will not trigger any event when clicked
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @type {boolean}
     * @memberof BankTransactionComponent
     */
    @Input()
    public disabled: boolean = false;

    /**
     * @description Event emitted when the user wants to associate the transaction
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/05/2024
     * @memberof BankTransactionComponent
     */
    @Output()
    public onAssociate = new EventEmitter<void>();

    /**
     * @description Event emitted when the user selects the transaction
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @memberof BankTransactionComponent
     */
    @Output()
    public onSelect = new EventEmitter<boolean>();

    /**
     * @description Event emitted when the user wants to dissociate the transaction
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/05/2024
     * @memberof BankTransactionComponent
     */
    @Output()
    public onDissociate = new EventEmitter<void>();

    /**
     * @description Callback function to call when the value changes. This is called by the ControlValueAccessor when the value changes
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @param {boolean} value
     * @type {Function}
     * @memberof BankTransactionComponent
     */
    public onChange: Function = (value: boolean) => {
        this.onSelect.emit(value);
    };

    /**
     * @description Callback function to call when the button is touched. This is called by the ControlValueAccessor when the button is touched
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @type {Function}
     * @memberof BankTransactionComponent
     */
    public onTouched: Function = () => {};

    /**
     * @description List of subscriptions to unsubscribe when the component is destroyed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 14/05/2024
     * @private
     * @type {Subscription[]}
     * @memberof BankTransactionComponent
     */
    private subscriptions: Subscription[] = [];

    /**
     * @description Observable to know if the transaction is associated to a charge or income (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @memberof BankTransactionComponent
     */
    public associated$ = new BehaviorSubject<boolean>(false);

    constructor(
        private bankAccountService: BankAccountService,
        private bankTransactionService: BankTransactionService,
        private toastController: ToastController,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        if (!this.account) {
            this.getAccount();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['transaction'] && changes['transaction'].currentValue) {
            this.associated$.next(!!this.transaction.treasuryUID);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    writeValue(value: boolean): void {
        this.value = value;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * @description Get the account of the transaction from the database and set it in the component property account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @private
     * @returns {Promise<void>}
     * @memberof BankTransactionComponent
     */
    private getAccount(): void {
        this.subscriptions.push(
            this.userService.user$
                .pipe(
                    switchMap((user) =>
                        this.bankAccountService._getBankAccountFromID(
                            this.transaction.accountID,
                            user.uid
                        )
                    )
                )
                .subscribe((account) => {
                    this.account = account;
                })
        );
    }

    /**
     * @description Mask the transaction to hide it from the user view
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @returns {Promise<void>}
     * @memberof BankTransactionComponent
     */
    public async maskTransaction(): Promise<void> {
        await this.bankTransactionService.mask(this.transaction);

        // Display a toast to inform the user that the transaction has been masked
        const masked = await this.toastController.create({
            message: 'Cette transaction a été masquée.',
            duration: 4000,
            color: 'warning',
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    handler: async () => {
                        await this.bankTransactionService.unmask(this.transaction);
                        await masked.dismiss();

                        // Display a toast to inform the user that the transaction has been unmasked
                        const unmasked = await this.toastController.create({
                            message: 'Cette transaction a été démasquée.',
                            duration: 2000,
                            color: 'success',
                        });

                        await unmasked.present();
                    },
                },
            ],
        });

        await masked.present();
    }

    /**
     * @description Unmask the transaction to display it to the user view
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/04/2024
     * @returns {Promise<void>}
     * @memberof BankTransactionComponent
     */
    public async unmaskTransaction(): Promise<void> {
        await this.bankTransactionService.unmask(this.transaction);

        // Display a toast to inform the user that the transaction has been unmasked
        const unmasked = await this.toastController.create({
            message: 'Cette transaction a été démasquée.',
            duration: 2000,
            color: 'success',
        });

        await unmasked.present();
    }

    /**
     * @description Broadcast the event to associate the transaction to the user view
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/05/2024
     * @memberof BankTransactionComponent
     */
    public associateTransaction(): void {
        this.onAssociate.emit();
    }

    /**
     * @description Broadcast the event to dissociate the transaction to the user view
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/05/2024
     * @memberof BankTransactionComponent
     */
    public dissociateTransaction(): void {
        this.onDissociate.emit();
    }

    /**
     * @description Select the transaction when the user clicks on it
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @returns {void}
     * @memberof BankTransactionComponent
     */
    @HostListener('click')
    public selectTransaction(): void {
        if (this.disabled) {
            return;
        }

        this.value = !this.value;
        this.onChange(this.value);
    }
}

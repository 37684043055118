import { Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ConfigurationEntity, SubscriptionDto, SubscriptionRank } from '@omedom/data';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RestService } from './rest.service';
import { SubscriptionService } from './subscription.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService extends RestService<ConfigurationEntity> {
    public configuration$: BehaviorSubject<ConfigurationEntity[]> = new BehaviorSubject([
        new ConfigurationEntity(),
    ]);

    public subscription$: Observable<SubscriptionDto | undefined> = toObservable(
        this.subscriptionService.subscription
    );

    constructor(
        private subscriptionService: SubscriptionService,
        protected override firestore: AngularFirestore,
        protected auth: AngularFireAuth
    ) {
        super(firestore, 'configuration');
    }

    watchConfiguration(): Observable<ConfigurationEntity[]> {
        return this.firestore
            .collection('configuration')
            .snapshotChanges()
            .pipe(
                map((actions) =>
                    actions.map((a) => {
                        const data = a.payload.doc.data() as Partial<ConfigurationEntity>;
                        const docName = a.payload.doc.id;
                        return new ConfigurationEntity({ ...data, docName });
                    })
                )
            );
    }

    getConfigurations(): BehaviorSubject<ConfigurationEntity[]> {
        return this.configuration$;
    }

    public getUserConfiguration(): Observable<ConfigurationEntity> {
        const configuration = combineLatest([
            this.configuration$.asObservable(),
            this.subscription$,
        ]).pipe(
            map(([configurations, currentSubscription]) => {
                const configuration = configurations.find(
                    (config: ConfigurationEntity) => config.docName === currentSubscription?.rank
                );
                if (!configuration) {
                    return (
                        configurations.find(
                            (config: ConfigurationEntity) =>
                                config.docName === SubscriptionRank.ESSENTIAL
                        ) ?? new ConfigurationEntity()
                    );
                }
                return configuration;
            }),
            catchError((error) => {
                console.error('Error while getting user configuration', error);
                return of(new ConfigurationEntity());
            })
        );

        return configuration;
    }

    public getSpecificConfiguration(rank: SubscriptionRank, configuration: string): any {
        const rankConfiguration = this.configuration$.value.find(
            (config: ConfigurationEntity) => config.docName === rank
        );
        if (!rankConfiguration) {
            return this.configuration$.value.find(
                (config: ConfigurationEntity) => config.docName === 'essential'
            )?.[configuration];
        }
        return rankConfiguration[configuration];
    }
}

/**
 * @description Data of an automation action to send a notification to display in a form or in a chart
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 28/11/2023
 * @export
 * @interface AutomationActionSendNotification
 */
export interface AutomationActionSendNotification {
    title: string;
    body: string;
    url: string;
}

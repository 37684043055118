/**
 * @description Bank channel type enum for Bridge
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 01/03/2024
 * @export
 * @enum BankChannelType
 */
export enum BankChannelType {
    DSP2 = 'dsp2', // DSP2 is the European regulation for open banking
    DA = 'direct_access', // Direct access to the bank
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
    DocumentEntity,
    DocumentTypeAll,
    DocumentTypeClient,
    DocumentTypeUsed,
    PropertyEntity,
    SelectOption,
    SocietyEntity,
    UserEntity,
} from '@omedom/data';
import { PropertyService, UserService } from '@omedom/services';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
    selector: 'omedom-document-filter',
    templateUrl: './document-filter.component.html',
    styleUrls: ['./document-filter.component.scss'],
})
export class DocumentFilterComponent implements OnInit, OnDestroy {
    /**
     * @description Type of document to filter on the list of documents
     * @author Brisset Killian
     * @date 19/06/2024
     * @type {DocumentTypeAll}
     * @memberof DocumentFilterComponent
     */
    @Input()
    public type?: DocumentTypeAll;

    /**
     * @description UID of the asset to filter on the list of documents
     * @author Brisset Killian
     * @date 19/06/2024
     * @type {string}
     * @memberof DocumentFilterComponent
     */
    @Input()
    public assetUID?: string;

    /**
     * @description List of properties we can filter on the list of documents
     * @author Brisset Killian
     * @date 19/06/2024
     * @type {PropertyEntity[]}
     * @memberof DocumentFilterComponent
     */
    @Input()
    public properties: PropertyEntity[] = [];

    /**
     * @description List of societies we can filter on the list of documents
     * @author Brisset Killian
     * @date 19/06/2024
     * @type {SocietyEntity[]}
     * @memberof DocumentFilterComponent
     */
    @Input()
    public societies: SocietyEntity[] = [];

    /**
     * @description Start date of documents to filter on the list of documents
     * @author Brisset Killian
     * @date 19/06/2024
     * @type {string}
     * @memberof DocumentFilterComponent
     */
    @Input()
    public startDate?: string;

    /**
     * @description End date of documents to filter on the list of documents
     * @author Brisset Killian
     * @date 19/06/2024
     * @type {string}
     * @memberof DocumentFilterComponent
     */
    @Input()
    public endDate?: string;

    /**
     * @description Is the tab a property tab or a building/society tab ? (for the asset input)
     * @author Brisset Killian
     * @date 19/06/2024
     * @memberof DocumentFilterComponent
     */
    @Input()
    public isPropertyTab = false;

    /**
     * @description The type of document used, like for client, pro and importable, or pro and not importable,
     *  that will allow to display the correct type
     * @author ANDRE Felix
     * @type {DocumentTypeUsed}
     * @memberof DocumentFilterComponent
     */
    @Input() DocumentTypeUsed: DocumentTypeUsed = DocumentTypeUsed.client;

    /**
     * @description if type is free, user can search in an input of type text
     * @author ANDRE Felix
     * @memberof DocumentFilterComponent
     */
    @Input() isTypeFree = false;

    /**
     * @description Placeholder of select component
     * @author Jérémie Lopez
     * @memberof DocumentFilterComponent
     */
    public typePlaceholder = {
        id: null,
        label: 'Type de document',
    } as SelectOption;

    /**
     * @description List type of property in the select component
     * @author Jérémie Lopez
     * @memberof DocumentFilterComponent
     */
    public typeOptions = DocumentEntity.getDocumentTypeOptionsByTypeUsed(this.DocumentTypeUsed);

    /**
     * @description Placeholder of the property input
     * @author Jérémie Lopez
     * @memberof DocumentFilterComponent
     */
    public propertyPlaceholder = {
        label: 'Bien',
        icon: 'uil uil-home',
    } as SelectOption;

    /**
     * @description Options of the asset input
     * @author Jérémie Lopez
     * @memberof DocumentFilterComponent
     */
    public assetsOptions$ = new BehaviorSubject<SelectOption[]>([]);

    /**
     * @description User data
     * @author Jérémie Lopez
     * @type {UserEntity}
     * @memberof DocumentFilterComponent
     */
    public user?: UserEntity;

    /**
     * @description
     * @author Jérémie Lopez
     * @private
     * @type {Subscription[]}
     * @memberof DocumentFilterComponent
     */
    private subscriptions: Subscription[] = [];

    constructor(
        private modalController: ModalController,
        private userService: UserService,
        private propertyService: PropertyService
    ) {}

    async ngOnInit(): Promise<void> {
        this.typeOptions = DocumentEntity.getDocumentTypeOptionsByTypeUsed(this.DocumentTypeUsed);
        const user$ = this.userService.user$.subscribe(async (user) => {
            this.user = user;

            const societiesOptions = this.societies
                .map((society) => (society.toSelectOption ? society.toSelectOption() : null))
                .filter((society) => society !== null);

            const propertiesOptions = this.properties
                .map((property) => (property.toSelectOption ? property.toSelectOption() : null))
                .filter((property) => property !== null);

            this.assetsOptions$.next([...propertiesOptions, ...societiesOptions] as SelectOption[]);
        });

        this.startDate = this.startDate ? (this.startDate as any).toInputString() : null;
        this.endDate = this.endDate ? (this.endDate as any).toInputString() : null;

        this.subscriptions.push(user$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * @description Close modal
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof DocumentFilterComponent
     */
    public async close(data?: any): Promise<void> {
        await this.modalController.dismiss(data);
    }

    public async resetFilter(): Promise<void> {
        const filter: any[] = [];

        await this.close({ filter });
    }

    public async sendFilter(): Promise<void> {
        const filter = [];

        if (this.assetUID) {
            const isSociety = this.societies.find((society) => society.uid === this.assetUID);
            const isProperty = this.properties.find((property) => property.uid === this.assetUID);
            if (!isSociety && !isProperty) {
                this.assetUID = undefined;
            } else {
                filter.push({
                    where: isSociety ? 'societyUID' : 'propertyUID',
                    value: this.assetUID,
                });
            }
        }

        if (this.type) {
            filter.push({
                where: 'type',
                value: this.type,
            });
        }

        if (this.startDate) {
            filter.push({
                where: 'startDate',
                value: Timestamp.fromDate(new Date(this.startDate)),
            });
        }

        if (this.endDate) {
            filter.push({
                where: 'endDate',
                value: Timestamp.fromDate(new Date(this.endDate)),
            });
        }

        await this.close({ filter });
    }
}

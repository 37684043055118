<div class="toggle-container">
  <div class="toggle" (click)="toggle()">
    @if (switchContent1) {
      <div class="switch-content left">
        @if (switchContent1.icon) {
          <i
            [class]="'uil ' + switchContent1.icon"
            [ngClass]="{ 'not-selected': !value, selected: value }"
          ></i>
        }
        @if (switchContent1.text) {
          <span>{{ switchContent1.text }}</span>
        }
      </div>
    }
    @if (switchContent2) {
      <div class="switch-content right">
        @if (switchContent2.icon) {
          <i
            [class]="'uil ' + switchContent2.icon"
            [ngClass]="{ 'not-selected': value, selected: !value }"
          ></i>
        }
        @if (switchContent2.text) {
          <span>{{ switchContent2.text }}</span>
        }
      </div>
    }
    <div
      class="switch"
      [ngClass]="{ 'right-position': !value, 'left-position': value }"
    ></div>
  </div>
</div>

import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProEntity, UserEntity } from '@omedom/data';
import { ProService } from '@omedom/services';

@Component({
    selector: 'omedom-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnChanges {
    @Input()
    public user?: UserEntity;

    @Input()
    public keyToDisplay?: Array<keyof UserEntity>;

    @Input()
    public alignCenter = false;

    public pro?: ProEntity;

    constructor(
        private proService: ProService,
        private elementRef: ElementRef<HTMLElement>,
    ) { }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        // Check if user is defined
        if (!this.user) {
            return;
        }

        // Get pro
        if (this.user.proUID) {
            this.pro = await this.proService.get(this.user.proUID);
        }

        if (this.alignCenter) {
            this.elementRef.nativeElement.style.alignItems = 'center';
        } else {
            this.elementRef.nativeElement.style.alignItems = 'flex-start';
        }
    }

    private getKeys(): string[] {
        if (this.keyToDisplay) {
            return this.keyToDisplay;
        }
        return Object.keys(this.user ?? {});
    }

    public displayKey(key: keyof UserEntity): boolean {
        return this.getKeys().includes(key);
    }
}

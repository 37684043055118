import { FormControl } from '@angular/forms';

/**
 * @description Loan due date form type
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 09/07/2024
 * @type {LoanDueDateForm}
 */
export type LoanDueDateForm = {
    /**
     * @description Opening date of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<Date | undefined>)}
     */
    openingDate: FormControl<Date | undefined>,

    /**
     * @description Maturity date of the loan (end date of the loan)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<Date | undefined>)}
     */
    maturityDate: FormControl<Date | undefined>,

    /**
     * @description Next payment date of the loan (date of the next payment to be made)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<Date | undefined>)}
     */
    nextPaymentDate: FormControl<Date | undefined>,

    /**
     * @description Next payment amount of the loan (amount of the next payment to be made) in euros (€)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<number | undefined>)}
     */
    nextPaymentAmount: FormControl<number | undefined>,
};

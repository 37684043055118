<i
    [ngClass]="icon"
    class="content-info-icon {{ iconColor }}"
    [id]="popoverId"
></i>
<ion-popover class="omedom-popover" [trigger]="popoverId">
    <ng-template>
        <div></div>

        <div
            class="omedom-popover-container"
            [ngClass]="{ 'flex-column': iconTop }"
        >
            @if (withIcon) {
            <i [ngClass]="icon"></i>
            } @if (contentType === 'string') {
            <div class="omedom-popover-content">
                {{ content }}
            </div>
            } @if (contentType === 'object' && content) {
            <ul
                class="omedom-popover-content list-without-bullet-point"
                [ngClass]="{ 'omedom-popover-ul': !withMargin }"
            >
                @for (paragaraph of content; track paragaraph) {
                <li class="margin-top-1">{{ paragaraph }}<br /></li>
                }
            </ul>
            }
        </div>
        @if (displayButton && redirectionButton) {
        <button
            class="omedom-button-primary margin-right-1 margin-bottom-1 margin-left-1"
            (click)="buttonClicked()"
        >
            {{ redirectionButton.text }}
        </button>
        }
    </ng-template>
</ion-popover>

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { NotificationActions, NotificationEntity } from '@omedom/data';

@Component({
    selector: 'omedom-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnChanges {
    /**
     * @description List of notifications
     * @author Brisset Killian
     * @date 14/05/2024
     * @type {NotificationEntity[]}
     * @memberof NotificationListComponent
     */
    @Input() notifications: NotificationEntity[] = [];

    @Input() enableActions = true;

    /**
     * @description Emit when need to refresh data from parent component
     * @author Jérémie Lopez
     * @memberof NotificationListComponent
     */
    @Output()
    public refresh = new EventEmitter();

    @Output()
    public notificationActionClick = new EventEmitter<{
        action: NotificationActions;
        params?: { [key: string]: string | number | boolean | Date };
    }>();

    @Output()
    public openSetting = new EventEmitter();

    /**
     * @description List of readed notifications
     * @author Brisset Killian
     * @date 14/05/2024
     * @protected
     * @type {NotificationEntity[]}
     * @memberof NotificationListComponent
     */
    protected readedNotifications: NotificationEntity[] = [];

    /**
     * @description List of unread notifications
     * @author Brisset Killian
     * @date 14/05/2024
     * @protected
     * @type {NotificationEntity[]}
     * @memberof NotificationListComponent
     */
    protected unreadNotifications: NotificationEntity[] = [];

    ngOnChanges() {
        this.readedNotifications = this.getReadNotifications();
        this.unreadNotifications = this.getUnreadNotifications();
    }

    getUnreadNotifications(): NotificationEntity[] {
        return (this.notifications ?? []).filter((notification) => !notification.isRead);
    }

    getReadNotifications(): NotificationEntity[] {
        return (this.notifications ?? []).filter((notification) => notification.isRead);
    }
}

<!-- <div class="stars margin-bottom-2" (mouseleave)="resetHover()">
    @for ( index of stars; track index) { @if (!rate || rate === 0) {
    <span
        class="star pointer"
        [class.filled]="index < hoverIndex && !readOnly"
        (mouseenter)="onHover(index + 1)"
        (click)="onClick(index + 1)"
    >
        ★
    </span>
    } @if (rate && rate > 0) {
    <span class="star" [class.filled]="index < rate"> ★ </span>
    } }
</div> -->

<div class="stars margin-bottom-2" (mouseleave)="resetHover()">
    @for ( index of stars; track index) { @if ( !readOnly) {
    <span
        class="star pointer"
        [class.filled]="index < hoverIndex || (rate && index < rate)"
        (mouseenter)="onHover(index + 1)"
        (click)="onClick(index + 1)"
    >
        ★
    </span>
    } @if (rate && readOnly) {
    <span
        class="star"
        [class.filled]="index < rate"
        (mouseenter)="onHover(index + 1)"
        (click)="onClick(index + 1)"
    >
        ★
    </span>
    } }
</div>

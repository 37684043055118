/**
 * @description Minimal stuff in each Bridge entity
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 01/03/2024
 * @export
 * @interface Bridge
 */
export interface Bridge {
    /**
     * @description The ID of the stuff in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {number}
     * @memberof Bridge
     */
    bridgeID: number;
}

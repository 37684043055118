import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import {
    atticTypeOptions,
    DPEScoreOption,
    glazingTypeOptions,
    isolationRenovationPeriodOptions,
    MAX_BUILDING_YEAR,
    MIN_BUILDING_YEAR,
    MIN_LIVING_AREA,
    MissingDataHouseOptional,
    MissingDataHouseRequired,
    MissingDataApartementOptional,
    PropertyEntity,
    SelectOption,
} from '@omedom/data';
import { PropertyService } from '@omedom/services';
import { OmedomPricehubble, OmedomPricehubbleMissingData, OmedomRegex } from '@omedom/utils';
import { of } from 'rxjs';

@Component({
    selector: 'omedom-pricehubble-missing-data-modal',
    templateUrl: './pricehubble-missing-data-modal.component.html',
    styleUrls: ['./pricehubble-missing-data-modal.component.scss'],
})
export class PricehubbleMissingDataModalComponent implements OnInit {
    @Input({ required: true }) property!: PropertyEntity;

    @Input({ required: true }) missingRequiredData!: MissingDataHouseRequired;

    @Input({ required: true }) missingOptionalData!:
        | MissingDataHouseOptional
        | MissingDataApartementOptional;

    @Input()
    public missingDataInView: boolean = false;

    @Output() updatedProperty: EventEmitter<boolean> = new EventEmitter<boolean>();

    get missingDataOptionalHouse(): MissingDataHouseOptional {
        return this.missingOptionalData as MissingDataHouseOptional;
    }

    get missingDataOptionalAppartement(): MissingDataApartementOptional {
        return this.missingOptionalData as MissingDataApartementOptional;
    }

    minLivingSpace = MIN_LIVING_AREA;

    livingSpacePlaceholder = `${MIN_LIVING_AREA}m² min`;

    floorNumberLabel = 'Nombre total d’étage(s) immeuble';

    codepostalRegex = OmedomRegex.postalCodeRegex;

    propertyForm!: FormGroup;

    isMissingAddress = false;

    dpeScoreOptions$ = of(DPEScoreOption);

    dpeScorePlaceHolder: SelectOption = {
        id: null,
        label: 'Sélectionner une note',
    };

    isAppartement = false;
    isAssimilateHouse = false;

    isolationRenovationOptions = isolationRenovationPeriodOptions;
    atticTypeOptions = atticTypeOptions;
    glazingTypeOptions = glazingTypeOptions;

    constructor(
        private modalController: ModalController,
        private toastController: ToastController,
        private formBuilder: FormBuilder,
        private propertyService: PropertyService
    ) {}

    ngOnInit(): void {
        this.initPropertyForm();
        this.isMissingAddress = OmedomPricehubbleMissingData.hasMissingProperty(
            this.missingRequiredData.address
        );

        this.isAppartement = OmedomPricehubble.isPropertyAssimilateAppartement(this.property?.type);

        this.isAssimilateHouse = OmedomPricehubble.isPropertyAssimilateHouse(this.property?.type);
        if (this.isAssimilateHouse) {
            this.floorNumberLabel = 'Nombre de niveaux';
        }
    }

    private initPropertyForm() {
        this.propertyForm = this.formBuilder.group(
            {
                buildingYear: [
                    this.property?.buildingYear ?? null,
                    [
                        Validators.required,
                        Validators.max(MAX_BUILDING_YEAR),
                        Validators.min(MIN_BUILDING_YEAR),
                    ],
                ],
                livingSpace: [this.property?.livingSpace ?? null, [Validators.required]],
                landArea: [this.property?.landArea ?? null, []],
                address: this.formBuilder.group({
                    streetNumber: [
                        this.property?.address?.streetNumber ?? null,
                        [Validators.required],
                    ],
                    suffixNumber: [this.property?.address?.suffixNumber ?? null],
                    street: [this.property?.address?.street ?? '', [Validators.required]],
                    addressLine2: [this.property?.address?.addressLine2 ?? '', []],
                    postalCode: [this.property?.address?.postalCode ?? '', [Validators.required]],
                    city: [this.property?.address?.city ?? '', [Validators.required]],
                }),
                roomCount: [this.property?.roomCount ?? null, []],
                floor: [this.property?.floor ?? null, []],
                floorNumber: [this.property?.floorNumber ?? null, []],
                hasLift: [this.property?.amenities?.includes('Ascenseur') ?? false, []],
                hasPool: [this.property.amenities?.includes('Piscine') ?? false, []],
                dpeDate: [this.property?.dpeDetails?.dpeDate ?? null, []],
                dpeScore: [this.property.dpeDetails?.dpeScore ?? null, []],
                numberOfWindows: [
                    this.property?.dpeDetails?.estimationInformations?.numberOfWindows ?? null,
                    [],
                ],
                numberOfExternalFacingWalls: [
                    this.property?.dpeDetails?.estimationInformations
                        ?.numberOfExternalFacingWalls ?? null,
                    [],
                ],
                isolationRenovationPeriod: [
                    this.property?.dpeDetails?.estimationInformations?.isolationRenovationPeriod ??
                        null,
                    [],
                ],
                atticType: [
                    this.property?.dpeDetails?.estimationInformations?.atticType ?? null,
                    [],
                ],
                glazingType: [
                    this.property?.dpeDetails?.estimationInformations?.glazingType ?? null,
                    [],
                ],
            },
            { validators: [this.floorValidator(), this.spaceValidator()] }
        );
    }

    public get addressForm(): FormGroup {
        return this.propertyForm.get('address') as FormGroup;
    }

    public async updateProperty(): Promise<void> {
        try {
            this.property.buildingYear = this.propertyForm.get('buildingYear')?.value;
            this.property.livingSpace = this.propertyForm.get('livingSpace')?.value;
            this.property.landArea = this.propertyForm.get('landArea')?.value;
            this.property.address = {
                streetNumber: this.addressForm.get('streetNumber')?.value,
                suffixNumber: this.addressForm.get('suffixNumber')?.value,
                street: this.addressForm.get('street')?.value,
                addressLine2: this.addressForm.get('addressLine2')?.value,
                postalCode: this.addressForm.get('postalCode')?.value,
                city: this.addressForm.get('city')?.value,
            };
            this.property.roomCount = this.propertyForm.get('roomCount')?.value;
            this.property.floor = this.propertyForm.get('floor')?.value;
            this.property.floorNumber = this.propertyForm.get('floorNumber')?.value;

            const hasLift = this.propertyForm.get('hasLift')?.value;
            if (hasLift && !this.property.amenities?.includes('Ascenseur')) {
                // I check if acsenseur is already present, otherwise, it creates duplicates
                this.property.amenities?.push('Ascenseur');
            }

            const hasPool = this.propertyForm.get('hasPool')?.value;
            if (hasPool && !this.property.amenities?.includes('Piscine')) {
                this.property.amenities?.push('Piscine');
            }

            this.property.dpeDetails = {
                dpeDate: this.propertyForm.get('dpeDate')?.value,
                dpeScore: this.propertyForm.get('dpeScore')?.value ?? null,
                estimationInformations: {
                    numberOfWindows: this.propertyForm.get('numberOfWindows')?.value,
                    numberOfExternalFacingWalls: this.propertyForm.get(
                        'numberOfExternalFacingWalls'
                    )?.value,
                    isolationRenovationPeriod: this.propertyForm.get('isolationRenovationPeriod')
                        ?.value,
                    atticType: this.propertyForm.get('atticType')?.value,
                    glazingType: this.propertyForm.get('glazingType')?.value,
                },
            };

            this.propertyService.update(this.property);
            if (this.missingDataInView) {
                this.updatedProperty.emit(true);
            } else {
                this.dismissModal(true);
            }
        } catch (error) {
            console.error(error);
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });

            if (this.missingDataInView) {
                this.updatedProperty.emit(false);
            } else {
                this.dismissModal(false);
            }

            await toast.present();
        }
    }

    public async close(): Promise<void> {
        if (this.missingDataInView) {
            this.updatedProperty.emit(false);
        } else {
            this.dismissModal(false);
        }
    }

    private async dismissModal(emailIsSendable: boolean): Promise<void> {
        await this.modalController.dismiss(emailIsSendable);
    }

    public floorValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const buildingFloors = control.get('floorNumber');
            const apartmentFloor = control.get('floor');

            if (buildingFloors && apartmentFloor) {
                const buildingFloorsValue = buildingFloors.value;
                const apartmentFloorValue = apartmentFloor.value;

                if (apartmentFloorValue > buildingFloorsValue) {
                    this.displayErrorToast(
                        "L'étage de votre appartement ne peut pas dépasser le nombre d'étages de l'immeuble"
                    );
                    return { floorInvalid: true };
                }
            }

            return null;
        };
    }

    public spaceValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (OmedomPricehubble.isPropertyAssimilateAppartement(this.property?.type)) {
                return null;
            }
            const livingSpace = control.get('livingSpace');
            const landArea = control.get('landArea');

            if (livingSpace && landArea) {
                const livingSpaceValue = livingSpace.value;
                const landAreaValue = landArea.value;

                if (livingSpaceValue > landAreaValue) {
                    this.displayErrorToast(
                        'La surface du terrain doit être égale ou supérieure à celle de la surface habitable'
                    );
                    return { spaceInvalid: true };
                }
            }

            return null;
        };
    }

    private async displayErrorToast(message: string) {
        const toast = await this.toastController.create({
            position: 'top',
            color: 'danger',
            duration: 5000,
            message: message,
        });

        await toast.present();
    }
}

import { Component, Input } from '@angular/core';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { SubscriptionEntity } from '@omedom/data';
import { SubscriptionService } from '@omedom/services';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'omedom-subscription-confirm-cancellation',
    templateUrl: './subscription-confirm-cancellation.component.html',
    styleUrls: ['./subscription-confirm-cancellation.component.scss'],
})
export class SubscriptionConfirmCancellationComponent {
    @Input({ required: true }) subscription!: SubscriptionEntity;

    pending$ = new BehaviorSubject<boolean>(false);

    @Input({ required: true }) backUrl: string = '/tabs/menu/subscription';

    constructor(
        private modalController: ModalController,
        private subscriptionService: SubscriptionService,
        private navController: NavController,
        private toastController: ToastController
    ) { }

    public dismiss(): void {
        this.modalController.dismiss();
    }

    public async cancelSubscription(): Promise<void> {
        this.pending$.next(true);
        await this.subscriptionService.cancelSubscription(this.subscription.uid);

        if (this.subscription.isApple) {
            // Redirect to subscription management page on apple device
            window.location.href = 'https://apps.apple.com/account/subscriptions';
        }

        this.subscriptionService.updateLocalSubscription().then(async () => {
            await this.modalController.dismiss();
            const toast = await this.toastController.create({
                position: 'top',
                message: `Votre abonnement ${this.subscription.rank ? this.subscription.rank.toUpperCase() : 'SMART'} prendra fin à compter du ${new Date(this.subscription.renewDate).toLocaleDateString()}`,
                duration: 5500,
                color: 'success',
            });

            await toast.present();

            await this.navController.navigateBack(this.backUrl);

            this.pending$.next(false);
        });
    }
}

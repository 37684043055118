import { RestEntity } from '../rest.entity';

export class ChatMessageEntity extends RestEntity {
    /**
     * @description The content of the message. Only text is supported for now.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/08/2024
     * @type {string}
     * @memberof ChatMessageEntity
     */
    content!: string;

    /**
     * @description UID of the user who sent the message
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/08/2024
     * @type {string}
     * @memberof ChatMessageEntity
     */
    userUID!: string;

    /**
     * @description UID of the chat where the message was sent
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/08/2024
     * @type {string}
     * @memberof ChatMessageEntity
     */
    chatUID!: string;

    /**
     * @description UIDs of the users who read the message
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/08/2024
     * @type {string[]}
     * @memberof ChatMessageEntity
     */
    readByUserUIDs?: string[];
}

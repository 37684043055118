import { DecimalPipe } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ChargeEntity, IncomeEntity, OmedomDateType, PropertyEntity } from '@omedom/data';
import { OmedomChart, OmedomDate, OmedomTreasury } from '@omedom/utils';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { OmedomChartLegend } from '../../components/chart-legend/omedom-chart-legend';
import { OmedomNumberPipe } from '../../pipes/number.pipe';

@Component({
    selector: 'omedom-liquidity',
    templateUrl: './liquidity.component.html',
    styleUrls: ['./liquidity.component.scss'],
})
export class LiquidityComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild(BaseChartDirective) baseChart?: BaseChartDirective;

    @Input()
    public showDate = true;

    /**
     * @description Used to display the forecast on the year if user has the good subscription plan
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 19/06/2024
     * @memberof ChartLegendComponent
     */
    @Input()
    forecastAuthorised = false;

    @Input()
    get charges(): ChargeEntity[] {
        return this._charges ?? [];
    }

    set charges(value: ChargeEntity[]) {
        this._charges = value;
        this.dataChange$.next(null);
    }

    @Input()
    get incomes(): IncomeEntity[] {
        return this._incomes ?? [];
    }

    set incomes(value: IncomeEntity[]) {
        this._incomes = value;
        this.dataChange$.next(null);
    }

    @Input() property?: PropertyEntity;

    @Output() forecastClicked = new EventEmitter<void>();

    omedomDateType = OmedomDateType;

    chartLegends: OmedomChartLegend[] = [
        new OmedomChartLegend({
            label: 'Trésorerie mensuelle',
            color: OmedomChart.gold,
        }),
        new OmedomChartLegend({
            label: 'Trésorerie cumulée',
            color: OmedomChart.blue,
        }),
        new OmedomChartLegend({
            label: 'Prévisionnel',
            color: OmedomChart.red,
            previsionLabel: true,
        }),
        new OmedomChartLegend({
            label: 'Revenus mensuels',
            color: OmedomChart.green,
        }),
        new OmedomChartLegend({
            label: 'Charges mensuelles',
            color: OmedomChart.red,
        }),
    ];

    totalCharge = 0;

    totalIncome = 0;

    datasetIncome: ChartDataset<'bar'> = {
        data: [],
        backgroundColor: [OmedomChart.green],
        borderColor: 'transparent',
        order: 2,
        hoverBackgroundColor: [OmedomChart.green],
        borderWidth: 0,
        barThickness: 8,
        borderRadius: 8,
    };

    datasetCharge: ChartDataset<'bar'> = {
        data: [],
        backgroundColor: [OmedomChart.red],
        borderColor: 'transparent',
        order: 2,
        hoverBackgroundColor: [OmedomChart.red],
        borderWidth: 0,
        barThickness: 8,
        borderRadius: 8,
    };

    datasetTreasuryByMonth: ChartDataset<'line'> = {
        data: [],
        type: 'line',
        backgroundColor: [OmedomChart.gold],
        borderColor: OmedomChart.gold,
        order: 1,
        hoverBackgroundColor: [OmedomChart.gold],
        pointStyle: 'rectRounded',
        borderWidth: 5,
        pointRadius: 2.5,
        pointBorderWidth: 0,
        pointBackgroundColor: OmedomChart.gold,
    };

    datasetTreasuryCumulated: ChartDataset<'line'> = {
        data: [],
        type: 'line',
        backgroundColor: [OmedomChart.blue],
        borderColor: OmedomChart.blue,
        order: 1,
        hoverBackgroundColor: [OmedomChart.blue],
        pointStyle: 'rectRounded',
        borderWidth: 5,
        pointRadius: 2.5,
        pointBorderWidth: 0,
        pointBackgroundColor: OmedomChart.blue,
    };

    datasetIncomeProjection: ChartDataset<'bar'> = {
        data: [],
        backgroundColor: ['rgba(0, 194, 154, 0.3)'],
        borderColor: 'transparent',
        order: 2,
        hoverBackgroundColor: ['rgba(0, 194, 154, 0.3)'],
        borderWidth: 0,
        barThickness: 8,
        borderRadius: 8,
    };

    datasetChargeProjection: ChartDataset<'bar'> = {
        data: [],
        backgroundColor: ['rgba(255, 86, 65, 0.3)'],
        borderColor: 'transparent',
        order: 2,
        hoverBackgroundColor: ['rgba(255, 86, 65, 0.3)'],
        borderWidth: 0,
        barThickness: 8,
        borderRadius: 8,
    };

    datasetTreasuryByMonthProjection: ChartDataset<'line'> = {
        data: [],
        type: 'line',
        backgroundColor: [OmedomChart.gold],
        borderColor: OmedomChart.gold,
        order: 1,
        hoverBackgroundColor: [OmedomChart.gold],
        pointStyle: 'rectRounded',
        borderWidth: 5,
        pointRadius: 2.5,
        pointBorderWidth: 0,
        pointBackgroundColor: OmedomChart.gold,
        borderDash: [5, 5],
    };

    datasetTreasuryCumulatedProjection: ChartDataset<'line'> = {
        data: [],
        type: 'line',
        backgroundColor: [OmedomChart.blue],
        borderColor: OmedomChart.blue,
        order: 1,
        hoverBackgroundColor: [OmedomChart.blue],
        pointStyle: 'rectRounded',
        borderWidth: 5,
        pointRadius: 2.5,
        pointBorderWidth: 0,
        pointBackgroundColor: OmedomChart.blue,
        borderDash: [5, 5],
    };

    chartData: ChartData = {
        labels: [
            'Jan',
            'Fev',
            'Mars',
            'Avr',
            'Mai',
            'Juin',
            'Juil',
            'Aout',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ],
        datasets: [
            this.datasetIncome,
            this.datasetCharge,
            this.datasetTreasuryByMonth,
            this.datasetTreasuryCumulated,
            this.datasetIncomeProjection,
            this.datasetChargeProjection,
            this.datasetTreasuryByMonthProjection,
            this.datasetTreasuryCumulatedProjection,
        ],
    };

    chartOptions: ChartOptions = {
        aspectRatio: 1,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                backgroundColor: 'white',
                cornerRadius: 10,
                caretSize: 0,
                bodyColor: '#04151c',
                titleColor: '#04151c',
                bodyAlign: 'center',
                bodyFont: {
                    family: 'Asap',
                    size: 18,
                    lineHeight: 1.1,
                    weight: '700',
                },
                borderWidth: 1.5,
                usePointStyle: true,
                padding: 10,
                callbacks: {
                    label: (context) => {
                        const labels: string[] = [];
                        let rawAmount;
                        if (typeof context?.raw == 'number') {
                            rawAmount = context?.raw;
                        } else {
                            // eslint-disable-next-line @typescript-eslint/dot-notation
                            rawAmount = (context?.raw as any)['y'];
                        }
                        const amount = this.omedomNumberPipe.transform(
                            this.decimalPipe.transform(rawAmount.toString(), '0.0-2') ?? 0
                        );

                        labels.push(context.label);
                        labels.push(amount + '€');
                        return labels;
                    },
                    title: () => '',
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: '#04151C',
                    font: {
                        family: 'Asap',
                        size: 14,
                        lineHeight: 1.1,
                        weight: '700',
                    },
                },
                grid: {
                    lineWidth: 0,
                    drawBorder: false,
                },
            },
            y: {
                ticks: {
                    color: '#04151C',
                    font: {
                        family: 'Asap',
                        size: 14,
                        lineHeight: 1.1,
                        weight: '700',
                    },
                },
                grid: {
                    color: 'white',
                    lineWidth: 2,
                    drawBorder: false,
                },
            },
        },
    };

    private dataChange$ = new Subject();

    private selectedYear?: number;

    private subscription?: Subscription;

    private _charges?: ChargeEntity[];

    private _incomes?: IncomeEntity[];

    constructor(private decimalPipe: DecimalPipe, private omedomNumberPipe: OmedomNumberPipe) {}

    ngOnInit(): void {
        this.selectedYear = new Date().toUTC().getUTCFullYear();

        this.subscription = this.dataChange$
            .pipe(debounceTime(123))
            .subscribe(() => this.updateGraphData());
    }

    ngOnChanges(): void {
        this.updateGraphData();
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    dateChange(date: Date) {
        this.selectedYear = date.getUTCFullYear();
        this.updateGraphData();
    }

    private getLabel(month: number) {
        const labels = [
            'Jan',
            'Fev',
            'Mars',
            'Avr',
            'Mai',
            'Juin',
            'Juil',
            'Aout',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        return labels[month];
    }

    private updateGraphData(): void {
        let now = new Date().toUTC();
        let currentDate = new Date(Date.UTC(this.selectedYear ?? now.getUTCFullYear(), 0, 1));

        let lastMonth = new Date().toUTC();
        const amountCharges: number[] = [];
        const amountIncomes: number[] = [];
        const treasuryByMonth: number[] = [];
        const treasuryCumulated: number[] = [];

        const amountChargesProjection = [];
        const amountIncomesProjection = [];
        const treasuryByMonthProjection = [];
        const treasuryCumulatedProjection = [];

        lastMonth = lastMonth.subUTCMonths(1);
        while (
            currentDate.getUTCFirstDayOfMonth().getTime() <=
                lastMonth.getUTCFirstDayOfMonth().getTime() &&
            currentDate.getUTCFullYear() === this.selectedYear
        ) {
            const endDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0,
                23,
                59,
                59
            );

            const monthCharges = OmedomTreasury.filterTreasury(
                this.charges,
                currentDate,
                endDate
            ).filter((x) => OmedomTreasury.isTreasuryPayed(x, currentDate, endDate));
            const monthChargeAmount = monthCharges?.length
                ? monthCharges.sumBy((x) => -OmedomTreasury.getMonthAmount(x, currentDate))
                : undefined;
            amountCharges.push(monthChargeAmount ?? 0);

            const monthIncomes = OmedomTreasury.filterTreasury(
                this.incomes,
                currentDate,
                endDate
            ).filter((x) => OmedomTreasury.isTreasuryPayed(x, currentDate, endDate));
            const monthIncomeAmount = monthIncomes?.length
                ? monthIncomes.sumBy((x) => OmedomTreasury.getMonthAmount(x, currentDate))
                : undefined;
            amountIncomes.push(monthIncomeAmount ?? 0);

            const monthTreasury =
                (!!monthIncomeAmount ? monthIncomeAmount : 0) +
                (!!monthChargeAmount ? monthChargeAmount : 0);
            treasuryCumulated.push(treasuryByMonth.sum() + monthTreasury);
            treasuryByMonth.push(monthTreasury);

            currentDate = currentDate.addUTCMonths(1);
        }
        let startWithCumul = null;
        if (this.selectedYear === lastMonth.getUTCFullYear()) {
            const label = this.getLabel(lastMonth.getMonth());
            startWithCumul = treasuryCumulated[treasuryCumulated.length - 1];
            treasuryCumulatedProjection.push({ x: label, y: startWithCumul });
            treasuryByMonthProjection.push({
                x: label,
                y: treasuryByMonth[treasuryByMonth.length - 1],
            });
        }
        if (this.forecastAuthorised) {
            // Prepare forecast treausury for one year
            const startProjectionWithCumul = startWithCumul;

            while (currentDate.getUTCFullYear() === this.selectedYear) {
                const endDate = currentDate.getUTCLastDayOfMonth();
                const label = this.getLabel(currentDate.getMonth());
                const monthCharges = OmedomTreasury.filterTreasury(
                    this.charges,
                    currentDate,
                    endDate
                );
                const monthChargeAmount = monthCharges?.length
                    ? monthCharges.sumBy((x) => -OmedomTreasury.getMonthAmount(x, currentDate))
                    : undefined;
                amountChargesProjection.push({
                    x: label,
                    y: monthChargeAmount,
                });

                const monthIncomes = OmedomTreasury.filterTreasury(
                    this.incomes,
                    currentDate,
                    endDate
                );
                const monthIncomeAmount = monthIncomes?.length
                    ? monthIncomes.sumBy((x) => OmedomTreasury.getMonthAmount(x, currentDate))
                    : undefined;
                amountIncomesProjection.push({
                    x: label,
                    y: monthIncomeAmount,
                });

                const monthTreasury =
                    (!!monthIncomeAmount ? monthIncomeAmount : 0) +
                    (!!monthChargeAmount ? monthChargeAmount : 0);
                treasuryByMonthProjection.push({ x: label, y: monthTreasury });

                if (startWithCumul) {
                    treasuryCumulatedProjection.push({
                        x: label,
                        y: startWithCumul + monthTreasury,
                    });
                    startWithCumul = null;
                } else {
                    const cumul: number = treasuryCumulatedProjection.length
                        ? monthTreasury +
                          treasuryCumulatedProjection[treasuryCumulatedProjection.length - 1].y
                        : monthTreasury + (startProjectionWithCumul ?? 0);
                    treasuryCumulatedProjection.push({ x: label, y: cumul });
                }

                currentDate = currentDate.addUTCMonths(1);
            }
        } else if (currentDate.getUTCFullYear() === this.selectedYear) {
            // Prepare forecast treausury for one month

            // const endDate = currentDate.getUTCLastDayOfMonth();
            const endDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0,
                23,
                59,
                59
            );

            const label = this.getLabel(currentDate.getMonth());

            const monthCharges = OmedomTreasury.filterTreasury(this.charges, currentDate, endDate);
            const monthChargeAmount = monthCharges?.length
                ? monthCharges.sumBy((x) => -OmedomTreasury.getMonthAmount(x, currentDate))
                : undefined;
            amountChargesProjection.push({ x: label, y: monthChargeAmount });

            const monthIncomes = OmedomTreasury.filterTreasury(this.incomes, currentDate, endDate);
            const monthIncomeAmount = monthIncomes?.length
                ? monthIncomes.sumBy((x) => OmedomTreasury.getMonthAmount(x, currentDate))
                : undefined;
            amountIncomesProjection.push({ x: label, y: monthIncomeAmount });

            const monthTreasury =
                (!!monthIncomeAmount ? monthIncomeAmount : 0) +
                (!!monthChargeAmount ? monthChargeAmount : 0);
            treasuryCumulatedProjection.push({
                x: label,
                y: treasuryCumulatedProjection.map((x) => x.y).sum() + monthTreasury,
            });
            treasuryByMonthProjection.push({ x: label, y: monthTreasury });

            currentDate = currentDate.addUTCMonths(1);
        }

        this.chartData.datasets[0].data = amountIncomes;
        this.chartData.datasets[1].data = amountCharges;
        this.chartData.datasets[2].data = treasuryByMonth;
        this.chartData.datasets[3].data = treasuryCumulated;
        this.chartData.datasets[4].data = amountIncomesProjection as any;
        this.chartData.datasets[5].data = amountChargesProjection as any;
        this.chartData.datasets[6].data = treasuryByMonthProjection as any;
        this.chartData.datasets[7].data = treasuryCumulatedProjection as any;

        this.totalIncome = amountIncomes.filter((x) => !!x).sum();
        this.totalCharge = amountCharges.filter((x) => !!x).sum();

        this.baseChart?.update();
    }
}

@if (enableFilter) {
<omedom-button-toggle
    [options]="options"
    [(ngModel)]="filter"
    (ngModelChange)="getTransactionByDay()"
></omedom-button-toggle>
} @if (introduction) {
<omedom-info
    [information]="introduction"
    iconStart="uil-info-circle"
    class="omedom-no-background w-100"
></omedom-info>
}

<div class="bank-transaction-container" [@list]="transactionByDay.length">
    @switch (state$ | async) { @case ('pending') {
    <ng-container *ngTemplateOutlet="pending"></ng-container>
    } @default {
    <ng-container
        [ngTemplateOutlet]="transactionByDay.length > 0 ? content : empty"
    ></ng-container>
    } }
</div>

<ng-template #content>
    @if (selectable && isMultiple) {
    <omedom-checkbox
        class="w-100 margin-bottom-2"
        [(ngModel)]="selectAll"
        (ngModelChange)="toggleSelectAll($event)"
        >Tout sélectionner</omedom-checkbox
    >
    } @for (timeline of transactionByDay; track timeline.day; let i = $index) {
    <omedom-timeline
        [class.margin-top-2]="i !== 0"
        [date]="timeline.day"
        [@list]="transactionByDay.length"
    >
        @for (transaction of timeline.transactions; track
        trackByTransaction($index, transaction)) {
        <div [@transaction]="timeline.transactions.length">
            <omedom-bank-transaction
                [account]="account"
                [transaction]="transaction"
                [showActions]="showActions"
                [selectable]="selectable"
                [value]="isSelected(transaction)"
                (onSelect)="selectTransaction(transaction, timeline.day)"
                (onAssociate)="associateTransaction(transaction)"
                (onDissociate)="dissociateTransaction(transaction)"
            ></omedom-bank-transaction>
        </div>
        }
    </omedom-timeline>
    }
</ng-template>

<ng-template #empty>
    <div class="empty-container" @element>
        <i class="uil uil-{{ icon }} empty-icon"></i>
        <span class="empty-text">{{ message }}</span>
    </div>
</ng-template>

<ng-template #pending>
    <div class="empty-container" @element>
        <i class="uil uil-sync empty-icon"></i>
        <span class="empty-text text-center">Chargement des mouvements...</span>
        <ion-spinner></ion-spinner>
    </div>
</ng-template>

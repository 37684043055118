@if (label) {
<div
    class="omedom-form-label"
    [ngStyle]="{ 'font-weight': boldLabel ? '700' : '500' }"
>
    {{ label }}
    @if (required) {
    <sup>*</sup>
    } @if (warning) {
    <omedom-warning-popover
        [content]="warning"
        class="margin-left-2"
    ></omedom-warning-popover>
    }
</div>
} @if (type !== 'file') {
<ion-item class="omedom-form-item" [class]="'omedom-form-item-' + mode">
    @if (icon) {
    <ion-label>
        <i [class]="icon"></i>
    </ion-label>
    } @if (!isTextarea) {
    <ion-input
        [placeholder]="placeholder"
        [name]="name"
        [type]="enableShowPassword ? (showPassword ? 'text' : type) : type"
        [ngModel]="type === 'date' ? dateString : value"
        (ngModelChange)="valueChange($event)"
        (ionFocus)="isFocused.next(true)"
        [disabled]="isDisabled"
        [debounce]="debounce"
        [pattern]="pattern"
        [min]="min"
        [max]="max"
        [maxlength]="maxLength"
        [autocomplete]="autocomplete"
        [required]="required"
        [inputmode]="inputmode"
    ></ion-input>
    } @if (isTextarea) {
    <ion-textarea
        [placeholder]="placeholder"
        [rows]="rows ?? 9"
        [name]="name"
        [ngModel]="value"
        (ngModelChange)="valueChange($event)"
        [disabled]="isDisabled"
        [debounce]="debounce"
        [pattern]="pattern"
        [required]="required"
    ></ion-textarea>
    } @if (iconEnd) {
    <a (click)="iconEndClick.emit()" slot="end">
        <i [class]="iconEnd"></i>
    </a>
    } @if (enableShowPassword) {
    <a
        (click)="showPassword = !showPassword"
        style="cursor: pointer"
        slot="end"
    >
        <i [class]="showPassword ? 'uil uil-eye-slash' : 'uil uil-eye'"></i>
    </a>
    }
</ion-item>
} @else {
<div class="omedom-file-container">
    <input
        [name]="name"
        [id]="name"
        style="display: none"
        type="file"
        [accept]="accept"
        (change)="keepFile($event.target)"
    />
    <label class="omedom-button-primary" [for]="name">
        <i class="uil uil-cloud-upload"></i>
        <span>Choisir un fichier</span>
    </label>
    @if (value) {
    <span class="omedom-form-file-name">
        {{ value.name ?? value }}
    </span>
    }
</div>
}

<ng-template #file>
    <div class="omedom-file-container">
        <input
            [name]="name"
            [id]="name"
            style="display: none"
            type="file"
            [accept]="accept"
            (change)="keepFile($event.target)"
        />

        <label class="omedom-button-primary" [for]="name">
            <i class="uil uil-cloud-upload"></i>
            <span>Choisir un fichier</span>
        </label>

        @if (value) {
        <span class="omedom-form-file-name">
            {{ value.name ?? value }}
        </span>
        }
    </div>
</ng-template>

@if (error) {
<span class="omedom-form-error">{{ error }}</span>
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoleEnum } from '@omedom/data';
import { UserService } from '@omedom/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProGuard {
    constructor(private userService: UserService, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.userService.user$.pipe(
            map((user) => {
                if (user && user.role.indexOf(RoleEnum.pro) !== -1) {
                    return true;
                } else {
                    this.router.navigate(['/user/login']);
                    return false;
                }
            })
        );
    }
}

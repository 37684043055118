import { ChargeEntity, IncomeEntity, PropertyEntity, SocietyEntity } from "../../entities";

/**
 * @description StoryParams interface for story object params
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 17/11/2023
 * @export
 * @interface StoryParams
 */
export interface StoryParams {
    charges: ChargeEntity[];
    incomes: IncomeEntity[];
    assets: (SocietyEntity | PropertyEntity)[];
}

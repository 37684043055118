import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { UserService } from '@omedom/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TemporaryPasswordGuard {
    constructor(
        private userService: UserService,
        private router: Router,
        private toastController: ToastController
    ) { }

    canActivate(): Observable<boolean> {
        return this.userService.user$.pipe(
            map((user) => {
                if (user && !user.hasTemporaryPassword) {
                    return true;
                } else {
                    // Display toast
                    this.toastController.create({
                        message: 'Vous devez changer votre mot de passe temporaire avant de continuer.',
                        duration: 3000,
                        color: 'danger',
                    }).then((toast) => {
                        toast.present();
                    });

                    this.router.navigate(['/user/change-password']);
                    return false;
                }
            })
        );
    }
}

<div class="omedom-dropdown-button">
    <ng-content select="[label]"></ng-content>
    <button
        #dropdownButton
        class="omedom-icon-button-primary margin-left-2"
        [class.transparent]="isButtonBackgroundTransparent"
        style="margin-right: 0"
        (click)="toggleVisibility()"
    >
        <i #dropdownIcon [class]="iconClass" style="display: inline-block"></i>
    </button>
</div>
<div
    #omedomDropdown
    class="dropdown-container-list"
    [class.only-buttons]="onlyButtons"
    [hidden]="!isDropdownVisible"
>
    <ng-content select="[content]"></ng-content>
</div>

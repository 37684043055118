import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Platform, ToastController, ModalController } from '@ionic/angular';
import { SubscriptionService, UserService } from '@omedom/services';
import { TwoFactorValidationComponent } from '../../components/two-factor-validation/two-factor-validation.component';
import { MultiFactorAuthentification, Mode } from '@omedom/data';
import { OmedomEnvironment } from '@omedom/environment';

interface SignInForm {
    email: FormControl<string>;
    password: FormControl<string>;
}

@Component({
    selector: 'omedom-user-signin',
    templateUrl: './user-signin.component.html',
    styleUrls: ['./user-signin.component.scss'],
})
export class UserSigninComponent {
    /**
     * @description Form Group to signin
     * @author Jérémie Lopez
     * @type {FormGroup}
     * @memberof UserSigninComponent
     */
    public signinForm: FormGroup<SignInForm> = new FormGroup<SignInForm>({
        email: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required, Validators.email],
        }),
        password: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required],
        }),
    });

    @Input()
    public environment?: typeof OmedomEnvironment.production | typeof OmedomEnvironment.development;

    /**
     * @description Pending state (default: false) (optional)
     * @author Brisset Killian
     * @date 30/04/2024
     * @type {boolean}
     * @memberof UserSigninComponent
     */
    public pending: boolean = false;

    @Input()
    mode: Mode = Mode.app;

    constructor(
        private userService: UserService,
        private toast: ToastController,
        private router: Router,
        private platform: Platform,
        private modalController: ModalController,
        private subscriptionService: SubscriptionService
    ) {}

    public get isAppleSignInAvailable() {
        // get if we are on android app but we can android web
        return (
            !(this.platform.is('android') && this.platform.is('mobile')) ||
            this.platform.is('ios') ||
            this.platform.is('mobileweb')
        );
    }

    /**
     * @description Login with Google
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof UserSigninComponent
     */
    public async loginGoogle(): Promise<void> {
        try {
            await this.userService.loginGoogle();

            this.router.navigate(['/']);

            const controller = await this.toast.create({
                position: 'top',
                color: 'primary',
                duration: 3000,
                message: `Vous êtes à présent connecté !`,
            });

            await controller.present();
        } catch (error: any) {
            console.error(error);
            if (error?.error === 'popup_closed_by_user') {
                return;
            }
            const code = error?.code;

            switch (code) {
                case 'auth/cancelled-popup-request':
                    break;
                case 'auth/user-disabled':
                    const disabledCtrl = await this.toast.create({
                        position: 'top',
                        color: 'warning',
                        duration: 7000,
                        message: `
                        Votre compte a été désactivé. Il sera supprimé 30 jours après la demande de suppression. <br>
                        <strong>Pour annuler cette demande</strong>, cliquez sur le bouton "Annuler" dans le mail de confirmation que vous avez reçu.
                        `,
                    });

                    await disabledCtrl.present();
                    break;

                default:
                    const errorCtrl = await this.toast.create({
                        position: 'top',
                        color: 'danger',
                        duration: 4000,
                        message: `Une erreur s'est produite, veuillez réessayer plus tard.`,
                    });

                    await errorCtrl.present();
                    break;
            }
        }
    }

    /**
     * @description Login with Apple
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof UserSigninComponent
     */
    public async loginApple(): Promise<void> {
        try {
            await this.userService.loginApple(this.environment?.production ?? false);
            this.router.navigate(['/']);

            const controller = await this.toast.create({
                position: 'top',
                color: 'primary',
                duration: 3000,
                message: `Vous êtes à présent connecté !`,
            });

            await controller.present();
        } catch (error: any) {
            const code = error?.code;

            switch (code) {
                case 'auth/cancelled-popup-request':
                    break;
                case 'auth/user-disabled':
                    const disabledCtrl = await this.toast.create({
                        position: 'top',
                        color: 'warning',
                        duration: 7000,
                        message: `
                        Votre compte a été désactivé. Il sera supprimé 30 jours après la demande de suppression. <br>
                        <strong>Pour annuler cette demande</strong>, cliquez sur le bouton "Annuler" dans le mail de confirmation que vous avez reçu.
                        `,
                    });

                    await disabledCtrl.present();
                    break;

                default:
                    const errorCtrl = await this.toast.create({
                        position: 'top',
                        color: 'danger',
                        duration: 4000,
                        message: `Une erreur s'est produite, veuillez réessayer plus tard.`,
                    });

                    await errorCtrl.present();
                    break;
            }
        }
    }

    // public async loginFacebook(): Promise<void> {
    //     try {
    //         await this.userService.loginFacebook();
    //         this.router.navigate(['/']);

    //         const controller = await this.toast.create({
    //             position: 'top',
    //             color: 'primary',
    //             duration: 3000,
    //             message: `Vous êtes à présent connecté !`,
    //         });

    //     await controller.present();
    // } catch (error) {
    //     const code = error?.code;

    //         switch (code) {
    //             case 'auth/user-disabled':
    //                 const disabledCtrl = await this.toast.create({
    //                     position: 'top',
    //                     color: 'warning',
    //                     duration: 7000,
    //                     message: `
    //                     Votre compte a été désactivé. Il sera supprimé 30 jours après la demande de suppression. <br>
    //                     <strong>Pour annuler cette demande</strong>, cliquez sur le bouton "Annuler" dans le mail de confirmation que vous avez reçu.
    //                     `,
    //                 });

    // await disabledCtrl.present();
    // break;

    //             default:
    //                 const errorCtrl = await this.toast.create({
    //                     position: 'top',
    //                     color: 'danger',
    //                     duration: 4000,
    //                     message: `Une erreur s'est produite, veuillez réessayer plus tard.`,
    //                 });

    //                 await errorCtrl.present();
    //                 break;
    //         }
    //     }
    // }

    /**
     * @description Try to login with email and password
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof UserSigninComponent
     */
    public async submit(): Promise<void> {
        const data = this.signinForm.getRawValue();
        // Set pending state
        this.pending = true;

        try {
            window.localStorage.setItem('firstVisit', 'false');
            await this.userService.loginEmailAndPassword(data.email, data.password);

            await this.subscriptionService.updateLocalSubscription();

            this.router.navigate(['/']);

            const controller = await this.toast.create({
                position: 'top',
                color: 'primary',
                duration: 3000,
                message: `Vous êtes à présent connecté !`,
            });

            await controller.present();
        } catch (error: any) {
            const code = error?.code;
            switch (code) {
                case 'popup_closed_by_user':
                    break;
                case 'auth/wrong-password':
                case 'auth/user-not-found':
                    const notFoundCtrl = await this.toast.create({
                        position: 'top',
                        color: 'warning',
                        duration: 4000,
                        message: `Email ou mot de passe incorrect.`,
                    });
                    this.signinForm.controls.email.setValue('');
                    this.signinForm.controls.password.setValue('');

                    await notFoundCtrl.present();
                    break;

                case 'auth/user-disabled':
                    const disabledCtrl = await this.toast.create({
                        position: 'top',
                        color: 'warning',
                        duration: 7000,
                        message: `
                        Votre compte a été désactivé. Il sera supprimé 30 jours après la demande de suppression. <br>
                        <strong>Pour annuler cette demande</strong>, dans le mail de confirmation que vous avez reçu, cliquez sur le bouton "Je souhaite continuer à utiliser Omedom".
                        `,
                    });

                    await disabledCtrl.present();
                    break;
                case 'auth/internal-error':
                    if (error.message) {
                        let json;
                        const start = error.message.indexOf('{');
                        const end = error.message.lastIndexOf('}');

                        if (start !== -1 && end !== -1 && start < end) {
                            try {
                                json = JSON.parse(error.message.substring(start, end + 1));
                            } catch (e) {
                                json = null;
                            }
                        }

                        if (
                            json &&
                            json.error &&
                            json.error.message === 'auth/multi-factor-auth-required' &&
                            json.error.details
                        ) {
                            try {
                                await this.modalController.getTop().then(async (modal) => {
                                    if (modal) {
                                        await modal.dismiss();
                                    }
                                });
                                const multiFactorDetails = json.error
                                    .details as MultiFactorAuthentification;

                                const modal = await this.modalController.create({
                                    component: TwoFactorValidationComponent,
                                    initialBreakpoint: 1,
                                    breakpoints: [0, 1],
                                    componentProps: {
                                        multiFactorDetails,
                                        userData: data,
                                    },
                                });

                                modal.present();
                                break;
                            } catch (error) {
                                console.error(error);
                            }
                        }
                    }
                    break;

                default:
                    const errorCtrl = await this.toast.create({
                        position: 'top',
                        color: 'danger',
                        duration: 4000,
                        message: `Une erreur s'est produite, veuillez réessayer plus tard.`,
                    });

                    await errorCtrl.present();
                    break;
            }
        }

        // Remove pending state
        this.pending = false;
    }
}

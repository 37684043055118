<div class="top-container">
    <ng-content select="[logo]"></ng-content>

    <div class="sidenav-navigation">
        <ng-content select="omedom-sidenav-item[nav-primary]"></ng-content>
    </div>
</div>

<ng-content select="omedom-sidenav-item[add-button]"></ng-content>

<div class="sidenav-navigation">
    <ng-content select="omedom-sidenav-item[nav-secondary]"></ng-content>
</div>

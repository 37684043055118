<div class="card">
    <div class="header">
        @if (option && (!!option.icon || !!option.image)) {
        <ng-container
            *ngTemplateOutlet="!!option.icon ? iconTemplate : imageTemplate"
        >
        </ng-container>
        <ng-template #iconTemplate>
            <i class="omedom-select-option-icon" [class]="option.icon"></i>
        </ng-template>
        <ng-template #imageTemplate>
            <img
                class="omedom-select-option-image"
                [src]="option.image"
                [alt]="option.imageAlt"
                alt="Image de l'option"
            />
        </ng-template>
        }

        <span class="omedom-select-option-label">{{ option.label }}</span>

        @if (option.amount) {
        <span class="omedom-select-option-amount"
            >{{ option.amount | omedomNumber }} €</span
        >
        }
    </div>

    <div class="footer">
        @if (option.periodicity) {
        <span class="omedom-select-option-info">
            {{ option.periodicity }}
        </span>
        } @if (option.notes) {
        <span class="omedom-select-option-info">
            {{ option.notes }}
        </span>
        }
    </div>
</div>

@if (!!option.popover && isOpen) {
<omedom-popover
    class="omedom-select-option-popover"
    [content]="option.popover"
    (click)="$event.stopPropagation()"
></omedom-popover>
} @if (!isOpen && !isDisabled) {
<ng-container
    *ngTemplateOutlet="isSelected ? selectedTemplate : notSelectedTemplate"
></ng-container>
<ng-template #selectedTemplate>
    <i class="uil uil-times-circle" (click)="clearSelection($event)"></i>
</ng-template>
<ng-template #notSelectedTemplate>
    <i class="uil uil-angle-down"></i>
</ng-template>
}

import { Component } from '@angular/core';
import { LevelService } from '@omedom/services';

@Component({
    selector: 'omedom-level-success-container',
    templateUrl: './level-success-container.component.html',
    styleUrls: ['./level-success-container.component.scss'],
})
export class LevelSuccessContainerComponent {
    /**
     * @description List of events to display in the popup
     * @author Jérémie Lopez
     * @memberof LevelSuccessContainerComponent
     */
    public eventsToDisplay$ = this.levelService.eventsToDisplay;

    constructor(private levelService: LevelService) {}

    /**
     * @description Remove the event from the list of events to display in the popup
     * @author Jérémie Lopez
     * @param {string} id
     * @memberof LevelSuccessContainerComponent
     */
    public removeEvent(id: string | undefined): void {
        if (id) this.levelService.removeEvent(id);
    }
}

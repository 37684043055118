import { animate, state, style, transition, trigger } from '@angular/animations';

export const slideYInOutAnimation = trigger('slideYInOut', [
    state(
        'void',
        style({
            height: '0px',
            opacity: 0,
            overflow: 'hidden',
        })
    ),
    state(
        '*',
        style({
            height: '*',
            opacity: 1,
        })
    ),
    transition('void => *', [animate('300ms ease-out')]),
    transition('* => void', [animate('300ms ease-in')]),
]);

@if (pro) {
<img [src]="pro.logo" class="logo" alt="Logo du professionnel" />
<div class="infos">
    @if (displayKey('name')) {
    <div class="info">
        <i class="uil uil-briefcase-alt"></i>
        @if (pro.name) {
        <span>{{ pro.name }}</span>
        } @else {
        <span class="empty-info">Non renseigné</span>
        }
    </div>
    } @if (displayKey('siret')) {
    <div class="info">
        <i class="uil uil-shield-check"></i>
        @if (pro.siret) {
        <span>{{ pro.siret }}</span>
        } @else {
        <span class="empty-info">Non renseigné</span>
        }
    </div>
    } @if (displayKey('address')) {
    <div class="info">
        <i class="uil uil-location-pin-alt"></i>
        @if (pro.address) {
        <span>{{ pro.address }}</span>
        } @else {
        <span class="empty-info">Non renseigné</span>
        }
    </div>
    } @if (displayKey('phone')) {
    <div class="info">
        <i class="uil uil-phone"></i>
        @if (pro.phone) {
        <span>{{ pro.phone }}</span>
        } @else {
        <span class="empty-info">Non renseigné</span>
        }
    </div>
    } @if (displayKey('email')) {
    <div class="info">
        <i class="uil uil-envelope"></i>
        @if (pro.email) {
        <span>{{ pro.email }}</span>
        } @else {
        <span class="empty-info">Non renseigné</span>
        }
    </div>
    } @if (displayKey('website')) {
    <div class="info">
        <i class="uil uil-globe"></i>
        @if (pro.website) {
        <span>{{ pro.website }}</span>
        } @else {
        <span class="empty-info">Non renseigné</span>
        }
    </div>
    } @if (displayKey('contractNumber')) {
    <div class="info">
        <i class="uil uil-receipt"></i>
        @if (pro.contractNumber) {
        <span>{{ pro.contractNumber }}</span>
        } @else {
        <span class="empty-info">Non renseigné</span>
        }
    </div>
    } @if (displayKey('signatureContractDate')) {
    <div class="info">
        <i class="uil uil-calendar-alt"></i>
        @if (pro.signatureContractDate) {
        <span>
            {{ pro.signatureContractDate | date : 'dd/MM/yyyy' }}
        </span>
        } @else {
        <span class="empty-info">Non renseigné</span>
        }
    </div>
    }
</div>
} @else {
<div class="empty">
    <i class="uil uil-exclamation-triangle"></i>
    <span>Professionnel non renseigné</span>
</div>
}

<ng-template #empty>
    <div class="empty">
        <i class="uil uil-exclamation-triangle"></i>
        <span>Professionnel non renseigné</span>
    </div>
</ng-template>

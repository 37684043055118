import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LoanInfosFormType, SelectOption } from '@omedom/data';
import { BankService } from '@omedom/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { listAnimation } from '../../../../animations';

@Component({
    selector: 'omedom-loan-infos-step',
    templateUrl: './loan-infos-step.container.html',
    styleUrls: ['./loan-infos-step.container.scss'],
    animations: [
        listAnimation
    ]
})
export class LoanInfosStepContainer {
    /**
     * @description Form group input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {FormGroup<LoanInfosFormType>}
     * @memberof LoanInfosStepContainer
     */
    @Input({ required: true })
    public form!: FormGroup<LoanInfosFormType>;

    /**
     * @description Banks options observable
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {Observable<SelectOption[]>}
     * @memberof LoanInfosStepContainer
     */
    public banksOptions$: Observable<SelectOption[]> = this.bankService._list().pipe(
        map(banks => banks
            .map(bank => ({ id: bank.bridgeID, label: bank.name }))
            .sort((a, b) => a.label.localeCompare(b.label)
            )),
    );

    /**
     * @description Placeholder of the bank select input field
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {SelectOption}
     * @memberof LoanInfosStepContainer
     */
    public placeholder: SelectOption = { id: null, label: 'Sélectionner une banque' };

    constructor(
        private readonly bankService: BankService
    ) { }

    /**
     * @description Number of keys in the form controls object for animation
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @readonly
     * @type {number}
     * @memberof LoanInfosStepContainer
     */
    public get keys(): number {
        return Object.keys(this.form.controls).length;
    }

    /**
     * @description Calculate the repaid capital from the borrowed capital and the remaining capital
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/07/2024
     * @memberof LoanInfosStepContainer
     */
    public calculateRepaidCapital(): void {
        const borrowedCapital = this.form.get('borrowedCapital')?.value || 0;
        const remainingCapital = this.form.get('remainingCapital')?.value || 0;

        this.form.get('repaidCapital')?.setValue(borrowedCapital - remainingCapital);
    }

    /**
     * @description Calculate the remaining capital from the borrowed capital and the repaid capital
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/07/2024
     * @memberof LoanInfosStepContainer
     */
    public calculateRemainingCapital(): void {
        const borrowedCapital = this.form.get('borrowedCapital')?.value || 0;
        const repaidCapital = this.form.get('repaidCapital')?.value || 0;

        this.form.get('remainingCapital')?.setValue(borrowedCapital - repaidCapital);
    }
}

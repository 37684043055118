import { RoleRightInterface } from '../interfaces';

export class RoleRight {
    private static CHANGE =
        "Modifier les informations liées au bien partagé (fiche du bien, charges, revenus, date et type de bail, coordonnées d'un locataire)";

    private static VALIDATE = 'Valider une alerte (charge ou revenus)';
    private static IMPORT = 'Importer un document dans le bien partagé';
    private static EXPORT = 'Exporter un document du bien partagé';
    private static SEND =
        'Envoyer une quittance ou une lettre de relance au(x) locataire(s) du bien partagé';

    private static READ =
        'Visualiser les données du bien partagé (informations du bien, charges, revenus, trésorerie, documents)';

    private static NONE = "n'a aucun droit";

    public static fr: RoleRightInterface = {
        owner: [this.READ, this.VALIDATE, this.SEND, this.CHANGE, this.IMPORT, this.EXPORT],
        admin: [this.READ, this.VALIDATE, this.SEND, this.CHANGE, this.IMPORT, this.EXPORT],
        editor: [this.READ, this.CHANGE, this.IMPORT, this.EXPORT],
        reader: [this.READ, this.EXPORT],
        none: [this.NONE],
    };
}

import { Component, Input } from '@angular/core';
import { NumericRange } from '@omedom/data';

@Component({
    selector: 'omedom-display-range-price',
    templateUrl: './display-range-price.component.html',
    styleUrls: ['./display-range-price.component.scss'],
})
export class DisplayRangePriceComponent {
    @Input({ required: true }) valueRange!: NumericRange;

    @Input() withPlus = false;
}

<omedom-sub-tab
  [subTabs]="subTabs"
  [(selectedSubTab)]="selectedSubTab"
></omedom-sub-tab>

@switch (selectedSubTab.id) {
  <!-- Details -->
  @case (societyInfoTab.details) {
    <omedom-society-info-details
      class="content"
      [society]="society"
      >
    </omedom-society-info-details>
  }
  <!-- Members -->
  @case (societyInfoTab.members) {
    <omedom-society-info-members
      class="content"
      [society]="society"
      >
    </omedom-society-info-members>
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DocumentEntity, PropertyEntity } from '@omedom/data';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { PropertyService } from './property.service';
import {
    LimitSearchParameter,
    RestService,
    SortSearchParameter,
    WhereSearchParameter,
} from './rest.service';

@Injectable({ providedIn: 'root' })
export class DocumentService extends RestService<DocumentEntity> {
    protected override builder = DocumentEntity;

    constructor(
        protected override firestore: AngularFirestore,
        private propertyService: PropertyService
    ) {
        super(firestore, 'documents');
    }

    /**
     * @description Returns all documents created by user (does not include shared properties/societies documents)
     * @author Hanane Djeddal
     * @param userUID user UID
     * @returns {Promise<DocumentEntity[]>} Documents of the user
     * @memberof DocumentService
     * @example
     * const documents = await documentService.getUserDocuments(userUID);
     */
    async getUserDocuments(userUID: string): Promise<DocumentEntity[]> {
        return await this.search([{ where: 'userUID', operator: '==', value: userUID }]);
    }

    /**
     * @description Returns all documents created by user (does not include shared properties/societies documents) in real time
     * @author Brisset Killian
     * @date 18/06/2024
     * @param {string} userUID
     * @returns {*}  {Observable<DocumentEntity[]>}
     * @memberof DocumentService
     */
    public _getUserDocuments(userUID: string): Observable<DocumentEntity[]> {
        return this._search([{ where: 'userUID', operator: '==', value: userUID }]);
    }
    /**
     * @description get document of a Pro user (where isForPro at true )
     * @author ANDRE Felix
     * @memberof DocumentService
     */
    public _getUserProDocuments(userUID: string): Observable<DocumentEntity[]> {
        return this._search([
            { where: 'userUID', operator: '==', value: userUID },
            {
                where: 'isForPro',
                operator: '==',
                value: true,
            },
        ]);
    }

    /**
     * @description Returns all documents:
     * 1 - Created by the user
     * 2 - Created in properties shared with the user;
     * 3 - Created by others in societies or peroperties shared BY the user;
     * 4 - Created in societies (their properties) shared with the user;
     * @author Hanane Djeddal
     * @param userUID user UID
     * @param propertyUIDs properties shared with the user
     * @param societyUIDs societies shared with the user
     * @returns {Promise<DocumentEntity[]>} Documents of the user
     * @memberof DocumentService
     * @example
     * const documents = await documentService.getAllDocuments(userUID, propertyUIDs, societyUIDs);
     */
    public async getAllDocuments(
        userUID: string,
        propertyUIDs: string[],
        societyUIDs: string[]
    ): Promise<DocumentEntity[]> {
        // Init documents array
        const documents = [];
        // Get user documents
        const userDocs = await this.getUserDocuments(userUID);

        // Push user documents to the array
        documents.push(...userDocs);

        // some properties/societies may be undefined
        // TODO: clean database to remove undefined properties/societies
        propertyUIDs = propertyUIDs.filter((x) => x !== undefined);
        societyUIDs = societyUIDs.filter((x) => x !== undefined);

        if (propertyUIDs?.length) {
            // Get properties documents
            await Promise.all(
                propertyUIDs.map(async (propertyUID: string) => {
                    if (!propertyUID) {
                        return;
                    }
                    const docs = await this.getPropertyDocuments(propertyUID);

                    documents.push(...docs);
                })
            );
        }

        if (societyUIDs?.length) {
            // Get societies documents
            await Promise.all(
                societyUIDs.map(async (societyUID: string) => {
                    const docs = await this.getSocietyDocuments(societyUID);

                    documents.push(...docs);
                })
            );
        }

        // Filter out duplicates
        const filteredDocuments = documents.filter(
            (v, i, a) => a.findIndex((t) => t.uid === v.uid) === i
        );

        // Return documents
        return filteredDocuments;
    }

    /**
     * @description Returns all documents (observable version of getAllDocuments)
     * @author Brisset Killian
     * @date 19/06/2024
     * @param {string} userUID
     * @param {string[]} propertyUIDs
     * @param {string[]} societyUIDs
     * @returns {*}  {Observable<DocumentEntity[]>}
     * @memberof DocumentService
     */
    public _getAllDocuments(
        userUID: string,
        propertyUIDs: string[],
        societyUIDs: string[]
    ): Observable<DocumentEntity[]> {
        // Init documents array
        const userDocuments$ = this._getUserDocuments(userUID);

        propertyUIDs = propertyUIDs.filter((x) => x !== undefined);
        societyUIDs = societyUIDs.filter((x) => x !== undefined);

        const propertyDocuments$ = combineLatest(
            propertyUIDs.map((propertyUID) => this._getPropertyDocuments(propertyUID))
        )
            .pipe(startWith([]))
            .pipe(map((docs) => docs.flat()));

        const societyDocuments$ = combineLatest(
            societyUIDs.map((societyUID) => this._getSocietyDocuments(societyUID))
        )
            .pipe(startWith([]))
            .pipe(map((docs) => docs.flat()));

        return combineLatest([userDocuments$, propertyDocuments$, societyDocuments$]).pipe(
            map(([userDocs, propertyDocs, societyDocs]) => {
                const allDocuments = [...userDocs, ...propertyDocs, ...societyDocs];

                const existingDocuments = allDocuments.filter((document) => document?.uid);

                const filteredDocuments = existingDocuments.filter(
                    (v, i, a) => a.findIndex((t) => t.uid === v.uid) === i
                );

                return filteredDocuments;
            })
        );
    }

    /**
     * @description Returns documents of a property
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} propertyUID UID of the property
     * @returns {Promise<DocumentEntity[]>} Documents of the property
     * @memberof DocumentService
     * @example
     * const documents = await documentService.getPropertyDocuments(propertyUid);
     */
    public async getPropertyDocuments(
        propertyUID: string,
        userUID?: string
    ): Promise<DocumentEntity[]> {
        const queries: (WhereSearchParameter | SortSearchParameter | LimitSearchParameter)[] = [
            { where: 'propertyUID', operator: '==', value: propertyUID },
        ];
        if (userUID) {
            queries.push({ where: 'userUID', operator: '==', value: userUID });
        }
        return await this.search(queries);
    }

    /**
     * @description Returns documents of a property in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} propertyUID UID of the property
     * @returns {Observable<DocumentEntity[]>} Documents of the property in real time
     * @memberof DocumentService
     * @example
     * documentService._getPropertyDocuments(propertyUid).subscribe((documents) => {
     * // Do something with documents
     * });
     */
    public _getPropertyDocuments(propertyUID: string): Observable<DocumentEntity[]> {
        return this._search([{ where: 'propertyUID', operator: '==', value: propertyUID }]);
    }

    /**
     * @description Returns documents of a list of properties in real time
     * @author Brisset Killian
     * @date 19/06/2024
     * @param {string[]} propertyUIDs
     * @returns {*}  {Observable<DocumentEntity[]>}
     * @memberof DocumentService
     */
    public _getPropertiesDocuments(propertyUIDs: string[]): Observable<DocumentEntity[]> {
        if (!propertyUIDs?.length) {
            return of([]);
        }
        return combineLatest(
            propertyUIDs.map((propertyUID) => this._getPropertyDocuments(propertyUID))
        ).pipe(map((docs) => docs.flat()));
    }

    /**
     * @description Returns documents of a society
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} societyUID UID of the society
     * @returns {Promise<DocumentEntity[]>} Documents of the society
     * @memberof DocumentService
     * @example
     * const documents = await documentService.getSocietyDocuments(societyUID);
     */
    public async getSocietyDocuments(
        societyUID: string,
        userUID?: string,
        withPropertiesDocuments: boolean = true
    ): Promise<DocumentEntity[]> {
        // Init documents array
        const documents = [];
        if (withPropertiesDocuments) {
            // Get properties of the society
            const properties = await this.propertyService.getPropertiesBySociety(societyUID);

            // Get documents of each property
            await Promise.all(
                properties.map(async (property: PropertyEntity) => {
                    const docs = await this.getPropertyDocuments(property.uid, userUID);

                    documents.push(...docs);
                })
            );
        }

        const queries: (WhereSearchParameter | SortSearchParameter | LimitSearchParameter)[] = [
            { where: 'societyUID', operator: '==', value: societyUID },
        ];

        if (userUID) {
            queries.push({ where: 'userUID', operator: '==', value: userUID });
        }

        // Get documents of the society
        const docSociety = await this.search(queries);

        // Push documents of the society to the array
        documents.push(...docSociety);

        return documents;
    }

    /**
     * @description Returns documents of a society in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} societyUID UID of the society
     * @returns {Promise<Observable<DocumentEntity[]>>} Documents of the society in real time
     * @memberof DocumentService
     * @example
     * const documents = documentService._getSocietyDocuments(societyUID);
     * documents$.subscribe((documents) => {
     * // Do something with documents
     * });
     */
    public _getSocietyDocuments(societyUID: string): Observable<DocumentEntity[]> {
        // Fetch properties of the society by society UID
        const properties$ = this.propertyService._getPropertiesBySociety(societyUID);

        // Initialize an Observable stream to fetch documents based on property UIDs
        const docsFromProperties$ = properties$.pipe(
            // Map each property to its UID
            map((properties: PropertyEntity[]) => properties.map((property) => property.uid)),
            // Switch to a new Observable that combines document searches for each property UID
            switchMap((propertyUIDs: string[]) =>
                combineLatest(
                    propertyUIDs
                        .filter((propertyUID) => propertyUID) // Filter out any falsy values to ensure valid UIDs
                        .map((propertyUID) =>
                            // Perform a search for documents by property UID
                            this._search([
                                { where: 'propertyUID', operator: '==', value: propertyUID },
                            ])
                        )
                )
            ),
            // Flatten the array of document arrays to a single array of documents
            map((documentArrays: DocumentEntity[][]) => documentArrays.flat())
        );

        // Directly fetch documents associated with the society UID
        const docsFromSociety$ = this._search([
            { where: 'societyUID', operator: '==', value: societyUID },
        ]);

        // Combine the documents from properties and the society into a single Observable stream
        return combineLatest([
            docsFromProperties$.pipe(startWith([])),
            docsFromSociety$.pipe(startWith([])),
        ]).pipe(
            // Merge both sources of documents into one array
            map(([docsFromProperties, docsFromSociety]) => {
                return [...docsFromProperties, ...docsFromSociety];
            })
        );
    }

    /**
     * @description Gets documents in a society and its properties that were NOT created by userUid and are NOT included in filteroutProperty
     * @author Hanane Djeddal
     * @param userUID User UID
     * @param societyUID Society UID
     * @param filteroutProperty Property UIDs to filter out
     * @returns {Promise<DocumentEntity[]>} Documents of the society and its properties
     * @memberof DocumentService
     * @example
     * const documents = await documentService.getSocietyFilteredDocuments(userUID, societyUID, filteroutProperty);
     */
    public async getSocietyFilteredDocuments(
        userUID: string,
        societyUID: string,
        filterOutProperty: string[]
    ): Promise<DocumentEntity[]> {
        // Get properties of the society
        let properties = await this.propertyService.getPropertiesBySociety(societyUID);

        // Filter out properties
        properties = properties.filter((x) => !filterOutProperty.includes(x.uid));

        // Init documents array
        const documents = [];

        // Get documents of each property
        await Promise.all(
            properties.map(async (property: PropertyEntity) => {
                const docs = await this.search([
                    { where: 'propertyUID', operator: '==', value: property.uid },
                    { where: 'userUID', operator: '!=', value: userUID },
                ]);

                documents.push(...docs);
            })
        );

        // Get documents of the society
        const docSociety = await this.search([
            { where: 'societyUID', operator: '==', value: societyUID },
            { where: 'userUID', operator: '!=', value: userUID },
        ]);

        // Push documents of the society to the array
        documents.push(...docSociety);

        return documents;
    }

    /**
     * @description Returns documents of a pro in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @param {string} proUID
     * @returns {*}  {Observable<DocumentEntity[]>}
     * @memberof DocumentService
     * @example
     * documentService._getProDocuments(proUID).subscribe((documents) => {
     * // Do something with documents
     * });
     */
    public _getProDocuments(proUID: string): Observable<DocumentEntity[]> {
        return this._search([{ where: 'proUID', operator: '==', value: proUID }]);
    }

    /**
     * @description Override Document
     * @author ANDRE Felix
     * @param {DocumentEntity} newDocument
     * @param {DocumentEntity} oldDocument
     * @memberof DocumentService
     */
    public async overrideDocument(newDocument: DocumentEntity, oldDocument: DocumentEntity) {
        await this.delete(oldDocument.uid);
        await this.create(newDocument);
    }

    public _getLastestDocumentWithLimit(
        userUID: string,
        limit: number
    ): Observable<DocumentEntity[]> {
        return this._search([
            { where: 'userUID', operator: '==', value: userUID },
            { sortBy: 'created', direction: 'desc' },
            { limit: limit, limitToLast: true },
        ]);
    }

    /**
     * @description Returns all documents linked to a loan in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @param {string} loanUID
     * @returns {Observable<DocumentEntity[]>}
     * @memberof DocumentService
     */
    public _getLoanDocuments(loanUID: string): Observable<DocumentEntity[]> {
        return this._search([{ where: 'loanUID', operator: '==', value: loanUID }]);
    }

    /**
     * @description Returns all documents linked to a saving in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/07/2024
     * @param {string} savingUID
     * @returns {Observable<DocumentEntity[]>}
     * @memberof DocumentService
     */
    public _getSavingDocuments(savingUID: string): Observable<DocumentEntity[]> {
        return this._search([{ where: 'savingUID', operator: '==', value: savingUID }]);
    }
}

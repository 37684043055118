import { LoanEntity, PropertyEntity, SavingEntity, SocietyEntity } from '../entities';

export type AssetType = PropertyEntity | SocietyEntity;

export type BankAsset = SavingEntity | LoanEntity;

export type AllAssets = AssetType | BankAsset;

export enum AllAssetTypeName {
    property = 'propriété',
    building = 'immeuble',
    society = 'société',
    loan = 'crédit',
    saving = 'épargne',
}

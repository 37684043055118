<omedom-bank-info icon="hourglass"
                  @element
                  [color]="colors.blue"
                  title="Vue en Temps Réel"
                  content="Visualisez votre trésorerie en temps réel sur l'ensemble de votre patrimoine."></omedom-bank-info>

<omedom-bank-info icon="link"
                  @element
                  [color]="colors.green"
                  title="Liaison bancaire"
                  content="Connectez vos comptes bancaires et catégorisez vos mouvements."></omedom-bank-info>

<omedom-bank-info icon="shield"
                  @element
                  [color]="colors.yellow"
                  title="Sécurité"
                  content="Parce que votre sécurité est notre priorité, nous faisons appel à notre partenaire Bridge (France), agrée par l'ACPR - Banque de France (Autorité de Contrôle Prudentiel et de résolution)."></omedom-bank-info>

<div class="logo-container"
     @element>
    <img src="/assets/icon/dsp2_logo.png"
         alt="DSP2">
    <img src="/assets/icon/acpr_logo.svg"
         alt="ACPR">
</div>
<div class="omedom-modal">
  <div class="omedom-modal-header">
    <div class="omedom-modal-header-title">
      <i
        class="uil uil-trash-alt color-light-black background-color-light-orange"
      ></i>
      <h3>Suppression de charge</h3>
    </div>
    <i class="uil uil-times-circle" (click)="dismiss()"></i>
  </div>
  <div class="omedom-modal-content">
    @if (treasuryListModel?.periodicityInfo?.label !== 'Ponctuelle') {
      <omedom-radio
        [options]="deleteOptions"
        type="column"
        name="deleteOption"
        label="Souhaitez-vous supprimer cette charge ?"
        [(ngModel)]="selectedDeleteOption"
      ></omedom-radio>
    }
    @if (treasuryListModel?.periodicityInfo?.label === 'Ponctuelle') {
      <p>
        Souhaitez-vous supprimer cette charge ?
      </p>
    }
  </div>
  <div class="omedom-modal-buttons">
    <button class="omedom-button-secondary" (click)="dismiss()">
      Annuler
    </button>
    <button
      class="omedom-button-warn"
            [disabled]="
                !selectedDeleteOption &&
                treasuryListModel?.periodicityInfo?.label !== 'Ponctuelle'
            "
      (click)="deleteConfirmed()"
      >
      <i class="uil uil-trash-alt"></i>
      Oui, supprimer
    </button>
  </div>
</div>

import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { SelectOption } from '@omedom/data';

@Component({
    selector: 'omedom-select-option',
    templateUrl: './select-option.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SelectOptionComponent {
    /**
     * @description Event emitted when the option is clicked
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @memberof SelectOptionComponent
     */
    @Output()
    public optionClicked = new EventEmitter<void>();

    /**
     * @description Event emitted when the clear selection button is clicked
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @memberof SelectOptionComponent
     */
    @Output()
    public clearSelectionClicked = new EventEmitter<void>();

    /**
     * @description The option to display in the select component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {SelectOption}
     * @memberof SelectOptionComponent
     */
    @Input()
    public option?: SelectOption;

    /**
     * @description True if the select component is open, false otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {boolean}
     * @memberof SelectOptionComponent
     */
    @Input()
    @HostBinding('class.is-open')
    public isOpen: boolean = false;

    /**
     * @description True if the option is disabled, false otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {boolean}
     * @memberof SelectOptionComponent
     */
    @Input()
    public isDisabled: boolean = false;

    /**
     * @description True if the option is selected, false otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @readonly
     * @type {boolean}
     * @memberof SelectOptionComponent
     */
    @HostBinding('class.is-selected')
    public get isSelected(): boolean {
        return this.option?.isSelected ?? false;
    }

    /**
     * @description Broadcast the click event on the option to the select component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @param {PointerEvent} event
     * @memberof SelectOptionComponent
     */
    @HostListener('click', ['$event'])
    public onClick(event: PointerEvent): void {
        // Prevent the event from bubbling up to the select component
        if (this.isOpen) {
            event.stopPropagation();
        }

        // Emit the click event
        this.optionClicked.emit();
    }

    /**
     * @description Broadcast the click event on the clear selection button to the select component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @param {MouseEvent} event
     * @memberof SelectOptionComponent
     */
    public clearSelection(event: MouseEvent): void {
        event.stopPropagation();
        this.clearSelectionClicked.emit();
    }
}

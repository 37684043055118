<div
    (click)="clickEvent.emit(key)"
    [ngClass]="{
        selected: selected
    }"
>
    @if (icon) {
    <i class="uil uil-comment-dots"></i>
    } @if (!icon) {
    <img
        class="clic"
        height="50"
        width="50"
        [ngSrc]="
            'assets/icon/property-type/' +
            keyWithoutUpperCase +
            (selected ? '_selected' : '') +
            '.png'
        "
        alt="Icone"
    />
    }
    <p class="margin-y-1">{{ title }}</p>
</div>

export class OmedomSanitiser {
    public static validateFirestoreURL(url: string) {
        const urlPattern = /^(https:\/\/firebasestorage\.googleapis\.com\/.*)$/;
        return urlPattern.test(url);
    }

    public static validateYoutubeURL(url: string) {
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
        return youtubeRegex.test(url);
    }
}

import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'omedomByte' })
@Injectable({ providedIn: 'root' })
export class OmedomBytePipe implements PipeTransform {
    /**
     * @description Transform bytes to a human readable string
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/09/2023
     * @param {number} bytes Bytes to transform
     * @returns {string} Human readable string
     * @memberof OmedomBytePipe
     */
    public transform(bytes: number | undefined): string {
        // Check if the value is null or undefined
        if (bytes == null) {
            return '0 o';
        }

        let unity: string;
        let value: number;

        if (bytes < 1000) {
            unity = 'o';
            value = bytes;
        } else if (bytes < 1000000) {
            unity = 'ko';
            value = bytes / 1000;
        } else if (bytes < 1000000000) {
            unity = 'Mo';
            value = bytes / 1000000;
        } else {
            unity = 'Go';
            value = bytes / 1000000000;
        }

        // Round value
        value = Math.round(value * 100) / 100;

        // Return value with unity
        return `${value} ${unity}`;
    }
}

<div class="date margin-bottom-1 margin-top-1">
    {{ paymentDate | date : 'dd/MM/yyyy' }}
</div>

<div class="card">
    <div class="card-header">
        <span class="card-header-label">{{ payment.description }}</span>
    </div>
    <div class="card-content">
        <span class="card-header-label"
            >{{ (payment.amount / 100).toFixed(2).replace('.', ',') }} €</span
        >
        <span class="card-content-icon">
            <i class="uil uil-check-circle"></i>
        </span>
    </div>
    <div class="card-button">
        <button
            class="omedom-button-secondary"
            [disabled]="!payment.receiptURL"
            (click)="downloadReceipt()"
        >
            <i class="uil uil-import"></i>
        </button>
    </div>
</div>

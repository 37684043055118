export interface RoleRightTranslated {
    change: string;
    validate: string;
    create: string;
    documentComplete: string;
    documentPartial: string;
    send: string;
    read: string;
    none: string;
    delete: string;
    smartReaderOptions: string;
    smartAdminOptions: string;
}

/**
 * @description Data of an automation action to send an email to display in a form or in a chart
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 28/11/2023
 * @export
 * @interface AutomationActionSendEmail
 */
export interface AutomationActionSendEmail {
    templateId: string;
}

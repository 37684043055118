import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { ChargeEntity, ChartDoughnutData, IncomeEntity, PropertyEntity } from '@omedom/data';
import { ChargeService, IncomeService } from '@omedom/services';
import { OmedomTreasury } from '@omedom/utils';

@Component({
    selector: 'omedom-property-profil-treasury',
    templateUrl: './treasury.component.html',
    styleUrls: ['./treasury.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class PropertyProfilTreasuryComponent implements OnInit, OnChanges {
    /**
     * @description Property to display treasury of
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {PropertyEntity}
     * @memberof TreasuryComponent
     */
    @Input()
    public property?: PropertyEntity;

    /**
     * @description Date to display treasury of the property of the month of the date passed in parameter
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {Date}
     * @memberof TreasuryComponent
     */
    public date: Date = new Date();

    /**
     * @description Start date of the treasury to display of the property of the month of the date passed in parameter
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @private
     * @type {Date}
     * @memberof TreasuryComponent
     */
    private startDate: Date = this.date.getFirstDayOfMonth();

    /**
     * @description End date of the treasury to display of the property of the month of the date passed in parameter
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @private
     * @type {Date}
     * @memberof TreasuryComponent
     */
    private endDate: Date = this.date.getLastDayOfMonth();

    /**
     * @description Charges of the property of the month of the date passed in parameter to display in the treasury of the property of the month of the date passed in parameter
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @private
     * @type {ChargeEntity[]}
     * @memberof TreasuryComponent
     */
    public charges: ChargeEntity[] = [];

    /**
     * @description Incomes of the property of the month of the date passed in parameter to display in the treasury of the property of the month of the date passed in parameter
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @private
     * @type {IncomeEntity[]}
     * @memberof TreasuryComponent
     */
    public incomes: IncomeEntity[] = [];

    /**
     * @description Chart data for charges
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @type {ChartDoughnutData[]}
     * @memberof TreasuryComponent
     */
    public chargesChartData: ChartDoughnutData[] = [];

    /**
     * @description Chart data for incomes
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @type {ChartDoughnutData[]}
     * @memberof TreasuryComponent
     */
    public incomesChartData: ChartDoughnutData[] = [];

    constructor(
        private readonly chargeService: ChargeService,
        private readonly incomeService: IncomeService
    ) {}

    async ngOnInit(): Promise<void> {
        // Get treasury
        await this.getTreasury();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        // Check changes on date
        if (changes['property']) {
            await this.getTreasury();
        }
    }

    private async getTreasury(): Promise<void> {
        // Check if the property is defined
        if (!this.property) {
            return;
        }

        const userUID = this.property.userUID;

        // Get charges
        this.charges = await this.chargeService.getChargesByProperty(this.property.uid, userUID);

        // Get incomes
        this.incomes = await this.incomeService.getIncomesByProperty(this.property.uid, userUID);

        // Update treasury
        this.updateTreasury();
    }

    /**
     * @description Update treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @private
     * @memberof TreasuryComponent
     */
    private updateTreasury(): void {
        // Transform charges and incomes to chart data
        this.chargesChartData = OmedomTreasury.getChartData(
            this.charges,
            this.startDate,
            this.endDate
        );
        this.incomesChartData = OmedomTreasury.getChartData(
            this.incomes,
            this.startDate,
            this.endDate
        );
    }

    public updateDate(value: Date): void {
        this.date = value;

        // Update start date
        this.startDate = this.date.getFirstDayOfMonth();

        // Update end date
        this.endDate = this.date.getLastDayOfMonth();

        // Update treasury
        this.updateTreasury();
    }
}

/**
 * @description List of bank categories link
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 06/05/2024
 * @export
 * @type {Array<{ value: string, ids: number[] }>}
 */
export const BANK_CATEGORIES_LINK = [
    { value: "water", ids: [293] },
    { value: "electricity", ids: [217] },
    { value: "heater", ids: [] },
    { value: "insurance", ids: [278, 246] },
    { value: "condominium", ids: [216] },
    { value: "taxes", ids: [159, 206, 208, 209, 302, 441988] },
    { value: "managementFees", ids: [328] },
    { value: "banking", ids: [164, 89, 194, 195] },
    { value: "workAndMaintains", ids: [221, 222, 323, 203] },
    { value: "furniture", ids: [184] },
    { value: "internet", ids: [180] },
    { value: "various", ids: [276, 90, 282, 283, 441886, 441891, 441895, 441899] },
    { value: "landlordInsurance", ids: [] },
    { value: "houseInsurance", ids: [] },
    { value: "rentGuaranteeInsurance", ids: [] },
    { value: "loanInsurance", ids: [] },
    { value: "legalInsurance", ids: [] }
];

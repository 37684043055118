import { RestEntity } from './rest.entity';

export enum LeaseType {
    empty = 'Vide',
    furnished = 'Meublé',
    coleasing = 'Co-location',
    student = 'Etudiant',
    mobility = 'Mobilité',
}

export class LeaseEntity extends RestEntity {
    /**
     * @description Date of the beginning of the lease
     * @author Martin Bastié
     * @type {string[]}
     * @memberof TenantEntity
     */
    leaseStart!: Date;

    /**
     * @description Date of the end of the lease
     * @author Martin Bastié
     * @type {string[]}
     * @memberof TenantEntity
     */
    leaseEnd!: Date;

    /**
     * @description Type of the lease
     * @author Jérémie Lopez
     * @type {LeaseType}
     * @memberof LeaseEntity
     */
    leaseType!: {
        id: LeaseType,
        isSelected: boolean,
        label: LeaseType,
    };

    /**
     * @description Amount of the deposit of the guaranty
     * @author Martin Bastié
     * @type {string[]}
     * @memberof TenantEntity
     */
    guarantyDeposit!: number;

    /**
     * @description Property of the lease
     * @author Jérémie Lopez
     * @type {string}
     * @memberof LeaseEntity
     */
    propertyUID!: string;

    /**
     * @description Rent of the lease
     * @author Jérémie Lopez
     * @type {string}
     * @memberof LeaseEntity
     */
    rentUID!: string;
}

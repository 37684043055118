/**
 * @description Type of action to perform (sendNotification, sendEmail) to display in the chart or in a form
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 28/11/2023
 * @export
 * @enum {number}
 */
export enum AutomationActionType {
    'sendNotification' = 'sendNotification',
    'sendEmail' = 'sendEmail',
}

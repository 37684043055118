<div
  class="form-collapse"
  (click)="displayPhysicalData.next(!displayPhysicalData.value)"
  >
  <img
    src="/assets/icon/tax-habitation.svg"
    alt="données physiques"
    title="données physiques"
    class="info-icon margin-right-1"
    />
  <span> Données physiques</span>
  <i
    class="uil"
    [class.uil-angle-down]="!displayPhysicalData.value"
    [class.uil-angle-up]="displayPhysicalData.value"
  ></i>
</div>
@if (displayPhysicalData | async) {
  <div @slideYInOut>
    <div class="section color-light-black margin-top-2">
      <span>Pièces</span>
      <span>
        <strong>{{ property?.roomCount || '-' }}</strong>
      </span>
    </div>
    @if (property?.livingSpace || property?.livingSpace === 0) {
      <div
        class="section color-light-black"
        >
        <span>Surface</span>
        <div>
          <span>
            <i class="uil uil-tape margin-right-1"></i>
            <strong
              >{{ property?.livingSpace || 0 | omedomNumber }} m²</strong
              >
            </span>
          </div>
        </div>
      }
      @if (property?.landArea || property?.landArea === 0) {
        <div
          class="section color-light-black"
          >
          <span>Surface du terrain</span>
          <div>
            <span>
              <i class="uil uil-tape margin-right-1"></i>
              <strong>{{ property?.landArea || 0 | omedomNumber }} m²</strong>
            </span>
          </div>
        </div>
      }
      @if (property?.floorNumber) {
        <div class="section color-light-black">
          <span>Nombre d'étages</span>
          <div>
            <span>
              <strong>{{ property?.floorNumber || 0 | omedomNumber }}</strong>
            </span>
          </div>
        </div>
      }
      @if (property?.floor && isAssimilateAppartement) {
        <div
          class="section color-light-black"
          >
          <span>{{
            isAssimilateAppartement
            ? 'Nombre total d’étage(s) immeuble'
            : "Etage de l'appartement"
          }}</span>
          <div>
            <span>
              <strong>{{ property?.floor || 0 | omedomNumber }}</strong>
            </span>
          </div>
        </div>
      }
    </div>
  }
  <hr class="omedom-divider" />

  <div
    class="form-collapse"
    (click)="displayDiagnosticForm.next(!displayDiagnosticForm.value)"
    >
    <i class="uil uil-signal margin-right-1 rotate-90"></i>
    <span>Diagnostic de performance énergétique</span>
    <i
      class="uil"
      [class.uil-angle-down]="!displayDiagnosticForm.value"
      [class.uil-angle-up]="displayDiagnosticForm.value"
    ></i>
  </div>
  @if (displayDiagnosticForm | async) {
    <div @slideYInOut>
      <div class="section color-light-black">
        <span>Date du DPE</span>
        <span>
          <strong>{{ property?.dpeDetails?.dpeDate || '-' }}</strong>
        </span>
      </div>
      <div class="section color-light-black">
        <span>Note du DPE</span>
        <span>
          <strong>{{ property?.dpeDetails?.dpeScore || '-' }}</strong>
        </span>
      </div>
      <div class="section color-light-black">
        <span>Nombre de fenêtres</span>
        <span>
          <strong>{{
            property?.dpeDetails?.estimationInformations?.numberOfWindows ||
            '-'
          }}</strong>
        </span>
      </div>
      <div class="section color-light-black">
        <span>Nombre de mur externes</span>
        <span>
          <strong>{{
            property?.dpeDetails?.estimationInformations
            ?.numberOfExternalFacingWalls || '-'
          }}</strong>
        </span>
      </div>
      <div class="section color-light-black">
        <span>Dernière rénovation</span>
        <span>
          <strong>{{ isolationRenovationPeriod || '-' }}</strong>
        </span>
      </div>
      <div class="section color-light-black">
        <span>Type de vitrage</span>
        <span class="capitalize">
          <strong>{{
            property?.dpeDetails?.estimationInformations?.glazingType || '-'
          }}</strong>
        </span>
      </div>
      @if (isAssimilateHouse) {
        <div class="section color-light-black">
          <span>Aménagement du grenier</span>
          <span>
            <strong>{{ atticType || '-' }}</strong>
          </span>
        </div>
      }
    </div>
  }

  <hr class="omedom-divider" />

  <div
    class="form-collapse"
    (click)="displayRentForm.next(!displayRentForm.value)"
    >
    <img
      src="/assets/icon/location.svg"
      alt="location"
      title="location"
      class="info-icon margin-right-1"
      />
    <span> Location</span>
    <i
      class="uil"
      [class.uil-angle-down]="!displayRentForm.value"
      [class.uil-angle-up]="displayRentForm.value"
    ></i>
  </div>
  @if (displayRentForm | async) {
    <div @slideYInOut>
      <div class="section color-light-black">
        <span>Type de location</span>
        <span>
          <strong>{{ property?.rentDetails?.type || '-' }}</strong>
        </span>
      </div>
      <div class="section color-light-black">
        <span>Type de gestion locative</span>
        <span>
          <strong>{{ property?.rentDetails?.managementType || '-' }}</strong>
        </span>
      </div>
    </div>
  }

  <hr class="omedom-divider" />

  <div
    class="form-collapse"
    (click)="displayAmentiesForm.next(!displayAmentiesForm.value)"
    >
    <img
      src="/assets/icon/union.svg"
      alt="commodités"
      title="Commodités"
      class="margin-right-1"
      style="width: 18px"
      />
    <span> Commodités</span>
    <i
      class="uil"
      [class.uil-angle-down]="!displayAmentiesForm.value"
      [class.uil-angle-up]="displayAmentiesForm.value"
    ></i>
  </div>
  @if (displayAmentiesForm | async) {
    <div
      class="section-list color-light-black"
      @slideYInOut
      >
      <div class="section-list-group">
        <div class="section-list-group-title">
          <i class="uil uil-home"></i>
          <strong>Intérieur</strong>
        </div>
        @if (
          indoorLayouts && indoorLayouts.length > 0) {
          <ul>
            @for (indoorLayout of indoorLayouts; track indoorLayout) {
              <li>
                {{ indoorLayout }}
              </li>
            }
          </ul>
        } @else {
          <strong>-</strong>
        }
      </div>
      <div class="section-list-group">
        <div class="section-list-group-title">
          <i class="uil uil-trees"></i>
          <strong>Extérieur</strong>
        </div>
        @if (
          outdoorLayouts && outdoorLayouts.length > 0) {
          @if (outdoorLayouts && outdoorLayouts.length > 0) {
            <ul>
              @for (outdoorLayout of outdoorLayouts; track outdoorLayout) {
                <li>
                  {{ outdoorLayout }}
                </li>
              }
            </ul>
          }
        } @else {
          <strong>-</strong>
        }
      </div>
      <div class="section-list-group">
        <div class="section-list-group-title">
          <i class="uil uil-building"></i>
          <strong>Immeuble</strong>
        </div>
        @if (
          buildingLayouts && buildingLayouts.length > 0) {
          @if (buildingLayouts && buildingLayouts.length > 0) {
            <ul>
              @for (buildingLayout of buildingLayouts; track buildingLayout) {
                <li>
                  {{ buildingLayout }}
                </li>
              }
            </ul>
          }
        } @else {
          <strong>-</strong>
        }
      </div>
      <div class="section-list-group">
        <div class="section-list-group-title">
          <i class="uil uil-temperature-quarter"></i>
          <strong>Chauffage</strong>
        </div>
        @if (heatLayouts && heatLayouts.length > 0) {
          <ul>
            @for (heatLayout of heatLayouts; track heatLayout) {
              <li>{{ heatLayout }}</li>
            }
          </ul>
        } @else {
          <strong>-</strong>
        }
      </div>
    </div>
  }


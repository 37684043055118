import { Appearance } from '../enums/appearance.enum';

export interface DataTableAction<T> {
    icon: string;
    title?: string;
    placeholder?: string;
    callback: (data: T) => void;
    appearance: Appearance;
    validator?: (data: T) => boolean;
    minimal?: boolean;
}

<section class="treasury-container">
    <div class="card">
        <omedom-liquidity
            [showDate]="false"
            [charges]="charges"
            [incomes]="incomes"
            [forecastAuthorised]="true"
        ></omedom-liquidity>
    </div>
    <div class="card">
        <omedom-circular-graph
            label="Charges"
            icon="chart-pie-alt"
            [showLegend]="true"
            [showTotal]="true"
            [data]="chargesChartData"
        ></omedom-circular-graph>
    </div>
    <div class="card">
        <omedom-circular-graph
            label="Revenus"
            icon="euro-circle"
            [showLegend]="true"
            [showTotal]="true"
            [data]="incomesChartData"
        ></omedom-circular-graph>
    </div>
</section>

<div class="treasury-footer">
    <omedom-date [date]="date" (dateChange)="updateDate($event)"></omedom-date>
</div>

<omedom-modal-header>Renvoyer la licence</omedom-modal-header>

<div class="container">
    <p>
        Voulez-vous vraiment renvoyer le mail de présentation de la licence à
        {{ licence.userEmail }} ?
    </p>

    <button
        (click)="resend()"
        [disabled]="pending"
        class="omedom-button-yellow"
    >
        <i class="uil uil-message"></i>
        Envoyer @if (pending) {
        <ion-spinner></ion-spinner>
        }
    </button>
    <!-- </form> -->
</div>

<omedom-modal-header>Annuler une licence</omedom-modal-header>

<div class="container">
  <omedom-info iconStart="uil uil-exclamation-triangle"
                 information="Vous êtes sur le point d'annuler la licence de :<br>
                 <strong>{{ licence.userEmail }}</strong>"></omedom-info>

    <p class="omedom-no-margin">
        Une fois la licence annulée, l'utilisateur ne pourra plus activer la licence.
        Vous serez recrédité d'une licence dans votre quota de licence disponible.
    </p>

    <div class="actions-container">
        <button class="omedom-button-secondary"
                (click)="close()">
        Retour
      </button>

      <button class="omedom-button-warn"
        [disabled]="pending"
        (click)="cancel()">
        <i class="uil uil-times-circle"></i>
        Annuler la licence
        @if (pending) {
          <ion-spinner></ion-spinner>
        }
      </button>
    </div>
  </div>
@if (proAlert) {
<div class="pro-alert-card">
    <div class="pro-alert-header">
        <span class="pro-alert-title"
            >{{ proAlert.clientFirstname }} {{ proAlert.clientName }} -
            {{ proAlert.title }}</span
        >
        <span class="pro-alert-date">{{ getFormattedDate() }}</span>
    </div>
    <div class="pro-alert-body">
        @if (proAlert.image) {
        <img [src]="proAlert.image" alt="pro-alert" class="pro-alert-image" />
        }
        <span class="pro-alert-message"> {{ proAlert.body }}</span>
        <i
            class="uil uil-ellipsis-v"
            style="color: #04151c"
            (click)="modal.present()"
        ></i>
    </div>
    <div class="pro-alert-footer" *ngIf="hasEnableActions">
        <button
            class="omedom-icon-button-secondary-outline flex-1"
            (click)="openValidTreadModal(proAlert)"
        >
            @if (!proAlert.isTreated) {
            <i class="uil uil-check"></i>
            }@else {
            <!-- cross -->
            <i class="uil uil-times"></i>
            }
        </button>
        <button
            class="omedom-icon-button-primary flex-1"
            (click)="startChat.emit(proAlert)"
        >
            <i class="uil uil-envelope"></i>
        </button>
    </div>
</div>
}
<ion-modal #modal [breakpoints]="[0, 1]" initialBreakpoint="1">
    <!-- Supprimer cette pro-alert et Paramètres -->
    <ng-template>
        <div class="omedom-modal">
            <div class="omedom-modal-header">
                <i class="uil uil-times-circle" (click)="modal.dismiss()"></i>
            </div>
            <div (click)="deleteProAlert(); modal.dismiss()" class="modal-item">
                <!-- Delete this pro-alert -->
                <i class="uil uil-trash"></i>
                <span>Supprimer cette alerte</span>
            </div>
            <div
                (click)="openSetting.emit(proAlert?.clientUID); modal.dismiss()"
                class="modal-item"
            >
                <i class="uil uil-bell"></i>
                <span>Paramètres</span>
            </div>
        </div>
    </ng-template>
</ion-modal>

import {
    ChargeCategoryInfo,
    ChargeCategoryProperty,
    IncomeCategoryInfo,
    IncomeCategoryProperty,
} from '@omedom/data';

/**
 * @description Transaction utils class. Contains all the methods to manipulate transactions and stories
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 07/08/2023
 * @export
 * @class OmedomTransaction
 */
export class OmedomTransaction {
    /**
     * @description Get the category info of a transaction (label and icon). If the category is not found, return the default category (other) with the ellipsis icon
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/08/2023
     * @static
     * @param {string} category Category of the transaction
     * @returns {IncomeCategoryInfo | ChargeCategoryInfo | { label: string; icon: string; }} Category info
     * @memberof OmedomTransaction
     * @example
     * const categoryInfo = OmedomTransaction.getCategoryInfo('rent');
     */
    public static getCategoryInfo(
        category: string
    ): IncomeCategoryInfo | ChargeCategoryInfo | { label: string; icon: string; } {
        if (category) {
            if (category === 'other') {
                return {
                    label: 'Autres',
                    icon: ' uil uil-ellipsis-h',
                };
            } else {
                if (Object.keys(IncomeCategoryProperty).includes(category)) {
                    return new IncomeCategoryInfo(category as IncomeCategoryProperty);
                } else {
                    return new ChargeCategoryInfo(category as ChargeCategoryProperty);
                }
            }
        } else {
            return {
                label: 'Autres',
                icon: ' uil uil-ellipsis-h',
            };
        }
    }

    /**
     * @description Get the Omedom category of a transaction
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/08/2023
     * @static
     * @param {string} transaction Transaction name
     * @param {number} [amount] Transaction amount
     * @param {string} [parent] Parent category
     * @returns {IncomeCategoryInfo | ChargeCategoryInfo | { label: string; icon: string; }} Category info
     * @memberof OmedomTransaction
     * @example
     * const categoryInfo = OmedomTransaction.getOmedomCategory('rent');
     */
    public static getOmedomCategory(
        transaction: string,
        amount?: number,
        parent?: string
    ): IncomeCategoryInfo | ChargeCategoryInfo | { label: string; icon: string; } {
        // List of all the Omedom Charge categories
        const omedomChargeCategory = Object.keys(ChargeCategoryProperty);

        // Format the transaction name
        const rawName = transaction.toLowerCase();

        // Check if the transaction name contains an Omedom Charge category
        const possibleChargeCategory: ChargeCategoryInfo[] = omedomChargeCategory
            .filter(
                (cat) =>
                    cat.toLowerCase() === rawName.replace(' ', '') ||
                    rawName.includes(cat.toLocaleLowerCase()) ||
                    (cat === ChargeCategoryProperty.managementFees &&
                        rawName.includes('management')) ||
                    (cat === ChargeCategoryProperty.workAndMaintains &&
                        (rawName.includes('maintenance') || rawName.includes('work')))
            )
            .map((cat) => new ChargeCategoryInfo(cat as ChargeCategoryProperty));

        // If the transaction name contains an Omedom category, return it
        if (possibleChargeCategory?.length) {
            return possibleChargeCategory[0];
        }

        // List of all the Omedom Income categories
        const omedomIncomeCategory = Object.keys(IncomeCategoryProperty);

        // Check if the transaction name contains an Omedom Income category
        const possibleIncomeCategory: IncomeCategoryInfo[] = omedomIncomeCategory
            .filter(
                (cat) =>
                    cat.toLowerCase() === rawName.replace(' ', '') ||
                    transaction.includes(cat.toLocaleLowerCase())
            )
            .map((cat) => new IncomeCategoryInfo(cat as IncomeCategoryProperty));

        // If the transaction name contains an Omedom Income category, return it
        if (possibleIncomeCategory?.length) {
            return possibleIncomeCategory[0];
        }

        // For the following transactions, return the corresponding Omedom category
        switch (transaction) {
            case 'Hardware':
                return new ChargeCategoryInfo(ChargeCategoryProperty.furniture);
            case 'Home insurance':
                return new ChargeCategoryInfo(ChargeCategoryProperty.insurance);
            case 'Maintenance':
            case 'Office improvement':
            case 'Lawn & Garden':
                return new ChargeCategoryInfo(ChargeCategoryProperty.workAndMaintains);
            case 'Offices - Others':
                return new ChargeCategoryInfo(ChargeCategoryProperty.condominium);
            case 'Gas':
                return new ChargeCategoryInfo(ChargeCategoryProperty.heater);
            case 'Office Rent':
            case 'Student housing':
                return new IncomeCategoryInfo(IncomeCategoryProperty.rent);
            case 'Mobile phone':
            case 'Home phone':
            case 'Cable TV':
            case 'Subscription - Others':
                return new ChargeCategoryInfo(ChargeCategoryProperty.internet);
            case 'Loans':
                if (amount && amount >= 0) {
                    return new IncomeCategoryInfo(IncomeCategoryProperty.aid);
                } else {
                    return new ChargeCategoryInfo(ChargeCategoryProperty.banking);
                }
            case 'Withdrawals':
                return new ChargeCategoryInfo(ChargeCategoryProperty.banking);
            case 'Savings':
            case 'Grants':
            case 'Pension':
                return new IncomeCategoryInfo(IncomeCategoryProperty.aid);
            case 'Business refunds':
                return new IncomeCategoryInfo(IncomeCategoryProperty.regularization);
            case 'Laundry / Dry cleaning':
            case 'Business services':
                return new ChargeCategoryInfo(ChargeCategoryProperty.managementFees);
            case 'Misc. utilities':
            case 'Interest incomes':
            case 'Sales':
            case 'Services':
            case 'Deposit':
            case 'Salaries':
                return new ChargeCategoryInfo(ChargeCategoryProperty.various);
        }

        // Check if parent category is defined
        const parentName = parent ?? null;
        switch (parentName) {
            case 'Taxes':
                return new ChargeCategoryInfo(ChargeCategoryProperty.taxes);
            case 'Withdrawals, checks & transfer':
                return new ChargeCategoryInfo(ChargeCategoryProperty.banking);
            default:
                return {
                    label: 'autres',
                    icon: ' uil uil-ellipsis-h',
                };
        }
    }
}

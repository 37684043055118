@if ((user$ | async) && form) {
  <ng-container
    [formGroup]="form">
    @if ((state$ | async) === 'ok') {
      @if (propertiesOptions.length > 0) {
        <omedom-select [boldLabel]="true"
          @element
          [options]="propertiesOptions"
          [placeholder]="propertiesPlaceholder"
          formControlName="propertyUID"
        label="Associer à un bien"></omedom-select>
      } @else {
        <div class="empty-container"
          @element>
          <span>Aucun bien n'a été trouvé.</span>
        </div>
      }
      <span class="separator"
      @element>Ou</span>
      @if (societiesOptions.length > 0) {
        <omedom-select [boldLabel]="true"
          @element
          [options]="societiesOptions"
          [placeholder]="societiesPlaceholder"
          formControlName="societyUID"
        label="Associer à une société"></omedom-select>
      } @else {
        <div class="empty-container"
          @element>
          <span>Aucune société n'a été trouvée.</span>
        </div>
      }
    } @else {
      <div @element
        class="pending-container">
        <span>Chargement des données de votre compte...</span>
        <ion-spinner></ion-spinner>
      </div>
    }
  </ng-container>
}



<ng-template #pending>
  <div @element
    class="pending-container">
    <span>Chargement des données de votre compte...</span>
    <ion-spinner></ion-spinner>
  </div>
</ng-template>

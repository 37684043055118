import { Component } from '@angular/core';
import { OmedomRadioOption } from '../radio/omedom-radio-option';
import { IncomeUpdateType } from '@omedom/data';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'omedom-income-edit-type',
    templateUrl: './income-edit-type.component.html',
    styleUrls: ['./income-edit-type.component.scss'],
})
export class IncomeEditTypeComponent {
    updateOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({
            id: IncomeUpdateType.onlyThisOne,
            label: 'Ce revenu',
        }),
        new OmedomRadioOption({
            id: IncomeUpdateType.thisOneAndNext,
            label: 'Ce revenu et les suivants',
        }),
        new OmedomRadioOption({
            id: IncomeUpdateType.all,
            label: 'Tous les revenus de la série',
        }),
    ];

    selectedUpdateOption = null;

    constructor(private modalController: ModalController) {}

    async updateConfirmed(): Promise<void> {
        await this.modalController.dismiss(this.selectedUpdateOption);
    }

    async dismiss(): Promise<void> {
        await this.modalController.dismiss();
    }
}

<div class="omedom-modal">
  <div class="omedom-modal-header">
    <div class="omedom-modal-header-title">
      <div class="logo-container">
        <img
          src="/assets/icon/replace.svg"
          alt="logo omedom"
          title="logo omedom"
          class="logo"
          />
      </div>
      @if (titleMessage !== '') {
        <h3>{{ titleMessage }}</h3>
      }
    </div>
    <i class="uil uil-times-circle" (click)="closeModal(false)"></i>
  </div>
  <div class="omedom-modal-content">
    <p>{{ infoMessage }}</p>
    <hr class="omedom-divider" />
  </div>
  <div class="omedom-modal-buttons">
    <button class="omedom-button-secondary" (click)="closeModal(false)">
      {{ rejectMessage }}
    </button>
    <button class="omedom-button-primary" (click)="closeModal(true)">
      {{ validateMessage }}
    </button>
  </div>
</div>

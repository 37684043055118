import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationReceived } from '@omedom/data';
import { gsap } from 'gsap';
import * as uuid from 'uuid';

@Component({
    selector: 'omedom-notification-popup',
    templateUrl: './notification-popup.component.html',
    styleUrls: ['./notification-popup.component.scss'],
})
export class NotificationPopupComponent implements OnInit {
    /**
     * @description Notification to display in the popup when the user receive a push notification
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/01/2024
     * @type {NotificationReceived}
     * @memberof NotificationPopupComponent
     */
    @Input({ required: true })
    public notification!: NotificationReceived;

    /**
     * @description Event emitted when the user click on the button to close the popup or when the popup is closed automatically after 5 seconds
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/01/2024
     * @memberof NotificationPopupComponent
     */
    @Output()
    public finished = new EventEmitter<void>();

    constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.id = uuid
            .v4()
            .toString()
            .replace(/-/g, '')
            .split('')
            .filter((char) => /[a-zA-Z]/.test(char))
            .join('')
            .substring(0, 10);
    }

    ngOnInit(): void {
        this.animate();
        // create a timeout to close the notification after 5 seconds
        setTimeout(() => {
            this.close();
        }, 5500);
    }

    /**
     * @description Animate the popup when it is displayed (fade in and slide up) and when it is closed (slide down and fade out)
     * @author Jérémie Lopez
     * @private
     * @memberof LevelSuccessPopupComponent
     */
    private animate(): void {
        const tl = gsap.timeline();
        tl.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { opacity: 0, y: 100 },
            { opacity: 1, y: 0, duration: 0.5 }
        );
        tl.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { maxWidth: '70px' },
            { maxWidth: '400px', duration: 0.5 }
        );

        tl.fromTo(
            `#${this.elementRef.nativeElement.id} .notification-text-container`,
            { display: 'none' },
            { display: 'flex', duration: 0.1, delay: -0.5 }
        );
        tl.fromTo(
            `#${this.elementRef.nativeElement.id} .notification-text-container .notification-title`,
            { opacity: 0, y: -12.5 },
            { opacity: 1, y: 0, duration: 0.5 }
        );

        tl.fromTo(
            `#${this.elementRef.nativeElement.id} .notification-icon-container`,
            { backgroundColor: '#eeeeee' },
            { backgroundColor: '#0076c8', duration: 0.5 }
        );

        tl.to(`#${this.elementRef.nativeElement.id} .notification-icon-container #bell path`, {
            fill: '#fff',
            duration: 0.5,
            delay: -0.5,
        });

        tl.fromTo(
            `#${this.elementRef.nativeElement.id} .notification-text-container .notification-body`,
            { opacity: 0, y: 20, maxHeight: 0 },
            { opacity: 1, y: 0, duration: 0.5, maxHeight: '320px' }
        );
        tl.to(`#${this.elementRef.nativeElement.id}`, {
            maxHeight: '400px',
            duration: 0.5,
            delay: -0.5,
        });

        const tl3 = gsap.timeline();
        tl3.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { scale: 0.9 },
            { scale: 1.1, duration: 0.15 }
        );
        tl3.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { scale: 1.1 },
            { scale: 1, duration: 0.15 }
        );
        tl3.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { scale: 1 },
            { scale: 1.1, duration: 0.15 }
        );
        tl3.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { scale: 1.1 },
            { scale: 0.9, duration: 0.15 }
        );
        tl3.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { scale: 0.9 },
            { scale: 1, duration: 0.15 }
        );
    }

    /**
     * @description Close the notification
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/01/2024
     * @memberof NotificationPopupComponent
     */
    public close(): void {
        const t4 = gsap.timeline();
        t4.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { opacity: 1, y: 0 },
            { opacity: 0, y: -100, duration: 1 }
        );
        t4.eventCallback('onComplete', () => {
            this.finished.emit();
        });
    }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    BankTransactionPatrimonyForm,
    EntityTypes,
    PropertyEntity,
    SelectOption,
    SocietyEntity,
    UserEntity,
} from '@omedom/data';
import { PropertyService, RoleService, SocietyService, UserService } from '@omedom/services';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { elementAnimation } from '../../../../animations';

@Component({
    selector: 'omedom-bank-transaction-patrimony-step',
    templateUrl: './bank-transaction-patrimony-step.container.html',
    styleUrls: ['./bank-transaction-patrimony-step.container.scss'],
    animations: [elementAnimation],
})
export class BankTransactionPatrimonyStepContainer implements OnInit, OnDestroy {
    /**
     * @description User data observable
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @type {Observable<UserEntity>}
     * @memberof BankTransactionAssociationFormPage
     */
    public user$?: Observable<UserEntity>;

    /**
     * @description List of properties options for the select input in the form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @type {SelectOption[]}
     * @memberof BankTransactionPatrimonyStepContainer
     */
    public propertiesOptions: SelectOption[] = [];

    /**
     * @description Placeholder for the properties select input in the form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {SelectOption}
     * @memberof BankTransactionPatrimonyStepContainer
     */
    public propertiesPlaceholder: SelectOption = {
        label: 'Choisir dans ma liste de biens',
        id: null,
        icon: 'uil uil-home',
    };

    /**
     * @description List of societies options for the select input in the form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @type {SelectOption[]}
     * @memberof BankTransactionPatrimonyStepContainer
     */
    public societiesOptions: SelectOption[] = [];

    /**
     * @description Placeholder for the societies select input in the form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {SelectOption}
     * @memberof BankTransactionPatrimonyStepContainer
     */
    public societiesPlaceholder: SelectOption = {
        label: 'Choisir dans ma liste de sociétés',
        id: null,
        icon: 'uil uil-suitcase',
    };

    /**
     * @description State of the page (pending, ok, error, etc.)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @memberof BankTransactionPatrimonyStepContainer
     */
    public state$ = new BehaviorSubject<string>('pending');

    /**
     * @description List of subscriptions to unsubscribe when the component is destroyed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @private
     * @type {Subscription[]}
     * @memberof BankTransactionPatrimonyStepContainer
     */
    private subscriptions: Subscription[] = [];

    /**
     * @description Form group input for the patrimony step in the form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {FormGroup<BankTransactionPatrimonyForm>}
     * @memberof BankTransactionPatrimonyStepContainer
     */
    @Input({ required: true })
    public form?: FormGroup<BankTransactionPatrimonyForm>;

    constructor(
        private userService: UserService,
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private roleService: RoleService
    ) {}

    ngOnInit() {
        this.user$ = this.userService.user$;

        this.getOptions();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    ngOnChanges() {
        this.initForm();
    }

    /**
     * @description Get the properties ans societies options for the select input in the form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @private
     * @memberof BankTransactionPatrimonyStepContainer
     */
    private getOptions(): void {
        // Set the state to pending
        this.state$.next('pending');

        if (!this.user$) {
            return;
        }

        this.subscriptions.push(
            this.user$
                .pipe(
                    switchMap((user: UserEntity) => {
                        return combineLatest([
                            this.propertyService._getUserPropertiesAndSharedAccessible(user.uid),
                            this.societyService._getUserSocietiesAndShared(user.uid),
                        ]);
                    }),

                    map((entities: [PropertyEntity[], SocietyEntity[]]) => {
                        // Check if the entities are valid
                        if (!entities || entities.length !== 2) {
                            return;
                        }

                        const [properties, societies] = entities;

                        return {
                            properties,
                            societies,
                        };
                    }),
                    tap(async (data) => {
                        // Check if the data is valid
                        if (!data) {
                            this.state$.next('error');

                            return;
                        }

                        const { properties, societies } = data;

                        const filteredProperties = await Promise.all(
                            properties.map(async (property) => {
                                const roleState = await this.roleService.getRoleState(
                                    property,
                                    EntityTypes.property
                                );
                                return roleState.create
                                    ? property
                                    : ({} as Partial<PropertyEntity>);
                            })
                        );

                        const filteredSocieties = await Promise.all(
                            societies.map(async (society) => {
                                const roleState = await this.roleService.getRoleState(
                                    society,
                                    EntityTypes.society
                                );
                                return roleState.create ? society : ({} as Partial<SocietyEntity>);
                            })
                        );

                        // Check if the properties and societies are valid
                        // and transform them into select options
                        this.propertiesOptions = filteredProperties
                            .filter((p) => !!p.uid)
                            .map((property) =>
                                property.toSelectOption
                                    ? property.toSelectOption()
                                    : ({ id: property.uid, label: property.name } as SelectOption)
                            );

                        this.societiesOptions = filteredSocieties
                            .filter((s) => !!s.uid)
                            .map((society) =>
                                society.toSelectOption
                                    ? society.toSelectOption()
                                    : ({ id: society.uid, label: society.name } as SelectOption)
                            );

                        // Set the state to ok
                        this.state$.next('ok');
                    })
                )
                .subscribe()
        );
    }

    /**
     * @description Initialize the form and its controls for the patrimony step in the form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @private
     * @returns {void}
     * @memberof BankTransactionPatrimonyStepContainer
     */
    private initForm(): void {
        // Check if the form exists
        if (!this.form) {
            return;
        }

        this.subscriptions.push(
            this.form.controls.propertyUID.valueChanges.subscribe((propertyUID: string) => {
                // Check if the property UID is valid
                if (!propertyUID) {
                    return;
                }

                this.form?.controls.societyUID.setValue('');
            }),

            this.form.controls.societyUID.valueChanges.subscribe((societyUID: string) => {
                // Check if the society UID is valid
                if (!societyUID) {
                    return;
                }

                this.form?.controls.propertyUID.setValue('');
            })
        );
    }
}

<div class="omedom-modal">
    <div class="omedom-modal-header">
        <div class="omedom-modal-header-title">
            <div class="icon margin-top-1 background-color-light-green">
                <i class="uil uil-check"></i>
            </div>
            <h3>Succès !</h3>
        </div>
        <i class="uil uil-times-circle"
           (click)="dismiss()"></i>
    </div>
    <div class="omedom-modal-content">
        <p class="color-light-black">
            Vos comptes bancaires sont maintenant connectés avec succès
        </p>
        <p class="color-light-black"
           style="font-style: italic;">
            C'est parti pour un suivi en temps réel de votre trésorerie !
        </p>
    </div>
    <div class="omedom-modal-buttons w-100">
        <button class="omedom-button-primary omedom-no-margin"
                (click)="dismiss()">
            Continuer
        </button>
    </div>
</div>
import { AssetTypes, AtticTypeFr } from '../enums';
import { PostalAddress } from '../interfaces';
import { SelectOption } from '../models';
import { RestEntity } from './rest.entity';
import { SocietyRoleMember } from './society.entity';

/* eslint-disable @typescript-eslint/naming-convention */

const currentYear = new Date().getFullYear();
export const MAX_BUILDING_YEAR = currentYear + 3;
export const MIN_BUILDING_YEAR = 1700;

export const MIN_LIVING_AREA = 9; // m2
export const MAX_LIVING_AREA = 1000; // m2

export const MIN_LAND_AREA = 10; // m2
export const MAX_LAND_AREA = 100000; // m2

export const MIN_FLOOR_NUMBER = 0;
export const MAX_FLOOR_NUMBER = 20;

export enum PropertyType {
    maison = 'Maison',
    chalet = 'Chalet',
    appartement = 'Appartement',
    immeuble = 'Immeuble',
    batimentAgricole = 'Bâtiment agricole',
    hotel = 'Hôtel particulier',
    bureaux = 'Bureaux',
    localCommercial = 'Local commercial',
    parkingBox = 'Parking / Box',
    terrain = 'Terrain',
    other = 'Autre',
}

export enum UseProperty {
    principale = 'Résidence principale',
    secondaire = 'Résidence secondaire',
    locatifParticulier = 'Locatif particulier',
    locatifPro = 'Locatif professionnel',
    locatifCommercial = 'Locatif commercial',
}

export enum DetentionType {
    pleine = 'Pleine propriété',
    nu = 'Nue-propriété',
    usufruit = 'Usufruit',
    usufruitTemporaire = 'Usufruit temporaire',
}

export enum OwningType {
    proper = 'Nom propre',
    society = 'Société',
    community = 'Communauté',
    indivision = 'Indivision',
}

export enum RentType {
    empty = 'Vide',
    furnished = 'Meublé.e',
}

export enum ManagementRentType {
    direct = 'En direct',
    mandatory = 'Mandataire',
    agency = 'Agence immobilière',
    platform = 'Plateforme',
}

export enum DPEScore {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
}

export enum GlazingType {
    single = 'single',
    double = 'double',
    triple = 'triple',
}

export enum IsolationRenovationPeriod {
    moreThan10Years = 'more_than_10_years',
    last10Years = 'last_10_years',
    notRenovated = 'not_renovated',
}

export enum AtticType {
    lostAtticSpace = 'lost_attic_space',
    flatRoof = 'flat_roof',
    convertedAttic = 'converted_attic',
}

export enum Confidence {
    poor = 'poor',
    medium = 'medium',
    good = 'good',
}

export enum ConfidenceFr {
    poor = 'faible',
    mefium = 'moyen',
    good = 'fort',
}

export const glazingTypeOptions: SelectOption[] = [
    { label: 'Triple', id: 'triple' },
    { label: 'Double', id: 'double' },
    { label: 'Simple', id: 'single' },
];

export const isolationRenovationPeriodOptions: SelectOption[] = [
    { label: 'Plus de 10 ans', id: IsolationRenovationPeriod.moreThan10Years },
    { label: 'Moins de 10 ans', id: IsolationRenovationPeriod.last10Years },
    { label: 'Pas de rénovation', id: IsolationRenovationPeriod.notRenovated },
];

export const atticTypeOptions: SelectOption[] = [
    { label: AtticTypeFr.convertedAttic, id: 'converted_attic' },
    { label: AtticTypeFr.lostAtticSpace, id: 'lost_attic_space' },
    { label: AtticTypeFr.flatRoof, id: 'flat_roof' },
];

export const DPEScoreOption = [
    {
        id: DPEScore.A,
        label: DPEScore.A,
    } as SelectOption,
    {
        id: DPEScore.B,
        label: DPEScore.B,
    } as SelectOption,
    {
        id: DPEScore.C,
        label: DPEScore.C,
    } as SelectOption,
    {
        id: DPEScore.D,
        label: DPEScore.D,
    } as SelectOption,
    {
        id: DPEScore.E,
        label: DPEScore.E,
    } as SelectOption,
    {
        id: DPEScore.F,
        label: DPEScore.F,
    } as SelectOption,
    {
        id: DPEScore.G,
        label: DPEScore.G,
    } as SelectOption,
];
export interface PropertyPurchaseDetails {
    use: UseProperty | UseProperty[];
    detention: DetentionType;
    owning: OwningType;
    year?: number;
    price?: number;
    notaryFees?: number;
    marketValue?: number;
    agencyFees?: number;
    propertyTax?: number;
    housingTax?: number;
}

export interface PropertyRentDetails {
    type: RentType;
    managementType: ManagementRentType;
}

export interface Sharing {
    userUID?: string;
    name: string;
    firstname: string;
    email: string;
    role?: SocietyRoleMember;
}

export interface AssuranceDetails {
    assuranceName: string;
    assuranceContractNumber: string;
}

export interface DPEDetails {
    dpeScore: DPEScore;
    dpeDate: Date;
    estimationInformations?: EstimationInformation;
    estimationResult?: EstimationDPE;
}

export interface PropertyAccess {
    renewDate: string;
    firstProperty: boolean;
}

export interface PricehubbleInfo {
    folderId: string;
}

export interface EstimationInformation {
    isolationRenovationPeriod?: IsolationRenovationPeriod;
    numberOfExternalFacingWalls?: number;
    numberOfWindows?: number;
    atticType?: AtticType;
    glazingType?: GlazingType;
}

export interface Valorisation {
    confidence: Confidence;
}

export interface RenovationCalculatorDPE {
    currentEnergyLabel: DPEScore;
    renovations: Renovation[];
}

export interface Renovation {
    energyLabelRenovation: DPEScore;
    renovationCosts: NumericRange;
    salePriceDifference: NumericRange;
    rentGrossDifference?: NumericRange;
}

export interface Valuation {
    valuationSale: ValuationInfo;
    valuationRentGross: ValuationInfo;
    valuationRentNet: ValuationInfo;
}
export interface ValuationInfo {
    value: number;
    valueRange: {
        lower: number;
        upper: number;
    };
    valuationConfidence: Confidence;
    valuationDate: string;
}

export interface NumericRange {
    min: number;
    avg: number;
    max: number;
}

export interface EstimationDPE {
    energyLabel?: RangeEnergyDPE;
    energyConsumption?: energyMeasure[];
    ghgEmission?: energyMeasure[];
    // officialEnergyConsumption: 'primary';
}

export interface RangeEnergyDPE {
    value: DPEScore;
    lower: DPEScore;
    upper: DPEScore;
}

export interface RangeEnergyNumberScore {
    value: DPEScore;
    lower: DPEScore;
    upper: DPEScore;
}
export interface RangeEnergyNumberMeasure {
    value: number;
    lower: number;
    upper: number;
    unit?: string;
    type?: string;
}

export interface energyMeasure {
    label?: RangeEnergyDPE;
    measure?: RangeEnergyNumberMeasure;
    score?: RangeEnergyNumberScore;
    // type: 'primary';
}

export class PropertyEntity extends RestEntity {
    constructor(property?: Partial<PropertyEntity>) {
        super();
        if (!property) {
            return;
        }
        Object.assign(this, property);
    }

    /**
     * @description Name of the property in the app
     * @author Jérémie Lopez
     * @type {string}
     * @memberof PropertyEntity
     */
    public name!: string;

    /**
     * @description Property Type or legal status
     * @author Jérémie Lopez
     * @type {PropertyType}
     * @memberof PropertyEntity
     */
    public type!: PropertyType;

    /**
     * @description If true, it change the display of the property
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof PropertyEntity
     */
    public divisible!: boolean;

    /**
     * @description Address of the property
     * @author Jérémie Lopez
     * @type {PostalAddress}
     * @memberof PropertyEntity
     */
    public address?: PostalAddress;

    /**
     * @description Building year of the property
     * @author Jérémie Lopez
     * @type {number}
     * @memberof PropertyEntity
     */
    public buildingYear!: number;

    /**
     * @description Purchase details of the property
     * @author Jérémie Lopez
     * @type {PropertyPurchaseDetails}
     * @memberof PropertyEntity
     */
    public purchaseDetails?: PropertyPurchaseDetails;

    /**
     * @description Rent details of the property
     * @author Jérémie Lopez
     * @type {PropertyRentDetails}
     * @memberof PropertyEntity
     */
    public rentDetails?: PropertyRentDetails;

    /**
     * @description Photo of the property
     * @author Jérémie Lopez
     * @type {string}
     * @memberof PropertyEntity
     */
    public photo?: string;

    /**
     * @description Room count of the property
     * @author Jérémie Lopez
     * @type {number}
     * @memberof PropertyEntity
     */
    public roomCount!: number;

    /**
     * @description Living space in m2
     * @author Jérémie Lopez
     * @type {number}
     * @memberof PropertyEntity
     */
    public livingSpace!: number;

    /**
     * @description Land Area in m2
     * @author Jérémie Lopez
     * @type {number}
     * @memberof PropertyEntity
     */
    public landArea!: number;

    /**
     * @description Amenities of the property
     * @author Jérémie Lopez
     * @type {string[]}
     * @memberof PropertyEntity
     */
    public amenities?: string[];

    /**
     * @description UID of the property's owner
     * @author Jérémie Lopez
     * @type {string}
     * @memberof PropertyEntity
     */
    public userUID!: string;

    /**
     * @description UID of the property parent
     * @author Jérémie Lopez
     * @type {string}
     * @memberof PropertyEntity
     */
    public propertyUID?: string;

    /**
     * @description UID of the society parent
     * @author Jérémie Lopez
     * @type {string}
     * @memberof PropertyEntity
     */
    public societyUID?: string;

    /**
     * @description List of user that the property is sharing
     * @author Jérémie Lopez
     * @type {string[]}
     * @memberof PropertyEntity
     */
    public sharing!: Sharing[];

    /**
     * @description Lease of the property
     * @author Jérémie Lopez
     * @type {string}
     * @memberof PropertyEntity
     */
    tenantIds!: string[];

    /**
     * @description
     * @author Brisset Killian
     * @type {PropertyAccess}
     * @memberof PropertyEntity
     * @date 2024-02-14
     */
    public accesible?: PropertyAccess;

    /**
     * @description Lease of the property
     * @author Jérémie Lopez
     * @type {string}
     * @memberof PropertyEntity
     */
    public leaseUID?: string;

    /**
     * @description Assurance details of the property
     * @author Hanane Djeddal
     * @type {AssuranceDetails}
     * @memberof PropertyEntity
     */
    public assuranceDetails?: AssuranceDetails;

    /**
     * @description DPE score details of the property
     * @author Hanane Djeddal
     * @type {DPEDetails}
     * @memberof PropertyEntity
     */
    public dpeDetails?: DPEDetails;

    /**
     * @description additional notes for the property
     * @author Hanane Djeddal
     * @type {string}
     * @memberof PropertyEntity
     */
    public notes!: string;

    /**
     * @description If true, the property is shared to the pro of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/09/2023
     * @type {boolean}
     * @memberof PropertyEntity
     */
    public sharedToPro?: boolean;

    /**
     * @description
     * @author ANDRE Felix
     * @type {string[]}
     * @memberof PropertyEntity
     */
    public lotsUID!: string[];

    /**
     * @description UID of parent property, like building
     * @author ANDRE Felix
     * @type {string}
     * @memberof PropertyEntity
     */
    public parentPropertyUID!: string;

    /**
     * @description Infos received from pricehubble
     * @author ANDRE Felix
     * @type {PricehubbleInfo}
     * @memberof PropertyEntity
     */
    public pricehubble?: PricehubbleInfo;

    /**
     * @description for appartement, it indicates the floor of the appartement
     * @author ANDRE Felix
     * @type {number}
     * @memberof PropertyEntity
     */
    public floor?: number;

    /**
     * @description it indicates the number of floor in a building or in a house (used for appartement, chalet,...)
     * @author ANDRE Felix
     * @type {number}
     * @memberof PropertyEntity
     */
    public floorNumber?: number;

    /**
     * @description GlazingType for the window or glass Door, forPricehubble
     * @author ANDRE Felix
     * @type {GlazingType}
     * @memberof PropertyEntity
     */
    public glazingType?: GlazingType;

    /**
     * @description Number of window, for Pricehubble
     * @author ANDRE Felix
     * @type {number}
     * @memberof PropertyEntity
     */
    public numberOfWindows?: number;

    /**
     * @description Number of external Facing wall, for Pricehubble
     * @author ANDRE Felix
     * @type {number}
     * @memberof PropertyEntity
     */
    public numberOfExternalFacingWalls?: number;

    /**
     * @description Period of last renovation
     * @author ANDRE Felix
     * @type {IsolationRenovationPeriod}
     * @memberof PropertyEntity
     */
    public isolationRenovationPeriod?: IsolationRenovationPeriod;

    /**
     * @description Valuation from pricehubble
     * @author ANDRE Felix
     * @type {Valuation}
     * @memberof PropertyEntity
     */
    public valuation?: Valuation;

    /**
     * @description Estimation of the cost for isolation renovation
     * @author ANDRE Felix
     * @type {RenovationCalculatorDPE}
     * @memberof PropertyEntity
     */
    renovationCalculation?: RenovationCalculatorDPE;

    /**
     * @description Transforms the property into a select option for the UI
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @returns {SelectOption}
     * @memberof PropertyEntity
     */
    public toSelectOption(): SelectOption {
        let icon: string | undefined;
        if (!this.photo) {
            icon = this.type === PropertyType.immeuble ? 'uil uil-building' : 'uil uil-home';
        }
        return {
            id: this.uid,
            label: this.name,
            icon,
            image: this.photo,
            purchaseYear: this.purchaseDetails?.year,
            assetType:
                this.type === PropertyType.immeuble ? AssetTypes.building : AssetTypes.property,
        };
    }
}

/**
 * @description postal adress for classic mail sending
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 07/02/2024
 * @export
 * @interface PostalAddress
 */
export interface PostalAddress {
    streetNumber?: number | null;
    suffixNumber?: string | null;
    street?: string;
    postalCode?: string;
    city?: string;
    addressLine2?: string;
}

/**
 * @description Date and location of the document creation
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 07/02/2024
 * @export
 * @interface DateAndLocation
 */
export interface DateAndLocation {
    date: Date; // date of the document creation
    location: string; // city where the document is created
}

/**
 * @description Interface of the different role existing
 * @author ANDRE Felix
 * @export
 * @interface RolesInterface
 */
export interface RolesInterface {
    owner: string;
    admin: string;
    editor: string;
    reader: string;
    none: string;
}

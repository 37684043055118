import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BankAccountEntity, BankAccountType, BankEntity } from '@omedom/data';

/**
 * @description Layout of the progress bar widget (default, dashboard or mobile)
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 23/09/2024
 * @export
 * @enum {number}
 */
export enum BankingAmountWidgetLayout {
    WIDGET = 'widget',
    DASHBOARD = 'dashboard',
}

@Component({
    selector: 'omedom-widget-banking-amount',
    templateUrl: './widget-banking-amount.component.html',
    styleUrls: ['./widget-banking-amount.component.scss'],
})

export class WidgetBankingAmountComponent implements OnInit {

    /**
     * @description Title of the progress bar widget
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/09/2024
     * @memberof WidgetBankingAmountComponent
     */
    @Input() title = 'Solde bancaire total';

    /**
     * @description List of bank accounts of the user
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 30/10/2024
     * @type {BankAccountEntity[]}
     * @memberof WidgetBankingAmountComponent
     */
    @Input() accounts: BankAccountEntity[] = [];

    /**
     * @description Icon of the progress bar widget
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/09/2024
     * @type {string}
     * @memberof WidgetBankingAmountComponent
     */
    @Input() icon = 'uil uil-balance-scale';

    /**
     * @description Layout of the progress bar widget (default, dashboard or mobile)
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 06/11/2024
     * @type {BankingAmountWidgetLayout}
     * @memberof WidgetBankingAmountComponent
     */
    @Input() layout: BankingAmountWidgetLayout = BankingAmountWidgetLayout.WIDGET;

    /**
     * @description List of banks of the user to display the bank logo in the widget header
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 06/11/2024
     * @type {BankEntity[]}
     * @memberof WidgetBankingAmountComponent
     */
    @Input() banks?: BankEntity[] = [];

    constructor(
        private readonly ref: ElementRef,
        private navController: NavController,
    ) { }

    ngOnInit(): void {
        // Add the layout class to ref
        this.ref.nativeElement.classList.add(`layout-${this.layout}`);
    }

    /**
 * @description Compute the overall balance of the user bank accounts
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 22/04/2024
 * @type {number}
 * @memberof WidgetBankingAmountComponent
 */
    public get overallBalance(): number {
        return this.accounts.reduce((acc, account) => {
            // Do not display the balance of a loan account
            // Or a brokerage account
            // Or a shared saving plan account
            if (
                account.type === BankAccountType.Loan ||
                account.type === BankAccountType.Brokerage ||
                account.type === BankAccountType.SharedSavingPlan ||
                account.type === BankAccountType.LifeInsurance ||
                account.type === BankAccountType.Savings
            ) {
                return acc;
            }

            return acc + account.balance;
        }, 0);
    }
}

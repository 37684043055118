<ion-app>
    @if (user$ | async) {
        <omedom-sidenav>
            <img
                logo
                src="/assets/icon/white-logo.svg"
                alt="Logo OMEDOM"
                routerLink="/"
                title="Logo OMEDOM"
                style="width: 100% !important; height: 100px !important"
            />
            <!-- Navigation Primary -->

            @for (
                navigation of getNavigation('primary');
                track navigation.path
            ) {
                <omedom-sidenav-item
                    nav-primary
                    [routerLink]="navigation.path"
                    routerLinkActive="active"
                    [icon]="navigation.icon"
                    [label]="navigation.title"
                    [isOmedomIcon]="navigation.isOmedomIcon ?? false"
                ></omedom-sidenav-item>
            }
            <!-- Navigation Secondary -->
            @for (
                navigation of getNavigation('secondary');
                track navigation.path
            ) {
                <omedom-sidenav-item
                    nav-secondary
                    [routerLink]="navigation.path"
                    routerLinkActive="active"
                    [icon]="navigation.icon"
                    [label]="navigation.title"
                    [isOmedomIcon]="navigation.isOmedomIcon ?? false"
                ></omedom-sidenav-item>
            }

            <omedom-sidenav-item
                nav-secondary
                (click)="logout()"
                icon="signout"
                label="Se déconnecter"
            ></omedom-sidenav-item>
        </omedom-sidenav>
    }

    <main>
        @if (user$ | async) {
            <omedom-toolbar
                [enableBack]="enableBack"
                [title]="title"
                [titleFontSize]="titleFontSize"
                [icon]="icon"
                [isOmedomIcon]="isOmedomIcon"
            >
                <button
                    (click)="modal.present()"
                    toolbar-action
                    class="omedom-icon-button-secondary-outline"
                >
                    <i class="uil uil-bell"></i>
                </button>
            </omedom-toolbar>
        }

        <div class="container">
            <router-outlet></router-outlet>
        </div>
    </main>
</ion-app>

<ion-modal #modal>
    <ng-template>
        <div class="omedom-modal">
            <div class="omedom-modal-header">
                <div class="omedom-modal-header-title">
                    <h3>Évènement(s) client(s)</h3>
                </div>
                <i class="uil uil-times-circle" (click)="modal.dismiss()"></i>
            </div>
            <div class="omedom-modal-content">
                @if (proAlerts$ | async; as alerts) {
                    @if (alerts.length > 0) {
                        <omedom-sub-tab
                            [subTabs]="proAlertSubTab"
                            [(selectedSubTab)]="selectedProAlertSubTab"
                        ></omedom-sub-tab>
                        @if (getAlertsBySubTab(alerts); as alertsBySubTab) {
                            <div
                                class="alert-list"
                                [@list]="alertsBySubTab.length"
                            >
                                @for (alert of alertsBySubTab; track $index) {
                                    <omedom-pro-alert-card
                                        [proAlert]="alert"
                                        [hasEnableActions]="true"
                                        (startChat)="
                                            createChatWithClient($event)
                                        "
                                    >
                                    </omedom-pro-alert-card>
                                }
                            </div>
                        }
                    }
                } @else {
                    <div class="omedom-modal-content">
                        <p>Vous n'avez aucun évènement client.</p>
                    </div>
                }
            </div>
        </div>
    </ng-template>
</ion-modal>

<ion-modal #startChatModal [breakpoints]="[0, 1]" initialBreakpoint="1">
    <ng-template>
        <div class="omedom-modal">
            <div class="omedom-modal-header">
                <div class="omedom-modal-header-title">
                    <h3>Envoyer un message</h3>
                </div>
                <ion-icon
                    name="close"
                    (click)="startChatModal.dismiss()"
                ></ion-icon>
            </div>
            <div class="omedom-modal-content">
                <omedom-input label="Objet" #chatName> </omedom-input>
                <omedom-input
                    label="Message"
                    #message
                    class="textarea"
                ></omedom-input>
            </div>
            <div class="omedom-modal-footer start-chat-modal-footer">
                <button
                    class="omedom-button-warn-secondary"
                    (click)="startChatModal.dismiss()"
                >
                    Annuler
                </button>
                <button
                    class="omedom-button-secondary"
                    [disabled]="!chatName.value || !message.value"
                    (click)="
                        startChatModal.dismiss({
                            chatName: chatName.value,
                            message: message.value
                        })
                    "
                >
                    Envoyer
                </button>

                <button
                    class="omedom-button-primary"
                    [disabled]="!chatName.value || !message.value"
                    (click)="
                        startChatModal.dismiss({
                            chatName: chatName.value,
                            message: message.value,
                            treat: true
                        })
                    "
                >
                    Envoyer et traiter
                </button>
            </div>
        </div>
    </ng-template>
</ion-modal>

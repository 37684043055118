/**
 * @description Email utils class
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 09/08/2023
 * @export
 * @class OmedomEmail
 */
export class OmedomEmail {
    /**
     * @description List of blacklisted email domain
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @static
     * @type {string[]}
     * @memberof Email
     */
    public static blackList: string[] = [
        '0815.ru',
        '0wnd.net',
        '0wnd.org',
        '10minutemail.co.za',
        '10minutemail.com',
        '123-m.com',
        '1fsdfdsfsdf.tk',
        '1pad.de',
        '20minutemail.com',
        '21cn.com',
        '2fdgdfgdfgdf.tk',
        '2prong.com',
        '30minutemail.com',
        '33mail.com',
        '3trtretgfrfe.tk',
        '4gfdsgfdgfd.tk',
        '4warding.com',
        '5ghgfhfghfgh.tk',
        '6hjgjhgkilkj.tk',
        '6paq.com',
        '7tags.com',
        '9ox.net',
        'a-bc.net',
        'agedmail.com',
        'ama-trade.de',
        'amilegit.com',
        'amiri.net',
        'amiriindustries.com',
        'anonmails.de',
        'anonymbox.com',
        'antichef.com',
        'antichef.net',
        'antireg.ru',
        'antispam.de',
        'antispammail.de',
        'armyspy.com',
        'artman-conception.com',
        'azmeil.tk',
        'baxomale.ht.cx',
        'beefmilk.com',
        'bigstring.com',
        'binkmail.com',
        'bio-muesli.net',
        'bobmail.info',
        'bodhi.lawlita.com',
        'bofthew.com',
        'bootybay.de',
        'boun.cr',
        'bouncr.com',
        'breakthru.com',
        'brefmail.com',
        'bsnow.net',
        'bspamfree.org',
        'bugmenot.com',
        'bund.us',
        'burstmail.info',
        'buymoreplays.com',
        'byom.de',
        'c2.hu',
        'card.zp.ua',
        'casualdx.com',
        'cek.pm',
        'centermail.com',
        'centermail.net',
        'chammy.info',
        'childsavetrust.org',
        'chogmail.com',
        'choicemail1.com',
        'clixser.com',
        'cmail.net',
        'cmail.org',
        'coldemail.info',
        'cool.fr.nf',
        'courriel.fr.nf',
        'courrieltemporaire.com',
        'crapmail.org',
        'cust.in',
        'cuvox.de',
        'd3p.dk',
        'dacoolest.com',
        'dandikmail.com',
        'dayrep.com',
        'dcemail.com',
        'deadaddress.com',
        'deadspam.com',
        'delikkt.de',
        'despam.it',
        'despammed.com',
        'devnullmail.com',
        'dfgh.net',
        'digitalsanctuary.com',
        'dingbone.com',
        'disposableaddress.com',
        'disposableemailaddresses.com',
        'disposableinbox.com',
        'dispose.it',
        'dispostable.com',
        'dodgeit.com',
        'dodgit.com',
        'donemail.ru',
        'dontreg.com',
        'dontsendmespam.de',
        'drdrb.net',
        'dump-email.info',
        'dumpandjunk.com',
        'dumpyemail.com',
        'e-mail.com',
        'e-mail.org',
        'e4ward.com',
        'easytrashmail.com',
        'einmalmail.de',
        'einrot.com',
        'eintagsmail.de',
        'emailgo.de',
        'emailias.com',
        'emaillime.com',
        'emailsensei.com',
        'emailtemporanea.com',
        'emailtemporanea.net',
        'emailtemporar.ro',
        'emailtemporario.com.br',
        'emailthe.net',
        'emailtmp.com',
        'emailwarden.com',
        'emailx.at.hm',
        'emailxfer.com',
        'emeil.in',
        'emeil.ir',
        'emz.net',
        'ero-tube.org',
        'evopo.com',
        'explodemail.com',
        'express.net.ua',
        'eyepaste.com',
        'fakeinbox.com',
        'fakeinformation.com',
        'fansworldwide.de',
        'fantasymail.de',
        'fightallspam.com',
        'filzmail.com',
        'fivemail.de',
        'fleckens.hu',
        'frapmail.com',
        'friendlymail.co.uk',
        'fuckingduh.com',
        'fudgerub.com',
        'fyii.de',
        'garliclife.com',
        'gehensiemirnichtaufdensack.de',
        'get2mail.fr',
        'getairmail.com',
        'getmails.eu',
        'getonemail.com',
        'giantmail.de',
        'girlsundertheinfluence.com',
        'gishpuppy.com',
        'gmial.com',
        'goemailgo.com',
        'gotmail.net',
        'gotmail.org',
        'gotti.otherinbox.com',
        'great-host.in',
        'greensloth.com',
        'grr.la',
        'gsrv.co.uk',
        'guerillamail.biz',
        'guerillamail.com',
        'guerrillamail.biz',
        'guerrillamail.com',
        'guerrillamail.de',
        'guerrillamail.info',
        'guerrillamail.net',
        'guerrillamail.org',
        'guerrillamailblock.com',
        'gustr.com',
        'harakirimail.com',
        'hat-geld.de',
        'hatespam.org',
        'herp.in',
        'hidemail.de',
        'hidzz.com',
        'hmamail.com',
        'hopemail.biz',
        'ieh-mail.de',
        'ikbenspamvrij.nl',
        'imails.info',
        'inbax.tk',
        'inbox.si',
        'inboxalias.com',
        'inboxclean.com',
        'inboxclean.org',
        'infocom.zp.ua',
        'instant-mail.de',
        'ip6.li',
        'irish2me.com',
        'iwi.net',
        'jetable.com',
        'jetable.fr.nf',
        'jetable.net',
        'jetable.org',
        'jnxjn.com',
        'jourrapide.com',
        'jsrsolutions.com',
        'kasmail.com',
        'kaspop.com',
        'killmail.com',
        'killmail.net',
        'klassmaster.com',
        'klzlk.com',
        'koszmail.pl',
        'kurzepost.de',
        'lawlita.com',
        'letthemeatspam.com',
        'lhsdv.com',
        'lifebyfood.com',
        'link2mail.net',
        'litedrop.com',
        'lol.ovpn.to',
        'lolfreak.net',
        'lookugly.com',
        'lortemail.dk',
        'lr78.com',
        'lroid.com',
        'lukop.dk',
        'm21.cc',
        'mail-filter.com',
        'mail-temporaire.fr',
        'mail.by',
        'mail.mezimages.net',
        'mail.zp.ua',
        'mail1a.de',
        'mail21.cc',
        'mail2rss.org',
        'mail333.com',
        'mailbidon.com',
        'mailbiz.biz',
        'mailblocks.com',
        'mailbucket.org',
        'mailcat.biz',
        'mailcatch.com',
        'mailde.de',
        'mailde.info',
        'maildrop.cc',
        'maileimer.de',
        'mailexpire.com',
        'mailfa.tk',
        'mailforspam.com',
        'mailfreeonline.com',
        'mailguard.me',
        'mailin8r.com',
        'mailinater.com',
        'mailinator.com',
        'mailinator.net',
        'mailinator.org',
        'mailinator2.com',
        'mailincubator.com',
        'mailismagic.com',
        'mailme.lv',
        'mailme24.com',
        'mailmetrash.com',
        'mailmoat.com',
        'mailms.com',
        'mailnesia.com',
        'mailnull.com',
        'mailorg.org',
        'mailpick.biz',
        'mailrock.biz',
        'mailscrap.com',
        'mailshell.com',
        'mailsiphon.com',
        'mailtemp.info',
        'mailtome.de',
        'mailtothis.com',
        'mailtrash.net',
        'mailtv.net',
        'mailtv.tv',
        'mailzilla.com',
        'makemetheking.com',
        'manybrain.com',
        'mbx.cc',
        'mega.zik.dj',
        'meinspamschutz.de',
        'meltmail.com',
        'messagebeamer.de',
        'mezimages.net',
        'ministry-of-silly-walks.de',
        'mintemail.com',
        'misterpinball.de',
        'moncourrier.fr.nf',
        'monemail.fr.nf',
        'monmail.fr.nf',
        'monumentmail.com',
        'mt2009.com',
        'mt2014.com',
        'mycard.net.ua',
        'mycleaninbox.net',
        'mymail-in.net',
        'mypacks.net',
        'mypartyclip.de',
        'myphantomemail.com',
        'mysamp.de',
        'mytempemail.com',
        'mytempmail.com',
        'mytrashmail.com',
        'nabuma.com',
        'neomailbox.com',
        'nepwk.com',
        'nervmich.net',
        'nervtmich.net',
        'netmails.com',
        'netmails.net',
        'neverbox.com',
        'nice-4u.com',
        'nincsmail.hu',
        'nnh.com',
        'no-spam.ws',
        'noblepioneer.com',
        'nomail.pw',
        'nomail.xl.cx',
        'nomail2me.com',
        'nomorespamemails.com',
        'nospam.ze.tc',
        'nospam4.us',
        'nospamfor.us',
        'nospammail.net',
        'notmailinator.com',
        'nowhere.org',
        'nowmymail.com',
        'nurfuerspam.de',
        'nus.edu.sg',
        'objectmail.com',
        'obobbo.com',
        'odnorazovoe.ru',
        'oneoffemail.com',
        'onewaymail.com',
        'onlatedotcom.info',
        'online.ms',
        'opayq.com',
        'ordinaryamerican.net',
        'otherinbox.com',
        'ovpn.to',
        'owlpic.com',
        'pancakemail.com',
        'pcusers.otherinbox.com',
        'pjjkp.com',
        'plexolan.de',
        'poczta.onet.pl',
        'politikerclub.de',
        'poofy.org',
        'pookmail.com',
        'privacy.net',
        'privatdemail.net',
        'proxymail.eu',
        'prtnx.com',
        'putthisinyourspamdatabase.com',
        'qq.com',
        'quickinbox.com',
        'rcpt.at',
        'reallymymail.com',
        'realtyalerts.ca',
        'recode.me',
        'recursor.net',
        'reliable-mail.com',
        'rhyta.com',
        'rmqkr.net',
        'royal.net',
        'rtrtr.com',
        's0ny.net',
        'safe-mail.net',
        'safersignup.de',
        'safetymail.info',
        'safetypost.de',
        'saynotospams.com',
        'schafmail.de',
        'schrott-email.de',
        'secretemail.de',
        'secure-mail.biz',
        'senseless-entertainment.com',
        'services391.com',
        'sharklasers.com',
        'shieldemail.com',
        'shiftmail.com',
        'shitmail.me',
        'shitware.nl',
        'shmeriously.com',
        'shortmail.net',
        'sibmail.com',
        'sinnlos-mail.de',
        'slapsfromlastnight.com',
        'slaskpost.se',
        'smashmail.de',
        'smellfear.com',
        'snakemail.com',
        'sneakemail.com',
        'sneakmail.de',
        'snkmail.com',
        'sofimail.com',
        'solvemail.info',
        'sogetthis.com',
        'soodonims.com',
        'spam4.me',
        'spamail.de',
        'spamarrest.com',
        'spambob.net',
        'spambog.ru',
        'spambox.us',
        'spamcannon.com',
        'spamcannon.net',
        'spamcon.org',
        'spamcorptastic.com',
        'spamcowboy.com',
        'spamcowboy.net',
        'spamcowboy.org',
        'spamday.com',
        'spamex.com',
        'spamfree.eu',
        'spamfree24.com',
        'spamfree24.de',
        'spamfree24.org',
        'spamgoes.in',
        'spamgourmet.com',
        'spamgourmet.net',
        'spamgourmet.org',
        'spamherelots.com',
        'spamhereplease.com',
        'spamhole.com',
        'spamify.com',
        'spaml.de',
        'spammotel.com',
        'spamobox.com',
        'spamslicer.com',
        'spamspot.com',
        'spamthis.co.uk',
        'spamtroll.net',
        'speed.1s.fr',
        'spoofmail.de',
        'stuffmail.de',
        'super-auswahl.de',
        'supergreatmail.com',
        'supermailer.jp',
        'superrito.com',
        'superstachel.de',
        'suremail.info',
        'talkinator.com',
        'teewars.org',
        'teleworm.com',
        'teleworm.us',
        'temp-mail.org',
        'temp-mail.ru',
        'tempe-mail.com',
        'tempemail.co.za',
        'tempemail.com',
        'tempemail.net',
        'tempinbox.co.uk',
        'tempinbox.com',
        'tempmail.eu',
        'tempmaildemo.com',
        'tempmailer.com',
        'tempmailer.de',
        'tempomail.fr',
        'temporaryemail.net',
        'temporaryforwarding.com',
        'temporaryinbox.com',
        'temporarymailaddress.com',
        'tempthe.net',
        'thankyou2010.com',
        'thc.st',
        'thelimestones.com',
        'thisisnotmyrealemail.com',
        'thismail.net',
        'throwawayemailaddress.com',
        'tilien.com',
        'tittbit.in',
        'tizi.com',
        'tmailinator.com',
        'toomail.biz',
        'topranklist.de',
        'tradermail.info',
        'trash-mail.at',
        'trash-mail.com',
        'trash-mail.de',
        'trash2009.com',
        'trashdevil.com',
        'trashemail.de',
        'trashmail.at',
        'trashmail.com',
        'trashmail.de',
        'trashmail.me',
        'trashmail.net',
        'trashmail.org',
        'trashymail.com',
        'trialmail.de',
        'trillianpro.com',
        'twinmail.de',
        'tyldd.com',
        'uggsrock.com',
        'umail.net',
        'uroid.com',
        'us.af',
        'venompen.com',
        'veryrealemail.com',
        'viditag.com',
        'viralplays.com',
        'vpn.st',
        'vsimcard.com',
        'vubby.com',
        'wasteland.rfc822.org',
        'webemail.me',
        'weg-werf-email.de',
        'wegwerf-emails.de',
        'wegwerfadresse.de',
        'wegwerfemail.com',
        'wegwerfemail.de',
        'wegwerfmail.de',
        'wegwerfmail.info',
        'wegwerfmail.net',
        'wegwerfmail.org',
        'wh4f.org',
        'whyspam.me',
        'willhackforfood.biz',
        'willselfdestruct.com',
        'winemaven.info',
        'wronghead.com',
        'www.e4ward.com',
        'www.mailinator.com',
        'wwwnew.eu',
        'x.ip6.li',
        'xagloo.com',
        'xemaps.com',
        'xents.com',
        'xmaily.com',
        'xoxy.net',
        'yep.it',
        'yogamaven.com',
        'yopmail.com',
        'yopmail.fr',
        'yopmail.net',
        'yourdomain.com',
        'yuurok.com',
        'z1p.biz',
        'za.com',
        'zehnminuten.de',
        'zehnminutenmail.de',
        'zippymail.info',
        'zoemail.net',
        'zomg.info',
        '0-mail.com',
        '027168.com',
        '0815.ry',
        '0815.su',
        '0845.ru',
        '0box.eu',
        '0clickemail.com',
        '0n0ff.net',
        '0nelce.com',
        '0v.ro',
        '0w.ro',
        '0x207.info',
        '1-8.biz',
        '1-tm.com',
        '10-minute-mail.com',
        '10-minuten-mail.de',
        '1000rebates.stream',
        '100likers.com',
        '105kg.ru',
        '10mail.com',
        '10mail.org',
        '10minut.com.pl',
        '10minut.xyz',
        '10minutemail.be',
        '10minutemail.cf',
        '10minutemail.co.uk',
        '10minutemail.de',
        '10minutemail.ga',
        '10minutemail.gq',
        '10minutemail.ml',
        '10minutemail.net',
        '10minutemail.nl',
        '10minutemail.pro',
        '10minutemail.us',
        '10minutemailbox.com',
        '10minutemails.in',
        '10minutenemail.de',
        '10minutesmail.com',
        '10minutesmail.fr',
        '10minutmail.pl',
        '10x9.com',
        '11163.com',
        '12hosting.net',
        '12houremail.com',
        '12minutemail.com',
        '12minutemail.net',
        '12storage.com',
        '140unichars.com',
        '147.cl',
        '14n.co.uk',
        '15qm.com',
        '1blackmoon.com',
        '1ce.us',
        '1chuan.com',
        '1clck2.com',
        '1mail.ml',
        '1s.fr',
        '1secmail.com',
        '1secmail.net',
        '1secmail.org',
        '1st-forms.com',
        '1to1mail.org',
        '1usemail.com',
        '1webmail.info',
        '1zhuan.com',
        '2012-2016.ru',
        '20email.eu',
        '20email.it',
        '20mail.eu',
        '20mail.in',
        '20mail.it',
        '20minutemail.it',
        '20mm.eu',
        '2120001.net',
        '247web.net',
        '24hourmail.com',
        '24hourmail.net',
        '2anom.com',
        '2chmail.net',
        '2ether.net',
        '2odem.com',
        '2wc.info',
        '300book.info',
        '30mail.ir',
        '30wave.com',
        '3202.com',
        '36ru.com',
        '3d-painting.com',
        '3l6.com',
        '3mail.ga',
        '4-n.us',
        '4057.com',
        '418.dk',
        '42o.org',
        '4mail.cf',
        '4mail.ga',
        '4nextmail.com',
        '4nmv.ru',
        '4tb.host',
        '4warding.net',
        '4warding.org',
        '50set.ru',
        '55hosting.net',
        '5gramos.com',
        '5july.org',
        '5mail.cf',
        '5mail.ga',
        '5oz.ru',
        '5x25.com',
        '5ymail.com',
        '60minutemail.com',
        '672643.net',
        '675hosting.com',
        '675hosting.net',
        '675hosting.org',
        '6ip.us',
        '6mail.cf',
        '6mail.ga',
        '6mail.ml',
        '6somok.ru',
        '6url.com',
        '75hosting.com',
        '75hosting.net',
        '75hosting.org',
        '7days-printing.com',
        '7mail.ga',
        '7mail.ml',
        '80665.com',
        '8127ep.com',
        '8mail.cf',
        '8mail.ga',
        '8mail.ml',
        '99.com',
        '99cows.com',
        '99experts.com',
        '9mail.cf',
        '9me.site',
        '9mot.ru',
        '9q.ro',
        'a45.in',
        'a7996.com',
        'aa5zy64.com',
        'abacuswe.us',
        'abakiss.com',
        'abcmail.email',
        'abilitywe.us',
        'abovewe.us',
        'absolutewe.us',
        'abundantwe.us',
        'abusemail.de',
        'abuser.eu',
        'abyssmail.com',
        'ac20mail.in',
        'academiccommunity.com',
        'academywe.us',
        'acceleratewe.us',
        'accentwe.us',
        'acceptwe.us',
        'acclaimwe.us',
        'accordwe.us',
        'accreditedwe.us',
        'acentri.com',
        'achievementwe.us',
        'achievewe.us',
        'acornwe.us',
        'acrylicwe.us',
        'activatewe.us',
        'activitywe.us',
        'acucre.com',
        'acuitywe.us',
        'acumenwe.us',
        'adaptivewe.us',
        'adaptwe.us',
        'add3000.pp.ua',
        'addictingtrailers.com',
        'adeptwe.us',
        'adiq.eu',
        'aditus.info',
        'admiralwe.us',
        'ado888.biz',
        'adobeccepdm.com',
        'adoniswe.us',
        'adpugh.org',
        'adsd.org',
        'adubiz.info',
        'advantagewe.us',
        'advantimo.com',
        'adventurewe.us',
        'adventwe.us',
        'advisorwe.us',
        'advocatewe.us',
        'adwaterandstir.com',
        'aegde.com',
        'aegia.net',
        'aegiscorp.net',
        'aegiswe.us',
        'aelo.es',
        'aeonpsi.com',
        'affiliate-nebenjob.info',
        'affiliatedwe.us',
        'affilikingz.de',
        'affinitywe.us',
        'affluentwe.us',
        'affordablewe.us',
        'afrobacon.com',
        'afterhourswe.us',
        'agendawe.us',
        'agger.ro',
        'agilewe.us',
        'agorawe.us',
        'agtx.net',
        'aheadwe.us',
        'ahem.email',
        'ahk.jp',
        'air2token.com',
        'airsi.de',
        'ajaxapp.net',
        'akapost.com',
        'akerd.com',
        'akgq701.com',
        'al-qaeda.us',
        'albionwe.us',
        'alchemywe.us',
        'aleeas.com',
        'aliaswe.us',
        'alienware13.com',
        'aligamel.com',
        'alisongamel.com',
        'alivance.com',
        'alivewe.us',
        'all-cats.ru',
        'allaccesswe.us',
        'allamericanwe.us',
        'allaroundwe.us',
        'alldirectbuy.com',
        'allegiancewe.us',
        'allegrowe.us',
        'allemojikeyboard.com',
        'allgoodwe.us',
        'alliancewe.us',
        'allinonewe.us',
        'allofthem.net',
        'alloutwe.us',
        'allowed.org',
        'alloywe.us',
        'allprowe.us',
        'allseasonswe.us',
        'allstarwe.us',
        'allthegoodnamesaretaken.org',
        'allurewe.us',
        'almondwe.us',
        'alph.wtf',
        'alpha-web.net',
        'alphaomegawe.us',
        'alpinewe.us',
        'altairwe.us',
        'altitudewe.us',
        'altuswe.us',
        'ama-trans.de',
        'amadeuswe.us',
        'amail.club',
        'amail.com',
        'amail1.com',
        'amail4.me',
        'amazon-aws.org',
        'amberwe.us',
        'ambiancewe.us',
        'ambitiouswe.us',
        'amelabs.com',
        'americanawe.us',
        'americasbestwe.us',
        'americaswe.us',
        'amicuswe.us',
        'amplewe.us',
        'amplifiedwe.us',
        'amplifywe.us',
        'ampsylike.com',
        'analogwe.us',
        'analysiswe.us',
        'analyticalwe.us',
        'analyticswe.us',
        'analyticwe.us',
        'anappfor.com',
        'anappthat.com',
        'andreihusanu.ro',
        'andthen.us',
        'animesos.com',
        'anit.ro',
        'ano-mail.net',
        'anon-mail.de',
        'anonbox.net',
        'anonmail.top',
        'anonymail.dk',
        'anonymized.org',
        'anonymousness.com',
        'anotherdomaincyka.tk',
        'ansibleemail.com',
        'anthony-junkmail.com',
        'antireg.com',
        'antispam24.de',
        'anyalias.com',
        'aoeuhtns.com',
        'apfelkorps.de',
        'aphlog.com',
        'apkmd.com',
        'appc.se',
        'appinventor.nl',
        'appixie.com',
        'apps.dj',
        'arduino.hk',
        'ariaz.jetzt',
        'aron.us',
        'arroisijewellery.com',
        'art-en-ligne.pro',
        'arur01.tk',
        'arurgitu.gq',
        'arvato-community.de',
        'aschenbrandt.net',
        'asdasd.nl',
        'asdasd.ru',
        'ashleyandrew.com',
        'ask-mail.com',
        'asorent.com',
        'ass.pp.ua',
        'astonut.tk',
        'astroempires.info',
        'asu.mx',
        'asu.su',
        'at.hm',
        'at0mik.org',
        'atnextmail.com',
        'attnetwork.com',
        'augmentationtechnology.com',
        'ausgefallen.info',
        'auti.st',
        'autorobotica.com',
        'autosouvenir39.ru',
        'autotwollow.com',
        'autowb.com',
        'aver.com',
        'averdov.com',
        'avia-tonic.fr',
        'avls.pt',
        'awatum.de',
        'awdrt.org',
        'awiki.org',
        'awsoo.com',
        'axiz.org',
        'axon7zte.com',
        'axsup.net',
        'azazazatashkent.tk',
        'azcomputerworks.com',
        'b1of96u.com',
        'b2bx.net',
        'b2cmail.de',
        'badgerland.eu',
        'badoop.com',
        'badpotato.tk',
        'banit.club',
        'banit.me',
        'bank-opros1.ru',
        'bareed.ws',
        'barryogorman.com',
        'bartdevos.be',
        'basscode.org',
        'bauwerke-online.com',
        'bazaaboom.com',
        'bbbbyyzz.info',
        'bbhost.us',
        'bcaoo.com',
        'bcast.ws',
        'bcb.ro',
        'bccto.me',
        'bdmuzic.pw',
        'bearsarefuzzy.com',
        'beddly.com',
        'beins.info',
        'belamail.org',
        'belljonestax.com',
        'beluckygame.com',
        'benipaula.org',
        'beribase.ru',
        'beribaza.ru',
        'berirabotay.ru',
        'bestchoiceusedcar.com',
        'bestlistbase.com',
        'bestoption25.club',
        'bestsoundeffects.com',
        'besttempmail.com',
        'betr.co',
        'bgtmail.com',
        'bgx.ro',
        'bidourlnks.com',
        'big1.us',
        'bigprofessor.so',
        'bigwhoop.co.za',
        'bij.pl',
        'binka.me',
        'binnary.com',
        'bio-muesli.info',
        'bione.co',
        'bitwhites.top',
        'bitymails.us',
        'blackgoldagency.ru',
        'blackmarket.to',
        'bladesmail.net',
        'blip.ch',
        'blnkt.net',
        'blogmyway.org',
        'blogos.net',
        'blogspam.ro',
        'bluedumpling.info',
        'bluewerks.com',
        'bnote.com',
        'boatmail.us',
        'bobmurchison.com',
        'bonobo.email',
        'bookthemmore.com',
        'borged.com',
        'borged.net',
        'borged.org',
        'bot.nu',
        'boxformail.in',
        'boximail.com',
        'boxtemp.com.br',
        'bptfp.net',
        'brandallday.net',
        'brasx.org',
        'brennendesreich.de',
        'briggsmarcus.com',
        'broadbandninja.com',
        'bspooky.com',
        'bst-72.com',
        'btb-notes.com',
        'btc.email',
        'btcmail.pw',
        'btcmod.com',
        'btizet.pl',
        'buccalmassage.ru',
        'budaya-tionghoa.com',
        'budayationghoa.com',
        'buffemail.com',
        'bugmenever.com',
        'bulrushpress.com',
        'bum.net',
        'bumpymail.com',
        'bunchofidiots.com',
        'bundes-li.ga',
        'bunsenhoneydew.com',
        'burnthespam.info',
        'businessbackend.com',
        'businesssuccessislifesuccess.com',
        'buspad.org',
        'bussitussi.com',
        'buyordie.info',
        'buyusedlibrarybooks.org',
        'buzzcluby.com',
        'byebyemail.com',
        'byespm.com',
        'c51vsgq.com',
        'cachedot.net',
        'californiafitnessdeals.com',
        'cam4you.cc',
        'camping-grill.info',
        'candymail.de',
        'cane.pw',
        'car101.pro',
        'carbtc.net',
        'cars2.club',
        'carsencyclopedia.com',
        'cartelera.org',
        'caseedu.tk',
        'cavi.mx',
        'cbair.com',
        'cbes.net',
        'cc.liamria',
        'ccmail.uk',
        'cdpa.cc',
        'ceed.se',
        'cellurl.com',
        'cetpass.com',
        'cfo2go.ro',
        'chacuo.net',
        'chaichuang.com',
        'chalupaurybnicku.cz',
        'chasefreedomactivate.com',
        'cheaphub.net',
        'cheatmail.de',
        'chibakenma.ml',
        'chickenkiller.com',
        'chielo.com',
        'chilkat.com',
        'chithinh.com',
        'choco.la',
        'chong-mail.com',
        'chong-mail.net',
        'chong-mail.org',
        'chumpstakingdumps.com',
        'cigar-auctions.com',
        'civx.org',
        'ckaazaza.tk',
        'ckiso.com',
        'cl-cl.org',
        'cl0ne.net',
        'claimab.com',
        'clandest.in',
        'classesmail.com',
        'clearwatermail.info',
        'click-email.com',
        'clickdeal.co',
        'clipmail.eu',
        'cloudns.cx',
        'clrmail.com',
        'cmail.club',
        'cmail.com',
        'cnamed.com',
        'cndps.com',
        'cnew.ir',
        'cnmsg.net',
        'cnsds.de',
        'co.cc',
        'cobarekyo1.ml',
        'cocoro.uk',
        'cocovpn.com',
        'codeandscotch.com',
        'codivide.com',
        'coieo.com',
        'coin-host.net',
        'coinlink.club',
        'compareshippingrates.org',
        'completegolfswing.com',
        'comwest.de',
        'conf.work',
        'consumerriot.com',
        'contbay.com',
        'cooh-2.site',
        'coolandwacky.us',
        'coolimpool.org',
        'coreclip.com',
        'cosmorph.com',
        'coza.ro',
        'crankhole.com',
        'crastination.de',
        'crazespaces.pw',
        'crazymailing.com',
        'cream.pink',
        'cross-law.ga',
        'cross-law.gq',
        'crossroadsmail.com',
        'crusthost.com',
        'csh.ro',
        'cszbl.com',
        'ctmailing.us',
        'ctos.ch',
        'cu.cc',
        'cubiclink.com',
        'cuirushi.org',
        'cuoly.com',
        'curlhph.tk',
        'curryworld.de',
        'cutout.club',
        'cyber-innovation.club',
        'cyber-phone.eu',
        'cylab.org',
        'd1yun.com',
        'daabox.com',
        'dab.ro',
        'daemsteam.com',
        'daibond.info',
        'daily-email.com',
        'daintly.com',
        'damai.webcam',
        'dammexe.net',
        'damnthespam.com',
        'darkharvestfilms.com',
        'daryxfox.net',
        'dasdasdascyka.tk',
        'dash-pads.com',
        'dataarca.com',
        'datarca.com',
        'datazo.ca',
        'datenschutz.ru',
        'datum2.com',
        'davidkoh.net',
        'davidlcreative.com',
        'dawin.com',
        'daymail.life',
        'daymailonline.com',
        'dbunker.com',
        'ddcrew.com',
        'de-a.org',
        'deadchildren.org',
        'deadfake.cf',
        'deadfake.ga',
        'deadfake.ml',
        'deadfake.tk',
        'deagot.com',
        'dealja.com',
        'dealrek.com',
        'deekayen.us',
        'defomail.com',
        'degradedfun.net',
        'delayload.com',
        'delayload.net',
        'demen.ml',
        'dengekibunko.ga',
        'dengekibunko.gq',
        'dengekibunko.ml',
        'der-kombi.de',
        'derkombi.de',
        'derluxuswagen.de',
        'desoz.com',
        'dev-null.cf',
        'dev-null.ga',
        'dev-null.gq',
        'dev-null.ml',
        'deyom.com',
        'dharmatel.net',
        'dhm.ro',
        'dhy.cc',
        'dialogus.com',
        'diapaulpainting.com',
        'digdig.org',
        'digital-message.com',
        'digitalesbusiness.info',
        'digitalmail.info',
        'digitalmariachis.com',
        'dildosfromspace.com',
        'dim-coin.com',
        'directmail24.net',
        'disaq.com',
        'disbox.net',
        'disbox.org',
        'discard.cf',
        'discard.email',
        'discard.ga',
        'discard.gq',
        'discard.ml',
        'discard.tk',
        'discardmail.com',
        'discardmail.de',
        'discos4.com',
        'disign-concept.eu',
        'disign-revelation.com',
        'dispo.in',
        'dispomail.eu',
        'disposable-e.ml',
        'disposable-email.ml',
        'disposable.cf',
        'disposable.ga',
        'disposable.ml',
        'disposablemails.com',
        'disposeamail.com',
        'disposemail.com',
        'divad.ga',
        'divermail.com',
        'divismail.ru',
        'diwaq.com',
        'dlemail.ru',
        'dmarc.ro',
        'dndent.com',
        'dnses.ro',
        'doanart.com',
        'dob.jp',
        'dodgemail.de',
        'dodgit.org',
        'dodsi.com',
        'doiea.com',
        'dolphinnet.net',
        'domforfb1.tk',
        'domforfb18.tk',
        'domforfb19.tk',
        'domforfb2.tk',
        'domforfb23.tk',
        'domforfb27.tk',
        'domforfb29.tk',
        'domforfb3.tk',
        'domforfb4.tk',
        'domforfb5.tk',
        'domforfb6.tk',
        'domforfb7.tk',
        'domforfb8.tk',
        'domforfb9.tk',
        'domozmail.com',
        'dongqing365.com',
        'doquier.tk',
        'dotman.de',
        'dotmsg.com',
        'dotslashrage.com',
        'doublemail.de',
        'douchelounge.com',
        'dozvon-spb.ru',
        'dp76.com',
        'dr69.site',
        'drdrb.com',
        'dred.ru',
        'drevo.si',
        'drivetagdev.com',
        'droolingfanboy.de',
        'dropcake.de',
        'dropjar.com',
        'droplar.com',
        'dropmail.me',
        'dsgvo.ru',
        'dsiay.com',
        'dspwebservices.com',
        'duam.net',
        'duck2.club',
        'dudmail.com',
        'duk33.com',
        'dukedish.com',
        'dumpmail.de',
        'durandinterstellar.com',
        'duskmail.com',
        'dwse.edu.pl',
        'dyceroprojects.com',
        'dz17.net',
        'e-marketstore.ru',
        'e-tomarigi.com',
        'e3z.de',
        'eanok.com',
        'easy-trash-mail.com',
        'easynetwork.info',
        'eatmea2z.club',
        'eay.jp',
        'ebbob.com',
        'ebeschlussbuch.de',
        'ecallheandi.com',
        'ecolo-online.fr',
        'edgex.ru',
        'edinburgh-airporthotels.com',
        'edv.to',
        'ee1.pl',
        'ee2.pl',
        'eelmail.com',
        'efxs.ca',
        'einrot.de',
        'elearningjournal.org',
        'electro.mn',
        'elitevipatlantamodels.com',
        'elki-mkzn.ru',
        'elwatar.com',
        'email-fake.cf',
        'email-fake.com',
        'email-fake.ga',
        'email-fake.gq',
        'email-fake.ml',
        'email-fake.tk',
        'email-jetable.fr',
        'email-lab.com',
        'email-temp.com',
        'email.net',
        'email1.pro',
        'email60.com',
        'emailage.cf',
        'emailage.ga',
        'emailage.gq',
        'emailage.ml',
        'emailage.tk',
        'emailate.com',
        'emailcu.icu',
        'emaildienst.de',
        'emaildrop.io',
        'emailfake.ml',
        'emailfreedom.ml',
        'emailgenerator.de',
        'emailigo.de',
        'emailinfive.com',
        'emailisvalid.com',
        'emailmiser.com',
        'emailna.co',
        'emailo.pro',
        'emailondeck.com',
        'emailportal.info',
        'emailproxsy.com',
        'emailresort.com',
        'emails.ga',
        'emailsecurer.com',
        'emailsingularity.net',
        'emailspam.cf',
        'emailspam.ga',
        'emailspam.gq',
        'emailspam.ml',
        'emailspam.tk',
        'emailsy.info',
        'emailtech.info',
        'emailto.de',
        'emailure.net',
        'emailz.cf',
        'emailz.ga',
        'emailz.gq',
        'emailz.ml',
        'emeraldwebmail.com',
        'emil.com',
        'emkei.cf',
        'emkei.ga',
        'emkei.gq',
        'emkei.ml',
        'emkei.tk',
        'eml.pp.ua',
        'emlhub.com',
        'emlpro.com',
        'emltmp.com',
        'empireanime.ga',
        'emstjzh.com',
        'enayu.com',
        'enterto.com',
        'envy17.com',
        'eoffice.top',
        'eoopy.com',
        'epb.ro',
        'ephemail.net',
        'ephemeral.email',
        'eposta.buzz',
        'eqiluxspam.ga',
        'ericjohnson.ml',
        'esbano-ru.ru',
        'esc.la',
        'escapehatchapp.com',
        'esemay.com',
        'esgeneri.com',
        'esiix.com',
        'esprity.com',
        'estate-invest.fr',
        'eth2btc.info',
        'ether123.net',
        'ethereum1.top',
        'ethersports.org',
        'ethersportz.info',
        'etotvibor.ru',
        'etranquil.com',
        'etranquil.net',
        'etranquil.org',
        'euaqa.com',
        'evanfox.info',
        'eveav.com',
        'evilcomputer.com',
        'evyush.com',
        'exdonuts.com',
        'existiert.net',
        'exitstageleft.net',
        'extremail.ru',
        'ez.lv',
        'ezehe.com',
        'ezfill.com',
        'ezstest.com',
        'f4k.es',
        'f5.si',
        'facebook-email.cf',
        'facebook-email.ga',
        'facebook-email.ml',
        'facebookmail.gq',
        'facebookmail.ml',
        'fackme.gq',
        'fadingemail.com',
        'faecesmail.me',
        'fag.wf',
        'failbone.com',
        'faithkills.com',
        'fake-box.com',
        'fake-email.pp.ua',
        'fake-mail.cf',
        'fake-mail.ga',
        'fake-mail.ml',
        'fakedemail.com',
        'fakeinbox.cf',
        'fakeinbox.ga',
        'fakeinbox.info',
        'fakeinbox.ml',
        'fakeinbox.tk',
        'fakemail.fr',
        'fakemailgenerator.com',
        'fakemailz.com',
        'fammix.com',
        'fanclub.pm',
        'fangoh.com',
        'farrse.co.uk',
        'fast-email.info',
        'fast-mail.fr',
        'fastacura.com',
        'fastchevy.com',
        'fastchrysler.com',
        'fasternet.biz',
        'fastkawasaki.com',
        'fastmazda.com',
        'fastmitsubishi.com',
        'fastnissan.com',
        'fastsubaru.com',
        'fastsuzuki.com',
        'fasttoyota.com',
        'fastyamaha.com',
        'fatflap.com',
        'fbma.tk',
        'fbpoint.net',
        'fddns.ml',
        'fdfdsfds.com',
        'fer-gabon.org',
        'fermaxxi.ru',
        'fettometern.com',
        'fexbox.org',
        'fexbox.ru',
        'fexpost.com',
        'ficken.de',
        'fictionsite.com',
        'figjs.com',
        'figshot.com',
        'figurescoin.com',
        'fiifke.de',
        'filbert4u.com',
        'filberts4u.com',
        'film-blog.biz',
        'findemail.info',
        'findu.pl',
        'fir.hk',
        'firemailbox.club',
        'fitnesrezink.ru',
        'fixmail.tk',
        'fizmail.com',
        'flemail.ru',
        'flowu.com',
        'flu.cc',
        'fluidsoft.us',
        'flurred.com',
        'fly-ts.de',
        'flyinggeek.net',
        'flyspam.com',
        'foobarbot.net',
        'footard.com',
        'forecastertests.com',
        'foreskin.cf',
        'foreskin.ga',
        'foreskin.gq',
        'foreskin.ml',
        'foreskin.tk',
        'forgetmail.com',
        'fornow.eu',
        'forspam.net',
        'forward.cat',
        'fosil.pro',
        'foxja.com',
        'foxtrotter.info',
        'fr.nf',
        'fr33mail.info',
        'fragolina2.tk',
        'frappina.tk',
        'free-email.cf',
        'free-email.ga',
        'free-temp.net',
        'freebabysittercam.com',
        'freeblackbootytube.com',
        'freecat.net',
        'freedom4you.info',
        'freedompop.us',
        'freefattymovies.com',
        'freehotmail.net',
        'freeinbox.email',
        'freelance-france.eu',
        'freeletter.me',
        'freemail.ms',
        'freemails.cf',
        'freemails.ga',
        'freemails.ml',
        'freemeil.ga',
        'freemeil.gq',
        'freemeil.ml',
        'freeplumpervideos.com',
        'freepost.cc',
        'freerubli.ru',
        'freeschoolgirlvids.com',
        'freesistercam.com',
        'freeteenbums.com',
        'freundin.ru',
        'front14.org',
        'ftp.sh',
        'ftpinc.ca',
        'fuckedupload.com',
        'fuckme69.club',
        'fucknloveme.top',
        'fuckxxme.top',
        'fuirio.com',
        'fukaru.com',
        'fukurou.ch',
        'fulvie.com',
        'fun64.com',
        'funnycodesnippets.com',
        'funnymail.de',
        'furzauflunge.de',
        'fuwamofu.com',
        'fuwari.be',
        'fux0ringduh.com',
        'fxnxs.com',
        'g14l71lb.com',
        'g1xmail.top',
        'g2xmail.top',
        'g3xmail.top',
        'g4hdrop.us',
        'gafy.net',
        'gage.ga',
        'galaxy.tv',
        'gally.jp',
        'gamail.top',
        'gamegregious.com',
        'gamgling.com',
        'garasikita.pw',
        'garbagecollector.org',
        'garbagemail.org',
        'gardenscape.ca',
        'garizo.com',
        'garrymccooey.com',
        'gav0.com',
        'gawab.com',
        'gbcmail.win',
        'gbmail.top',
        'gcmail.top',
        'gdmail.top',
        'gedmail.win',
        'geekforex.com',
        'geew.ru',
        'geldwaschmaschine.de',
        'gelitik.in',
        'genderfuck.net',
        'geronra.com',
        'geschent.biz',
        'get-mail.cf',
        'get-mail.ga',
        'get-mail.ml',
        'get-mail.tk',
        'get.pp.ua',
        'get1mail.com',
        'get2israel.com',
        'getairmail.cf',
        'getairmail.ga',
        'getairmail.gq',
        'getairmail.ml',
        'getairmail.tk',
        'geteit.com',
        'getfun.men',
        'getnada.com',
        'getnowtoday.cf',
        'getonemail.net',
        'getsimpleemail.com',
        'gett.icu',
        'ghosttexter.de',
        'giacmosuaviet.info',
        'giaiphapmuasam.com',
        'gifto12.com',
        'ginzi.be',
        'ginzi.co.uk',
        'ginzi.es',
        'ginzi.net',
        'ginzy.co.uk',
        'ginzy.eu',
        'girlmail.win',
        'girlsindetention.com',
        'giveh2o.info',
        'givmail.com',
        'glitch.sx',
        'globaltouron.com',
        'glubex.com',
        'glucosegrin.com',
        'gmal.com',
        'gmatch.org',
        'gmx1mail.top',
        'gmxmail.top',
        'gmxmail.win',
        'gnctr-calgary.com',
        'go2usa.info',
        'go2vpn.net',
        'golemico.com',
        'gomail.in',
        'goplaygame.ru',
        'gorillaswithdirtyarmpits.com',
        'goround.info',
        'gosuslugi-spravka.ru',
        'gothere.biz',
        'gotmail.com',
        'gowikibooks.com',
        'gowikicampus.com',
        'gowikicars.com',
        'gowikifilms.com',
        'gowikigames.com',
        'gowikimusic.com',
        'gowikinetwork.com',
        'gowikitravel.com',
        'gowikitv.com',
        'grandmamail.com',
        'grandmasmail.com',
        'greenhousemail.com',
        'greggamel.com',
        'greggamel.net',
        'gregorsky.zone',
        'gregorygamel.com',
        'gregorygamel.net',
        'grish.de',
        'griuc.schule',
        'grn.cc',
        'groupbuff.com',
        'grugrug.ru',
        'gruz-m.ru',
        'gs-arc.org',
        'gsredcross.org',
        'gsxstring.ga',
        'gudanglowongan.com',
        'guerillamail.de',
        'guerillamail.info',
        'guerillamail.net',
        'guerillamail.org',
        'guerillamailblock.com',
        'gxemail.men',
        'gynzi.co.uk',
        'gynzi.es',
        'gynzy.at',
        'gynzy.es',
        'gynzy.eu',
        'gynzy.gr',
        'gynzy.info',
        'gynzy.lt',
        'gynzy.mobi',
        'gynzy.pl',
        'gynzy.ro',
        'gynzy.sk',
        'gzb.ro',
        'h8s.org',
        'habitue.net',
        'hacccc.com',
        'hackersquad.tk',
        'hackthatbit.ch',
        'hahawrong.com',
        'haida-edu.cn',
        'hairs24.ru',
        'haltospam.com',
        'hamham.uk',
        'hangxomcuatoilatotoro.ml',
        'happydomik.ru',
        'haribu.com',
        'hartbot.de',
        'hasanmail.ml',
        'hawrong.com',
        'haydoo.com',
        'hazelnut4u.com',
        'hazelnuts4u.com',
        'hazmatshipping.org',
        'hccmail.win',
        'headstrong.de',
        'heathenhammer.com',
        'heathenhero.com',
        'hecat.es',
        'heisei.be',
        'hellodream.mobi',
        'helloricky.com',
        'helpinghandtaxcenter.org',
        'helpjobs.ru',
        'heros3.com',
        'herpderp.nl',
        'hezll.com',
        'hi5.si',
        'hiddentragedy.com',
        'hidebox.org',
        'hidebusiness.xyz',
        'hidemail.pro',
        'hidemail.us',
        'highbros.org',
        'hiltonvr.com',
        'himail.online',
        'hmail.us',
        'hmh.ro',
        'hoanggiaanh.com',
        'hoanglong.tech',
        'hobsun.com',
        'hochsitze.com',
        'hola.org',
        'holl.ga',
        'honeys.be',
        'honor-8.com',
        'hornyalwary.top',
        'hostcalls.com',
        'hostguru.top',
        'hostlaba.com',
        'hot-mail.cf',
        'hot-mail.ga',
        'hot-mail.gq',
        'hot-mail.ml',
        'hot-mail.tk',
        'hotmai.com',
        'hotmailproduct.com',
        'hotmial.com',
        'hotpop.com',
        'hotprice.co',
        'hotsoup.be',
        'housat.com',
        'hpc.tw',
        'hs.vc',
        'ht.cx',
        'huangniu8.com',
        'hukkmu.tk',
        'hulapla.de',
        'humaility.com',
        'hungpackage.com',
        'hushmail.cf',
        'huskion.net',
        'hvastudiesucces.nl',
        'hwsye.net',
        'i2pmail.org',
        'i6.cloudns.cc',
        'iaoss.com',
        'ibnuh.bz',
        'icantbelieveineedtoexplainthisshit.com',
        'icemail.club',
        'ichigo.me',
        'icx.in',
        'icx.ro',
        'idx4.com',
        'idxue.com',
        'ieatspam.eu',
        'ieatspam.info',
        'iencm.com',
        'iffymedia.com',
        'ige.es',
        'igg.biz',
        'ignoremail.com',
        'ihateyoualot.info',
        'ihazspam.ca',
        'iheartspam.org',
        'illistnoise.com',
        'ilovespam.com',
        'imail1.net',
        'imailt.com',
        'imgof.com',
        'imgv.de',
        'immo-gerance.info',
        'imstations.com',
        'imul.info',
        'in-ulm.de',
        'inbound.plus',
        'inbox2.info',
        'inboxbear.com',
        'inboxdesign.me',
        'inboxed.im',
        'inboxed.pw',
        'inboxkitten.com',
        'inboxproxy.com',
        'inboxstore.me',
        'inclusiveprogress.com',
        'incognitomail.com',
        'incognitomail.net',
        'incognitomail.org',
        'incq.com',
        'ind.st',
        'indieclad.com',
        'indirect.ws',
        'indomaed.pw',
        'indomina.cf',
        'indoserver.stream',
        'indosukses.press',
        'ineec.net',
        'inggo.org',
        'inmynetwork.tk',
        'inoutmail.de',
        'inoutmail.eu',
        'inoutmail.info',
        'inoutmail.net',
        'inpwa.com',
        'insanumingeniumhomebrew.com',
        'insorg-mail.info',
        'instance-email.com',
        'instantblingmail.info',
        'instantemailaddress.com',
        'instantmail.fr',
        'internet-v-stavropole.ru',
        'internetoftags.com',
        'interstats.org',
        'intersteller.com',
        'intopwa.com',
        'intopwa.net',
        'intopwa.org',
        'investore.co',
        'iozak.com',
        'ip4.pp.ua',
        'ip6.pp.ua',
        'ipoo.org',
        'ippandansei.tk',
        'ipsur.org',
        'irabops.com',
        'irc.so',
        'irishspringrealty.com',
        'iroid.com',
        'ironiebehindert.de',
        'irssi.tv',
        'is.af',
        'isdaq.com',
        'isosq.com',
        'istii.ro',
        'isukrainestillacountry.com',
        'it7.ovh',
        'italy-mail.com',
        'itfast.net',
        'itunesgiftcodegenerator.com',
        'iuemail.men',
        'ixaks.com',
        'ixx.io',
        'j-p.us',
        'jafps.com',
        'jajxz.com',
        'janproz.com',
        'jaqis.com',
        'jdmadventures.com',
        'jdz.ro',
        'je-recycle.info',
        'jellow.ml',
        'jellyrolls.com',
        'jet-renovation.fr',
        'jetable.pp.ua',
        'jmail.ovh',
        'jmail.ro',
        'jobbikszimpatizans.hu',
        'jobposts.net',
        'jobs-to-be-done.net',
        'joelpet.com',
        'joetestalot.com',
        'jopho.com',
        'joseihorumon.info',
        'josse.ltd',
        'jpco.org',
        'jumonji.tk',
        'jungkamushukum.com',
        'junk.to',
        'junk1e.com',
        'junkmail.ga',
        'junkmail.gq',
        'just-email.com',
        'justemail.ml',
        'juyouxi.com',
        'jwork.ru',
        'kademen.com',
        'kadokawa.cf',
        'kadokawa.ga',
        'kadokawa.gq',
        'kadokawa.ml',
        'kadokawa.tk',
        'kaengu.ru',
        'kagi.be',
        'kakadua.net',
        'kalapi.org',
        'kamen-market.ru',
        'kamsg.com',
        'kaovo.com',
        'kappala.info',
        'karatraman.ml',
        'kariplan.com',
        'karta-kykyruza.ru',
        'kartvelo.com',
        'katztube.com',
        'kazelink.ml',
        'kcrw.de',
        'keepmymail.com',
        'keinhirn.de',
        'keipino.de',
        'kekita.com',
        'kemptvillebaseball.com',
        'kennedy808.com',
        'kiani.com',
        'kimsdisk.com',
        'kingsq.ga',
        'kino-100.ru',
        'kiois.com',
        'kismail.ru',
        'kisstwink.com',
        'kitnastar.com',
        'kkmail.be',
        'kksm.be',
        'klassmaster.net',
        'klick-tipp.us',
        'klipschx12.com',
        'kloap.com',
        'kludgemush.com',
        'kmail.li',
        'kmhow.com',
        'knol-power.nl',
        'kommunity.biz',
        'kon42.com',
        'konultant-jurist.ru',
        'kook.ml',
        'kopagas.com',
        'kopaka.net',
        'korona-nedvizhimosti.ru',
        'koshu.ru',
        'kosmetik-obatkuat.com',
        'kostenlosemailadresse.de',
        'kpooa.com',
        'kpost.be',
        'krd.ag',
        'krsw.tk',
        'krypton.tk',
        'ksmtrck.tk',
        'kuhrap.com',
        'kulmeo.com',
        'kulturbetrieb.info',
        'kutakbisajauhjauh.gq',
        'kwift.net',
        'kwilco.net',
        'kyal.pl',
        'kyois.com',
        'l-c-a.us',
        'l33r.eu',
        'l6factors.com',
        'labetteraverouge.at',
        'lacedmail.com',
        'lackmail.net',
        'lackmail.ru',
        'lacto.info',
        'lags.us',
        'lain.ch',
        'lak.pp.ua',
        'lakelivingstonrealestate.com',
        'lakqs.com',
        'landmail.co',
        'laoeq.com',
        'larisia.com',
        'last-chance.pro',
        'lastmail.co',
        'lastmail.com',
        'lazyinbox.com',
        'lazyinbox.us',
        'ldaho.biz',
        'ldop.com',
        'ldtp.com',
        'le-tim.ru',
        'lee.mx',
        'leeching.net',
        'legalrc.loan',
        'lellno.gq',
        'lenovog4.com',
        'lerbhe.com',
        'letmeinonthis.com',
        'lez.se',
        'lgxscreen.com',
        'liamcyrus.com',
        'lifetimefriends.info',
        'lifetotech.com',
        'ligsb.com',
        'lillemap.net',
        'lilo.me',
        'lindenbaumjapan.com',
        'linkedintuts2016.pw',
        'linshiyouxiang.net',
        'linuxmail.so',
        'liveradio.tk',
        'lkgn.se',
        'llogin.ru',
        'loadby.us',
        'loan101.pro',
        'loaoa.com',
        'loapq.com',
        'locanto1.club',
        'locantofuck.top',
        'locantowsite.club',
        'locomodev.net',
        'login-email.cf',
        'login-email.ga',
        'login-email.ml',
        'login-email.tk',
        'logular.com',
        'loh.pp.ua',
        'loin.in',
        'lolmail.biz',
        'lordsofts.com',
        'losemymail.com',
        'lovemeet.faith',
        'lovemeleaveme.com',
        'lpfmgmtltd.com',
        'lr7.us',
        'lru.me',
        'ls-server.ru',
        'luckymail.org',
        'lukecarriere.com',
        'lukemail.info',
        'luv2.us',
        'lyfestylecreditsolutions.com',
        'lzoaq.com',
        'm4ilweb.info',
        'maboard.com',
        'macr2.com',
        'macromaid.com',
        'macromice.info',
        'magamail.com',
        'maggotymeat.ga',
        'magicbox.ro',
        'magim.be',
        'magspam.net',
        'maidlow.info',
        'mail-card.net',
        'mail-easy.fr',
        'mail-help.net',
        'mail-hub.info',
        'mail-now.top',
        'mail-owl.com',
        'mail-share.com',
        'mail-temporaire.com',
        'mail-tester.com',
        'mail.wtf',
        'mail0.ga',
        'mail1.top',
        'mail114.net',
        'mail1web.org',
        'mail22.club',
        'mail2run.com',
        'mail4trash.com',
        'mail666.ru',
        'mail7.io',
        'mail707.com',
        'mail72.com',
        'mailapp.top',
        'mailback.com',
        'mailbox.in.ua',
        'mailbox52.ga',
        'mailbox80.biz',
        'mailbox82.biz',
        'mailbox87.de',
        'mailbox92.biz',
        'mailboxy.fun',
        'mailchop.com',
        'mailcker.com',
        'maildrop.cf',
        'maildrop.ga',
        'maildrop.gq',
        'maildrop.ml',
        'maildu.de',
        'maildx.com',
        'maileater.com',
        'mailed.in',
        'mailed.ro',
        'maileme101.com',
        'mailf5.com',
        'mailfall.com',
        'mailfree.ga',
        'mailfree.gq',
        'mailfree.ml',
        'mailfs.com',
        'mailgutter.com',
        'mailhazard.com',
        'mailhazard.us',
        'mailhex.com',
        'mailhub.pro',
        'mailhz.me',
        'mailimate.com',
        'mailinatar.com',
        'mailinator.co.uk',
        'mailinator.gq',
        'mailinator.info',
        'mailinator.us',
        'mailinator0.com',
        'mailinator1.com',
        'mailinator2.net',
        'mailinator3.com',
        'mailinator4.com',
        'mailinator5.com',
        'mailinator6.com',
        'mailinator7.com',
        'mailinator8.com',
        'mailinator9.com',
        'mailita.tk',
        'mailjunk.cf',
        'mailjunk.ga',
        'mailjunk.gq',
        'mailjunk.ml',
        'mailjunk.tk',
        'mailkert.com',
        'mailmate.com',
        'mailme.gq',
        'mailme.ir',
        'mailmoth.com',
        'mailna.biz',
        'mailna.co',
        'mailna.in',
        'mailna.me',
        'mailnator.com',
        'mailonaut.com',
        'mailorc.com',
        'mailox.fun',
        'mailpooch.com',
        'mailpress.gq',
        'mailproxsy.com',
        'mailquack.com',
        'mailsac.com',
        'mailseal.de',
        'mailshiv.com',
        'mailslapping.com',
        'mailslite.com',
        'mailsucker.net',
        'mailt.net',
        'mailt.top',
        'mailtechx.com',
        'mailtemporaire.com',
        'mailtemporaire.fr',
        'mailto.plus',
        'mailtraps.com',
        'mailtrix.net',
        'mailzi.ru',
        'mailzilla.org',
        'mainerfolg.info',
        'makemenaughty.club',
        'malahov.de',
        'malayalamdtp.com',
        'mama3.org',
        'mamulenok.ru',
        'mandraghen.cf',
        'manifestgenerator.com',
        'mansiondev.com',
        'mark-compressoren.ru',
        'marketlink.info',
        'markmurfin.com',
        'mask03.ru',
        'masonline.info',
        'maswae.world',
        'matamuasu.ga',
        'matchpol.net',
        'matra.site',
        'max-mail.org',
        'mbox.re',
        'mcache.net',
        'mciek.com',
        'mdhc.tk',
        'meantinc.com',
        'mebelnu.info',
        'mechanicalresumes.com',
        'medkabinet-uzi.ru',
        'meepsheep.eu',
        'meltedbrownies.com',
        'memsg.site',
        'merry.pink',
        'messwiththebestdielikethe.rest',
        'mfsa.info',
        'mfsa.ru',
        'mhzayt.online',
        'miaferrari.com',
        'miauj.com',
        'midcoastcustoms.com',
        'midcoastcustoms.net',
        'midcoastsolutions.com',
        'midcoastsolutions.net',
        'midlertidig.com',
        'midlertidig.net',
        'midlertidig.org',
        'mierdamail.com',
        'migmail.net',
        'migmail.pl',
        'migumail.com',
        'mihep.com',
        'mijnhva.nl',
        'minsmail.com',
        'mirai.re',
        'miucce.com',
        'mji.ro',
        'mjukglass.nu',
        'mkpfilm.com',
        'ml8.ca',
        'mm.my',
        'mm5.se',
        'mnode.me',
        'moakt.cc',
        'moakt.co',
        'moakt.com',
        'moakt.ws',
        'mobileninja.co.uk',
        'mobilevpn.top',
        'moburl.com',
        'mockmyid.com',
        'moeri.org',
        'mofu.be',
        'mohmal.com',
        'mohmal.im',
        'mohmal.in',
        'mohmal.tech',
        'moimoi.re',
        'molms.com',
        'momentics.ru',
        'monachat.tk',
        'monadi.ml',
        'moneypipe.net',
        'moonwake.com',
        'moot.es',
        'moreawesomethanyou.com',
        'moreorcs.com',
        'morriesworld.ml',
        'morsin.com',
        'moruzza.com',
        'motique.de',
        'mountainregionallibrary.net',
        'mox.pp.ua',
        'moy-elektrik.ru',
        'moza.pl',
        'mozej.com',
        'mp-j.ga',
        'mr24.co',
        'msgos.com',
        'mspeciosa.com',
        'msrc.ml',
        'mswork.ru',
        'msxd.com',
        'mt2015.com',
        'mtmdev.com',
        'muathegame.com',
        'muchomail.com',
        'mucincanon.com',
        'muehlacker.tk',
        'muell.icu',
        'muell.monster',
        'muell.xyz',
        'muellemail.com',
        'muellmail.com',
        'munoubengoshi.gq',
        'mutant.me',
        'mvrht.com',
        'mvrht.net',
        'mwarner.org',
        'mxfuel.com',
        'my-pomsies.ru',
        'my-teddyy.ru',
        'my10minutemail.com',
        'mybitti.de',
        'mycorneroftheinter.net',
        'myde.ml',
        'mydemo.equipment',
        'myecho.es',
        'myemailboxy.com',
        'mygeoweb.info',
        'myindohome.services',
        'myinterserver.ml',
        'mykickassideas.com',
        'mymail90.com',
        'mymailoasis.com',
        'mynetstore.de',
        'myopang.com',
        'myspaceinc.com',
        'myspaceinc.net',
        'myspaceinc.org',
        'myspacepimpedup.com',
        'myspamless.com',
        'mystvpn.com',
        'mysugartime.ru',
        'mytemp.email',
        'mywarnernet.net',
        'mywrld.site',
        'mywrld.top',
        'myzx.com',
        'n1nja.org',
        'na-cat.com',
        'nada.email',
        'nada.ltd',
        'nagi.be',
        'nakedtruth.biz',
        'nanonym.ch',
        'naslazhdai.ru',
        'nationalgardeningclub.com',
        'nawmin.info',
        'nbzmr.com',
        'negated.com',
        'neko2.net',
        'nekochan.fr',
        'neotlozhniy-zaim.ru',
        'net1mail.com',
        'netricity.nl',
        'netris.net',
        'netviewer-france.com',
        'netzidiot.de',
        'nevermail.de',
        'newbpotato.tk',
        'newfilm24.ru',
        'newideasfornewpeople.info',
        'newmail.top',
        'next.ovh',
        'nextmail.info',
        'nextstopvalhalla.com',
        'nezdiro.org',
        'nezumi.be',
        'nezzart.com',
        'nfast.net',
        'nguyenusedcars.com',
        'nh3.ro',
        'nicknassar.com',
        'nincsmail.com',
        'niseko.be',
        'niwl.net',
        'nm7.cc',
        'nmail.cf',
        'nnot.net',
        'nnoway.ru',
        'no-ux.com',
        'nobugmail.com',
        'nobulk.com',
        'nobuma.com',
        'noclickemail.com',
        'nodezine.com',
        'nogmailspam.info',
        'noicd.com',
        'nokiamail.com',
        'nolemail.ga',
        'nomail.cf',
        'nomail.ga',
        'nonspam.eu',
        'nonspammer.de',
        'nonze.ro',
        'noref.in',
        'norseforce.com',
        'nospamthanks.info',
        'nothingtoseehere.ca',
        'notif.me',
        'notrnailinator.com',
        'notsharingmy.info',
        'now.im',
        'nowmymail.net',
        'nthrl.com',
        'nthrw.com',
        'ntlhelp.net',
        'nubescontrol.com',
        'nullbox.info',
        'nut.cc',
        'nutpa.net',
        'nuts2trade.com',
        'nwldx.com',
        'nwytg.com',
        'nwytg.net',
        'ny7.me',
        'nypato.com',
        'nyrmusic.com',
        'o2stk.org',
        'o7i.net',
        'oalsp.com',
        'obfusko.com',
        'oborudovanieizturcii.ru',
        'obxpestcontrol.com',
        'odaymail.com',
        'odem.com',
        'oepia.com',
        'oerpub.org',
        'offshore-proxies.net',
        'oglerau.com',
        'ohaaa.de',
        'ohi.tw',
        'oida.icu',
        'oing.cf',
        'okclprojects.com',
        'okinawa.li',
        'okrent.us',
        'okzk.com',
        'olimp-case.ru',
        'olypmall.ru',
        'omail.pro',
        'omnievents.org',
        'one-mail.top',
        'one-time.email',
        'one2mail.info',
        'oneoffmail.com',
        'onlineidea.info',
        'onqin.com',
        'ontyne.biz',
        'oohioo.com',
        'oolus.com',
        'oonies-shoprus.ru',
        'oopi.org',
        'openavz.com',
        'opendns.ro',
        'opentrash.com',
        'opmmedia.ga',
        'opp24.com',
        'optimaweb.me',
        'opwebw.com',
        'oranek.com',
        'oreidresume.com',
        'orgmbx.cc',
        'oroki.de',
        'oshietechan.link',
        'ourklips.com',
        'ourpreviewdomain.com',
        'outlawspam.com',
        'outmail.win',
        'owleyes.ch',
        'ownsyou.de',
        'oxopoha.com',
        'ozyl.de',
        'p-banlis.ru',
        'p33.org',
        'p71ce1m.com',
        'pa9e.com',
        'pachilly.com',
        'pagamenti.tk',
        'paharpurmim.ga',
        'pakadebu.ga',
        'papierkorb.me',
        'paplease.com',
        'para2019.ru',
        'parlimentpetitioner.tk',
        'pastebitch.com',
        'patonce.com',
        'pavilionx2.com',
        'payperex2.com',
        'payspun.com',
        'pe.hu',
        'pecinan.com',
        'pecinan.net',
        'pecinan.org',
        'penisgoes.in',
        'penoto.tk',
        'pepbot.com',
        'peterdethier.com',
        'petrzilka.net',
        'peykesabz.com',
        'pfui.ru',
        'phone-elkey.ru',
        'photo-impact.eu',
        'photomark.net',
        'pi.vu',
        'piaa.me',
        'pig.pp.ua',
        'pii.at',
        'piki.si',
        'pimpedupmyspace.com',
        'pinehill-seattle.org',
        'pingir.com',
        'pisls.com',
        'pitaniezdorovie.ru',
        'pivo-bar.ru',
        'pleasenoham.org',
        'plhk.ru',
        'ploae.com',
        'plw.me',
        'poehali-otdihat.ru',
        'pojok.ml',
        'pokemail.net',
        'pokiemobile.com',
        'polarkingxx.ml',
        'polyfaust.net',
        'pooae.com',
        'poopiebutt.club',
        'popesodomy.com',
        'popgx.com',
        'porsh.net',
        'posdz.com',
        'posta.store',
        'postacin.com',
        'postonline.me',
        'poutineyourface.com',
        'powered.name',
        'powerencry.com',
        'powlearn.com',
        'ppetw.com',
        'pqoia.com',
        'prazdnik-37.ru',
        'predatorrat.cf',
        'predatorrat.ga',
        'predatorrat.gq',
        'predatorrat.ml',
        'predatorrat.tk',
        'premium-mail.fr',
        'primabananen.net',
        'prin.be',
        'privy-mail.com',
        'privy-mail.de',
        'privymail.de',
        'pro-tag.org',
        'procrackers.com',
        'profast.top',
        'projectcl.com',
        'promailt.com',
        'proprietativalcea.ro',
        'propscore.com',
        'protempmail.com',
        'proxyparking.com',
        'prtshr.com',
        'prtz.eu',
        'psh.me',
        'psles.com',
        'psoxs.com',
        'puglieisi.com',
        'puji.pro',
        'punkass.com',
        'puppetmail.de',
        'purcell.email',
        'purelogistics.org',
        'put2.net',
        'puttanamaiala.tk',
        'pwrby.com',
        'qasti.com',
        'qbfree.us',
        'qc.to',
        'qibl.at',
        'qipmail.net',
        'qiq.us',
        'qisdo.com',
        'qisoa.com',
        'qmrbe.com',
        'qoika.com',
        'qq.my',
        'qsl.ro',
        'qtum-ico.com',
        'quadrafit.com',
        'quick-mail.cc',
        'quickemail.info',
        'quickmail.nl',
        'quicksend.ch',
        'ququb.com',
        'qvy.me',
        'qwickmail.com',
        'r4nd0m.de',
        'ra3.us',
        'rabin.ca',
        'rabiot.reisen',
        'raetp9.com',
        'rainbowly.ml',
        'raketenmann.de',
        'rancidhome.net',
        'randomail.net',
        'rapt.be',
        'raqid.com',
        'rax.la',
        'raxtest.com',
        'razemail.com',
        'rbb.org',
        'rcasd.com',
        'rdklcrv.xyz',
        're-gister.com',
        'reality-concept.club',
        'rebates.stream',
        'receiveee.com',
        'recipeforfailure.com',
        'reconmail.com',
        'recyclemail.dk',
        'redfeathercrow.com',
        'reftoken.net',
        'regbypass.com',
        'regspaces.tk',
        'rejectmail.com',
        'rejo.technology',
        'remail.cf',
        'remail.ga',
        'remarkable.rocks',
        'remote.li',
        'reptilegenetics.com',
        'resgedvgfed.tk',
        'revolvingdoorhoax.org',
        'rfc822.org',
        'richfinances.pw',
        'riddermark.de',
        'rifkian.ga',
        'risingsuntouch.com',
        'riski.cf',
        'rklips.com',
        'rkomo.com',
        'rm2rf.com',
        'rma.ec',
        'rnailinator.com',
        'ro.lt',
        'robertspcrepair.com',
        'robot-mail.com',
        'rollindo.agency',
        'ronnierage.net',
        'rootfest.net',
        'rosebearmylove.ru',
        'rotaniliam.com',
        'rover.info',
        'rowe-solutions.com',
        'royaldoodles.org',
        'royalmarket.life',
        'royandk.com',
        'rppkn.com',
        'rsvhr.com',
        'rtskiya.xyz',
        'rudymail.ml',
        'ruffrey.com',
        'rumgel.com',
        'runi.ca',
        'rupayamail.com',
        'ruru.be',
        'rustydoor.com',
        'rvb.ro',
        's33db0x.com',
        'sabrestlouis.com',
        'sackboii.com',
        'safaat.cf',
        'safermail.info',
        'saharanightstempe.com',
        'salmeow.tk',
        'samsclass.info',
        'sandcars.net',
        'sandelf.de',
        'sandwhichvideo.com',
        'sanfinder.com',
        'sanim.net',
        'sanstr.com',
        'sast.ro',
        'satisfyme.club',
        'satukosong.com',
        'sausen.com',
        'scatmail.com',
        'scay.net',
        'schachrol.com',
        'schmeissweg.tk',
        'scrsot.com',
        'sd3.in',
        'secmail.pw',
        'secure-mail.cc',
        'secured-link.net',
        'securehost.com.es',
        'seekapps.com',
        'seekjobs4u.com',
        'sejaa.lv',
        'selfdestructingmail.com',
        'selfdestructingmail.org',
        'send22u.info',
        'sendfree.org',
        'sendingspecialflyers.com',
        'sendspamhere.com',
        'server.ms',
        'sexforswingers.com',
        'sexical.com',
        'sexyalwasmi.top',
        'shalar.net',
        'sharedmailbox.org',
        'sheileh.net',
        'shhmail.com',
        'shhuut.org',
        'shieldedmail.com',
        'shipfromto.com',
        'shiphazmat.org',
        'shipping-regulations.com',
        'shippingterms.org',
        'shitaway.tk',
        'shitmail.de',
        'shitmail.org',
        'shotmail.ru',
        'showslow.de',
        'shrib.com',
        'shut.name',
        'shut.ws',
        'sidelka-mytischi.ru',
        'siftportal.ru',
        'sify.com',
        'sika3.com',
        'sikux.com',
        'siliwangi.ga',
        'silvercoin.life',
        'sim-simka.ru',
        'simaenaga.com',
        'simpleitsecurity.info',
        'sin.cl',
        'sinema.ml',
        'sinfiltro.cl',
        'singlespride.com',
        'sino.tw',
        'siteposter.net',
        'sizzlemctwizzle.com',
        'sjuaq.com',
        'skeefmail.com',
        'skrx.tk',
        'sky-inbox.com',
        'sky-ts.de',
        'slave-auctions.net',
        'slippery.email',
        'slipry.net',
        'slopsbox.com',
        'slothmail.net',
        'slushmail.com',
        'sluteen.com',
        'sly.io',
        'smallker.tk',
        'smapfree24.com',
        'smapfree24.de',
        'smapfree24.eu',
        'smapfree24.info',
        'smapfree24.org',
        'smarttalent.pw',
        'smellrear.com',
        'smellypotato.tk',
        'smtp99.com',
        'smwg.info',
        'snapwet.com',
        'social-mailer.tk',
        'socialfurry.org',
        'sofia.re',
        'sofort-mail.de',
        'sofortmail.de',
        'softkey-office.ru',
        'softpls.asia',
        'sohai.ml',
        'sohus.cn',
        'soioa.com',
        'soisz.com',
        'solar-impact.pro',
        'solventtrap.wiki',
        'sonshi.cf',
        'soodmail.com',
        'soodomail.com',
        'soon.it',
        'spacebazzar.ru',
        'spam-be-gone.com',
        'spam.care',
        'spam.la',
        'spam.org.es',
        'spam.su',
        'spamavert.com',
        'spambob.com',
        'spambob.org',
        'spambog.com',
        'spambog.de',
        'spambog.net',
        'spambooger.com',
        'spambox.info',
        'spambox.me',
        'spambox.org',
        'spamcero.com',
        'spamdecoy.net',
        'spamfighter.cf',
        'spamfighter.ga',
        'spamfighter.gq',
        'spamfighter.ml',
        'spamfighter.tk',
        'spamfree24.eu',
        'spamfree24.info',
        'spamfree24.net',
        'spaminator.de',
        'spamkill.info',
        'spaml.com',
        'spamlot.net',
        'spamoff.de',
        'spamsalad.in',
        'spamsphere.com',
        'spamstack.net',
        'spamthisplease.com',
        'spamtrail.com',
        'spamtrap.ro',
        'spamwc.cf',
        'spamwc.ga',
        'spamwc.gq',
        'spamwc.ml',
        'speedgaus.net',
        'sperma.cf',
        'spikio.com',
        'spindl-e.com',
        'spr.io',
        'spritzzone.de',
        'spybox.de',
        'squizzy.de',
        'squizzy.net',
        'sroff.com',
        'sry.li',
        'ssoia.com',
        'stanfordujjain.com',
        'starlight-breaker.net',
        'starpower.space',
        'startfu.com',
        'startkeys.com',
        'statdvr.com',
        'stathost.net',
        'statiix.com',
        'stayhome.li',
        'steam-area.ru',
        'steambot.net',
        'stexsy.com',
        'stinkefinger.net',
        'stop-my-spam.cf',
        'stop-my-spam.com',
        'stop-my-spam.ga',
        'stop-my-spam.ml',
        'stop-my-spam.pp.ua',
        'stop-my-spam.tk',
        'storiqax.top',
        'storj99.com',
        'storj99.top',
        'streetwisemail.com',
        'stromox.com',
        'stuckmail.com',
        'stumpfwerk.com',
        'stylist-volos.ru',
        'suburbanthug.com',
        'suckmyd.com',
        'suioe.com',
        'superplatyna.com',
        'supersave.net',
        'sute.jp',
        'svip520.cn',
        'svk.jp',
        'svxr.org',
        'sweetpotato.ml',
        'sweetxxx.de',
        'swift-mail.net',
        'swift10minutemail.com',
        'sylvannet.com',
        'symphonyresume.com',
        'syosetu.gq',
        'syujob.accountants',
        'szerz.com',
        'tafmail.com',
        'tafoi.gr',
        'taglead.com',
        'tagmymedia.com',
        'tagyourself.com',
        'tanukis.org',
        'tapchicuoihoi.com',
        'taphear.com',
        'tapi.re',
        'tarzanmail.cf',
        'tastrg.com',
        'tb-on-line.net',
        'tech69.com',
        'techemail.com',
        'techgroup.me',
        'technoproxy.ru',
        'teerest.com',
        'tefl.ro',
        'telecomix.pl',
        'tellos.xyz',
        'temp-link.net',
        'temp-mail.com',
        'temp-mail.de',
        'temp-mail.pp.ua',
        'temp-mails.com',
        'tempail.com',
        'tempalias.com',
        'tempemail.biz',
        'tempmail.co',
        'tempmail.de',
        'tempmail.it',
        'tempmail.pp.ua',
        'tempmail.us',
        'tempmail.ws',
        'tempmail2.com',
        'tempmailo.com',
        'tempomail.org',
        'temporarily.de',
        'temporarioemail.com.br',
        'temporary-mail.net',
        'temporaryemail.us',
        'tempr.email',
        'tempsky.com',
        'tempymail.com',
        'ternaklele.ga',
        'testore.co',
        'testudine.com',
        'thanksnospam.info',
        'thatim.info',
        'theaviors.com',
        'thebearshark.com',
        'thecity.biz',
        'thecloudindex.com',
        'thediamants.org',
        'thedirhq.info',
        'thelightningmail.net',
        'thembones.com.au',
        'themegreview.com',
        'themostemail.com',
        'thereddoors.online',
        'theroyalweb.club',
        'thescrappermovie.com',
        'theteastory.info',
        'thex.ro',
        'thietbivanphong.asia',
        'thisurl.website',
        'thnikka.com',
        'thoas.ru',
        'thraml.com',
        'thrma.com',
        'throam.com',
        'thrott.com',
        'throwam.com',
        'throwawaymail.com',
        'throwawaymail.pp.ua',
        'throya.com',
        'thrubay.com',
        'thunderbolt.science',
        'thunkinator.org',
        'thxmate.com',
        'tiapz.com',
        'tic.ec',
        'timgiarevn.com',
        'timkassouf.com',
        'tinoza.org',
        'tinyurl24.com',
        'tipsb.com',
        'tiv.cc',
        'tkitc.de',
        'tlpn.org',
        'tmail.com',
        'tmail.ws',
        'tmails.net',
        'tmpbox.net',
        'tmpemails.com',
        'tmpeml.info',
        'tmpjr.me',
        'tmpmail.net',
        'tmpmail.org',
        'tmpnator.live',
        'toddsbighug.com',
        'toiea.com',
        'tokem.co',
        'tokenmail.de',
        'tonne.to',
        'tonymanso.com',
        'toon.ml',
        'top-shop-tovar.ru',
        'top101.de',
        'top1mail.ru',
        'top1post.ru',
        'topinrock.cf',
        'topmail2.com',
        'topmail2.net',
        'topofertasdehoy.com',
        'toprumours.com',
        'tormail.org',
        'toss.pw',
        'tosunkaya.com',
        'totalvista.com',
        'totesmail.com',
        'totoan.info',
        'tp-qa-mail.com',
        'tqoai.com',
        'tqosi.com',
        'tranceversal.com',
        'trash-amil.com',
        'trash-mail.cf',
        'trash-mail.ga',
        'trash-mail.gq',
        'trash-mail.ml',
        'trash-mail.tk',
        'trash-me.com',
        'trash2010.com',
        'trash2011.com',
        'trashcanmail.com',
        'trashdevil.de',
        'trashemails.de',
        'trashinbox.com',
        'trashmail.gq',
        'trashmail.io',
        'trashmail.ws',
        'trashmailer.com',
        'trashmailgenerator.de',
        'trashmails.com',
        'trashymail.net',
        'trasz.com',
        'trayna.com',
        'trbvm.com',
        'trbvn.com',
        'trbvo.com',
        'trend-maker.ru',
        'trgovinanaveliko.info',
        'trickmail.net',
        'trixtrux1.ru',
        'trollproject.com',
        'tropicalbass.info',
        'trungtamtoeic.com',
        'truthfinderlogin.com',
        'tryalert.com',
        'tryninja.io',
        'tryzoe.com',
        'ttirv.org',
        'ttszuo.xyz',
        'tualias.com',
        'tuofs.com',
        'turoid.com',
        'turual.com',
        'tvchd.com',
        'tverya.com',
        'twkly.ml',
        'twocowmail.net',
        'twoweirdtricks.com',
        'twzhhq.online',
        'txtadvertise.com',
        'tyhe.ro',
        'tympe.net',
        'uacro.com',
        'uber-mail.com',
        'ubismail.net',
        'ubm.md',
        'ucche.us',
        'ucupdong.ml',
        'uemail99.com',
        'ufacturing.com',
        'uguuchantele.com',
        'uhhu.ru',
        'uiu.us',
        'ujijima1129.gq',
        'uk.to',
        'ultra.fyi',
        'ultrada.ru',
        'uma3.be',
        'undo.it',
        'unicodeworld.com',
        'unids.com',
        'unimark.org',
        'unit7lahaina.com',
        'unmail.ru',
        'upliftnow.com',
        'uplipht.com',
        'uploadnolimit.com',
        'upozowac.info',
        'urfunktion.se',
        'urhen.com',
        'us.to',
        'usa.cc',
        'usako.net',
        'usbc.be',
        'used-product.fr',
        'ushijima1129.cf',
        'ushijima1129.ga',
        'ushijima1129.gq',
        'ushijima1129.ml',
        'ushijima1129.tk',
        'utiket.us',
        'uu.gl',
        'uu2.ovh',
        'uwork4.us',
        'uyhip.com',
        'vaasfc4.tk',
        'vaati.org',
        'valemail.net',
        'valhalladev.com',
        'vankin.de',
        'vctel.com',
        'vda.ro',
        'vdig.com',
        'veanlo.com',
        'vemomail.win',
        'veo.kr',
        'ver0.cf',
        'ver0.ga',
        'ver0.gq',
        'ver0.ml',
        'ver0.tk',
        'vercelli.cf',
        'vercelli.ga',
        'vercelli.gq',
        'vercelli.ml',
        'verdejo.com',
        'vermutlich.net',
        'veryday.ch',
        'veryday.eu',
        'veryday.info',
        'vesa.pw',
        'vfemail.net',
        'via.tokyo.jp',
        'vickaentb.tk',
        'victime.ninja',
        'victoriantwins.com',
        'vidchart.com',
        'viewcastmedia.com',
        'viewcastmedia.net',
        'viewcastmedia.org',
        'vikingsonly.com',
        'vinernet.com',
        'vipepe.com',
        'vipmail.name',
        'vipmail.pw',
        'vipxm.net',
        'virginiaintel.com',
        'virtualemail.info',
        'visal007.tk',
        'visal168.cf',
        'visal168.ga',
        'visal168.gq',
        'visal168.ml',
        'visal168.tk',
        'vixletdev.com',
        'vkcode.ru',
        'vmailing.info',
        'vmani.com',
        'vmpanda.com',
        'vnedu.me',
        'voidbay.com',
        'voltaer.com',
        'vomoto.com',
        'vorga.org',
        'votiputox.org',
        'voxelcore.com',
        'vps30.com',
        'vps911.net',
        'vradportal.com',
        'vremonte24-store.ru',
        'vrmtr.com',
        'vssms.com',
        'vtxmail.us',
        'vuiy.pw',
        'vusra.com',
        'vztc.com',
        'w-asertun.ru',
        'w3internet.co.uk',
        'wakingupesther.com',
        'walala.org',
        'walkmail.net',
        'walkmail.ru',
        'wallm.com',
        'wanko.be',
        'watch-harry-potter.com',
        'watchever.biz',
        'watchfull.net',
        'watchironman3onlinefreefullmovie.com',
        'wazabi.club',
        'wbdev.tech',
        'wbml.net',
        'web-contact.info',
        'web-ideal.fr',
        'web-inc.net',
        'web-mail.pp.ua',
        'web2mailco.com',
        'webcontact-france.eu',
        'webhook.site',
        'webm4il.info',
        'webmail24.top',
        'webtrip.ch',
        'webuser.in',
        'wee.my',
        'wef.gr',
        'wegwerf-email-addressen.de',
        'wegwerf-email-adressen.de',
        'wegwerf-email.at',
        'wegwerf-email.de',
        'wegwerf-email.net',
        'wegwerfemail.info',
        'wegwerfemail.net',
        'wegwerfemail.org',
        'wegwerfemailadresse.com',
        'wegwerpmailadres.nl',
        'wegwrfmail.de',
        'wegwrfmail.net',
        'wegwrfmail.org',
        'welikecookies.com',
        'wellsfargocomcardholders.com',
        'wemel.top',
        'wetrainbayarea.com',
        'wetrainbayarea.org',
        'wfgdfhj.tk',
        'wg0.com',
        'whatiaas.com',
        'whatifanalytics.com',
        'whatpaas.com',
        'whatsaas.com',
        'whiffles.org',
        'whopy.com',
        'wibblesmith.com',
        'wickmail.net',
        'widaryanto.info',
        'widget.gg',
        'wierie.tk',
        'wifimaple.com',
        'wifioak.com',
        'wikidocuslava.ru',
        'wilemail.com',
        'wimsg.com',
        'wins.com.br',
        'wlist.ro',
        'wmail.cf',
        'wmail.club',
        'wokcy.com',
        'wolfmail.ml',
        'wolfsmail.tk',
        'wollan.info',
        'worldspace.link',
        'wpg.im',
        'wralawfirm.com',
        'writeme.us',
        'ws.gy',
        'wudet.men',
        'wuespdj.xyz',
        'wupics.com',
        'wuzup.net',
        'wuzupmail.net',
        'wwjmp.com',
        'wxnw.net',
        'x24.com',
        'xagloo.co',
        'xbaby69.top',
        'xcode.ro',
        'xcodes.net',
        'xcompress.com',
        'xcpy.com',
        'xemne.com',
        'xjoi.com',
        'xl.cx',
        'xmail.com',
        'xmailer.be',
        'xn--9kq967o.com',
        'xn--d-bga.net',
        'xost.us',
        'xoxox.cc',
        'xperiae5.com',
        'xrho.com',
        'xvx.us',
        'xwaretech.com',
        'xwaretech.info',
        'xwaretech.net',
        'xww.ro',
        'xxi2.com',
        'xxolocanto.us',
        'xxqx3802.com',
        'yahooproduct.net',
        'yanet.me',
        'yarnpedia.ga',
        'ycare.de',
        'yedi.org',
        'yeezus.ru',
        'yodx.ro',
        'yomail.info',
        'yoo.ro',
        'yopmail.pp.ua',
        'yougotgoated.com',
        'youmailr.com',
        'yourlms.biz',
        'yui.it',
        'yuoia.com',
        'yyolf.net',
        'z0d.eu',
        'zainmax.net',
        'zaktouni.fr',
        'zasod.com',
        'zaym-zaym.ru',
        'zdenka.net',
        'ze.tc',
        'zebins.com',
        'zepp.dk',
        'zetmail.com',
        'zfymail.com',
        'zhaoyuanedu.cn',
        'zhcne.com',
        'zhewei88.com',
        'zhorachu.com',
        'zik.dj',
        'zipcad.com',
        'zoemail.org',
        'zsero.com',
        'zwoho.com',
        'zxcvbnm.com',
        'zymuying.com',
        'zzi.us',
        'zzrgg.com',
        'zzz.com',
    ];
}

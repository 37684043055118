import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
    ClientEntity,
    ProEntity,
    PropertyEntity,
    SocietyEntity,
    SubscriptionEntity,
    UserEntity,
} from '@omedom/data';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { PropertyService } from './property.service';
import { RestService } from './rest.service';
import { SubscriptionService } from './subscription.service';
import { UserService } from './user.service';
import { SocietyService } from './society.service';
import { ClientService } from './client.service';

@Injectable({
    providedIn: 'root',
})
export class ProService extends RestService<ProEntity> {
    protected override builder = ProEntity;

    constructor(
        protected override firestore: AngularFirestore,
        private userService: UserService,
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private subscriptionService: SubscriptionService,
        private clientService: ClientService,
        private functions: AngularFireFunctions
    ) {
        super(firestore, 'pros');
    }

    /**
     * @description Get the clients of a pro with the proUID
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/09/2023
     * @param {string} proUID
     * @returns {*}  {Observable<UserEntity[]>}
     * @memberof ProService
     */
    public _getClients(proUID: string): Observable<{
        users: UserEntity[];
        clients: ClientEntity[];
        subscriptions: SubscriptionEntity[];
    }> {
        // Fetch subscriptions that match the given professional UID
        return this.clientService
            ._search([{ where: 'proUID', operator: '==', value: proUID }])
            .pipe(
                switchMap((clients) => {
                    // Extract user UIDs from subscriptions, ignoring any undefined or null entries
                    const userUIDs = clients.map((client) => client.userUID).filter(Boolean);

                    // Return early with empty arrays if no user UIDs are found
                    if (!userUIDs || userUIDs.length === 0) {
                        return of([]);
                    }

                    // Fetch users based on the UIDs obtained from the subscriptions
                    const users$ = combineLatest(
                        userUIDs.map((userUID) =>
                            this.userService._get(userUID).pipe(catchError(() => of(null)))
                        )
                    ).pipe(map((users) => users.filter(Boolean) as UserEntity[]));

                    const subscriptions$ = this.subscriptionService._search([
                        { where: 'proUID', operator: '==', value: proUID },
                    ]);
                    // Combine the fetched users with the original subscriptions
                    return combineLatest([users$, of(clients), subscriptions$]);
                }),
                switchMap(([users, clients, subscriptions]) => {
                    // If no users are found, return empty arrays for users, subscriptions, and properties
                    if (!users || users.length === 0) {
                        return of({
                            users: [] as UserEntity[],
                            clients: [] as ClientEntity[],
                            subscriptions: [] as SubscriptionEntity[],
                        });
                    }

                    // Combine all fetched data into one object
                    return of({
                        users: users,
                        clients: clients,
                        subscriptions: subscriptions,
                    });
                })
            );
    }

    /**
     * @description Check if the pro is enable to access to the client profil
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/09/2023
     * @param {(string | null)} uid
     * @memberof ProService
     */
    public isEnableToAccessClientProfil(uid: string | null): Observable<boolean> {
        // Get the proUID of the user
        return this.userService.user$.pipe(
            map((user) => {
                // Check if the user is logged in
                if (!user) {
                    return false;
                }

                // Check if the user is a pro
                if (!user.proUID) {
                    return false;
                }

                // Get the proUID of the user
                return user.proUID;
            }),
            switchMap((proUID) => {
                // Check if the proUID is valid
                if (!proUID) {
                    return of(false);
                }

                // Check if the proUID is type of string
                if (typeof proUID !== 'string') {
                    return of(false);
                }

                // Get the clients of the pro
                return this._getClients(proUID);
            }),
            map((data) => {
                // Check if the users is valid
                if (!data) {
                    return false;
                }

                // Check if the users isn't type of boolean
                if (typeof data === 'boolean') {
                    return false;
                }

                // Get the users
                const { users, clients } = data;

                // Check if the client uid is valid
                if (!uid) {
                    return false;
                }

                const user = users.find((user) => user.uid === uid);

                const client = clients.find((client) => client.userUID === uid);

                // Check if the client uid is in the clients of the pro
                return !!user && !!client && client.isMandated && client.isValidatedByPro;
            })
        );
    }

    /**
     * @description get pros given an array of proUIDs
     * @author ANDRE Felix
     * @param {string[]} proUIDs
     * @returns {*}
     * @memberof ProService
     */
    public _getProByUIDs(proUIDs: string[]) {
        return this._list().pipe(
            map((pros) => {
                const proFiltered = pros.filter((pro) => proUIDs.includes(pro.uid));
                return proFiltered;
            })
        );
    }

    /**
     * @description Create a pro user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 21/10/2023
     * @param {ProEntity} pro
     * @param {string} name
     * @param {string} firstname
     * @returns {*}  {Observable<{ success: boolean; message?: string; }>}
     * @memberof ProService
     */
    public createProUser(
        pro: ProEntity,
        name: string,
        firstname: string
    ): Observable<{ success: boolean; message?: string }> {
        return this.functions.httpsCallable<
            { pro: ProEntity; name: string; firstname: string },
            { success: boolean; message?: string }
        >('createProUser')({ pro, name, firstname });
    }

    /**
     * @description Check if the pro exist with his uid
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 21/10/2023
     * @param {string} uid
     * @returns {*}  {Observable<boolean>}
     * @memberof ProService
     */
    public isProExist(uid: string): Observable<boolean> {
        return this.functions
            .httpsCallable<{ uid: string }, { isProExist: boolean }>('isProExist')({ uid })
            .pipe(map((data) => data.isProExist));
    }

    /**
     * @description get pro By user UID
     * @author ANDRE Felix
     * @param {string} userUID
     * @returns {*}
     * @memberof ProService
     */
    public _getProByUserUID(userUID: string) {
        return this._search([{ where: 'userUID', operator: '==', value: userUID }]).pipe(
            map((company) => company[0])
        );
    }
}

import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ModalTab, SocietyEntity } from '@omedom/data';

@Component({
    selector: 'omedom-society-profil',
    templateUrl: './society-profil.component.html',
    styleUrls: ['./society-profil.component.scss'],
})
export class SocietyProfilComponent {
    /**
     * @description Society to display
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/09/2023
     * @type {SocietyEntity}
     * @memberof SocietyProfilComponent
     */
    @Input()
    public society?: SocietyEntity;

    /**
     * @description Tabs to display in the modal header
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {ModalTab[]}
     * @memberof SocietyProfilComponent
     */
    public tabs: ModalTab[] = [
        {
            label: 'Informations',
            name: 'info',
            index: 0,
            active: true,
        },
        {
            label: 'Trésorerie',
            name: 'treasury',
            index: 1,
        },
        {
            label: 'Documents',
            name: 'documents',
            index: 2,
        },
    ];

    /**
     * @description Info template to display in the modal body according to the active tab (optional) (default: undefined)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @type {TemplateRef<HTMLElement>}
     * @memberof SocietyProfilComponent
     */
    @ViewChild('info', { static: true })
    public infoTemplate?: TemplateRef<HTMLElement>;

    /**
     * @description Treasury template to display in the modal body according to the active tab (optional) (default: undefined)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @type {TemplateRef<HTMLElement>}
     * @memberof SocietyProfilComponent
     */
    @ViewChild('treasury', { static: true })
    public treasuryTemplate?: TemplateRef<HTMLElement>;

    /**
     * @description Documents template to display in the modal body according to the active tab (optional) (default: undefined)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @type {TemplateRef<HTMLElement>}
     * @memberof SocietyProfilComponent
     */
    @ViewChild('documents', { static: true })
    public documentsTemplate?: TemplateRef<HTMLElement>;

    /**
     * @description Get active tab to select the right template to display
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @readonly
     * @type {string}
     * @memberof SocietyProfilComponent
     */
    public get activeTab(): ModalTab {
        // Get active tab
        return this.tabs.find((x) => x.active) ?? this.tabs[0];
    }

    /**
     * @description Get the right template to display according to the active tab
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @returns {*}  {(TemplateRef<HTMLElement> | null)}
     * @memberof SocietyProfilComponent
     */
    public getTemplate(): TemplateRef<HTMLElement> | null {
        // Get active tab
        const tab = this.activeTab;

        switch (tab.name) {
            case 'info':
                return this.infoTemplate ?? null;
            case 'treasury':
                return this.treasuryTemplate ?? null;
            case 'documents':
                return this.documentsTemplate ?? null;
            default:
                return null;
        }
    }
}

/**
 * @description The translation interface, used to store translations of different languages for the same entity
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 05/03/2024
 * @export
 * @interface Translation
 */
export interface Translation {
    /**
     * @description The translation in French (default language)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof Translation
     */
    fr: string;

    /**
     * @description The translation in English
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof Translation
     */
    en: string;
}

import { SocietyEntity, SocietyStatusMember, UserEntity } from '@omedom/data';

export class OmedomSociety {
    public static isSocietyManagerExist(society: SocietyEntity) {
        const societyManagerIndex = society.members.findIndex((member) => {
            return member.status === SocietyStatusMember.manager;
        });

        return societyManagerIndex > -1;
    }

    public static isCurrentUserManager(society: SocietyEntity, user: UserEntity) {
        const memberIndex = society.members.findIndex((member) => {
            return member.email === user.email;
        });

        if (memberIndex > -1) {
            return society.members[memberIndex].status === SocietyStatusMember.manager;
        }
        return false;
    }
}

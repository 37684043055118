<omedom-modal-header>Activer une licence</omedom-modal-header>

<div class="container">
  <form [formGroup]="sendForm" (ngSubmit)="send()">
    <omedom-input
      [boldLabel]="true"
      formControlName="email"
      label="E-mail"
      placeholder="Renseignez l'e-mail de votre client"
      type="email"
    ></omedom-input>

    @if (errorMessage) {
      <span class="omedom-form-error">{{
        errorMessage
      }}</span>
    }
    @if (errorUserCantHaveLicence) {
      <omedom-info
        class="margin-top-1"
        [information]="infoMessageSubscriptionAlreadyValid"
      ></omedom-info>
    }

    <p>
      Votre client va recevoir un e-mail lui indiquant les étapes à suivre
      pour l'installation de l'application OMEDOM et l'activation de sa
      licence.<br />
      Une copie de cet e-mail vous sera envoyé.
    </p>

    <button
      type="submit"
      [disabled]="!sendForm.valid || pending"
      class="omedom-button-yellow"
      >
      <i class="uil uil-message"></i>
      Envoyer
      @if (pending) {
        <ion-spinner></ion-spinner>
      }
    </button>
  </form>
</div>

<span class="margin-y-2">{{ message }} </span>
<button class="omedom-button-primary" (click)="buttonClicked.emit()">
  <i class="uil uil-plus"></i>
  @if (buttonIcon.startsWith('uil')) {
    <i
      class="uil"
      [class]="buttonIcon"
    ></i>
  }
  @if (!buttonIcon.startsWith('uil')) {
    <div
      class="icon"
      [style.mask]="iconMask"
      [style.-webkit-mask]="iconMask"
    ></div>
  }
</button>

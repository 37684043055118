import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import {
    AllChargeCategories,
    AllIncomeCategories,
    BankTransactionEntity,
    ChargeCategoryInfo,
    ChargeCategoryProperty,
    ChargeEntity,
    ChargePeriodicityInfo,
    IncomeCategoryInfo,
    IncomeEntity,
    IncomePeriodicityInfo,
} from '@omedom/data';
import { BankTransactionService, ChargeService, IncomeService } from '@omedom/services';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

import { elementAnimation } from '../../../animations';

@Component({
    selector: 'app-bank-transaction-dissociation-confirm',
    templateUrl: './bank-transaction-dissociation-confirm.container.html',
    styleUrls: ['./bank-transaction-dissociation-confirm.container.scss'],
    animations: [elementAnimation],
})
export class BankTransactionDissociationConfirmContainer implements OnInit {
    /**
     * @description Transaction to dissociate
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @type {BankTransactionEntity}
     * @memberof BankTransactionDissociationConfirmContainer
     */
    @Input()
    public transaction?: BankTransactionEntity;

    /**
     * @description Treasury to dissociate from the transaction
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @type {(ChargeEntity | IncomeEntity)}
     * @memberof BankTransactionDissociationConfirmContainer
     */
    public treasury?: ChargeEntity | IncomeEntity;

    /**
     * @description Message to display in the confirm button of the modal component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @memberof BankTransactionDissociationConfirmContainer
     */
    public message$ = new BehaviorSubject<string>('Oui, dissocier');

    /**
     * @description State of the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @memberof BankTransactionDissociationConfirmContainer
     */
    public state$ = new BehaviorSubject<string>('ok');

    /**
     * @description Message to display when no charge or income is found for the transaction to dissociate from the treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @memberof BankTransactionDissociationConfirmContainer
     */
    public notFoundMessage$ = new BehaviorSubject<string>(
        'Aucune charge ou revenu trouvé pour ce mouvement'
    );

    constructor(
        private modalController: ModalController,
        private bankTransactionService: BankTransactionService,
        private toastController: ToastController,
        private chargeService: ChargeService,
        private incomeService: IncomeService
    ) {}

    async ngOnInit(): Promise<void> {
        if (!this.transaction || !this.transaction.treasuryUID) {
            return;
        }

        // Get the treasury to dissociate from the transaction
        const isCharge = this.transaction.amount < 0;
        this.treasury = isCharge
            ? await this.chargeService.get(this.transaction.treasuryUID)
            : await this.incomeService.get(this.transaction.treasuryUID);

        // Check if the treasury is found
        if (!this.treasury) {
            this.notFoundMessage$.next(
                isCharge
                    ? 'Aucune charge trouvée pour ce mouvement'
                    : 'Aucun revenu trouvé pour ce mouvement'
            );
        }
    }

    /**
     * @description Dismiss the modal component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @returns {Promise<void>}
     * @memberof BankTransactionDissociationConfirmContainer
     */
    public async dismiss(): Promise<void> {
        // Check if the state is pending
        if (this.state$.value === 'pending') {
            return;
        }

        await this.modalController.dismiss();
    }

    /**
     * @description Dissociate the bank transaction and dismiss the modal component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @returns {Promise<void>}
     * @memberof BankTransactionDissociationConfirmContainer
     */
    public async delete(): Promise<void> {
        // Disable dismiss for the modal component
        const modal = await this.modalController.getTop();

        if (modal) {
            modal.backdropDismiss = false;
        }

        // Set the state to pending
        this.state$.next('pending');

        // Set the message to display in the confirm button
        this.message$.next('Dissociation...');

        if (this.transaction) {
            // Dissociate the transaction
            await lastValueFrom(this.bankTransactionService.dissociate(this.transaction));

            // Set the state to ok
            this.state$.next('ok');

            // Dismiss the modal component
            await this.dismiss();

            // Display a toast message
            const toast = await this.toastController.create({
                message: 'Ce mouvement a été dissocié avec succès',
                duration: 3000,
                color: 'success',
            });

            await toast.present();
        } else {
            // Display a toast message
            const toast = await this.toastController.create({
                message: 'Une erreur est survenue lors de la dissociation du mouvement',
                duration: 3000,
                color: 'danger',
            });

            await toast.present();

            // Set the state to ok
            this.state$.next('ok');
        }

        // Set the message to display in the confirm button
        this.message$.next('Oui, dissocier');

        // Enable dismiss for the modal component
        if (modal) {
            modal.backdropDismiss = true;
        }
    }

    /**
     * @description Get the category info from the category form to display the right information in the confirmation step
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @param {(ChargeEntity | IncomeEntity)} treasury
     * @returns {(ChargeCategoryInfo | IncomeCategoryInfo)}
     * @memberof BankTransactionDissociationConfirmContainer
     */
    public getCategoryInfo(
        treasury: ChargeEntity | IncomeEntity
    ): ChargeCategoryInfo | IncomeCategoryInfo {
        if (!this.transaction) {
            return new ChargeCategoryInfo(ChargeCategoryProperty.various);
        }

        const isCharge = this.transaction.amount < 0;

        return isCharge
            ? new ChargeCategoryInfo(treasury.category as AllChargeCategories)
            : new IncomeCategoryInfo(treasury.category as AllIncomeCategories);
    }

    /**
     * @description Get the periodicity info from the details form to display the right information in the confirmation step
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @param {(ChargeEntity | IncomeEntity)} treasury
     * @returns {(ChargePeriodicityInfo | IncomePeriodicityInfo)}
     * @memberof BankTransactionDissociationConfirmContainer
     */
    public getPeriodicityInfo(
        treasury: ChargeEntity | IncomeEntity
    ): ChargePeriodicityInfo | IncomePeriodicityInfo {
        return treasury instanceof ChargeEntity
            ? new ChargePeriodicityInfo(treasury.periodicity)
            : new IncomePeriodicityInfo(treasury.periodicity);
    }
}

@if (title) {
<div class="header">
    <div class="title">
        @if (titleIcon) {
        <i class="{{ titleIcon }} "></i>
        }
        <strong class="title-text">{{ title }}</strong>
    </div>
</div>
}

<div class="infos">
    <div class="info layout-{{layout}}">
        <span class="title">Total remboursé</span>
        <span class="value" id="repaid"
            >{{ ('accessManageFinance' | isAuthorised | async) ? (totalRepaid |
            currency : 'EUR' ) : '--,--' }}
        </span>
    </div>

    <div class="info layout-{{layout}}">
        <span class="title">Total restant dû</span>
        <span class="value" id="remaining"
            >{{ ('accessManageFinance' | isAuthorised | async) ? (totalRemaining
            | currency : 'EUR' ) : '--,--' }}</span
        >
    </div>
</div>

@if (showGraph) {
<ng-container>
    <div class="progress-bar">
        <div
            class="total-repaid-container"
            [style.width]="percentageRepaid + '%'"
        >
            @for (loan of loans; track loan.uid) {
            <div
                class="progress-value"
                [style.width]="getPercentage(loan) + '%'"
                [style.background]="getColor(loan)"
            ></div>
            }
        </div>
    </div>

    <div class="legend">
        @for (loan of loans; track loan.uid) {
        <div class="item">
            <div class="color" [style.background]="getColor(loan)"></div>
            <span class="label">{{ loan.name }}</span>
            @if (loan.interestRate) {
            <span class="interest-rate"
                >{{ ('accessManageFinance' | isAuthorised | async) ?
                (loan.interestRate | number) : '--,--' }} %</span
            >
            } @if (loan.borrowedCapital) {
            <span class="borrowed"
                >{{ ('accessManageFinance' | isAuthorised | async) ?
                (loan.borrowedCapital | currency : 'EUR' ) : '--,--'}}</span
            >
            }
        </div>
        }
    </div>
</ng-container>
}

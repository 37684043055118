import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UseEntity } from '@omedom/data';

import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root',
})
export class UseService extends RestService<UseEntity> {
    constructor(protected override firestore: AngularFirestore) {
        super(firestore, 'uses');
    }
}

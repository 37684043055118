/**
 * @description Autocomplete option model
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 03/11/2023
 * @export
 * @class AutocompleteOption
 * @template T
 */
export class AutocompleteOption<T> {
    /**
     * @description Label of the option (displayed in the UI)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {string}
     * @memberof AutocompleteOption
     */
    public label: string;

    /**
     * @description Value of the option (used in the code)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {T}
     * @memberof AutocompleteOption
     */
    public value: T;

    constructor(label: string, value: T) {
        this.label = label;
        this.value = value;
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProAlertEntity } from '@omedom/data';
import { ProAlertService } from '@omedom/services';
import { ValidationModalComponent } from '../validation-modal/validation-modal.component';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'omedom-pro-alert-card',
    templateUrl: './pro-alert-card.component.html',
    styleUrls: ['./pro-alert-card.component.scss'],
})
export class ProAlertCardComponent {
    /**
     * @description The pro alert to display
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 08/10/2024
     * @type {ProAlertEntity}
     * @memberof ProAlertCardComponent
     */
    @Input() proAlert?: ProAlertEntity;

    /**
     * @description Enable actions for the pro alert card
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 08/10/2024
     * @memberof ProAlertCardComponent
     */
    @Input() hasEnableActions = true;

    /**
     * @description Event emitter for start chat
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 08/10/2024
     * @memberof ProAlertCardComponent
     */
    @Output() startChat = new EventEmitter<ProAlertEntity>();

    /**
     * @description Event emitter for open setting
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 08/10/2024
     * @memberof ProAlertCardComponent
     */
    @Output() openSetting = new EventEmitter<string>();

    constructor(
        private proAlertService: ProAlertService,
        private modalController: ModalController
    ) {}

    /**
     * @description Enable actions for the pro alert card
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 08/10/2024
     * @returns {*}
     * @memberof ProAlertCardComponent
     */
    getFormattedDate() {
        const date = this.proAlert
            ? new Date((this.proAlert.created as any).seconds * 1000)
            : new Date();
        const actualDate = new Date();
        const diff = actualDate.getTime() - date.getTime();
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        const diffHours = Math.ceil(diff / (1000 * 3600));
        const diffMinutes = Math.ceil(diff / (1000 * 60));
        const diffSeconds = Math.ceil(diff / 1000);
        if (diffDays > 1) {
            return `Il y a ${diffDays} jours`;
        }
        if (diffHours > 1) {
            return `Il y a ${diffHours} heures`;
        }
        if (diffMinutes > 1) {
            return `Il y a ${diffMinutes} minutes`;
        }
        if (diffSeconds > 1) {
            return `Il y a ${diffSeconds} secondes`;
        }
        if (diffSeconds === 1) {
            return 'Il y a 1 seconde';
        }

        return '';
    }

    /**
     * @description Delete the pro alert card from the list of pro alerts
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 08/10/2024
     * @memberof ProAlertCardComponent
     */
    deleteProAlert() {
        if (this.proAlert) {
            this.proAlertService.delete(this.proAlert.uid);
        }
    }

    async changeTreatStatus() {
        if (this.proAlert) {
            await this.proAlertService.update({
                uid: this.proAlert.uid,
                isTreated: !this.proAlert.isTreated,
            });
        }
    }

    public async openValidTreadModal(proAlert: ProAlertEntity) {
        const modal = await this.modalController.create({
            component: ValidationModalComponent,
            canDismiss: true,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            backdropDismiss: true,
            componentProps: {
                title: proAlert.isTreated
                    ? "Annuler le traitement de l'alerte"
                    : "Traiter l'alerte",
                message: proAlert.isTreated
                    ? 'Voulez-vous annuler le traitement de cette alerte ?'
                    : 'Voulez-vous valider cette alerte ?',
                // information: 'Félicitations, vous venez de créer une propriété',
                iconInfo: '',
                validateButtonMessage: 'Valider',
                cancelButtonMessage: 'Plus tard',
            },
        });
        await modal.present();

        await modal.onDidDismiss().then(async (res) => {
            const confirmation = res.data;
            try {
                if (confirmation) {
                    await this.changeTreatStatus();
                }
            } catch (error) {
                console.error(error);
            }
        });
    }
}

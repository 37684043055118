import { FormControl } from '@angular/forms';

/**
 * @description Bank transaction patrimony form type
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 20/05/2024
 * @type {BankTransactionPatrimonyForm}
 * @memberof BankTransactionPatrimonyStepContainer
 */
export type BankTransactionPatrimonyForm = {
    /**
     * @description Choose a property (mandatory if society is not filled)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @type {FormControl<string>}
     */
    propertyUID: FormControl<string>;

    /**
     * @description Choose a society (mandatory if property is not filled)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @type {FormControl<string>}
     */
    societyUID: FormControl<string>;
};

<h3>
    <i class="uil uil-filter margin-right-1"></i>
    <span>Filtrer</span>
</h3>

<i class="uil uil-times-circle close" (click)="close()"></i>

@if(isTypeFree) {
<omedom-input
    label="Type de document"
    class="margin-top-2 margin-bottom-1"
    name="type"
    [(ngModel)]="type"
    placeholder="Recherche..."
></omedom-input>
} @else if ((typeOptions || []).length > 0) {
<omedom-select
    label="Type de document"
    class="margin-top-2 margin-bottom-1"
    name="type"
    [(ngModel)]="type"
    [placeholder]="typePlaceholder"
    [options]="typeOptions || []"
></omedom-select>
} @if (!isPropertyTab && ((assetsOptions$ | async) || []).length > 0) {
<omedom-select
    label="Bien"
    class="margin-bottom-1"
    name="type"
    [(ngModel)]="assetUID"
    [placeholder]="propertyPlaceholder"
    [options]="(assetsOptions$ | async) || []"
></omedom-select>
}

<div class="date-container margin-bottom-1">
    <omedom-input
        class="w-50 padding-right-1"
        type="date"
        name="startDate"
        label="Date de début"
        placeholder="Date de début"
        [(ngModel)]="startDate"
        min="1200-01-01"
        max="2080-01-01"
    >
    </omedom-input>

    <omedom-input
        class="w-50 padding-left-1"
        type="date"
        name="endDate"
        label="Date de fin"
        placeholder="Date de fin"
        [(ngModel)]="endDate"
        min="1200-01-01"
        max="2080-01-01"
    ></omedom-input>
</div>

<div class="button-container margin-top-2">
    <button
        class="omedom-button-secondary margin-right-1"
        (click)="resetFilter()"
    >
        Effacer
    </button>
    <button class="omedom-button-primary flex-1" (click)="sendFilter()">
        Afficher les résultats
    </button>
</div>

import { PASSValue, SMICValue } from '../constants/calc-sim.constant';
import { IRTrancheValue, SASRegime } from '../enums/calc-sim.enum';
import {
    CalcSimResult,
    Contribution,
    Repartition,
    SocialChargesOnRemuneration,
} from '../interfaces/calc-sim.interface';

export enum CompanySize {
    lessThanEleven = 'Moins de 11 salariés',
    elevenToFortyNine = '11 à 49 salariés',
    fiftyToTwoHundredFortyNine = '50 à 249 salariés',
    moreThanTwoHundredFifty = '250 salariés et plus',
}

// KeyCompanySize is a type that is equal to the keys of the CompanySize enum
export type KeyCompanySize = keyof typeof CompanySize;

export class AssimilatedEmployee {
    /**
     * @description Calculate the assimilated employee
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @static
     * @param {number} remunerationSubmitted
     * @param {KeyCompanySize} companySize
     * @param {boolean} hasAdditionalEmploymentContract
     * @param {boolean} companyIsNoSubjectToVAT
     * @returns {*}  {Result}
     * @memberof AssimilatedEmployee
     */
    public static calculateAssimilatedEmployee(
        remunerationSubmitted: number,
        companySize: KeyCompanySize,
        hasAdditionalEmploymentContract: boolean,
        companyIsNoSubjectToVAT: boolean,
        sasRegime: SASRegime,
        irTrancheValue: IRTrancheValue = 0,
        dividendsPaid: number = 0
    ): CalcSimResult {
        // Calculate social charges on remuneration
        const socialChargesOnRemuneration = this.calculateSocialChargesOnRemuneration(
            remunerationSubmitted,
            companySize,
            hasAdditionalEmploymentContract,
            companyIsNoSubjectToVAT
        );

        // Check if the SAS regime is SASIS
        const isSASIS = sasRegime === SASRegime.SASIS;

        // Calculate social charges on dividends
        const socialChargesOnDividends = isSASIS ? dividendsPaid * (17.3 / 100) : 0;

        // Calculate retirement pension
        const retirementPension =
            socialChargesOnRemuneration.basicRetirementPension +
            socialChargesOnRemuneration.complementaryRetirementPension;

        // Calculate taxes on dividends
        const taxesOnDividends = isSASIS ? dividendsPaid * (12.8 / 100) : 0;

        // Calculate net remuneration
        const netRemuneration =
            remunerationSubmitted - socialChargesOnRemuneration.employeeContribution;

        // Calculate taxes on remuneration
        const taxesOnRemuneration = netRemuneration * (irTrancheValue / 100);

        // Calculate taxes
        const taxes: Repartition = {
            total: taxesOnRemuneration + taxesOnDividends,
            partOfDividends: isSASIS ? taxesOnDividends : 0,
            partOfRemuneration: taxesOnRemuneration,
        };

        // Calculate after tax income
        const afterTaxIncome =
            netRemuneration -
            taxes.total -
            socialChargesOnDividends +
            (isSASIS ? dividendsPaid : 0);

        // Calculate company cost on remuneration
        const companyCostOnRemuneration =
            remunerationSubmitted + socialChargesOnRemuneration.employerContribution;

        // Calculate company cost on dividends
        const companyCostOnDividends = isSASIS
            ? dividendsPaid + dividendsPaid * (0.25 / (1 - 0.25))
            : 0;

        // Calculate company cost
        const companyCost: Repartition = {
            total: companyCostOnRemuneration + companyCostOnDividends,
            partOfDividends: companyCostOnDividends,
            partOfRemuneration: companyCostOnRemuneration,
        };

        return {
            companyCost,
            socialChargesOnRemuneration,
            socialChargesOnDividends,
            netRemuneration,
            taxes,
            afterTaxIncome,
            retirementPension,
        };
    }

    /**
     * @description Calculate the social charges of an assimilated employee
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @param {KeyCompanySize} companySize
     * @param {boolean} hasAdditionalEmploymentContract
     * @param {boolean} companyIsNoSubjectToVAT
     * @returns {*}  {SocialCharges}
     * @memberof AssimilatedEmployee
     */
    private static calculateSocialChargesOnRemuneration(
        remunerationSubmitted: number,
        companySize: KeyCompanySize,
        hasAdditionalEmploymentContract: boolean,
        companyIsNoSubjectToVAT: boolean
    ): SocialChargesOnRemuneration {
        // Get all contributions in an array of Contribution objects
        const allContributions = this.getAllContributionsArray(
            remunerationSubmitted,
            companySize,
            hasAdditionalEmploymentContract,
            companyIsNoSubjectToVAT
        );

        // Calculate the sum of employer contributions
        const employerContribution = allContributions.reduce(
            (acc, contribution) => acc + contribution.employerContribution,
            0
        );

        // Calculate the sum of employee contributions
        const employeeContribution = allContributions.reduce(
            (acc, contribution) => acc + contribution.employeeContribution,
            0
        );

        // Calculate the total of contributions
        const total = employerContribution + employeeContribution;

        // Get the Disease, Maternity, Invalidity, Death (DMID) insurance contribution
        const dmidInsurance = this.calculateDMIDInsurance(remunerationSubmitted);

        // Calculate the foresight
        const foresight = dmidInsurance.employeeContribution + dmidInsurance.employerContribution;

        // Calculate the capped  old age insurance contributions
        const cappedOldAgeInsurance = this.calculateCappedOldAgeInsurance(remunerationSubmitted);

        // Calculate the no capped old age insurance contributions
        const noCappedOldAgeInsurance =
            this.calculateNoCappedOldAgeInsurance(remunerationSubmitted);

        // Calculate the basic retirement
        const basicRetirement =
            cappedOldAgeInsurance.employerContribution +
            cappedOldAgeInsurance.employeeContribution +
            noCappedOldAgeInsurance.employerContribution +
            noCappedOldAgeInsurance.employeeContribution;

        // Calculate the basic retirement pension
        const basicRetirementPension = (Math.min(remunerationSubmitted, PASSValue) * 0.5 * 4) / 172;

        // Get the complementary old age insurance contributions
        const complementaryOldAgeInsurance =
            this.calculateComplementaryOldAgeInsurance(remunerationSubmitted);

        // Calculate the complementary retirement
        const complementaryRetirement =
            complementaryOldAgeInsurance.employerContribution +
            complementaryOldAgeInsurance.employeeContribution;

        // Calculate the complementary retirement pension
        const complementaryRetirementPension =
            (complementaryRetirement / (1.27 * 19.6321)) * 1.4159;

        return {
            total,
            employerContribution,
            employeeContribution,
            foresight,
            basicRetirement,
            basicRetirementPension,
            complementaryRetirement,
            complementaryRetirementPension,
        };
    }

    /**
     * @description Get all contributions in an array of Contribution objects for an assimilated employee
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @static
     * @param {number} remunerationSubmitted
     * @param {KeyCompanySize} companySize
     * @param {boolean} hasAdditionalEmploymentContract
     * @param {boolean} companyIsNoSubjectToVAT
     * @returns {*}  {Contribution[]}
     * @memberof AssimilatedEmployee
     */
    public static getAllContributionsArray(
        remunerationSubmitted: number,
        companySize: KeyCompanySize,
        hasAdditionalEmploymentContract: boolean,
        companyIsNoSubjectToVAT: boolean
    ): Contribution[] {
        return [
            this.calculateDMIDInsurance(remunerationSubmitted),
            this.calculateCappedOldAgeInsurance(remunerationSubmitted),
            this.calculateNoCappedOldAgeInsurance(remunerationSubmitted),
            this.calculateComplementaryOldAgeInsurance(remunerationSubmitted),
            this.calculateCEG(remunerationSubmitted),
            this.calculateCET(remunerationSubmitted),
            this.calculateFamilyAllowances(remunerationSubmitted),
            this.calculateWorkAccidents(remunerationSubmitted),
            this.calculateHousingAidFNAL(remunerationSubmitted, companySize),
            this.calculateUnemployment(remunerationSubmitted, hasAdditionalEmploymentContract),
            this.calculateAGS(remunerationSubmitted, hasAdditionalEmploymentContract),
            this.calculateProfessionalTraining(remunerationSubmitted, companySize),
            this.calculateApprenticeshipTaxMainPart(remunerationSubmitted),
            this.calculateApprenticeshipTaxAdditionalPay(remunerationSubmitted, companySize),
            this.calculatePayrollTax(remunerationSubmitted, companyIsNoSubjectToVAT),
            this.calculatePEEC(remunerationSubmitted, companySize),
            this.calculateContributionToSocialDialogue(
                remunerationSubmitted,
                hasAdditionalEmploymentContract
            ),
            this.calculateDeductibleCSG(remunerationSubmitted),
            this.calculateNoDeductibleCSG(remunerationSubmitted),
            this.calculateCRDS(remunerationSubmitted),
            this.calculateSolidarityAutonomy(remunerationSubmitted),
        ];
    }

    /**
     * @description Calculate the Disease, Maternity, Invalidity, Death (DMID) insurance contribution
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateDMIDInsurance(remunerationSubmitted: number): Contribution {
        let employerContribution = 0;

        if (remunerationSubmitted < 2.5 * SMICValue) {
            employerContribution = (7 / 100) * remunerationSubmitted;
        } else {
            employerContribution = (13 / 100) * remunerationSubmitted;
        }

        return { employerContribution, employeeContribution: 0 };
    }

    /**
     * @description Calculate the Capped Old Age insurance contribution for employees born before 1st January 1973
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateCappedOldAgeInsurance(remunerationSubmitted: number): Contribution {
        const contributionBase = Math.min(remunerationSubmitted, PASSValue);

        return {
            employerContribution: (8.55 / 100) * contributionBase,
            employeeContribution: (6.9 / 100) * contributionBase,
        };
    }

    /**
     * @description Calculate the No Capped Old Age insurance contribution
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateNoCappedOldAgeInsurance(remunerationSubmitted: number): Contribution {
        return {
            employerContribution: (2.02 / 100) * remunerationSubmitted,
            employeeContribution: (0.4 / 100) * remunerationSubmitted,
        };
    }

    /**
     * @description Calculate the Complementary Old Age insurance contribution
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateComplementaryOldAgeInsurance(
        remunerationSubmitted: number
    ): Contribution {
        const firstContributionBase = Math.min(remunerationSubmitted, PASSValue);

        let employerContribution = (4.72 / 100) * firstContributionBase;
        let employeeContribution = (3.15 / 100) * firstContributionBase;

        const secondContributionBase = Math.max(
            Math.min(8 * PASSValue, remunerationSubmitted) - PASSValue,
            0
        );

        if (secondContributionBase > 0) {
            employerContribution += (12.95 / 100) * secondContributionBase;
            employeeContribution += (8.64 / 100) * secondContributionBase;
        }

        return { employerContribution, employeeContribution };
    }

    /**
     * @description Calculate the Contribution Economique Généralisée (CEG) contribution
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateCEG(remunerationSubmitted: number): Contribution {
        const firstContributionBase = Math.min(remunerationSubmitted, PASSValue);

        let employerContribution = (1.29 / 100) * firstContributionBase;
        let employeeContribution = (0.86 / 100) * firstContributionBase;

        const secondContributionBase = Math.max(
            Math.min(8 * PASSValue, remunerationSubmitted) - PASSValue,
            0
        );

        if (secondContributionBase > 0) {
            employerContribution += (1.62 / 100) * secondContributionBase;
            employeeContribution += (1.08 / 100) * secondContributionBase;
        }

        return { employerContribution, employeeContribution };
    }

    /**
     * @description Calculate the Contribution Economique Territoriale (CET) contribution
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateCET(remunerationSubmitted: number): Contribution {
        const contributionBase = Math.min(8 * PASSValue, remunerationSubmitted);

        return {
            employerContribution: (0.21 / 100) * contributionBase,
            employeeContribution: (0.14 / 100) * contributionBase,
        };
    }

    /**
     * @description Calculate the Family Allowances contribution
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateFamilyAllowances(remunerationSubmitted: number): Contribution {
        let employerContribution = 0;
        if (remunerationSubmitted < 3.5 * SMICValue) {
            employerContribution = (3.45 / 100) * remunerationSubmitted;
        } else {
            employerContribution = (5.25 / 100) * remunerationSubmitted;
        }

        return { employerContribution, employeeContribution: 0 };
    }

    /**
     * @description Calculate the Work Accidents contribution for assimilated employees
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateWorkAccidents(remunerationSubmitted: number): Contribution {
        return {
            employerContribution: (2.23 / 100) * remunerationSubmitted,
            employeeContribution: 0,
        };
    }

    /**
     * @description Calculate the Housing Aid FNAL contribution for assimilated employees
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @param {KeyCompanySize} companySize
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateHousingAidFNAL(
        remunerationSubmitted: number,
        companySize: KeyCompanySize
    ): Contribution {
        let employerContribution = 0;
        if (companySize === 'lessThanEleven' || companySize === 'elevenToFortyNine') {
            employerContribution = (0.1 / 100) * Math.min(PASSValue, remunerationSubmitted);
        } else {
            employerContribution = (0.5 / 100) * remunerationSubmitted;
        }

        return { employerContribution, employeeContribution: 0 };
    }

    /**
     * @description Calculate the Unemployment contribution for assimilated employees with additional employment contract or not
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @param {boolean} hasAdditionalEmploymentContract
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateUnemployment(
        remunerationSubmitted: number,
        hasAdditionalEmploymentContract: boolean
    ): Contribution {
        const contributionBase = Math.min(4 * PASSValue, remunerationSubmitted);
        let employerContribution = 0;
        if (hasAdditionalEmploymentContract) {
            employerContribution = (4.05 / 100) * contributionBase;
        }

        return {
            employerContribution,
            employeeContribution: 0,
        };
    }

    /**
     * @description Calculate the AGS contribution for assimilated employees with additional employment contract or not
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @param {boolean} hasAdditionalEmploymentContract
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateAGS(
        remunerationSubmitted: number,
        hasAdditionalEmploymentContract: boolean
    ): Contribution {
        const contributionBase = Math.min(4 * PASSValue, remunerationSubmitted);
        let employerContribution = 0;
        if (hasAdditionalEmploymentContract) {
            employerContribution = (0.2 / 100) * contributionBase;
        }
        return {
            employerContribution,
            employeeContribution: 0,
        };
    }

    /**
     * @description Calculate the Professional Training contribution for assimilated employees with company size
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @param {KeyCompanySize} companySize
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateProfessionalTraining(
        remunerationSubmitted: number,
        companySize: KeyCompanySize
    ): Contribution {
        let employerContribution = 0;
        if (companySize === 'lessThanEleven') {
            employerContribution = (0.55 / 100) * remunerationSubmitted;
        } else {
            employerContribution = (1 / 100) * remunerationSubmitted;
        }

        return { employerContribution, employeeContribution: 0 };
    }

    /**
     * @description Calculate the Apprenticeship Tax main part for assimilated employees
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateApprenticeshipTaxMainPart(remunerationSubmitted: number): Contribution {
        return {
            employerContribution: (0.59 / 100) * remunerationSubmitted,
            employeeContribution: 0,
        };
    }

    /**
     * @description Calculate the Apprenticeship Tax additional pay for assimilated employees with company size
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @param {KeyCompanySize} companySize
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateApprenticeshipTaxAdditionalPay(
        remunerationSubmitted: number,
        companySize: KeyCompanySize
    ): Contribution {
        let employerContribution = 0;
        if (companySize === 'moreThanTwoHundredFifty') {
            employerContribution = (0.09 / 100) * remunerationSubmitted;
        }
        return { employerContribution, employeeContribution: 0 };
    }

    /**
     * @description Calculate the Payroll Tax for assimilated employees with company size and company subject to VAT or not
     * Not implemented yet, return 0 for both employer and employee contribution for now
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @param {boolean} companyIsNoSubjectToVAT
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculatePayrollTax(
        remunerationSubmitted: number,
        companyIsNoSubjectToVAT: boolean
    ): Contribution {
        let employerContribution = 0;
        if (companyIsNoSubjectToVAT) {
            const firstContributionBase = Math.min(remunerationSubmitted, 8985);
            employerContribution = (4.25 / 100) * firstContributionBase;

            const secondContributionBase = Math.max(
                0,
                Math.min(remunerationSubmitted, 17936) - 8985
            );

            if (secondContributionBase > 0) {
                employerContribution += (4.25 / 100) * secondContributionBase;
            }

            const thirdContributionBase = Math.max(0, remunerationSubmitted - 17936);

            if (thirdContributionBase > 0) {
                employerContribution += (9.35 / 100) * thirdContributionBase;
            }
        }

        return {
            employerContribution,
            employeeContribution: 0,
        };
    }

    private static calculatePEEC(
        remunerationSubmitted: number,
        companySize: KeyCompanySize
    ): Contribution {
        let employerContribution = 0;
        if (companySize === 'lessThanEleven' || companySize === 'elevenToFortyNine') {
            employerContribution = 0;
        } else {
            employerContribution = (0.45 / 100) * remunerationSubmitted;
        }

        return { employerContribution, employeeContribution: 0 };
    }

    /**
     * @description Calculate the Contribution to Social Dialogue for assimilated employees with additional employment contract or not
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @param {boolean} hasAdditionalEmploymentContract
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateContributionToSocialDialogue(
        remunerationSubmitted: number,
        hasAdditionalEmploymentContract: boolean
    ): Contribution {
        let employerContribution = 0;
        if (hasAdditionalEmploymentContract) {
            employerContribution = (0.016 / 100) * remunerationSubmitted;
        }

        return { employerContribution, employeeContribution: 0 };
    }

    /**
     * @description Calculate the deductible CSG contribution for assimilated employees
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateDeductibleCSG(remunerationSubmitted: number): Contribution {
        const firstContributionBase =
            (Math.min(4 * PASSValue, remunerationSubmitted) * 98.25) / 100;
        let employeeContribution = (6.8 / 100) * firstContributionBase;
        const secondContributionBase = Math.max(0, remunerationSubmitted - 4 * PASSValue);
        if (secondContributionBase > 0) {
            employeeContribution += (6.8 / 100) * secondContributionBase;
        }
        return { employeeContribution, employerContribution: 0 };
    }

    /**
     * @description Calculate the no-deductible CSG contribution for assimilated employees
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateNoDeductibleCSG(remunerationSubmitted: number): Contribution {
        const firstContributionBase =
            (Math.min(4 * PASSValue, remunerationSubmitted) * 98.25) / 100;
        let employeeContribution = (2.4 / 100) * firstContributionBase;
        const secondContributionBase = Math.max(0, remunerationSubmitted - 4 * PASSValue);
        if (secondContributionBase > 0) {
            employeeContribution += (2.4 / 100) * secondContributionBase;
        }
        return { employeeContribution, employerContribution: 0 };
    }

    /**
     * @description Calculate the CRDS contribution for assimilated employees
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateCRDS(remunerationSubmitted: number): Contribution {
        const firstContributionBase =
            (Math.min(4 * PASSValue, remunerationSubmitted) * 98.25) / 100;
        let employeeContribution = (0.5 / 100) * firstContributionBase;
        const secondContributionBase = Math.max(0, remunerationSubmitted - 4 * PASSValue);
        if (secondContributionBase > 0) {
            employeeContribution += (0.5 / 100) * secondContributionBase;
        }
        return { employeeContribution, employerContribution: 0 };
    }

    /**
     * @description Calculate the Solidarity Autonomy contribution for assimilated employees
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/10/2024
     * @private
     * @static
     * @param {number} remunerationSubmitted
     * @returns {*}  {Contribution}
     * @memberof AssimilatedEmployee
     */
    private static calculateSolidarityAutonomy(remunerationSubmitted: number): Contribution {
        return {
            employerContribution: (0.3 / 100) * remunerationSubmitted,
            employeeContribution: 0,
        };
    }
}

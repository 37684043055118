@if (society?.photo) {
<img [src]="society?.photo" class="avatar" alt="society photo" />
} @else {
<div class="avatar">
    <i class="uil uil-suitcase"></i>
</div>
}

<ng-template #photo>
    <img [src]="society?.photo" class="avatar" alt="society photo" />
</ng-template>

<ng-template #avatar>
    <div class="avatar">
        <i class="uil uil-suitcase"></i>
    </div>
</ng-template>

<div class="card-content">
    <h3>{{ society?.name ?? 'Société inconnu' }}</h3>
    <p>{{ society?.type }}</p>
</div>

@if (selected && pro) {
<img
    [src]="pro?.logo"
    [alt]="pro?.name"
    class="logo"
    alt="Logo du professionnel"
/>
}

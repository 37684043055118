<div class="treasury-container {{ status }}">
  <ng-container [ngTemplateOutlet]="header"></ng-container>
  @if (transaction) {
    <ng-container
    [ngTemplateOutlet]="bank"></ng-container>
  }
</div>


<ng-template #header>
  <div class="treasury-header">
    <div class="treasury-title">
      <i class="{{ category.icon }}"></i>
      <span>{{ category.label }}</span>
      <ng-container [ngTemplateOutlet]="paiement"></ng-container>
    </div>

    <div class="treasury-periodicity-container">
      <span class="treasury-amount">{{ (history?.amount || treasury.amount) | omedomNumber }} €</span>
      <span class="treasury-periodicity">{{ periodicity.label }}</span>
    </div>
  </div>
</ng-template>


<ng-template #paiement>
  @switch (status) {
    @case ('paid') {
      <i class="uil uil-check-circle color-green"></i>
    }
    @case ('pending') {
      <i class="uil uil-question-circle color-yellow"></i>
    }
    @case ('unpaid') {
      <i class="uil uil-times-circle color-red"></i>
    }
  }
</ng-template>


<ng-template #bank>
  <div class="treasury-transaction">
    <i class="uil uil-link-alt"></i>
    <span class="treasury-transaction-label">{{ transaction?.cleanDescription }}</span>
  </div>
</ng-template>

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'omedom-suggest-smart',
    templateUrl: './suggest-smart.component.html',
    styleUrls: ['./suggest-smart.component.scss'],
})
export class SuggestSmartComponent {
    /**
     * @description Text of the smart suggest component
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {string}
     * @memberof SuggestSmartComponent
     */
    @Input() text?: string;

    constructor(private router: Router) {}

    /**
     * @description Go to the smart page on click on the suggest smart component
     * @author Brisset Killian
     * @date 26/04/2024
     * @memberof SuggestSmartComponent
     */
    public goSmart(): void {
        this.router.navigateByUrl('/tabs/menu/subscription/change-subscription');
    }
}

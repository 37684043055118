import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AllChargeCategories,
    ChargeCategoryInfo,
    ChargeCategoryProperty,
    ChargeEntity,
    Mode,
    RoleState,
} from '@omedom/data';
import {
    AnalyticsService,
    PropertyService,
    RefreshService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { OmedomDate } from '@omedom/utils';

import { TreasuryBase } from '../../class';

@Component({
    selector: 'omedom-charge',
    templateUrl: './charge.component.html',
    styleUrls: ['./charge.component.scss'],
})
export class ChargeComponent extends TreasuryBase<ChargeEntity, AllChargeCategories> {
    propertyName?: string;

    @Input() public override hideSticker: boolean = false;
    public override alreadyClicGraph: boolean = false;

    @Input()
    showDateBar: boolean = true;

    @Input()
    mode: Mode = Mode.app;

    @Input()
    charges: ChargeEntity[] = [];

    @Input()
    hideFilter: boolean = false;

    @Output()
    navigateToTreasuryDetailEvent: EventEmitter<string> = new EventEmitter();

    @Output()
    navigateToAddTreasuryEvent: EventEmitter<void> = new EventEmitter();

    @Output()
    navigateToCategoryDetailEvent: EventEmitter<{
        category: ChargeCategoryProperty;
        date: string;
    }> = new EventEmitter();

    @Input() roleState?: RoleState;

    constructor(
        userService: UserService,
        decimalPipe: DecimalPipe,
        refreshService: RefreshService,
        protected activeRouter: ActivatedRoute,
        propertyService: PropertyService,
        societyService: SocietyService,
        router: Router,
        private analyticsService: AnalyticsService
    ) {
        super(
            userService,
            decimalPipe,
            refreshService,
            activeRouter,
            propertyService,
            societyService,
            router
        );
        this.treasuryType = 'charge';
    }

    ionViewDidEnter() {
        this.analyticsService.setCurrentScreen('Charge with graph');
    }

    navigateToChargesDetail(): void {
        const dateISOFormat = OmedomDate.formatDateToISO(this.currentDate);
        this.navigateToTreasuryDetailEvent.emit(dateISOFormat);
    }

    addClicked(): void {
        this.navigateToAddTreasuryEvent.emit();
    }

    navigateToDetail(category: ChargeCategoryProperty): void {
        this.navigateToCategoryDetailEvent.emit({
            category,
            date: OmedomDate.formatDateToISO(this.currentDate),
        });
    }

    async loadTreasury(userUid: string): Promise<ChargeEntity[]> {
        let propertiesCharges: ChargeEntity[] = [];
        let societiesCharges: ChargeEntity[] = [];
        const isFilteredUsed = this.selectedProperties.length || this.selectedSocieties.length;

        const propertiesUids = isFilteredUsed
            ? this.selectedProperties.map((x) => x.id)
            : this.properties.map((x) => x.uid);
        const societiesUids = isFilteredUsed
            ? this.selectedSocieties.map((x) => x.id)
            : this.societies.map((x) => x.uid);

        propertiesCharges = this.charges.filter((charge) =>
            propertiesUids.includes(charge.propertyUID)
        );
        societiesCharges = this.charges.filter((charge) =>
            societiesUids.includes(charge.societyUID)
        );

        return [...propertiesCharges, ...societiesCharges];
    }

    transformCategoryToLabel(category: string): ChargeCategoryInfo {
        return new ChargeCategoryInfo(category as AllChargeCategories);
    }
}

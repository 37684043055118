<div class="omedom-modal">
  <button class="close" (click)="dismiss()">
    <i class="uil uil-times-circle"></i>
  </button>

  @if (user) {
    <div class="container">
      <div class="delete-circle">
        <i class="uil uil-trash-alt"></i>
      </div>
      <h1 class="delete-title">Suppression du compte</h1>
      <div class="delete-container">
        <p class="delete-text">
          Êtes-vous sûr.e de vouloir supprimer votre compte ?
        </p>
        <div class="delete-data-container">
          <p class="delete-data-text">
            En supprimant votre compte vous allez perdre toutes vos
            données enregistrées dans l’application.
          </p>
          <p class="delete-data-text-italic">
            Celles-ci seront conservées pendant 30 jours sur nos
            serveurs et supprimées définitivement apres ce délai.
          </p>
          <p class="delete-data-text">
            Un mail va vous être envoyé pour confirmer la suppression du
            compte.
          </p>
          <div class="delete-list-item">
            <i class="uil uil-home"></i>
            <span>{{ user.propertiesUID?.length ?? 0 }} bien(s)</span>
          </div>
          <div class="delete-list-item">
            <i class="uil uil-building"></i>
            <span>{{ buildingList.length ?? 0 }} immeuble(s)</span>
          </div>
          <div class="delete-list-item">
            <i class="uil uil-suitcase"></i>
            <span>{{ user.societiesUID?.length ?? 0 }} société(s)</span>
          </div>
          <div class="delete-list-item">
            <i class="uil uil-file-copy-alt"></i>
            <span
              >{{ user.documentsUID?.length ?? 0 }} document(s)</span
              >
            </div>
            <div class="delete-list-item">
              <i class="uil uil-share-alt"></i>
              <span
                >{{
                sharedByUserProperties.length ?? 0
                }}
                partage(s)</span
                >
              </div>
              <p class="delete-data-text">
                Une fois votre compte supprimé, vos biens partagés ne seront
                plus visibles par les tiers. Un email d’information leur
                sera envoyé.
              </p>
            </div>
            <hr class="omedom-divider omedom-no-margin" />
            <omedom-radio
              [options]="yesNoOptions"
              name="unsubNewsletter"
              label="Souhaitez-vous aussi vous désinscrire de la newsletter Omedom ?"
              [(ngModel)]="unsubNewsletter"
            ></omedom-radio>
            <div class="actions-container">
              <button
                class="omedom-button-secondary flex-1"
                [disabled]="pending$.asObservable() | async"
                (click)="dismiss()"
                >
                <span>Annuler</span>
              </button>
              <button
                class="omedom-button-warn flex-1"
                [disabled]="pending$.asObservable() | async"
                (click)="delete()"
                >
                @if ((pending$.asObservable() | async) === false) {
                  <i class="uil uil-trash-alt"></i>
                  <span>Oui, supprimer</span>
                }
                @if (pending$.asObservable() | async) {
                  <ion-spinner
                    class="delete-spinner"
                  ></ion-spinner>
                }
              </button>
            </div>
          </div>
        </div>
      }
    </div>

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LoanDueDateForm } from '@omedom/data';

import { listAnimation } from '../../../../animations';

@Component({
    selector: 'omedom-loan-due-date-step',
    templateUrl: './loan-due-date-step.container.html',
    styleUrls: ['./loan-due-date-step.container.scss'],
    animations: [
        listAnimation
    ]
})
export class LoanDueDateStepContainer {
    /**
     * @description Form group input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {FormGroup}
     * @memberof LoanDueDateStepContainer
     */
    @Input({ required: true })
    public form!: FormGroup<LoanDueDateForm>;

    /**
     * @description Number of keys in the form controls object for animation
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @readonly
     * @type {number}
     * @memberof LoanDueDateStepContainer
     */
    public get keys(): number {
        return Object.keys(this.form.controls).length;
    }
}

export const questions = [
    {
        icon: 'user',
        question: ' Qu’est-ce que j’ai payé hier ? et demain ?',
        answer: 'Suivez vos finances en temps réel en renseignant vos dépenses et revenus dans l’outil OMEDOM.',
    },
    {
        icon: 'user',
        question: 'Où se trouvent les papiers ?',
        answer: 'Importez ou photographiez tous vos documents. Avec OMEDOM ils sont rangés et à portée de main.',
    },
    {
        icon: 'user',
        question: 'Comment suivre à plusieurs mon patrimoine ?',
        answer: 'Partagez vos infos en un clic. OMEDOM vous facilite le transfert d’informations.',
    },
    {
        icon: 'user',
        question: 'Combien vaut mon bien ?',
        answer: 'Obtenez la valeur en temps réel de votre bien grâce à l’outil OMEDOM.',
    },
    {
        icon: 'user',
        question: 'Ai-j’ai assez d’argent pour les prochains mois ?',
        answer: 'Anticipez vos dépenses grâce au prévisionnel OMEDOM.',
    },
    {
        icon: 'user',
        question: "Combien me coûte l'amélioration de mon DPE?",
        answer: 'Estimez votre budget travaux pour relever la note DPE et connaître la nouvelle estimation de votre bien avec OMEDOM.',
    },
];

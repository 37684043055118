import { Component, Input } from '@angular/core';
import { ProEntity } from '@omedom/data';

@Component({
    selector: 'omedom-pro-info',
    templateUrl: './pro-info.component.html',
    styleUrls: ['./pro-info.component.scss'],
})
export class ProInfoComponent {
    @Input()
    public pro?: ProEntity;

    @Input()
    public keyToDisplay?: Array<keyof ProEntity>;

    private getKeys(): string[] {
        if (this.keyToDisplay) {
            return this.keyToDisplay;
        }
        return Object.keys(this.pro ?? {});
    }

    public displayKey(key: keyof ProEntity): boolean {
        return this.getKeys().includes(key);
    }
}

<h2 class="form-title">Informations du membre</h2>

<button class="form-close-button" (click)="close()">
    <i class="uil uil-times-circle"></i>
</button>

@if (memberForm) {
<form class="form" [formGroup]="memberForm">
    <div class="form-item">
        <span>Prénom<sup>*</sup></span>
        <ion-item>
            <ion-label>
                <i class="uil uil-user-circle"></i>
            </ion-label>
            <ion-input
                type="text"
                formControlName="firstname"
                placeholder="Prénom"
            ></ion-input>
        </ion-item>
    </div>
    <div class="form-item">
        <span>Nom<sup>*</sup></span>
        <ion-item>
            <ion-label>
                <i class="uil uil-user-circle"></i>
            </ion-label>
            <ion-input
                type="text"
                formControlName="name"
                placeholder="Nom"
            ></ion-input>
        </ion-item>
    </div>
    @if ('accessManageSociety' | isAuthorised | async) {
    <div class="form-item">
        <span>Téléphone</span>
        <ion-item>
            <ion-label>
                <i class="uil uil-phone"></i>
            </ion-label>
            <ion-input
                type="tel"
                formControlName="tel"
                placeholder="Téléphone"
            ></ion-input>
        </ion-item>
    </div>
    }
    <div class="form-item-radio">
        <omedom-radio
            label="Statut du membre"
            name="status"
            formControlName="status"
            [options]="statusOptions"
            [required]="true"
        ></omedom-radio>
    </div>
    <hr class="omedom-divider omedom-no-margin" />
    <span
        class="form-member-title"
        [style.color]="
            ('accessManageSociety' | isAuthorised | async)
                ? '#04151C'
                : '#A8ADAF'
        "
    >
        <i class="uil uil-share-alt"></i>
        Informer et partager la société
    </span>
    @if (!('accessManageSociety' | isAuthorised | async)) {
    <omedom-action
        text="Fonctionnalité Premium, changez d'abonnement."
        actionIcon="omedom-icon-crown"
        [simpleIcon]="true"
    >
    </omedom-action>
    } @if ('accessManageSociety' | isAuthorised | async) {
    <div class="form-item">
        <span>Email<sup>*</sup></span>
        <ion-item>
            <ion-label>
                <i class="uil uil-envelope"></i>
            </ion-label>
            <ion-input
                type="email"
                formControlName="email"
                placeholder="Email"
            ></ion-input>
        </ion-item>
    </div>
    }
    <omedom-input
        name="tel"
        type="tel"
        label="Téléphone"
        placeholder="Téléphone"
        [pattern]="phoneRegex"
        formControlName="tel"
    ></omedom-input>
    <!-- // OM-816 Retirer temporairement les droits sur les membres de société -->
    <!-- <app-select
    formControlName="role"
    label="Rôle du membre"
    [required]="true"
    [placeholder]="rolePlaceholder"
    [options$]="roleOptions$"
  ></app-select> -->
</form>
}

<div class="omedom-sticky-button-container w-100 margin-top-2 margin-bottom-1">
    <button
        class="omedom-button-secondary flex-1 margin-right-1"
        (click)="close()"
    >
        <span>Annuler</span>
    </button>

    <button
        class="omedom-button-green flex-1"
        (click)="submit()"
        [disabled]="memberForm?.invalid || (pending$.asObservable() | async)"
    >
        <i class="uil uil-save"></i>
        <span>Enregistrer</span>
        @if (pending$.asObservable() | async) {
        <ion-spinner color="primary" class="form-upload-spinner"></ion-spinner>
        }
    </button>
</div>

@if (label) {
<div
    class="omedom-form-label"
    [ngStyle]="{ 'font-weight': boldLabel ? '700' : '500' }"
>
    {{ label }}
    @if (required) {
    <sup>*</sup>
    } @if (warning) {
    <omedom-warning-popover
        [content]="warning"
        class="margin-left-2"
    ></omedom-warning-popover>
    }
</div>
}

<ion-item
    class="omedom-form-item"
    [class.autocomplete-focused]="
        (isFocused | async) &&
        ((filteredAutocomplete$ | async)?.length ?? 0 > 0)
    "
    [class.autocomplete-selected]="selectedAutocomplete"
>
    @if (icon) {
    <ion-label>
        <i [class]="icon"></i>
    </ion-label>
    }

    <ion-input
        [placeholder]="placeholder"
        [name]="name"
        [type]="type"
        [ngModel]="displayedValue"
        (ngModelChange)="filterAutocomplete($event)"
        (ionFocus)="isFocused.next(true)"
        (ionBlur)="detectBlur()"
        [disabled]="isDisabled"
        [debounce]="debounce"
        [autocomplete]="autocomplete"
        [required]="required"
    ></ion-input>

    @if (iconEnd) {
    <a (click)="iconEndClick.emit()" slot="end">
        <i [class]="iconEnd"></i>
    </a>
    } @if (clearable && value) {
    <a (click)="clear()" slot="end">
        <i class="uil uil-times-circle"></i>
    </a>
    }
</ion-item>

<!-- <div class="omedom-form-autocomplete-selected"
*ngIf="selectedAutocomplete">
{{ selectedAutocomplete }}
</div> -->

@if (isFocused | async) {
<div class="omedom-form-autocomplete">
    @for (item of filteredAutocomplete$ | async; track item.label) {
    <div class="omedom-autocomplete-item" (click)="selectItem(item)">
        {{ item.label }}
    </div>
    }
</div>
} @if (error) {
<span class="omedom-form-error">{{ error }}</span>
}

/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SavingExitConstraintsFormType } from '@omedom/data';

import { listAnimation } from '../../../../animations';

@Component({
    selector: 'omedom-saving-exit-constraints-step',
    templateUrl: './saving-exit-constraints-step.container.html',
    styleUrls: ['./saving-exit-constraints-step.container.scss'],
    animations: [
        listAnimation
    ],
})
export class SavingExitConstraintsStepContainer {
    /**
     * @description Form group input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {FormGroup<SavingInfosFormType>}
     * @memberof SavingExitConstraintsStepContainer
     */
    @Input({ required: true })
    public form!: FormGroup<SavingExitConstraintsFormType>;

    constructor(
    ) { }

    /**
     * @description Number of keys in the form controls object for animation
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @readonly
     * @type {number}
     * @memberof SavingExitConstraintsStepContainer
     */
    public get keys(): number {
        return Object.keys(this.form.controls).length;
    }
}

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { OmedomConfetti } from '@omedom/utils';

import { LevelAssignmentsComponent } from '../level-assignments/level-assignments.component';
import { AnalyticsService } from '@omedom/services';

@Component({
    selector: 'omedom-level-home',
    templateUrl: './level-home.component.html',
    styleUrls: ['./level-home.component.scss'],
    animations: [
        trigger('up', [
            transition(':enter', [
                style({ transform: 'translateY(100%)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateY(0)' })),
            ]),
            transition(':leave', [
                style({ transform: 'translateY(0)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateY(100%)' })),
            ]),
        ]),
    ],
})
export class LevelHomeComponent implements OnInit {
    /**
     * @description Storage of the app
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @memberof LevelHomeComponent
     */
    public storage = new Storage();

    constructor(
        private modalController: ModalController,
        private analyticsService: AnalyticsService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.storage.create();
        const showLevelMapOneTime: boolean = await this.storage.get('showLevelMapOneTime');

        if (!showLevelMapOneTime) {
            OmedomConfetti.addConfetti();
            this.storage.set('showLevelMapOneTime', true);
        }
    }

    ionViewDidEnter(): void {
        this.analyticsService.setCurrentScreen('Level modal');
    }

    /**
     * @description Close the modal
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof LevelHomeComponent
     */
    public async close(): Promise<void> {
        await this.modalController.dismiss();
    }

    /**
     * @description Open the modal of assignments of levels
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof LevelHomeComponent
     */
    public async openAssignments(): Promise<void> {
        const modal = await this.modalController.create({
            component: LevelAssignmentsComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
        });
        await modal.present();
    }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TenantEntity } from '@omedom/data';

import { RestService } from './rest.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TenantService extends RestService<TenantEntity> {
    constructor(protected override firestore: AngularFirestore) {
        super(firestore, 'tenants');
    }

    /**
     * @description Get the tenants of a lease
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} leaseUID The lease UID
     * @returns {Promise<TenantEntity[]>} The tenants of the lease
     * @memberof TenantService
     */
    public async getTenantsByLease(leaseUID: string): Promise<TenantEntity[]> {
        return await this.search([{ where: 'leaseUID', operator: '==', value: leaseUID }]);
    }

    /**
     * @description Get the tenants of a lease in realtime
     * @author Brisset Killian
     * @date 09/04/2024
     * @param {string} leaseUID
     * @returns {*}  {Observable<TenantEntity[]>}
     * @memberof TenantService
     */
    public _getTenantsByLease(leaseUID: string): Observable<TenantEntity[]> {
        return this._search([{ where: 'leaseUID', operator: '==', value: leaseUID }]);
    }
}

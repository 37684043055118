import { BankTransactionEntity } from '../../entities';

/**
 * @description Interface to group transactions by day
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 29/04/2024
 * @export
 * @interface BankTransactionByDay
 */
export interface BankTransactionByDay {
    /**
     * @description Day of the transactions
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/04/2024
     * @type {Date}
     * @memberof BankTransactionByDay
     */
    day: Date;

    /**
     * @description List of transactions for the day of the transactions
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/04/2024
     * @type {BankTransactionEntity[]}
     * @memberof BankTransactionByDay
     */
    transactions: BankTransactionEntity[];
}

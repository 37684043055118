import { FormControl } from '@angular/forms';

import { SavingType } from '../enums';

/**
 * @description Saving infos form type
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 09/07/2024
 * @type {SavingInfosFormType}
 */
export type SavingInfosFormType = {
    /**
     * @description Name of the saving (mandatory)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {FormControl<string>}
     */
    name: FormControl<string>,

    /**
     * @description Balance of the saving
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<number>)}
     */
    balance: FormControl<number>,

    /**
     * @description ID of the bank linked to the saving in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<number | undefined>)}
     */
    bankID: FormControl<number | undefined>,

    /**
     * @description Etablissement name linked to the saving account (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 15/07/2024
     * @type {(FormControl<string | undefined>)}
     */
    etablissement: FormControl<string | undefined>,

    /**
     * @description Limit of the saving
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<number | undefined>)}
     */
    limit: FormControl<number | undefined>,

    /**
     * @description Interest rate of the saving (in %)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<number | undefined>)}
     */
    interestRate: FormControl<number | undefined>,

    /**
     * @description Interest amount of the saving account (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @type {(FormControl<number | undefined>)}
     */
    interestAmount: FormControl<number | undefined>,

    /**
     * @description Type of saving
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<SavingType | undefined>)}
     */
    type: FormControl<SavingType | undefined>,

    /**
     * @description Opening date of the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @type {(FormControl<Date | undefined>)}
     */
    openingDate: FormControl<Date | undefined>,
};

import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LevelEventEntity } from '@omedom/data';
import { gsap } from 'gsap';
import * as uuid from 'uuid';

@Component({
    selector: 'omedom-level-success-popup',
    templateUrl: './level-success-popup.component.html',
    styleUrls: ['./level-success-popup.component.scss'],
})
export class LevelSuccessPopupComponent implements OnInit {
    /**
     * @description Event to display in the popup
     * @author Jérémie Lopez
     * @type {LevelEventEntity}
     * @memberof LevelSuccessPopupComponent
     */
    @Input({
        required: true,
    })
    public event!: LevelEventEntity;

    /**
     * @description Is the lock closed or opened ? (true = closed, false = opened)
     * @author Jérémie Lopez
     * @memberof LevelSuccessPopupComponent
     */
    public isClosed = true;

    /**
     * @description Event emitted when the popup is closed (animation finished) and the user can continue the flow
     * @author Jérémie Lopez
     * @memberof LevelSuccessPopupComponent
     */
    @Output() public finished = new EventEmitter();

    constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.id = uuid
            .v4()
            .toString()
            .replace(/-/g, '')
            .split('')
            .filter((char) => /[a-zA-Z]/.test(char))
            .join('')
            .substring(0, 10);
    }

    ngOnInit(): void {
        this.animate();
    }

    /**
     * @description Animate the popup when it is displayed (fade in and slide up) and when it is closed (slide down and fade out)
     * @author Jérémie Lopez
     * @private
     * @memberof LevelSuccessPopupComponent
     */
    private animate(): void {
        const tl = gsap.timeline();
        tl.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { opacity: 0, y: 100 },
            { opacity: 1, y: 0, duration: 0.5 }
        );
        tl.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { maxWidth: '70px' },
            { maxWidth: '400px', duration: 0.5 }
        );
        tl.fromTo(
            `#${this.elementRef.nativeElement.id} .level-text-container`,
            { display: 'none' },
            { display: 'flex', duration: 0.1 }
        );
        tl.fromTo(
            `#${this.elementRef.nativeElement.id} .level-text-container .level-success-title`,
            { opacity: 0, y: 32.5 },
            { opacity: 1, y: 12.5, duration: 0.5 }
        );
        tl.fromTo(
            `#${this.elementRef.nativeElement.id} .level-icon-container`,
            { backgroundColor: '#eeeeee' },
            { backgroundColor: '#ffbe00', duration: 0.5 }
        );
        tl.fromTo(
            `#${this.elementRef.nativeElement.id} .level-text-container .level-success-title`,
            { y: 12.5 },
            { y: 0, duration: 0.15 }
        );
        tl.fromTo(
            `#${this.elementRef.nativeElement.id} .level-text-container .level-success-event`,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: 0.5 }
        );

        const tl2 = gsap.timeline();
        tl2.delay(1.6);
        tl2.fromTo(
            `#${this.elementRef.nativeElement.id} .level-icon-container #closed`,
            { y: 0 },
            { y: -50, duration: 0.3 }
        );
        tl2.fromTo(
            `#${this.elementRef.nativeElement.id} .level-icon-container #opened`,
            { y: 50 },
            { y: 0, duration: 0.3 }
        );

        const tl3 = gsap.timeline();
        tl3.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { scale: 0.9 },
            { scale: 1.1, duration: 0.15 }
        );
        tl3.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { scale: 1.1 },
            { scale: 1, duration: 0.15 }
        );
        tl3.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { scale: 1 },
            { scale: 1.1, duration: 0.15 }
        );
        tl3.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { scale: 1.1 },
            { scale: 0.9, duration: 0.15 }
        );
        tl3.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { scale: 0.9 },
            { scale: 1, duration: 0.15 }
        );

        const t4 = gsap.timeline();
        t4.delay(6);
        t4.fromTo(
            `#${this.elementRef.nativeElement.id}`,
            { opacity: 1, y: 0 },
            { opacity: 0, y: -100, duration: 1 }
        );
        t4.eventCallback('onComplete', () => {
            this.finished.emit();
        });
    }
}

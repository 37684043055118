<div class="omedom-modal">
  <div class="omedom-modal-header">
    <div class="omedom-modal-header-title">
      <i
        class="uil uil-envelope-check color-light-black background-color-light-orange"
      ></i>
    </div>
    <i class="uil uil-times-circle" (click)="close()"></i>
  </div>
  <omedom-info
    class="margin-top-2"
        information="Des informations sont manquantes pour la création de cette {{
            story.isPayed ? 'quittance' : 'relance'
        }} à votre locataire.<br />
        <br />
        Veuillez compléter les informations ci-dessous.<br />
        Elles ne vous seront plus demandées les prochaines fois."
  ></omedom-info>

  <div class="margin-bottom-2">
    <form #form="ngForm">
      @if (missingData?.lessor || missingData?.societyManager) {
        <div>
          <hr class="omedom-divider w-100" />
          <h2 class="margin-y-2">
            <i
              class="uil uil-icon"
                        [ngClass]="{
                            'uil-user-circle': !society,
                            'uil-suitcase': society
                        }"
            ></i>
            {{
            society
            ? 'Informations de la société ' + society.name
            : 'Informations personnelles'
            }}
            <!-- Adresse du bailleur -->
          </h2>
        </div>
      }
      @if (missingData?.lessor) {
        <div class="userInformations">
          <div>
            <!-- Lessor address-->
            <div class="account-split-field">
              <omedom-input
                class="margin-bottom-1"
                style="width: 140px"
                name="lessor-streetNumber"
                type="number"
                [(ngModel)]="lessor.postalAddress.streetNumber"
                placeholder="N°"
                autocomplete="off"
                [required]="true"
                [debounce]="300"
                label="Adresse"
              ></omedom-input>
              <omedom-input
                class="margin-bottom-1"
                name="lessor-street"
                type="text"
                icon="uil-map-marker"
                [(ngModel)]="lessor.postalAddress.street"
                placeholder="Rue"
                autocomplete="off"
                [required]="true"
                [debounce]="300"
                label=" "
              ></omedom-input>
            </div>
            <div class="account-split-field">
              <omedom-input
                name="lessor-postal-code"
                type="text"
                [(ngModel)]="lessor.postalAddress.postalCode"
                placeholder="Code postal"
                autocomplete="off"
                [required]="true"
                [debounce]="300"
                [pattern]="postalCodeRegex"
              ></omedom-input>
              <omedom-input
                class="margin-bottom-1"
                name="lessor-city"
                type="text"
                [(ngModel)]="lessor.postalAddress.city"
                autocomplete="off"
                placeholder="Ville"
                [required]="true"
                [debounce]="300"
              ></omedom-input>
            </div>
          </div>
        </div>
      }

      <!-- Society manager's informations -->
      @if (society && missingData?.societyManager) {
        <div>
          <omedom-input
            class="margin-bottom-1"
            [required]="true"
            [(ngModel)]="societyManager.name"
            name="lastName"
            autocomplete="off"
            placeholder="Nom du gérant"
            icon="uil uil-user-circle"
            label="Nom du gérant"
            >
          </omedom-input>
          <omedom-input
            class="margin-bottom-1"
            [required]="true"
            [(ngModel)]="societyManager.firstname"
            name="firstName"
            autocomplete="off"
            placeholder="Prénom du gérant"
            icon="uil uil-user-circle"
            label="Prénom du gérant"
            >
          </omedom-input>
          <omedom-input
            class="margin-bottom-1"
            [required]="true"
            type="email"
            [(ngModel)]="societyManager.email"
            name="email"
            autocomplete="off"
            [pattern]="emailRegex"
            placeholder="E-mail du gérant"
            icon="uil uil-envelope"
            label="E-mail du gérant"
            >
          </omedom-input>
          <omedom-input
            type="tel"
            [(ngModel)]="societyManager.tel"
            name="tel"
            autocomplete="off"
            [pattern]="phoneRegex"
            placeholder="Téléphone du gérant"
            label="Téléphone du gérant"
            >
          </omedom-input>
        </div>
      }

      @if (missingData?.property) {
        <div class="rentInformations">
          <hr class="omedom-divider w-100" />
          <div>
            <h2 class="margin-y-2">
              <i class="uil uil-house-user uil-icon"></i>
              Informations du bien en location
            </h2>
            @if (property.address) {
              <div>
                <omedom-input
                  class="margin-bottom-1"
                  style="width: 140px"
                  name="lessor-streetNumber"
                  type="number"
                  [(ngModel)]="property.address.streetNumber"
                  placeholder="N°"
                  autocomplete="off"
                  [required]="true"
                  [debounce]="300"
                  label="Adresse"
                ></omedom-input>
                <omedom-input
                  class="margin-bottom-1"
                  name="property-street"
                  type="text"
                  icon="uil-map-marker"
                  [(ngModel)]="property.address.street"
                  placeholder="Rue"
                  autocomplete="off"
                  [required]="true"
                  [debounce]="300"
                  label=""
                ></omedom-input>
                <div class="account-split-field">
                  <omedom-input
                    name="property-postalCode"
                    type="text"
                    [(ngModel)]="property.address.postalCode"
                    placeholder="Code postal"
                    autocomplete="off"
                    [required]="true"
                    [debounce]="300"
                    [pattern]="postalCodeRegex"
                  ></omedom-input>
                  <omedom-input
                    name="property-city"
                    type="text"
                    [(ngModel)]="property.address.city"
                    autocomplete="off"
                    placeholder="Ville"
                    [required]="true"
                    [debounce]="300"
                  ></omedom-input>
                </div>
              </div>
            }
          </div>
        </div>
      }

      @if (missingData?.tenant) {
        <div class="userInformations">
          <hr class="omedom-divider w-100" />
          <h2 class="margin-y-2">
            <i class="uil uil-user-circle uil-icon"></i>
            Informations du locataire
          </h2>
          <div>
            <omedom-input
              class="margin-bottom-1"
              [required]="true"
              [(ngModel)]="currentTenant.lastname"
              name="lastName"
              autocomplete="off"
              placeholder="Nom"
              icon="uil uil-user-circle"
              label="Nom"
              >
            </omedom-input>
            <omedom-input
              class="margin-bottom-1"
              [required]="true"
              [(ngModel)]="currentTenant.firstname"
              name="firstName"
              autocomplete="off"
              placeholder="Prénom"
              icon="uil uil-user-circle"
              label="Prénom"
              >
            </omedom-input>
            <omedom-input
              type="email"
              [(ngModel)]="currentTenant.email"
              name="email"
              [pattern]="emailRegex"
              autocomplete="off"
              placeholder="E-mail"
              icon="uil uil-envelope"
              label="E-mail"
              >
            </omedom-input>
          </div>
        </div>
      }
    </form>
  </div>
  <div class="omedom-sticky-button-container w-100">
    <button class="omedom-button-secondary" (click)="close()">
      Annuler
    </button>
    <button
      class="omedom-button-primary flex-1 margin-left-2"
      (click)="continue()"
      [disabled]="form.invalid"
      >
      <i class="uil uil-eye"></i>
      Visualiser la
      {{ story.isPayed ? 'quittance' : 'relance' }}
    </button>
  </div>
</div>

<form [formGroup]="form"
  [@list]="keys">
  <omedom-input [boldLabel]="true"
    formControlName="name"
    label="Nom"
    placeholder="Crédit immobilier"
    [required]="true"
  type="text"></omedom-input>

  @if (banksOptions$ | async; as options) {
    <omedom-select [boldLabel]="true"
      formControlName="bankID"
      label="Banque"
      [placeholder]="placeholder"
    [options]="options"></omedom-select>
  }

  <hr class="omedom-divider omedom-no-margin">

  <omedom-input [boldLabel]="true"
    formControlName="borrowedCapital"
    label="Montant emprunté"
    (onChange)="calculateRemainingCapital(); calculateRepaidCapital()"
    placeholder="500 000"
    iconEnd="uil uil-euro"
  type="number"></omedom-input>

  <omedom-input [boldLabel]="true"
    formControlName="repaidCapital"
    label="Capital déjà remboursé"
    (onChange)="calculateRemainingCapital()"
    placeholder="25 000"
    iconEnd="uil uil-euro"
  type="number"></omedom-input>

  <omedom-input [boldLabel]="true"
    formControlName="remainingCapital"
    label="Capital restant dû"
    (onChange)="calculateRepaidCapital()"
    placeholder="475 000"
    iconEnd="uil uil-euro"
  type="number"></omedom-input>

  <omedom-input [boldLabel]="true"
    formControlName="interestRate"
    label="Taux d'intérêt"
    placeholder="1.5"
    iconEnd="uil uil-percentage"
  type="number"></omedom-input>

  <hr class="omedom-divider omedom-no-margin">

  <omedom-input [boldLabel]="true"
    formControlName="assurance"
    label="Assurance"
    placeholder="Allianz"
  type="text"></omedom-input>

  <omedom-input [boldLabel]="true"
    formControlName="guarantee"
    label="Garantie"
    placeholder="Hypothèque"
  type="text"></omedom-input>
</form>

import { Component, OnInit, ViewChild } from '@angular/core';
import { PropertyEntity, PropertyType } from '@omedom/data';
import { Router } from '@angular/router';
import { IonModal, ModalController } from '@ionic/angular';

@Component({
    selector: 'omedom-registration-process',
    templateUrl: './user-registration-process.component.html',
    styleUrls: ['./user-registration-process.component.scss'],
})
export class UserRegistrationProcessPage implements OnInit {
    @ViewChild('modalEndInscription') modalEndInscription?: IonModal;

    property: PropertyEntity = new PropertyEntity({
        address: {
            streetNumber: null,
            street: '',
            postalCode: '',
            city: '',
            addressLine2: '',
        },
    });
    currentStep: number = 1;
    nextEnabled: boolean = false;

    constructor(private router: Router, private modalController: ModalController) {}

    ngOnInit() {
        const propertyFromStorage = window.localStorage.getItem('userRegistrationProperty');
        if (propertyFromStorage) {
            Object.assign(this.property, JSON.parse(propertyFromStorage));
            if (this.property.type) {
                this.setNextEnabled(true);
            }
        }
    }

    saveToLocalStorage() {
        window.localStorage.setItem('userRegistrationProperty', JSON.stringify(this.property));
    }

    setNextEnabled(enabled: boolean) {
        this.nextEnabled = enabled;
    }

    previousClick() {
        this.currentStep = this.currentStep - 1;
    }

    nextClick() {
        this.nextEnabled = false;
        this.saveToLocalStorage();
        if (this.currentStep === 3) {
            this.openFinalModal();
            return;
        }
        this.currentStep = this.currentStep + 1;
    }

    selectPropertyType($event: PropertyType) {
        this.property.type = $event;
    }

    private async openFinalModal() {
        if (!this.modalEndInscription) {
            this.navigateToInscription();
            return;
        }
        await this.modalEndInscription.present();

        this.modalEndInscription.onDidDismiss().then((e) => {
            this.navigateToInscription();
        });
    }

    private navigateToInscription() {
        this.router.navigate(['/user/auth-action', { state: 'register' }]);
    }
}

@if (notification) {
  <div class="notification-card">
    <div class="notification-header">
      <span class="notification-title">{{ notification.title }}</span>
      <span class="notification-date">{{ getFormattedDate() }}</span>
    </div>
    <div class="notification-body">
      @if (notification.image) {
        <img
          [src]="notification.image"
          alt="notification"
          class="notification-image"
          />
      }
      <span class="notification-message">{{ notification.body }}</span>
      <i
        class="uil uil-ellipsis-v"
        style="color: #04151c"
        (click)="modal.present()"
      ></i>
    </div>
    <!-- <div class="notification-footer" *ngIf="enableActions && action">
    <button
      class="omedom-button-primary flex-1"
      style="max-width: max-content; height: 40px"
      (click)="action?.action()"
      >
      <span>{{ actionTitle }}</span>
      <i *ngIf="action?.icon" [class]="'uil ' + action?.icon"></i>
    </button>
  </div> -->
</div>
}
<ion-modal #modal [breakpoints]="[0, 1]" initialBreakpoint="1">
  <!-- Supprimer cette notification et Paramètres -->
  <ng-template>
    <div class="omedom-modal">
      <div class="omedom-modal-header">
        <i class="uil uil-times-circle" (click)="modal.dismiss()"></i>
      </div>
      <div
        (click)="deleteNotification(); modal.dismiss()"
        class="modal-item"
        >
        <!-- Delete this notification -->
        <i class="uil uil-trash"></i>
        <span>Supprimer cette notification</span>
      </div>
      <div
        (click)="openSetting.emit(); modal.dismiss()"
        class="modal-item"
        >
        <i class="uil uil-bell"></i>
        <span>Paramètres</span>
      </div>
    </div>
  </ng-template>
</ion-modal>

import { Component, Input, OnChanges } from '@angular/core';
import { ModalTab } from '@omedom/data';

@Component({
    selector: 'omedom-modal-tab',
    templateUrl: './modal-tab.component.html',
    styleUrls: ['./modal-tab.component.scss'],
})
export class ModalTabComponent implements OnChanges {
    /**
     * @description Tabs to display in the modal header (required) (default: [])
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @type {ModalTab[]}
     * @memberof ModalTabComponent
     */
    @Input()
    public tabs: ModalTab[] = [];

    ngOnChanges(): void {
        // Sort tabs by index
        this.tabs.sort((a, b) => a.index - b.index);
    }

    /**
     * @description Set the tab passed in parameter as active and set the other tabs as inactive
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @param {ModalTab} tab
     * @memberof ModalTabComponent
     */
    public setActiveTab(tab: ModalTab): void {
        const activeTab = this.tabs.find((t) => t.active);

        if (activeTab && activeTab.index !== tab.index) {
            activeTab.active = false;
        }

        tab.active = true;
    }
}

import { AutomationActionType } from '../../enums';
import { AutomationActionData } from './';

/**
 * @description Data of an automation action (send notification, send email) to display in a form or in a chart
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 28/11/2023
 * @export
 * @interface AutomationAction
 * @template T
 */
export interface AutomationAction<T extends AutomationActionType = AutomationActionType> {
    type: T;
    data: AutomationActionData[T];
}

export function getAutomationActionData<T>(action: AutomationAction | undefined): T | undefined {
    return action?.data as T;
}

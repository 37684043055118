import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { UserEntity } from '@omedom/data';
import { OmedomStorage } from '@omedom/utils';

@Component({
    selector: 'omedom-storage-progress',
    templateUrl: './storage-progress.component.html',
    styleUrls: ['./storage-progress.component.scss'],
})
export class StorageProgressComponent {
    /**
     * @description Observable of the user connected
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/09/2023
     * @type {Observable<UserEntity>}
     * @memberof StorageProgressComponent
     */
    @Input()
    public user$?: Observable<UserEntity>;

    /**
     * @description Staging file size
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/09/2023
     * @type {number} Bytes
     * @memberof StorageProgressComponent
     */
    @Input()
    public staging: number = 0;

    /**
     * @description Get the percentage of storage used by the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/09/2023
     * @param {UserEntity} user User to get the percentage of storage used
     * @returns {number} Percentage of storage used
     * @memberof StorageProgressComponent
     */
    public getPercentage(user: UserEntity): number {
        return OmedomStorage.getPercentage(user);
    }

    /**
     * @description Get the percentage of staging used by the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/09/2023
     * @param {UserEntity} user User to get the percentage of staging used
     * @returns {number} Percentage of staging used
     * @memberof StorageProgressComponent
     */
    public getStaging(user: UserEntity): number {
        return OmedomStorage.getStaging(user, this.staging);
    }

    /**
     * @description Get the total usage of the user (staging + storage used) in percentage
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/09/2023
     * @param {UserEntity} user
     * @returns {*}  {number}
     * @memberof StorageProgressComponent
     */
    public getTotalUsage(user: UserEntity): number {
        return OmedomStorage.getTotalUsage(user, this.staging);
    }
}

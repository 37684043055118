import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { SelectOption } from '@omedom/data';

@Component({
    selector: 'omedom-detailed-option',
    templateUrl: './detailed-option.component.html',
    styleUrls: ['./detailed-option.component.scss'],
})
export class DetailedOptionComponent {
    /**
     * @description Action qui se déclenche quand on clique sur un élément
     * @author Martin Bastié
     */
    @Output() optionClicked = new EventEmitter();

    /**
     * @description Action qui se déclenche quand on veut remettre à zéro la sélection
     * @author Martin Bastié
     */
    @Output() clearSelectionClicked = new EventEmitter();

    /**
     * @description L'option que l'on veut afficher
     * @author Martin Bastié
     */
    @Input({ required: true }) option!: SelectOption;

    /**
     * @description True si la liste déroulante est ouverte
     * @author Martin Bastié
     */
    @Input()
    @HostBinding('class.is-open')
    isOpen: boolean = false;

    @Input() isDisabled: boolean = false;

    /**
     * @description True si l'option est sélectionnée dans la liste déroulante
     * @author Martin Bastié
     */
    @HostBinding('class.is-selected')
    get isSelected(): boolean {
        return this.option.isSelected ?? false;
    }

    @HostListener('click', ['$event'])
    private onClick(event: PointerEvent): void {
        if (this.isOpen) {
            event.stopPropagation();
        }

        this.optionClicked.emit();
    }

    clearSelection(event: MouseEvent): void {
        event.stopPropagation();
        this.clearSelectionClicked.next(null);
    }
}

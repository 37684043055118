import { Component, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { LicenceEntity } from '@omedom/data';
import { LicenceService } from '@omedom/services';

@Component({
    selector: 'omedom-licence-resend',
    templateUrl: './licence-resend.component.html',
    styleUrls: ['./licence-resend.component.scss'],
})
export class LicenceResendComponent {
    @Input({ required: true }) licence!: LicenceEntity;

    pending = false;

    constructor(
        private licenceService: LicenceService,
        private toastController: ToastController,
        private modalController: ModalController
    ) {}

    async resend() {
        try {
            this.pending = true;
            await this.licenceService.resend(this.licence);

            const toast = await this.toastController.create({
                message: `La licence a bien été renvoyée à ${this.licence.userEmail}`,
                duration: 3000,
                color: 'success',
                position: 'top',
            });

            await toast.present();
            this.pending = false;
            this.modalController.dismiss();
        } catch (error) {
            console.error(error);

            // Display an error message
            const toast = await this.toastController.create({
                message: `Une erreur est survenue lors de l'envoi de la licence à ${this.licence.userEmail}`,
                duration: 3000,
                color: 'danger',
                position: 'top',
            });

            await toast.present();
            this.pending = false;
            this.modalController.dismiss();
        }
    }
}

import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'omedom-confidentiality',
    templateUrl: './confidentiality.component.html',
    styleUrls: ['./confidentiality.component.scss'],
})
export class ConfidentialityComponent {
    /**
     * @description If the component is in a modal or not
     * @author Brisset Killian
     * @date 16/05/2024
     * @type {boolean}
     * @memberof ConfidentialityComponent
     */
    protected isModal: boolean = false;

    constructor(protected modalController: ModalController) {}
}

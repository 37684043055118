@if (!hideHeader) {
  <div
    class="subscription-header"
    [style.background-color]="subscriptionColor"
    >
    <div style="min-width: 100px">
      @if (
        subscriptionName !== SubscriptionRank.ESSENTIAL &&
        subscriptionName !== SubscriptionRank.FAMILY
        ) {
        <i
          class="omedom-icon-crown margin-right-1"
        ></i>
      }
      @if (subscriptionName === SubscriptionRank.ESSENTIAL) {
        <i
          class="uil uil-star margin-right-1"
        ></i>
      }
      @if (subscriptionName === SubscriptionRank.FAMILY) {
        <i
          class="uil uil-users-alt margin-right-1"
        ></i>
      }
      <h3 style="display: inline-block" class="margin-left-1">
        {{ subscriptionName | capitalizeFirstLetter }}
      </h3>
    </div>
    @if (displayPrice) {
      <div class="subscription-price">
        <div>
          @if (subscriptionPrice && subscriptionPrice.ANNUALLY) {
            <span>
              A partir de
              {{ subscriptionPrice.ANNUALLY / 12 | payplugNumberToPrice }}€
              TTC / mois</span
              >
            }
            @if (!subscriptionPrice?.ANNUALLY) {
              <span
                >{{ subscriptionPrice?.MONTHLY | payplugNumberToPrice }}€ TTC /
                mois</span
                >
              }
            </div>
            <div class="subscription-detail">sans limite de biens</div>
          </div>
        }
        @if (subscriptionName === SubscriptionRank.ESSENTIAL) {
          <div
            class="subscription-price"
            >
            <div>
              <span> Gratuit</span>
            </div>
            <div class="subscription-detail">Limité à <b>un bien </b></div>
          </div>
        }
      </div>
    }
    <omedom-dropdown
      iconClass="uil uil-angle-down"
      [rotationAnimate]="true"
      [isButtonBackgroundTransparent]="true"
      >
      <p
        label
        class="subscription-info"
        style="margin-bottom: 0; display: flex; flex-direction: column"
        >
        <span>{{ subscriptionSubTitle }}</span>
        @if (subscriptionName === SubscriptionRank.FAMILY) {
          <span>
            <u>5 comptes "Premium" dont 4 à partager</u>
          </span>
        }
      </p>
      @if (
        subscriptionName === SubscriptionRank.FAMILY ||
        subscriptionName === SubscriptionRank.PREMIUM
        ) {
        <div
          content
          class="subscription-info"
          >
          <p>- Patrimoine immobilier : <b>Illimité</b></p>
          <p>- Partage entre tiers : <b>Illimité</b></p>
          <p>
            - Pilotage des Sociétés immobilières et partage entre membres
            (gérant et associés)
          </p>
          <p>- Patrimoine financier (Épargnes - Crédits)</p>
          <p>- Prévisionnel de trésorerie</p>
          <p>- Ventilation des mouvements</p>
          <p>- Estimation et valorisation des biens (- DPE projeté)</p>
          <p>- Suivi des locataires (état des loyers, quittance/relance)</p>
          <p>- Connexion bancaire : <b>Illimité</b></p>
          <p>- Centralisation des documents</p>
        </div>
      }
      @if (subscriptionName === SubscriptionRank.ESSENTIAL) {
        <div
          content
          class="subscription-info"
          >
          <p>Patrimoine immobilier : <b>1 bien max</b></p>
          <p>Partage entre tiers : <b>Illimité</b></p>
          <p>Prévisionnel de trésorerie : <b>1 mois</b></p>
          <p>Ventilation des charges / revenus</p>
        </div>
      }
    </omedom-dropdown>

    @if (displayButton) {
      <div>
        <hr class="omedom-divider" />
        @if (subscriptionName === SubscriptionRank.ESSENTIAL) {
          <omedom-info
        information="
        Pour passer en version gratuite OMEDOM, veuillez résilier votre
        abonnement en cours."
          ></omedom-info>
        }
        @if (
          subscriptionName !== SubscriptionRank.ESSENTIAL &&
          subscriptionName !== currentSubscription?.rank
          ) {
          <button
            class="omedom-button-primary w-100 margin-top-2"
            (click)="chooseEvent.emit(subscriptionName)"
            >
            Choisir cet abonnement
          </button>
        }
        @if (
          currentSubscription?.isSubscribed &&
          subscriptionName === currentSubscription?.rank
          ) {
          <div
            class="omedom-button-container"
            >
            <button
              class="omedom-button-warn-secondary"
              (click)="cancelEvent.emit(subscriptionName)"
              >
              Résilier
            </button>
            <button
              class="omedom-button-primary"
              (click)="chooseEvent.emit(subscriptionName)"
              >
              Modifier mon abonnement
            </button>
          </div>
        }
      </div>
    }

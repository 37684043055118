import { AutomationCondition } from '../interfaces/automation/automation-condition.interface';

/**
 * @description List of all the conditions available for the automation
 * @export
 * @type {AutomationCondition[]}
 */
export const conditions: AutomationCondition[] = [
    {
        description: `Si l'abonnement de l'utilisateur est expiré de plus de {numberOfDays} jours`,
        id: 'lastOrderDate',
        params: {
            numberOfDays: 0,
        },
    },
    {
        description: `Si l'utilsateur est en période d'essai`,
        id: 'isInTrialPeriod',
        params: {},
    },
    {
        description: `Si l'utilisateur a un abonnement actif`,
        id: 'hasActiveSubscription',
        params: {},
    },
    {
        description: `Si l'utilisateur possède {numberOfProperties} propriétés`,
        id: 'numberOfProperties',
        params: {
            numberOfProperties: 0,
        },
    },
    // {
    //        description: `Si l'utilisateur a {numberOfProperties} propriétés en location`,
    //    id: 'numberOfPropertiesInRent',
    //    params: {
    //        numberOfProperties: 0
    //    }
    //},
    {
        description: `Si la date du jour est le {date}`,
        id: 'specificDate',
        params: {
            date: new Date(),
        },
    },
    {
        description: `Si la date du jour est {numberOfDays} jour(s) avant la date d'anniversaire de l'utilisateur`,
        id: 'numberOfDaysBeforeBirthday',
        params: {
            numberOfDays: 0,
        },
    },
    {
        description: `Si l'utilisateur est vérifié`,
        id: 'isVerified',
        params: {},
    },
    {
        description: `Si l'utilisateur a créé son compte il y a {numberOfDays} jour(s)`,
        id: 'numberOfDaysSinceAccountCreation',
        params: {
            numberOfDays: 0,
        },
    },
    {
        description: `Si l'utilisateur a atteint le niveau {level}`,
        id: 'level',
        params: {
            level: 0,
        },
    },
    {
        description: `Si l'utilisateur a atteint {percentage}% de sa capacité de stockage`,
        id: 'storageCapacity',
        params: {
            percentage: 0,
        },
    },
];

import { Timestamp } from 'firebase/firestore';

import { RestEntity } from './rest.entity';



interface CategoryInfo<T> {
    label: string;
    icon: string;
    category: T;
    ids?: number[];
}

interface PeriodicityInfo<T> {
    label: string;
    periodicity: T;
}

export enum ChargeCategoryProperty {
    water = 'water',
    electricity = 'electricity',
    heater = 'heater',
    insurance = 'insurance',
    condominium = 'condominium',
    taxes = 'taxes',
    managementFees = 'managementFees',
    banking = 'banking',
    workAndMaintains = 'workAndMaintains',
    furniture = 'furniture',
    internet = 'internet',
    various = 'various',
}

export enum ChargeCategorySociety {
    landlordInsurance = 'landlordInsurance',
    houseInsurance = 'houseInsurance',
    rentGuaranteeInsurance = 'rentGuaranteeInsurance',
    loanInsurance = 'loanInsurance',
    legalInsurance = 'legalInsurance',
}

export enum ChargeCategoryBuilding {
    common = 'common',
}

export type AllChargeCategories =
    | ChargeCategoryProperty
    | ChargeCategorySociety
    | ChargeCategoryBuilding;

export type ChargeAssociateTo = {
    propertyUid: string;
    propertyLabel: string;
    percentage: number;
    amount: number;
};
export class ChargeCategoryInfo implements CategoryInfo<AllChargeCategories> {
    label!: string;
    icon!: string;
    category!: AllChargeCategories;

    constructor(chargeCategory: AllChargeCategories) {
        this.convertToInfo(chargeCategory);
    }

    private convertToInfo(chargeCategory: AllChargeCategories) {
        this.category = chargeCategory;

        switch (chargeCategory) {
            case ChargeCategoryProperty.banking:
                this.label = 'Crédits et frais bancaires';
                this.icon = 'uil uil-university';
                break;

            case ChargeCategoryProperty.condominium:
                this.label = 'Charges de copropriété';
                this.icon = 'uil uil-building';
                break;

            case ChargeCategoryProperty.electricity:
                this.label = 'Electricité';
                this.icon = 'uil uil-bolt';
                break;

            case ChargeCategoryProperty.heater:
                this.label = 'Energie de chauffage';
                this.icon = 'uil uil-temperature-half';
                break;

            case ChargeCategoryProperty.furniture:
                this.label = 'Mobilier';
                this.icon = 'uil uil-bed-double';
                break;

            case ChargeCategoryProperty.insurance:
                this.label = 'Assurances';
                this.icon = 'uil uil-home';
                break;

            case ChargeCategoryProperty.taxes:
                this.label = 'Impôts';
                this.icon = 'uil uil-calculator';
                break;

            case ChargeCategoryProperty.internet:
                this.label = 'Abonnement divers';
                this.icon = 'uil uil-globe';
                break;

            case ChargeCategoryProperty.managementFees:
                this.label = 'Frais de gestion';
                this.icon = 'uil uil-receipt-alt';
                break;

            case ChargeCategoryProperty.workAndMaintains:
                this.label = 'Travaux & entretiens';
                this.icon = 'uil uil-trowel';
                break;

            case ChargeCategoryProperty.various:
                this.label = 'Divers';
                this.icon = 'uil uil-chart';
                break;

            case ChargeCategoryProperty.water:
                this.label = 'Eau';
                this.icon = 'uil uil-tear';
                break;
            case ChargeCategoryBuilding.common:
                this.label = 'Commun';
                this.icon = 'uil uil-building';
                break;
            case ChargeCategorySociety.loanInsurance:
                this.label = 'Assurance crédit';
                this.icon = 'uil uil-home';
                break;
            case ChargeCategorySociety.landlordInsurance:
                this.label = 'Assurance bailleur';
                this.icon = 'uil uil-home';
                break;
            case ChargeCategorySociety.houseInsurance:
                this.label = 'Assurance habitation';
                this.icon = 'uil uil-home';
                break;
            case ChargeCategorySociety.rentGuaranteeInsurance:
                this.label = 'Assurance loyers impayés';
                this.icon = 'uil uil-home';
                break;
            case ChargeCategorySociety.legalInsurance:
                this.label = 'Assurance juridique';
                this.icon = 'uil uil-home';
                break;
            default:
                this.label = 'Divers';
                this.icon = 'uil uil-chart';
                break;
        }
    }
}

export enum ChargePeriodicity {
    punctual = 'punctual',
    monthly = 'monthly',
    bimonthly = 'bimonthly',
    quarterly = 'quarterly',
    halfYearly = 'half-yearly',
    yearly = 'yearly',
}

export class ChargePeriodicityInfo implements PeriodicityInfo<ChargePeriodicity> {
    label!: string;
    periodicity!: ChargePeriodicity;

    constructor(periodicity: ChargePeriodicity) {
        this.convertToInfo(periodicity);
    }

    private convertToInfo(periodicity: ChargePeriodicity): void {
        this.periodicity = periodicity;

        switch (periodicity) {
            case ChargePeriodicity.punctual:
                this.label = 'Ponctuelle';
                break;

            case ChargePeriodicity.monthly:
                this.label = 'Mensuelle';
                break;

            case ChargePeriodicity.bimonthly:
                this.label = 'Bimestrielle';
                break;

            case ChargePeriodicity.quarterly:
                this.label = 'Trimestrielle';
                break;

            case ChargePeriodicity.halfYearly:
                this.label = 'Semestrielle';
                break;

            case ChargePeriodicity.yearly:
                this.label = 'Annuelle';
                break;
            default:
                this.label = 'Mensuelle';
                break;
        }
    }
}

export class ChargeEntity extends RestEntity {
    /**
     * @description Link to property
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ChargeEntity
     */
    propertyUID?: string;

    /**
     * @description Link to society, if the charge is a society charge
     * @author ANDRE Felix
     * @type {string}
     * @memberof ChargeEntity
     */
    societyUID?: string;

    /**
     * @description Link to loan, if the charge is a loan charge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 24/06/2024
     * @type {string}
     * @memberof ChargeEntity
     */
    loanUID?: string;

    /**
     * @description Link to user
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ChargeEntity
     */
    userUID!: string;

    /**
     * @description Category
     * @author Martin Bastié
     * @type {ChargeCategoryProperty}
     * @memberof ChargeEntity
     */
    category!: AllChargeCategories;

    /**
     * @description Amount of the charge
     * @author Jérémie Lopez
     * @type {number}
     * @memberof ChargeEntity
     */
    amount!: number;

    /**
     * @description Periodicity
     * @author Martin Bastié
     * @type {ChargePeriodicity}
     * @memberof ChargeEntity
     */
    periodicity!: ChargePeriodicity;

    /**
     * @description Debit date of the charge
     * @author Martin Bastié
     * @type {Timestamp}
     * @memberof ChargeEntity
     */
    debitDate?: Timestamp;

    /**
     * @description Start date of the charge
     * @author Jérémie Lopez
     * @type {Timestamp}
     * @memberof ChargeEntity
     */
    startDate!: Timestamp;

    /**
     * @description End date of the charge
     * @author Jérémie Lopez
     * @type {Timestamp}
     * @memberof ChargeEntity
     */
    endDate?: Timestamp;

    /**
     * @description If true, the owner receive alert to validate movement
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof ChargeEntity
     */
    notification?: boolean;

    /**
     * @description History of payments
     * @author Martin Bastié
     * @type {ChargeHistory[]}
     * @memberof ChargeEntity
     */
    history?: ChargeHistory[];

    /**
     * @description Payments in the futur. Use cas, I want to change amount or debit date in for a month in the future
     * @author Martin Bastié
     * @type {ChargeFuturPayment[]}
     * @memberof ChargeEntity
     */
    futurPayment?: ChargeFuturPayment[];

    /**
     * @description Last amount of payment
     * @author Martin Bastié
     * @type {number}
     * @memberof ChargeEntity
     */
    lastHistoryAmount?: number;

    /**
     * @description Last date of payment
     * @author Martin Bastié
     * @type {Timestamp}
     * @memberof ChargeEntity
     */
    lastHistoryDate?: Timestamp;

    /**
     * @description Next date of payment
     * @author Martin Bastié
     * @type {Timestamp}
     * @memberof ChargeEntity
     */
    nextHistoryDate?: Timestamp | null;

    /**
     * @description Designation
     * @author Martin Bastié
     * @type {string}
     * @memberof ChargeEntity
     */
    designation?: string;

    /**
     * @description If the charge is deleted
     * @author Martin Bastié
     * @type {boolean}
     * @memberof ChargeEntity
     */
    isDeleted?: boolean;

    /**
     * @description If the income is payed and punctual
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof ChargeEntity
     */
    isPayed?: boolean;

    /**
     * @description If the income is readed and punctual
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof ChargeEntity
     */
    isReaded?: boolean;

    /**
     * @description additional notes for the property
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {string}
     * @memberof ChargeEntity
     */
    public notes?: string;

    associatedTo?: ChargeAssociateTo[];

    /**
     * @description Transaction ID of the charge, if it's a punctual charge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 31/05/2024
     * @type {number}
     * @memberof ChargeEntity
     */
    transactionID?: number;

    constructor(charge: Partial<ChargeEntity>) {
        super();
        Object.assign(this, charge);
    }
}

export class ChargeHistory {
    date!: Timestamp;
    designation?: string;
    amount!: number;
    isPayed!: boolean;
    isReaded?: boolean;
    isDeleted!: boolean;
    notes?: string;
    transactionID?: number;
}

export class ChargeFuturPayment {
    date!: Timestamp;
    amount!: number;
    notes?: string;
    isForNext!: boolean;
    isDeleted!: boolean;
}

<div class="section">
  <div class="field">
    <i class="uil uil-user-square"></i>
    <strong>{{ member?.firstname }} {{ member?.name }}</strong>
  </div>
  <div class="info">
    <span>{{ member?.status }}</span>
  </div>
  @if (canMemberBeDelete && canEditMember) {
    <div class="action">
      <button class="action-button" (click)="openDeleteModal(modal)">
        <i class="uil uil-trash-alt"></i>
      </button>
    </div>
  }
</div>

@if (member?.tel) {
  <div class="section">
    <div class="field">
      <i class="uil uil-phone-volume"></i>
      <span>{{ member?.tel }}</span>
    </div>
  </div>
}

<div class="section">
  @if (member?.email) {
    <div class="field">
      <i class="uil uil-envelope"></i>
      <span>{{ member?.email }}</span>
    </div>
  }
  @if (canEditMember) {
    <div class="action">
      <button class="action-button" (click)="edit()">
        <i class="uil uil-edit-alt"></i>
      </button>
    </div>
  }
</div>

<ion-modal #modal [breakpoints]="[0, 1]" initialBreakpoint="1">
  <ng-template>
    <div class="omedom-modal">
      <div class="omedom-modal-header">
        <div class="omedom-modal-header-title">
          <i
            class="uil uil-trash-alt color-light-black background-color-light-orange"
          ></i>
          <h3>Suppression du membre</h3>
        </div>
        <i class="uil uil-times-circle" (click)="modal?.dismiss()"></i>
      </div>
      <div class="omedom-modal-content">
        <p>Êtes-vous sûr.e de vouloir supprimer ce membre ?</p>
        <omedom-info
          information="En supprimant ce membre, ce dernier perd ses accès à l'ensemble des données de la société."
          >
        </omedom-info>
      </div>
      <div class="omedom-modal-buttons">
        <button
          class="omedom-button-secondary"
          (click)="modal?.dismiss()"
          >
          Annuler
        </button>
        <button class="omedom-button-warn" (click)="delete(modal)">
          <i class="uil uil-trash-alt"></i>
          Oui supprimer
        </button>
      </div>
    </div>
  </ng-template>
</ion-modal>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'omedom-licence-stats',
    templateUrl: './licence-stats.component.html',
    styleUrls: ['./licence-stats.component.scss'],
})
export class LicenceStatsComponent {
    /**
     * @description Number of used licences
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {number}
     * @memberof LicenceStatsComponent
     */
    @Input()
    public used: number = 0;

    /**
     * @description Number of sended licences and not validate by user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {number}
     * @memberof LicenceStatsComponent
     */
    @Input()
    public sended: number = 0;

    /**
     * @description Number of available licences
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @type {number}
     * @memberof LicenceStatsComponent
     */
    @Input()
    public available: number = 0;

    /**
     * @description Total of licences
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {number}
     * @memberof LicenceStatsComponent
     */
    @Input()
    public total: number = 0;

    /**
     * @description Event to emit when the user click on the order button
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @memberof LicenceStatsComponent
     */
    @Output()
    public order = new EventEmitter<void>();

    /**
     * @description Display the order button
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/10/2023
     * @type {boolean}
     * @memberof LicenceStatsComponent
     */
    @Input()
    public displayOrderButton: boolean = true;
}

export enum SASRegime {
    SASIR = "SAS à l'IR",
    SASIS = "SAS à l'IS",
}

export enum SARLRegime {
    SARLIR = "SARL à l'IR",

    SARLIS = "SARL à l'IS",
}
/*
Tranche marginale d'imposition
0 %	Jusqu'à 11 294 €
11 %	De 11 295 € à  28 797 €
30 %	De  28 798 € à  82 341 €
41 %	De  82 342 € à 177 106 €
45 %	Plus de 177 106 €
*/

export enum IRTranche {
    T0 = "0% - Jusqu'à 11 294 €",
    T11 = '11% - De 11 295 € à 28 797 €',
    T30 = '30%  - De 28 798 € à 82 341 €',
    T41 = '41% - De 82 342 € à 177 106 €',
    T45 = '45% - Plus de 177 106 €',
}

export enum IRTrancheValue {
    T0 = 0,
    T11 = 11,
    T30 = 30,
    T41 = 41,
    T45 = 45,
}

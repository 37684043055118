import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PropertyEntity, PropertyType, UseProperty } from '@omedom/data';
import { BehaviorSubject } from 'rxjs';

import { slideYInOutAnimation } from '../../../animations/slideYInOut';

@Component({
    selector: 'omedom-property-info-possession',
    templateUrl: './possession.component.html',
    styleUrls: ['./possession.component.scss'],
    animations: [slideYInOutAnimation],
})
export class PropertyInfoPossessionComponent implements OnInit, OnChanges {
    @Input() property?: PropertyEntity;

    propertyUse: UseProperty | string = '';

    /**
     * @description Display Financial data after collapse
     * @author ANDRE Felix
     * @memberof PropertyInfoPossessionComponent
     */
    public displayFinancialData = new BehaviorSubject<boolean>(false);

    ngOnInit(): void {
        if (
            this.property?.type === PropertyType.immeuble &&
            Array.isArray(this.property?.purchaseDetails?.use)
        ) {
            this.propertyUse = this.property?.purchaseDetails?.use?.join(', ') || '';
        } else if (Array.isArray(this.property?.purchaseDetails?.use)) {
            this.propertyUse = this.property?.purchaseDetails?.use[0] || '';
        } else {
            this.propertyUse = this.property?.purchaseDetails?.use || '';
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['property']) {
            this.property = changes['property'].currentValue;
        }
    }
}

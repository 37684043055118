import { Component, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import {
    AssetTypes,
    PropertyEntity,
    QualityEnum,
    SelectOption,
    Sharing,
    SocietyEntity,
    SocietyRoleMember,
    UserEntity,
} from '@omedom/data';
import { AnalyticsService, ShareService, UserService } from '@omedom/services';
import { OmedomRegex } from '@omedom/utils';
import { BehaviorSubject, of } from 'rxjs';

import { OmedomRadioOption } from '../../../components/radio';

@Component({
    selector: 'omedom-share-form',
    templateUrl: './share-form.component.html',
    styleUrls: ['./share-form.component.scss'],
})
export class ShareFormComponent implements OnInit {
    /**
     * @description Share user to edit (in case of edit; null otherwise)
     * @author Hanane DJEDDAL
     * @type {Sharing}
     * @memberof ShareFormComponent
     */
    @Input() share?: Sharing;

    /**
     * @description Index of the Share user to edit in the array of shares (in case of edit, null otherwise)
     *
     * @type {number}
     * @memberof ShareFormComponent
     */
    @Input() sharingIndex?: number;

    /**
     * @description the type of entity to which we add the share Entity (used in submit)
     * @author Hanane DJEDDAL
     * @type {('property' | 'society')}
     * @memberof ShareFormComponent
     */
    @Input() type: AssetTypes = AssetTypes.property;

    /**
     * @description the entity to which we add the share Entity (used to get the array of Shares)
     * @author Hanane DJEDDAL
     * @type {('property' | 'society')}
     * @memberof ShareFormComponent
     */
    @Input() entity?: PropertyEntity | SocietyEntity;

    // @Input() currentUser?: UserEntity;

    /**
     * @description use for society, if the person is a member of this society
     * @author ANDRE Felix
     * @memberof ShareFormComponent
     */
    @Input() public isSocietyMember = false;

    /**
     * @description Regex for email validation in the form of sharing user email address
     * @author Brisset Killian
     * @date 20/06/2024
     * @type {string}
     * @memberof ShareFormComponent
     */
    public emailRegex: string = OmedomRegex.emailRegex;

    /**
     * @description List of already existing sharing, stop submission if already shared
     * @author ANDRE Felix
     * @type {string[]}
     * @memberof ShareFormComponent
     */
    existingSharingMails: string[] = [];

    /**
     * @description Form group of the share user
     * @author Jérémie Lopez
     * @type {FormGroup}
     * @memberof ShareFormComponent
     */
    public shareForm?: UntypedFormGroup;

    /**
     * @description Options to radio buttons
     * @author Jérémie Lopez
     * @type {OmedomRadioOption[]}
     * @memberof ShareFormComponent
     */
    public qualityOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({
            id: QualityEnum.particular,
            label: QualityEnum.particular,
        }),
        new OmedomRadioOption({
            id: QualityEnum.professional,
            label: QualityEnum.professional,
        }),
    ];

    /**
     * @description Placeholder of select component
     * @author Jérémie Lopez
     * @memberof ShareFormComponent
     */
    public rolePlaceholder = {
        id: null,
        label: 'Rôle',
    } as SelectOption;

    /**
     * @description List type of role in the select component
     * @author Jérémie Lopez
     * @memberof ShareFormComponent
     */
    public roleOptions$ = of([
        {
            id: SocietyRoleMember.reader,
            label: SocietyRoleMember.reader,
        } as SelectOption,
        {
            id: SocietyRoleMember.editor,
            label: SocietyRoleMember.editor,
        } as SelectOption,
        // {
        //     id: SocietyRoleMember.admin,
        //     label: SocietyRoleMember.admin,
        // } as SelectOption,
    ]);

    /**
     * @description If true, the role select is disabled (in case of society member sharing) else false
     * @author Brisset Killian
     * @date 20/06/2024
     * @memberof ShareFormComponent
     */
    public disabledRole = new BehaviorSubject<boolean>(false);

    /**
     * @description Role enum of the society member (reader, editor, admin) used for select component in the form of sharing user role
     * @type {SocietyRoleMember}
     * @memberof ShareFormComponent
     */
    public roleEnum = SocietyRoleMember;

    /**
     * @description If true, the app is pending
     * @author Jérémie Lopez
     * @memberof ShareFormPage
     */
    public pending$ = new BehaviorSubject<boolean>(false);

    /**
     * @description if we are creating a sharing
     * @author ANDRE Felix
     * @memberof ShareFormComponent
     */
    public isCreatingSharing = true;

    public currentUser?: UserEntity;

    constructor(
        private modalController: ModalController,
        private formBuilder: UntypedFormBuilder,
        private toastController: ToastController,
        private analyticsService: AnalyticsService,
        private shareService: ShareService,
        private navController: NavController,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.userService.user$.subscribe((user) => {
            this.currentUser = user;
        });
        this.initForm();
        this.setRoleOptions();
        this.isCreatingSharing = this.share?.email === undefined;
    }
    /**
     * @description initiate the Share form (in case of an edit, or an add)
     * @author Hanane Djeddal
     */
    private initForm(): void {
        this.shareForm = this.formBuilder.group({
            firstname: [this.share?.firstname ?? '', [Validators.required]],
            name: [this.share?.name ?? '', [Validators.required]],
            email: [
                this.share?.email ?? '',
                [Validators.email, Validators.required, this.mailValidator()],
            ],
            role: [this.share?.role ?? this.roleEnum.reader, [Validators.required]],
        });
    }

    /**
     * @description In society, for member, we can share as readOnly and edit, otherwise, only readOnly
     * @author ANDRE Felix
     * @private
     * @memberof ShareFormComponent
     */
    private setRoleOptions() {
        const isNotSocietyMember = !this.isSocietyMember && this.type === AssetTypes.society;

        if (isNotSocietyMember) {
            this.roleOptions$ = of([
                {
                    id: SocietyRoleMember.reader,
                    label: SocietyRoleMember.reader,
                } as SelectOption,
            ]);
        }
    }

    /**
     * @description Close the form in page
     * @author Jérémie Lopez
     * @memberof ShareFormComponent
     */
    public async close(): Promise<void> {
        if (await this.modalController.getTop()) {
            this.modalController.dismiss();
        } else {
            this.navController.back();
        }
    }

    /**
     * @description Adds new share user or update existing on submit button click
     * @author Hanane Djeddal
     */
    public async submit(): Promise<void> {
        this.pending$.next(true);

        const data = this.shareForm?.getRawValue();

        const share = {
            firstname: data.firstname,
            name: data.name,
            email: data.email,
            role: data.role ?? null,
        };

        if (this.type === AssetTypes.society) {
            await this.shareService.addSocietySharing(
                share,
                this.entity as SocietyEntity,
                this.sharingIndex
            );
        }
        if (this.type === AssetTypes.property || this.type === AssetTypes.building) {
            await this.shareService.addPropertySharing(
                share,
                this.entity as PropertyEntity,
                this.sharingIndex
            );
        }

        this.pending$.next(false);

        const toast = await this.toastController.create({
            position: 'top',
            message: 'Les partages ont été mis à jour',
            duration: 3000,
            color: 'primary',
        });
        this.analyticsService.logEvent('Share form submitted', { role: data.role });

        await toast.present();
        this.close();
    }

    /**
     * @description Validator for email field in the form of sharing user email address
     * @author Brisset Killian
     * @date 20/06/2024
     * @private
     * @returns {*}  {ValidatorFn}
     * @memberof ShareFormComponent
     */
    private mailValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const email = control.value;
            if (!email) {
                return null;
            }
            const result = this.checkExistingSharing(email);
            return result;
        };
    }

    /**
     * @description Check if the email is already shared or if the email is the owner's email
     * @author Brisset Killian
     * @date 20/06/2024
     * @private
     * @param {string} shareEmail
     * @returns {*}
     * @memberof ShareFormComponent
     */
    private checkExistingSharing(shareEmail: string) {
        if (this.share) {
            return null;
        }
        if (shareEmail === this.currentUser?.email) {
            this.displayErrorToast('Vous ne pouvez pas vous partagez un bien');
            return { shareToOwner: true };
        }
        const existingSharing =
            this.entity?.sharing.findIndex((share) => {
                return shareEmail === share.email;
            }) ?? 0;

        if (existingSharing > -1) {
            this.displayErrorToast('Cette adresse email a déjà un partage');
            return { existingShare: true };
        }
        return null;
    }

    /**
     * @description Display an error toast with the message passed in parameter
     * @author Brisset Killian
     * @date 20/06/2024
     * @private
     * @param {string} message
     * @memberof ShareFormComponent
     */
    private async displayErrorToast(message: string) {
        const toast = await this.toastController.create({
            position: 'top',
            color: 'danger',
            duration: 5000,
            message: message,
        });

        await toast.present();
    }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ChatMessageEntity, ClientEntity, emailMessageToPro, ProEntity } from '@omedom/data';

import {
    LimitSearchParameter,
    RestService,
    SortSearchParameter,
    WhereSearchParameter,
} from './rest.service';
import { lastValueFrom, map, Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
    providedIn: 'root',
})
export class ChatMessageService extends RestService<ChatMessageEntity> {
    constructor(
        protected override firestore: AngularFirestore,
        private readonly functions: AngularFireFunctions
    ) {
        super(firestore, 'chatMessages');
    }

    /**
     * @description Get the messages of a chat. Can be limited and filtered by date.
     *  Returns the last messages by default
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/08/2024
     * @param {string} chatUID
     * @param {number} [limit]
     * @param {Date} [createdBefore]
     * @returns {*}  {Promise<ChatMessageEntity[]>}
     * @memberof ChatMessageService
     * @example
     * const messages = await chatMessageService.getMessages('chatUID', 10, new Date());
     */
    async getMessages(
        chatUID: string,
        limit?: number,
        createdBefore?: Date
    ): Promise<ChatMessageEntity[]> {
        const searchParams: (WhereSearchParameter | SortSearchParameter | LimitSearchParameter)[] =
            [
                {
                    where: 'chatUID',
                    operator: '==',
                    value: chatUID,
                },
            ];

        if (limit) {
            searchParams.push({ limit: limit, limitToLast: true });
        }

        if (createdBefore) {
            searchParams.push({
                where: 'created',
                operator: '<=',
                value: createdBefore,
            });
        } else {
            searchParams.push({
                sortBy: 'created',
                direction: 'desc',
            });
        }

        return this.search(searchParams);
    }

    /**
     * @description Get the messages of a chat. Can be limited and filtered by date.
     * Returns the last messages by default. (Observable version)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/08/2024
     * @param {string} chatUID
     * @param {number} [limit]
     * @param {Date} [createdBefore]
     * @returns {*}  {Observable<ChatMessageEntity[]>}
     * @memberof ChatMessageService
     * @example
     * const messages$ = chatMessageService._getMessages('chatUID', 10, new Date()).subscribe(messages => console.log(messages));
     */
    _getMessages(
        chatUID: string,
        limit?: number,
        createdBefore?: Date
    ): Observable<ChatMessageEntity[]> {
        const searchParams: (WhereSearchParameter | SortSearchParameter | LimitSearchParameter)[] =
            [
                {
                    where: 'chatUID',
                    operator: '==',
                    value: chatUID,
                },
            ];

        if (limit) {
            searchParams.push({ limit: limit, limitToLast: true });
        }

        if (createdBefore) {
            searchParams.push({
                where: 'created',
                operator: '<=',
                value: createdBefore,
            });
        } else {
            searchParams.push({
                sortBy: 'created',
                direction: 'desc',
            });
        }

        return this._search(searchParams);
    }

    /**
     * @description Get the latest message of a chat. Returns null if no message is found.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/08/2024
     * @param {string} chatUID
     * @returns {*}  {(Promise<ChatMessageEntity | null>)}
     * @memberof ChatMessageService
     */
    async getLatestMessage(chatUID: string): Promise<ChatMessageEntity | null> {
        const messages = await this.getMessages(chatUID, 1);
        return messages[0] ?? null;
    }

    /**
     * @description Get the latest message of a chat. Returns null if no message is found. (Observable version)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/08/2024
     * @param {string} chatUID
     * @returns {*}  {(Observable<ChatMessageEntity | null>)}
     * @memberof ChatMessageService
     */
    _getLatestMessage(chatUID: string): Observable<ChatMessageEntity | null> {
        return this._getMessages(chatUID, 1).pipe(
            map((messages: ChatMessageEntity[]) => messages[0] ?? null)
        );
    }

    public async sendMailToProNewChatMessage(emailInfo: emailMessageToPro) {
        const callback = this.functions.httpsCallable('sendMailChatMessage');
        await lastValueFrom(callback(emailInfo));
    }
}

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    Civility,
    ClientEntity,
    FamilyStatus,
    HouseholdIncomeBrackets,
    Liberated,
    MaritalRegime,
    ModalTab,
    PacsRegime,
    ProfessionalStatus,
    SelectOption,
    SlicesOfTheHouseholdSNetBorrowingAssets,
    SocialRegime,
    UserEntity,
} from '@omedom/data';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OmedomRegex } from '@omedom/utils';
import { OmedomRadioOption, OmedomSubTab } from '../../../components';
import { ClientService } from '@omedom/services';
import { BehaviorSubject } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { ChangeDetectorRef } from '@angular/core';

interface MaritalStatusForm {
    firstname: FormControl<string | null>;
    name: FormControl<string | null>;
    birthdate: FormControl<string | null>;
    civility: FormControl<Civility | null>;
    birthplace: FormGroup<{
        country: FormControl<string | null>;
        postalCode: FormControl<string | null>;
        city: FormControl<string | null>;
    }>;
    nationality: FormControl<string | null>;
    countryOfTaxResidence: FormControl<string | null>;
    address: FormGroup<{
        streetNumber: FormControl<number | null>;
        suffixNumber: FormControl<string | null>;
        street: FormControl<string | null>;
        postalCode: FormControl<string | null>;
        city: FormControl<string | null>;
        addressLine2: FormControl<string | null>;
    }>;
    contact: FormGroup<{
        telPro: FormControl<string | null>;
        telHouse: FormControl<string | null>;
        telMobile: FormControl<string | null>;
        emailPro: FormControl<string | null>;
        emailPerso: FormControl<string | null>;
    }>;
    professionalSituation: FormGroup<{
        label: FormControl<string | null>;
        expectedRetirementAge: FormControl<number | null>;
        nameOfsociety: FormControl<string | null>;
        seniority: FormControl<number | null>;
        sector: FormControl<string | null>;
        status: FormControl<keyof typeof ProfessionalStatus | null>;
        socialRegime: FormControl<keyof typeof SocialRegime | null>;
        householdIncomeBracket: FormControl<keyof typeof HouseholdIncomeBrackets | null>;
        slicesOfTheHouseholdSNetBorrowingAssets: FormControl<
            keyof typeof SlicesOfTheHouseholdSNetBorrowingAssets | null
        >;
    }>;
    familySituation: FormGroup<{
        familyStatus: FormControl<keyof typeof FamilyStatus | null>;
        weddingDate: FormControl<string | null>;
        weddingPlace: FormControl<string | null>;
        maritalRegime: FormControl<keyof typeof MaritalRegime | null>;
        pacsRegime: FormControl<keyof typeof PacsRegime | null>;
        liberated: FormControl<(keyof typeof Liberated)[] | null>;
        numberOfDependentPeople: FormControl<number | null>;
        statusOfProtectedAdults: FormControl<string | null>;
    }>;
    isUSPerson: FormControl<boolean | null>;
    isPPE: FormControl<boolean | null>;
    isLinkedToPPE: FormControl<boolean | null>;
}

interface AdditionalInformationForm {
    updated: FormControl<Date | null>;
    mainAdvisor: FormControl<string | null>;
    dateOfConnection: FormControl<Date | null>;
    notes: FormControl<string | null>;
}

@Component({
    selector: 'omedom-client-form',
    templateUrl: './client-form.component.html',
    styleUrls: ['./client-form.component.scss'],
})
export class ClientFormComponent implements OnChanges, AfterViewInit {
    /**
     * @description Client entity to display in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/08/2024
     * @type {ClientEntity}
     * @memberof ClientFormComponent
     */
    @Input({ required: true }) client!: ClientEntity;

    /**
     * @description User entity of the client to display in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @type {UserEntity}
     * @memberof ClientFormComponent
     */
    @Input({ required: true }) user!: UserEntity;

    /**
     * @description Template reference to the marital status tab content of the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @type {TemplateRef<HTMLElement>}
     * @memberof ClientFormComponent
     */
    @ViewChild('maritalStatus') maritalStatus?: TemplateRef<HTMLElement>;

    /**
     * @description Template reference to the additional information tab content of the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @type {TemplateRef<HTMLElement>}
     * @memberof ClientFormComponent
     */
    @ViewChild('additionalInformation') additionalInformation?: TemplateRef<HTMLElement>;

    /**
     * @description Tabs of the form to display the different sections of the form
     * (marital status, additional information) in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @type {ModalTab[]}
     * @memberof ClientFormComponent
     */
    public tabs: ModalTab[] = [
        {
            label: 'État civil',
            name: 'maritalStatus',
            index: 0,
            active: true,
        },
        {
            label: 'Coordonnées',
            name: 'contact',
            index: 1,
        },
        {
            label: 'Situation familiale',
            name: 'familySituation',
            index: 3,
        },
        {
            label: 'Situation professionnelle',
            name: 'professionalSituation',
            index: 4,
        },
        {
            label: 'Informations Complémentaires',
            name: 'additionalInformation',
            index: 5,
        },
    ];

    /**
     * @description Subtabs of the marital status tab to display the different sections of the marital status form
     * (marital status, contact, professional situation) in the marital status tab of the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @type {OmedomSubTab[]}
     * @memberof ClientFormComponent
     */
    public maritalStatusSubTabs: OmedomSubTab[] = [
        {
            label: 'État civil',
            id: 'maritalStatus',
        },
        {
            label: 'Coordonnées',
            id: 'contact',
        },
        {
            label: 'Situation professionnelle',
            id: 'professionalSituation',
        },
    ];

    /**
     * @description Getter to know if the client is married or not based on the family status form value
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @readonly
     * @memberof ClientFormComponent
     */
    public get familyStatus() {
        return this.maritalStatusForm?.getRawValue().familySituation?.familyStatus;
    }

    public allFamilyStatus = Object.keys(FamilyStatus);

    /**
     * @description Selected subtab of the marital status tab to display the selected section of the marital status form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @type {OmedomSubTab}
     * @memberof ClientFormComponent
     */
    public maritalStatusSelectedSubTab: OmedomSubTab = this.maritalStatusSubTabs[0];

    /**
     * @description Pending state of the form to display a loader while the form is submitting the data to the server
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @memberof ClientFormComponent
     */
    pending$ = new BehaviorSubject<boolean>(false);

    /**
     * @description Options for the marital status radio group to display the different marital status options in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @memberof ClientFormComponent
     */
    maritalStatusOptions = [
        new OmedomRadioOption({ id: Civility.Mr, label: 'Monsieur' }),
        new OmedomRadioOption({ id: Civility.Mrs, label: 'Madame' }),
        new OmedomRadioOption({ id: Civility.other, label: 'Autre' }),
    ];

    /**
     * @description Options for the isPPE radio group to display the different options in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @memberof ClientFormComponent
     */
    isPPEOptions = [
        new OmedomRadioOption({ id: true, label: 'Oui' }),
        new OmedomRadioOption({ id: false, label: 'Non' }),
    ];

    /**
     * @description Options for the isLinkedToPPE radio group to display the different options in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @memberof ClientFormComponent
     */
    isLinkedToPPEOptions = [
        new OmedomRadioOption({ id: true, label: 'Oui' }),
        new OmedomRadioOption({ id: false, label: 'Non' }),
    ];

    /**
     * @description Options for the isUSPerson radio group to display the different options in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @memberof ClientFormComponent
     */
    isUSPerson = [
        new OmedomRadioOption({ id: true, label: 'Oui' }),
        new OmedomRadioOption({ id: false, label: 'Non' }),
    ];

    /**
     * @description Options for the family status select to display the different family status options in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @memberof ClientFormComponent
     */
    familyStatusOptions = Object.keys(FamilyStatus).map((key: string) => {
        const value: string = FamilyStatus[key as keyof typeof FamilyStatus];
        return { id: key, label: value } as SelectOption;
    });

    maritalRegimeOptions = Object.keys(MaritalRegime).map((key: string) => {
        const value: string = MaritalRegime[key as keyof typeof MaritalRegime];
        return { id: key, label: value } as SelectOption;
    });

    pacsRegimeOptions = Object.keys(PacsRegime).map((key: string) => {
        const value: string = PacsRegime[key as keyof typeof PacsRegime];
        return { id: key, label: value } as SelectOption;
    });

    regimePlaceholders = {
        id: null,
        label: 'Choisissez un régime',
    } as SelectOption;

    // to 0.5 to 10 with step 0.5
    numberOfDependentPeopleOptions = Array.from({ length: 20 }, (_, i) => i / 2).map((i) => {
        return { id: i, label: i.toString() } as SelectOption;
    });

    numberOfDependentPeoplePlaceholders = {
        id: null,
        label: 'Choisissez un nombre de personnes à charge',
    } as SelectOption;

    /**
     * @description Options for the liberated select to display the different liberated options in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @memberof ClientFormComponent
     */
    liberatedOptions = Object.keys(Liberated).map((key: string) => {
        const value: string = Liberated[key as keyof typeof Liberated];
        return { id: key, label: value } as SelectOption;
    });

    statusOptions = Object.keys(ProfessionalStatus).map((key: string) => {
        const value: string = ProfessionalStatus[key as keyof typeof ProfessionalStatus];
        return { id: key, label: value } as SelectOption;
    });

    statusPlaceholders = {
        id: null,
        label: 'Choisissez un statut professionnel',
    };

    socialRegimeOptions = Object.keys(SocialRegime).map((key: string) => {
        const value: string = SocialRegime[key as keyof typeof SocialRegime];
        return { id: key, label: value } as SelectOption;
    });

    socialRegimePlaceholders = {
        id: null,
        label: 'Choisissez un régime social',
    } as SelectOption;

    householdIncomeBracketOptions = Object.keys(HouseholdIncomeBrackets).map((key: string) => {
        const value: string = HouseholdIncomeBrackets[key as keyof typeof HouseholdIncomeBrackets];
        return { id: key, label: value } as SelectOption;
    });

    householdIncomeBracketPlaceholders = {
        id: null,
        label: 'Choisissez une tranche de revenus du foyer',
    } as SelectOption;

    slicesOfTheHouseholdSNetBorrowingAssetsOptions = Object.keys(
        SlicesOfTheHouseholdSNetBorrowingAssets
    ).map((key: string) => {
        const value: string =
            SlicesOfTheHouseholdSNetBorrowingAssets[
                key as keyof typeof SlicesOfTheHouseholdSNetBorrowingAssets
            ];
        return { id: key, label: value } as SelectOption;
    });

    slicesOfTheHouseholdSNetBorrowingAssetsPlaceholders = {
        id: null,
        label: 'Choisissez une tranche des actifs nets empruntés du foyer',
    } as SelectOption;

    /**
     * @description Placeholders for the family status select to display the default value in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @memberof ClientFormComponent
     */
    familyStatusPlaceholders = {
        id: null,
        label: 'Choisissez un statut familial',
    } as SelectOption;

    /**
     * @description Placeholders for the liberated select to display the default value in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @memberof ClientFormComponent
     */
    liberatedPlaceholders = {
        id: null,
        label: 'Choisissez une libéralité',
    } as SelectOption;

    /**
     * @description Form group for the marital status form to handle the form values and validations in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @type {FormGroup<MaritalStatusForm>}
     * @memberof ClientFormComponent
     */
    maritalStatusForm?: FormGroup<MaritalStatusForm>;

    /**
     * @description Form group for the additional information form to handle the form values and validations in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @type {FormGroup<AdditionalInformationForm>}
     * @memberof ClientFormComponent
     */
    additionalInformationForm?: FormGroup<AdditionalInformationForm>;

    /**
     * @description Regex for the birthdate input to validate the birthdate format in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @memberof ClientFormComponent
     */
    bdayRegex = OmedomRegex.birthdateRegex;

    /**
     * @description Regex for the phone number input to validate the phone number format in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @memberof ClientFormComponent
     */
    phoneRegex = OmedomRegex.phoneRegex;

    constructor(
        private readonly clientService: ClientService,
        private readonly toastController: ToastController,
        private cdr: ChangeDetectorRef
    ) {}

    ngAfterViewInit() {
        this.cdr.detectChanges(); // Ensures changes are applied after the view is initialized
    }

    ngOnChanges(): void {
        this.maritalStatusForm = new FormGroup<MaritalStatusForm>({
            firstname: new FormControl(this.client.firstname || null),
            name: new FormControl(this.client.name || null),
            birthdate: new FormControl(this.client.birthdate || null),
            civility: new FormControl(this.client.civility || null),
            birthplace: new FormGroup({
                postalCode: new FormControl(this.client.birthplace?.postalCode || null),
                city: new FormControl(this.client.birthplace?.city || null),
                country: new FormControl(this.client.birthplace?.country || null),
            }),
            nationality: new FormControl(this.client.nationality || null),
            countryOfTaxResidence: new FormControl(this.client.countryOfTaxResidence || null),
            address: new FormGroup({
                streetNumber: new FormControl(this.client.address?.streetNumber || null),
                suffixNumber: new FormControl(this.client.address?.suffixNumber || null),
                street: new FormControl(this.client.address?.street || null),
                postalCode: new FormControl(this.client.address?.postalCode || null),
                city: new FormControl(this.client.address?.city || null),
                addressLine2: new FormControl(this.client.address?.addressLine2 || null),
            }),
            contact: new FormGroup({
                telPro: new FormControl(this.client.tels?.pro || null),
                telHouse: new FormControl(this.client.tels?.house || null),
                telMobile: new FormControl(this.client.tels?.mobile || null),
                emailPro: new FormControl(this.client.proEmail || null, {
                    validators: [Validators.email],
                }),
                emailPerso: new FormControl(this.client.email, {
                    validators: [Validators.email],
                }),
            }),
            professionalSituation: new FormGroup({
                label: new FormControl(this.client.professionalSituation?.label || null),
                expectedRetirementAge: new FormControl(
                    this.client.professionalSituation?.expectedRetirementAge || null
                ),
                nameOfsociety: new FormControl(
                    this.client.professionalSituation?.nameOfsociety || null
                ),
                seniority: new FormControl(this.client.professionalSituation?.seniority || null),
                sector: new FormControl(this.client.professionalSituation?.sector || null),

                status: new FormControl(this.client.professionalSituation?.status || null),
                slicesOfTheHouseholdSNetBorrowingAssets: new FormControl(
                    this.client.professionalSituation?.slicesOfTheHouseholdSNetBorrowingAssets ||
                        null
                ),
                socialRegime: new FormControl(
                    this.client.professionalSituation?.socialRegime || null
                ),
                householdIncomeBracket: new FormControl(
                    this.client.professionalSituation?.householdIncomeBracket || null
                ),
            }),
            familySituation: new FormGroup({
                familyStatus: new FormControl(this.client.familySituation?.familyStatus || null),
                weddingDate: new FormControl(this.client.familySituation?.weddingDate || null),
                weddingPlace: new FormControl(this.client.familySituation?.weddingPlace || null),
                maritalRegime: new FormControl(this.client.familySituation?.maritalRegime || null),
                pacsRegime: new FormControl(this.client.familySituation?.pacsRegime || null),
                liberated: new FormControl(this.client.familySituation?.liberated || null),
                numberOfDependentPeople: new FormControl(
                    this.client.familySituation?.numberOfDependentPeople || null
                ),
                statusOfProtectedAdults: new FormControl(
                    this.client.familySituation?.statusOfProtectedAdults || null
                ),
            }),
            isUSPerson: new FormControl(this.client.isUSPerson || false),
            isPPE: new FormControl(this.client.isPPE || false),
            isLinkedToPPE: new FormControl(this.client.isLinkedToPPE || false),
        });

        this.additionalInformationForm = new FormGroup<AdditionalInformationForm>({
            updated: new FormControl(this.timestampToDate(this.client.updated) || null),
            mainAdvisor: new FormControl(this.client.mainAdvisor || null),
            dateOfConnection: new FormControl(
                this.timestampToDate(this.client.dateOfConnection) ||
                    this.timestampToDate(this.client.created) ||
                    null
            ),
            notes: new FormControl(this.client.notes || null),
        });
    }

    /**
     * @description Function to handle the key press event on the birthdate input to format the birthdate input in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @param {KeyboardEvent} event
     * @memberof ClientFormComponent
     */
    keyClientBirthdatePress(event: KeyboardEvent) {
        if (event.key !== 'Backspace' && this.user) {
            let bday = this.maritalStatusForm?.get('birthdate')?.value;
            if (bday && (bday.length === 2 || bday.length === 5)) {
                bday = bday + '/';
                this.maritalStatusForm?.get('birthdate')?.setValue(bday);
            }
        }
    }

    /**
     * @description Function to handle the key press event on the wedding date input to format the wedding date input in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @param {KeyboardEvent} event
     * @memberof ClientFormComponent
     */
    keyWeddingDatePress(event: KeyboardEvent) {
        if (event.key !== 'Backspace' && this.user) {
            let bday = this.maritalStatusForm?.get('familySituation')?.get('weddingDate')?.value;
            if (bday && (bday.length === 2 || bday.length === 5)) {
                bday = bday + '/';
                this.maritalStatusForm?.get('familySituation')?.get('weddingDate')?.setValue(bday);
            }
        }
    }

    /**
     * @description Submit function to submit the form data to the server and update the client entity in the database
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @memberof ClientFormComponent
     */
    async submit() {
        const client: ClientEntity = {
            ...this.client,
            firstname: this.maritalStatusForm?.value.firstname,
            name: this.maritalStatusForm?.value.name,
            birthdate: this.maritalStatusForm?.value.birthdate,
            birthplace: this.maritalStatusForm?.value.birthplace,
            civility: this.maritalStatusForm?.value.civility,
            nationality: this.maritalStatusForm?.value.nationality,
            countryOfTaxResidence: this.maritalStatusForm?.value.countryOfTaxResidence,
            address: this.maritalStatusForm?.value.address,
            professionalSituation: this.maritalStatusForm?.value.professionalSituation,
            tels: {
                pro: this.maritalStatusForm?.value.contact?.telPro,
                house: this.maritalStatusForm?.value.contact?.telHouse,
                mobile: this.maritalStatusForm?.value.contact?.telMobile,
            },
            familySituation: this.maritalStatusForm?.value.familySituation,
            proEmail: this.maritalStatusForm?.value.contact?.emailPro,
            email: this.maritalStatusForm?.value.contact?.emailPerso,
            mainAdvisor: this.additionalInformationForm?.value.mainAdvisor,
            dateOfConnection: this.additionalInformationForm?.value.dateOfConnection,
            notes: this.additionalInformationForm?.value.notes,
            isUSPerson: this.maritalStatusForm?.value.isUSPerson,
            isPPE: this.maritalStatusForm?.value.isPPE,
            isLinkedToPPE: this.maritalStatusForm?.value.isLinkedToPPE,
        } as ClientEntity;

        // Remove empty/undefined/null fields
        this.removeEmptyFields(client);

        this.pending$.next(true);
        // create 2 sec delay to simulate the update

        // setTimeout(() => {
        //     this.pending$.next(false);
        // }, 2000);
        try {
            await this.clientService.update(client);
            const toast = await this.toastController.create({
                position: 'top',
                message: 'Le client a bien été mis à jour',
                duration: 5000,
                color: 'success',
            });

            toast.present();
        } catch (error) {
            console.error(error);
            const toast = await this.toastController.create({
                message: 'Une erreur est survenue lors de la mise à jour du client',
                position: 'top',
                duration: 5000,
                color: 'danger',
            });
            toast.present();
        }

        this.pending$.next(false);
    }

    /**
     * @description Function to remove empty fields in an object to avoid sending empty fields to the server
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @param {*} obj
     * @memberof ClientFormComponent
     */
    private removeEmptyFields(obj: any) {
        Object.keys(obj).forEach((key) => {
            if (obj[key] && typeof obj[key] === 'object') {
                this.removeEmptyFields(obj[key]);
                if (obj[key] && Object.keys(obj[key]).length === 0) {
                    delete obj[key];
                }
            } else if (obj[key] === null || obj[key] === undefined) {
                delete obj[key];
            }
        });
    }

    /**
     * @description Function to get the template reference of the active tab to display the content of the active tab in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @returns {*}  {(TemplateRef<HTMLElement> | null)}
     * @memberof ClientFormComponent
     */

    public getTemplate(): TemplateRef<HTMLElement> | null {
        const tab = this.activeTab;

        switch (tab.name) {
            case 'maritalStatus':
            case 'contact':
            case 'professionalSituation':
            case 'familySituation':
                return this.maritalStatus ?? null;
            case 'additionalInformation':
                return this.additionalInformation ?? null;
            default:
                return null;
        }
    }

    /**
     * @description Function to handle the tab change event to change the active tab in the form when a tab is clicked by the user
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 23/08/2024
     * @readonly
     * @type {ModalTab}
     * @memberof ClientFormComponent
     */
    public get activeTab(): ModalTab {
        // Get active tab
        return this.tabs.find((x) => x.active) ?? this.tabs[0];
    }

    private timestampToDate(timestamp: any): Date | undefined {
        if (timestamp && timestamp.toDate && typeof timestamp.toDate === 'function') {
            return timestamp.toDate();
        } else if (timestamp && typeof timestamp === 'object') {
            return new Date(timestamp);
        }

        return undefined;
    }
}

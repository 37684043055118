@if (text) {
  <span [innerHTML]="text"></span>
}
@if (!simpleIcon) {
  <button
    class="omedom-icon-button-primary"
    (click)="actionClicked.next(null)"
    >
    @if (actionIcon) {
      <i
        [class]="actionIcon"
        (click)="actionClicked.next(null)"
      ></i>
    }
  </button>
}
@if (simpleIcon) {
  <i class="omedom-icon-crown smart-option-icon"></i>
}

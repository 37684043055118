<section class="margin-bottom-4">
    @if (proAlerts$ | async; as proAlerts) {
    <!-- Header display -->
    <omedom-section-header title="Évènement(s) client(s)" icon="bell">
    </omedom-section-header>
    <omedom-data-table
        [data]="proAlerts"
        [columns]="columns"
        [actions]="actions"
        [collapsable]="true"
        [expanded]="true"
        [emptyMessage]="emptyMessage"
        [pageSize]="10"
    >
        <ng-container omedom-collapsed-header>
            {{ proAlerts.length }} évènement(s) client(s)
        </ng-container>
    </omedom-data-table>
    } @else {
    <div class="loading-container">
        <ion-spinner></ion-spinner>
    </div>
    }
</section>

<ng-template #loading>
    <div class="loading-container">
        <ion-spinner></ion-spinner>
    </div>
</ng-template>

<ion-modal #modal>
    <ng-template>
        <div class="omedom-modal">
            <div class="omedom-modal-header">
                <div class="omedom-modal-header-title">
                    <h3>Envoyer un message</h3>
                </div>
                <ion-icon name="close" (click)="modal.dismiss()"></ion-icon>
            </div>
            <div class="omedom-modal-content">
                <omedom-input label="Objet" #chatName> </omedom-input>
                <omedom-input
                    label="Message"
                    #message
                    class="textarea"
                ></omedom-input>
            </div>
            <div class="omedom-modal-footer start-chat-modal-footer">
                <button
                    class="omedom-button-warn-secondary"
                    (click)="modal.dismiss()"
                >
                    Annuler
                </button>
                <button
                    class="omedom-button-secondary"
                    [disabled]="!chatName.value || !message.value"
                    (click)="
                        modal.dismiss({
                            chatName: chatName.value,
                            message: message.value
                        })
                    "
                >
                    Envoyer
                </button>
                <button
                    class="omedom-button-primary"
                    [disabled]="!chatName.value || !message.value"
                    (click)="
                        modal.dismiss({
                            chatName: chatName.value,
                            message: message.value,
                            treat: true
                        })
                    "
                >
                    Envoyer et traiter
                </button>
            </div>
        </div>
    </ng-template>
</ion-modal>

<div class="chat-message">
    <div class="chat-message-sub-container" [class.sent]="isCurrentUser">
        <!-- innert HTML -->
        <div class="chat-message-header" [class.flex-reverse]="isCurrentUser">
            @if(isCurrentUser) {
            <span>Vous</span>
            }@else {
            <span>
                {{ message.userEntity?.firstname }}
                {{ message.userEntity?.name }}
            </span>
            }
            <span class="chat-date">{{
                timestampToDate(message.chatMessageEntity.created)
                    | date : 'short'
            }}</span>
        </div>
        <div
            class="chat-message-content"
            [class.align-right]="isCurrentUser"
            [innerHTML]="message.chatMessageEntity.content"
        ></div>
        <div></div>
        <div></div>
    </div>
</div>

<div class="container" [@list]="options.length">
    @for (option of options; track option.label) {
    <div
        class="option"
        (click)="toggleValue(option.value)"
        [class.selected]="option.value === value"
    >
        <i class="option-icon {{ option.icon }}"></i>
        <span class="option-name">{{ option.label }}</span>
    </div>
    }
</div>

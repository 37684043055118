import { animate, style, transition, trigger } from '@angular/animations';

/**
 * @description Animation for the list component
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 29/04/2024
 * @export
 */
export const listAnimation = trigger('list', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(15px)', maxHeight: '0px' }),
        animate('0.25s', style({ maxHeight: '500px' })),
        animate(
            '0.25s',
            style({
                opacity: 1,
                transform: 'translateY(0px)',
            })
        ),
    ]),

    transition(':leave', [
        style({ opacity: 1, transform: 'translateX(0px)', maxHeight: '500px' }),
        animate(
            '0.25s',
            style({
                opacity: 0,
                transform: 'translateX(15px)',
            })
        ),
        animate('0.25s', style({ maxHeight: '0px', marginTop: '0px' })),
    ]),
]);

import { Component, Input } from '@angular/core';
import { ChatMessage } from '../../components/chat-message-card/chat-message-card.component';
import { Observable, of } from 'rxjs';
import { UserEntity } from '@omedom/data';

@Component({
    selector: 'omedom-chat-message-list',
    templateUrl: './chat-message-list.component.html',
    styleUrls: ['./chat-message-list.component.scss'],
})
export class ChatMessageListComponent {
    @Input({ required: true }) messages$: Observable<ChatMessage[]> = of([]);

    @Input({ required: true }) user?: UserEntity;
}

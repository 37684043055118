/**
 * @description LogLevel enum for log level in database
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 30/10/2023
 * @export
 * @enum {number}
 */
export enum LogLevel {
    debug = 'debug',
    info = 'info',
    notice = 'notice',
    warning = 'warning',
    error = 'error',
    critical = 'critical',
    alert = 'alert',
    emergency = 'emergency',
}

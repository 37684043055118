@if (label) {
<div
    class="omedom-form-label"
    [ngStyle]="{ 'font-weight': boldLabel ? '700' : '500' }"
>
    <span>{{ label }}</span>
    @if (required) {
    <sup>*</sup>
    }
</div>
}

<div class="content-container">
    <ng-container
        [ngTemplateOutlet]="selectedTemplate"
        [ngTemplateOutletContext]="{
            option:
                !value || (isMultiple && !lentgh) ? placeholder : selectedOption
        }"
    >
    </ng-container>
</div>

<ng-template #selectedTemplate let-option="option">
    <omedom-select-option
        [option]="option"
        [isDisabled]="isDisabled"
        (clearSelectionClicked)="clearSelectionClicked()"
    >
    </omedom-select-option>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SortParameter } from '@omedom/data';

import { OmedomRadioOption } from '../radio';

@Component({
    selector: 'omedom-document-sort',
    templateUrl: './document-sort.component.html',
    styleUrls: ['./document-sort.component.scss'],
})
export class DocumentSortComponent implements OnInit {
    /**
     * @description Sort options
     * @author Jérémie Lopez
     * @type {OmedomRadioOption[]}
     * @memberof DocumentSortComponent
     */
    public options: OmedomRadioOption[] = [
        new OmedomRadioOption({
            id: { where: 'name', order: 'asc' },
            label: 'Ordre alphabétique',
        }),
        new OmedomRadioOption({
            id: { where: 'date', order: 'desc' },
            label: 'Date du document : du plus récent au plus ancien',
        }),
        new OmedomRadioOption({
            id: { where: 'date', order: 'asc' },
            label: 'Date du document : du plus ancien au plus récent',
        }),
        new OmedomRadioOption({
            id: { where: 'created', order: 'desc' },
            label: "Date de l'ajout : du plus récent au plus ancien",
        }),
        new OmedomRadioOption({
            id: { where: 'created', order: 'asc' },
            label: "Date de l'ajout : du plus ancien au plus récent",
        }),
    ];

    /**
     * @description Selected option
     * @author Jérémie Lopez
     * @memberof DocumentSortComponent
     */
    public selectedOption?: SortParameter;

    constructor(private modalController: ModalController) {}

    ngOnInit(): void {
        this.initState();
    }

    /**
     * @description Init state with default value
     * @author Jérémie Lopez
     * @private
     * @memberof DocumentSortComponent
     */
    private initState(): void {
        const clone = Object.assign(
            {},
            this.options.find(
                (element) =>
                    element.id.where === this.selectedOption?.where &&
                    element.id.order === this.selectedOption?.order
            )
        );
        delete this.selectedOption;
        setTimeout(() => {
            this.selectedOption = clone.id;
        });
    }

    /**
     * @description Close modal
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof DocumentSortComponent
     */
    public async close(data?: any): Promise<void> {
        await this.modalController.dismiss(data);
    }

    public async resetSort(): Promise<void> {
        const sort = null;

        await this.close({ sort });
    }

    public async sendSort(): Promise<void> {
        await this.close({ sort: this.selectedOption });
    }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { IonContent, ModalController, Platform, ToastController } from '@ionic/angular';
import {
    SelectOption,
    Sharing,
    SocietyEntity,
    SocietyMember,
    SocietyRoleMember,
    SocietyStatusMember,
    SocietyType,
    UserEntity,
} from '@omedom/data';
import { ShareService, SocietyService, UserService } from '@omedom/services';
import { OmedomRegex, OmedomShare, OmedomTransformer } from '@omedom/utils';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { AlertComponent } from '../../../components/alert/alert.component';
import { OmedomRadioOption } from '../../../components/radio';
import { OmedomSubTab } from '../../../components/sub-tab/omedom-sub-tab';
import { ValidationModalComponent } from '../../../components/validation-modal/validation-modal.component';
import { OmedomFormatNumber } from '../../../functions';
import { OmedomNumberPipe } from '../../../pipes';

@Component({
    selector: 'omedom-society-form',
    templateUrl: './society-form.component.html',
    styleUrls: ['./society-form.component.scss'],
})
export class SocietyFormComponent implements OnInit, OnDestroy {
    /**
     * @description Send the last active step
     * @author Jérémie Lopez
     * @memberof SocietyFormComponent
     */
    @Output()
    public changeStep = new EventEmitter<number>();

    @Input()
    public container?: IonContent;

    /**
     * @description If true, the form accept to select an other property to associate
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof SocietyFormComponent
     */
    @Input()
    public divisible: boolean = false;

    @Input()
    public society?: SocietyEntity;

    @Output()
    public finishToEdit = new EventEmitter<{
        property: Partial<SocietyEntity>;
        createdPropertyId: string | undefined;
    }>();

    /**
     * @description Regex for postal code validation (5 digits format, no space allowed)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/10/2024
     * @memberof SocietyFormComponent
     */
    codepostalRegex = OmedomRegex.postalCodeRegex;

    /**
     * @description Regex for Closing date validation (dd/mm format) - SCI only
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/10/2024
     * @memberof SocietyFormComponent
     */
    closingDateRegex = OmedomRegex.closingDateRegex;

    /**
     * @description Active step of the step form
     * @author Jérémie Lopez
     * @memberof SocietyFormComponent
     */
    public activeStep = 0;

    /**
     * @description Form Group details
     * @author Jérémie Lopez
     * @type {FormGroup}
     * @memberof SocietyFormComponent
     */
    public detailForm?: UntypedFormGroup;

    /**
     * @description Form Group management
     * @author Jérémie Lopez
     * @type {FormGroup}
     * @memberof SocietyFormComponent
     */
    public managementForm?: UntypedFormGroup;

    /**
     * @description Form Group purchase infos
     * @author Jérémie Lopez
     * @type {FormArray}
     * @memberof SocietyFormComponent
     */
    public membersForm?: UntypedFormArray;

    /**
     * @description If true, the app is pending to upload photo
     * @author Jérémie Lopez
     * @memberof SocietyFormComponent
     */
    public pendingPhoto$ = new BehaviorSubject<boolean>(false);

    /**
     * @description If true, the app is pending
     * @author Jérémie Lopez
     * @memberof SocietyFormComponent
     */
    public pending$ = new BehaviorSubject<boolean>(false);

    /**
     * @description Placeholder of select component
     * @author Jérémie Lopez
     * @memberof SocietyFormComponent
     */
    public typeSocietyPlaceholder = {
        id: null,
        label: 'Type de société',
    } as SelectOption;

    /**
     * @description List type of society in the select component
     * @author Jérémie Lopez
     * @memberof SocietyFormComponent
     */
    public typeSocietyOptions$ = of([
        { id: SocietyType.sci, label: SocietyType.sci } as SelectOption,
        { id: SocietyType.sas, label: SocietyType.sas } as SelectOption,
        { id: SocietyType.sarl, label: SocietyType.sarl } as SelectOption,
        {
            id: SocietyType.familySarl,
            label: SocietyType.familySarl,
        } as SelectOption,
    ]);

    public societyTypes = SocietyType;

    /**
     * @description Placeholder of select component
     * @author Jérémie Lopez
     * @memberof SocietyFormComponent
     */
    public rolePlaceholder = {
        id: null,
        label: 'Rôle',
    } as SelectOption;

    /**
     * @description List type of status in the select component
     * @author Jérémie Lopez
     * @memberof SocietyFormComponent
     */
    public roleOptions$ = of([
        {
            id: SocietyRoleMember.reader,
            label: SocietyRoleMember.reader,
        } as SelectOption,
        {
            id: SocietyRoleMember.editor,
            label: SocietyRoleMember.editor,
        } as SelectOption,
        {
            id: SocietyRoleMember.admin,
            label: SocietyRoleMember.admin,
        } as SelectOption,
    ]);

    /**
     * @description Status of a member
     * @author Jérémie Lopez
     * @memberof SocietyFormComponent
     */
    public status = SocietyStatusMember;

    /**
     * @description Options to radio buttons
     * @author Jérémie Lopez
     * @type {OmedomRadioOption[]}
     * @memberof SocietyFormComponent
     */
    public yesNoOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({ id: true, label: SocietyStatusMember.manager }),
        new OmedomRadioOption({
            id: false,
            label: SocietyStatusMember.associated,
        }),
    ];

    /**
     * @description Options to radio buttons
     * @author Jérémie Lopez
     * @type {OmedomRadioOption[]}
     * @memberof SocietyFormComponent
     */
    public statusOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({
            id: SocietyStatusMember.manager,
            label: SocietyStatusMember.manager,
        }),
        new OmedomRadioOption({
            id: SocietyStatusMember.associated,
            label: SocietyStatusMember.associated,
        }),
    ];

    public subTabs: OmedomSubTab[] = [
        new OmedomSubTab({ id: 0, label: 'Détails' }),
        new OmedomSubTab({ id: 1, label: 'Membres' }),
    ];

    public selectedSubTab: OmedomSubTab = this.subTabs[0];

    /**
     * @description User Data
     * @author Jérémie Lopez
     * @private
     * @type {UserEntity}
     * @memberof SocietyFormComponent
     */
    private user?: UserEntity;

    /**
     * @description List of subscriptions that will deleted
     * @author Jérémie Lopez
     * @private
     * @type {Subscription[]}
     * @memberof SocietyFormComponent
     */
    private subscriptions: Subscription[] = [];

    public alertLabel: string = '';

    public isCapacitor = this.platform.is('capacitor') || false;

    public isAndroid = this.platform.is('android') || false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private userService: UserService,
        private storage: AngularFireStorage,
        private societyService: SocietyService,
        private toastController: ToastController,
        private numberPipe: OmedomNumberPipe,
        private modalController: ModalController,
        private platform: Platform,
        private shareService: ShareService,
        private router: Router
    ) {}

    /**
     * @description Get the address form
     * @author Jérémie Lopez
     * @readonly
     * @type {FormGroup}
     * @memberof SocietyFormComponent
     */
    public get addressForm(): UntypedFormGroup {
        return this.detailForm?.get('address') as UntypedFormGroup;
    }

    /**
     * @description Get if forms are valid
     * @author Jérémie Lopez
     * @readonly
     * @type {boolean}
     * @memberof SocietyFormComponent
     */
    public get validity(): boolean {
        const validityMembers = this.membersForm?.controls.map((form) => form.valid);
        let validityCallback = true;

        validityMembers?.forEach((validity) => {
            if (!validity) {
                validityCallback = false;
            }
        });

        return !!this.detailForm?.valid && !!this.managementForm?.valid && validityCallback;
    }

    async ngOnInit(): Promise<void> {
        this.selectedSubTab = this.subTabs[0];

        this.alertLabel = this.society ? "Edition d'une société" : "Ajout d'une société";

        this.detailForm = this.formBuilder.group(
            {
                name: [this.society?.name ?? '', [Validators.required]],
                type: [this.society?.type ? this.society.type : '', [Validators.required]],
                photo: [this.society?.photo ?? null, []],
                address: this.formBuilder.group({
                    streetNumber: [this.society?.address?.streetNumber ?? null, []],
                    suffixNumber: [this.society?.address?.suffixNumber ?? null, []],
                    street: [this.society?.address?.street ?? '', []],
                    addressLine2: [this.society?.address?.addressLine2 ?? '', []],
                    postalCode: [this.society?.address?.postalCode ?? '', []],
                    city: [this.society?.address?.city ?? '', []],
                }),
                closingDate: [
                    this.society?.closingDate ?? null,
                    [Validators.pattern(this.closingDateRegex)],
                ],
            },
            {
                validators: [
                    (formGroup: UntypedFormGroup) => {
                        if (formGroup.get('type')?.value.id === SocietyType.sci) {
                            if (!formGroup.get('closingDate')?.value) {
                                formGroup.get('closingDate')?.setErrors({ pattern: true });
                            }
                        }
                    },
                ],
            }
        );

        // this.managementForm = this.formBuilder.group({
        //     isUserManager: [this.society?.isUserManager ?? false],
        // });

        this.managementForm = this.formBuilder.group({
            status: [SocietyStatusMember.manager],
        });

        this.membersForm = this.formBuilder.array([]);

        if (this.society) {
            this.society.members.forEach((member) => {
                this.addMemberForm(member);
            });
        }

        const user$ = this.userService.user$.pipe(take(1)).subscribe(async (user) => {
            this.user = user;
        });

        const scroll$ = this.changeStep.subscribe(async () => {
            await this.container?.scrollToTop(200);
        });

        this.subscriptions.push(user$, scroll$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * @description Add a form for new member in form array
     * @author Jérémie Lopez
     * @memberof SocietyFormComponent
     */
    public addMemberForm(member?: SocietyMember): void {
        const isManagerAlreadyExist = this.isManagerAlreadyExist();
        const form = this.formBuilder.group({
            name: [member?.name ?? '', Validators.required],
            firstname: [member?.firstname ?? '', Validators.required],
            email: [
                member?.email ?? '',
                [Validators.required, Validators.email, this.mailValidator()],
            ],
            tel: [member?.tel ?? '', []],
            status: [
                {
                    value: member?.status ?? SocietyStatusMember.associated,
                    disabled: this.isManagerAlreadyExist(),
                },
                Validators.required,
            ],
        });

        this.membersForm?.controls.push(form);
    }

    private isManagerAlreadyExist() {
        const isCreatorManager =
            this.managementForm?.get('status')?.value === SocietyStatusMember.manager;

        if (isCreatorManager) {
            return true;
        }

        return this.isManagerAlreadyPresentInMemberForm();
    }

    private isManagerAlreadyPresentInMemberForm() {
        const members = this.membersForm?.controls.map((form) => {
            return {
                name: form.get('name')?.value,
                firstname: form.get('firstname')?.value,
                email: form.get('email')?.value,
                tel: form.get('tel')?.value,
                status: form.get('status')?.value,
                isCreator: false,
            };
        });

        const index = members?.findIndex((member) => {
            return member.status === SocietyStatusMember.manager;
        });

        if (index !== undefined && index > -1) {
            return true;
        }
        return false;
    }

    /**
     * @description Remove member in form array
     * @author Jérémie Lopez
     * @param {number} index
     * @memberof SocietyFormComponent
     */
    public removeMember(index: number): void {
        this.membersForm?.controls.splice(index, 1);
    }

    private mailValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const email = control.value;

            if (!email || this.society) {
                return null;
            }

            if (this.user?.email === email) {
                this.displayErrorEmailToast();
                return { existingShare: true };
            }

            const members = this.getMembersFromForm() ?? [];

            const index =
                members.findIndex((member) => {
                    return member.email === email;
                }) ?? -1;

            if (index > -1) {
                // we remove the member currently creating (otherwise, all email would be wrong)
                members.splice(index, 1);
            }

            const isEmailAlreadyUse = this.shareService.isEmailAlreadyInMembersOrSharing(
                email,
                [],
                members
            );

            if (isEmailAlreadyUse) {
                this.displayErrorEmailToast();
                return { existingShare: true };
            }
            return null;
        };
    }

    private getMembersFromForm() {
        const members = this.membersForm?.controls.map((form) => {
            return {
                name: form.get('name')?.value,
                firstname: form.get('firstname')?.value,
                email: form.get('email')?.value,
                tel: form.get('tel')?.value,
                status: form.get('status')?.value,
                isCreator: false,
            };
        });
        return members;
    }

    private async displayErrorEmailToast() {
        const toast = await this.toastController.create({
            position: 'top',
            color: 'danger',
            duration: 5000,
            message: 'Cet email est déjà utilisé pour un partage ou pour un membre',
        });

        await toast.present();
    }

    /**
     * @description Submit data to create or edit property
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof SocietyFormComponent
     */
    public async submit(): Promise<void> {
        this.pending$.next(true);

        let createSociety = !this.society;

        let society: Partial<SocietyEntity> = {};

        if (!createSociety) {
            society = this.society as Partial<SocietyEntity>;
        } else {
            society.userUID = this.user?.uid;
            society.sharing = [];
            // society.isUserManager = this.managementForm?.get('isUserManager').value;
        }
        society.members = [];

        // Detail Form
        society.name = this.detailForm?.get('name')?.value;
        society.type = this.detailForm?.get('type')?.value;
        society.photo = this.detailForm?.get('photo')?.value;
        society.address = {
            streetNumber: this.detailForm?.get('address')?.get('streetNumber')?.value,
            suffixNumber: this.detailForm?.get('address')?.get('suffixNumber')?.value,
            street: this.detailForm?.get('address')?.get('street')?.value,
            addressLine2: this.detailForm?.get('address')?.get('addressLine2')?.value,
            postalCode: this.detailForm?.get('address')?.get('postalCode')?.value,
            city: this.detailForm?.get('address')?.get('city')?.value,
        };

        if (this.detailForm?.get('closingDate')?.value) {
            society.closingDate = this.detailForm?.get('closingDate')?.value;
        }

        if (createSociety && this.user) {
            const member: SocietyMember = {
                name: this.user.name,
                firstname: this.user.firstname,
                email: this.user.email,
                status: this.managementForm?.get('status')?.value,
                tel: '',
                isCreator: true,
            };
            // Members Form
            society.members = [member];
        }

        const members = this.getMembersFromForm() ?? [];
        society.members.push(...members);
        if (createSociety) {
            // Members are addind to society.sharing, but not the creator
            const newSharing = this.addNewMemberInSharing(society.members, society.sharing ?? []);
            society.sharing?.push(...newSharing);
            const createdSocietyId = await this.createSociety(society);
            if (createdSocietyId) {
                this.openValidationModal(createdSocietyId);
            } else {
                const toast = await this.toastController.create({
                    position: 'top',
                    color: 'danger',
                    duration: 4000,
                    message: "Une erreur s'est produite, veuillez réessayer plus tard.",
                });
                await toast.present();
            }
        } else {
            await this.updateExistingSociety(society);
        }
        this.pending$.next(false);
        // this.router.navigate([`/tabs/property/society/info/${society.uid}`], {
        //     queryParams: { tab: 1 },
        // });
        // this.router.navigate([`/tabs/property/`]);
        if (await this.modalController.getTop()) {
            this.modalController.dismiss();
        }
    }

    private addNewMemberInSharing(members: SocietyMember[], sharing: Sharing[]) {
        const membersNotInSharing = members.filter((member) => {
            if (member.isCreator) {
                // le createur n'est pas dans les sharing
                return false;
            }
            // Vérifie si l'email du membre n'est pas présent dans le tableau sharing
            return !sharing.some((share) => share.email === member.email);
        });

        const newSharing = membersNotInSharing.map((member) => {
            return OmedomShare.transformSocietyMemberToShare(member);
        });

        return newSharing;
    }

    private async updateExistingSociety(societyToUpdate: Partial<SocietyEntity>) {
        try {
            await this.societyService.update(societyToUpdate);

            const toast = await this.toastController.create({
                position: 'top',
                color: 'primary',
                duration: 4000,
                message: 'Vous avez modifié une société.',
            });
            await toast.present();

            this.pending$.next(false);
        } catch (error) {
            console.error(error);
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
        }
    }

    private async createSociety(societyToUpdate: Partial<SocietyEntity>): Promise<string | void> {
        try {
            if (!this.user) {
                return;
            }
            const response = await this.societyService.create(societyToUpdate);

            if ((this.user.societiesUID?.length ?? 0) > 0) {
                this.user.societiesUID?.push(response.id);
            } else {
                this.user.societiesUID = [response.id];
            }

            await this.userService.update(this.user);

            return response.id;
            //this.pending$.next(false);
        } catch (error) {
            console.error(error);
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
        }
    }

    async exitClicked(label: string, icon: string, img: string | null) {
        const modal = await this.modalController.create({
            component: AlertComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
            componentProps: { title: label, avatar: icon, image: img },
        });

        await modal.present();

        await modal.onDidDismiss().then(async () => {
            if (await this.modalController.getTop()) {
                this.modalController.dismiss();
            }
        });
    }

    async onSave() {
        this.submit();
    }

    async openValidationModal(createdSocietyId: string) {
        const modal = await this.modalController.create({
            component: ValidationModalComponent,
            canDismiss: true,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            backdropDismiss: true,
            componentProps: {
                title: 'Société ajoutée',
                // message:
                //     'Voulez-vous continuer à remplir les informations de votre société (ex: charges, revenus, ...) ?',
                // information: 'Félicitations, vous venez de créer une société',
                // iconInfo: '',
                validateButtonMessage: 'Continuer',
                // cancelButtonMessage: 'Plus tard',
            },
        });
        await modal.present();

        modal.onDidDismiss().then((res) => {
            // const confirmation = res.data;
            // this.router.navigate(['/tabs/property/society/info/', createdSocietyId], {
            //     queryParams: { tab: confirmation ? 3 : 1 },
            // });
            this.finishToEdit.emit({ property: {}, createdPropertyId: undefined });
            // this.router.navigate(['/tabs/property/']);
        });
    }

    /**
     * @description Upload photo to firebase storage
     * @author Jérémie Lopez, Didier Pascarel
     * @return {*}  {Promise<void>}
     * @memberof SocietyFormComponent
     */
    public async uplaodAvatar(): Promise<void> {
        if (this.isCapacitor) {
            const photoIsAllowed = await Camera.checkPermissions().then(async (result) => {
                if (result.camera === 'granted' && result.photos === 'granted') {
                    return true;
                } else {
                    const permission = await Camera.requestPermissions();
                    if (permission.photos === 'granted' && permission.camera === 'granted') {
                        return true;
                    } else {
                        return false;
                    }
                }
            });
            if (!photoIsAllowed) {
                const toast = await this.toastController.create({
                    position: 'top',
                    color: 'warning',
                    duration: 3000,
                    message: 'Accès aux fichiers refusé.',
                });

                await toast.present();
                return;
            }
        }
        let image: Photo;
        if (this.isCapacitor && this.isAndroid) {
            image = await Camera.getPhoto({
                quality: 70,
                allowEditing: false,
                resultType: CameraResultType.Base64,
                source: CameraSource.Prompt,
                promptLabelPhoto: 'Depuis la galerie',
                promptLabelPicture: 'Prendre une photo',
                saveToGallery: true,
            });
        } else {
            image = await Camera.getPhoto({
                quality: 70,
                allowEditing: false,
                resultType: CameraResultType.Base64,
                source: CameraSource.Photos,
                saveToGallery: true,
            });
        }
        const myId = uuidv4();

        if (!image.base64String) {
            return;
        }
        const file = OmedomTransformer.base64ToFile(image.base64String, myId + '.jpg');
        const path = `assets/images/${file.name}`;
        this.pendingPhoto$.next(true);

        //The main task
        try {
            const task = await this.storage.upload(path, file);
            // The file's download URL
            await task.ref.getDownloadURL().then(async (url) => {
                if (url) {
                    this.detailForm?.get('photo')?.setValue(url);
                    this.pendingPhoto$.next(false);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * @description Format number in input type number
     * @author Jérémie Lopez
     * @param {string} newValue
     * @param {string} inputId
     * @param {AbstractControl} control
     * @memberof SocietyFormComponent
     */
    public formatNumber(newValue: string, inputId: string, control: AbstractControl): void {
        const newText = OmedomFormatNumber.format(newValue, this.numberPipe, inputId);
        control.patchValue(newText);
    }
}

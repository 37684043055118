import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'omedom-star-rate',
    templateUrl: './star-rate.component.html',
    styleUrls: ['./star-rate.component.scss'],
})
export class StarRateComponent {
    @Input() rate: number | undefined = 0;
    @Input() readOnly = false;
    @Output() rateChange = new EventEmitter<number>();

    stars = [0, 1, 2, 3, 4];
    hoverIndex = 0;

    onHover(index: number): void {
        if (!this.rate) {
            this.hoverIndex = index;
        }
    }

    resetHover(): void {
        this.hoverIndex = 0;
    }

    onClick(index: number): void {
        if (!this.readOnly) {
            this.rateChange.emit(index);
        }
    }
}

import { RestEntity } from '../rest.entity';

export enum GuestStatus {
    pending = 'pending',
    accepted = 'accepted',
    refused = 'refused',
}

export interface GuestUser {
    email: string;
    userUID: string;
    name: string;
    firstname: string;
    status: GuestStatus;
}

export class ChatEntity extends RestEntity {
    /**
     * @description UIDs of the users in the chat.
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/08/2024
     * @type {string[]}
     * @memberof ChatEntity
     */
    userUIDs!: string[];

    /**
     * @description Guest users in the chat, used to determine if the user want to accept the chat (guestUsers receive the chat)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/08/2024
     * @type {GuestUser[]}
     * @memberof ChatEntity
     */
    guestUsers?: GuestUser[];

    /**
     * @description UID of the user who created the chat
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/08/2024
     * @type {string}
     * @memberof ChatEntity
     */
    creatorUID!: string;

    /**
     * @description Name of the chat
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 28/08/2024
     * @type {string}
     * @memberof ChatEntity
     */
    name!: string;
}

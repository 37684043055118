<div class="header">
    @if (title) {
    <div class="title">
        @if (titleIcon) {
        <i class="{{ titleIcon }} "></i>
        }
        <strong class="title-text">{{ title }}</strong>
    </div>
    }
    <div class="balance layout-{{layout}}">
        <span class="value"
            >{{ totalBalance| currency : 'EUR' || '--,-- €' }}</span
        >
    </div>
</div>

<div class="infos">
    <div class="info layout-{{layout}}">
        <span class="title">Total déposé</span>
        <span class="value" id="deposit"
            >{{ totalDeposit !== null ?(totalDeposit | currency : 'EUR') :
            '--,-- €' }}</span
        >
    </div>

    <div class="info layout-{{layout}}">
        <span class="title">Total retiré</span>
        <span class="value" id="withdraw"
            >{{ totalWithdraw !== null ?(totalWithdraw | currency : 'EUR') :
            '--,-- €' }}
        </span>
    </div>

    @if(showTotalIntrest) {
    <div class="info layout-{{layout}} important">
        <span class="title">Taux d'intérêt</span>
        @if (averageInterest !== null && averageInterest !== undefined ){
        <span
            class="value"
            [class.green]="averageInterest > 0"
            [class.orange]="averageInterest < 0"
            >{{ averageInterest | number }} %</span
        >} @else { <span class="value"> --,-- %</span>}
    </div>
    }
</div>

@if (showGraph) {
<ng-container>
    <div class="progress-bar">
        <div class="total-container">
            @for (saving of savings; track saving.uid) {
            <div
                class="progress-value"
                [style.width]="getPercentage(saving) + '%'"
                [style.background]="getColor(saving)"
            ></div>
            }
        </div>
    </div>

    <div class="legend">
        @for (saving of savings; track saving.uid) {
        <div class="item">
            <div class="color" [style.background]="getColor(saving)"></div>
            <span class="label">{{ saving.name }}</span>
            @if (saving.interestRate) {
            <span class="interest-rate"
                >{{ saving.interestRate | number }} %</span
            >
            } @else {
            <span class="interest-rate">--,-- %</span>
            } @if (saving.balance) {
            <span class="borrowed"
                >{{ saving.balance | currency : 'EUR' || '--,-- €' }}
            </span>
            }
        </div>
        }
    </div>
</ng-container>
}

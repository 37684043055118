/**
 * @description Type of saving account
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 09/07/2024
 * @export
 * @enum {number}
 */
export enum SavingType {
    PEA = 'PEA',
    A_BOOKLET = 'Livret A',
    LDDS = 'LDDS',
    LEP = 'PEL',
    YOUNG_A_BOOKLET = 'Livret Jeune',
    REP = 'PER',
    BROKERAGE = 'Compte-titres',
    LIFE_INSURANCE = 'Assurance-vie',
    OTHER = 'Autre',
}

/**
 * @description Notification event interface
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 15/12/2023
 * @export
 * @interface NotificationEvent
 */
export interface NotificationEvent {
    name?: NotificationEventName;
    content: string;
    subtitle?: string;
    smart?: boolean;
}

/**
 * @description Notification event name enum
 * @author Didier Pascarel <didier.pascarel@omedom.com
 * @date 30/01/2024
 * @export
 * @enum {string}
 */
export enum NotificationEventName {
    mySubscription = 'mySubscription',
    newSharedProperty = 'newSharedProperty',
    newDocument = 'newDocument',
    unpayedCharge = 'unpayedCharge',
    unpayedIncome = 'unpayedIncome',
    payedCharge = 'payedCharge',
    payedIncome = 'payedIncome',
    sendRentalReceipt = 'sendRentalReceipt',
    sendRentalRelaunch = 'sendRentalRelaunch',
    omdeomNews = 'omdeomNews',
    updateLoanAccount = 'updateLoanAccount',
    updateSavingAccount = 'updateSavingAccount',
}

@if (label) {
  <div class="omedom-form-label"
    [ngStyle]="{ 'font-weight': boldLabel ? '700' : '500' }"
    >
    {{ label }}
    @if (required) {
      <sup>*</sup>
    }
    @if (warning) {
      <omedom-warning-popover
        [content]="warning"
      class="margin-left-2"></omedom-warning-popover>
    }
  </div>
}
<div class="omedom-range-container">
  <ion-range [name]="name"
    [ngModel]="value"
    (ngModelChange)="valueChange($event)"
    (ionFocus)="isFocused.next(true)"
    [disabled]="isDisabled"
    [debounce]="debounce"
    [step]="step || 1"
    [min]="min"
    [max]="max"
  [required]="required"></ion-range>
  <div class="omedom-range-value">
    <svg width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M-2.62268e-07 6L6 -2.62268e-07L6 12L-2.62268e-07 6Z" />
      <path d="M-2.62268e-07 6L6 -2.62268e-07L6 12L-2.62268e-07 6Z" />
    </svg>

    {{ value }}
  </div>
</div>


@if (error) {
  <span class="omedom-form-error"
  >{{ error }}</span>
}

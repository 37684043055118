/**
 * @description Step model used to define the steps of a stepper
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 06/05/2024
 * @export
 * @class OmedomStep
 */
export class OmedomStep {
    /**
     * @description The id of the step
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {string}
     * @memberof OmedomStep
     */
    public id: string | number;

    /**
     * @description The label of the step displayed in the stepper component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {string}
     * @memberof OmedomStep
     */
    public label: string;

    /**
     * @description The logic to know if the step is valid or not. If the step is not valid, the user can't
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @memberof OmedomStep
     */
    public canGoNext?: () => boolean;

    constructor(init: OmedomStep) {
        this.id = init.id;
        this.label = init.label;
        this.canGoNext = init.canGoNext;
    }
}

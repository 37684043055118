<ion-item class="omedom-toggle-item">
    <ion-label class="omedom-toggle-item-label">{{ label }}</ion-label>
    <ion-toggle
        class="omedom-toggle-item-toggle"
        slot="start"
        color="secondary"
        [ngModel]="value"
        (ngModelChange)="valueChange($event)"
    ></ion-toggle>
</ion-item>

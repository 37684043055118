/* eslint-disable no-unused-vars */
/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SavingInfosFormType, SavingType, SelectOption } from '@omedom/data';

import { listAnimation } from '../../../../animations';

@Component({
    selector: 'omedom-saving-infos-step',
    templateUrl: './saving-infos-step.container.html',
    styleUrls: ['./saving-infos-step.container.scss'],
    animations: [
        listAnimation
    ]
})
export class SavingInfosStepContainer {
    /**
     * @description Form group input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {FormGroup<SavingInfosFormType>}
     * @memberof SavingInfosStepContainer
     */
    @Input({ required: true })
    public form!: FormGroup<SavingInfosFormType>;

    /**
     * @description Type options for the select input field of the type of saving account form control
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @type {SelectOption[]}
     * @memberof SavingInfosStepContainer
     */
    public typeOptions: SelectOption[] = Object.values(SavingType).map(type => ({ id: type, label: type }));

    /**
     * @description Placeholder of the type select input field
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @type {SelectOption}
     * @memberof SavingInfosStepContainer
     */
    public typePlaceholder: SelectOption = { id: null, label: 'Sélectionner un type de placement' };

    constructor(
    ) { }

    /**
     * @description Number of keys in the form controls object for animation
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @readonly
     * @type {number}
     * @memberof SavingInfosStepContainer
     */
    public get keys(): number {
        return Object.keys(this.form.controls).length;
    }
}

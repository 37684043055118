/**
 * @description Utils for data transformation (base64, blob, file, etc.)
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 01/08/2023
 * @export
 * @class Transformer
 */
export class OmedomTransformer {
    /**
     * @description Transform a base64 string to a file
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @static
     * @param {string} base64
     * @param {string} filename
     * @returns {File}
     * @memberof TransformDatas
     * @example
     * const file = OmedomTransformer.base64ToFile(base64, 'filename.jpg');
     */
    public static base64ToFile(base64: string, filename: string): File {
        const blob = this.dataURItoBlob(base64);
        const file = this.blobToFile(blob, filename);
        return file;
    }

    /**
     * @description Change URI data to Blob
     * @author Jérémie Lopez
     * @static
     * @param {*} dataURI
     * @returns
     * @memberof MenuAccountFormComponent
     * @example
     * const blob = OmedomTransformer.dataURItoBlob(dataURI);
     */
    public static dataURItoBlob(dataURI: string): Blob {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        let i: number;
        for (i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        return new Blob([int8Array], { type: 'image/jpg' });
    }

    /**
     * @description Change Blob to File
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @static
     * @param {Blob} blob
     * @param {string} fileName
     * @returns {File}
     * @memberof MenuAccountFormComponent
     * @example
     * const file = OmedomTransformer.blobToFile(blob, 'filename.jpg');
     */
    public static blobToFile(blob: Blob, fileName: string): File {
        const b: any = blob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return blob as File;
    }

    public static blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result as string);
            };
            reader.readAsDataURL(blob);
        });
    }

    public static async imageUrlToBase64(url: string) {
        let base64 = '';
        try {
            const response = await fetch(url);

            const blob = await response.blob();

            const base64String = await this.blobToBase64(blob);
            base64 = base64String;
        } catch (err) {
            console.error(err);
        }
        return base64;
    }
}

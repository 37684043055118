<div class="graph-header">
    <div>
        <strong>Total&nbsp;</strong>
        <strong
            >{{
                (totalAmount | number : '0.2-2') ?? 0 | omedomNumber
            }}&nbsp;€</strong
        >
    </div>
    <div class="omedom-card padding-1 flex-1 margin-right-1">
        <div>Déjà {{ isCharge ? 'payé' : 'perçu' }}</div>
        <strong
            >{{
                (totalAmountPayed | number : '0.2-2') ?? 0 | omedomNumber
            }}&nbsp;€</strong
        >
    </div>
    <div class="omedom-card padding-1 flex-1">
        <div>Reste à {{ isCharge ? 'payer' : 'percevoir' }}</div>
        <strong
            >{{
                (totalAmountNotPayed | number : '0.2-2') ?? 0 | omedomNumber
            }}&nbsp;€</strong
        >
    </div>
    <!-- <button
      class="omedom-button-secondary w-100 margin-top-1"
      (click)="navigateToChargesDetail()"
  >
      <i class="uil uil-eye"></i>
      Détail des charges
  </button> -->
</div>

import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { Keyboard } from '@capacitor/keyboard';
import { Platform } from '@ionic/angular';
import { Mode } from '@omedom/data';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'omedom-input',
    templateUrl: './input.component.html',
    styleUrls: ['input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent implements ControlValueAccessor, Validator {
    @Input() placeholder?: string;

    @Input() label?: string;

    @Input() name: string = '';

    @Input() icon?: string;

    @Input() mode: Mode = Mode.app;

    @Input() iconEnd?: string;

    @Input() type:
        | 'date'
        | 'datetime-local'
        | 'email'
        | 'month'
        | 'number'
        | 'password'
        | 'search'
        | 'tel'
        | 'text'
        | 'time'
        | 'url'
        | 'bday'
        | 'file'
        | 'week' = 'text';

    @Input() required: boolean = false;

    @Input() debounce = 0;

    @Input() min?: number | string;

    @Input() max?: number | string;

    @Input() maxLength: number | null = null;

    @Input() pattern?: any;

    @Input() class?: string;

    @Input() enableShowPassword?: boolean;

    @Input() autocomplete?: string;

    @Input() error?: string;

    @Input() warning?: string;

    @Input() boldLabel?: boolean;

    @Input() inputmode?: string;

    @Input() accept?: string;

    @Input() rows?: number;

    value: any;

    dateString: string = '';

    isDisabled: boolean = false;

    showPassword = false;

    /**
     * @description Event when user click on icon end of input
     * @author Jérémie Lopez
     * @memberof InputComponent
     */
    @Output()
    public iconEndClick = new EventEmitter<void>();

    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onChange = new EventEmitter<any>();

    private onChangeCallback: Function = () => {};

    /**
     * @description Is focused
     * @author Jérémie Lopez
     * @memberof InputComponent
     */
    public isFocused = new BehaviorSubject<boolean>(false);

    constructor(private platform: Platform, private elementRef: ElementRef) {
        if (this.platform.is('capacitor')) {
            this.keyboardListener();
        }
    }

    validate(): null {
        return undefined as any;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(): void {}

    writeValue(newValue: any): void {
        if (newValue !== this.value) {
            if (this.type === 'date') {
                // Change date format to be compatible with input date
                if (newValue instanceof Date) {
                    newValue = newValue.toISOString().split('T')[0];
                    this.dateString = newValue;
                }
            }

            this.value = newValue;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        // Ne fonctionne que si c'est du template driven
        this.isDisabled = isDisabled;
    }

    valueChange(value: any) {
        if (this.type === 'date') {
            // Change date format to be compatible with input date
            if (typeof value === 'string') {
                value = new Date(value);
            }
        }

        if (this.type === 'number') {
            value = Number(value);
        }

        this.writeValue(value);
        this.onChangeCallback(value);
        this.onChange.emit(value);
    }

    /**
     * @description Listen to the keyboard to scroll to the input if it is hidden by the keyboard and sticky container
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/08/2024
     * @private
     * @returns {Promise<void>}
     * @memberof InputComponent
     */
    private async keyboardListener(): Promise<void> {
        Keyboard.addListener('keyboardWillShow', async () => {
            // Get the parent container of the input
            const parent = this.elementRef.nativeElement.parentElement;
            const parentRect = parent.getBoundingClientRect();
            const parentHeight = parentRect.height;

            // Get the y position of the input
            const input = this.elementRef.nativeElement.querySelector('input');
            const inputRect = input.getBoundingClientRect();
            const inputY = inputRect.top;

            // Get the height of the keyboard
            const keyboardHeight = window.innerHeight * 0.4;

            // Get an eventual sticky container height
            const stickyContainer = this.elementRef.nativeElement.closest(
                '.omedom-sticky-button-container'
            );
            const stickyContainerHeight = stickyContainer ? stickyContainer.clientHeight : 0;

            // Check if the input is hidden by the keyboard and sticky container
            if (inputY > parentHeight - keyboardHeight - stickyContainerHeight) {
                // Scroll to the input
                parent.scrollTo(
                    0,
                    inputY - parentHeight + keyboardHeight + stickyContainerHeight + 20
                );
            }
        });
    }

    get isTextarea(): boolean {
        return this.class?.includes('textarea') ?? false;
    }

    keepFile(event: EventTarget | null) {
        if (!event) {
            return;
        }

        const files = (event as HTMLInputElement).files;

        if (!files?.item(0)) {
            return;
        }

        this.valueChange(files.item(0));
    }
}

import { animate, style, transition, trigger } from '@angular/animations';

/**
 * @description Animation for overlay element
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 04/06/2024
 * @export
 */
export const overlayAnimation = trigger('overlay', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('0.25s', style({ opacity: 1 })),
    ]),
    transition(':leave', [
        animate('0.25s', style({ opacity: 0 })),
    ]),
]);

import { SubscriptionRank } from '../enums';
import { BillingInformation, SubscriptionType } from '../entities';

export type SubscriptionDto = {
    uid: string;
    isSubscribed: boolean;
    isApple: boolean;
    renewDate: number;
    billingInformations: BillingInformation;
    fromFamily?: {
        name: string;
        firstName: string;
        userUID: string;
    };
    trial: boolean;
    canceled: boolean;
    rank: SubscriptionRank;
    futureRank?: SubscriptionRank;
    subscriptionType: SubscriptionType;
    licenceUID?: string;
    proUID?: string;
};

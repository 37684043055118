@if (recommendation) {
  <div class="recommendation"
    @element
    >
    <i class="uil uil-lightbulb-alt"></i>
    <span class="recommendation-label">Recommandation :</span>
    <span class="recommendation-value">{{ recommendation }}</span>
  </div>
}

@if (form) {
  <form [formGroup]="form"
    >
    <omedom-select-grid [options]="options"
    formControlName="category"></omedom-select-grid>
  </form>
}
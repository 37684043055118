import { FormControl } from '@angular/forms';

/**
 * @description Bank transaction recurrence form type to associate transactions to a recurrence (optional)
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 20/05/2024
 * @type {BankTransactionRecurrenceForm}
 * @memberof BankTransactionRecurrenceStepContainer
 */
export type BankTransactionRecurrenceForm = {
    /**
     * @description Transaction UIDs to associate to the recurrence (optional)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @type {FormControl<string[]>}
     */
    transactionUIDs: FormControl<string[]>;
};

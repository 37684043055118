import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
    IncomeCategoryProperty,
    IncomeEntity,
    IncomePeriodicity,
    IncomePeriodicityInfo,
    IncomeUpdateType,
    LeaseEntity,
    PropertyEntity,
    RoleState,
    SelectOption,
    TenantEntity,
} from '@omedom/data';
import { IncomeService, PropertyService } from '@omedom/services';

@Component({
    selector: 'omedom-lease-form-rent',
    templateUrl: './rent.component.html',
    styleUrls: ['./rent.component.scss'],
})
export class RentComponent implements OnInit {
    @Input() lease: Partial<LeaseEntity> = {};

    @Input() roleState?: RoleState;

    public rents: IncomeEntity[] = [];

    @Input({ required: true }) property?: PropertyEntity;

    @Output() addRentClick = new EventEmitter<{ propertyUID: string | undefined ; navigationExtras: NavigationExtras }>();

    public tenant?: TenantEntity;

    public afterAddRent = false;

    /**
     * @description Placeholder of select component
     * @author Jérémie Lopez
     * @memberof LeaseFormComponent
     */
    public rentPlaceholder = {
        id: null,
        label: 'Choisir dans ma liste des loyers',
    } as SelectOption;

    /**
     * @description List type of lease in the select component
     * @author Jérémie Lopez
     * @memberof LeaseFormComponent
     */
    public rentOptions: SelectOption[] = [];

    public rentSelected?: string;

    public periodicityLabel = '';

    constructor(
        private incomeService: IncomeService,
        private router: Router,
        private propertyService: PropertyService,
        private modalController: ModalController
    ) {}

    public get rent(): IncomeEntity | undefined {
        const rentIncome = this.rents.find((rent) => rent.uid === this.lease.rentUID);
        if (rentIncome) {
            this.periodicityLabel = new IncomePeriodicityInfo(rentIncome?.periodicity).label;
        }
        return rentIncome;
    }

    async ngOnInit(): Promise<void> {
        if (this.lease.propertyUID) {
            this.rents = await this.incomeService.getIncomesByCategoryByPropertyIds(
                IncomeCategoryProperty.rent,
                [this.lease.propertyUID]
            );
        }
        this.rents = this.rents.filter((rent) => !rent.isDeleted);

        this.rentOptions = this.rents.map(
            (income) =>
                ({
                    id: income.uid,
                    label: `Loyer ${income.designation ? '(' + income.designation + ')' : ''} ${
                        income.amount
                    }€`,
                    icon: 'uil uil-euro-circle',
                } as SelectOption)
        );

        this.rentSelected = this.rentOptions.find((option) => option.id === this.lease.rentUID)?.id;
    }
    async ngDoCheck(): Promise<void> {
        if (this.afterAddRent) {
            this.afterAddRent = false;
            if (this.lease.propertyUID) {
                this.rents = await this.incomeService.getIncomesByCategoryByPropertyIds(
                    IncomeCategoryProperty.rent,
                    [this.lease.propertyUID]
                );
            }
            const orderedRents = this.rents.sort(
                (a, b) => b.created.getTime() - a.created.getTime()
            );
            this.rentOptions = this.rents.map(
                (income) =>
                    ({
                        id: income.uid,
                        label: `Loyer ${income.designation ? '(' + income.designation + ')' : ''} ${
                            income.amount
                        }€`,
                        icon: 'uil uil-euro-circle',
                    } as SelectOption)
            );

            this.lease.rentUID = orderedRents[0].uid;
        }
    }

    public async addRent(): Promise<void> {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                income: IncomeCategoryProperty.rent,
            },
            state: { back: true },
        };

        if(await this.modalController.getTop()) {
            await this.modalController.dismiss({
                addRent: true,
            })
        } else {
            this.addRentClick.emit({propertyUID : this.lease.propertyUID, navigationExtras});
        }
        this.afterAddRent = true;
    }

    public removeRent(): void {
        delete this.lease.rentUID;
    }

    public async editRent(): Promise<void> {
        if (this.rent?.periodicity === IncomePeriodicity.punctual) {
            await this.router.navigate([
                `/tabs/treasury/income/edit/${this.rent.uid}/${IncomeUpdateType.thisOneAndNext}/${this.rent.startDate}`,
            ]);
        }

        // const modal = await this.showEditComponent(IncomeEditTypeComponent);

        // modal.onDidDismiss().then(x => {
        //     if (!x.data) {
        //         return;
        //     }

        //     if (this.propertyUid) {
        //         this.router.navigate([`/tabs/property/info/${this.propertyUid}/income/edit/${incomeListModel.uid}/${x.data}/${incomeListModel.date}`]);

        //     } else {
        //         this.router.navigate([`/tabs/treasury/income/edit/${incomeListModel.uid}/${x.data}/${incomeListModel.date}`]);
        //     }
        // });
    }

    public onRentChange(rent: string): void {
        this.lease.rentUID = rent;
        this.rentSelected = rent;
    }
}

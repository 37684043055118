/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable no-unused-vars */
import { Component, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { SavingEntity } from '@omedom/data';
import { SavingService } from '@omedom/services';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'omedom-saving-delete-confirm',
    templateUrl: './saving-delete-confirm.container.html',
    styleUrls: ['./saving-delete-confirm.container.scss'],
})
export class SavingDeleteConfirmContainer {
    /**
     * @description Saving data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @type {BankItemEntity}
     * @memberof SavingDeleteConfirmContainer
     */
    @Input({ required: true })
    public saving!: SavingEntity;

    /**
     * @description Message to display in the confirm button of the modal component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @memberof SavingDeleteConfirmContainer
     */
    public message$ = new BehaviorSubject<string>('Oui, supprimer');

    /**
     * @description State of the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @memberof SavingDeleteConfirmContainer
     */
    public state$ = new BehaviorSubject<string>('ok');

    constructor(
        private modalController: ModalController,
        private savingService: SavingService,
        private toastController: ToastController
    ) { }

    /**
     * @description Dismiss the modal component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @param {boolean} deleted
     * @returns {Promise<void>}
     * @memberof SavingDeleteConfirmContainer
     */
    public async dismiss(deleted?: boolean): Promise<void> {
        // Check if the state is pending
        if (this.state$.value === 'pending') {
            return;
        }

        await this.modalController.dismiss(deleted);
    }

    /**
     * @description Delete the saving and dismiss the modal component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @returns {Promise<void>}
     * @memberof SavingDeleteConfirmContainer
     */
    public async delete(): Promise<void> {
        // Disable dismiss for the modal component
        const modal = await this.modalController.getTop();

        if (modal) {
            modal.backdropDismiss = false;
        }

        // Set the state to pending
        this.state$.next('pending');

        // Set the message to display in the confirm button
        this.message$.next('Suppression...');

        if (this.saving) {
            await this.savingService.delete(this.saving.uid);

            // Set the state to ok
            this.state$.next('ok');

            // Dismiss the modal component
            await this.dismiss(true);

            // Display a toast message
            const toast = await this.toastController.create({
                message: 'Cette épargne a bien été supprimée',
                duration: 3000,
                color: 'success',
            });

            await toast.present();
        } else {
            // Display a toast message
            const toast = await this.toastController.create({
                message: 'Impossible de supprimer cette épargne',
                duration: 3000,
                color: 'danger',
            });

            await toast.present();

            // Set the state to ok
            this.state$.next('ok');
        }

        // Set the message to display in the confirm button
        this.message$.next('Oui, supprimer');

        // Enable dismiss for the modal component
        if (modal) {
            modal.backdropDismiss = true;
        }
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Sharing, UserEntity } from '@omedom/data';
import { OmedomRegex } from '@omedom/utils';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-share-form-component',
    templateUrl: './share-form.component.html',
    styleUrls: ['./share-form.component.scss'],
})
export class ShareFormModalComponent implements OnInit {
    /**
     * @description Share user to edit (in case of edit; null otherwise)
     * @author Hanane DJEDDAL
     * @type {Sharing}
     * @memberof ShareFormComponent
     */
    @Input() share?: Sharing;

    @Input() addText?: string = "Ajout d'un tiers";
    @Input() modifyText?: string = "Modification d'un tiers";
    @Input() buttonText?: string = 'Partager';
    @Input() buttonIcon?: string = 'share-alt';

    @Output() submitEvent = new EventEmitter<Sharing>();

    public emailRegex: string = OmedomRegex.emailRegex;

    /**
     * @description Form group of the share user
     * @author Jérémie Lopez
     * @type {FormGroup}
     * @memberof ShareFormComponent
     */
    public shareForm?: FormGroup;

    /**
     * @description If true, the app is pending
     * @author Jérémie Lopez
     * @memberof ShareFormPage
     */
    public pending$ = new BehaviorSubject<boolean>(false);

    /**
     * @description if we are creating a sharing
     * @author ANDRE Felix
     * @memberof ShareFormComponent
     */
    public isCreatingSharing = true;

    public currentUser?: UserEntity;

    constructor(
        private modalController: ModalController,
        private formBuilder: UntypedFormBuilder
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.isCreatingSharing = this.share?.email === undefined;
    }
    /**
     * @description initiate the Share form (in case of an edit, or an add)
     * @author Hanane Djeddal
     */
    private initForm(): void {
        this.shareForm = this.formBuilder.group({
            firstname: [this.share?.firstname ?? '', [Validators.required]],
            name: [this.share?.name ?? '', [Validators.required]],
            email: [this.share?.email ?? '', [Validators.email, Validators.required]],
        });
    }

    /**
     * @description Close the form in page
     * @author Jérémie Lopez
     * @memberof ShareFormComponent
     */
    public async close() {
        await this.modalController.dismiss(null);
    }

    /**
     * @description Adds new share user or update existing on submit button click
     * @author Hanane Djeddal
     */
    public async submit(): Promise<void> {
        if (this.shareForm) {
            const data = this.shareForm.getRawValue();

            const share = {
                firstname: data.firstname,
                name: data.name,
                email: data.email,
            };

            await this.modalController.dismiss(share);
        } else {
            throw new Error('Form is not defined');
        }
    }
}

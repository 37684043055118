import { EventDom, LevelEntity, levels } from '@omedom/data';

/**
 * @description Utils to manage level of user and doms
 * @author Jérémie Lopez
 * @export
 * @class OmedomLevel
 */
export class OmedomLevel {
    /**
     * @description Get the level of user from doms
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @param {number} dom Dom points of user
     * @return {number} Level of user
     * @memberof OmedomLevel
     * @example
     * const dom = 100;
     * const level = OmedomLevel.getLevel(dom);
     */
    public static getLevel(dom: number): number {
        const maxDom = levels[levels.length - 1].value;

        if (dom >= maxDom) {
            return levels[levels.length - 1].order;
        }

        const level = levels.find((l, i) => l.value <= dom && levels[i + 1]?.value > dom);

        return level ? level.order : 0;
    }

    public static getLevelValue(dom: number): number {
        const maxDom = levels[levels.length - 1].value;

        if (dom >= maxDom) {
            return levels[levels.length - 1].value;
        }

        const level = levels.find((l, i) => l.value <= dom && levels[i + 1]?.value > dom);

        return level ? level.value : 0;
    }

    /**
     * @description Get the next level value of user from doms and level of user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @param {number} dom Dom points of user
     * @return {number}  Next level of user
     * @memberof OmedomLevel
     * @example
     * const dom = 100;
     * const nextLevel = OmedomLevel.getNextLevel(dom);
     */
    public static getNextLevel(dom: number): number {
        const maxDom = levels[levels.length - 1].value;

        if (dom >= maxDom) {
            return levels[levels.length - 1].order;
        }

        const level = levels.find((l, i) => l.value <= dom && levels[i + 1]?.value > dom);

        const nextLevel = levels[(level?.order ?? 0) + 1];

        return nextLevel ? nextLevel.order : 0;
    }

    public static getNextLevelValue(dom: number): number {
        const maxDom = levels[levels.length - 1].value;

        if (dom >= maxDom) {
            return levels[levels.length - 1].value;
        }

        const level = levels.find((l, i) => l.value <= dom && levels[i + 1]?.value > dom);

        const nextLevel = levels[(level?.order ?? 0) + 1];

        return nextLevel ? nextLevel.value : 0;
    }

    /**
     * @description Get the progress of user from doms and level of user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @param {number} dom Dom points of user
     * @return {number} Progress of user to next level in percent
     * @memberof OmedomLevel
     * @example
     * const dom = 120;
     * const progress = OmedomLevel.getProgressLevel(dom);
     */
    public static getProgressLevel(dom: number): number {
        const maxDom = levels[levels.length - 1].value;

        if (dom >= maxDom) {
            return 100;
        }

        const level = levels.find((l) => l.order === this.getLevel(dom));
        const nextLevel = levels.find((l) => l.order === this.getNextLevel(dom));

        if (level && nextLevel) {
            const diff = nextLevel.value - level.value;
            const progress = dom - level.value;
            return Math.round((progress * 100) / diff);
        }

        return 0;
    }

    /**
     * @description Calculate the offset of the path to display on map of levels (only frontend)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @param {number} dom Dom points of user
     * @param {number} totalLength Total length of the path
     * @return {number} Offset of the path to display on map of levels
     * @memberof OmedomLevel
     * @example
     * const dom = 120;
     * const totalLength = 1000;
     * const offset = OmedomLevel.calculateOffset(dom, totalLength);
     */
    public static calculateOffset(dom: number, totalLength: number): number {
        // Get the level of user
        const level = levels.find((l) => l.order === this.getLevel(dom));

        // Get the next level of user
        const nextLevel = levels.find((l) => l.order === this.getNextLevel(dom));

        // Get the progress of user
        const progress = this.getProgressLevel(dom);

        const levelOffset = level?.offset ?? 0;

        const nextLevelOffset = nextLevel?.offset ?? 0;

        const diff = nextLevelOffset - levelOffset;

        const progressOffset = (diff * progress) / 100;

        return totalLength * (1 - (levelOffset + progressOffset));
    }

    /**
     * @description Get the events for a level of user from doms and level of user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @param {number} order Order of level
     * @param {EventDom[]} eventsDom Events of user
     * @return {EventDom[]} Events passed for a level of user
     * @memberof OmedomLevel
     * @example
     * const order = 1;
     * const eventsDom = [...];
     *
     * const events = OmedomLevel.getEventsToReachLevel(order, eventsDom);
     */
    public static getEventsToReachLevel(order: number, eventsDom: EventDom[]): EventDom[] {
        if (order === 0) {
            return [];
        }

        const level = levels.find((l: LevelEntity) => l.order === order - 1);
        const nextLevel = levels.find((l: LevelEntity) => l.order === order);

        const eventsDomSorted = eventsDom.sort(
            (a: EventDom, b: EventDom) =>
                (a.date as any).toDate().getTime() - (b.date as any).toDate().getTime()
        );

        if (!level) {
            return eventsDomSorted.filter((e: EventDom) => e.dom < (nextLevel?.value ?? 0));
        }

        // If nextLevel is undefined, it's the last level
        if (!nextLevel) {
            return eventsDomSorted.filter((e: EventDom) => e.dom >= level.value);
        }

        return eventsDomSorted.filter(
            (e: EventDom) => e.dom >= level.value && e.dom < nextLevel.value
        );
    }
}

import {
    AtticType,
    AtticTypeFr,
    IsolationRenovationPeriod,
    IsolationRenovationPeriodFr,
    RenovationCalculatorDPE,
} from '@omedom/data';

export class OmedomTransformPricehubbleData {
    public static transformIsolation(
        isolationRenovationPeriod: IsolationRenovationPeriod | undefined
    ) {
        if (!isolationRenovationPeriod) {
            return undefined;
        }
        switch (isolationRenovationPeriod) {
            case IsolationRenovationPeriod.moreThan10Years:
                return IsolationRenovationPeriodFr.moreThan10Years;

            case IsolationRenovationPeriod.last10Years:
                return IsolationRenovationPeriodFr.last10Years;
            case IsolationRenovationPeriod.notRenovated:
                return IsolationRenovationPeriodFr.notRenovated;
            default:
                return isolationRenovationPeriod;
        }
    }

    public static transformAtticType(atticType: AtticType | undefined) {
        if (!atticType) {
            return undefined;
        }
        switch (atticType) {
            case AtticType.convertedAttic:
                return AtticTypeFr.convertedAttic;
            case AtticType.flatRoof:
                return AtticTypeFr.flatRoof;
            case AtticType.lostAtticSpace:
                return AtticTypeFr.lostAtticSpace;
        }
        return atticType;
    }

    public static transformRenovationCalcFromPricehubble(renovationCalc: RenovationCalculatorDPE) {
        const renovations = renovationCalc.renovations.map((renovation) => {
            const adaptedRenovation = { ...renovation };
            delete adaptedRenovation.rentGrossDifference;
            return adaptedRenovation;
        });

        // functions.logger.debug()

        // const renovationCost = renovation.renovationCosts;
        // const salePriceDifference = renovation.salePriceDifference;
        // const energyLabelRenovation = renovation.energyLabelRenovation;
    }
}

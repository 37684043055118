import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormControl } from '@angular/forms';
import * as uuid from 'uuid';

/**
 * @description Form utils functions to be used in forms components and this.services
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 27/10/2023
 * @export
 * @class OmedomForm
 */
export class OmedomForm {
    /**
     * @description Error messages for each form control error code
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/10/2023
     * @private
     * @static
     * @memberof OmedomForm
     */
    private static errorMessages: Record<string, (error: any) => string> = {
        required: () => 'Ce champ est obligatoire.',
        email: () => 'Veuillez entrer une adresse email valide.',
        min: (error: { min: number; }) => `La valeur doit être supérieur à ${error.min}.`,
        max: (error: { max: number; }) => `La valeur doit être inférieur à ${error.max}.`,
        minlength: (error: { requiredLength: number; }) => `La valeur doit contenir au moins ${error.requiredLength} caractères.`,
        maxlength: (error: { requiredLength: number; }) => `La valeur doit contenir au maximum ${error.requiredLength} caractères.`,
        phoneNumberInvalid: () => 'Veuillez entrer un numéro de téléphone valide.',
        userDoesNotExist: () => 'Cet utilisateur n\'existe pas.',
        proDoesNotExist: () => 'Ce professionnel n\'existe pas.',
        propertyDoesNotExist: () => 'Ce bien n\'existe pas.',
        societyDoesNotExist: () => 'Cette société n\'existe pas.',
        urlInvalid: () => 'Veuillez entrer une url valide.'
    };

    /**
     * @description Get error message of a form control if it has an error and if it has already been touched (dirty = true) else return empty string
     * @param {FormControl<keyof T>} control
     * @returns {string}
     * @memberof Form
     * @static
     * @date 27/10/2023
     * @export
     * @example
     * const control = new FormControl('', [Validators.required]);
     * const errorMessage = OmedomForm.getErrorMessage(control);
     * console.log(errorMessage); // 'Ce champ est obligatoire.'
     */
    public static getErrorMessage(control: FormControl): string {
        // Init error message
        let message = '';

        // Check if the form control exist
        if (!control) {
            return message;
        }

        // Check if the form control has already touched
        if (control?.dirty === false) {
            return message;
        }

        // For each error of the form control
        for (const code of Object.keys(control.errors || {})) {
            const error: any = control.errors ? control.errors[code] : null;

            // Check if error is defined
            if (!error) {
                continue;
            }

            // If message is not empty add space
            if (message) {
                message += ' ';
            }

            // Check if error message exist
            if (!this.errorMessages[code]) {
                continue;
            }

            // Add error message to message
            message += this.errorMessages[code](error);
        }

        return message;
    }

    /**
     * @description Upload a file to firebase storage and return the file URL
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/10/2023
     * @static
     * @param {File} file
     * @param {string} path
     * @param {AngularFireStorage} storage
     * @returns {Promise<string>}
     * @memberof OmedomForm
     * @example
     * const file = new File([''], 'filename');
     * const path = 'path';
     * const storage = new AngularFireStorage();
     * const fileUrl = await OmedomForm.uploadFile(file, path, storage);
     * console.log(fileUrl); // 'https://firebasestorage.googleapis.com/v0/b/omedom.appspot.com/o/path%2Ffilename?alt=media&token=token'
     */
    public static async uploadFile(file: File, path: string, storage: AngularFireStorage): Promise<string> {
        // Generate a unique id
        const uid = uuid.v4();

        // Get extension
        const extension = file.name.split('.').pop();

        // Create a reference to the file to save
        const filePath = `${path}/${uid}.${extension}`;

        // Upload the file
        const task = await storage.upload(filePath, file);

        // Get the file URL
        const fileRef = task.ref;

        // Return the file URL
        return await fileRef.getDownloadURL();
    }
}

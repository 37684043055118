import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
    DocumentTypeUsed,
    FilterParameter,
    PropertyEntity,
    SocietyEntity,
    SortParameter,
} from '@omedom/data';
import { PropertyService } from '@omedom/services';

import { DocumentSortComponent } from '../../../components/document-sort/document-sort.component';
import { DocumentFilterComponent } from '../document-filter/document-filter.component';

@Component({
    selector: 'omedom-document-search',
    templateUrl: './document-search.component.html',
    styleUrls: ['./document-search.component.scss'],
})
export class DocumentSearchComponent implements OnInit {
    /**
     * @description Search data string
     * @author Jérémie Lopez
     * @type {string}
     * @memberof DocumentSearchComponent
     */
    public searchData?: string;

    /**
     * @description Filter data
     * @author Jérémie Lopez
     * @type {FilterParameter[]}
     * @memberof DocumentSearchComponent
     */
    @Input()
    public filterData: FilterParameter[] = [];

    /**
     * @description Sort data
     * @author Jérémie Lopez
     * @type {SortParameter[]}
     * @memberof DocumentSearchComponent
     */
    @Input()
    public sortData?: SortParameter;

    @Input()
    public isPropertyTab = false;

    /**
     * @description Societies list for filter modal component
     * @author Brisset Killian
     * @date 19/06/2024
     * @type {SocietyEntity[]}
     * @memberof DocumentSearchComponent
     */
    @Input()
    public societies: SocietyEntity[] = [];

    /**
     * @description Properties list for filter modal component
     * @author Brisset Killian
     * @date 19/06/2024
     * @type {PropertyEntity[]}
     * @memberof DocumentSearchComponent
     */
    @Input()
    public properties: PropertyEntity[] = [];

    /**
     * @description The type of document used, like for client, pro and importable, or pro and not importable,
     *  that will allow to display the correct type
     * @author ANDRE Felix
     * @type {DocumentTypeUsed}
     * @memberof DocumentSearchComponent
     */
    @Input() DocumentTypeUsed: DocumentTypeUsed = DocumentTypeUsed.client;

    /**
     * @description if type is free, user can search in an input of type text
     * @author ANDRE Felix
     * @memberof DocumentSearchComponent
     */
    @Input() isTypeFree = false;

    /**
     * @description Send data input
     * @author Jérémie Lopez
     * @memberof DocumentSearchComponent
     */
    @Output()
    public search = new EventEmitter<string>();

    /**
     * @description Send filter
     * @author Jérémie Lopez
     * @memberof DocumentSearchComponent
     */
    @Output()
    public filter = new EventEmitter<FilterParameter[]>();

    /**
     * @description Send sort
     * @author Jérémie Lopez
     * @memberof DocumentSearchComponent
     */
    @Output()
    public sort = new EventEmitter<SortParameter>();

    /**
     * @description Add document
     * @author Brisset Killian
     * @date 25/06/2024
     * @memberof DocumentSearchComponent
     */
    @Output()
    public add = new EventEmitter<void>();

    /**
     * @description If the add button is displayed in the search bar or not
     * @author Brisset Killian
     * @date 25/06/2024
     * @memberof DocumentListComponent
     */
    @Input()
    addButtonInSearchBar = false;

    public societyUID?: string;

    constructor(
        private modalController: ModalController,
        private propertyService: PropertyService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.societyUID = this.route.snapshot.params['societyUid'];
    }

    /**
     * @description Send data to parent component
     * @author Jérémie Lopez
     * @memberof DocumentSearchComponent
     */
    public sendSearch(): void {
        const input = this.searchData?.toLowerCase();
        this.search.emit(input);
    }

    /**
     * @description Open filter modal
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof DocumentSearchComponent
     */
    public async openFilter(): Promise<void> {
        const type = this.filterData.find((element) => element.where === 'type');
        const propertyUID = this.filterData.find((element) => element.where === 'propertyUID');
        const societyUID = this.filterData.find((element) => element.where === 'societyUID');
        const startDate = this.filterData.find((element) => element.where === 'startDate');
        const endDate = this.filterData.find((element) => element.where === 'endDate');
        // Open modal
        const modal = await this.modalController.create({
            component: DocumentFilterComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                type: type?.value ?? null,
                DocumentTypeUsed: this.DocumentTypeUsed,
                assetUID: propertyUID?.value ?? societyUID?.value ?? null,
                properties: this.properties,
                societies: this.societies,
                isPropertyTab: this.isPropertyTab,
                startDate: startDate ? startDate.value : null,
                endDate: endDate ? endDate.value : null,
                isTypeFree: this.isTypeFree,
            },
        });
        await modal.present();

        // Retrieve data from modal
        const { data } = await modal.onWillDismiss();

        if (data?.filter) {
            // Assign data to filterData
            this.filterData = data.filter;

            // Send filter event
            this.filter.emit(this.filterData);
        }
    }

    /**
     * @description Open sort modal
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof DocumentSearchComponent
     */
    public async openSort(): Promise<void> {
        // Open modal
        const modal = await this.modalController.create({
            component: DocumentSortComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                selectedOption: this.sortData ?? ({ where: 'name', order: 'asc' } as SortParameter),
            },
        });
        await modal.present();

        // Retrieve data from modal
        const { data } = await modal.onWillDismiss();
        if (data?.sort) {
            // Assign data to sortData
            this.sortData = data.sort;

            // Send sort event
            this.sort.emit(this.sortData);
        }
    }

    /**
     * @description Open add document
     * @author Brisset Killian
     * @date 25/06/2024
     * @memberof DocumentSearchComponent
     */
    public openAdd(): void {
        this.add.emit();
    }
}

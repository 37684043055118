import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { OmedomStep } from '@omedom/data';

@Component({
    selector: 'omedom-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements AfterViewInit, AfterViewChecked {
    /**
     * @description The steps of the stepper component. Each step is a OmedomStep object
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {OmedomStep[]}
     * @memberof StepperComponent
     */
    @Input({ required: true })
    public steps: OmedomStep[] = [];

    /**
     * @description The selected step of the stepper component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {OmedomStep}
     * @memberof StepperComponent
     */
    @Input()
    public selectedStep?: OmedomStep;

    /**
     * @description Event emitted when the selected step change
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @memberof StepperComponent
     */
    @Output()
    public selectedStepChange = new EventEmitter<OmedomStep>();

    /**
     * @description Event emitted when the save button is clicked
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @memberof StepperComponent
     */
    @Output()
    public onSave = new EventEmitter<void>();

    /**
     * @description The index of the selected step in the steps array of the stepper component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @memberof StepperComponent
     */
    public selectedStepIndex = 0;

    /**
     * @description The header of the stepper component. It is used to scroll the steps horizontally when there are too many steps to display at once
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @type {ElementRef<HTMLDivElement>}
     * @memberof StepperComponent
     */
    @ViewChild('header')
    public header?: ElementRef<HTMLDivElement>;

    /**
     * @description Enable mask at start of the scroll container
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @memberof StepperComponent
     */
    public enableLeftMask = false;

    /**
     * @description Enable mask at end of the scroll container
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @memberof StepperComponent
     */
    public enableRightMask = false;

    /**
     * @description Show spinner loading
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/05/2024
     * @memberof StepperComponent
     */
    @Input()
    public loading = false;

    ngAfterViewInit(): void {
        this.updateSelectedStep();
    }

    ngAfterViewChecked(): void {
        if (this.header) {
            this.checkScroll();
            this.header.nativeElement.onscroll = () => {
                this.checkScroll();
            };
        }
    }

    /**
     * @description Method called when the previous button is clicked. It updates the selected step to the previous one if it exists. If the selected step is the first one, nothing happens
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @memberof StepperComponent
     */
    public onPrevious(): void {
        if (this.selectedStepIndex > 0) {
            this.selectedStepIndex--;
            this.updateSelectedStep();
        }
    }

    /**
     * @description Method called when the next button is clicked. It updates the selected step to the next one if it exists. If the selected step is the last one, nothing happens
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @memberof StepperComponent
     */
    public onNext(): void {
        if (this.selectedStepIndex < this.steps.length - 1) {
            this.selectedStepIndex++;
            this.updateSelectedStep();
        }
    }

    /**
     * @description Method called when a step is clicked. It updates the selected step to the clicked one if it exists. If the clicked step is the selected one, nothing happens
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @param {number} index
     * @memberof StepperComponent
     */
    public onStep(index: number): void {
        // If the clicked step is the selected one, nothing happens
        if (this.selectedStepIndex === index) {
            return;
        }

        // Check if the clicked step exists
        if (!this.steps[index]) {
            return;
        }

        // Check if previous steps are valid and can be skipped
        if (index > this.selectedStepIndex) {
            for (let i = this.selectedStepIndex; i < index; i++) {
                const step = this.steps[i];

                if (step.canGoNext && !step.canGoNext()) {
                    return;
                }
            }
        }

        this.selectedStepIndex = index;
        this.updateSelectedStep();
    }

    /**
     * @description Method called when a step is clicked. It updates the selected step to the clicked one if it exists. If the clicked step is the selected one, nothing happens
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @private
     * @memberof StepperComponent
     */
    private updateSelectedStep(): void {
        this.selectedStep = this.steps[this.selectedStepIndex];

        // Check if the selected step is valid
        if (!this.selectedStep) {
            return;
        }

        // Emit the selected step change event
        this.selectedStepChange.next(this.selectedStep);

        // Get the selected step element
        const stepElement = document.getElementById(this.selectedStep?.id.toString() || '');

        // Scroll the header to the selected step
        if (this.header && stepElement) {
            this.header.nativeElement.scrollTo({
                left:
                    stepElement.offsetLeft -
                    this.header.nativeElement.clientWidth / 2 +
                    stepElement.clientWidth / 2,
                behavior: 'smooth',
            });
        }
    }

    /**
     * @description Check if the step selected is valid
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @param {number} index
     * @returns {boolean}
     * @memberof StepperComponent
     */
    public isValidatedStep(index: number): boolean {
        const isPreviousStep = index < this.selectedStepIndex;
        const step = this.steps[index];
        const canGoNext = step.canGoNext ? step.canGoNext() : true;

        return isPreviousStep && canGoNext;
    }

    /**
     * @description Check scroll container of the stepper to set a mask at start or end
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @private
     * @returns {void}
     * @memberof StepperComponent
     */
    private checkScroll(): void {
        // Check if the header exists
        if (!this.header) {
            return;
        }

        // Check if the header is scrollable
        const scrollLeft = this.header.nativeElement.scrollLeft;
        const scrollWidth = this.header.nativeElement.scrollWidth;

        // Update the masks
        this.enableLeftMask = scrollLeft > 0;
        this.enableRightMask =
            scrollLeft < scrollWidth - this.header.nativeElement.clientWidth &&
            scrollWidth > this.header.nativeElement.clientWidth;

        // Update the view
        this.header.nativeElement.classList.toggle(
            'masked-left',
            this.enableLeftMask && !this.enableRightMask
        );
        this.header.nativeElement.classList.toggle(
            'masked-right',
            this.enableRightMask && !this.enableLeftMask
        );
        this.header.nativeElement.classList.toggle(
            'masked-both',
            this.enableLeftMask && this.enableRightMask
        );
    }
}

import { Component } from '@angular/core';
import { OmedomRadioOption } from '../radio/omedom-radio-option';
import { ChargeUpdateType } from '@omedom/data';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'omedom-charge-edit-type',
    templateUrl: './charge-edit-type.component.html',
    styleUrls: ['./charge-edit-type.component.scss'],
})
export class ChargeEditTypeComponent {
    updateOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({
            id: ChargeUpdateType.onlyThisOne,
            label: 'Cette charge',
        }),
        new OmedomRadioOption({
            id: ChargeUpdateType.thisOneAndNext,
            label: 'Cette charge et les suivantes',
        }),
        new OmedomRadioOption({
            id: ChargeUpdateType.all,
            label: 'Toutes les charges de la série',
        }),
    ];

    selectedUpdateOption = null;

    constructor(private modalController: ModalController) {}

    async updateConfirmed(): Promise<void> {
        await this.modalController.dismiss(this.selectedUpdateOption);
    }

    async dismiss(): Promise<void> {
        await this.modalController.dismiss();
    }
}

<i class="uil-exclamation-triangle content-info-icon" [id]="popoverId"></i>
<ion-popover class="omedom-popover" [trigger]="popoverId">
    <ng-template>
        <div class="omedom-popover-container">
            <i class="uil-exclamation-triangle"></i>
            <div class="omedom-popover-content">
                {{ content }}
            </div>
        </div>
    </ng-template>
</ion-popover>

import { Component, Input } from '@angular/core';

@Component({
    selector: 'omedom-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
    @Input() step: number = 0;
}

import { BankStatusCode } from '../enums';
import { BankErrorMessage } from '../interfaces';

/**
 * @description Error message from the bank's API when the status code is different from 0 (BankStatusCode.OK)
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 22/04/2024
 * @export
 */
export const bankErrorMessages: BankErrorMessage[] = [
    {
        status: BankStatusCode.LoginFailed,
        message: 'Les identifiants de connexion sont incorrects et ne nous permettent pas de nous connecter à votre banque. Veuillez vérifier vos identifiants et réessayer.',
        actionEnabled: true,
        actionMessage: 'Modifier ma connexion'
    },
    {
        status: BankStatusCode.JustAdded,
        message: 'La connexion avec votre banque a été ajoutée récemment. La synchronisation est en cours. Veuillez patienter.',
        actionEnabled: false,
    },
    {
        status: BankStatusCode.CouldNotRefresh,
        message: 'Impossible de synchroniser cette banque, le site web de votre banque est actuellement inaccessible. Veuillez réessayer ultérieurement.',
        actionEnabled: true,
        actionMessage: 'Réessayer'
    },
    {
        status: BankStatusCode.DisabledTemporarily,
        message: 'Cette banque a été désactivée temporairement. La synchronisation reviendra très bientôt. Veuillez patienter.',
        actionEnabled: false,
    },
    {
        status: BankStatusCode.Incomplete,
        message: 'Votre banque nous a transmis des informations incomplètes. La synchronisation est en cours. Veuillez patienter.',
        actionEnabled: false,

    },
    {
        status: BankStatusCode.JustEdited,
        message: 'La connexion avec votre banque a été modifiée récemment. La synchronisation est en cours. Veuillez patienter.',
        actionEnabled: false,
    },
    {
        status: BankStatusCode.Migration,
        message: 'La connexion avec votre banque est en cours de migration. La synchronisation est en cours. Veuillez patienter.',
        actionEnabled: false,
    },
    {
        status: BankStatusCode.NeedsHumanAction,
        message: 'Votre banque nous demande une action de votre part. Veuillez vous connecter à votre espace client et valider les CGU de votre banque.',
        actionEnabled: false,
    },
    {
        status: BankStatusCode.NeedsManuelRefresh,
        message: 'Impossible de synchroniser cette banque, vous devez valider une double authentification.',
        actionEnabled: true,
        actionMessage: 'Valider la double authentification'
    },
    {
        status: BankStatusCode.NotSupported,
        message: 'Impossible de synchroniser cette banque, elle n\'est pas supportée par notre service.',
        actionEnabled: false,
    },
    {
        status: BankStatusCode.ProAccountLocked,
        message: 'Impossible de synchroniser cette banque, il s\'agit d\'un compte professionnel et nécessite une double authentification.',
        actionEnabled: true,
        actionMessage: 'Valider la double authentification'
    },
];

import { Component, Input } from '@angular/core';

@Component({
    selector: 'omedom-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent {
    /**
     * @description Date to display in the timeline component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @type {Date}
     * @memberof TimelineComponent
     */
    @Input({ required: true })
    public date!: Date;
}

<div class="container header-{{layout}}">
    <ng-container *ngTemplateOutlet="openingDate"></ng-container>
    <ng-container *ngTemplateOutlet="maturityDate"></ng-container>
    <ng-container *ngTemplateOutlet="interestRate"></ng-container>
</div>

<omedom-circular-graph
    @element
    label="Remboursement du crédit"
    [labelPosition]="layout === LoanDetailsLayout.MOBILE ? 'top' : 'right'"
    [data]="data"
    [showLegend]="true"
    [gap]="20"
></omedom-circular-graph>

<div class="container footer-{{layout}}">
    <ng-container *ngTemplateOutlet="guarantee"></ng-container>
    <ng-container *ngTemplateOutlet="assurance"></ng-container>
</div>

<!-- Opening Date -->
<ng-template #openingDate>
    <div class="info" @element>
        <span class="title">Date de souscription</span>
        <span class="value">{{ loan.openingDate | date:'dd/MM/yyyy' }}</span>
    </div>
</ng-template>

<!-- Maturity Date -->
<ng-template #maturityDate>
    <div class="info" @element>
        <span class="title">Dernière échéance</span>
        <span class="value">{{ loan.maturityDate | date:'dd/MM/yyyy' }}</span>
    </div>
</ng-template>

<!-- Interest Rate -->
<ng-template #interestRate>
    <div class="info priority" @element>
        <span class="title">Taux d'intérêt</span>
        <span class="value">{{ loan.interestRate || 0 | omedomNumber }} %</span>
    </div>
</ng-template>

<!-- Guarantee -->
<ng-template #guarantee>
    <div class="info" @element>
        <span class="title">Garantie</span>
        <span class="value" [class.undefined]="!loan.guarantee"
            >{{ loan.guarantee || 'Non renseignée' }}</span
        >
    </div>
</ng-template>

<!-- Assurance -->
<ng-template #assurance>
    <div class="info" @element>
        <span class="title">Assurance</span>
        <span class="value" [class.undefined]="!loan.assurance"
            >{{ loan.assurance || 'Non renseignée' }}</span
        >
    </div>
</ng-template>

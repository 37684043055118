import {
    CategoryInfo,
    ChargeCategoryInfo,
    ChargeCategoryProperty,
    ChargeEntity,
    ChargeListModel,
    ChargePeriodicity,
    ChargePeriodicityInfo,
    DateInterval,
    IncomeCategoryInfo,
    IncomeCategoryProperty,
    IncomeEntity,
    IncomeHistory,
    IncomeListModel,
    IncomePeriodicity,
    IncomePeriodicityInfo,
    PeriodicityInfo,
    PropertyEntity,
    SocietyEntity,
    Story,
    StoryOptionParams,
    StoryParams,
} from '@omedom/data';

import { OmedomTreasury } from './treasury';

/**
 * @description Utils for stories (charges and incomes) management
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 02/08/2023
 * @export
 * @class Story
 */
export class OmedomStory {
    /**
     * @description Transform charges and incomes to stories
     * @author Martin Bastie, Didier Pascarel
     * @static
     * @param {ChargeEntity[]} charges Charges to transform
     * @param {IncomeEntity[]} incomes Incomes to transform
     * @param {PropertyEntity[]} properties Properties linked to the charges and incomes
     * @param {boolean} onlyPayed If true, only payed charges and incomes will be returned
     * @param {boolean} [futureStories=false] If false, only past charges and incomes will be returned
     * @param {boolean} [notification=true] If true, only charges and incomes with notification will be returned
     * @param {Date} [startDate] Start date of the stories
     * @param {Date} [endDate] End date of the stories
     * @return {Story[]} Array of stories
     * @memberof Story
     * @example
     * const charges = await chargeService.search({ ... });
     * const incomes = await incomeService.search({ ... });
     * const properties = await propertyService.search({ ... });
     *
     * const stories = Story.getStories(charges, incomes, properties, true, false, true);
     */
    public static getStories(
        charges: ChargeEntity[],
        incomes: IncomeEntity[],
        properties: PropertyEntity[],
        onlyPayed: boolean,
        futureStories: boolean = false,
        notification: boolean = true,
        startDate?: Date,
        endDate?: Date
    ): Story[] {
        // If there is no charges and no incomes, return an empty array
        if (!charges && !incomes) {
            return [];
        }

        // Filter charges
        let storyChargesWithNotif = this.filterStories(
            charges,
            true,
            properties,
            onlyPayed,
            futureStories,
            notification,
            (category: string) => new ChargeCategoryInfo(category as ChargeCategoryProperty),
            (periodicity: string) => new ChargePeriodicityInfo(periodicity as ChargePeriodicity)
        );

        // Filter incomes
        let storyIncomesWithNotif = this.filterStories(
            incomes,
            false,
            properties,
            onlyPayed,
            futureStories,
            notification,
            (category: string) => new IncomeCategoryInfo(category as IncomeCategoryProperty),
            (periodicity: string) => new IncomePeriodicityInfo(periodicity as IncomePeriodicity)
        );
        // Filter by date if needed
        if (startDate && endDate) {
            storyChargesWithNotif = storyChargesWithNotif.filter(
                (x) => x.date >= startDate && x.date <= endDate
            );
            storyIncomesWithNotif = storyIncomesWithNotif.filter(
                (x) => x.date >= startDate && x.date <= endDate
            );
        }

        // Sort by date
        // If the date is the same, sort by isReaded
        // Return the sorted array
        return storyChargesWithNotif
            .concat(storyIncomesWithNotif)
            .sort(
                (a, b) =>
                    +a.isReaded - +b.isReaded ||
                    new Date(a.date).getTime() - new Date(b.date).getTime()
            );
    }

    /**
     * @description Filter charges or incomes to get stories
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @private
     * @static
     * @template T ChargeEntity or IncomeEntity
     * @param {T[]} entities Entities to filter
     * @param {boolean} isCharge If true, entities are charges, else entities are incomes
     * @param {PropertyEntity[]} properties Properties linked to the entities
     * @param {boolean} onlyPayed If true, only payed entities will be returned
     * @param {boolean} futureStories If false, only past  entities will be returned
     * @param {boolean} notification If true, only entities with notification will be returned
     * @param {((category: string) => CategoryInfo<any>)} getCategory Get category info
     * @param {((periodicity: string) => PeriodicityInfo<any>)} getPeriodicity Get periodicity info
     * @returns {Story[]} Array of stories
     * @memberof Story
     * @example
     * const charges = await chargeService.search({ ... });
     * const properties = await propertyService.search({ ... });
     * const stories = Story.filterStories(charges, true, properties, true, false, true);
     */
    private static filterStories<T extends ChargeEntity | IncomeEntity>(
        entities: T[],
        isCharge: boolean,
        properties: PropertyEntity[],
        onlyPayed: boolean,
        futureStories: boolean,
        notification: boolean,
        getCategory: (category: string) => CategoryInfo<any>,
        getPeriodicity: (periodicity: string) => PeriodicityInfo<any>
    ): Story[] {
        // Filter deleted entities
        entities = entities.filter((x) => !x.isDeleted);

        entities.forEach((entity) => {
            entity.history = entity.history?.filter((history) => !history.isDeleted);
        });

        // Filter entities that are payed unless onlyPayed is false
        // And filter entities that have notification unless notification is false

        return (
            entities
                .filter((x) => !onlyPayed || (x?.history?.some((h) => !h.isPayed) ?? !x?.isPayed))
                .map((treasury) => {
                    // Sort history by date
                    const sortedHistory = treasury?.history?.sort(
                        (a, b) =>
                            new Date(a.date.toDate()).getTime() -
                            new Date(b.date.toDate()).getTime()
                    );

                    // Get the last history
                    const history = onlyPayed
                        ? sortedHistory?.find((x) => !x.isPayed)
                        : !!sortedHistory?.length
                        ? sortedHistory[0]
                        : undefined;

                    // If we don't want future stories and there is no history, return undefined
                    if (
                        !futureStories &&
                        !history &&
                        treasury.periodicity !== (ChargePeriodicity || IncomePeriodicity).punctual
                    ) {
                        return undefined;
                    }

                    let date: Date;
                    let amount: number;

                    // If the periodicity is punctual, the date is the debit date and the amount is the amount of the treasury
                    // Else the date is the date of the history or the next history date and the amount is the amount of the month
                    if (treasury.periodicity === 'punctual') {
                        date =
                            treasury.debitDate?.toDate() ??
                            treasury.startDate?.toDate() ??
                            new Date();
                        amount = treasury.amount;
                    } else {
                        date = history
                            ? history.date.toDate()
                            : treasury.nextHistoryDate?.toDate() ?? new Date();
                        amount = OmedomTreasury.getMonthAmount(treasury, date);
                    }

                    // Get the property of the treasury
                    const property = properties?.find((p) => p.uid === treasury.propertyUID);
                    // Create the story
                    const storyModel = new Story({
                        amount,
                        isCharge,
                        uid: treasury.uid,
                        categoryInfo: getCategory(treasury.category),
                        periodicityInfo: getPeriodicity(treasury.periodicity),
                        propertyImg: property?.photo,
                        propertyName: property?.name,
                        ownerUID: property?.userUID,
                        withNotif: true,
                        isReaded:
                            treasury.periodicity ===
                            (ChargePeriodicity || IncomePeriodicity).punctual
                                ? treasury?.isReaded ?? false
                                : history?.isReaded,
                        isPayed:
                            treasury.periodicity ===
                            (ChargePeriodicity || IncomePeriodicity).punctual
                                ? treasury?.isPayed ?? false
                                : history?.isPayed,
                        isTransaction:
                            treasury.periodicity ===
                            (ChargePeriodicity || IncomePeriodicity).punctual
                                ? !!treasury.transactionID
                                : !!history?.transactionID,
                        designation: treasury.designation,
                        date,
                    });

                    // Set the isSentReceipt, isSentRelaunch and rentWithCharges if the treasury is a income rent
                    if (treasury.category === IncomeCategoryProperty.rent) {
                        storyModel.isSentReceipt =
                            treasury.periodicity === IncomePeriodicity.punctual
                                ? treasury?.isSentReceipt ?? false
                                : (history as IncomeHistory)?.isSentReceipt;
                        storyModel.isSentRelaunch =
                            treasury.periodicity === IncomePeriodicity.punctual
                                ? treasury?.isSentRelaunch ?? false
                                : (history as IncomeHistory)?.isSentRelaunch;
                        storyModel.rentWithCharges =
                            treasury.periodicity === IncomePeriodicity.punctual
                                ? treasury.rentWithCharges
                                : (history as IncomeHistory).rentWithCharges;
                    }

                    return storyModel;
                })
                // Filter undefined
                .filter((x) => x !== undefined) as Story[]
        );
    }

    /**
     * @todo Add type for entity
     * @description Transform a charge or an income to a story model
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @template T ChargeEntity or IncomeEntity
     * @param {T} data Entity to transform
     * @param {boolean} isCharge If true, entity is a charge, else entity is an income
     * @param {PropertyEntity} property Property linked to the entity
     * @returns {Story} Story model
     * @memberof Story
     * @example
     * const charge = await chargeService.get(...);
     * const income = await incomeService.get(...);
     * const property = await propertyService.get(...);
     *
     * const story = Story.transformTreasuryToStory(charge, true, property);
     * const story = Story.transformTreasuryToStory(income, false, property);
     */
    public static transformTreasuryToStory<T extends ChargeEntity | IncomeEntity>(
        data: T,
        isCharge: boolean,
        asset: PropertyEntity | SocietyEntity
    ): Story {
        // Get the periodicity and category info
        let periodicityInfo: PeriodicityInfo<any>;
        let categoryInfo: CategoryInfo<any>;

        // Get the date of the story
        const date = data.debitDate?.toDate() ?? data.startDate?.toDate() ?? new Date();

        // Get the periodicity and category info
        if (isCharge) {
            periodicityInfo = new ChargePeriodicityInfo(data.periodicity as ChargePeriodicity);
            categoryInfo = new ChargeCategoryInfo(data.category as ChargeCategoryProperty);
        } else {
            periodicityInfo = new IncomePeriodicityInfo(data.periodicity as IncomePeriodicity);
            categoryInfo = new IncomeCategoryInfo(data.category as IncomeCategoryProperty);
        }

        // Create the story
        const storyModel = new Story({
            amount: data.amount,
            isCharge,
            uid: data.uid,
            categoryInfo,
            periodicityInfo,
            propertyImg: asset?.photo,
            propertyName: asset?.name,
            ownerUID: asset?.userUID,
            isReaded: data.isReaded,
            isPayed: data.isPayed,
            withNotif: true,
            notes: data.notes,
            designation: data.designation,
            date,
            userUID: data.userUID,
        });

        // Set the isSentReceipt and isSentRelaunch if the treasury is an income rent
        if (data.category === IncomeCategoryProperty.rent) {
            storyModel.isSentReceipt = data.isSentReceipt;
            storyModel.isSentRelaunch = data.isSentRelaunch;
            storyModel.rentWithCharges = data.rentWithCharges;
        }

        // Return the story
        return storyModel;
    }
    /**
     * @description Transform a ChargeModelList or IncomeModelList into a Story
     * @author ANDRE Felix
     * @static
     * @param {ChargeListModel} data
     * @param {boolean} isCharge
     * @returns {*}  {Story}
     * @memberof OmedomStory
     */
    public static transformTreasuryModelListToStory(
        data: ChargeListModel | IncomeListModel,
        isCharge: boolean
    ): Story {
        // Get the date of the story
        const date = data.date;

        // Get the periodicity and category info
        const periodicityInfo = data.periodicityInfo;
        const categoryInfo = data.categoryInfo;

        // Create the story
        const storyModel = new Story({
            amount: data.amount,
            isCharge,
            uid: data.uid,
            categoryInfo,
            periodicityInfo,
            propertyImg: data.propertyImg,
            propertyName: data.propertyName,
            ownerUID: data.propertyOwnerUID,
            withNotif: data.withNotif,
            // isReaded: data.isReaded,
            isPayed: data.isPayed,
            notes: data.notes,
            designation: data.designation,
            date,
            userUID: data.userUID,
        });

        // Return the story
        return storyModel;
    }

    public static getNotifs(
        { charges, incomes, assets }: StoryParams,
        { payedTreasury, notPayedTreasury, futureStories, withNotificationOnly }: StoryOptionParams,
        { startDate, endDate }: DateInterval
    ): Story[] {
        let notifChargesStories: Story[] = this.filterNotifs(charges, true, assets, {
            startDate,
            endDate,
        });
        let notifIncomesStories: Story[] = this.filterNotifs(incomes, false, assets, {
            startDate,
            endDate,
        });

        // Concat charges and incomes notifs stories
        const notifStories = notifChargesStories.concat(notifIncomesStories);

        //Sort notifs stories by isReaded and date
        const sortedNotifStories = this.sortNotifsStories(notifStories);

        return sortedNotifStories;
    }

    private static filterNotifs<T extends ChargeEntity | IncomeEntity>(
        entities: T[],
        isCharge: boolean,
        assets: (PropertyEntity | SocietyEntity)[],

        { startDate, endDate }: DateInterval
    ): Story[] {
        // check if assets are PropertyEntity or SocietyEntity
        const isProperty = assets[0] instanceof PropertyEntity;
        const isSociety = assets[0] instanceof SocietyEntity;

        // Filter deleted entities and deleted histories
        entities = entities.filter((x) => !x.isDeleted);
        entities.forEach((entity) => {
            entity.history = entity.history?.filter((history) => !history.isDeleted);
        });

        // Filter entities exists in past and present
        entities = entities.filter((entity) => {
            if (entity.periodicity === (IncomePeriodicity || ChargePeriodicity).punctual) {
                const isPastOrPresent =
                    entity.debitDate !== undefined && entity.debitDate?.toDate() <= endDate;
                return isPastOrPresent;
            } else {
                const hasHistory =
                    entity.history?.length !== undefined && entity.history?.length > 0;
                return hasHistory;
            }
        });

        // Filter entities that are not payed
        entities = entities.filter((entity) => {
            if (entity.periodicity === (IncomePeriodicity || ChargePeriodicity).punctual) {
                return !entity.isPayed;
            } else {
                return entity.history?.some((h) => !h.isPayed);
            }
        });

        //Filter histories that not payed
        entities.forEach((entity) => {
            entity.history = entity.history?.filter((history) => !history.isPayed);
        });

        // Transform entities to stories
        const notifsStories: Story[] = [];
        entities.forEach((entity) => {
            const asset = assets?.find((asset) => {
                if (isProperty) {
                    return asset.uid === (entity as ChargeEntity | IncomeEntity).propertyUID;
                } else if (isSociety) {
                    return asset.uid === (entity as ChargeEntity | IncomeEntity).societyUID;
                } else {
                    return false;
                }
            });
            let categoryInfo: CategoryInfo<any>;
            let periodicityInfo: PeriodicityInfo<any>;

            if (isCharge) {
                categoryInfo = new ChargeCategoryInfo(entity.category as ChargeCategoryProperty);
                periodicityInfo = new ChargePeriodicityInfo(
                    entity.periodicity as ChargePeriodicity
                );
            } else {
                categoryInfo = new IncomeCategoryInfo(entity.category as IncomeCategoryProperty);
                periodicityInfo = new IncomePeriodicityInfo(
                    entity.periodicity as IncomePeriodicity
                );
            }

            // Create the story from punctual entity
            if (entity.periodicity === (IncomePeriodicity || ChargePeriodicity).punctual) {
                const notifStory = new Story({
                    ownerUID: entity.userUID,
                    uid: entity.uid,
                    categoryInfo,
                    periodicityInfo,
                    propertyImg: asset?.photo,
                    propertyName: asset?.name,
                    amount: entity.amount,
                    isCharge,
                    isReaded: entity.isReaded,
                    isPayed: entity.isPayed,
                    date: entity.debitDate?.toDate(),
                    withNotif: true,
                    userUID: entity.userUID,
                });
                // Set the isSentReceipt, isSentRelaunch and rentWithCharges if the entity is a income rent
                if (entity.category === IncomeCategoryProperty.rent) {
                    notifStory.isSentReceipt = (entity as IncomeEntity).isSentReceipt ?? false;
                    notifStory.isSentRelaunch = (entity as IncomeEntity).isSentRelaunch ?? false;
                    notifStory.rentWithCharges =
                        (entity as IncomeEntity).rentWithCharges ?? undefined;
                }
                notifsStories.push(notifStory);
            } else {
                entity.history?.forEach((history) => {
                    const notifStory = new Story({
                        ownerUID: entity.userUID,
                        uid: entity.uid,
                        categoryInfo,
                        periodicityInfo,
                        propertyImg: asset?.photo,
                        propertyName: asset?.name ?? '',
                        amount: history.amount,
                        isCharge,
                        isReaded: history.isReaded ?? false,
                        isPayed: history.isPayed ?? false,
                        date: history.date.toDate(),
                        withNotif: true,
                        userUID: entity.userUID,
                    });
                    // Set the isSentReceipt, isSentRelaunch and rentWithCharges if the treasury is a income rent
                    if (entity.category === IncomeCategoryProperty.rent) {
                        notifStory.isSentReceipt =
                            (history as IncomeHistory).isSentReceipt ?? false;
                        notifStory.isSentRelaunch =
                            (history as IncomeHistory).isSentRelaunch ?? false;
                        notifStory.rentWithCharges =
                            (history as IncomeHistory).rentWithCharges ?? undefined;
                    }
                    notifsStories.push(notifStory);
                });
            }
        });
        return notifsStories;
    }

    /**
     * @description Sort notifs stories by isReaded and date
     * @author Didier Pascarel
     * @private
     * @static
     * @param {Story[]} notifStories
     * @return {Story[]}
     * @memberof OmedomStory
     */
    private static sortNotifsStories(notifStories: Story[]): Story[] {
        // Sort by reader and not readed and by date
        return notifStories.sort(
            (a, b) =>
                +a.isReaded - +b.isReaded || new Date(a.date).getTime() - new Date(b.date).getTime()
        );
    }
}

import { Component, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { LICENCE_PRICE, LicenceEntity, ProEntity } from '@omedom/data';
import { LicenceService } from '@omedom/services';

@Component({
    selector: 'omedom-licence-order',
    templateUrl: './licence-order.component.html',
    styleUrls: ['./licence-order.component.scss'],
})
export class LicenceOrderComponent {
    /**
     * @description Pro data (default: undefined)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 15/11/2024
     * @type {ProEntity}
     * @memberof LicenceOrderComponent
     */
    @Input() public pro?: ProEntity;

    /**
     * @description Number of licences to order (default: 10)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 18/09/2023
     * @type {number}
     * @memberof LicenceOrderComponent
     */
    public numberOfLicences: number = 10;

    /**
     * @description Price of licences (from LICENCE_PRICE)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 25/10/2024
     * @memberof LicenceOrderComponent
     */
    public LICENCE_PRICE = LICENCE_PRICE;

    /**
     * @description Pending state of the order (default: false) (used to display a loader on the button when the order is in progress)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/09/2023
     * @type {boolean}
     * @memberof LicenceOrderComponent
     */
    public pending: boolean = false;

    constructor(
        private modalController: ModalController,
        private toastController: ToastController,
        private licenceService: LicenceService
    ) {}

    /**
     * @description True if the form is disabled
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/09/2023
     * @readonly
     * @type {boolean}
     * @memberof LicenceOrderComponent
     */
    public get isOrderDisabled(): boolean {
        return this.pending || this.numberOfLicences <= 0;
    }

    /**
     * @description Get the price of licences per month
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/09/2023
     * @readonly
     * @type {number}
     * @memberof LicenceOrderComponent
     */
    public get pricePerMonth(): number {
        return LicenceEntity.getPriceOfLicences(this.numberOfLicences, this.pro?.spaceType)
            .pricePerMonth;
    }

    /**
     * @description Get the price of licences per year (12 months)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/09/2023
     * @readonly
     * @type {number}
     * @memberof LicenceOrderComponent
     */
    public get pricePerYear(): number {
        return LicenceEntity.getPriceOfLicences(this.numberOfLicences, this.pro?.spaceType)
            .pricePerYear;
    }

    /**
     * @description Close the modal
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 18/09/2023
     * @returns {Promise<void>}
     * @memberof LicenceOrderComponent
     */
    public async close(): Promise<void> {
        await this.modalController.dismiss();
    }

    /**
     * @description Send the order to manager and close the modal
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/09/2023
     * @returns {Promise<void>}
     * @memberof LicenceOrderComponent
     */
    public async order(): Promise<void> {
        // Display a loader on the button
        this.pending = true;

        // Send the order to manager
        try {
            await this.licenceService.order(this.numberOfLicences);
        } catch (error) {
            // Reset the pending state
            this.pending = false;

            // Display a toast of the error
            const toast = await this.toastController.create({
                message: `Une erreur est survenue lors de la commande de ${this.numberOfLicences} licences.`,
                duration: 3000,
                color: 'danger',
                position: 'bottom',
            });

            await toast.present();

            return;
        }

        // Close the modal
        await this.modalController.dismiss();

        // Reset the pending state
        this.pending = false;

        // Display a toast to confirm the order
        const toast = await this.toastController.create({
            message: `Votre demande de ${this.numberOfLicences} licences a bien été prise en compte. Vous recevrez un email de confirmation avec les prochaines étapes, pour finaliser votre commande.`,
            duration: 6000,
            color: 'success',
            position: 'bottom',
        });

        await toast.present();
    }

    checkNumberOfLicences(numberOfLicences: number) {
        if (numberOfLicences > 200) {
            this.numberOfLicences = 200;
        } else if (numberOfLicences < 200 && numberOfLicences > 185) {
            this.numberOfLicences = 200;
        } else if (numberOfLicences < 100 && numberOfLicences > 90) {
            this.numberOfLicences = 100;
        } else if (numberOfLicences < 50 && numberOfLicences > 45) {
            this.numberOfLicences = 50;
        } else if (numberOfLicences < 0) {
            this.numberOfLicences = 0;
        }

        if (isNaN(numberOfLicences)) {
            this.numberOfLicences = 0;
        }
    }
}

import { Node } from '@kr0san89/ngx-graph';

import { AutomationActionType, AutomationNodeType } from '../../enums';
import { AutomationAction } from './';
import { AutomationCondition } from './automation-condition.interface';

/**
 * @description Node to display in a graph (start, action, condition, branch, end) to display in the chart or in a form
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 27/11/2023
 * @export
 * @interface AutomationNode
 * @extends {Node}
 */
export interface AutomationNode extends Node {
    data: {
        type: AutomationNodeType;
        action?: AutomationAction<AutomationActionType>;
        condition?: AutomationCondition;
    };
}

/**
 * @description Schedule of an automation (once, daily, weekly, monthly, yearly)
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 28/11/2023
 * @export
 * @enum {number}
 */
export enum AutomationSchedule {
    // hourly = 'HOURLY',
    once = 'ONCE',
    daily = 'DAILY',
    weekly = 'WEEKLY',
    monthly = 'MONTHLY',
    yearly = 'YEARLY',
}

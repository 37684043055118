import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SavingEntity } from '@omedom/data';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root',
})
export class SavingService extends RestService<SavingEntity> {
    protected override builder = SavingEntity;

    constructor(protected override firestore: AngularFirestore) {
        super(firestore, 'savings');
    }

    /**
     * @description Get savings of the user in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @param {string} userUID
     * @returns {Observable<SavingEntity[]>}
     * @memberof SavingService
     */
    public _getSavingsFromUser(userUID: string): Observable<SavingEntity[]> {
        return this._search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            },
        ]);
    }

    /**
     * @description Get savings of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @param {string} userUID
     * @returns {Promise<SavingEntity[]>}
     * @memberof SavingService
     */
    public getSavingsFromUser(userUID: string): Promise<SavingEntity[]> {
        return this.search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            },
        ]);
    }

    /**
     * @description Get savings of the user without accountID (manual savings) in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @param {string} userUID
     * @returns {Observable<SavingEntity[]>}
     * @memberof SavingService
     */
    public _getSavingsManualFromUser(userUID: string): Observable<SavingEntity[]> {
        return this._search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            },
        ]).pipe(
            map((savings) => {
                return savings.filter((saving) => !saving.accountID);
            })
        );
    }

    /**
     * @description Get savings of the user without accountID (manual savings)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @param {string} userUID
     * @returns {Promise<SavingEntity[]>}
     * @memberof SavingService
     */
    public getSavingsManualFromUser(userUID: string): Promise<SavingEntity[]> {
        return this.search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID,
            },
        ]).then((savings) => {
            return savings.filter((saving) => !saving.accountID);
        });
    }

    /**
     * @description Get saving capacity of a user by making a sum of deposit on the current year
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/09/2024
     * @param {string} userUID
     * @returns {Observable<number>}
     * @memberof SavingService
     */
    public _getCapacityFromUser(
        userUID: string,
        delta?: { start: Date; end: Date }
    ): Observable<number> {
        return this._getSavingsFromUser(userUID).pipe(
            switchMap((savings) => {
                // Check if savings exist
                if (savings.length === 0) {
                    return of(0);
                }

                // If delta is not defined, get the total capacity
                // Loop over savings
                // Get the sum of deposit
                if (!delta) {
                    // Init capacity
                    let capacity = 0;

                    for (const saving of savings) {
                        // Check if the saving has a deposit
                        if (saving.depositAmount) {
                            // Get the sum of deposit
                            capacity += saving.depositAmount || 0;

                            // Get the sum of withdraw
                            capacity -= saving.withdrawAmount || 0;
                        }
                    }

                    return of(capacity);
                }

                // Init subscriptions
                const subscriptions = [];

                // Get the capacity of the delta
                // Loop over savings
                // Get the sum of deposit in the delta
                for (const saving of savings) {
                    // Check if the saving has a deposit
                    const query = this.firestore
                        .collection('savings')
                        .doc(saving.uid)
                        .collection('capacities', (ref) => {
                            return ref
                                .where('date', '>=', delta.start)
                                .where('date', '<=', delta.end);
                        });

                    // Check if the query is empty
                    if (!query) {
                        const deposit = saving.depositAmount || 0;
                        const withdraw = saving.withdrawAmount || 0;

                        subscriptions.push(of(deposit - withdraw || 0));

                        continue;
                    }

                    // Get the capacities
                    // Loop over capacities
                    // Get the sum of deposit
                    subscriptions.push(
                        query.valueChanges().pipe(
                            map((capacities) => {
                                return capacities.reduce((acc, capacity) => {
                                    return acc + (capacity['amount'] || 0);
                                }, 0);
                            }),
                            catchError((error) => {
                                console.error("Can't get capacities for  ", saving.uid, error);
                                return of(0);
                            })
                        )
                    );
                }

                // Return the sum of capacities
                if (subscriptions.length === 0) {
                    return of(0);
                }

                return combineLatest(subscriptions).pipe(
                    map((capacities) => {
                        return capacities.reduce((acc, capacity) => {
                            return acc + capacity;
                        }, 0);
                    })
                );
            })
        );
    }

    public _getCapacityFromSavings(
        savings$: Observable<SavingEntity[]>,
        userUID: string,
        delta?: { start: Date; end: Date }
    ): Observable<number> {
        return savings$.pipe(
            switchMap((savings) => {
                // Check if savings exist
                if (savings.length === 0) {
                    return of(0);
                }

                // If delta is not defined, get the total capacity
                // Loop over savings
                // Get the sum of deposit
                if (!delta) {
                    // Init capacity
                    let capacity = 0;

                    for (const saving of savings) {
                        // Check if the saving has a deposit
                        if (saving.depositAmount) {
                            // Get the sum of deposit
                            capacity += saving.depositAmount || 0;

                            // Get the sum of withdraw
                            capacity -= saving.withdrawAmount || 0;
                        }
                    }

                    return of(capacity);
                }

                // Init subscriptions
                const subscriptions = [];

                // Get the capacity of the delta
                // Loop over savings
                // Get the sum of deposit in the delta
                for (const saving of savings) {
                    // Check if the saving has a deposit
                    const query = this.firestore
                        .collection('savings')
                        .doc(saving.uid)
                        .collection('capacities', (ref) => {
                            return ref
                                .where('date', '>=', delta.start)
                                .where('date', '<=', delta.end);
                        });

                    // Check if the query is empty
                    if (!query) {
                        const deposit = saving.depositAmount || 0;
                        const withdraw = saving.withdrawAmount || 0;

                        subscriptions.push(of(deposit - withdraw || 0));

                        continue;
                    }

                    // Get the capacities
                    // Loop over capacities
                    // Get the sum of deposit
                    subscriptions.push(
                        query.valueChanges().pipe(
                            map((capacities) => {
                                return capacities.reduce((acc, capacity) => {
                                    return acc + (capacity['amount'] || 0);
                                }, 0);
                            }),
                            catchError((error) => {
                                console.error("Can't get capacities for  ", saving.uid, error);
                                return of(0);
                            })
                        )
                    );
                }

                // Return the sum of capacities
                if (subscriptions.length === 0) {
                    return of(0);
                }

                return combineLatest(subscriptions).pipe(
                    map((capacities) => {
                        return capacities.reduce((acc, capacity) => {
                            return acc + capacity;
                        }, 0);
                    })
                );
            })
        );
    }
}

import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Story } from '@omedom/data';

@Component({
    selector: 'omedom-story-card',
    templateUrl: './story-card.component.html',
    styleUrls: ['./story-card.component.scss'],
})
export class StoryCardComponent {
    /**
     * @description Story to display
     * @author Brisset Killian
     * @date 02/05/2024
     * @type {Story}
     * @memberof StoryCardComponent
     */
    @Input() story?: Story;

    /**
     * @description Icon to display on the card, (depends on assets type)
     * @author Brisset Killian
     * @date 02/05/2024
     * @type {string}
     * @memberof StoryCardComponent
     */
    @Input() icon?: string;

    /**
     * @description Event emitted when the card is clicked
     * @author Brisset Killian
     * @date 02/05/2024
     * @memberof StoryCardComponent
     */
    @Output() storyClicked = new EventEmitter();

    /**
     * @description Emit the storyClicked event when the card is clicked
     * @author Brisset Killian
     * @date 02/05/2024
     * @memberof StoryCardComponent
     */
    @HostListener('click')
    onClick(): void {
        this.storyClicked.next(null);
    }
}

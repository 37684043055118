import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { IonPopover } from '@ionic/angular';
import {
    PropertiesFilter,
    PropertyEntity,
    SelectOption,
    SocietyEntity,
    startDateTarification,
    UserEntity,
} from '@omedom/data';
import { PropertyService, SocietyService, UserService } from '@omedom/services';
import { BehaviorSubject, Subscription } from 'rxjs';

import { SelectComponent } from '../select/select.component';

@Component({
    selector: 'omedom-asset-filter',
    templateUrl: './asset-filter.component.html',
    styleUrls: ['./asset-filter.component.scss'],
})
export class AssetFilterComponent implements OnChanges {
    @Input() disabled = false;

    /**
     * @description Display filter icon if authorized by user subscription plan
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 18/06/2024
     * @memberof AssetFilterComponent
     */
    @Input() canFilter = false;

    @Output() update = new EventEmitter();

    @Input() showLabel = false;

    /**
     * @description Put icon filter to left and add text, user in desktop
     * @author ANDRE Felix
     * @memberof AssetFilterComponent
     */
    @Input() iconFilterToLeft = false;

    isOpen = false;

    /**
     * @description controls popover for properties
     * @author Hanane Djeddal
     * @memberof TreasuryBase
     */
    @ViewChild('popover') popover!: IonPopover;

    @ViewChild('smartPopover') smartPopover!: IonPopover;

    /**
     * @description the listing of properties for filtering
     * @author Hanane Djeddal
     * @type {SelectComponent}
     * @memberof TreasuryBase
     */
    @ViewChild('selectApp') selectApp!: SelectComponent;

    selectedAssets: SelectOption[] = [];

    propertyOptions$ = new BehaviorSubject<SelectOption[]>([]);

    subjectPlaceholder = { label: 'Vos biens' } as SelectOption;

    allPropertiesOptions: SelectOption[] = [];

    private subscription!: Subscription;

    private currentUser!: UserEntity;

    @Input() properties: PropertyEntity[] = [];

    @Input() societies: SocietyEntity[] = [];

    /**
     * @description If the date is before the transition date (2024-03-01) or not
     * @author Brisset Killain
     * @type {boolean}
     * @memberof PropertyListPage
     */
    protected isBeforeTransitionDate = false;

    constructor(
        private userService: UserService,
        private propertyService: PropertyService,
        private societyService: SocietyService
    ) {}

    async ngOnChanges() {
        this.selectedAssets = PropertiesFilter.filteredProperties;

        this.subscription = this.userService.user$.subscribe(async (user) => {
            this.currentUser = user;
            const propertieOptions = this.propertyService.getPropertiesOptions(this.properties);
            const societiesOptions = this.societyService.getSocietiesOptions(this.societies);
            const propertieOptionsFiltered = propertieOptions.filter((x) => x.isAccesible);
            const propertiesUids = propertieOptions.map((x) => x.id);
            const societiesUids = societiesOptions.map((x) => x.id);
            this.selectedAssets = this.selectedAssets.filter(
                (x) => propertiesUids.includes(x.id) || societiesUids.includes(x.id)
            );

            this.updateFilter();

            const selectedUids = this.selectedAssets.map((x) => x.id);

            this.allPropertiesOptions = [...propertieOptionsFiltered, ...societiesOptions];

            this.propertyOptions$.next(this.allPropertiesOptions);

            this.propertyOptions$.forEach((y) =>
                y.forEach((x) => {
                    if (selectedUids.includes(x.id)) {
                        x.isSelected = true;
                    } else {
                        x.isSelected = false;
                    }
                })
            );

            const transitionDate = new Date(startDateTarification);
            const today = new Date();
            this.isBeforeTransitionDate = today.getTime() < transitionDate.getTime();
        });
    }
    /**
     * @description event when property is selected from filter
     * @param property
     */
    async propertySelected(property: SelectOption) {
        this.popover?.dismiss();
        if (
            this.canFilter &&
            !this.disabled &&
            (property.isAccesible || this.isBeforeTransitionDate)
        ) {
            const index = this.selectedAssets.findIndex((element) => element.id === property.id);
            if (index >= 0) {
                this.selectedAssets.splice(index, 1);

                this.propertyOptions$.forEach((y) =>
                    y.forEach((x) => {
                        if (x.id === property.id) {
                            x.isSelected = false;
                        }
                    })
                );
            } else {
                this.selectedAssets.push(property);

                this.propertyOptions$.forEach((y) =>
                    y.forEach((x) => {
                        if (x.id === property.id) {
                            x.isSelected = true;
                        }
                    })
                );
            }
            PropertiesFilter.updateProperties(this.selectedAssets);
            this.update.emit();
        }
    }
    updateFilter() {
        PropertiesFilter.updateProperties(this.selectedAssets);
        this.update.emit();
    }
    /**
     * @description allow to open the select-app via the filter icon
     */
    openSelect(): void {
        // TODO filter is not working, i disabled it
        // this.selectApp.onClick();
    }
    presentSmartPopover(e: Event) {
        if (!this.canFilter) {
            this.smartPopover.event = e;
            this.isOpen = true;
        }
    }
}

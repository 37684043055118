<div class="bank-account-select-header" (click)="toggle()">
    <span class="bank-account-select-header-title">{{
        selectedAccount?.name ?? 'Sélectionner un compte'
    }}</span>
    <i
        class="bank-account-select-header-icon uil uil-angle-down"
        [class.rotate]="opened$ | async"
    ></i>
</div>

<div
    class="bank-account-select-content"
    [@list]="(opened$ | async) ? accounts.length : 1"
>
    <ng-container
        [ngTemplateOutlet]="(opened$ | async) ? open : close"
    ></ng-container>
</div>

<ng-template #close>
    <span class="bank-account-select-content-balance" (click)="toggle()"
        >{{ selectedAccount?.balance ?? '--,--' | omedomNumber }} €</span
    >
</ng-template>

<ng-template #open>
    @for (account of accounts; track account.bridgeID) {
    <div class="bank-account" (click)="select(account)">
        <h2 class="bank-account-name">{{ account.name }}</h2>
        <span class="bank-account-balance"
            >{{ account.balance | omedomNumber }} €</span
        >
    </div>
    }
</ng-template>

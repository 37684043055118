<div class="omedom-modal">
    <div class="omedom-modal-header">
        <div class="omedom-modal-header-title">
            <i
                class="uil uil-calendar-alt color-light-black background-color-light-grey"
            ></i>
            <h3>Gestion d'abonnement</h3>
        </div>
        <i class="uil uil-times-circle" (click)="dismiss()"></i>
    </div>
    <div class="omedom-modal-content">
        <p>Pour gérer votre abonnement, rendez-vous sur votre compte Apple.</p>

        <div class="omedom-modal-buttons">
            <button class="omedom-button-primary" (click)="dismiss()">
                Fermer
            </button>
        </div>
    </div>
</div>

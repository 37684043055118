<div class="omedom-page-container-content">
    <form #form="ngForm" class="w-100 flex-1" @element>
        <omedom-select
            [required]="true"
            label="Objet"
            name="objet"
            [(ngModel)]="subject"
            [placeholder]="subjectPlaceholder"
            [options]="(subjectOptions$ | async) ?? []"
            [disabled]="false"
        ></omedom-select>

        <omedom-input
            class="textarea"
            placeholder="Expliquez nous le bug que vous avez rencontré."
            label="Texte"
            name="texte"
            [required]="true"
            [(ngModel)]="message"
        ></omedom-input>
    </form>
    <div class="form-stepper-button-container">
        <button
            class="flex-1 omedom-button omedom-button-secondary"
            (click)="
                existClicked(
                    'Retour d’expérience utilisateur',
                    'uil uil-constructor',
                    null
                )
            "
        >
            Annuler
        </button>

        <button
            class="flex-1 omedom-button omedom-button-primary"
            (click)="submit()"
            [disabled]="form.invalid"
        >
            <i class="uil uil-envelope-upload"></i>
            Envoyer
        </button>
    </div>
</div>

@if (!selectedForm?.id) {
<div class="content layout-{{ layout }}" [style.width]="'100%'">
    @if (hasSecondaryForms) {
    <div class="button-list" @list>
        @for (formItem of secondaryForms; track formItem.id) { @if
        (!formItem.disabled) {
        <button class="omedom-button-toggle" (click)="selectForm(formItem.id)">
            <i class="omedom-button-toggle-icon uil {{ formItem.icon }}"></i>
            <span class="omedom-button-toggle-title">
                <span>{{ formItem.label }}</span>
                <span>
                    @if (formItem.isLocked$ | async) {
                    <i class="omedom-icon-crown"></i>
                    }</span
                >
            </span>
        </button>
        } }
    </div>

    <div class="horizontal-bar"></div>
    }
    <div class="button-list" @list>
        @for (formItem of primaryForms; track formItem.id) { @if
        (!formItem.disabled) {
        <button class="omedom-button-toggle" (click)="selectForm(formItem.id)">
            @if (!formItem.isOmedomIcon) {
            <i class="omedom-button-toggle-icon uil {{ formItem.icon }}"></i>
            } @if (formItem.isOmedomIcon) {
            <div class="omedom-button-toggle-icon">
                @if (formItem.isOmedomIcon) {
                <div class="form-icon"></div>
                }
            </div>
            }
            <span class="omedom-button-toggle-title">
                <span>{{ formItem.label }}</span>
                <span>
                    @if (formItem.isLocked$ | async) {
                    <i class="omedom-icon-crown"></i>
                    }</span
                >
            </span>
        </button>
        } }
    </div>
</div>
}

@if (!isWaitingData) {
  <div class="footer w-100 margin-top-2 margin-bottom-2">
    @if (!canUpdateStory) {
      <div class="italic">
        Vous n'avez pas les droits pour valider
        {{ isCharge ? ' cette charge' : ' ce revenu' }}
      </div>
    } @else {
      @if (isDateBeforeToday && !isBankingMovment && canUpdateStory) {
        <div class="margin-bottom-2">
          <b>
            {{
            isCharge
            ? 'Cette charge est-elle bien passée ?'
            : 'Ce revenu est-il bien passé ?'
            }}
          </b>
        </div>
        <div class="footer-actions">
          <button
            class="omedom-button-secondary flex-1 margin-right-1"
            (click)="storyNotValidateClick.emit()"
            >
            <i class="uil uil-times-circle"></i>
            Non
          </button>
          <button
            class="omedom-button-primary flex-1 margin-left-1"
            (click)="storyValidateClick.emit()"
            >
            <i class="uil uil-check-circle"></i>
            Oui
          </button>
        </div>
      }
      @if (isDateBeforeToday && isBankingMovment) {
        <div>
          <div class="margin-bottom-2">
            <i class="uil uil-link-alt margin-right-1"></i>
            <span>Liaison bancaire activée</span>
          </div>
          <span class="italic">
            {{
            isCharge
            ? 'Cette charge a bien été prélevée'
            : 'Ce revenue a bien été encaissé'
            }}
          </span>
        </div>
      }
      @if (!isDateBeforeToday && isBankingMovment) {
        <div class="margin-bottom-2">
          <i class="uil uil-link-alt margin-right-1"></i>
          <span>Liaison bancaire activée</span>
        </div>
        <span class="italic">
          * Attendre la date
          {{ isCharge ? 'de prélèvement' : "d'encaissement" }}
        </span>
      }
      @if (!isDateBeforeToday && !isBankingMovment) {
        <span class="italic">
          * Attendre la date
          {{ isCharge ? 'de prélèvement' : "d'encaissement" }} pour
          valider
        </span>
      }
    }
  </div>
}

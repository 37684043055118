import { Sharing } from './property.entity';
import { RestEntity } from './rest.entity';

export class FamilyEntity extends RestEntity {
    /**
     * @description User who's the family is assigned
     * @author Vidjay Seganti (Keleo)
     * @type {string}
     * @memberof FamilyEntity
     */
    public userUID!: string;

    /**
     * @description List of uid of the family members
     * @author Jérémie Lopez
     * @type {string[]}
     * @memberof FamilyEntity
     */
    public familyMembers: string[] = [];

    /**
     * @description List of emails names and first names of invited contacts to join the family
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 05/07/2024
     * @type {Sharing[]}
     * @memberof FamilyEntity
     */
    public invitations: Sharing[] = [];
}

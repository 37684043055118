import { Component, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { LicenceEntity } from '@omedom/data';
import { LicenceService } from '@omedom/services';

@Component({
    selector: 'omedom-licence-cancel',
    templateUrl: './licence-cancel.component.html',
    styleUrls: ['./licence-cancel.component.scss'],
})
export class LicenceCancelComponent {
    /**
     * @description Licence to cancel
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/02/2024
     * @type {LicenceEntity}
     * @memberof LicenceCancelComponent
     */
    @Input({ required: true })
    public licence!: LicenceEntity;

    /**
     * @description Pending state (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/02/2024
     * @memberof LicenceCancelComponent
     */
    public pending = false;

    constructor(
        private readonly modalController: ModalController,
        private readonly toastController: ToastController,
        private readonly licenceService: LicenceService
    ) { }

    /**
     * @description Close the modal without any action
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/02/2024
     * @returns {Promise<void>}
     * @memberof LicenceCancelComponent
     */
    public async close(): Promise<void> {
        await this.modalController.dismiss();
    }

    /**
     * @description Cancel the licence and close the modal if the action is successful, show an error message otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/02/2024
     * @returns {*}  {Promise<void>}
     * @memberof LicenceCancelComponent
     */
    public async cancel(): Promise<void> {
        // Set the pending state to true
        this.pending = true;

        // Try to cancel the licence
        try {
            // Cancel the licence
            await this.licenceService.cancel(this.licence);

            // Close the modal
            await this.modalController.dismiss();

            // Show a success message
            const toast = await this.toastController.create({
                message: 'La licence a bien été annulée, vous avez été recrédité(e) de 1 licence disponible.',
                duration: 3000,
                color: 'success',
            });

            toast.present();
        } catch (error) {
            // Show an error message
            const toast = await this.toastController.create({
                message: 'Une erreur est survenue',
                duration: 3000,
                color: 'danger',
            });

            toast.present();
        } finally {
            // Set the pending state to false
            this.pending = false;
        }
    }
}

import { FormControl } from '@angular/forms';

/**
 * @description Saving exit constraints form type
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 09/07/2024
 * @type {SavingExitConstraintsFormType}
 */
export type SavingExitConstraintsFormType = {
    /**
     * @description Minimum date to exit the saving account (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {(FormControl<Date | undefined>)}
     */
    minimumDate: FormControl<Date | undefined>,

    /**
     * @description Other constraint for the saving exit (if any) for the saving account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @type {(FormControl<string | undefined>)}
     */
    otherConstraint: FormControl<string | undefined>,
};

<div class="omedom-page-container">
    @if (modalLabel) {
    <h2>{{ modalLabel }}</h2>
    }
    <div
        class="omedom-page-container-content flex-1 margin-top-1 padding-bottom-1"
    >
        @if (emptyTreasury) {
        <omedom-info
            @element
            iconStart="uil uil-chart-pie-alt"
            information="Vous n'avez pas encore enregistré de charges pour ce mois."
            class="w-100 padding-2"
        ></omedom-info>
        } @for (chargeByDay of treasuryByDay; track $index) {
        <div class="w-100" [@list]="treasuryByDay.length">
            <p class="margin-0 margin-bottom-2">
                {{ chargeByDay.day | date : 'dd/MM/YYYY' }}
            </p>
            <div
                class="charges-container padding-left-1 margin-bottom-2 w-100"
                [@transaction]="chargeByDay.treasury.length"
            >
                @for (charge of chargeByDay.treasury; track $index) {
                <omedom-treasury-card
                    [treasury]="charge"
                    [avatar]="avatar"
                    [currentUserUID]="user?.uid"
                    [image]="charge.propertyImg"
                    [name]="charge.propertyName"
                    [icon]="getChargeInfo(charge.propertyUID).icon"
                    [isCharge]="true"
                    (treasuryClicked)="chargeClicked(charge)"
                ></omedom-treasury-card>
                }
            </div>
        </div>
        }
        <div class="omedom-sticky-button-container w-100 flex-1 flex-end">
            @if (currentDate) {
            <omedom-date
                class="flex-1"
                [type]="omedomDateType.month"
                [date]="currentDate"
                (dateChange)="dateChange($event)"
            ></omedom-date>
            } @if (canCreateCharge) {
            <button
                class="omedom-icon-button-primary margin-left-2"
                (click)="addClicked()"
            >
                <i class="uil uil-plus"></i>
            </button>
            }
        </div>
    </div>
</div>

<ng-template #defaultAvatar>
    <div class="header-icon">
        <i class="uil uil-home"></i>
    </div>
</ng-template>

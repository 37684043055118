import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { BankAccountType } from '@omedom/data';

export interface AccountDataInterface {
    id: string;
    account: {
        amount: string;
        accountType: BankAccountType;
        name: string;
        nameColor: string;
        amountColor: string;
    }
    bank: {
        logoUrl?: string;
        name: string;
        color: string;
    }
}

export interface GlobalAmountInterface {
    value: string;
    color: string;
}

/**
 * @description Layout of the progress bar widget (default, dashboard or mobile)
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 23/09/2024
 * @export
 * @enum {number}
 */
export enum BankingOverviewLayout {
    DEFAULT = 'default',
    DASHBOARD = 'dashboard',
    MOBILE = 'mobile',
}

@Component({
    selector: 'omedom-widget-banking-overview',
    templateUrl: './widget-banking-overview.component.html',
    styleUrls: ['./widget-banking-overview.component.scss'],
})
export class WidgetBankingOverviewComponent implements OnInit {

    /**
     * @description Layout of the progress bar widget (default, dashboard or mobile)
     * @type {ProgressBarCardLayout}
     * @memberof WidgetProgressBarComponent
     */
    @Input() layout: BankingOverviewLayout = BankingOverviewLayout.DEFAULT;

    /**
     * @description Title of the progress bar widget
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/09/2024
     * @memberof WidgetProgressBarComponent
     */
    @Input() title = '';

    /**
     * @description Icon of the progress bar widget
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/09/2024
     * @type {string}
     * @memberof WidgetProgressBarComponent
     */
    @Input() icon?: string;
    @Input() accountType: BankAccountType = BankAccountType.Checking;
    @Input() accountsData: AccountDataInterface[] = [];
    @Input() globalAmountData: GlobalAmountInterface | undefined;

    protected globalAmount = '';


    protected bankAccountType = BankAccountType;

    constructor(
        private readonly ref: ElementRef,
    ) { }

    ngOnInit() {
        // Add the layout class to ref
        this.ref.nativeElement.classList.add(`layout-${this.layout}`);
    }
}

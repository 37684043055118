declare global {
    interface Array<T> {
        sumBy(selector: (x: T) => any): number;

        sum(): number;

        equals(array: Array<T>): boolean;
    }
}

Array.prototype.equals = function(array) {
    // if the other array is a falsy value, return
    if (!array) { return false; }

    // compare lengths - can save a lot of time
    if (this.length !== array.length) { return false; }

    for (let i = 0, l = this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i])) { return false; }
        } else if (this[i] !== array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
};

Array.prototype.sumBy = function <T>(selector: (x: T) => any): number {
    if (!this || !this.length) {
        return 0;
    }

    return this.map(selector).sum();
};

Array.prototype.sum = function(): number {
    if (!this || !this.length) {
        return 0;
    }

    return this.reduce((sum, x) => sum + x, 0);
};

export { };

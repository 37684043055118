@if (!(isAssimilateHouse || isAssimilateAppartement)) {
<omedom-info
    iconStart="uil uil-exclamation-triangle"
    information="La valorisation d'un bien est disponible uniquement pour les biens de type maison, appartement, immeuble, hôtel et chalet"
></omedom-info>
} @if (canUsePricehubble && canStartValuation) {
<div class="actions flex-1 margin-y-2">
    <button
        class="omedom-button-primary flex-1 w-100"
        (click)="refreshPricehubble()"
    >
        <i class="uil uil-chart-line"></i>
        <span>
            {{
                hasValorisation
                    ? 'Rafraîchir la valorisation'
                    : 'Commencer la valorisation'
            }}
        </span>
    </button>
</div>
} @if (propertyValuation && canAccessValuation && canUsePricehubble) {
<div>
    <div class="valo-title margin-bottom-2">Estimation du bien</div>
    <div class="valuation-container margin-bottom-2">
        <div class="valuation-price margin-right-2">
            <div class="margin-bottom-1">Valeur estimée</div>
            <span>{{ propertyValuation?.value | number }} €</span>
            <span class="meter-price"
                >{{ meterSquarePriceAvg | number }} € / m<sup>2</sup></span
            >
        </div>
        <div
            class="valuation-price valuation-interval"
            style="font-weight: 400"
        >
            <div class="margin-bottom-1">Fourchette</div>
            <span
                >{{ propertyValuation.valueRange.lower | number }} -
                {{ propertyValuation.valueRange.upper | number }}
                €</span
            >
            <span class="meter-price"
                >{{ meterSquarePriceLower | number }} -
                {{ meterSquarePriceUpper | number }} € / m<sup>2</sup></span
            >
        </div>
    </div>
    <div class="pertinance-dpe-information margin-top-1">
        Dernière mise à jour :
        {{ lastValuationUpdate }}. Indice de confiance de l'estimation :
        {{ valuationConfidence }}
    </div>
    <hr class="omedom-divider" />
</div>
} @if (dpeRating && canAccessValuation && canUsePricehubble) {
<omedom-dpe-interface [rating]="dpeRating"></omedom-dpe-interface>
} @if ( (propertyRenovations || []).length > 0 && canAccessValuation &&
canUsePricehubble ) {
<hr class="omedom-divider" />
<omedom-info
    class="margin-bottom-2"
    information="Si vous avez renseigné une note DPE dans les informations de votre bien, l’estimation des coûts de rénovation sera basée sur celle-ci"
></omedom-info>
<div class="valo-title margin-bottom-2">
    Estimation des coûts de rénovation énergétique
</div>
<div class="dpe margin-bottom-2 grid-table">
    <div></div>
    <div class="header">Coût de rénovation</div>
    <div class="header">Impact sur la valeur du bien</div>
    @for (renovation of propertyRenovations; track $index) {
    <ng-container class="dpe margin-bottom-2">
        <div>
            @if (currentEnergyLabel) {
            <omedom-dpe-score [score]="currentEnergyLabel"></omedom-dpe-score>
            }
            <i class="uil uil-arrow-right"></i>
            <omedom-dpe-score
                [score]="renovation.energyLabelRenovation"
                class="margin-left-1"
            ></omedom-dpe-score>
        </div>
        <div class="max-width">
            <omedom-display-range-price
                [valueRange]="renovation.renovationCosts"
            ></omedom-display-range-price>
        </div>
        <div class="max-width">
            <omedom-display-range-price
                [valueRange]="renovation.salePriceDifference"
                [withPlus]="true"
            ></omedom-display-range-price>
        </div>
    </ng-container>
    }
</div>
@for (renovation of propertyRenovations; track $index) {
<div class="dpe margin-bottom-2"></div>
} } @if ((!propertyValuation && canUsePricehubble) || !canAccessValuation) {
<omedom-info
    iconStart="uil uil-info-circle"
    information="Pour obtenir une estimation de votre bien, veuillez commencer la valorisation"
></omedom-info>
}

import { Confidence, ConfidenceFr, PropertyEntity, PropertyType, UseProperty } from '@omedom/data';

export class OmedomProperty {
    public static excludeResidentialProperties(properties: PropertyEntity[]) {
        const rentalProperties = (properties ?? []).filter((p) => {
            const rentalUses = [
                UseProperty.locatifCommercial,
                UseProperty.locatifParticulier,
                UseProperty.locatifPro,
            ];

            const uses = p?.purchaseDetails?.use ?? [];

            const isRental = Array.isArray(uses)
                ? uses.some((use) => rentalUses.includes(use))
                : rentalUses.includes(uses);

            return isRental;
        });

        return rentalProperties;
    }

    public static excludeRentalProperties(properties: PropertyEntity[]) {
        const residencyProperties = (properties ?? []).filter((p) => {
            const residencyUses = [UseProperty.principale, UseProperty.secondaire];

            const uses = p?.purchaseDetails?.use ?? [];

            const isResidency = Array.isArray(uses)
                ? uses.some((use) => residencyUses.includes(use))
                : residencyUses.includes(uses);

            return isResidency;
        });

        return residencyProperties;
    }

    public static isRentalProperty(property: PropertyEntity) {
        const rentalUses = [
            UseProperty.locatifCommercial,
            UseProperty.locatifParticulier,
            UseProperty.locatifPro,
        ];

        const uses = property?.purchaseDetails?.use ?? [];

        const isRental = Array.isArray(uses)
            ? uses.some((use) => rentalUses.includes(use))
            : rentalUses.includes(uses);

        return isRental;
    }
    public static adaptConfidence(confidence: Confidence | undefined) {
        switch (confidence) {
            case Confidence.good:
                return ConfidenceFr.good;
            case Confidence.medium:
                return ConfidenceFr.mefium;
            case Confidence.poor:
                return ConfidenceFr.poor;
            default:
                return confidence;
        }
    }
    public static adaptConfidenceTextColor(confidence: Confidence | undefined) {
        switch (confidence) {
            case Confidence.good:
                return '#00c29a';
            case Confidence.medium:
                return '#ffbe00';
            case Confidence.poor:
                return '#cd493a';
            default:
                return confidence;
        }
    }

    public static CanHaveFloor(propertyType: PropertyType | undefined) {
        if (
            propertyType === PropertyType.other ||
            propertyType === PropertyType.terrain ||
            propertyType === PropertyType.parkingBox
        ) {
            return false;
        }
        return true;
    }
}

import { Component, ElementRef } from '@angular/core';
import { gsap } from 'gsap';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';

@Component({
    selector: 'omedom-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    host: {
        role: 'navigation',
    }
})
export class SidenavComponent {
    /**
     * @description Is opened sidenav subject (default: true)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/09/2023
     * @memberof SidenavComponent
     */
    public isOpened = new BehaviorSubject<boolean>(true);

    constructor(
        private elementRef: ElementRef,
    ) {
        this.observeIsOpened();
    }

    /**
     * @description Toggle sidenav state (opened/closed)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/09/2023
     * @private
     * @memberof SidenavComponent
     */
    private observeIsOpened(): void {
        this.isOpened.pipe(
            skip(1),
        ).subscribe((isOpened) => {
            if (isOpened) {
                // Display sidenav
                this.elementRef.nativeElement.style.display = 'flex';

                // Animate sidenav
                gsap.fromTo(this.elementRef.nativeElement, {
                    x: '-100%',
                }, {
                    x: '0%',
                    duration: 0.5,
                    ease: 'power1.inOut',
                });
            } else {
                // Animate sidenav
                gsap.fromTo(this.elementRef.nativeElement, {
                    x: '0%',
                }, {
                    x: '-100%',
                    duration: 0.5,
                    ease: 'power1.inOut',
                    // Hide sidenav
                    onComplete: () => {
                        this.elementRef.nativeElement.style.display = 'none';
                    }
                });
            }
        });
    }
}

/**
 * @description The bank loan details interface. It contains all the details of a loan account in a bank
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 05/03/2024
 * @export
 * @interface BankLoanDetails
 */
export interface BankLoanDetails {
    /**
     * @description The next payment date of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {Date}
     * @memberof BankLoanDetails
     */
    nextPaymentDate?: Date;

    /**
     * @description The next amount of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankLoanDetails
     */
    nextPaymentAmount?: number;

    /**
     * @description End date of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {Date}
     * @memberof BankLoanDetails
     */
    maturityDate?: Date;

    /**
     * @description Start date of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {Date}
     * @memberof BankLoanDetails
     */
    openingDate?: Date;

    /**
     * @description Interest rate in percent
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankLoanDetails
     */
    interestRate?: number;

    /**
     * @description Type of loan given by the bank app
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {string}
     * @memberof BankLoanDetails
     */
    type?: string;

    /**
     * @description Amount of the capital that was borrowed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankLoanDetails
     */
    borrowedCapital?: number;

    /**
     * @description Amount of capital that has already repaid
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankLoanDetails
     */
    repaidCapital?: number;

    /**
     * @description Amount of capital left to repay
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     * @type {number}
     * @memberof BankLoanDetails
     */
    remainingCapital?: number;
}

<h3>
  <i class="uil uil-filter margin-right-1"></i>
  <span>Filtrer</span>
</h3>

<i class="uil uil-times-circle close" (click)="close()"></i>

<div class="form-item margin-top-2 margin-bottom-2">
  <span>Catégorie</span>
  <div class="form-select">
    <div
      class="form-option"
      (click)="changeEntityType('society')"
      [class.form-option-active]="entityType === 'society'"
      [class.form-option-disabled]="entityType !== 'society'"
      >
      <i class="uil uil-suitcase"></i>
      <span>Société</span>
    </div>

    <div
      class="form-option"
      (click)="changeEntityType('property')"
      [class.form-option-active]="entityType === 'property'"
      [class.form-option-disabled]="entityType !== 'property'"
      >
      <i class="uil uil-home"></i>
      <span>Bien</span>
    </div>
  </div>
</div>

@if (entityType === 'property') {
  <omedom-select
    label="Type de bien"
    class="margin-bottom-2"
    name="type"
    [(ngModel)]="type"
    [placeholder]="typePropertyPlaceholder"
    [options]="(typePropertyOptions$ | async) || []"
  ></omedom-select>
}
@if (entityType === 'society') {
  <omedom-select
    label="Type de société"
    class="margin-bottom-2"
    name="type"
    [(ngModel)]="type"
    [placeholder]="typeSocietyPlaceholder"
    [options]="(typeSocietyOptions$ | async) || []"
  ></omedom-select>
}

<omedom-radio
  [options]="rentabilityOptions"
  class="margin-bottom-2"
  label="Rentabilités"
  name="rentabilityOption"
  [(ngModel)]="rentability"
></omedom-radio>

<button class="omedom-button-primary margin-top-2" (click)="sendFilter()">
  Afficher les résultats
</button>

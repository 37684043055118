import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class HeaderDropdownClickService {
    //

    /**
     * @description La propriete actuellement affiche dans propertyList
     * @author ANDRE Felix
     * @private
     * @memberof HeaderDropdownClickService
     */
    private assetUID = '';
    /**
     * @description Subject, declenche quand on clicke sur l'icon delete
     * @author ANDRE Felix
     * @private
     * @memberof HeaderDropdownClickService
     */
    private deleteClickEvent = new Subject();
    /**
     * @description Observable que l'on ecoutera pour ouvrir la popup de suppressio d'un bien
     * @author ANDRE Felix
     * @memberof HeaderDropdownClickService
     */
    deleteClickObservable = this.deleteClickEvent.asObservable();

    constructor(private router: Router) {}

    /**
     * @description declenche l'observable pour afficher la popup de suppression d'un bien
     * @author ANDRE Felix
     * @param {boolean} [spreadClick=false]
     * @memberof HeaderDropdownClickService
     */
    deleteClicked(): void {
        this.deleteClickEvent.next(null);
    }

    deleteClickedHandled(isAlreadyHandled = false) {
        this.deleteClickEvent.next(isAlreadyHandled);
    }
    /**
     * @description navige vers la page d'edition d'un bien
     * @author ANDRE Felix
     * @param {ActivatedRoute} route
     * @memberof HeaderDropdownClickService
     */
    editClicked(route: ActivatedRoute): void {
        // ActivatedRoute ne fonctionne 'pas correctement' dans les services
        this.router.navigate(['../../form', this.assetUID], { relativeTo: route });
    }
    /**
     * @description memorise le property.uid affiché
     * @author ANDRE Felix
     * @param {string} newPropertyUid
     * @memberof HeaderDropdownClickService
     */
    setAssetUID(UID: string): void {
        this.assetUID = UID;
    }
}

import { LogLevel } from '@omedom/data';

import { RestEntity } from './rest.entity';

export class LogEntity extends RestEntity {
    /**
     * @description Message of the log
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/10/2023
     * @type {string}
     * @memberof LogEntity
     */
    public message!: string;

    /**
     * @description Level of the log (debug, info, notice, warning, error, critical, alert, emergency)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/10/2023
     * @type {LogLevel}
     * @memberof LogEntity
     */
    public level!: LogLevel;

    /**
     * @description Context of the log
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/10/2023
     * @type {{
     *         [key: string]: any;
     *     }}
     * @memberof LogEntity
     */
    public context?: {
        [key: string]: any;
    };
}

<omedom-modal-header>
  {{ title }}
</omedom-modal-header>

<div class="container">
  <p class="omedom-no-margin">
    {{ message }}
  </p>

  <div class="footer">
    <button class="omedom-button-{{ cancel.color }}"
      (click)="dismissModal(false)">
      @if (cancel.icon) {
        <i
        class="uil uil-{{ cancel.icon }}"></i>
      }
      {{ cancel.text }}
    </button>
    <button class="omedom-button-{{ confirm.color }}"
      (click)="dismissModal(true)">
      @if (confirm.icon) {
        <i
        class="uil uil-{{ confirm.icon }}"></i>
      }
      {{ confirm.text }}
    </button>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OmedomValidators } from '../../validators';
import { ModalController, ToastController } from '@ionic/angular';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { OmedomForm } from '@omedom/utils';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

interface RecommendProDataForm {
    companyName: FormControl<string>;
    fullName: FormControl<string>;
    phone: FormControl<string>;
    profession: FormControl<string>;
    email: FormControl<string>;
}

@Component({
    selector: 'omedom-reccomend-pro',
    templateUrl: './reccomend-pro.component.html',
    styleUrls: ['./reccomend-pro.component.scss'],
})
export class ReccomendProComponent implements OnInit {
    recomendForm?: FormGroup<RecommendProDataForm>;
    constructor(
        private formBuilder: FormBuilder,
        private modalController: ModalController,
        private toastController: ToastController,
        private functions: AngularFireFunctions
    ) {}

    pending$ = new BehaviorSubject(false);

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.recomendForm = this.formBuilder.group<RecommendProDataForm>({
            companyName: new FormControl<string>('', {
                nonNullable: true,
                validators: [Validators.required],
            }),
            fullName: new FormControl<string>('', {
                nonNullable: true,
            }),
            phone: new FormControl('', {
                nonNullable: true,
                validators: [],
            }),
            email: new FormControl('', {
                nonNullable: true,
                validators: [Validators.required, Validators.email],
            }),
            profession: new FormControl('', {
                nonNullable: true,
                validators: [Validators.required],
            }),
        });
    }

    async onSubmit() {
        this.pending$.next(true);
        const callable = this.functions.httpsCallable('sendMailToContactAddTrustyPro');
        const message = this.getMessageToSend();
        try {
            await lastValueFrom(callable({ message }));
            await this.successToast();
        } catch (err) {
            await this.errorToast();
        }
        this.pending$.next(false);

        this.cancel();
    }

    cancel() {
        this.modalController.dismiss();
    }

    public getErrorMessage(formControlName: string, form: FormGroup): string {
        const control = form.get(formControlName) as FormControl;
        return OmedomForm.getErrorMessage(control);
    }

    private getMessageToSend() {
        if (!this.recomendForm) {
            return;
        }

        const data = this.recomendForm.getRawValue();

        const messageAdapted = `Un client vient de vous proposer ce pro de confiance:\n\n
        - Nom de l'entreprise:${data.companyName}\n<br />
        - Profession:${data.profession}\n<br />
        - Email:${data.email}\n<br />
        - Nom complet:${data.fullName}\n<br />
        - Téléphone:${data.phone}
        `;
        return messageAdapted;
    }

    async successToast() {
        const toast = await this.toastController.create({
            position: 'top',
            message:
                'Votre demande est prise en compte, nous allons contacter votre pro de confiance',
            duration: 5000,
            color: 'primary',
        });

        await toast.present();
    }

    async errorToast() {
        const toast = await this.toastController.create({
            position: 'top',
            message: "Une erreur est survenue, nous n'avons pas ",
            duration: 5000,
            color: 'danger',
        });

        await toast.present();
    }
}

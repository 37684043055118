/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable no-unused-vars */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BankEntity, SavingEntity } from '@omedom/data';
import { BankService } from '@omedom/services';
import { BehaviorSubject } from 'rxjs';


/**
 * @description Layout of the saving card
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 27/06/2024
 * @enum {number}
 */
export enum SavingCardLayout {
    DEFAULT = 'default',
    DASHBOARD = 'dashboard',
    MOBILE = 'mobile',
}

@Component({
    selector: 'omedom-saving-card',
    templateUrl: './saving-card.container.html',
    styleUrls: ['./saving-card.container.scss'],
})
export class SavingCardContainer implements OnInit {
    /**
     * @description Layout of the saving card
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @type {SavingCardLayout}
     * @memberof SavingCardContainer
     */
    @Input()
    public layout: SavingCardLayout = SavingCardLayout.DEFAULT;

    /**
     * @description Saving entity to display in the card
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @type {SavingEntity}
     * @memberof SavingCardContainer
     */
    @Input({ required: true })
    public saving!: SavingEntity;

    /**
     * @description Bank entity to display in the card (optional, only if the saving has a bank associated to it)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @type {BankEntity}
     * @memberof SavingCardContainer
     */
    public bank?: BankEntity;

    /**
     * @description Show the bank name in the card or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof SavingCardContainer
     */
    @Input()
    public showBankName = true;

    /**
     * @description Show the balance in the card or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof SavingCardContainer
     */
    @Input()
    public showBalance = true;

    /**
     * @description Show the interest rate in the card or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof SavingCardContainer
     */
    @Input()
    public showInterestRate = true;

    /**
     * @description Show the progress bar of the saving limit in the card or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof SavingCardContainer
     */
    @Input()
    public showLimit = true;

    /**
     * @description Show the informations of the saving in the card or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof SavingCardContainer
     */
    @Input()
    public showInformations = true;

    /**
     * @description Show the footer of the card (with the "evolution" and "see more" buttons) or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 28/06/2024
     * @memberof SavingCardContainer
     */
    @Input()
    public showFooter = true;

    /**
     * @description Event emitted when the user click on the "evolution" button of the card
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof SavingCardContainer
     */
    @Output()
    public onEvolutionClick = new EventEmitter<void>();

    /**
     * @description Event emitted when the user click on the "see more" button of the card
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof SavingCardContainer
     */
    @Output()
    public onSeeMoreClick = new EventEmitter<void>();

    /**
     * @description State of the component (ok, loading, error) to display the right content
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof SavingCardContainer
     */
    public state$ = new BehaviorSubject<'ok' | 'loading' | 'error'>('ok');

    /**
     * @description Layout of the saving card (enum) to use in the template (HTML) file
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @protected
     * @memberof SavingCardContainer
     */
    protected SavingCardLayout = SavingCardLayout;

    constructor(
        private readonly bankService: BankService,
        private readonly ref: ElementRef
    ) { }

    /**
     * @description Get the filled limit of the saving in percentage
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/07/2024
     * @readonly
     * @type {number}
     * @memberof SavingCardContainer
     */
    public get filledLimit(): number {
        // If the saving limit is 0, we return 0 to avoid division by 0
        if (!this.saving.limit) {
            return 0;
        }

        return this.saving.balance / this.saving.limit * 100;
    }


    async ngOnInit(): Promise<void> {
        // Check if the saving is defined
        if (!this.saving) {
            this.state$.next('error');
            return;
        }

        // Initial state of the component
        this.state$.next('loading');

        // If the saving has a bank associated to it
        // And if the bank is not already set
        // Then we fetch the bank from firebase
        if (this.saving.bankID && !this.bank) {
            this.bank = await this.bankService.getBankFromID(this.saving.bankID);
        }

        // Add the layout class to the component
        this.ref.nativeElement.classList.add(`layout-${this.layout}`);

        // Final state of the component
        this.state$.next('ok');
    }
}

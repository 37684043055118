import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { UserService } from '@omedom/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EmailVerificationGuard {
    constructor(private userService: UserService, private router: Router, private auth: AngularFireAuth) { }

    canActivate(): Observable<boolean> {
        return this.userService.user$.pipe(
            map((user) => {
                // Check if user is logged in
                if (!user) {
                    this.router.navigate(['/user/login']);
                    return false;
                }

                // Check if user is verified
                const verified = this.auth.currentUser.then(credential => {
                    return credential?.emailVerified;
                });

                if (!verified) {
                    this.router.navigate(['/user/verify-email']);
                    return false;
                }

                return true;
            })
        );
    }
}

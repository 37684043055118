import { Component, Input } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { PaymentEntity } from '@omedom/data';

@Component({
    selector: 'omedom-payment-card',
    templateUrl: './payment-card.component.html',
    styleUrls: ['./payment-card.component.scss'],
})
export class PaymentCardComponent {
    @Input({
        required: true,
    })
    payment!: PaymentEntity;

    constructor(private storage: AngularFireStorage) {}

    get paymentDate(): Date {
        return new Date((this.payment.created as any).seconds * 1000);
    }

    downloadReceipt(): void {
        this.storage
            .refFromURL(this.payment.receiptURL)
            .getDownloadURL()
            .subscribe((url) => {
                window.open(url, '_blank');
            });
    }
}

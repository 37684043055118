@for (tenant of tenants; track tenant.email; let i = $index) {
<div class="tenant-card">
    <div class="header">
        <div class="title">
            <i class="uil-users-alt margin-right-1"></i>
            <span>{{ tenant.firstname + ' ' + tenant.lastname }}</span>
        </div>
        @if (!tenant.helpUID && roleState?.create) {
        <div class="rent-info-card">
            @if (canAddAid) {
            <button class="omedom-button-secondary w-100" (click)="addAid(i)">
                <i class="uil uil-plus"></i>
                <span>Ajouter une aide</span>
            </button>
            }
        </div>
        }
    </div>
    <div class="side-action margin-top-1">
        @if (roleState?.delete) {
        <div class="delete">
            <i class="uil uil-trash-alt" (click)="removeTenant(i)"></i>
        </div>
        }
        <div class="edit">
            <i class="uil uil-edit-alt" (click)="editTenant(i)"></i>
        </div>
    </div>
</div>
} @if (roleState?.create) {
<button class="omedom-button-primary w-100" (click)="openModal()">
    <i class="uil uil-user-plus"></i>
    <span>{{
        tenants.length ? 'Ajouter un autre locataire' : 'Ajouter un locataire'
    }}</span>
</button>
}

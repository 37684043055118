<div class="omedom-page-container-content">
    <form #form="ngForm" class="w-100 flex-1" @element>
        <omedom-input
            placeholder="Objet"
            label="Objet"
            name="objet"
            [required]="true"
            [(ngModel)]="subject"
        ></omedom-input>

        <omedom-input
            class="textarea"
            placeholder="Dites-nous en quoi nous pouvons vous aider."
            label="Texte"
            name="texte"
            [required]="true"
            [(ngModel)]="message"
        ></omedom-input>
    </form>
    <div class="form-stepper-button-container">
        <button
            class="flex-1 omedom-button omedom-button-secondary"
            (click)="existClicked('Contact', 'uil uil-envelope-alt', null)"
        >
            Annuler
        </button>

        <button
            class="flex-1 omedom-button omedom-button-primary"
            (click)="submit()"
            [disabled]="form.invalid"
        >
            <i class="uil uil-envelope-upload"></i>
            Envoyer
        </button>
    </div>
</div>

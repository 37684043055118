/**
 * @description The type of the bank account
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 05/03/2024
 * @export
 * @enum {number}
 */
export enum BankAccountType {
    /**
     * @description The bank account is a checking account ("compte courant" in French)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     */
    Checking = 'checking',

    /**
     * @description The bank account is a savings account ("compte épargne" in French)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     */
    Savings = 'savings',

    /**
     * @description The bank account is a brokerage account ("compte titres" in French)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     */
    Brokerage = 'brokerage',

    /**
     * @description The bank account is a credit card account ("compte carte" in French). This account save all the transactions made with the credit card, and the amount to pay at the end of the month.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     */
    Card = 'card',

    /**
     * @description The bank account is a loan account ("prêt" in French)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     */
    Loan = 'loan',

    /**
     * @description The bank account is a shared saving plan ("Compte PEA" in French)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     */
    SharedSavingPlan = 'shared_saving_plan',

    /**
     * @description A virtual account that contains all the pending transactions
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     */
    Pending = 'pending',

    /**
     * @description The bank account is a life insurance ("assurance vie" in French)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     */
    LifeInsurance = 'life_insurance',

    /**
     * @description Temporary type. Set while the account is synchronizing
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     */
    Special = 'special',

    /**
     * @description Unknown account type
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/03/2024
     */
    Unknown = 'unknown',
}

import { Injectable, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
    ChargeDeleteType,
    ChargeEntity,
    ChargeHistory,
    ChargePeriodicity,
    IncomeDeleteType,
    IncomeEntity,
    IncomeHistory,
    IncomePeriodicity,
    TreasuryListModel,
} from '@omedom/data';
import { OmedomTreasury } from '@omedom/utils';
import { Timestamp } from 'firebase/firestore';

@Injectable()
export abstract class TreasuryDelete<
    TEntity extends ChargeEntity | IncomeEntity,
    TCategory,
    TPeriodicity
> {
    @Input() treasuryListModel?: TreasuryListModel<TCategory, TPeriodicity>;

    today = new Date();

    abstract selectedDeleteOption: IncomeDeleteType | ChargeDeleteType;

    protected constructor(private modalController: ModalController) { }

    async deleteConfirmed(): Promise<void> {
        if (!this.treasuryListModel) {
            return;
        }

        const treasury = await this.getTreasury(this.treasuryListModel.uid);
        let history: ChargeHistory[] | IncomeHistory[];
        const deletionDate = this.treasuryListModel.date;
        if (!!treasury) {
            // Delete punctual or all (or this one and next treasury if startdate is delation date)
            if (
                // In the case of a one-off charge / income, we delete it
                treasury.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual ||
                // In the case where we want to delete the entire series of a charge / income, we delete it
                this.selectedDeleteOption === (IncomeDeleteType || ChargeDeleteType).all ||
                //  In the case where we want to delete charge / income and the following ones from the first occurrence, we delete it
                (this.selectedDeleteOption ===
                    (IncomeDeleteType || ChargeDeleteType).thisOneAndNext &&
                    treasury.startDate.toDate().getTime() === deletionDate.getTime())
            ) {
                await this.deleteTreasury(treasury.uid);
                await this.dismiss();

                return;
            }

            //we treat the history according to whether it is just this one or this one + the next ones.
            if (this.selectedDeleteOption === (IncomeDeleteType || ChargeDeleteType).onlyThisOne) {
                history =
                    treasury.history?.filter(
                        (x) => x.date.toDate().getTime() === deletionDate.getTime()
                    ) ?? [];
            } else {
                history =
                    treasury.history?.filter(
                        (x) => x.date.toDate().getTime() >= deletionDate.getTime()
                    ) ?? [];
            }

            // We tag the histories as deleted that have the same date or older than the deletion date
            history?.forEach((x) => (x.isDeleted = true));

            const nextHistoryDate = treasury.nextHistoryDate
                ? treasury.nextHistoryDate.toDate()
                : OmedomTreasury.addMonthFromPeriodicity(
                    treasury,
                    treasury.debitDate
                        ? treasury.debitDate.toDate()
                        : treasury.startDate.toDate(),
                    1
                );

            // We treat the future payments according to whether it is just this one or this one + the next ones.
            if (this.selectedDeleteOption === (IncomeDeleteType || ChargeDeleteType).onlyThisOne) {
                // If we want to delete just this one, that it is not already deleted and that it has a next history date

                const endDate = treasury.endDate ?? undefined;

                // we must delete in the future (or the present in case of modification from the next day)

                if (nextHistoryDate.getTime() <= deletionDate.getTime()) {
                    // on vérifie si la date à supprimer est un futur payment existant
                    const futurePaymentIndex =
                        treasury.futurPayment?.findIndex(
                            (x) => x.date.toDate().getTime() === deletionDate.getTime()
                        ) ?? -1;
                    // if it is a future payment we tag it isDeleted
                    if (futurePaymentIndex >= 0 && treasury.futurPayment) {
                        // we check if it is the last future payment and isForNext
                        if (
                            futurePaymentIndex === treasury.futurPayment.length - 1 &&
                            !endDate &&
                            treasury.futurPayment[futurePaymentIndex].isForNext
                        ) {
                            // we move the due date the next due date
                            treasury.futurPayment.push({
                                ...treasury.futurPayment[futurePaymentIndex],
                                date: Timestamp.fromDate(
                                    OmedomTreasury.addMonthFromPeriodicity(
                                        treasury,
                                        deletionDate,
                                        1
                                    )
                                ),
                            });
                            treasury.futurPayment[futurePaymentIndex].isDeleted = true;
                        } else {
                            let index = futurePaymentIndex;
                            treasury.futurPayment[futurePaymentIndex].isDeleted = true;

                            // We check if the futur payment is isForNext
                            if (treasury.futurPayment[futurePaymentIndex].isForNext) {
                                // we find the date of the next free futur payment unless we meet a true isForNext futur payment
                                while (index < (treasury.futurPayment.length || 1000)) {
                                    index++;
                                    const nextDate = OmedomTreasury.addMonthFromPeriodicity(
                                        treasury,
                                        deletionDate,
                                        1
                                    );

                                    // is the next month a future payment?
                                    const nextFuturePaymentIndex =
                                        treasury.futurPayment?.findIndex(
                                            (x) => x.date.toDate().getTime() === nextDate.getTime()
                                        ) >= 0;
                                    if (
                                        !nextFuturePaymentIndex &&
                                        (!endDate || nextDate <= endDate.toDate())
                                    ) {
                                        // we find the date of the next free futur payment we move the futur payment isForNext to this date
                                        const futurPaymentRelpace = this.treasuryListModel;
                                        treasury.futurPayment = [
                                            ...treasury.futurPayment,
                                            {
                                                date: Timestamp.fromDate(nextDate),
                                                amount: futurPaymentRelpace.amount,
                                                isForNext: true,
                                                isDeleted: false,
                                            },
                                        ]
                                            // we sort the future payment by date
                                            .sort(
                                                (a, b) =>
                                                    a.date.toDate().getTime() -
                                                    b.date.toDate().getTime()
                                            );
                                        break;
                                    } else if (
                                        // if the futur payment is isForNext there is no need to reflect the deleted isForNext
                                        treasury.futurPayment?.findIndex(
                                            (x) =>
                                                x.date.toDate().getTime() === nextDate.getTime() &&
                                                x.isForNext
                                        ) >= 0 ||
                                        // the same  if the date of the next futur payment is greater than the end date
                                        (endDate && nextDate > endDate?.toDate())
                                    ) {
                                        break;
                                    }
                                    if (index > 1000) {
                                        throw new Error('Erreur de boucle infinie');
                                    }
                                }
                            }
                        }
                        treasury.futurPayment = treasury.futurPayment.sort(
                            (a, b) => a.date.toDate().getTime() - b.date.toDate().getTime()
                        );
                    } else {
                        // otherwise we add a future payment isDeleted
                        treasury.futurPayment = [
                            ...(treasury.futurPayment || []),
                            {
                                date: Timestamp.fromDate(deletionDate),
                                amount: this.treasuryListModel.amount,
                                isForNext: false,
                                isDeleted: true,
                            },
                        ]
                            // wee sort the future payment by date
                            .sort((a, b) => a.date.toDate().getTime() - b.date.toDate().getTime());
                    }
                }

                // if the next history date is the deletion date
                if (nextHistoryDate.getTime() === deletionDate.getTime()) {
                    // if we want to delete the charge that corresponds to the date of the next history, we change the next history date
                    const nextDate = OmedomTreasury.addMonthFromPeriodicity(
                        treasury,
                        nextHistoryDate
                    );
                    if (
                        !treasury.endDate ||
                        treasury.endDate.toDate().getTime() >= nextDate.getTime()
                    ) {
                        // if the end date is greater than the next date or there is no end date
                        treasury.nextHistoryDate = Timestamp.fromDate(nextDate);
                    } else {
                        // Otherwise it was the last history, we delete the next history date
                        treasury.nextHistoryDate = null;
                    }
                } else if (deletionDate.getTime() === endDate?.toDate().getTime()) {
                    // There is an end date and it is this one that we want to delete
                    const newEndDate = OmedomTreasury.addMonthFromPeriodicity(
                        treasury,
                        endDate.toDate(),
                        -1
                    );
                    treasury.endDate = Timestamp.fromDate(newEndDate);
                    treasury.futurPayment =
                        treasury.futurPayment?.filter(
                            (x) => x.date.toDate().getTime() < deletionDate.getTime()
                        ) || [];
                }
            }

            if (
                this.selectedDeleteOption === (IncomeDeleteType || ChargeDeleteType).thisOneAndNext
            ) {
                // If we want to delete this one and the following ones, we change the end date
                const newEndDate = Timestamp.fromDate(
                    OmedomTreasury.addMonthFromPeriodicity(treasury, deletionDate, -1)
                );
                treasury.endDate = newEndDate;

                if (nextHistoryDate < deletionDate) {
                    treasury.futurPayment =
                        treasury.futurPayment?.filter(
                            (x) => x.date.toDate().getTime() < deletionDate.getTime()
                        ) || [];
                    // treasury.futurPayment.push({ isDeleted: true, isForNext: true, date: Timestamp.fromDate(deletionDate), amount: null });
                } else if (nextHistoryDate.getTime() >= deletionDate.getTime()) {
                    delete treasury.futurPayment;
                }

                if (treasury.endDate.toDate().getTime() < nextHistoryDate.getTime()) {
                    // treasury.nextHistoryDate = Timestamp.fromDate(OmedomTreasury.addMonthFromPeriodicity(treasury, treasury.endDate.toDate(), -1));
                    delete treasury.futurPayment;
                }
            }

            if (this.checkDeleteTreasury(treasury, nextHistoryDate)) {
                await this.deleteTreasury(treasury.uid);
            } else {
                await this.updateTreasury(treasury);
            }
        }

        await this.dismiss();
    }

    /**
     * @description Check if we can delete the treasury
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @param {TEntity} treasury
     * @param {Date} nextHistoryDate
     * @return {*}  {boolean}
     * @memberof TreasuryDelete
     */
    checkDeleteTreasury(treasury: TEntity, nextHistoryDate: Date): boolean {
        // we check that the deletion has not deleted everything:
        // if there is no end date or future payment and the end is not exceeded we do not delete everything

        // if there is no end date...
        if (!treasury.endDate?.toDate()) {
            //... There is a future payment to come, we do not delete everything
            return false;
        }

        // ---- From here there is an end date ----

        // we check that there is:
        // - at least 1 non-deleted history
        // - at least 1 non-deleted future payment
        // so we don't delete everything
        if (
            (treasury.history?.findIndex((x) => !x.isDeleted) ?? -1) >= 0 ||
            (treasury.futurPayment?.findIndex((x) => !x.isDeleted) ?? -1) >= 0
        ) {
            return false;
        }

        // ---- From here there is no more history ----

        // if the end date is in the past and all the histories are deleted, we delete everything
        if (
            this.today.getTime() > treasury.endDate.toDate().getTime() &&
            (treasury.history?.findIndex((x) => !x.isDeleted) ?? -1) < 0
        ) {
            return true;
        }

        //!\
        // If there are no changes in the future and the next occurrence is before the end date, we do not delete everything
        if (
            !!treasury.nextHistoryDate &&
            (treasury.futurPayment?.length ?? 0) <= 0 &&
            treasury.endDate?.toDate().getTime() >= nextHistoryDate?.getTime()
        ) {
            return false;
        }

        //?\
        // If the first element of the future payment is not deleted and it is the next occurrence
        if (
            treasury.futurPayment &&
            treasury.futurPayment[0]?.date.toDate().getTime() === nextHistoryDate.getTime() &&
            !treasury.futurPayment[0]?.isDeleted
        ) {
            return false;
        }

        // If an end date is greater than or equal to nextHistoryDate...
        if (treasury.endDate.toDate().getTime() > nextHistoryDate.getTime()) {
            // If there are future payments we check that they are not all deleted
            if (treasury.futurPayment && treasury.futurPayment.length > 0) {
                // We go through the treasury.futurPayment table:
                for (let i = 0; i < treasury.futurPayment.length; i++) {
                    // - if there is a free space at a future payment from the beginning of the future payment table, we do not delete everything
                    if (
                        i === 0 &&
                        treasury.futurPayment[i].date.toDate().getTime() > nextHistoryDate.getTime()
                    ) {
                        return false;
                    }

                    // - if the future payment is deleted and an occurrence is possible, we do not delete everything
                    const nextFuturePayment = OmedomTreasury.addMonthFromPeriodicity(
                        treasury,
                        treasury.futurPayment[i].date.toDate(),
                        1
                    );
                    const haveFutur =
                        treasury.endDate.toDate().getTime() >= nextFuturePayment.getTime();

                    // if a futur is possible and this one is deleted...
                    if (haveFutur && treasury.futurPayment[i].isDeleted) {
                        // ... and the next one if after the next occurrence or there is no next one we do not delete everything
                        if (
                            treasury.futurPayment[i + 1]?.date.toDate().getTime() >
                            nextFuturePayment.getTime() ||
                            treasury.futurPayment[i + 1] === undefined
                        ) {
                            return false;
                        }
                    }
                }
            }
            //?!\
            else if (treasury.endDate.toDate().getTime() === nextHistoryDate?.getTime()) {
                return false;
            }
        }

        return true;
    }
    async dismiss(): Promise<void> {
        await this.modalController.dismiss();
    }

    abstract getTreasury(treasuryUid: string): Promise<TEntity | undefined>;

    abstract updateTreasury(treasury: TEntity): Promise<void>;

    abstract deleteTreasury(treasuryUid: string): Promise<void>;
}

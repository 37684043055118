interface DepartmentByRegion {
    [key: string]: Department[];
}

interface Department {
    name: string;
    code: string;
}

export const departmentByRegion: DepartmentByRegion = {
    Alsace: [
        {
            name: 'Bas Rhin',
            code: '67',
        },
        {
            name: 'Haut Rhin',
            code: '68',
        },
    ],
    Aquitaine: [
        {
            name: 'Dordogne',
            code: '24',
        },
        {
            name: 'Gironde',
            code: '33',
        },
        {
            name: 'Landes',
            code: '40',
        },
        {
            name: 'Lot et Garonne',
            code: '47',
        },
        {
            name: 'Pyrénées Atlantiques',
            code: '64',
        },
    ],
    Auvergne: [
        {
            name: 'Allier',
            code: '03',
        },
        {
            name: 'Cantal',
            code: '15',
        },
        {
            name: 'Haute Loire',
            code: '43',
        },
        {
            name: 'Puy de Dôme',
            code: '63',
        },
    ],
    'Basse-Normandie': [
        {
            name: 'Calvados',
            code: '14',
        },
        {
            name: 'Manche',
            code: '50',
        },
        {
            name: 'Orne',
            code: '61',
        },
    ],
    Bourgogne: [
        {
            name: "Côte d'Or",
            code: '21',
        },
        {
            name: 'Nièvre',
            code: '58',
        },
        {
            name: 'Saône et Loire',
            code: '71',
        },
        {
            name: 'Yonne',
            code: '89',
        },
    ],
    Bretagne: [
        {
            name: "Côtes d'Armor",
            code: '22',
        },
        {
            name: 'Finistère',
            code: '29',
        },
        {
            name: 'Ille et Vilaine',
            code: '35',
        },
        {
            name: 'Morbihan',
            code: '56',
        },
    ],
    Centre: [
        {
            name: 'Cher',
            code: '18',
        },
        {
            name: 'Eure et Loir',
            code: '28',
        },
        {
            name: 'Indre',
            code: '36',
        },
        {
            name: 'Indre et Loire',
            code: '37',
        },
        {
            name: 'Loir et Cher',
            code: '41',
        },
        {
            name: 'Loiret',
            code: '45',
        },
    ],
    'Champagne-Ardenne': [
        {
            name: 'Ardennes',
            code: '08',
        },
        {
            name: 'Aube',
            code: '10',
        },
        {
            name: 'Marne',
            code: '51',
        },
        {
            name: 'Haute Marne',
            code: '52',
        },
    ],
    Corse: [
        {
            name: 'Corse du Sud',
            code: '2A',
        },
        {
            name: 'Haute Corse',
            code: '2B',
        },
    ],
    'Franche-Comté': [
        {
            name: 'Doubs',
            code: '25',
        },
        {
            name: 'Jura',
            code: '39',
        },
        {
            name: 'Haute Saône',
            code: '70',
        },
        {
            name: 'Territoire de Belfort',
            code: '90',
        },
    ],
    'Haute-Normandie': [
        {
            name: 'Eure',
            code: '27',
        },
        {
            name: 'Seine Maritime',
            code: '76',
        },
    ],
    'Ile-de-France': [
        {
            name: 'Paris',
            code: '75',
        },
        {
            name: 'Seine et Marne',
            code: '77',
        },
        {
            name: 'Yvelines',
            code: '78',
        },
        {
            name: 'Essonne',
            code: '91',
        },
        {
            name: 'Hauts de Seine',
            code: '92',
        },
        {
            name: 'Seine St Denis',
            code: '93',
        },
        {
            name: 'Val de Marne',
            code: '94',
        },
        {
            name: "Val d'Oise",
            code: '95',
        },
    ],
    'Languedoc-Roussillon': [
        {
            name: 'Aude',
            code: '11',
        },
        {
            name: 'Gard',
            code: '30',
        },
        {
            name: 'Hérault',
            code: '34',
        },
        {
            name: 'Lozère',
            code: '48',
        },
        {
            name: 'Pyrénées Orientales',
            code: '66',
        },
    ],
    Limousin: [
        {
            name: 'Corrèze',
            code: '19',
        },
        {
            name: 'Creuse',
            code: '23',
        },
        {
            name: 'Haute Vienne',
            code: '87',
        },
    ],
    Lorraine: [
        {
            name: 'Meurthe et Moselle',
            code: '54',
        },
        {
            name: 'Meuse',
            code: '55',
        },
        {
            name: 'Moselle',
            code: '57',
        },
        {
            name: 'Vosges',
            code: '88',
        },
    ],
    'Midi-Pyrénées': [
        {
            name: 'Ariège',
            code: '09',
        },
        {
            name: 'Aveyron',
            code: '12',
        },
        {
            name: 'Haute Garonne',
            code: '31',
        },
        {
            name: 'Gers',
            code: '32',
        },
        {
            name: 'Lot',
            code: '46',
        },
        {
            name: 'Hautes Pyrénées',
            code: '65',
        },
        {
            name: 'Tarn',
            code: '81',
        },
        {
            name: 'Tarn et Garonne',
            code: '82',
        },
    ],
    'Nord-Pas-de-Calais': [
        {
            name: 'Nord',
            code: '59',
        },
        {
            name: 'Pas de Calais',
            code: '62',
        },
    ],
    'Pays de la Loire': [
        {
            name: 'Loire Atlantique',
            code: '44',
        },
        {
            name: 'Maine et Loire',
            code: '49',
        },
        {
            name: 'Mayenne',
            code: '53',
        },
        {
            name: 'Sarthe',
            code: '72',
        },
        {
            name: 'Vendée',
            code: '85',
        },
    ],
    Picardie: [
        {
            name: 'Aisne',
            code: '02',
        },
        {
            name: 'Oise',
            code: '60',
        },
        {
            name: 'Somme',
            code: '80',
        },
    ],
    'Poitou-Charentes': [
        {
            name: 'Charente',
            code: '16',
        },
        {
            name: 'Charente Maritime',
            code: '17',
        },
        {
            name: 'Deux Sèvres',
            code: '79',
        },
        {
            name: 'Vienne',
            code: '86',
        },
    ],
    "Provence-Alpes-Côte-d'Azur": [
        {
            name: 'Alpes de Haute Provence',
            code: '04',
        },
        {
            name: 'Hautes Alpes',
            code: '05',
        },
        {
            name: 'Alpes Maritimes',
            code: '06',
        },
        {
            name: 'Bouches du Rhône',
            code: '13',
        },
        {
            name: 'Var',
            code: '83',
        },
        {
            name: 'Vaucluse',
            code: '84',
        },
    ],
    'Rhône-Alpes': [
        {
            name: 'Ain',
            code: '01',
        },
        {
            name: 'Ardèche',
            code: '07',
        },
        {
            name: 'Drôme',
            code: '26',
        },
        {
            name: 'Isère',
            code: '38',
        },
        {
            name: 'Loire',
            code: '42',
        },
        {
            name: 'Rhône-Alpes',
            code: '69',
        },
        {
            name: 'Savoie',
            code: '73',
        },
        {
            name: 'Haute Savoie',
            code: '74',
        },
    ],
    DOM: [
        {
            name: 'Guadeloupe',
            code: '971',
        },
        {
            name: 'Martinique',
            code: '972',
        },
        {
            name: 'Guyane',
            code: '973',
        },
        {
            name: 'Réunion',
            code: '974',
        },
    ],
};

import { Sharing, SocietyMember, SocietyRoleMember } from '@omedom/data';

export class OmedomShare {
    public static transformSocietyMemberToShare(member: SocietyMember) {
        const share: Sharing = {
            name: member.name,
            firstname: member.firstname,
            email: member.email,
            role: SocietyRoleMember.reader,
        };

        return share;
    }
}

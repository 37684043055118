import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SocietyEntity, UserEntity } from '@omedom/data';

import { OmedomRadioOption } from '../radio';
import { OmedomSubTab } from '../sub-tab/omedom-sub-tab';
import { SocietyInfoTab } from './society-info-tab';
import { UserService } from '@omedom/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'omedom-society-info',
    templateUrl: './society-info.component.html',
    styleUrls: ['./society-info.component.scss'],
})
export class SocietyInfoComponent implements OnInit, OnDestroy {
    @Input() society?: SocietyEntity;
    @Input() editable: boolean = false;

    user?: UserEntity;

    subTabs: OmedomSubTab[] = [
        new OmedomSubTab({ id: 1, label: 'Détails' }),
        new OmedomSubTab({ id: 2, label: 'Membres' }),
    ];

    selectedSubTab: OmedomSubTab = this.subTabs[0];

    societyInfoTab = SocietyInfoTab;

    documentOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({ id: true, label: 'Oui' }),
        new OmedomRadioOption({ id: false, label: 'Non' }),
    ];

    withDocuments = true;

    private subscriptions: Subscription[] = [];

    constructor(private userService: UserService) {}

    ngOnInit() {
        this.selectedSubTab = this.subTabs[0];
        const user$ = this.userService.user$.subscribe((user) => {
            this.user = user;
            this.isCurrentUserMember();
        });
        this.subscriptions.push(user$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    isCurrentUserMember() {
        const memberIndex = this.society?.members.findIndex((member) => {
            return member.email === this.user?.email;
        });
        if (memberIndex !== undefined && memberIndex > -1) {
            this.subTabs = [
                new OmedomSubTab({ id: 1, label: 'Détails' }),
                new OmedomSubTab({ id: 2, label: 'Membres' }),
            ];
            return true;
        }
        return false;
    }
}

/**
 * @description Template for sendinblue automation
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 28/11/2023
 * @export
 * @interface AutomationTemplateSendinblue
 */
export interface AutomationTemplateSendinblue {
    templateId: number;
    description: string;
    params: { [key: string]: string; }; // All params must be string type
    to: { [key: string]: string; }[];
    attachment?: { [key: string]: string; }[];
    sender?: { [key: string]: string; };
    cc?: { [key: string]: string; }[];
    replyTo?: { [key: string]: string; }[];
}

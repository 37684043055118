/* eslint-disable no-unused-vars */
import { Component, Input, OnInit } from '@angular/core';
import {
    Category,
    Color,
    DocumentEntity,
    DocumentTypeClient,
    LoanEntity,
    PropertyEntity,
    SavingEntity,
    SocietyEntity,
} from '@omedom/data';
import { LoanService, PropertyService, SavingService, SocietyService } from '@omedom/services';
import { BehaviorSubject, Subscription } from 'rxjs';

import { OmedomIsAuthorisedPipe } from '../../../pipes';

@Component({
    selector: 'omedom-document-card',
    templateUrl: './document-card.component.html',
    styleUrls: ['./document-card.component.scss'],
})
export class DocumentCardComponent implements OnInit {
    /**
     * @description Document data
     * @author Jérémie Lopez
     * @type {DocumentEntity}
     * @memberof DocumentCardComponent
     */
    @Input()
    public document?: DocumentEntity;

    /**
     * @description Property data
     * @author Jérémie Lopez
     * @type {PropertyEntity}
     * @memberof DocumentCardComponent
     */
    public property?: PropertyEntity;

    /**
     * @description Society data
     * @author Jérémie Lopez
     * @type {SocietyEntity}
     * @memberof DocumentCardComponent
     */
    public society?: SocietyEntity;

    /**
     * @description Loan data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @type {LoanEntity}
     * @memberof DocumentCardComponent
     */
    public loan?: LoanEntity;

    /**
     * @description Saving data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/07/2024
     * @type {SavingEntity}
     * @memberof DocumentCardComponent
     */
    public saving?: SavingEntity;

    public colors = Color;
    public types = DocumentTypeClient;
    public categories = Category;
    public isDocumentEnable$ = new BehaviorSubject<boolean>(false);

    /**
     * @description if the user is in mode can access documents or not
     * @author Didier Pascarel
     * @public
     * @type {boolean}
     * @memberof DocumentCardComponent
     */
    public canAccessDocuments = false;

    /**
     * @description List of subscriptions to unsub after destroy component
     * @author Didier Pascarel
     * @private
     * @type {Subscription[]}
     * @memberof DocumentCardComponent
     */
    private subscriptions: Subscription[] = [];

    constructor(
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private readonly loanService: LoanService,
        private readonly savingService: SavingService,
        private omedomIsAuthorisedPipe: OmedomIsAuthorisedPipe
    ) {}

    public get icon(): string {
        return this.document
            ? DocumentEntity.getIcon(this.document.type)
            : DocumentEntity.getIcon(DocumentTypeClient.other);
    }

    async ngOnInit(): Promise<void> {
        const canAccessDocuments$ = this.omedomIsAuthorisedPipe
            .transform('accessDocuments')
            .subscribe((canAccessDocuments) => {
                this.canAccessDocuments = canAccessDocuments;
            });

        this.subscriptions.push(canAccessDocuments$);

        if (this.document?.propertyUID) {
            this.property = await this.propertyService.get(this.document.propertyUID);
        }

        if (this.document?.societyUID) {
            this.society = await this.societyService.get(this.document.societyUID);
        }

        // Check if the document has a loan linked
        if (this.document?.loanUID) {
            this.loan = await this.loanService.get(this.document.loanUID);
        }

        // Check if the document has a saving linked
        if (this.document?.savingUID) {
            this.saving = await this.savingService.get(this.document.savingUID);
        }

        if (
            this.document &&
            (this.document.userUID ||
                this.document.societyUID ||
                this.document.propertyUID ||
                this.document.loanUID ||
                this.document.savingUID)
        ) {
            this.isDocumentEnable$.next(this.canAccessDocuments || false);
        }
    }

    public isDocumentColor(color: Color): boolean {
        return DocumentEntity.isColor(color, this.document?.type ?? DocumentTypeClient.other);
    }

    public isDocumentCategory(category: Category): boolean {
        return DocumentEntity.isCategory(category, this.document?.type ?? DocumentTypeClient.other);
    }
}

import { RestEntity } from './rest.entity';

export interface Warrantor {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
}
export class TenantEntity extends RestEntity {
    /**
     * @description First name
     * @author Martin Bastié
     * @type {string[]}
     * @memberof TenantEntity
     */
    firstname!: string;

    /**
     * @description Last name
     * @author Martin Bastié
     * @type {string[]}
     * @memberof TenantEntity
     */
    lastname!: string;

    /**
     * @description Email
     * @author Martin Bastié
     * @type {string[]}
     * @memberof TenantEntity
     */
    email!: string;

    /**
     * @description Phone
     * @author Martin Bastié
     * @type {string[]}
     * @memberof TenantEntity
     */
    phone!: string;

    /**
     * @description Lease of the tenant
     * @author Jérémie Lopez
     * @type {string}
     * @memberof TenantEntity
     */
    leaseUID!: string;

    /**
     * @description Entry date of the tenant in the property
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 16/10/2024
     * @type {Date}
     * @memberof TenantEntity
     */
    entryDate?: Date;

    /**
     * @description Exit date of the tenant in the property
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 16/10/2024
     * @type {Date}
     * @memberof TenantEntity
     */
    exitDate?: Date;

    /**
     * @description Help of the tenant
     * @author Jérémie Lopez
     * @type {string}
     * @memberof TenantEntity
     */
    helpUID!: string;

    /**
     * @description Warrantor info
     * @author Hanane Djeddal
     * @type {Warrantor}
     * @memberof TenantEntity
     */
    warrantor!: Warrantor;
}

import { Timestamp } from 'firebase/firestore';

import { ChargeAssociateTo } from './charge.entity';
import { RestEntity } from './rest.entity';


export interface CategoryInfo<T> {
    label: string;
    icon: string;
    category: T;
}

export interface PeriodicityInfo<T> {
    label: string;
    periodicity: T;
}

export enum IncomeCategoryProperty {
    rent = 'rent',
    aid = 'aid',
    regularization = 'regularization',
    various = 'various',
}

export enum IncomeCategorySociety {
    incomeSociety = 'incomeSociety',
}
export enum IncomeCategoryBuilding {
    incomeBuilding = 'incomeBuilding',
}

export type AllIncomeCategories =
    | IncomeCategoryProperty
    | IncomeCategorySociety
    | IncomeCategoryBuilding;

export class IncomeCategoryInfo implements CategoryInfo<AllIncomeCategories> {
    label!: string;
    icon!: string;
    category!: AllIncomeCategories;

    constructor(incomeCategory: AllIncomeCategories) {
        this.convertToInfo(incomeCategory);
    }

    private convertToInfo(incomeCategory: AllIncomeCategories) {
        this.category = incomeCategory;

        switch (incomeCategory) {
            case IncomeCategoryProperty.rent:
                this.label = 'Loyer';
                this.icon = 'omedom-icon-rent';
                break;

            case IncomeCategoryProperty.aid:
                this.label = 'Aides';
                this.icon = 'uil uil-euro-circle';
                break;

            case IncomeCategoryProperty.regularization:
                this.label = 'Régularisation';
                this.icon = 'uil uil-chart';
                break;

            case IncomeCategoryProperty.various:
                this.label = 'Divers';
                this.icon = 'uil uil-chart';
                break;
            case IncomeCategorySociety.incomeSociety:
                this.label = 'Divers society';
                this.icon = 'uil uil-suitcase';
                break;
            default:
                this.label = 'Divers';
                this.icon = 'uil uil-chart';
        }
    }
}

export enum IncomePeriodicity {
    punctual = 'punctual',
    monthly = 'monthly',
    bimonthly = 'bimonthly',
    quarterly = 'quarterly',
    halfYearly = 'half-yearly',
    yearly = 'yearly',
}

export class IncomePeriodicityInfo implements PeriodicityInfo<IncomePeriodicity> {
    label!: string;
    periodicity!: IncomePeriodicity;

    constructor(periodicity: IncomePeriodicity) {
        this.convertToInfo(periodicity);
    }

    private convertToInfo(periodicity: IncomePeriodicity): void {
        this.periodicity = periodicity;

        switch (periodicity) {
            case IncomePeriodicity.punctual:
                this.label = 'Ponctuelle';
                break;

            case IncomePeriodicity.monthly:
                this.label = 'Mensuelle';
                break;

            case IncomePeriodicity.bimonthly:
                this.label = 'Bimestrielle';
                break;

            case IncomePeriodicity.quarterly:
                this.label = 'Trimestrielle';
                break;

            case IncomePeriodicity.halfYearly:
                this.label = 'Semestrielle';
                break;

            case IncomePeriodicity.yearly:
                this.label = 'Annuelle';
                break;
            default:
                this.label = 'Mensuelle';
                break;
        }
    }
}

export class IncomeEntity extends RestEntity {
    /**
     * @description Link to property
     * @author Jérémie Lopez
     * @type {string}
     * @memberof IncomeEntity
     */
    propertyUID?: string;

    /**
     * @description Link an income to a society
     * @author ANDRE Felix
     * @type {string}
     * @memberof IncomeEntity
     */
    societyUID?: string;

    /**
     * @description Link to user
     * @author Jérémie Lopez
     * @type {string}
     * @memberof IncomeEntity
     */
    userUID!: string;

    /**
     * @description Category
     * @author Martin Bastié
     * @type {IncomeCategoryProperty}
     * @memberof IncomeEntity
     */
    category!: AllIncomeCategories;

    /**
     * @description Amount of the income
     * @author Jérémie Lopez
     * @type {number}
     * @memberof IncomeEntity
     */
    amount!: number;

    /**
     * @description Amount of the rent income including charges
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {number}
     * @memberof IncomeEntity
     */
    rentWithCharges?: number;

    /**
     * @description Periodicity
     * @author Martin Bastié
     * @type {IncomePeriodicity}
     * @memberof IncomeEntity
     */
    periodicity!: IncomePeriodicity;

    /**
     * @description Start date of the income
     * @author Martin Bastié
     * @type {string}
     * @memberof IncomeEntity
     */
    debitDate?: Timestamp;

    /**
     * @description Start date of the income
     * @author Jérémie Lopez
     * @type {Timestamp}
     * @memberof IncomeEntity
     */
    startDate!: Timestamp;

    /**
     * @description End date of the income
     * @author Jérémie Lopez
     * @type {Date}
     * @memberof IncomeEntity
     */
    endDate?: Timestamp;

    /**
     * @description If true, the owner receive alert to validate movement
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof IncomeEntity
     */
    notification?: boolean;

    /**
     * @description History of payments
     * @author Martin Bastié
     * @type {IncomeHistory[]}
     * @memberof IncomeEntity
     */
    history?: IncomeHistory[];

    /**
     * @description Payments in the futur. Use cas, I want to change amount or debit date in for a month in the future
     * @author Martin Bastié
     * @type {IncomeFuturPayment[]}
     * @memberof IncomeEntity
     */
    futurPayment?: IncomeFuturPayment[];

    /**
     * @description Last amount of payment
     * @author Martin Bastié
     * @type {number}
     * @memberof IncomeEntity
     */
    lastHistoryAmount?: number;

    /**
     * @description Last date of payment
     * @author Martin Bastié
     * @type {Date}
     * @memberof IncomeEntity
     */
    lastHistoryDate?: Timestamp;

    /**
     * @description Next date of payment
     * @author Martin Bastié
     * @type {Date}
     * @memberof IncomeEntity
     */
    nextHistoryDate?: Timestamp;

    /**
     * @description Designation
     * @author Martin Bastié
     * @type {string}
     * @memberof IncomeEntity
     */
    designation?: string;

    /**
     * @description If the income is deleted
     * @author Martin Bastié
     * @type {boolean}
     * @memberof IncomeEntity
     */
    isDeleted?: boolean;

    /**
     * @description If the income is payed and punctual
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof IncomeEntity
     */
    isPayed?: boolean;

    /**
     * @description If the income is readed and punctual
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof IncomeEntity
     */
    isReaded?: boolean;

    /**
     * @description additional notes for the property
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {string}
     * @memberof IncomeEntity?
     */
    notes?: string;

    /**
     * @description Verify if receipt is sent
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {boolean}
     * @memberof IncomeEntity?
     */
    isSentReceipt?: boolean;

    /**
     * @description Verify if relaunch is sent
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {boolean}
     * @memberof IncomeEntity?
     */
    isSentRelaunch?: boolean;

    associatedTo?: ChargeAssociateTo[];

    /**
     * @description Transaction ID of the income if it's a punctual income
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 31/05/2024
     * @type {number}
     * @memberof IncomeEntity
     */
    transactionID?: number;

    constructor(data: Partial<IncomeEntity>) {
        super(data);
        Object.assign(this, data);
    }
}

export class IncomeHistory {
    date!: Timestamp;
    designation?: string;
    amount!: number;
    rentWithCharges?: number;
    isPayed!: boolean;
    isReaded?: boolean;
    isDeleted!: boolean;
    notes?: string;
    transactionID?: number;
    isSentReceipt?: boolean;
    isSentRelaunch?: boolean;
}

export class IncomeFuturPayment {
    date!: Timestamp;
    amount!: number;
    rentWithCharges?: number;
    notes?: string;
    isForNext!: boolean;
    isDeleted!: boolean;
}

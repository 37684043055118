<div class="chat-card">
    <div
        class="chat-card-content"
        (click)="chatSelected.emit(chat)"
        [class.active]="isSelected"
    >
        <div class="chat-card-icon">
            <i class="uil uil-comment"></i>
        </div>
        <div class="chat-card-content-info">
            <h3 class="chat-card-title">
                {{ chat.chatEntity.name }}
            </h3>
            <span class="chat-card-users">{{ users }}</span>
        </div>
    </div>
</div>

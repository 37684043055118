<h3>
    <i class="uil uil-sort-amount-down margin-right-1"></i>
    <span>Trier par</span>
</h3>

<i class="uil uil-times-circle close" (click)="close()"></i>

<omedom-radio
    [options]="options"
    class="margin-bottom-2"
    type="column"
    name="sortOption"
    [(ngModel)]="selectedOption"
></omedom-radio>

<div class="button-container margin-top-2">
    <button
        class="omedom-button-secondary margin-right-1"
        (click)="resetSort()"
    >
        Effacer
    </button>
    <button class="omedom-button-primary flex-1" (click)="sendSort()">
        Afficher les résultats
    </button>
</div>

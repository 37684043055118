import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'omedom-subscription-redirect-apple',
    templateUrl: './subscription-redirect-apple.component.html',
    styleUrls: ['./subscription-redirect-apple.component.scss'],
})
export class SubscriptionRedirectAppleComponent {

    constructor(
        private modalController: ModalController,
    ) { }

    public dismiss(): void {
        this.modalController.dismiss();
    }
}

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserEntity } from '@omedom/data';
import { UserService } from '@omedom/services';
import { OmedomLevel } from '@omedom/utils';
import { Subscription } from 'rxjs';

@Component({
    selector: 'omedom-level-progress',
    templateUrl: './level-progress.component.html',
    styleUrls: ['./level-progress.component.scss'],
    animations: [
        trigger('left', [
            transition(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateX(0)' })),
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' })),
            ]),
        ]),
        trigger('right', [
            transition(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateX(0)' })),
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' })),
            ]),
        ]),
        trigger('up', [
            transition(':enter', [
                style({ transform: 'translateY(100%)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateY(0)' })),
            ]),
            transition(':leave', [
                style({ transform: 'translateY(0)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateY(100%)' })),
            ]),
        ]),
    ],
})
export class LevelProgressComponent implements OnInit, OnDestroy {
    /**
     * @description User Data
     * @author Jérémie Lopez
     * @type {UserEntity}
     * @memberof LevelProgressComponent
     */
    public user!: UserEntity;

    /**
     * @description List of subscriptions to unsub after destroy the component
     * @author Jérémie Lopez
     * @private
     * @type {Subscription[]}
     * @memberof LevelProgressComponent
     */
    private subscriptions: Subscription[] = [];

    constructor(private userService: UserService) {}

    ngOnInit(): void {
        const user$ = this.userService.user$.subscribe((user) => (this.user = user));

        this.subscriptions.push(user$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    get level(): number {
        return OmedomLevel.getLevel(this.user.dom ?? 0);
    }

    get progress(): number {
        return OmedomLevel.getProgressLevel(this.user.dom ?? 0);
    }

    get nextLevel(): number {
        return OmedomLevel.getNextLevel(this.user.dom ?? 0);
    }

    get nextLevelValue(): number {
        return OmedomLevel.getNextLevelValue(this.user.dom ?? 0);
    }

    get levelValue(): number {
        return OmedomLevel.getLevelValue(this.user.dom ?? 0);
    }
}

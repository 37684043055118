import { Storage, UserEntity } from '@omedom/data';

/**
 * @description Class to manage storage functions
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 05/09/2023
 * @export
 * @class OmedomStorage
 */
export class OmedomStorage {
    /**
     * @description Get the percentage of storage used by the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/09/2023
     * @static
     * @param {UserEntity} user User to get the percentage of storage used
     * @returns {number} Percentage of storage used
     * @memberof OmedomStorage
     * @example
     * OmedomStorage.getPercentage(user);
     */
    public static getPercentage(user: UserEntity): number {
        return (user.storageUsed ?? 0) / Storage.limit * 100;
    }

    /**
     * @description Get the percentage of staging used by the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/09/2023
     * @static
     * @param {UserEntity} user User to get the percentage of staging used
     * @param {number} staging Staging to get the percentage of staging used
     * @returns {number} Percentage of staging used
     * @memberof OmedomStorage
     * @example
     * OmedomStorage.getStaging(user, staging);
     */
    public static getStaging(user: UserEntity, staging: number): number {
        // Check if the user has a staging
        const value = staging / Storage.limit * 100;

        // Get the percentage of storage used
        const percentage = this.getPercentage(user);

        // Return the min value
        return Math.min(value, 100 - percentage);
    }

    /**
     * @description Get the total usage of the user (staging + storage used) in percentage
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/09/2023
     * @static
     * @param {UserEntity} user User to get the total usage
     * @param {number} staging Staging to get the total usage
     * @returns {number} Total usage of the user
     * @memberof OmedomStorage
     * @example
     * OmedomStorage.getTotalUsage(user, staging);
     */
    public static getTotalUsage(user: UserEntity, staging: number): number {
        return this.getPercentage(user) + this.getStaging(user, staging);
    }

    /**
     * @description Check if the storage is full for the user with the staging file size in bytes
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/09/2023
     * @static
     * @param {UserEntity} user User to check if the storage is full
     * @param {number} staging Staging file size in bytes
     * @returns {boolean} True if the storage is full
     * @memberof OmedomStorage
     * @example
     * OmedomStorage.isStorageFull(user, staging);
     */
    public static isStorageFull(user: UserEntity, staging: number): boolean {
        // Check if the user has a staging
        const value = staging / Storage.limit * 100;

        // Get the percentage of storage used
        const percentage = this.getPercentage(user);

        // Return if the storage is full
        return value + percentage >= 100;
    }

    /**
     * @description Display the size in bytes, KB, MB or GB depending on the size value in bytes in french format
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/10/2023
     * @static
     * @param {number} size
     * @returns {*}  {string}
     * @memberof OmedomStorage
     * @example
     * OmedomStorage.displaySize(size);
     */
    static displaySize(size: number): string {
        // Check if the size is null
        if (!size) {
            return '0 B';
        }

        // Get the size in bytes
        const bytes = size;

        // Get the size in KB
        const kilobytes = bytes / 1024;

        // Get the size in MB
        const megabytes = kilobytes / 1024;

        // Get the size in GB
        const gigabytes = megabytes / 1024;

        // Return the size in the right format
        if (gigabytes > 1) {
            return `${gigabytes.toFixed(2).replace('.', ',')} Go`;
        } else if (megabytes > 1) {
            return `${megabytes.toFixed(2).replace('.', ',')} Mo`;
        } else if (kilobytes > 1) {
            return `${kilobytes.toFixed(2).replace('.', ',')} Ko`;
        } else {
            return `${bytes.toFixed(0).replace('.', ',')} o`;
        }
    }
}

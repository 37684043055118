export class OmedomChartLegend {
    color!: string;
    label!: string;
    amount!: number;
    percent!: number;
    previsionLabel?: boolean;
    disabled?: boolean;

    constructor(init?: Partial<OmedomChartLegend>) {
        Object.assign(this, init);
    }
}

export interface MultiFactorAuthentification {
    email: MultiFactorDetails;
    phone: MultiFactorDetails;
    userUID: string;
}

export interface MultiFactorDetails {
    enabled: boolean;
    value: string;
}

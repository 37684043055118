<div class="conditions-container">
    <omedom-checkbox
        class="omedom-checkBox"
        [disabled]="!client"
        [(ngModel)]="hasAgreedToShareBankTotalBalance"
        (ngModelChange)="toggleShareConditions()"
    >
        J'accepte de partager mon solde bancaire global avec mon pro
    </omedom-checkbox>

    <div class="bank-item-container margin-top-2">
        <div class="bank-item-card">
            <div class="bank-item-card-image">
                <i class="uil uil-balance-scale"></i>
            </div>
            <div class="bank-item-card-content">
                <h3>Solde Global</h3>
                <span
                    [class]="{
                        'balance-positive': overallBalance > 0,
                        'balance-negative': overallBalance < 0
                    }"
                    >{{ overallBalance | currency : 'EUR' }}</span
                >
            </div>
        </div>
    </div>
</div>

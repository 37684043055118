import { FormControl } from '@angular/forms';

/**
 * @description Bank transaction category form type
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 20/05/2024
 * @type {BankTransactionCategoryForm}
 * @memberof BankTransactionCategoryStepContainer
 */
export type BankTransactionCategoryForm = {
    /**
     * @description Category of the transaction (mandatory)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/05/2024
     * @type {FormControl<string>}
     */
    category: FormControl<string>;
};

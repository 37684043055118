import { RestEntity } from './rest.entity';
export class ContactEntity extends RestEntity {
    /**
     * @description the subject of the email
     * @author Hanane Djeddal
     * @type {string}
     * @memberof EmailEntity
     */
    public subject!: string;

    /**
     * @description the text message of the email
     * @author Hanane Djeddal
     * @type {string}
     * @memberof EmailEntity
     */
    public message!: string;

    /**
     * @description the email to send to
     * @author Hanane Djeddal
     * @type {string}
     * @memberof EmailEntity
     */
    public sendto!: string;

    /**
     * @description the email of the sender (user of the app)
     * @author Hanane Djeddal
     * @type {string}
     * @memberof EmailEntity
     */
    public senderEmail!: string;

    /**
     * @description the first of the sender (user of the app)
     * @author Hanane Djeddal
     * @type {string}
     * @memberof EmailEntity
     */
    public senderFirstName!: string;

    /**
     * @description the name of the sender (user of the app)
     * @author Hanane Djeddal
     * @type {string}
     * @memberof EmailEntity
     */
    public senderName!: string;

    /**
     * @description link to the user
     * @author Hanane Djeddal
     * @type {string}
     * @memberof EmailEntity
     */
    public userUID!: string;
}

<div class="question-container">
    <p>
        Dites-nous : quel est votre pire cauchemar ? On a peut-être la solution
        !
    </p>
    <div class="questions-list">
        @for (qa of questions; track qa.question) {
        <omedom-questions
            [selected]="qa.question === selectedQuestion?.question"
            [qa]="qa"
            (clickEvent)="selectQuestion($event)"
        >
        </omedom-questions>
        }
    </div>
</div>
@if (selectedQuestion) {
<div class="answer-container">
    <h3>La réponse d'OMEDOM :</h3>
    <div class="answer-content">
        <p>{{ selectedQuestion?.answer }}</p>
    </div>
</div>
}

<omedom-modal-header>Changer le destinataire d'une licence</omedom-modal-header>

<div class="container">
  <form [formGroup]="exchangeForm"
    (ngSubmit)="exchange()">
    <omedom-input [boldLabel]="true"
      formControlName="oldEmail"
      label="Ancienne adresse e-mail"
    type="email"></omedom-input>

    <omedom-input [boldLabel]="true"
      formControlName="newEmail"
      label="Nouvelle adresse e-mail"
      placeholder="Renseignez l'e-mail de votre client"
    type="email"></omedom-input>

    @if (errorMessage) {
      <span class="omedom-form-error"
      >{{ errorMessage }}</span>
    }

    <p>
      Votre client va recevoir un e-mail lui indiquant les étapes à suivre pour l'installation de
      l'application OMEDOM et l'activation de sa licence.<br>
      Une copie de cet e-mail vous sera envoyé.
    </p>

    <button type="submit"
      [disabled]="!exchangeForm.valid || pending"
      class="omedom-button-yellow">
      <i class="uil uil-message"></i>
      Envoyer
      @if (pending) {
        <ion-spinner></ion-spinner>
      }
    </button>
  </form>
</div>

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DocumentEntity, PropertyEntity, RoleState, defaultRoleState } from '@omedom/data';
import { AnalyticsService, DocumentService } from '@omedom/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'omedom-document-single-property',
    templateUrl: './document-single-property.component.html',
    styleUrls: ['./document-single-property.component.scss'],
})
export class DocumentSinglePropertyComponent implements OnInit, OnDestroy {
    @Input()
    public property: PropertyEntity = {} as PropertyEntity;

    @Input()
    public editable = true;

    // @Input({ required: true }) roleState: RoleState = defaultRoleState;

    /**
     * @description Emit event to open document form page with property uid
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 12/06/2024
     * @memberof DocumentSinglePropertyComponent
     */
    @Output() addDocumentClicked = new EventEmitter<string>();

    /**
     * @description List of documents of the property
     * @author Jérémie Lopez
     * @type {DocumentEntity[]}
     * @memberof DocumentSinglePropertyComponent
     */
    public documents: DocumentEntity[] = [];

    /**
     * @description If true, the view has been init a first time
     * @author Jérémie Lopez
     * @private
     * @memberof DocumentSinglePropertyComponent
     */
    private firstViewInit = false;

    private subcriptions: Subscription[] = [];

    constructor(
        private documentService: DocumentService,
        private platform: Platform,
        private analyticsService: AnalyticsService
    ) {}

    ngOnInit(): void {
        this.searchEntities();
        this.firstViewInit = true;
    }

    ngOnDestroy(): void {
        this.subcriptions.forEach((sub) => sub.unsubscribe());
    }
    /**
     * @description If true, the app is in desktop mode
     * @author Jérémie Lopez
     * @readonly
     * @type {boolean}
     * @memberof DocumentSinglePropertyComponent
     */
    public get isDesktop(): boolean {
        return this.platform.is('desktop');
    }

    /**
     * @description Ionic Hook Life cycle when the page will be display an other time
     * @author Jérémie Lopez
     * @memberof DocumentSinglePropertyComponent
     */
    // async ionViewWillEnter() {
    //     if (this.firstViewInit) {
    //         await this.searchEntities();
    //     }
    // }
    ionViewWillEnter() {
        if (this.firstViewInit) {
            this.searchEntities();
        }
    }

    ionViewDidEnter(): void {
        this.analyticsService.setCurrentScreen('Document single property tab');
    }

    /**
     * @description Search user's societies by owned and shared societies
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof DocumentSinglePropertyComponent
     */
    // async searchDocuments(): Promise<void> {
    //     this.documents = await this.documentService.getPropertyDocuments(this.property.uid);
    // }

    _searchDocuments(): void {
        const documents$ = this.documentService
            ._getPropertyDocuments(this.property.uid)
            .subscribe((propertyDocuments) => {
                this.documents = propertyDocuments;
            });
        this.subcriptions.push(documents$);
    }

    /**
     * @description Search entities
     * @author Jérémie Lopez
     * @param {*} [fromRefresher]
     * @return {*}  {Promise<void>}
     * @memberof DocumentSinglePropertyComponent
     */
    // async searchEntities(): Promise<void> {
    //     await this.searchDocuments();
    // }
    searchEntities(): void {
        this._searchDocuments();
    }

    /**
     * @description Emit event to open document form page with property uid
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 12/06/2024
     * @returns {*}  {void}
     * @memberof DocumentSinglePropertyComponent
     */
    public goToDocumentForm(): void {
        if (this.property?.uid) {
            this.addDocumentClicked.emit(this.property.uid);
        }
    }
}

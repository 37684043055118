import { BankTransactionRecurrenceDetail } from './bank-transaction-recurrence-detail.interface';

/**
 * @description Result of the recurrence calculation for a transaction and a treasury
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 18/03/2024
 * @export
 * @interface BankTransactionRecurrenceResult
 */
export interface BankTransactionRecurrenceResult {
    /**
     * @description Transaction uid compared
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 16/05/2024
     * @type {string}
     * @memberof BankTransactionRecurrenceResult
     */
    transactionUID: string;

    /**
     * @description The score of the recurrence, the higher the score, the more likely the transaction is recurrent. The score is calculated based on the amount, the date and the category of the transaction and the treasury.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 18/03/2024
     * @type {number}
     * @memberof BankTransactionRecurrenceResult
     */
    score: number;

    /**
     * @description The details of the recurrence calculation, the amount, the date and the category of the transaction and the treasury are compared to calculate the recurrence score.
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 18/03/2024
     * @type {{
     *         amount: BankTransactionRecurrenceDetail<number>;
     *         date: BankTransactionRecurrenceDetail<Date>;
     *         category: BankTransactionRecurrenceDetail<number>;
     *     }}
     * @memberof BankTransactionRecurrenceResult
     */
    details: {
        amount: BankTransactionRecurrenceDetail<number>;
        date: BankTransactionRecurrenceDetail<Date>;
        category: BankTransactionRecurrenceDetail<number>;
    };
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ValidationModalComponent } from '../validation-modal/validation-modal.component';
import { BankAccountEntity, BankAccountType, BankItemEntity, ClientEntity } from '@omedom/data';

@Component({
    selector: 'omedom-accept-share-banking-balance-to-pro',
    templateUrl: './accept-share-banking-balance-to-pro.component.html',
    styleUrls: ['./accept-share-banking-balance-to-pro.component.scss'],
})
export class AcceptShareBankingBalanceToProComponent implements OnChanges {
    @Input({ required: true }) client!: ClientEntity;

    @Input({ required: true }) bankItems: BankItemEntity[] = [];

    @Input({ required: true }) bankAccounts: BankAccountEntity[] = [];

    @Output() valueChanged = new EventEmitter();

    hasAgreedToShareBankTotalBalance: boolean = false;

    public get overallBalance() {
        if (this.bankItems.length === 0 || this.bankAccounts.length === 0) {
            return 0;
        }
        return this.bankItems.reduce((acc, item) => {
            return this.getBalanceOfBankItem(item);
        }, 0);
    }

    constructor(private modalController: ModalController) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.hasAgreedToShareBankTotalBalance = this.client.hasAgreedToShareBankTotalBalance;
    }

    async toggleShareConditions() {
        if (!this.hasAgreedToShareBankTotalBalance) {
            this.valueChanged.emit(false);
            return;
        }

        const modal = await this.modalController.create({
            component: ValidationModalComponent,
            canDismiss: true,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            backdropDismiss: true,
            componentProps: {
                title: 'Partager à un pro',
                information: 'Vous êtes sur le point de partager votre solde global à un pro',
                message:
                    "Je certifie être seul(e) titulaire de ce(s) compte(s) bancaire(s) OU Je certifie avoir l'accord du co-titulaire pour le partage du solde global des comptes bancaires à un Pro de confiance.",
                iconInfo: '',
                validateButtonMessage: 'Oui',
                cancelButtonMessage: 'Non',
            },
        });

        modal.present();

        modal.onDidDismiss().then(async (res) => {
            const confirmation = res.data;
            if (confirmation) {
                this.valueChanged.emit(true);
            } else {
                this.hasAgreedToShareBankTotalBalance = false;
            }
        });
    }

    private getBalanceOfBankItem(bankItem: BankItemEntity): number {
        const bankAccounts = this.bankAccounts.filter(
            (bankAccount) => bankAccount.itemID === bankItem.bridgeID
        );
        return this.getBalanceOfBankAccounts(bankAccounts);
    }

    private getBalanceOfBankAccounts(bankAccounts: BankAccountEntity[]): number {
        return bankAccounts.reduce((acc, account) => {
            // Do not display the balance of a loan account
            // Or a brokerage account
            // Or a shared saving plan account
            if (
                account.type === BankAccountType.Loan ||
                account.type === BankAccountType.Brokerage ||
                account.type === BankAccountType.SharedSavingPlan ||
                account.type === BankAccountType.LifeInsurance ||
                account.type === BankAccountType.Savings
            ) {
                return acc;
            }

            return acc + account.balance;
        }, 0);
    }
}

<div class="section">
  <div class="field">
    <i class="uil uil-user"></i>
    <strong>{{ share?.firstname }} {{ share?.name }}</strong>
  </div>
  @if (type === societyType && memberStatus) {
    <div class="info">
      <span class="gre">Membre: {{ memberStatus?.toLowerCase() }}</span>
    </div>
  }
  <div class="action">
    @if (editable && !isSocietyMember) {
      <button
        class="action-button"
        (click)="openDeleteModal(modal)"
        [disabled]="isSocietyMember"
        >
        <i class="uil uil-trash-alt"></i>
      </button>
    }
  </div>
</div>

<div class="section">
  @if (share?.email) {
    <div class="field">
      <i class="uil uil-envelope"></i>
      <span>{{ share?.email }}</span>
    </div>
  }
  @if (!isShared) {
    <div class="info">
      <span class="grey">{{ share?.role }}</span>
    </div>
  }
  <div class="action">
    @if (editable) {
      <button class="action-button" (click)="edit()">
        <i class="uil uil-edit-alt"></i>
      </button>
    }
  </div>
</div>

<ion-modal
  #modal
  trigger="delete{{ sharingIndex }}"
  [breakpoints]="[0, 1]"
  initialBreakpoint="1"
  >
  <ng-template>
    <div class="omedom-modal">
      <div class="omedom-modal-header">
        <div class="omedom-modal-header-title">
          <i
            class="uil uil-trash-alt color-light-black background-color-light-orange"
          ></i>
          <h3>Suppression du partage</h3>
        </div>
        <i class="uil uil-times-circle" (click)="modal.dismiss()"></i>
      </div>
      <div class="omedom-modal-content">
        <p>Êtes-vous sûr.e de vouloir supprimer ce partage ?</p>
        <omedom-info
                    information="En supprimant ce partage, ce dernier pert ces accès à l'ensemble des données {{
                        type === 'property' || type === 'building'
                            ? 'du bien'
                            : 'de la société'
                    }}."
          >
        </omedom-info>
      </div>
      <div class="omedom-modal-buttons">
        <button
          class="omedom-button-secondary"
          (click)="modal.dismiss()"
          >
          Annuler
        </button>
        <button class="omedom-button-warn" (click)="delete(modal)">
          <i class="uil uil-trash-alt"></i>
          Oui supprimer
        </button>
      </div>
    </div>
  </ng-template>
</ion-modal>

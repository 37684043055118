import { Component, Input, Output, EventEmitter } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormItem, FormItemName, RoleState } from '@omedom/data';

import { elementAnimation } from '../../animations/element.animation';
import { listAnimation } from '../../animations/list.animation';

import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { ModalController, Platform, } from '@ionic/angular';
import { ModalSuggestSubscriptionComponent } from '../modal-suggest-subscription/modal-suggest-subscription.component';

enum AddItemLayoutEnum {
    DEFAULT = 'default',
    MOBILE = 'mobile',
}
@Component({
    selector: 'omedom-add-item',
    templateUrl: './add-item.component.html',
    styleUrls: ['./add-item.component.scss'],
    animations: [
        trigger('left', [
            transition(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate('0.28s ease-out', style({ transform: 'translateX(0)' })),
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)' }),
                animate('0.28s ease-out', style({ transform: 'translateX(-100%)' })),
            ]),
        ]),
        trigger('opacity', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('0.28s ease-out', style({ opacity: '1' })),
            ]),
            transition(':leave', [
                style({ opacity: '1' }),
                animate('0.28s ease-out', style({ opacity: '0' })),
            ]),
        ]),

        elementAnimation,
        listAnimation,
    ],
})
export class AddItemComponent {

    /**
     * @description List of forms to display in the forms bar to select the form to display in the form container
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 30/09/2024
     * @type {FormItem[]}
     * @memberof AddItemPage
     */
    @Input() formList: FormItem[] = [];

    /**
     * @description Form selected by the user in the forms bar to display the form in the form container
     * @author Brisset Killian
     * @date 15/06/2024
     * @type {(FormItem | null)}
     * @memberof FormsBarComponent
     */
    @Input() selectedForm: FormItem | null = null;


    @Output()
    formSelected = new EventEmitter<FormItem | null>();

    @Output()
    goToSubClicked = new EventEmitter();

    /**
     * @description Event emitter to emit the change of the open property to know if the forms bar is open or not to display
     * @author Brisset Killian
     * @date 15/06/2024
     * @memberof FormsBarComponent
     */
    @Output() openChange = new EventEmitter<boolean>();

    protected isDesktop?: boolean;

    public roleState?: RoleState;

    get primaryForms() {
        return this.formList
            .filter((form) => form.type === 'primary')
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
    }

    get secondaryForms() {
        return this.formList
            .filter((form) => form.type === 'secondary')
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
    }

    get hasSecondaryForms() {
        return (
            (this.formList?.filter((form) => form.type === 'secondary' && !form.disabled) || [])
                .length > 0
        );
    }
    protected layout = AddItemLayoutEnum.DEFAULT;

    constructor(
        private modalController: ModalController,
        private platform: Platform,
    ) {
        if (!this.platform.is('desktop')) {
            this.layout = AddItemLayoutEnum.MOBILE;
        }
    }

    /**
       * @description Select the form to display in the form container
       * @author Brisset Killian
       * @date 15/06/2024
       * @param {FormName} formName
       * @memberof FormsBarComponent
       */
    async selectForm(formName: FormItemName | null): Promise<void> {
        if (formName) {
            const selectedForm = this.formList.find((elem) => elem.id === formName) || null;
            if (selectedForm?.isLocked$) {
                const isLocked = await lastValueFrom(selectedForm.isLocked$.pipe(take(1)));
                if (isLocked) {
                    const modal = await this.modalController.create({
                        component: ModalSuggestSubscriptionComponent,
                        breakpoints: [0, 1],
                        initialBreakpoint: 1,
                        canDismiss: true,
                        componentProps: {
                            message: "Fonctionnalité Premium",
                            buttonText: 'Changer mon abonnement',
                        },
                    });

                    await modal.present();
                    return;
                }
            }
            this.selectedForm = selectedForm;
        }
        this.formSelected.emit(this.selectedForm || null);
    }
}

<form [formGroup]="form"
      [@list]="keys">
    <omedom-input [boldLabel]="true"
                  formControlName="depositAmount"
                  label="Montant déposé"
                  placeholder="0"
                  iconEnd="uil uil-euro"
                  type="number"></omedom-input>

    <omedom-input [boldLabel]="true"
                  formControlName="withdrawAmount"
                  label="Montant retiré"
                  placeholder="0"
                  iconEnd="uil uil-euro"
                  type="number"></omedom-input>

    <hr class="omedom-divider omedom-no-margin">

    <omedom-input [boldLabel]="true"
                  formControlName="nextDepositDate"
                  label="Date du prochain dépôt"
                  placeholder="01/09/2024"
                  type="date"></omedom-input>

    <omedom-input [boldLabel]="true"
                  formControlName="nextDepositAmount"
                  label="Montant du prochain dépôt"
                  placeholder="100"
                  iconEnd="uil uil-euro"
                  type="number"></omedom-input>
</form>

<div class="container">
    <!-- Content -->
    <div class="container-content">
        @if (property || society || loan || saving) {
        <div class="assets-container w-100">
            <!-- <img
        *ngIf="property.photo"
        [src]="property.photo"
        class="photo"
        alt="Avatar de la propriété"
        />
      <div
        class="property-icon background-color-light-grey"
        *ngIf="!property.photo"
        >
        <i class="uil uil-home"></i>
      </div>
      <span class="property-title margin-left-1">{{
        property.name
      }}</span> -->
            @if (property) {
            <div class="asset-container">
                @if (property.photo) {
                <img
                    [src]="property.photo"
                    class="photo"
                    alt="Image de la propriété"
                />
                } @if (!property.photo) {
                <div class="property-icon background-color-light-grey">
                    <i class="uil uil-home"></i>
                </div>
                }
                <span class="property-title margin-left-1">{{
                    property.name
                }}</span>
            </div>
            } @if (society) {
            <div class="asset-container">
                @if (society.photo) {
                <img
                    [src]="society.photo"
                    class="photo"
                    alt="Image de la société"
                />
                } @if (!society.photo) {
                <div class="property-icon background-color-light-grey">
                    <i class="uil uil-home"></i>
                </div>
                }
                <span class="property-title margin-left-1">{{
                    society.name
                }}</span>
            </div>
            } @if (loan) {
            <div class="asset-container">
                <div class="loan-icon">
                    <i class="uil uil-euro-circle"></i>
                </div>
                <span class="property-title margin-left-1">{{
                    loan.name
                }}</span>
            </div>
            } @if (saving) {
            <div class="asset-container">
                <div class="saving-icon">
                    <i class="uil uil-wallet"></i>
                </div>
                <span class="property-title margin-left-1">{{
                    saving.name
                }}</span>
            </div>
            }
        </div>
        } @if (property || society || loan || saving) {
        <hr class="omedom-divider w-100" />
        }
        <div class="document-container padding-bottom-2">
            <span class="document-name">{{ document?.name }}</span>
            @if (document?.date) {
            <span class="document-date">{{
                document?.date | date : 'dd/MM/yyyy'
            }}</span>
            }
        </div>

        @if (sanitized$ | async) {
        <div
            class="viewer-container margin-bottom-1"
            [style.flex]="isBlobTypeOther() ? '1' : '0'"
        >
            @if (isBlobType('doc')) {
            <!------------------------------------------
        View PDF in native Android
        ------------------------------------------->
            @if (isAndroid) { @if (sanitized$ | async) {
            <div class="viewer-pdf mobile">
                <button
                    class="omedom-button-secondary"
                    (click)="displayPDF(document?.fileURL)"
                >
                    <i class="uil uil-eye"></i>
                    visualiser
                </button>
            </div>
            } } @else { @if (sanitized$ | async) {
            <div class="viewer-pdf">
                <iframe
                    class="viewer-pdf"
                    [src]="documentURL"
                    allowAutoPlay="true"
                    title="PDF Viewer"
                >
                </iframe>
            </div>
            } }
            <!--  end of View PDF in native Android -->
            <!------------------------------------------
        View PDF on Web
        ------------------------------------------->
            <!--  end of View PDF on Web -->
            } @if (isBlobType('image')) {
            <img
                [src]="documentURL"
                class="viewer-image"
                alt="Document"
                alt="Image de la document"
            />
            } @if (isBlobType('audio')) {
            <audio controls class="viewer-audio">
                <source
                    [src]="document?.fileURL"
                    [type]="document?.extension"
                />
                Impossible de lire le fichier audio.
            </audio>
            } @if (isBlobType('video')) {
            <video controls class="viewer-video">
                <source
                    [src]="document?.fileURL"
                    [type]="document?.extension"
                />
                Impossible de lire le fichier vidéo.
            </video>
            } @if (isBlobTypeOther()) {
            <i class="uil uil-eye"></i>
            <span class="margin-top-1"
                >Impossible de visualiser le fichier.</span
            >
            }
        </div>
        }

        <span class="file-name padding-1">{{ fileName }}</span>

        <div class="omedom-sticky-button-container top-100 w-100 margin-top-2">
            <!-- DELETE -->
            @if (roleRight?.delete) {
            <button
                class="omedom-button-warn flex-1 margin-right-1"
                (click)="delete()"
                [disabled]="pendingDocumentAction$.asObservable() | async"
                type="button"
            >
                @if ( (pendingDocumentDeletion$.asObservable() | async) ===
                false ) {
                <i class="uil uil-trash-alt"></i>
                } @if (pendingDocumentDeletion$.asObservable() | async) {
                <ion-spinner
                    color="primary"
                    class="form-upload-spinner"
                ></ion-spinner>
                }
            </button>
            }

            <!-- DOWNLOAD -->
            @if (roleRight?.update) {
            <a
                class="omedom-button-primary flex-1 margin-right-1"
                [class]="
                    (pendingDocumentDeletion$.asObservable() | async)
                        ? 'disabled'
                        : ''
                "
                [href]="document?.fileURL"
                [download]="fileName"
                target="_blank"
                type="button"
                rel="noopener"
            >
                <i class="uil uil-import"></i>
            </a>
            }

            <!-- EDIT -->
            @if (roleRight?.update) {
            <button
                class="omedom-button-primary flex-1"
                (click)="edit()"
                [disabled]="pendingDocumentAction$.asObservable() | async"
                type="button"
            >
                <i class="uil uil-edit-alt"></i>
            </button>
            }
        </div>
    </div>
</div>

import { RestEntity } from './rest.entity';
import { Sharing } from './property.entity';

export interface UserFamilyInvitation {
    invitationUID: string;
    familyName: string;
}

export class FamilyInvitationEntity extends RestEntity {
    /**
     * @description User who's the family is assigned
     * @author Vidjay Seganti (Keleo)
     * @type {string}
     * @memberof FamilyEntity
     */
    public userUID!: string;

    /**
     * @description List of uid of the family members
     * @author Vidjay Seganti (Keleo)
     * @type {string[]}
     * @memberof FamilyEntity
     */
    public insideInvitations: string[] = [];

    /**
     * @description Name of the property in the app
     * @author Vidjay Seganti (Keleo)
     * @type {Sharing[]}
     * @memberof FamilyEntity
     */
    public outsideInvitations: Sharing[] = [];
}

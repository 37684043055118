import { Component, Input } from '@angular/core';
import { SocietyEntity, SocietyType } from '@omedom/data';

@Component({
    selector: 'omedom-society-info-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
})
export class SocietyInfoDetailsComponent {
    /**
     * @description Society to display informations about it
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 22/10/2024
     * @type {SocietyEntity}
     * @memberof SocietyInfoDetailsComponent
     */
    @Input() society?: SocietyEntity;

    /**
     * @description Society types enum
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 22/10/2024
     * @memberof SocietyInfoDetailsComponent
     */
    societyTypes = SocietyType;
}

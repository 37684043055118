<form [formGroup]="form"
      [@list]="keys">
    <omedom-input [boldLabel]="true"
                  formControlName="openingDate"
                  label="Date d'ouverture"
                  placeholder="01/01/2021"
                  type="date"></omedom-input>

    <omedom-input [boldLabel]="true"
                  formControlName="maturityDate"
                  label="Date de dernière échéance"
                  placeholder="01/01/2041"
                  type="date"></omedom-input>

    <hr class="omedom-divider omedom-no-margin">

    <omedom-input [boldLabel]="true"
                  formControlName="nextPaymentDate"
                  label="Date du prochain prélèvement"
                  placeholder="01/09/2024"
                  type="date"></omedom-input>

    <omedom-input [boldLabel]="true"
                  formControlName="nextPaymentAmount"
                  label="Montant du prochain prélèvement"
                  placeholder="1 700"
                  iconEnd="uil uil-euro"
                  type="number"></omedom-input>
</form>

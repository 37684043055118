import { Observable, of } from 'rxjs';

import { SelectOption } from '../models';
import { AssetTypes } from '../enums';

/**
 * @description This class is used to store the properties filtered by the user
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 09/08/2023
 * @export
 * @class PropertiesFilter
 */
export class PropertiesFilter {
    /**
     * @description The properties filtered by the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @static
     * @type {SelectOption[]}
     * @memberof PropertiesFilter
     */
    public static filteredProperties: SelectOption[] = [];

    /**
     * @description the societies filtered by the user
     * @author ANDRE Felix
     * @static
     * @type {SelectOption[]}
     * @memberof PropertiesFilter
     */
    public static filteredSocieties: SelectOption[] = [];

    /**
     * @description The properties filtered by the user as an observable
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @static
     * @type {Observable<SelectOption[]>}
     * @memberof PropertiesFilter
     */
    public static filteredProperties$: Observable<SelectOption[]> = of([]);

    /**
     * @description The societies filtered by the user as observable
     * @author ANDRE Felix
     * @static
     * @type {Observable<SelectOption[]>}
     * @memberof PropertiesFilter
     */
    public static filteredSocieties$: Observable<SelectOption[]> = of([]);

    /**
     * @description Update the properties filtered by the user and update the observable with the new value
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @static
     * @param {SelectOption[]} properties
     * @memberof PropertiesFilter
     */
    public static updateProperties(assets: SelectOption[]) {
        this.filteredProperties = assets.filter(
            (assetOptions) =>
                assetOptions.assetType === AssetTypes.property ||
                assetOptions.assetType === AssetTypes.building
        );
        this.filteredSocieties = assets.filter(
            (assetOptions) => assetOptions.assetType === AssetTypes.society
        );

        this.filteredProperties$ = of(this.filteredProperties);
        this.filteredSocieties$ = of(this.filteredSocieties);
    }

    /**
     * @description Get the properties filtered by the user as an array of SelectOption
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @static
     * @returns {*}  {SelectOption[]}
     * @memberof PropertiesFilter
     */
    public static getFilteredProperties(): SelectOption[] {
        return [...this.filteredProperties];
    }

    public static getFilteredSocieties(): SelectOption[] {
        return [...this.filteredSocieties];
    }
}

<div class="security">
  <div class="security-bar">
    @for (item of [1, 2, 3, 4, 5]; track item) {
      <div
        class="security-bar-item margin-right-1"
            [ngClass]="{
                'security-bar-item--weak':
                    passwordStrengthExplicit === 'Faible' &&
                    passwordStrength >= item,
                'security-bar-item--medium':
                    passwordStrengthExplicit === 'Moyen' &&
                    passwordStrength >= item,
                'security-bar-item--strong':
                    passwordStrengthExplicit === 'Fort' &&
                    passwordStrength >= item
            }"
      ></div>
    }
    <span class="security-bar-text">{{
      passwordStrengthExplicit
    }}</span>
  </div>

  <div class="security-text">
    <div class="security-text-title">
      <i class="uil uil-info-circle margin-right-1"></i>
      <span>Votre mot de passe doit comporter au moins :</span>
    </div>
    <ul class="security-text-list">
      <li
                [ngClass]="{
                    'security-text-item--valid': numberOfCaracters === 1,
                    'security-text-item--invalid': numberOfCaracters === 0
                }"
        >
        8 caractères minimum
      </li>
      <li
                [ngClass]="{
                    'security-text-item--valid': hasMajorLetter === 1,
                    'security-text-item--invalid': hasMajorLetter === 0
                }"
        >
        1 majuscule
      </li>
      <li
                [ngClass]="{
                    'security-text-item--valid': hasMinorLetter === 1,
                    'security-text-item--invalid': hasMinorLetter === 0
                }"
        >
        1 miniscule
      </li>
      <li
                [ngClass]="{
                    'security-text-item--valid': hasSpecialCaracters === 1,
                    'security-text-item--invalid': hasSpecialCaracters === 0
                }"
        >
        1 caractère spécial
      </li>
      <li
                [ngClass]="{
                    'security-text-item--valid': hasNumber === 1,
                    'security-text-item--invalid': hasNumber === 0
                }"
        >
        1 nombre
      </li>
    </ul>
  </div>
</div>

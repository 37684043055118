export * from './bank-error-message.interface';
export * from './bank-item-mfa.interface';
export * from './bank-loan-details.interface';
export * from './bank-savings-details.interface';
export * from './bank-status-report.interface';
export * from './bank-transaction-by-day.interface';
export * from './bank-transaction-recurrence-base.interface';
export * from './bank-transaction-recurrence-result.interface';
export * from './bank-transaction-recurrence-detail.interface';
export * from './bridge.interface';


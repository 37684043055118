<section class="informations-container">
  <div class="column" style="gap: 20px">
    @if (property?.photo) {
      <img
        [src]="property?.photo"
        class="informations-card-image"
        alt="Image du bien"
        />
    } @else {
      <div class="informations-card-image">
        <i class="uil uil-home"></i>
      </div>
    }

  </div>

  <div class="column">
    <omedom-property-info-details
      [property]="property"
    ></omedom-property-info-details>
  </div>

  <div class="column">
    <omedom-property-info-possession
      [property]="property"
    ></omedom-property-info-possession>
    <omedom-property-info-settings
      [property]="property"
    ></omedom-property-info-settings>
  </div>
</section>

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BankEntity } from '@omedom/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RestService } from './rest.service';

@Injectable({ providedIn: 'root' })
export class BankService extends RestService<BankEntity> {
    protected override builder = BankEntity;

    constructor(
        protected override firestore: AngularFirestore,
    ) {
        super(firestore, 'banks');
    }

    /**
     * @description Return bank from ID
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @param {number} bankID
     * @returns {Promise<BankEntity>}
     * @memberof BankService
     */
    public async getBankFromID(bankID: number): Promise<BankEntity> {
        return this.search([
            {
                where: 'bridgeID',
                operator: '==',
                value: bankID
            }
        ]).then(banks => banks[0]);
    }

    /**
     * @description Return bank from ID in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 25/04/2024
     * @param {number} bankID
     * @returns {Observable<BankEntity>}
     * @memberof BankService
     */
    public _getBankFromID(bankID: number): Observable<BankEntity> {
        return this._search([
            {
                where: 'bridgeID',
                operator: '==',
                value: bankID
            }
        ]).pipe(
            map(banks => banks[0])
        );
    }
}

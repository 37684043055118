import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ContactEntity } from '@omedom/data';

import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root',
})
export class ContactService extends RestService<ContactEntity> {
    constructor(protected override firestore: AngularFirestore) {
        super(firestore, 'contacts');
    }

    /**
     * @description Get the contact of a user by his UID
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID UID of the user
     * @returns {Promise<ContactEntity[]>} Contact of the user
     * @memberof ContactService
     * @example
     * const contacts = await contactService.getUserContact(userUID);
     */
    public async getUserContact(userUID: string): Promise<ContactEntity[]> {
        return await this.search([
            { where: 'userUID', operator: '==', value: userUID },
        ]);
    }
}

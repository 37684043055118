import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalizeFirstLetter',
})
@Injectable({ providedIn: 'root' })
export class OmedomCapitalizeFirstLetterPipe implements PipeTransform {

    transform(string: string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PropertyEntity } from '@omedom/data';
import { OmedomPricehubble, OmedomTransformPricehubbleData } from '@omedom/utils';
import { BehaviorSubject } from 'rxjs';

import { slideYInOutAnimation } from '../../../animations/slideYInOut';

@Component({
    selector: 'omedom-property-info-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    animations: [slideYInOutAnimation],
})
export class PropertyInfoSettingsComponent implements OnInit, OnChanges {
    indoorLayouts: string[] = [];

    outdoorLayouts: string[] = [];

    buildingLayouts: string[] = [];

    heatLayouts: string[] = [];

    isolationRenovationPeriod: undefined | string = undefined;

    atticType: undefined | string = undefined;

    /**
     * @description Display diagnostic info after collapse
     * @author Hanane Djeddal
     * @memberof PropertyFormComponent
     */
    public displayDiagnosticForm = new BehaviorSubject<boolean>(false);

    /**
     * @description Display diagnostic info after collapse
     * @author Hanane Djeddal
     * @memberof PropertyFormComponent
     */
    public displayRentForm = new BehaviorSubject<boolean>(false);

    /**
     * @description Display diagnostic info after collapse
     * @author Hanane Djeddal
     * @memberof PropertyFormComponent
     */
    public displayAmentiesForm = new BehaviorSubject<boolean>(false);

    private _property?: PropertyEntity | undefined;

    get property(): PropertyEntity | undefined {
        return this._property;
    }

    @Input()
    set property(value: PropertyEntity | undefined) {
        this._property = value;

        if (!!value?.amenities?.length) {
            this.indoorLayouts = value.amenities.filter((x) =>
                ['Cuisine équipée', 'Cheminée', 'Climatisation', 'Alarme'].includes(x)
            );
            this.outdoorLayouts = value.amenities.filter((x) =>
                [
                    'Balcon / Terrasse',
                    'Jardin / Cour',
                    'Garage / Parking',
                    'Cave / Sous sol',
                    'Piscine',
                    'Dépendance(s)',
                ].includes(x)
            );
            this.buildingLayouts = value.amenities.filter((x) =>
                ['Ascenseur', 'Gardien / Concierge', 'Digicode', 'Interphone'].includes(x)
            );
            this.heatLayouts = value.amenities
                .filter((x) =>
                    [
                        'Chauffage collectif',
                        'Chauffage individuel',
                        'Chauffage au gaz',
                        'Chauffage éléctrique',
                        'Chauffage au fuel',
                    ].includes(x)
                )
                .map((x) => x.split(' ').splice(-1)[0]);
        } else {
            this.indoorLayouts = [];
            this.outdoorLayouts = [];
            this.buildingLayouts = [];
            this.heatLayouts = [];
        }
    }

    isAssimilateHouse = false;
    isAssimilateAppartement = false;

    /**
     * @description
     * @author ANDRE Felix
     * @memberof PropertyInfoSettingsComponent
     */
    public displayPhysicalData = new BehaviorSubject<boolean>(false);

    ngOnInit(): void {
        this.isolationRenovationPeriod = OmedomTransformPricehubbleData.transformIsolation(
            this.property?.dpeDetails?.estimationInformations?.isolationRenovationPeriod
        );

        this.atticType = OmedomTransformPricehubbleData.transformAtticType(
            this.property?.dpeDetails?.estimationInformations?.atticType
        );
        this.isAssimilateHouse = OmedomPricehubble.isPropertyAssimilateHouse(this.property?.type);
        this.isAssimilateAppartement = OmedomPricehubble.isPropertyAssimilateAppartement(
            this.property?.type
        );
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['property']) {
            this.property = changes['property'].currentValue;
        }
    }
}

import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'omedom-bank-sync-success',
    templateUrl: './bank-sync-success.container.html',
    styleUrls: ['./bank-sync-success.container.scss']
})
export class BankSyncSuccessComponent {
    constructor(
        private modalController: ModalController,
    ) { }

    /**
     * @description Dismiss the modal component when the user click on the dismiss button
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankSyncSuccessComponent
     */
    public async dismiss() {
        await this.modalController.dismiss();
    }
}

<div class="omedom-modal">
    <div class="omedom-modal-header">
        <div class="omedom-modal-header-title">
            <i
                class="uil uil-trash-alt color-light-black background-color-light-orange"
            ></i>
            <h3>Suppression de document</h3>
        </div>
        <i class="uil uil-times-circle" (click)="dismiss()"></i>
    </div>
    <div class="omedom-modal-content">
        <span class="confirmation-text">
            Êtes-vous sûr.e de vouloir supprimer ce document ?
        </span>
    </div>
    <div class="omedom-modal-buttons">
        <button class="omedom-button-secondary" (click)="dismiss()">
            Annuler
        </button>
        <button class="omedom-button-warn" (click)="deleteConfirmed()">
            <i class="uil uil-trash-alt"></i>
            Oui, supprimer
        </button>
    </div>
</div>

import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'omedom-modal-override-document-validation',
    templateUrl: './modal-override-document-validation.component.html',
    styleUrls: ['./modal-override-document-validation.component.scss'],
})
export class ModalOverrideDocumentValidationComponent {
    @Input()
    private modalControllerParentInstance?: ModalController;

    @Input() validateMessage = 'Valider';

    @Input() rejectMessage = 'Non';

    @Input() infoMessage = '';

    @Input() titleMessage = '';

    closeModal(isValidate: boolean) {
        this.modalControllerParentInstance?.dismiss(isValidate);
    }
}

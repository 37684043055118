import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProService, UserService } from '@omedom/services';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RestrictedSpaceGuard {
    constructor(
        private userService: UserService,
        private proService: ProService,
        private router: Router,
    ) {}

    canActivate(): Observable<boolean> {
        return this.userService.user$.pipe(
            switchMap((user) => {
                if (user && user.proUID) {
                    return this.proService._get(user.proUID);
                }
                return of(undefined);
            }),
            map((pro) => {
                if (!pro) {
                    this.router.navigate(['/']);
                    return false;
                }

                const isRestrictedSpaceType = pro.spaceType === 'limitedAccess';

                if (isRestrictedSpaceType) {
                    this.router.navigate(['/licences']);
                    return false;
                }

                return true;
            }),
        );
    }
}

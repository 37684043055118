import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
    selector: 'omedom-document-delete',
    templateUrl: './document-delete.component.html',
    styleUrls: ['./document-delete.component.scss'],
})
export class DocumentDeleteComponent {
    constructor(private modalController: ModalController, private navController: NavController) {}

    async dismiss(): Promise<void> {
        await this.modalController.dismiss();
    }

    back() {
        this.dismiss();
        this.navController.pop();
    }

    deleteConfirmed() {
        this.modalController.dismiss(true);
    }
}

import {
    Component,
    Input,
} from '@angular/core';
import {
    PropertyEntity,
    SocietyEntity,
    PropertyTab,
    AssetType,
    AssetTypes,
    AssetCardLayout,
    DashboardType,
} from '@omedom/data';

import { Router } from '@angular/router';

@Component({
    selector: 'omedom-asset-slider',
    templateUrl: './asset-slider.component.html',
    styleUrls: ['./asset-slider.component.scss'],
})
// implements OnInit
export class AssetSliderComponent {
    /**
     * @description List of assets (properties, societies) to display in the story slider
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 10/06/2024
     * @type {((PropertyEntity | SocietyEntity)[])}
     * @memberof AssetSliderComponent
     */
    @Input() assets: (PropertyEntity | SocietyEntity)[] | undefined;

    @Input() isEssential = false;

    @Input() dashboard?: DashboardType;

    protected assetCardLayout = AssetCardLayout;

    protected assetTypes = AssetTypes;

    protected dashboardType = DashboardType;

    protected get emptyListMessage(): string | void {
        switch (this.dashboard) {
            case DashboardType.rental:
                return 'Vos biens immobiliers locatifs s\'afficheront ici.';
            case DashboardType.residency:
                return 'Vos biens immobiliers résidentiels s\'afficheront ici.';
            case DashboardType.society:
                return 'Vos sociétés immobilières s\'afficheront ici.';
            case DashboardType.summary:
                return 'Vos sociétés et biens immobiliers s\'afficheront ici.';
            default:
                break;
        }
    }
    /**
     * @description Determine if the slider is focused or not (if the user has scrolled the list or not)
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/08/2024
     * @type {boolean}
     * @memberof AssetSliderComponent
     */
    focus: boolean = true;

    constructor(private router: Router) { }

    /**
     * @description This function is called when the user scrolls the story list
     * @author Jérémie Lopez
     * @param {*} event
     * @memberof AssetSliderComponent
     */
    onScroll(event: any): void {
        this.focus = event?.target?.scrollLeft > 0 || true;
    }

    public assetClicked(asset: AssetType): Promise<boolean> {
        let router: Promise<boolean>;
        switch (this.getAssetType(asset)) {
            case AssetTypes.property:
            case AssetTypes.building:
                router = this.router.navigate(['/tabs/property/info/', asset.uid], {
                    queryParams: { tab: PropertyTab.info },
                });
                break;
            case AssetTypes.society:
                router = this.router.navigate(['/tabs/property/society/info/', asset.uid]);
                break;
            default:
                router = Promise.resolve(false);
                break;
        }
        return router;
    }

    protected getAssetType(asset: AssetType): AssetTypes {
        const type = asset.toSelectOption!().assetType!;
        return type;
    }
}

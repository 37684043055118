import { FormControl } from '@angular/forms';

import { RestEntity } from '../entities';

/**
 * @description Form model to create FormGroups from RestEntities. Each property of the entity is a FormControl
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 25/10/2023
 * @export
 */
export type EntityForm<T extends RestEntity> = {
    [key in keyof T]: FormControl<T[key]>;
};

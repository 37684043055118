import { Injectable } from '@angular/core';
import { PropertyEntity, PropertyType, UserEntity } from '@omedom/data';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { UserService } from '../core';
import { ToastController } from '@ionic/angular';
import { AnalyticsService } from './analytics.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PricehubbleService {
    private currentUser?: UserEntity;
    constructor(
        private angularFireFunctions: AngularFireFunctions,
        private userService: UserService,
        private toastController: ToastController,
        private analyticsService: AnalyticsService
    ) {
        this.userService.user$.subscribe((user) => {
            this.currentUser = user;
        });
    }

    public async refreshAllData(property: PropertyEntity) {
        if (!this.currentUser?.uid) {
            throw new Error('There is no user connected');
        }
        this.analyticsService.logEvent('useValorisation');
        try {
            const callable = this.angularFireFunctions.httpsCallable('refreshPricehubble');
            const refreshAllData = await lastValueFrom(
                callable({
                    property: property,
                })
            );

            return refreshAllData;
        } catch (error) {
            const toast = await this.toastController.create({
                position: 'top',
                color: 'warning',
                duration: 5000,
                message:
                    "Veuillez vérifier si l'adresse de votre bien est correcte, ou si des champs sont manquants",
            });

            await toast.present();
        }
    }
}

<h3>
    <i class="uil uil-sort-amount-down margin-right-1"></i>
    <span>Trier par</span>
</h3>

<i class="uil uil-times-circle close" (click)="close()"></i>

<omedom-radio
    [options]="options"
    class="margin-bottom-2"
    type="column"
    name="sortOption"
    [(ngModel)]="selectedOption"
></omedom-radio>

<button class="omedom-button-primary margin-top-2" (click)="sendSort()">
    Afficher les résultats
</button>

import { ProEntity, UserEntity } from '@omedom/data';

export class OmedomPro {
    public static transformProInPartialUser(pro: ProEntity) {
        const partialUser: Partial<UserEntity> = {
            uid: pro.userUID,
            name: pro.proName,
            firstname: pro.proFirstName,
        };

        return partialUser;
    }

    public static proValuestoString(pro: ProEntity | undefined) {
        if (!pro || !pro.values || pro.values.length === 0) {
            return '';
        }
        if (pro.values.length === 1) {
            return pro.values[0];
        }
        return pro.values.join(', ');
    }
}

import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { emailReviewToPro, ReviewEntity } from '@omedom/data';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { lastValueFrom, Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
    providedIn: 'root',
})
export class ReviewService extends RestService<ReviewEntity> {
    constructor(
        protected override firestore: AngularFirestore,
        private functions: AngularFireFunctions
    ) {
        super(firestore, 'reviews');
    }

    _getProReviews(proUID: string): Observable<ReviewEntity[]> {
        return this._search([{ where: 'proUID', operator: '==', value: proUID }]);
    }

    async sendMailNewReviewToPro(emailInfo: emailReviewToPro) {
        const callable = this.functions.httpsCallable('sendMailNewProReview');
        await lastValueFrom(callable(emailInfo));
    }
}

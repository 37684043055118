import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
    IncomeCategoryProperty,
    IncomeEntity,
    LeaseEntity,
    SelectOption,
    TenantEntity,
} from '@omedom/data';
import { IncomeService } from '@omedom/services';
import { OmedomRegex } from '@omedom/utils';
import { BehaviorSubject } from 'rxjs';

interface TenantForm {
    firstname: FormControl<string | null>;
    lastname: FormControl<string | null>;
    email: FormControl<string | null>;
    phone: FormControl<string | null>;
    entryDate: FormControl<Date | null>;
    exitDate: FormControl<Date | null>;
    helpUID: FormControl<string | null>;
    warrantor: FormGroup<{
        warrantorFirstname: FormControl<string | null>;
        warrantorLastname: FormControl<string | null>;
        warrantorEmail: FormControl<string | null>;
        warrantorPhone: FormControl<string | null>;
    }>;
}

@Component({
    selector: 'omedom-tenant-form',
    templateUrl: './tenant-form-modal.component.html',
    styleUrls: ['./tenant-form-modal.component.scss'],
})
export class TenantFormModalComponent implements OnInit {
    @Input({ required: true }) lease!: Partial<LeaseEntity>;
    @Input() tenant: Partial<TenantEntity> = {};

    public emailRegex: string = OmedomRegex.emailRegex;
    public phoneRegex = OmedomRegex.phoneRegex;
    public aid: IncomeEntity[] = [];

    public tenantForm?: FormGroup<TenantForm>;

    /**
     * @description Display warrantor form after collapse
     * @author Hanane Djeddal
     * @memberof TenantComponent
     */
    public displayGarant = new BehaviorSubject<boolean>(false);
    /**
     * @description Display aid info after collapse
     * @author Hanane Djeddal
     * @memberof TenantComponent
     */
    public displayaid = new BehaviorSubject<boolean>(false);

    /**
     * @description Placeholder of select component
     * @author Hanane Djeddal
     * @memberof TenantComponent
     */
    public aidPlaceholder = {
        id: null,
        label: 'Choisir dans ma liste des aides',
    } as SelectOption;

    public selectedAid?: string;
    /**
     * @description List type of aid incomes in the select component
     * @author Hanane Djeddal
     * @memberof TenantComponent
     */
    public aidOptions: SelectOption[] = [] as SelectOption[];
    edit: boolean = false;
    afterAddAid: boolean = false;

    tenantFormIsInvalid: boolean = false;

    constructor(private incomeService: IncomeService, private modalController: ModalController) {}

    async ngOnInit(): Promise<void> {
        if (this.lease.propertyUID) {
            this.aid = await this.incomeService.getIncomesByCategoryByPropertyIds(
                IncomeCategoryProperty.aid,
                [this.lease.propertyUID]
            );
        }

        this.aidOptions = this.aid.map(
            (income) =>
                ({
                    id: income.uid,
                    label: `Aide ${income.designation ? '(' + income.designation + ')' : ''} ${
                        income.amount
                    }€`,
                    icon: 'uil uil-euro-circle',
                } as SelectOption)
        );

        if (!this.tenant.uid) {
            if (this.lease.uid) {
                this.tenant.leaseUID = this.lease.uid;
            }
        } else {
            this.selectedAid = this.aidOptions.find((x) => x.id === this.tenant.helpUID)?.id;
        }

        this.initForm();
    }

    private initForm(): void {
        this.tenantForm = new FormGroup({
            firstname: new FormControl(this.tenant.firstname || null, {
                validators: [Validators.required],
            }),

            lastname: new FormControl(this.tenant.lastname || null, {
                validators: [Validators.required],
            }),
            email: new FormControl(this.tenant.email || null),
            phone: new FormControl(this.tenant.phone || null),
            entryDate: new FormControl(this.timestampToDate(this.tenant.entryDate) || null, {
                validators: [Validators.required],
            }),
            exitDate: new FormControl(this.timestampToDate(this.tenant.exitDate) || null),
            helpUID: new FormControl(this.tenant.helpUID || null),
            warrantor: new FormGroup({
                warrantorFirstname: new FormControl(this.tenant.warrantor?.firstname || null),
                warrantorLastname: new FormControl(this.tenant.warrantor?.lastname || null),
                warrantorEmail: new FormControl(this.tenant.warrantor?.email || null),
                warrantorPhone: new FormControl(this.tenant.warrantor?.phone || null),
            }),
        });

        this.tenantForm.valueChanges.subscribe(() => {
            if (this.tenantForm) {
                this.tenantFormIsInvalid =
                    this.tenantForm.invalid || this.tenantForm.pending || false;
            } else {
                this.tenantFormIsInvalid = true;
            }
        });
    }

    public async saveTenant(): Promise<void> {
        const tenant: any = {
            firstname: this.tenantForm?.get('firstname')?.value,
            lastname: this.tenantForm?.get('lastname')?.value,
            email: this.tenantForm?.get('email')?.value,
            phone: this.tenantForm?.get('phone')?.value,
            leaseUID: this.lease.uid,
            entryDate: this.tenantForm?.get('entryDate')?.value,
            exitDate: this.tenantForm?.get('exitDate')?.value,
            helpUID: this.tenantForm?.get('helpUID')?.value,
            warrantor: {
                firstname: this.tenantForm?.get('warrantor')?.get('warrantorFirstname')?.value,
                lastname: this.tenantForm?.get('warrantor')?.get('warrantorLastname')?.value,
                email: this.tenantForm?.get('warrantor')?.get('warrantorEmail')?.value,
                phone: this.tenantForm?.get('warrantor')?.get('warrantorPhone')?.value,
            },
        };
        if (this.tenant.helpUID || this.selectedAid) {
            tenant.helpUID = this.selectedAid ? this.selectedAid : '';
        }

        if (this.tenant.uid) {
            tenant.uid = this.tenant.uid;
        }

        await this.modalController.dismiss({
            state: 'saved',
            tenant: tenant as Partial<TenantEntity>,
        });
    }

    async dismiss(): Promise<void> {
        await this.modalController.dismiss({
            state: 'cancelled',
        });
    }

    public async addAid(): Promise<void> {
        await this.modalController.dismiss({
            income: IncomeCategoryProperty.aid,
            lease: this.lease,
        });
    }

    private timestampToDate(timestamp: any): Date | undefined {
        if (timestamp && timestamp.toDate && typeof timestamp.toDate === 'function') {
            return timestamp.toDate();
        } else if (timestamp && typeof timestamp === 'object') {
            return new Date(timestamp);
        }

        return undefined;
    }
}

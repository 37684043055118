import { Component, Input } from '@angular/core';

@Component({
    selector: 'omedom-section-header',
    templateUrl: './section-header.component.html',
    styleUrls: ['./section-header.component.scss'],
    host: {
        class: 'margin-bottom-2'
    }
})
export class SectionHeaderComponent {
    /**
     * @description Icon of the section
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {string}
     * @memberof SectionHeaderComponent
     */
    @Input()
    public icon?: string;

    /**
     * @description Title of the section
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {string}
     * @memberof SectionHeaderComponent
     */
    @Input()
    public title?: string;
}

import { PostalAddress } from '@omedom/data';

export class OmedomAddress {
    public static adaptAddress(address: PostalAddress | undefined) {
        if (!address) {
            return null;
        }
        const streetNumber = this.getBeginningNumber(address);
        const newAddress: PostalAddress = {
            ...address,
            street: this.extractBeginningNumber(address?.street ?? ''),
            streetNumber: streetNumber,
        };

        return newAddress;
    }
    public static getBeginningNumber(address: PostalAddress | undefined): number | null {
        if (!address) {
            return null;
        }

        if (address.streetNumber) {
            return address.streetNumber;
        }

        if (!address.street) {
            return null;
        }

        const street = address.street as string;

        const number = street.match(/^\d{1,4}/);

        if (number) {
            return parseInt(number[0]);
        }

        return null;
    }

    public static extractBeginningNumber(str: string) {
        if (!str || str === '') {
            return '';
        }
        return str.replace(/^\d{1,4},?\s*/, '');
    }
}

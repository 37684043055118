<div class="dpe-container">
    <div class="dpe-title margin-bottom-2">
        Diagnostic de Performance Énergétique
    </div>
    <div class="dpe-scale margin-bottom-2">
        @for (level of levels; track level.letter; let i = $index) {
        <div
            class="dpe-label"
            [ngClass]="'level-' + (i + 1)"
            [class.active-score]="level.active"
            [ngStyle]="{ 'background-color': level.color }"
        >
            <div class="dpe-range">{{ level.range }}</div>
            <div class="dpe-letter">{{ level.letter }}</div>
        </div>
        }
        <div class="dpe-legend">En kWh/m²/an</div>
        <div
            class="dpe-rating"
            [style.top]="ratingPosition"
            [style.left]="ratingLeftProsition"
        >
            {{ ratingText }}
        </div>
    </div>
</div>

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DocumentEntity, ProEntity, PropertyEntity, SocietyEntity, UserEntity } from '@omedom/data';
import { DocumentService } from '@omedom/services';
import { Observable, of } from 'rxjs';

enum DataProvider {
    PRO = 'pro',
    USER = 'user',
    PROPERTY = 'property',
    SOCIETY = 'society',
}

@Component({
    selector: 'omedom-document-list-pro',
    templateUrl: './document-list-pro.component.html',
    styleUrls: ['./document-list-pro.component.scss'],
})
export class DocumentListProComponent implements OnChanges {
    /**
     * @description Search string to filter the document list
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {string}
     * @memberof DocumentListProComponent
     */
    public search: string = '';

    /**
     * @description Pro data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @type {ProEntity}
     * @memberof DocumentListProComponent
     */
    @Input()
    public pro?: ProEntity;

    /**
     * @description User data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @type {UserEntity}
     * @memberof DocumentListProComponent
     */
    @Input()
    public user?: UserEntity;

    /**
     * @description Property data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @type {PropertyEntity}
     * @memberof DocumentListProComponent
     */
    @Input()
    public property?: PropertyEntity;

    /**
     * @description Society data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @type {SocietyEntity}
     * @memberof DocumentListProComponent
     */
    @Input()
    public society?: SocietyEntity;

    /**
     * @description Documents of the pro, user, property or society depending on the input data provided
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @type {Observable<DocumentEntity[]>}
     * @memberof DocumentListProComponent
     */
    public documents$?: Observable<DocumentEntity[]>;

    /**
     * @description Show create button or not depending on the input data provided
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @type {boolean}
     * @memberof DocumentListProComponent
     */
    @Input()
    public showCreateButton: boolean = true;

    @Input()
    public onCreateLink: string = '/document/form';

    constructor(
        private readonly documentService: DocumentService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        // Select data provider
        let provider: DataProvider;

        if (changes['pro']) {
            provider = DataProvider.PRO;
        } else if (changes['user']) {
            provider = DataProvider.USER;
        } else if (changes['property']) {
            provider = DataProvider.PROPERTY;
        } else if (changes['society']) {
            provider = DataProvider.SOCIETY;
        } else {
            return;
        }

        // Get documents
        this.getDocuments(provider);
    }

    /**
     * @description Get the documents of the pro, user, property or society depending on the input data provided
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @private
     * @param {DataProvider} provider
     * @returns {*}  {Promise<void>}
     * @memberof DocumentListProComponent
     */
    private getDocuments(provider: DataProvider): void {
        switch (provider) {
            case DataProvider.PRO:
                // Check if the pro is defined
                if (!this.pro) {
                    return;
                }

                // Get the pro documents
                this.documents$ = this.documentService._getProDocuments(this.pro.uid);

                break;
            case DataProvider.USER:
                // TODO: Get documents by user
                this.documents$ = of([]);
                break;
            case DataProvider.PROPERTY:
                // Check if the property is defined
                if (!this.property) {
                    return;
                }

                // Get the property documents
                this.documents$ = this.documentService._getPropertyDocuments(this.property.uid);

                break;
            case DataProvider.SOCIETY:
                // Check if the society is defined
                if (!this.society) {
                    return;
                }

                // Get the society documents
                this.documents$ = this.documentService._getSocietyDocuments(this.society.uid);
                break;
        }
    }
}

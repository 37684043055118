import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
    EventDom,
    LevelEntity,
    LevelEventEntity,
    levelEvents,
    levels,
    UserEntity,
} from '@omedom/data';
import { OmedomLevel } from '@omedom/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import * as uuid from 'uuid';

import { UserService } from '../core';

@Injectable({
    providedIn: 'root',
})
export class LevelService {
    /**
     * @description Current dom of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @private
     * @memberof LevelService
     */
    private dom = 0;

    /**
     * @description State of the service
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @private
     * @memberof LevelService
     */
    private init = false;

    /**
     * @description List of events to display in the popup (success, level up, etc...)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @private
     * @memberof LevelService
     */
    private eventsToDisplay$ = new BehaviorSubject<LevelEventEntity[]>([]);

    /**
     * @description List of levels with their name and their dom required to unlock them
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @private
     * @memberof LevelService
     */
    private levels = levels;

    public static levelUpComponent: any;

    constructor(private userService: UserService, private modalController: ModalController) {}

    /**
     * @description Return the list of events to display in the popup
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @readonly
     * @type {Observable<LevelEventEntity[]>} List of events to display in the popup
     * @memberof LevelService
     * @example
     * this.levelService.eventsToDisplay.subscribe((events) => {
     *     // Do something with the list of events
     * });
     */
    public get eventsToDisplay(): Observable<LevelEventEntity[]> {
        return this.eventsToDisplay$.asObservable();
    }

    /**
     * @description Observe the user to check if a new event has been added to the list of eventsDom
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @return {void}
     * @memberof LevelService
     * @example
     * this.levelService.observeSuccess();
     */
    public observeSuccess(): void {
        // Observe the user
        this.userService.user$.subscribe((user) => {
            // Check if the user is defined
            if (user) {
                // Check if the service has already been initialized
                if (this.init) {
                    this.checkNewEventDom(user);
                }

                // Initialize the service
                this.dom = user.dom || 0;
                this.init = true;
            } else {
                this.init = false;
                this.dom = 0;
            }
        });
    }

    /**
     * @description Check if a new event has been added to the list of eventsDom and display the popup if it's the case
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @private
     * @param {UserEntity} user User to check
     * @memberof LevelService
     * @example
     * this.checkNewEventDom(user);
     */
    private checkNewEventDom(user: UserEntity): void {
        // Check if the user has dom
        if (user.dom === undefined) {
            return;
        }

        // Check if the user has more dom than before
        if (this.dom >= user.dom) {
            return;
        }

        // Check if the user have an event
        if (!user.eventsDom || user.eventsDom.length === 0) {
            return;
        }

        // Retrieve last event
        const lastEvent = user.eventsDom[user.eventsDom.length - 1];

        // Present success
        this.presentSuccessModal(lastEvent);

        // check if the user has unlocked a new level
        this.checkNewLevel(user.dom);
    }

    /**
     * @description Display the popup with the event to display in the popup
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @private
     * @param {EventDom} event Event to display in the popup
     * @return {Promise<void>}
     * @memberof LevelService
     * @example
     * this.presentSuccessModal(event);
     */
    private async presentSuccessModal(event: EventDom): Promise<void> {
        // Generate a new id for the event
        const id = uuid.v4();

        // Get the event to display
        const levelEvent = levelEvents.find((level: LevelEventEntity) => level.name === event.name);

        // Check if the event exists
        if (!levelEvent) {
            return;
        }

        // Verify if the event is already in the list of events to display
        if (this.eventsToDisplay$.value.find((e) => e.name === event.name)) {
            return;
        }

        // Assign the id to the event
        levelEvent.id = id;

        // Add the event to the list of events to display
        if (levelEvent) {
            this.eventsToDisplay$.next([...this.eventsToDisplay$.value, levelEvent]);
        }
    }

    /**
     * @description Present the popup with the level up message and the new level unlocked if it's the case
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @private
     * @param {LevelEntity} level Level to check
     * @returns {Promise<void>}
     * @memberof LevelService
     * @example
     * this.presentLevelModal(level);
     */
    private async presentLevelModal(level: LevelEntity): Promise<void> {
        const modal = await this.modalController.create({
            component: LevelService.levelUpComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            componentProps: {
                level,
            },
        });

        return await modal.present();
    }

    /**
     * @description Remove the event from the list of events to display in the popup
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @param {string} id Id of the event to remove
     * @memberof LevelService
     * @example
     * this.removeEvent(id);
     */
    public removeEvent(id: string): void {
        this.eventsToDisplay$.next(this.eventsToDisplay$.value.filter((event) => event.id !== id));
    }

    /**
     * @description Check if the user has unlocked a new level and display the popup if it's the case
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @private
     * @param {number} dom Dom of the user
     * @memberof LevelService
     * @example
     * this.checkNewLevel(dom);
     */
    private checkNewLevel(dom: number): void {
        // Retrieve the current level of the user
        const currentLevel = OmedomLevel.getLevel(this.dom);

        // Retrieve the new level of the user
        const newLevel = OmedomLevel.getLevel(dom);

        // If the user has unlocked a new level, display the popup
        if (currentLevel < newLevel) {
            // Retrieve the level
            const level = this.levels.find((l: LevelEntity) => l.order === newLevel);

            // Check if the level exists
            if (!level) {
                return;
            }

            this.presentLevelModal(level);
        }
    }
}

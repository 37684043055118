import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BankAccountEntity, BankAccountType, BankItemEntity } from '@omedom/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RestService, WhereSearchParameter } from './rest.service';

@Injectable({ providedIn: 'root' })
export class BankAccountService extends RestService<BankAccountEntity> {
    protected override builder = BankAccountEntity;

    constructor(
        protected override firestore: AngularFirestore,
    ) {
        super(firestore, 'bankAccounts');
    }

    /**
     * @description Return bank accounts of the item in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @param {BankItemEntity} item
     * @returns {Observable<BankAccountEntity[]>}
     * @memberof BankAccountService
     */
    public _getBankAccountsFromItem(item: BankItemEntity, userUID?: string): Observable<BankAccountEntity[]> {
        // Define filters
        const filters: WhereSearchParameter[] = [
            {
                where: 'itemID',
                operator: '==',
                value: item.bridgeID
            }
        ];

        // Add user filter if needed
        if (userUID) {
            filters.push({
                where: 'userUID',
                operator: '==',
                value: userUID
            });
        }

        return this._search(filters).pipe(
            map(accounts => accounts
                .sort((a, b) => {
                    // Sort by type then by name
                    if (a.type === b.type) {
                        return a.name.localeCompare(b.name);
                    } else {
                        return a.type.localeCompare(b.type);
                    }
                })
            )
        );
    }

    /**
     * @description Return bank accounts of the user in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @param {string} userUID
     * @returns {Observable<BankAccountEntity[]>}
     * @memberof BankAccountService
     */
    public _getUserBankAccounts(userUID: string): Observable<BankAccountEntity[]> {
        return this._search([
            {
                where: 'userUID',
                operator: '==',
                value: userUID
            }
        ])
            .pipe(
                map(accounts => accounts
                    .sort((a, b) => {
                        // Sort by type
                        // First Checking, then Card, then Savings,
                        // Then sort by name
                        const typeOrder = [
                            BankAccountType.Checking,
                            BankAccountType.Card,
                            BankAccountType.Savings,
                            BankAccountType.LifeInsurance,
                            BankAccountType.SharedSavingPlan,
                            BankAccountType.Brokerage,
                            BankAccountType.Loan,
                        ];

                        if (a.type === b.type) {
                            return a.name.localeCompare(b.name);
                        } else {
                            return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
                        }
                    })
                )
            );
    }

    /**
     * @description Get a bank account from its ID in the database
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @param {number} accountID
     * @returns {Observable<BankAccountEntity>}
     * @memberof BankAccountService
     */
    public _getBankAccountFromID(accountID: number, userUID?: string): Observable<BankAccountEntity> {
        // Define filters
        const filters: WhereSearchParameter[] = [
            {
                where: 'bridgeID',
                operator: '==',
                value: accountID
            }
        ];

        // Add user filter if needed
        if (userUID) {
            filters.push({
                where: 'userUID',
                operator: '==',
                value: userUID
            });
        }

        return this._search(filters).pipe(
            map(accounts => accounts[0])
        );
    }
}

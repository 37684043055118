<div class="stepper-header" #header>
    @for (step of steps; track step.id; let index = $index) {
    <div
        class="stepper-step"
        [id]="step.id"
        [class.selected]="selectedStep?.id === step.id"
        [class.previous]="selectedStepIndex > index"
    >
        <div class="stepper-step-container" (click)="onStep(index)">
            <span class="stepper-step-number">
                @if (isValidatedStep(index)) {
                <i class="uil uil-check"></i>
                } @else {
                {{ index + 1 }}
                }
            </span>
            <span class="stepper-step-label">
                {{ step.label }}
            </span>
        </div>
    </div>
    }
</div>

<div class="stepper-content">
    <ng-content></ng-content>
</div>

<div class="omedom-sticky-button-container">
    @if (selectedStepIndex > 0) {
    <button
        class="omedom-button-secondary flex-1 margin-right-2"
        (click)="onPrevious()"
    >
        <i class="uil uil-angle-left"></i>
        Précédent
    </button>
    } @if (selectedStepIndex < steps.length - 1) {
    <button
        class="omedom-button-primary flex-1"
        [disabled]="selectedStep?.canGoNext && !selectedStep?.canGoNext()"
        (click)="onNext()"
    >
        Suivant
        <i class="uil uil-angle-right"></i>
    </button>
    } @if (selectedStepIndex === steps.length - 1) {
    <button
        class="omedom-button-green flex-1"
        [disabled]="selectedStep?.canGoNext && !selectedStep?.canGoNext()"
        (click)="onSave.next()"
    >
        <i class="uil uil-save"></i>
        Enregistrer @if (loading) {
        <ion-spinner></ion-spinner>
        }
    </button>
    }
</div>

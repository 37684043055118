<div class="container">
    <omedom-select
        label="Rechercher un pro :"
        [optGroupOptions]="proOptGroupOptions"
        [boldLabel]="true"
        [(ngModel)]="searchData"
        [placeholder]="{ id: null, label: 'Rechercher un pro' }"
        (ngModelChange)="sendSearch()"
    ></omedom-select>
</div>

@if (property?.photo) {
  <img [src]="property?.photo" class="avatar" alt="property photo" />
} @else {
  <div class="avatar">
    <i class="uil uil-{{ isBuilding ? 'building' : 'home' }}"></i>
  </div>
}

<ng-template #photo>
  <img [src]="property?.photo" class="avatar" alt="property photo" />
</ng-template>

<ng-template #avatar>
  <div class="avatar">
    <i class="uil uil-{{ isBuilding ? 'building' : 'home' }}"></i>
  </div>
</ng-template>

<div class="card-content">
  <h3>{{ property?.name ?? 'Bien inconnu' }}</h3>
  <p>{{ property?.purchaseDetails?.use }}</p>
</div>

@if (selected && pro) {
  <img [src]="pro.logo" [alt]="pro.name" class="logo" />
}

<div class="container">
  <div
    class="graph-header background-color-very-light-grey padding-1 omedom-border-radius"
    >
    <div class="omedom-card flex-1 margin-right-1">
      <div class="margin-bottom-1">
        <i class="uil uil-euro-circle"></i>
        Total revenus
      </div>
      <strong class="color-green"
        >{{ totalIncome | number : '0.2-2' }}&nbsp;€</strong
        >
      </div>
      <div class="omedom-card flex-1 margin-left-1">
        <div class="margin-bottom-1">
          <i class="uil uil-chart-pie-alt"></i>
          Total charges
        </div>
        <strong class="color-red"
          >{{ totalCharge | number : '0.2-2' }}&nbsp;€</strong
          >
        </div>
      </div>
      <div class="graph-container w-100">
        <div
          class="graph w-100 background-color-very-light-grey padding-2 margin-y-2 omedom-border-radius"
          >
          <canvas
            baseChart
            [data]="chartData"
            [options]="chartOptions"
            type="bar"
            >
          </canvas>
        </div>
        <omedom-chart-legend
          [legends]="chartLegends"
          [forecastAuthorised]="forecastAuthorised"
          (forecastClicked)="forecastClicked.emit()"
        ></omedom-chart-legend>
      </div>
    </div>

    @if (showDate) {
      <div class="omedom-sticky-button-container w-100">
        <omedom-date
          [type]="omedomDateType.year"
          (dateChange)="dateChange($event)"
        ></omedom-date>
      </div>
    }

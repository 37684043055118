@if (user) {
<div class="container">
    <div class="picture-container">
        @if (user.avatar) {
        <img
            class="account-picture"
            (click)="uploadAvatar()"
            [src]="user.avatar"
            alt="Avatar de l'utilisateur"
        />
        } @else {
        <div class="account-default-picture" (click)="uploadAvatar()">
            <i class="uil uil-user-circle"></i>
        </div>
        } @if (editable) {
        <button
            class="omedom-button omedom-button-primary"
            style="padding: 0"
            (click)="uploadAvatar()"
            [disabled]="pendingPhoto$.asObservable() | async"
        >
            @if ((pendingPhoto$.asObservable() | async) === false) {
            <i class="uil uil-upload"></i>
            } @if (pendingPhoto$.asObservable() | async) {
            <ion-spinner
                color="primary"
                class="form-upload-spinner"
            ></ion-spinner>
            }
        </button>
        <button
            class="omedom-button omedom-button-primary"
            (click)="deletePicture()"
        >
            <i class="uil uil-trash-alt"></i>
        </button>
        }
    </div>
    <form class="form">
        <omedom-input
            [(ngModel)]="user.email"
            [disabled]="true"
            type="email"
            name="email"
            [label]="editable ? 'Mail' : undefined"
            icon="uil uil-envelope"
        ></omedom-input>
        <!-- Newsletter info -->
        @if (!editable) { @if (user.newsletter) {
        <p class="account-newsletter">
            Vous êtes abonné à la Newsletter Omedom.
        </p>
        } @if (!user.newsletter) {
        <p class="account-newsletter">
            Vous n'êtes pas abonné à la Newsletter Omedom.
        </p>
        } }
        <!-- Newsletter toggle -->
        @if (editable) {
        <omedom-toggle
            label="Je souhaite rester informé des actualités Omedom."
            name="newsletter"
            [(ngModel)]="user.newsletter"
        ></omedom-toggle>
        }
        <!-- Change password -->
        @if (editable && isEmailPasswordUser) {
        <hr class="omedom-divider w-100 omedom-no-margin" />
        <h2>
            <i class="uil uil-lock-alt"></i>
            Modification du mot de passe
        </h2>
        <!-- Current Password -->
        <omedom-input
            [(ngModel)]="oldPassword"
            (ngModelChange)="oldPasswordChange.emit($event)"
            [type]="showCurrentPassword ? 'text' : 'password'"
            autocomplete="current-password"
            label="Mot de passe actuel"
            name="oldPassword"
            [placeholder]="
                showCurrentPassword ? 'Mot de passe actuel' : '********'
            "
            [iconEnd]="
                showCurrentPassword ? 'uil uil-eye' : 'uil uil-eye-slash'
            "
            (iconEndClick)="showCurrentPassword = !showCurrentPassword"
            icon="uil uil-lock"
        ></omedom-input>
        <!-- New Password -->
        <omedom-input
            [(ngModel)]="password"
            (ngModelChange)="passwordChange.emit($event)"
            [type]="showNewPassword ? 'text' : 'password'"
            autocomplete="new-password"
            label="Nouveau mot de passe"
            name="password"
            [placeholder]="
                showNewPassword ? 'Nouveau mot de passe' : '********'
            "
            [iconEnd]="showNewPassword ? 'uil uil-eye' : 'uil uil-eye-slash'"
            (iconEndClick)="showNewPassword = !showNewPassword"
            icon="uil uil-lock"
        ></omedom-input>
        <!-- Confirm Password -->
        @if (password.length > 0) {
        <omedom-input
            [(ngModel)]="passwordConfirm"
            name="passwordConfirm"
            (ngModelChange)="passwordConfirmChange.emit($event)"
            [type]="showPasswordConfirm ? 'text' : 'password'"
            autocomplete="new-password"
            label="Confirmation du mot de passe"
            [placeholder]="
                showPasswordConfirm ? 'Nouveau mot de passe' : '********'
            "
            [iconEnd]="
                showPasswordConfirm ? 'uil uil-eye' : 'uil uil-eye-slash'
            "
            (iconEndClick)="showPasswordConfirm = !showPasswordConfirm"
            [pattern]="password"
            icon="uil uil-lock"
        ></omedom-input>
        } @if (password.length > 0) {
        <omedom-security-password
            @element
            [password]="password"
        ></omedom-security-password>
        } } @if (isEmailPasswordUser) {
        <h3 class="margin-top-1">
            <i class="uil uil-shield uil-icon"></i>
            Validation en deux étapes
        </h3>
        }
        <!-- email two factor auth-->
        @if (editable && isEmailPasswordUser) { @if
        (user.multiFactor?.email?.enabled) {
        <p class="account-newsletter">
            Vous avez activé la validation en deux étapes.
        </p>
        } @if (!user.multiFactor?.email?.enabled) {
        <p class="account-newsletter">
            Vous n'avez pas activé la validation en deux étapes.
        </p>
        } } @if (editable && isEmailPasswordUser) { @if ( user.multiFactor &&
        user.multiFactor.email) {
        <omedom-toggle
            label="Activer la validation en deux étapes par email"
            name="emailTwoFactor"
            [(ngModel)]="user.multiFactor.email.enabled"
        ></omedom-toggle>
        } @else {
        <omedom-toggle
            label="Activer la validation en deux étapes par email"
            name="emailTwoFactor"
            (click)="enableTwoFactor('email')"
            [ngModel]="false"
        ></omedom-toggle>
        } }
        <hr class="omedom-divider w-100 omedom-no-margin" />
        <h2>
            <i class="uil uil-user-circle uil-icon"></i>
            Informations personnelles
        </h2>
        <omedom-input
            name="firstname"
            type="text"
            icon="uil-user-circle"
            [label]="editable ? 'Prénom' : undefined"
            [disabled]="!editable"
            [(ngModel)]="user.firstname"
            placeholder="Prénom"
            [required]="true"
            [debounce]="300"
        ></omedom-input>
        <omedom-input
            name="name"
            type="text"
            icon="uil-user-circle"
            [label]="editable ? 'Nom' : undefined"
            [disabled]="!editable"
            [(ngModel)]="user.name"
            [required]="true"
            placeholder="Nom"
            [debounce]="300"
        ></omedom-input>
        <omedom-input
            type="bday"
            name="birthdate"
            [label]="editable ? 'Date d\'anniversaire' : undefined"
            [disabled]="!editable"
            [(ngModel)]="user.birthdate"
            placeholder="jj/mm/aaaa"
            (keyup)="keyPress($event)"
            [pattern]="bdayRegex"
        ></omedom-input>
        <omedom-input
            name="tel"
            type="tel"
            icon="uil-phone-volume"
            [label]="editable ? 'Téléphone' : undefined"
            [disabled]="!editable"
            [(ngModel)]="user.tel"
            placeholder="+33"
            [debounce]="300"
            [pattern]="phoneRegex"
        ></omedom-input>
        @if (user.address) {
        <h3 class="margin-top-1">
            <i class="uil uil-map-marker uil-icon"></i>
            Adresse
        </h3>
        <div class="account-split-field">
            <omedom-input
                style="width: 130px"
                name="streetNumber"
                type="number"
                [min]="0"
                [max]="9999"
                icon=""
                label="N°"
                [disabled]="!editable"
                [(ngModel)]="user.address.streetNumber"
                placeholder="N°"
                [debounce]="300"
            ></omedom-input>
            <omedom-input
                class="margin-bottom-1"
                style="width: 140px"
                name="suffixNumber"
                type="text"
                [(ngModel)]="user.address.suffixNumber"
                placeholder="bis, ter"
                autocomplete="off"
                [required]="false"
                [debounce]="300"
                label="Suffixe"
            ></omedom-input>
            <omedom-input
                name="street"
                type="text"
                icon=""
                [label]="editable ? 'Rue' : undefined"
                [disabled]="!editable"
                [(ngModel)]="user.address.street"
                placeholder="Rue"
                [debounce]="300"
            ></omedom-input>
        </div>
        <omedom-input
            name="addressLine2"
            type="text"
            icon=""
            label="Complément d'adresse"
            [disabled]="!editable"
            [(ngModel)]="user.address.addressLine2"
            placeholder="batiment B"
            [debounce]="300"
        ></omedom-input>
        <div class="account-split-field">
            <omedom-input
                name="postalCode"
                type="text"
                [label]="editable ? 'Code postal' : undefined"
                [disabled]="!editable"
                [(ngModel)]="user.address.postalCode"
                placeholder="Code postal"
                [required]="true"
                [debounce]="300"
                [pattern]="postalCodeRegex"
            ></omedom-input>
            <omedom-input
                name="city"
                type="text"
                [label]="editable ? 'Ville' : undefined"
                [disabled]="!editable"
                [(ngModel)]="user.address.city"
                placeholder="Ville"
            ></omedom-input>
        </div>
        }
    </form>
    <hr class="omedom-divider w-100 omedom-no-margin" />
    <div class="omedom-button-container w-100">
        <button
            @element
            class="omedom-button-warn-secondary flex-1"
            (click)="openModalDeleteAccount()"
        >
            <i class="uil uil-trash-alt"></i>
            <span>Supprimer mon compte</span>
        </button>
    </div>
    <div class="omedom-sticky-button-container w-100">
        @if (editable) {
        <button
            class="omedom-button-green flex-1"
            @element
            (click)="update()"
            [disabled]="
                (password.length > 0 &&
                    oldPassword.length > 0 &&
                    password !== passwordConfirm) ||
                !isFormValid ||
                (pending$ | async)
            "
        >
            <i class="uil uil-save"></i>
            <span>{{
                (pending$ | async) ? 'Enregistrement...' : 'Enregistrer'
            }}</span>
            @if (pending$ | async) {
            <ion-spinner></ion-spinner>
            }
        </button>
        }
    </div>
</div>
}

<ng-template #emailTwoFactor>
    <omedom-toggle
        label="Activer la validation en deux étapes par email"
        name="emailTwoFactor"
        (click)="enableTwoFactor('email')"
        [ngModel]="false"
    ></omedom-toggle>
</ng-template>

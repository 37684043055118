import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
    Appearance,
    ClientEntity,
    DataTableAction,
    DataTableColumn,
    Primitive,
    ProEntity,
    SubscriptionEntity,
    UserEntity,
} from '@omedom/data';
import { ClientService, ProService } from '@omedom/services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { LicenceOrderComponent, LicenceSendComponent } from '../../containers';
import { ValidationModalComponent } from '../../components/validation-modal/validation-modal.component';

@Component({
    selector: 'omedom-client-list',
    templateUrl: './client-list.component.html',
    styleUrls: ['./client-list.component.scss'],
})
export class ClientListComponent implements OnChanges {
    /**
     * @description Pro data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/10/2023
     * @type {ProEntity}
     * @memberof ClientListComponent
     */
    @Input()
    public pro?: ProEntity;

    /**
     * @description Page size of client list
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 27/09/2024
     * @type {number}
     * @memberof ClientListComponent
     */
    @Input()
    public pageSize?: number;

    /**
     * @description Empty message when the pro doesn't have client
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/10/2023
     * @type {string}
     * @memberof ClientListComponent
     */
    @Input()
    public emptyMessage: string = `Aucun résultat pour cette recherche.`;

    /**
     * @description Link prefix to redirect to client profil
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/10/2023
     * @type {string}
     * @memberof ClientListComponent
     */
    @Input()
    public linkPrefix: string = '/clients';

    /**
     * @description Columns to display in the table (label, key, type, transformer, validator) to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/09/2023
     * @type {DataTableColumn<UserEntity>[]}
     * @memberof ClientListComponent
     */
    @Input()
    public columns: DataTableColumn<ClientEntity>[] = [
        {
            label: '',
            key: 'userUID',
            type: Primitive.picture,
            sortable: false,
            transformer: (data: ClientEntity) => {
                const user = this.clientsUsers.find((user) => user.uid === data.userUID);
                return user?.avatar?.startsWith('https') ? user.avatar : 'user-circle';
            },
            width: '70px',
        },
        {
            label: 'Prénom',
            key: 'firstname',
            type: Primitive.string,
            sortable: true,
            transformer: (data: ClientEntity) => {
                const user = this.clientsUsers.find((user) => user.uid === data.userUID);
                return data.firstname ?? user?.firstname;
            },
        },
        {
            label: 'Nom',
            key: 'name',
            type: Primitive.string,
            sortable: true,
            transformer: (data: ClientEntity) => {
                const user = this.clientsUsers.find((user) => user.uid === data.userUID);
                return data.name ?? user?.name;
            },
        },
        {
            label: 'Mail',
            key: 'email',
            type: Primitive.string,
            sortable: true,
            transformer: (data: ClientEntity) => {
                const user = this.clientsUsers.find((user) => user.uid === data.userUID);
                return data?.email ?? user?.email ?? '';
            },
        },

        {
            label: "Fin d'activation",
            key: 'subscriptionUID',
            type: Primitive.date,
            sortable: true,
            transformer: (data: ClientEntity) => {
                return this.getSubscriptionEndDate(data);
            },
        },
    ];

    /**
     * @description Action on the data by row (icon, title, callback, appearance) to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/09/2023
     * @type {DataTableAction<UserEntity>[]}
     * @memberof ClientListComponent
     */
    @Input()
    public actions: DataTableAction<ClientEntity>[] = [
        {
            icon: 'angle-right-b',
            callback: async (data: ClientEntity) => {
                if (data.isMandated) {
                    // Route to the client page
                    await this.router.navigate([this.linkPrefix, data.userUID]);
                } else {
                    // modal validation
                    await this.openValidCientModal(data);
                }
            },
            appearance: Appearance.secondary,
            minimal: true,
        },
    ];

    /**
     * @description True if we let the possibility to disable the action on the data by row to display in the table, false otherwise (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @type {boolean}
     * @memberof ClientListComponent
     */
    @Input()
    public useDisabledCondition: boolean = false;

    /**
     * @description True if we display the order button, false otherwise (default: true)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @type {boolean}
     * @memberof ClientListComponent
     */
    @Input()
    public displayOrderButton: boolean = true;

    /**
     * @description List of clients to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @type {Observable<UserEntity[]>}
     * @memberof ClientListComponent
     */
    public clients$: Observable<ClientEntity[]> = of([]);

    /**
     * @description Search string to filter the clients list by name or email
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {string}
     * @memberof ClientListComponent
     */
    public search: string = '';

    /**
     * @description List of subscriptions of the clients of the pro (used to get the end date of the subscription of the client)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/09/2023
     * @private
     * @type {SubscriptionEntity[]}
     * @memberof ClientListComponent
     */
    private clientsSubscription: SubscriptionEntity[] = [];

    private clientsUsers: UserEntity[] = [];

    /**
     * @description Condition to disable the action on the data by row to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @memberof ClientListComponent
     */
    public disabledCondition: (data: ClientEntity) => boolean = (data) => {
        const shareWithProConditions: boolean = data.isValidatedByPro;

        return !shareWithProConditions;
    };

    /**
     * @description Default condition to let possibility to have disabled row
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @memberof ClientListComponent
     */
    public defaultCondition: (data: ClientEntity) => boolean = () => false;

    constructor(
        private readonly proService: ProService,
        private readonly clientService: ClientService,
        private readonly router: Router,
        private readonly modalController: ModalController
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        // Check if the pro is defined
        if (!this.pro) {
            return;
        }

        // If pro uid doesn't change, we don't need to get the pro data
        if (
            changes['pro'] &&
            changes['pro'].previousValue?.uid === changes['pro'].currentValue?.uid
        ) {
            return;
        }

        // Get the clients of the pro
        this.clients$ = this.proService._getClients(this.pro.uid).pipe(
            map((data) => {
                // Get the subscriptions of the clients
                this.clientsSubscription = data.subscriptions;

                // Get the users of the clients
                this.clientsUsers = data.users;

                // Return the clients
                return data.clients.filter((client) => client.isValidatedByPro);
            })
        );
    }

    /**
     * @description Filter the clients by the search string and return the filtered client list
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @param {UserEntity[]} clients
     * @returns {UserEntity[]}
     * @memberof ClientListComponent
     */
    public getFilteredClients(clients: ClientEntity[]): ClientEntity[] {
        const search = clients.filter((client: ClientEntity) => {
            return (
                client.firstname?.toLowerCase().includes(this.search.toLowerCase()) ||
                client.name?.toLowerCase().includes(this.search.toLowerCase()) ||
                client.email?.toLowerCase().includes(this.search.toLowerCase())
            );
        });

        return search;
    }

    /**
     * @description Get the end date of the subscription of the client
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/09/2023
     * @param {UserEntity} client
     * @returns {*}  {string}
     * @memberof ClientListComponent
     */
    public getSubscriptionEndDate(client: ClientEntity): string {
        // Get the subscriptionUID of the client
        // Get the subscription of the client
        const subscription = this.clientsSubscription.find(
            (subscription) => subscription.userUID === client.userUID
        );

        // Check if the subscription is defined
        if (!subscription) {
            return 'Non fourni';
        }

        // Get the end date of the subscription
        const endDate = subscription.renewDate;

        // Check if the end date is defined
        if (!endDate) {
            return 'Non activé';
        }

        // Split the string to get the date
        const split = endDate.split('/');

        // Create date with the string
        const date = new Date(Number(split[2]), Number(split[1]) - 1, Number(split[0]));

        return date.toLocaleDateString();
    }

    /**
     * @description Order licences for the pro
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @returns {Promise<void>}
     * @memberof ClientListComponent
     */
    public async onOrder(): Promise<void> {
        const modal = await this.modalController.create({
            component: LicenceOrderComponent,
        });

        await modal.present();
    }

    /**
     * @description Display a modal to filter the client list by state (used, shared, available)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/10/2023
     * @returns {Promise<void>}
     * @memberof ClientListComponent
     *
     * @todo Create the component to filter licences
     */
    public async onFilter(): Promise<void> {
        // TODO: Create the component to filter licences;
    }

    /**
     * @description Send licences to the client by email
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @returns {Promise<void>}
     * @memberof ClientListComponent
     */
    public async onSend(): Promise<void> {
        const modal = await this.modalController.create({
            component: LicenceSendComponent,
        });

        await modal.present();
    }

    async openValidCientModal(client: ClientEntity) {
        const user = this.clientsUsers.find((user) => user.uid === client.userUID);
        const firstname = user?.firstname ?? client.firstname ?? '';
        const name = user?.name ?? client.name ?? '';
        const displayName = firstname || name ? `${firstname} ${name}` : 'ce client';
        const modal = await this.modalController.create({
            component: ValidationModalComponent,
            componentProps: {
                title: 'Certification',

                information:
                    'Vous devez certifier être mandaté(e) par le client pour accéder à ses données.',
                message: `Je certifie être mandaté(e) par ${displayName}.
                 `,
                iconInfo: '',
                validateButtonMessage: 'Je certifie',
                cancelButtonMessage: 'Plus tard',
            },
        });

        modal.present();

        await modal.onDidDismiss().then(async (res) => {
            const confirmation = res.data;
            if (confirmation) {
                await this.clientService.update({
                    uid: client.uid,
                    isMandated: true,
                });
                this.router.navigate([this.linkPrefix, client.userUID]);
            }
        });
    }
}

/* eslint-disable @typescript-eslint/naming-convention */
import { RestEntity } from './rest.entity';
import { Timestamp } from 'firebase/firestore';



export class PaymentEntity extends RestEntity {
    amount!: number;
    description!: string;
    is_paid!: boolean;
    paid_at!: Timestamp;
    is_refunded!: boolean;
    card!: {
        last4: number;
        country: string;
        exp_month: number;
        exp_year: number;
        brand: string;
        id: string;
    };
    billing!: {
        first_name: string;
        last_name: string;
        email: string;
        address1: string;
        postcode: string;
        city: string;
        country: string;
        language: string;
        mobile_phone_number: string;
    };
    shipping!: {
        first_name: string;
        last_name: string;
        email: string;
        address1: string;
        postcode: string;
        city: string;
        country: string;
        language: string;
        mobile_phone_number: string;
        delivery_type: string;
    };
    userUID!: string;
    propertiesUID!: string[];
    subscriptionUID!: string;
    receiptURL!: string;
    recieptNumber!: number;
    payementID!: string;
}

import { BankStatusCode } from '../../enums';

/**
 * @description Bank status informations in Bridge
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 01/03/2024
 * @export
 * @interface BankStatusReport
 */
export interface BankStatusReport {
    /**
     * @description The ID of the bank status
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {BankStatusCode}
     * @memberof BankStatusReport
     */
    status: BankStatusCode;

    /**
     * @description More informations on the status if there is an error
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {string}
     * @memberof BankStatusReport
     */
    statusCodeInfo?: string;

    /**
     * @description Description of the status code in human language if there is an error
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {string}
     * @memberof BankStatusReport
     */
    statusCodeDescription?: string;
}

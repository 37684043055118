<div class="header-title">
    @if (hasNavigation) {
    <ng-container
        *ngTemplateOutlet="!!this.previousUrl ? routerTemplate : navTemplate"
    ></ng-container>
    <!-- Retour via url -->
    <ng-template #routerTemplate>
        <div
            class="header-title-previous margin-right-1"
            [routerLink]="
                previousType === headerPreviousType.previous
                    ? previousUrl
                    : null
            "
        >
            <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
        </div>
    </ng-template>
    <!-- Retour via NavController -->
    <ng-template #navTemplate>
        <div class="header-title-previous margin-right-1">
            <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
        </div>
    </ng-template>
    <!-- Les icônes possible pour le retour -->
    <ng-template #iconTemplate>
        @switch (previousType) { @case (headerPreviousType.previous) {
        <i class="uil uil-angle-left-b" (click)="back()"></i>
        } @case (headerPreviousType.close) {
        <i
            (click)="exitClicked(label, icon, img)"
            class="uil uil-times-circle"
        ></i>
        } }
    </ng-template>
    }
    <div class="info-container" style="width: 100%">
        <div
            class="header-title-label"
            style="margin-right: 10px"
            [routerLink]="previousUrl"
        >
            @if (img) {
            <img
                class="header-title-label-img"
                [src]="img"
                alt="image du header"
            />
            } @if (icon) {
            <i class="header-title-label-icon" [class]="icon"></i>
            } @if (avatarWithBg) {
            <div
                class="header-icon"
                [class.background-color-light-orange]="
                    backgroundIcon === colors.orange
                "
                [class.background-color-light-grey]="
                    backgroundIcon === colors.grey || !backgroundIcon
                "
                [class.background-color-light-blue]="
                    backgroundIcon === colors.blue
                "
                [class.background-color-light-yellow]="
                    backgroundIcon === colors.yellow
                "
                [class.background-color-light-green]="
                    backgroundIcon === colors.green
                "
            >
                @if (avatarWithBg.startsWith('uil')) {
                <i class="uil {{ avatarWithBg }}"></i>
                } @if (!avatarWithBg.startsWith('uil')) {
                <img
                    src="/assets/icon/{{ avatarWithBg }}.svg"
                    alt="Avatar du header"
                />
                }
            </div>
            }
            <h1 class="header-title-label-value margin-left-1" style="flex: 1">
                {{ label }}
            </h1>

            <div class="icon-container">
                @if (isSociety) {
                <i class="uil uil-suitcase margin-left-1"></i>
                }
            </div>
        </div>
        @if (popover) {
        <omedom-popover [content]="popover"></omedom-popover>
        } @if (isShared) {
        <i class="uil uil-share-alt"></i>
        }
        <!-- Pas sur de la condition dans le dropdown, par quel moyen sait-on qu'on affiche un bien ? -->
        @if (roleState.update || roleState.delete) {
        <omedom-dropdown
            class="margin-left-auto"
            iconClass="uil uil-ellipsis-v"
            [onlyButtons]="true"
            ><div content>
                <button
                    class="omedom-icon-button-primary margin-bottom-1"
                    style="margin-right: 0"
                    (click)="editClicked()"
                >
                    <i class="uil uil-edit-alt"></i>
                </button>
                <!-- <button class="omedom-icon-button-primary" style="margin-right: 0;">
        <i class="uil uil-file-download-alt"></i>
      </button> -->
                @if (roleState.delete) {
                <button
                    class="omedom-icon-button-warn"
                    style="margin-right: 0"
                    (click)="deleteClicked()"
                >
                    <i class="uil uil-trash-alt"></i>
                </button>
                }
            </div>
        </omedom-dropdown>
        }
    </div>
</div>

@if (!!tabs && tabs.length) {
<omedom-tab
    [tabs]="tabs ?? []"
    [(selectedTab)]="selectedTab"
    [style.padding-left]="selectedTab?.label === 'Informations' ? '35px' : null"
    (selectedTabChange)="selectedTabChange.next($event)"
></omedom-tab>
}

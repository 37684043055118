<h3>Bail</h3>

@if (typeLeaseOptions.length > 0) {
  <omedom-select
    [(ngModel)]="lease.leaseType"
    [placeholder]="typeLeasePlaceholder"
    label="Type de bail"
    class="margin-top-2"
    [required]="true"
    [options]="typeLeaseOptions"
  ></omedom-select>
}

<omedom-input
  [required]="true"
  class="margin-top-1"
  name="leaseStart"
  icon="uil uil-calendar-alt"
  label="Date de début"
  type="date"
  [(ngModel)]="lease.leaseStart"
  (ngModelChange)="checkTimePeriod()"
  >
</omedom-input>

<omedom-input
  [required]="true"
  name="leaseEnd"
  class="margin-top-1"
  icon="uil uil-calendar-alt"
  label="Date de fin"
  type="date"
  [(ngModel)]="lease.leaseEnd"
  (ngModelChange)="checkTimePeriod()"
  >
</omedom-input>

<omedom-input
  name="guarantyDeposit"
  class="margin-top-1"
  icon="uil uil-euro"
  label="Dépot de garantie"
  placeholder="0"
  type="number"
  [(ngModel)]="lease.guarantyDeposit"
  >
</omedom-input>

@if (society && society.members && society.members.length > 0) {
<hr class="omedom-divider" />
} @if (user) {
<div class="section section-center color-light-black">
    <div class="account-container">
        @if (isEdition) {
        <div>
            @if (user.avatar) {
            <img [src]="user.avatar" alt="Avatar" class="account-picture" />
            } @else {
            <div class="account-default-picture">
                <i class="uil uil-user-circle"></i>
            </div>
            }
        </div>
        }
        <strong>Moi</strong>
    </div>
    <span>{{ userStatus }}</span>
</div>
} @if (society && society.members && society.members.length > 0) {
<hr class="omedom-divider" />
} @for (member of society?.members; track i; let i = $index) {
<omedom-member-card
    [canEditMember]="canEditMember && isEdition"
    [society]="society"
    [index]="i"
    [member]="member"
></omedom-member-card>
} @if (!('accessManageSociety' | isAuthorised | async)) {
<omedom-action
    class="margin-top-2"
    text="Fonctionnalité Premium, changez d'abonnement."
    actionIcon="omedom-icon-crown"
>
</omedom-action>
} @if (('accessManageSociety' | isAuthorised | async) && canEditMember) {
<button class="omedom-button-primary w-100" (click)="createMember()">
    <i class="uil uil-user-plus"></i>
    Ajouter un membre
</button>
}

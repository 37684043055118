import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
    AssetTypes,
    PropertiesDto,
    PropertyEntity,
    PropertyType,
    SelectOption,
    SocietyRoleMember,
    startDateTarification,
} from '@omedom/data';
import { OmedomProperty } from '@omedom/utils';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { SmartService } from '../services';
import { RestService } from './rest.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class PropertyService extends RestService<PropertyEntity> {
    protected override builder = PropertyEntity;

    public propertiess$: BehaviorSubject<PropertiesDto | null> =
        new BehaviorSubject<PropertiesDto | null>(null);

    private propertyCreatedEvent = new Subject();

    public propertyCreatedObservable = this.propertyCreatedEvent.asObservable();

    private propertyDeletedEvent = new Subject();

    public propertyDeletedObservable = this.propertyDeletedEvent.asObservable();

    public hasAtLeastOneProperty$ = new BehaviorSubject<boolean>(false);

    constructor(
        protected override firestore: AngularFirestore,
        protected smartService: SmartService,
        protected userService: UserService,
        private readonly functions: AngularFireFunctions
    ) {
        super(firestore, 'properties');

        // Check if the user has at least one property
        this._hasAtLeastOneProperty();
    }

    /**
     * @description Get all properties by user UID
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID User UID
     * @returns {Promise<PropertyEntity[]>} Properties of the user
     * @memberof PropertyService
     * @example
     * const properties = await propertyService.getUserProperties(userUID);
     */
    public async getUserProperties(userUID: string): Promise<PropertyEntity[]> {
        return await this.search([{ where: 'userUID', operator: '==', value: userUID }]);
    }

    /**
     * @description Get all properties and shared of a user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID User UID
     * @returns {Promise<PropertyEntity[]>} Properties and shared of the user
     * @memberof PropertyService
     * @example
     * const properties = await propertyService.getUserPropertiesAndShared(userUID);
     */
    public async getUserPropertiesAndShared(userUID: string): Promise<PropertyEntity[]> {
        // Get user data
        const user = await this.userService.get(userUID);

        // Check if user is defined
        if (!user) {
            return [];
        }

        // Get user properties
        const userProperties = await this.search([
            { where: 'userUID', operator: '==', value: userUID },
        ]);

        // Get shared properties
        const sharedProperties = await Promise.all(
            user.sharedPropertiesUID?.map(async (x) => await this.get(x)) ?? []
        );

        // Filter property without UID
        // TODO: clean users with sharedPropertiesUID not present in property in properties collection
        const filteredSharedProperties = sharedProperties.filter((x) => x);

        // Get society shared properties
        const societySharedProperties: PropertyEntity[] = [];
        if (user.memberSocietiesUID) {
            for (const societyUID of user.memberSocietiesUID) {
                // Check if societyUID is defined
                if (!societyUID) {
                    continue;
                }

                // Get society properties
                const societyProperties = await this.getPropertiesBySociety(societyUID);

                // Add society properties to societySharedProperties
                societySharedProperties.push(...societyProperties);
            }
        }

        // Merge all properties
        const allProperies = [
            ...userProperties,
            ...filteredSharedProperties,
            ...societySharedProperties,
        ];

        // Remove undefiened
        const propertiesExists = allProperies.filter((x) => x) as PropertyEntity[];

        // Remove duplicates
        const properties = propertiesExists.filter(
            (v, i, a) => a.findIndex((t) => t.uid === v.uid) === i
        );
        return properties;
    }

    /**
     * @description Get all properties and shared of a user (observable version)
     * @author Brisset Killian <killian.brisset@omedom.com>
     * @param {string} userUID
     * @return {*}  {Observable<PropertyEntity[]>}
     * @memberof PropertyService
     */
    public _getUserPropertiesAndShared(userUID: string): Observable<PropertyEntity[]> {
        // Get user properties
        const userProperties$ = this._search([
            { where: 'userUID', operator: '==', value: userUID },
        ]) as Observable<PropertyEntity[]>;

        // Get user data
        const user$ = this.userService._get(userUID);

        // Get shared properties
        const sharedProperties$ = user$.pipe(
            switchMap((user) => {
                // Get shared properties of the user
                if (!user || !user.sharedPropertiesUID || user.sharedPropertiesUID.length === 0) {
                    return of([]);
                }
                const sharedProperties$ = user.sharedPropertiesUID.map((x) => this._get(x));

                // Combine all shared properties
                return combineLatest(sharedProperties$ as Observable<PropertyEntity>[]);
            }),
            catchError((error) => {
                console.error('Error : ', error);
                return of([]);
            })
        );

        // Get society shared properties
        const societySharedProperties$ = user$.pipe(
            switchMap((user) => {
                if (user && user.sharedSocietiesUID && user.sharedSocietiesUID.length > 0) {
                    // Get society shared properties

                    const societyObservables =
                        user.sharedSocietiesUID
                            .filter((societyUID) => societyUID)
                            .map(
                                (societyUID) =>
                                    (this._getPropertiesBySociety(societyUID) as Observable<
                                        PropertyEntity[]
                                    >) ?? of([])
                            ) ?? [];

                    // Combine all society shared properties
                    return combineLatest(societyObservables).pipe(
                        map(
                            (propertiesArrays) =>
                                propertiesArrays.flatMap((properties) => properties) ?? []
                        )
                    );
                } else {
                    return of([]);
                }
            }),
            catchError((error) => {
                console.error('Error : ', error);
                return of([]);
            })
        ) as Observable<PropertyEntity[]>;

        // Filter property without UID
        const filteredSharedProperties$ = sharedProperties$.pipe(
            map((properties) => properties.filter((x) => x))
        );

        // Merge all properties and apply filters
        return combineLatest([
            userProperties$,
            filteredSharedProperties$,
            societySharedProperties$,
        ]).pipe(
            map(([userProperties, filteredSharedProperties, societySharedProperties]) => {
                // Merge all properties
                const allProperties = [
                    ...userProperties,
                    ...filteredSharedProperties,
                    ...societySharedProperties,
                ];

                // Remove undefiened
                const propertiesExists = allProperties.filter((x) => x);

                // Remove duplicates
                const properties = propertiesExists.filter(
                    (v, i, a) => a.findIndex((t) => t.uid === v.uid) === i
                );

                // Return properties
                return properties ?? [];
            })
        );
    }

    /**
     * @description Get all properties and shared of a user can access (first property or renew date not expired) as a select option
     * @author Brisset Killian
     * @date 16/02/2024
     * @param {string} userUID
     * @return {*}  {Promise<PropertyEntity[]>}
     * @memberof PropertyService
     */
    public async getUserPropertiesAndSharedAccessible(userUID: string): Promise<PropertyEntity[]> {
        // Get all properties and shared of a user
        const properties = await this.getUserPropertiesAndShared(userUID);

        // Filter properties
        return properties.filter(
            // Check if the property is accessible
            (x) => this.isPropertyAccessible(x)
        );
    }

    /**
     * @description Get all properties and shared of a user can access (first property or renew date not expired) as a select option (observable version)
     * @author Brisset Killian <killian.brisset@oemdom.com>
     * @param {string} userUID
     * @return {*}  {Observable<PropertyEntity[]>}
     * @memberof PropertyService
     */
    public _getUserPropertiesAndSharedAccessible(userUID: string): Observable<PropertyEntity[]> {
        // Get all properties and shared of a user

        return this._getUserPropertiesAndShared(userUID).pipe(
            map((properties) => {
                // Filter properties
                return properties
                    ? properties.filter(
                        // Check if the property is accessible
                        (x) => this.isPropertyAccessible(x)
                    )
                    : [];
            })
        );
    }

    /**
     * @description get all user properties and shared properties as admin
     * @author ANDRE Felix
     * @param {string} userUID
     * @returns {*}  {Promise<PropertyEntity[]>}
     * @memberof PropertyService
     */
    public async getUserPropertiesAndSharedAsAdmin(userUID: string): Promise<PropertyEntity[]> {
        // Get user data
        const user = await this.userService.get(userUID);

        // Check if user is defined
        if (!user) {
            return [];
        }

        const allSharedProperties = await this.getUserPropertiesAndSharedAccessible(userUID);

        const allSharedPropertiesAsAdmin = allSharedProperties.filter((property) => {
            if (property.userUID === user.uid) {
                return true;
            }
            let isAdmin = false;
            property.sharing.forEach((sharing) => {
                if (sharing.email === user.email && sharing.role === 'Administrateur') {
                    isAdmin = true;
                }
            });
            return isAdmin;
        });
        return allSharedPropertiesAsAdmin;
    }

    /**
     * @description Get all properties of a user in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID User UID
     * @returns {Observable<PropertyEntity[]>} Properties of the user
     * @memberof PropertyService
     * @example
     * propertyService._getUserProperties(userUID).subscribe(properties => {
     *    // Do something with properties
     * });
     */
    public _getUserProperties(userUID: string): Observable<PropertyEntity[]> {
        return this._search([{ where: 'userUID', operator: '==', value: userUID }]);
    }

    /**
     * @description Get all properties and shared of a user for a select component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID User UID
     * @returns {Promise<SelectOption[]>} Properties and shared of the user in a select format
     * @memberof PropertyService
     * @example
     * const propertiesAndSharedOptions = await propertyService.getUserPropertiesAndSharedOptions(userUID);
     */

    // TODO : Add shared properties
    public async getUserPropertiesAndSharedOptions(userUID: string): Promise<SelectOption[]> {
        const properties = (await this.getUserPropertiesAndShared(userUID)).map(
            (x) =>
            ({
                id: x.uid,
                label: x.name,
                image: x.photo,
                icon: !x.photo
                    ? x.type === PropertyType.immeuble
                        ? 'uil uil-building'
                        : 'uil uil-home'
                    : undefined,
                isAccesible: this.isPropertyAccessible(x),
                purchaseYear: x.purchaseDetails?.year,
                assetType:
                    x.type === PropertyType.immeuble
                        ? AssetTypes.building
                        : AssetTypes.property,
            } as SelectOption)
        );

        const sortProperties = properties.sort((a, b) => a.label.localeCompare(b.label));

        return sortProperties;
    }

    /**
     * @description Get all properties of a user for a select component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID User UID
     * @returns {Promise<SelectOption[]>} Properties of the user in a select format
     * @memberof PropertyService
     * @example
     * const propertiesOptions = await propertyService.getUserPropertiesOptions(userUID);
     */
    public async getUserPropertiesOptions(userUID: string): Promise<SelectOption[]> {
        return (await this.getUserProperties(userUID)).map(
            (x) =>
            ({
                id: x.uid,
                label: x.name,
                image: x.photo,
                isAccesible: this.isPropertyAccessible(x),
                icon: !x.photo
                    ? x.type === PropertyType.immeuble
                        ? 'uil uil-building'
                        : 'uil uil-home'
                    : undefined,
                purchaseYear: x.purchaseDetails?.year,
                assetType:
                    x.type === PropertyType.immeuble
                        ? AssetTypes.building
                        : AssetTypes.property,
            } as SelectOption)
        );
    }

    /**
     * @description get all user properties and shared properties as admin
     * @author ANDRE Felix
     * @param {string} userUID
     * @returns {*}  {Promise<SelectOption[]>}
     * @memberof PropertyService
     */
    public async getUserPropertiesAndSharedAsAdminOptions(
        userUID: string
    ): Promise<SelectOption[]> {
        return (await this.getUserPropertiesAndSharedAsAdmin(userUID)).map(
            (x) =>
            ({
                id: x.uid,
                label: x.name,
                image: x.photo,
                isAccesible: this.isPropertyAccessible(x),
                icon: !x.photo
                    ? x.type === PropertyType.immeuble
                        ? 'uil uil-building'
                        : 'uil uil-home'
                    : undefined,
                purchaseYear: x.purchaseDetails?.year,
                assetType:
                    x.type === PropertyType.immeuble
                        ? AssetTypes.building
                        : AssetTypes.property,
            } as SelectOption)
        );
    }

    /**
     * @description Get all properties of a user for a select component with smart properties only and if user is editor of the property
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID User UID
     * @returns {Promise<SelectOption[]>} Properties in smart of the user in a select format
     * @memberof PropertyService
     * @example
     * const propertiesSmartOptions = await propertyService.getUserPropertiesSmartOptions(userUID);
     */
    public async getUserPropertiesSmartOptions(userUID: string): Promise<SelectOption[]> {
        // Get user data
        const user = await this.userService.get(userUID);

        // Check if user is defined
        if (!user) {
            return [];
        }

        // Get user properties, and user
        const properties = await this.getUserPropertiesAndShared(userUID);

        const editableProperties = properties
            .filter((property) => {
                if (property.userUID === user.uid) {
                    return true;
                }
                let isAdminOrOwner = true;
                property.sharing?.forEach((sharing) => {
                    if (
                        sharing.email === user.email &&
                        (sharing.role === SocietyRoleMember.editor ||
                            sharing.role === SocietyRoleMember.reader)
                    ) {
                        isAdminOrOwner = false;
                    }
                });
                return isAdminOrOwner;
            })
            .sort((a, b) => a.name.localeCompare(b.name));

        // Return properties in select format
        return editableProperties.map(
            (x) =>
            ({
                id: x.uid,
                label: x.name,
                image: x.photo,
                isAccesible: this.isPropertyAccessible(x),
                icon: !x.photo
                    ? x.type === PropertyType.immeuble
                        ? 'uil uil-building'
                        : 'uil uil-home'
                    : undefined,
                purchaseYear: x.purchaseDetails?.year,
                assetType:
                    x.type === PropertyType.immeuble
                        ? AssetTypes.building
                        : AssetTypes.property,
            } as SelectOption)
        );
    }

    public async getBuildingPropertiesOptions(buildingUID: string): Promise<SelectOption[]> {
        const building = await this.get(buildingUID);

        // Check if building is defined
        if (!building) {
            return [];
        }

        const buildingOption: SelectOption = {
            id: building.uid,
            label: building.name,
            image: building.photo,
            icon: !building.photo ? 'uil uil-building' : undefined,
            purchaseYear: building.purchaseDetails?.year,
            assetType: AssetTypes.building,
        };
        const lots = await this.getPropertiesByBuilding(buildingUID);
        if (lots.length === 0) {
            return [buildingOption];
        }
        const lotsOptions = lots.map(
            (lot) =>
            ({
                id: lot.uid,
                label: lot.name,
                image: lot.photo,
                icon: !lot.photo ? 'uil uil-home' : undefined,
                isAccesible: this.isPropertyAccessible(lot),
                purchaseYear: lot.purchaseDetails?.year,
                assetType: AssetTypes.property,
            } as SelectOption)
        );

        // Filter in case lot is undefined
        const lotsOptionsFiltered = lotsOptions.filter((x) => x.id);

        return [buildingOption, ...lotsOptionsFiltered];
    }

    /**
     * @description Get all properties of a user for a select component in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID User UID
     * @returns {Observable<SelectOption[]>} Properties of the user in a select format
     * @memberof PropertyService
     * @example
     * propertyService._getUserPropertiesOptions(userUID).subscribe(propertiesOptions => {
     *   // Do something with propertiesOptions
     * });
     */
    public _getUserPropertiesOptions(userUID: string): Observable<SelectOption[]> {
        return this._getUserProperties(userUID).pipe(
            map((x) =>
                x.map(
                    (y) =>
                    ({
                        id: y.uid,
                        label: y.name,
                        image: y.photo,
                        isAccesible: this.isPropertyAccessible(y),
                        icon: !y.photo
                            ? y.type === PropertyType.immeuble
                                ? 'uil uil-building'
                                : 'uil uil-home'
                            : undefined,
                        purchaseYear: y.purchaseDetails?.year,
                        assetType:
                            y.type === PropertyType.immeuble
                                ? AssetTypes.building
                                : AssetTypes.property,
                    } as SelectOption)
                )
            )
        );
    }

    /**
     * @description Get all properties of a society for a select component in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} societyUID Society UID
     * @returns {Promise<SelectOption[]>} Properties of the society in a select format
     * @memberof PropertyService
     * @example
     * const propertiesOptions = await propertyService.getSocietyPropertiesOptions(societyUID);
     */
    public async getSocietyPropertiesOptions(societyUID: string): Promise<SelectOption[]> {
        const propertyOfASociety = (await this.getPropertiesBySociety(societyUID)).map(
            (x) =>
            ({
                id: x.uid,
                label: x.name,
                image: x.photo,
                isAccesible: this.isPropertyAccessible(x),
                icon: !x.photo
                    ? x.type === PropertyType.immeuble
                        ? 'uil uil-building'
                        : 'uil uil-home'
                    : undefined,
                purchaseYear: x.purchaseDetails?.year,
                assetType:
                    x.type === PropertyType.immeuble
                        ? AssetTypes.building
                        : AssetTypes.property,
            } as SelectOption)
        );
        return propertyOfASociety;
    }

    /**
     * @description Get some properties of a user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string[]} propertyUIDs Property UIDs to filter
     * @param {string} userUID User UID
     * @returns {Promise<PropertyEntity[]>}
     * @memberof PropertyService
     * @example
     * const properties = await propertyService.getUserPropertiesByIds(propertyUIDs, userUID);
     */
    public async getUserPropertiesByIds(
        propertyUIDs: string[],
        userUID: string
    ): Promise<PropertyEntity[]> {
        if (!propertyUIDs || propertyUIDs.length === 0) {
            return [];
        }
        return await this.search([
            { where: 'userUID', operator: '==', value: userUID },
            { where: 'uid', operator: 'in', value: propertyUIDs },
        ]);
    }

    /**
     * @description Get all properties of a society
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} societyUID Society UID
     * @returns {Promise<PropertyEntity[]>} Properties of the society
     * @memberof PropertyService
     * @example
     * const properties = await propertyService.getPropertiesBySociety(societyUID);
     */
    public async getPropertiesBySociety(societyUID: string): Promise<PropertyEntity[]> {
        return await this.search([{ where: 'societyUID', operator: '==', value: societyUID }]);
    }

    /**
     * @description Get all properties of a society in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} societyUID Society UID
     * @returns {Observable<PropertyEntity[]>} Properties of the society
     * @memberof PropertyService
     * @example
     * propertyService._getPropertiesBySociety(societyUID).subscribe(properties => {
     *   // Do something with properties
     * });
     */
    public _getPropertiesBySociety(societyUID: string): Observable<PropertyEntity[]> {
        if (!societyUID) {
            //By security, if societyUID is undefined, return an empty array
            return of([]);
        }
        return this._search([{ where: 'societyUID', operator: '==', value: societyUID }]);
    }

    /**
     * @description Get all residency properties of a user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID User UID
     * @returns {Promise<PropertyEntity[]>} Residency properties of the user
     * @memberof PropertyService
     * @example
     * const properties = await propertyService.getUserResidencyProperties(userUID);
     */
    public async getUserResidencyProperties(userUID: string): Promise<PropertyEntity[]> {
        // Get all properties of the user
        const properties = await this.getUserProperties(userUID);

        // Filter properties to get only residency properties
        return OmedomProperty.excludeRentalProperties(properties);
    }

    /**
     * @description Get all rental properties of a user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID User UID
     * @returns {Promise<PropertyEntity[]>} Rental properties of the user
     * @memberof PropertyService
     * @example
     * const properties = await propertyService.getUserRentalProperties(userUID);
     */
    public async getUserRentalProperties(userUID: string): Promise<PropertyEntity[]> {
        // Get all properties of the user
        const properties = await this.getUserProperties(userUID);

        // Filter properties to get only rental properties
        return OmedomProperty.excludeResidentialProperties(properties);
    }

    /**
     * @description Get all properties of a user and its shared societies
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID
     * @param {string} societyUID
     * @returns {Promise<PropertyEntity[]>} Properties of the user and its shared societies
     * @memberof PropertyService
     * @example
     * const properties = await propertyService.getPropertiesBySharedSociety(userUID, societyUID);
     */
    public async getPropertiesBySharedSociety(
        userUID: string,
        societyUID: string
    ): Promise<PropertyEntity[]> {
        const userProperties = await this.getUserProperties(userUID);

        const societyProperties = await this.getPropertiesBySociety(societyUID);

        const properties = [...userProperties, ...societyProperties];

        // Remove duplicates
        return properties.filter((p, i) => properties.findIndex((x) => x.uid === p.uid) === i);
    }

    public async getPropertiesByBuilding(buildingUID: string): Promise<PropertyEntity[]> {
        return await this.search([
            { where: 'parentPropertyUID', operator: '==', value: buildingUID },
        ]);
    }

    public _getPropertiesByBuilding(buildingUID: string): Observable<PropertyEntity[]> {
        return this._search([{ where: 'parentPropertyUID', operator: '==', value: buildingUID }]);
    }

    /**
     * @description Check if a property exist with his uid
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 21/10/2023
     * @param {string} uid
     * @returns {*}  {Observable<boolean>}
     * @memberof PropertyService
     */
    public isPropertyExist(uid: string): Observable<boolean> {
        return this.functions
            .httpsCallable<{ uid: string }, { isPropertyExist: boolean }>('isPropertyExist')({
                uid,
            })
            .pipe(map((data) => data.isPropertyExist));
    }

    propertyCreated(): void {
        this.propertyCreatedEvent.next(null);
    }

    propertyDeleted(): void {
        this.propertyDeletedEvent.next(null);
    }

    /**
     * @description Check if the user has at least one property and update hasAtLeastOneProperty$ subject
     * @author Brisset Killian
     * @private
     * @memberof PropertyService
     */
    private _hasAtLeastOneProperty(): void {
        this.userService.user$.subscribe((user) => {
            if (!user) {
                this.hasAtLeastOneProperty$.next(true);
                return;
            }
            this._search([
                { where: 'userUID', operator: '==', value: user?.uid },
                { limit: 1, limitToLast: false },
            ]).subscribe((properties) => {
                this.hasAtLeastOneProperty$.next(!!properties.length);
            });
        });
    }

    /**
     * @description Check if the user has at the number of limit properties
     * @author Brisset Killian
     * @param {number} limit
     * @private
     * @memberof PropertyService
     */
    public _hasLimitProperties(limit: number): Observable<boolean> {
        if (limit <= 0) {
            return of(false);
        }

        return this.userService.user$.pipe(
            switchMap((user) => {
                if (!user) {
                    return of(false);
                }
                return this._search([
                    { where: 'userUID', operator: '==', value: user.uid },
                    { limit: limit, limitToLast: false },
                ]).pipe(map((properties) => properties.length >= limit));
            })
        );
    }

    /**
     * @description Check if the property is accessible (first property or renew date not expired) and return a boolean value
     * @param {PropertyEntity} property
     * @return {*}  {boolean}
     * @memberof PropertyService
     */
    public isPropertyAccessible(property: PropertyEntity): boolean {
        const today = new Date();
        const transitionDate = new Date(startDateTarification);
        if (today.getTime() < transitionDate.getTime()) {
            return true;
        }
        const accesible = property?.accesible;
        if (!accesible) {
            return false;
        }
        const { firstProperty, renewDate } = accesible;
        if (firstProperty) {
            return true;
        }
        const currentDate = new Date();
        const renewDateParts = renewDate.split('/').reverse();
        const renewDateObj = new Date(renewDateParts.join('/'));
        return currentDate.getTime() < renewDateObj.getTime();
    }

    public getPropertiesOptions(properties: PropertyEntity[]): SelectOption[] {
        return properties.map(
            (x) =>
            ({
                id: x.uid,
                label: x.name,
                image: x.photo,
                isAccesible: this.isPropertyAccessible(x),
                icon: !x.photo
                    ? x.type === PropertyType.immeuble
                        ? 'uil uil-building'
                        : 'uil uil-home'
                    : undefined,
                purchaseYear: x.purchaseDetails?.year,
                assetType:
                    x.type === PropertyType.immeuble
                        ? AssetTypes.building
                        : AssetTypes.property,
            } as SelectOption)
        );
    }

    public getPropertyList(forceUpdate = false): Observable<PropertiesDto | null> {
        if (
            this.propertiess$.value &&
            this.propertiess$.value.properties.length > 0 &&
            !forceUpdate
        ) {
            return this.propertiess$;
        }

        return this.fetchUserPropertyList().pipe(
            tap((properties: PropertiesDto) => {
                if (properties) {
                    this.propertiess$.next(Object.assign({}, properties));
                }
            })
        );
    }

    public getTotalPropertyShared(forceUpdate = false): Observable<number> {
        return this.userService.user$.pipe(
            switchMap((user) =>
                this.getPropertyList(forceUpdate).pipe(
                    map(
                        (properties) =>
                            properties?.properties.filter(
                                (property) =>
                                    property.sharing!.length > 0 && property.userUID === user.uid
                            ).length || 0
                    )
                )
            )
        );
    }

    private fetchUserPropertyList(): Observable<PropertiesDto> {
        const callable = this.functions.httpsCallable('getUserProperty');
        return callable(null);
    }
}

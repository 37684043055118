import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BankAccountEntity, BankTransactionByDay, BankTransactionEntity } from '@omedom/data';
import { BehaviorSubject } from 'rxjs';

import { elementAnimation, listAnimation, transactionAnimation } from '../../../animations';

@Component({
    selector: 'omedom-bank-transaction-list',
    templateUrl: './bank-transaction-list.container.html',
    styleUrls: ['./bank-transaction-list.container.scss'],
    animations: [elementAnimation, listAnimation, transactionAnimation],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BankTransactionListContainer),
            multi: true,
        },
    ],
})
export class BankTransactionListContainer implements OnChanges, ControlValueAccessor {
    /**
     * @description List of transactions to display in the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @type {BankTransactionEntity[]}
     * @memberof BankTransactionListContainer
     */
    @Input({ required: true })
    public transactions: BankTransactionEntity[] = [];

    /**
     * @description Bank account to display in the transaction component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @type {BankAccountEntity}
     * @memberof BankTransactionListContainer
     */
    @Input()
    public account?: BankAccountEntity;

    /**
     * @description Display the transactions masked or not in the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/04/2024
     * @type {boolean}
     * @memberof BankTransactionListContainer
     */
    @Input()
    public masked: boolean = false;

    /**
     * @description Introduction to display in the component to explain the purpose of the component to the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/04/2024
     * @type {string}
     * @memberof BankTransactionListContainer
     */
    @Input()
    public introduction?: string;

    /**
     * @description Message to display in the component when there is no transaction to display in the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/04/2024
     * @type {string}
     * @memberof BankTransactionListContainer
     */
    @Input()
    public message: string = 'Aucune transaction à afficher.';

    /**
     * @description Icon to display in the component to illustrate the purpose of the component to the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/04/2024
     * @type {string}
     * @memberof BankTransactionListContainer
     */
    @Input()
    public icon: string = 'credit-card';

    /**
     * @description List of transactions grouped by day to display in the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @type {BankTransactionByDay[]}
     * @memberof BankTransactionListContainer
     */
    public transactionByDay: BankTransactionByDay[] = [];

    /**
     * @description State of the component (pending, success, error) to display the loader or an error message in the template
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/04/2024
     * @memberof BankTransactionListContainer
     */
    public state$ = new BehaviorSubject<string>('pending');

    /**
     * @description Filter options to display in the component to filter the transactions by associated or not associated transactions in the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/05/2024
     * @memberof BankTransactionListContainer
     */
    public options = [
        { value: true, label: 'Associé', icon: 'link-alt' },
        { value: false, label: 'Non associé', icon: 'link-broken' },
    ];

    /**
     * @description Value selected by default (if any) or undefined if none selected in the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/05/2024
     * @type {boolean}
     * @memberof BankTransactionListContainer
     */
    public filter?: boolean;

    /**
     * @description Display filter in the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/05/2024
     * @type {boolean}
     * @memberof BankTransactionListContainer
     */
    @Input()
    public enableFilter: boolean = false;

    /**
     * @description Display buttons in the component to associate transactions in the component to the parent component or not in the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @type {boolean}
     * @memberof BankTransactionListContainer
     */
    @Input()
    public showActions: boolean = true;

    /**
     * @description Show the checkbox to select the item (default: false) in the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @type {boolean}
     * @memberof BankTransactionListContainer
     */
    @Input()
    public selectable: boolean = false;

    /**
     * @description Event emitted when the user wants to associate a transaction in the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/05/2024
     * @memberof BankTransactionListContainer
     */
    @Output()
    public onAssociate = new EventEmitter<BankTransactionEntity>();

    /**
     * @description Event emitted when the user wants to dissociate a transaction in the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/05/2024
     * @memberof BankTransactionListContainer
     */
    @Output()
    public onDissociate = new EventEmitter<BankTransactionEntity>();

    /**
     * @description Event emitted when the user selects a transaction in the component (if selectable is true)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @memberof BankTransactionListContainer
     */
    @Output()
    public onSelectionChange = new EventEmitter<string[]>();

    /**
     * @description Select all transactions in the component (only if selectable is true) in the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @type {boolean}
     * @memberof BankTransactionListContainer
     */
    public selectAll: boolean = false;

    /**
     * @description Value of the component (used by the ControlValueAccessor) (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @type {string[]}
     * @memberof BankTransactionListContainer
     */
    @Input()
    public value: string[] = [];

    /**
     * @description Callback function to call when the value changes. This is called by the ControlValueAccessor when the value changes
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @param {string[]} value
     * @type {Function}
     * @memberof BankTransactionListContainer
     */
    public onChange: Function = (value: string[]) => {
        this.onSelectionChange.emit(value);

        // Check if all transactions are selected
        this.selectAll = this.value.length === this.transactions.length;
    };

    /**
     * @description Callback function to call when the button is touched. This is called by the ControlValueAccessor when the button is touched in the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @type {Function}
     * @memberof BankTransactionListContainer
     */
    public onTouched: Function = () => {};

    /**
     * @description Display the multiple selection in the component (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @type {boolean}
     * @memberof BankTransactionListContainer
     */
    @Input()
    public isMultiple: boolean = false;

    ngOnChanges(changes: SimpleChanges): void {
        this.state$.next('pending');
        // Check if the transactions has changed
        if (changes['transactions']?.previousValue === changes['transactions']?.currentValue) {
            this.state$.next('ok');
            return;
        }

        // Check if the transactions is not undefined
        if (changes['transactions']?.currentValue) {
            this.getTransactionByDay();
        } else {
            this.transactionByDay = [];
        }

        this.state$.next('ok');
    }

    writeValue(value: string[]): void {
        this.value = value ?? [];

        // Check if all transactions are selected
        this.selectAll = this.value.length === this.transactions.length;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    /**
     * @description Group transactions by day in the transactionByDay property of the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @memberof BankTransactionListContainer
     */
    public getTransactionByDay(): void {
        let transit = this.transactions.reduce<BankTransactionByDay[]>((acc, transaction) => {
            const filterAssociated = this.filter === true && transaction.treasuryUID === undefined;
            const filterNonAssociated =
                this.filter === false && transaction.treasuryUID !== undefined;

            // Filter by associated or not
            if (filterAssociated || filterNonAssociated) {
                return acc;
            }

            // Check if the transaction masking is the same as the component
            if ((transaction.masked && !this.masked) || (!transaction.masked && this.masked)) {
                return acc;
            }

            const day = transaction.date;
            const index = acc.findIndex((item: BankTransactionByDay) => {
                const sameDay = item.day.getDate() === day.getDate();
                const sameMonth = item.day.getMonth() === day.getMonth();
                const sameYear = item.day.getFullYear() === day.getFullYear();

                return sameDay && sameMonth && sameYear;
            });

            if (index === -1) {
                acc.push({ day, transactions: [transaction] });
            } else {
                acc[index].transactions.push(transaction);
            }

            return acc;
        }, []);

        // Compare with transactionByDay to avoird re-rendering
        if (JSON.stringify(transit) === JSON.stringify(this.transactionByDay)) {
            return;
        }

        // Replace only items that have changed or are new
        transit.forEach((item, _) => {
            const transactionByDay = this.transactionByDay.find(
                (element) => element.day.getTime() === item.day.getTime()
            );

            if (!transactionByDay) {
                this.transactionByDay.unshift(item);
                return;
            }

            if (transactionByDay.transactions.length !== item.transactions.length) {
                transactionByDay.transactions = item.transactions;
            }
        });

        // Remove items that have been removed
        this.transactionByDay = this.transactionByDay.filter((item) => {
            return transit.some((element) => element.day.getTime() === item.day.getTime());
        });

        // Sort by date
        this.transactionByDay.sort((a, b) => {
            return b.day.getTime() - a.day.getTime();
        });
    }

    /**
     * @description Track by function for the ngFor directive in the template to avoid re-rendering of the component when the transactions are updated
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/04/2024
     * @param {number} index
     * @param {BankTransactionEntity} transaction
     * @returns {string}
     * @memberof BankTransactionListContainer
     */
    public trackByTransaction(_: number, transaction: BankTransactionEntity): string {
        return transaction.uid;
    }

    /**
     * @description Broadcast the event to associate a transaction in the component to the parent component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/05/2024
     * @param {BankTransactionEntity} transaction
     * @memberof BankTransactionListContainer
     */
    public associateTransaction(transaction: BankTransactionEntity): void {
        this.onAssociate.emit(transaction);
    }

    /**
     * @description Broadcast the event to dissociate a transaction in the component to the parent component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/05/2024
     * @param {BankTransactionEntity} transaction
     * @memberof BankTransactionListContainer
     */
    public dissociateTransaction(transaction: BankTransactionEntity): void {
        this.onDissociate.emit(transaction);
    }

    /**
     * @description If true, the transaction is selected
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @param {BankTransactionEntity} transaction
     * @returns {*}  {boolean}
     * @memberof BankTransactionListContainer
     */
    public isSelected(transaction: BankTransactionEntity): boolean {
        return this.value.includes(transaction.uid);
    }

    /**
     * @description Select a transaction in the component (only if selectable is true)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @param {BankTransactionEntity} transaction
     * @param {Date} [day]
     * @memberof BankTransactionListContainer
     */
    public selectTransaction(transaction: BankTransactionEntity, day?: Date): void {
        if (this.selectable) {
            const index = this.value.indexOf(transaction.uid);

            // Check if the transaction is already selected
            if (index === -1) {
                this.value.push(transaction.uid);
            } else {
                this.value.splice(index, 1);
            }

            // Unselect all transactions of the same day
            if (day && !this.isMultiple) {
                this.unSelectTransactionSameDay(transaction, day);
            }

            // Update the value
            this.onChange(this.value);
        }
    }

    /**
     * @description Select all transactions in the component (only if selectable is true)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @param {boolean} value
     * @memberof BankTransactionListContainer
     */
    public toggleSelectAll(value: boolean): void {
        // Check if all transactions are selected
        if (value) {
            this.value = this.transactions.map((transaction) => transaction.uid);
        } else {
            this.value = [];
        }

        // Update the value
        this.onChange(this.value);
    }

    /**
     * @description Unselect all transactions in the component (only if selectable is true)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 17/05/2024
     * @private
     * @param {BankTransactionEntity} transaction
     * @param {Date} day
     * @memberof BankTransactionListContainer
     */
    private unSelectTransactionSameDay(transaction: BankTransactionEntity, day: Date): void {
        // Get all transactions of the same day
        const sameDay = this.transactions.filter((item) => {
            return (
                item.date.getDate() === day.getDate() &&
                item.date.getMonth() === day.getMonth() &&
                item.date.getFullYear() === day.getFullYear()
            );
        });

        sameDay.forEach((item) => {
            // Check if this is the same transaction
            if (item.uid === transaction.uid) {
                return;
            }

            // Check if the transaction is already selected
            const index = this.value.indexOf(item.uid);

            if (index !== -1) {
                this.value.splice(index, 1);
            }
        });
    }
}

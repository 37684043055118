import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EventDom, levels, UserEntity } from '@omedom/data';
import { UserService } from '@omedom/services';
import { OmedomLevel } from '@omedom/utils';
import { Subscription } from 'rxjs';

@Component({
    selector: 'omedom-level-map',
    templateUrl: './level-map.component.html',
    styleUrls: ['./level-map.component.scss'],
})
export class LevelMapComponent implements OnInit, OnDestroy {
    /**
     * @description List of levels to display on map of levels
     * @author Jérémie Lopez
     * @memberof LevelMapComponent
     */
    public levelMap = [...levels].sort((a, b) => b.order - a.order);

    /**
     * @description User Data
     * @author Jérémie Lopez
     * @type {UserEntity}
     * @memberof LevelMapComponent
     */
    public user!: UserEntity;

    /**
     * @description List of subscriptions to unsub after destroy the component
     * @author Jérémie Lopez
     * @private
     * @type {Subscription[]}
     * @memberof LevelMapComponent
     */
    private subscriptions: Subscription[] = [];

    /**
     * @description Path of the level to display on map of levels
     * @author Jérémie Lopez
     * @type {ElementRef<SVGPathElement>}
     * @memberof LevelMapComponent
     */
    @ViewChild('path', { static: true })
    public path?: ElementRef<SVGPathElement>;

    constructor(private userService: UserService) {}

    ngOnInit(): void {
        const user$ = this.userService.user$.subscribe((user: UserEntity) => {
            this.user = user;
            if (!this.path?.nativeElement) {
                return;
            }
            this.path.nativeElement.style.strokeWidth = `10`;
            this.path.nativeElement.style.strokeDasharray = `${this.path.nativeElement.getTotalLength()}px`;
            this.path.nativeElement.style.strokeDashoffset = `${this.path.nativeElement.getTotalLength()}px`;
            this.path.nativeElement.style.display = `block`;

            // Set the offset of the path to the length of the path
            const offset = OmedomLevel.calculateOffset(
                this.user.dom ?? 0,
                this.path.nativeElement.getTotalLength()
            );

            this.path.nativeElement.style.strokeDashoffset = `${offset}px`;

            // Set the scroll of the modal to the bottom
            const home = document.querySelector('omedom-level-home');
            home?.scrollTo(0, home.scrollHeight);
        });

        this.subscriptions.push(user$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    /**
     * @description Get the mask of the level to display on map of levels
     * @author Jérémie Lopez
     * @param {number} level
     * @return {*}  {string}
     * @memberof LevelMapComponent
     */
    public getLevelMask(level: number): string {
        return `url("/assets/levels/${level}.svg") no-repeat center`;
    }

    /**
     * @description Get the level of the user from the DOM of the user
     * @author Jérémie Lopez
     * @param {number} dom
     * @return {*}  {number}
     * @memberof LevelMapComponent
     */
    public getLevel(dom: number): number {
        return OmedomLevel.getLevel(dom);
    }

    public getNextLevel(dom: number): number {
        return OmedomLevel.getNextLevel(dom);
    }

    /**
     * @description Get the events of the level to display on map of levels
     * @author Jérémie Lopez
     * @param {number} level
     * @return {*}  {EventDom[]}
     * @memberof LevelMapComponent
     */
    public getEventsForLevel(level: number): EventDom[] {
        return OmedomLevel.getEventsToReachLevel(level, this.user?.eventsDom ?? []);
    }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import { Observable, of, Subscription } from 'rxjs';
import Step from 'shepherd.js/src/types/step';

import { UserService } from '../core';
import { UserEntity } from '@omedom/data';

@Injectable({
    providedIn: 'root',
})
export class PropertyTutorialService {
    /**
     * @description Property UID to set the routing
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @type {string}
     * @memberof PropertyTutorialService
     */
    public propertyUID?: string;

    /**
     * @description Used to indicate whether it's a first or second pass of tutorial (in treasury-property)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @type {Observable<boolean>}
     * @memberof PropertyTutorialService
     */
    public tutorialFirstStep$: Observable<boolean> = of(true);

    /**
     * @description User UID to update the finishedTutorials property in the database
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @type {string}
     * @memberof PropertyTutorialService
     */
    public userUID?: string;

    /**
     * @description Current User
     * @author ANDRE Felix
     * @type {UserEntity}
     * @memberof PropertyTutorialService
     */
    private user?: UserEntity;

    /**
     * @description Subscription to unsubscribe when the tutorial is finished or canceled (in treasury-property)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @type {Subscription}
     * @memberof PropertyTutorialService
     */
    public subscription?: Subscription;

    constructor(
        private readonly shepherdService: ShepherdService,
        private router: Router,
        private userService: UserService
    ) {}

    /**
     * @description Launch the tutorial
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @param {string} [propertyUID] Property UID to set the routing
     * @returns {Promise<void>}
     * @memberof PropertyTutorialService
     * @example
     * this.propertyTutorialService.launchTutorial(propertyUID);
     */
    public async launchTutorial(propertyUID?: string): Promise<void> {
        if (propertyUID) {
            // Get the user  UID
            this.subscription = this.userService.user$.subscribe(async (user) => {
                this.userUID = user.uid;
                this.user = await this.userService.get(this.userUID);
            });

            // Set the property UID
            this.propertyUID = propertyUID;
            // if (!this.shepherdService.isActive) {
            // Set the tutorial config
            this.shepherdService.modal = true;
            this.shepherdService.defaultStepOptions = {
                canClickTarget: false,
                arrow: false,
            };
            // if (!this.shepherdService.isActive) {
            // Add the steps
            this.shepherdService.addSteps([
                this.getPropertyCardStep(),
                this.getPropertyInfoStep(),
                this.getAddTreasuryStep(),
            ]);

            // Start the tutorial
            setTimeout(() => this.shepherdService.start());
            // }
        }
    }

    /**
     * @description Get the first step of the tutorial (in treasury-property)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @private
     * @returns {Step.StepOptions}
     * @memberof PropertyTutorialService
     * @example
     * this.shepherdService.addSteps([this.getPropertyCardStep()]);
     */
    private getPropertyCardStep(): Step.StepOptions {
        return {
            id: 'tutoPropertyCard',
            attachTo: {
                element: '#tutoPropertyCard0',
                on: 'bottom',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, 84] } }],
            // },
            modalOverlayOpeningPadding: 10,
            modalOverlayOpeningRadius: 10,
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Bravo ! Retrouvez ici votre nouveau bien. Découvrons ensemble la fiche de votre bien. </span>',
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: async () => await this.cancelTutorial(true),
                    classes: 'cancelButton',
                },
                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: () => {
                        this.router
                            .navigate(['/tabs/property/info/' + this.propertyUID], {
                                queryParams: { tab: 'info' },
                            })
                            .then(() => {
                                this.tutorialFirstStep$ = of(false);
                                setTimeout(() => this.shepherdService.next(), 200);
                            });
                    },
                },
            ],
        };
    }

    /**
     * @description Get the second step of the tutorial (in treasury-property)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @private
     * @returns {Step.StepOptions}
     * @memberof PropertyTutorialService
     * @example
     * this.shepherdService.addSteps([this.getPropertyInfoStep()]);
     */
    private getPropertyInfoStep(): Step.StepOptions {
        return {
            id: 'propertyInfoStep',
            attachTo: {
                element: '#propertyInfoStep',
                on: 'bottom',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, -10] } }],
            // },
            modalOverlayOpeningPadding: 10,
            modalOverlayOpeningRadius: 10,
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Retrouvez toutes les informations concernant votre bien. Naviguez d\'onglet en onglet pour plus de détails. Modifiez à votre guise ! </span>',
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: async () => await this.cancelTutorial(true),
                    classes: 'cancelButton',
                },
                {
                    text: '<i class="uil uil-angle-left"></i><span>Précédent</span>',
                    action: () => {
                        this.router.navigate(['/tabs/property/']).then(() => {
                            setTimeout(() => this.shepherdService.back(), 200);
                        });
                    },
                },
                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: () => {
                        this.router
                            .navigate(['/tabs/property/info/' + this.propertyUID], {
                                queryParams: {
                                    tab: 'charge',
                                },
                            })
                            .then(() => {
                                setTimeout(() => this.shepherdService.next());
                            });
                    },
                },
            ],
        };
    }

    /**
     * @description Get the third step of the tutorial (in treasury-property)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @private
     * @returns {Step.StepOptions}
     * @memberof PropertyTutorialService
     * @example
     * this.shepherdService.addSteps([this.getAddTreasuryStep()]);
     */
    private getAddTreasuryStep(): Step.StepOptions {
        return {
            id: 'addTreasuryTuto',
            attachTo: {
                element: '#addTreasuryTuto',
                on: 'top',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, 34] } }],
            // },
            modalOverlayOpeningPadding: 10,
            modalOverlayOpeningRadius: 10,
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Commencez à paramétrer votre budget sur ce bien. Pas de panique, nous vous accompagnons ! <br>' +
                '<div class="italicText">Le processus de paramétrage d\'une charge ou d\'un revenu est identique.</div> </span>',
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: async () => await this.cancelTutorial(true),
                    classes: 'cancelButton',
                },
                {
                    text: '<i class="uil uil-angle-left"></i><span>Précédent</span>',
                    action: () => {
                        this.router
                            .navigate(['/tabs/property/info/' + this.propertyUID], {
                                queryParams: { tab: 'info' },
                            })
                            .then(() => {
                                // this.tutorialFirstStep$ = of(false);
                                setTimeout(() => this.shepherdService.back());
                            });
                    },
                },
                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: () => {
                        this.router
                            .navigate(['/tabs/property/info/' + this.propertyUID + '/charge/form'])
                            .then(async () => {
                                this.tutorialFirstStep$ = of(false);
                                await this.cancelTutorial(false);
                            });
                    },
                },
            ],
        };
    }

    /**
     * @description Cancel the tutorial and update the finishedTutorials property in the database (in treasury-property)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/08/2023
     * @private
     * @param {boolean} [update] Update the finishedTutorials property in the database
     * @memberof PropertyTutorialService
     * @example
     * await this.cancelTutorial(update);
     */
    private async cancelTutorial(update?: boolean): Promise<void> {
        // Cancel the tutorial
        this.shepherdService.cancel();

        // Update the finishedTutorials property in the database
        if (update) {
            await this.userService.update({
                finishedTutorials: {
                    ...this.user?.finishedTutorials,
                    property: true,
                },
                uid: this.userUID,
            });
        }

        this.subscription?.unsubscribe();
    }
}

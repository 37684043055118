<div class="title-container">
  @if (icon) {
    <div class="label"
      >
      <i class="uil uil-{{ icon }}"></i>
    </div>
  }

  <h3>{{ title }}</h3>
</div>

<div class="actions-container">
  <ng-content select="[section-action]"></ng-content>
</div>

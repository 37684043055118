@if(proLogo) {
<div class="container-logo">
    <img class="pro-avatar" [src]="proLogo" alt="profesionnel logo" />
</div>
} @if (!isOmedomIcon && !proLogo) {
<i class="uil uil-{{ icon }}"></i>
}
<!-- import svg -->
@if (isOmedomIcon) {
<div class="container-icon">
    <div
        class="icon"
        [style.mask]="iconMask"
        [style.-webkit-mask]="iconMask"
    ></div>
</div>
}
<span>{{ label }}</span>

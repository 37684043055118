<ng-container *ngTemplateOutlet="balance"></ng-container>
<ng-container *ngTemplateOutlet="progress"></ng-container>
<ng-container *ngTemplateOutlet="limit"></ng-container>

<h3 @element class="margin-top-1 {{layout}}">Informations</h3>
<div class="container container-{{layout}}">
  <ng-container *ngTemplateOutlet="openingDate"></ng-container>
  <ng-container *ngTemplateOutlet="interestRate"></ng-container>
  <ng-container *ngTemplateOutlet="nextDeposit"></ng-container>
</div>

<ng-container *ngTemplateOutlet="exitConstraints"></ng-container>

<!-- Balance -->
<ng-template #balance>
  <div class="balance" @element>
    <span class="title">Solde</span>
    <span class="value">{{ saving.balance | omedomNumber }} €</span>
  </div>
</ng-template>

<!-- Opening Date -->
<ng-template #openingDate>
  <div class="info" @element>
    <span class="title">Date de souscription</span>
    @if (saving.openingDate) {
      <span class="value"
        >{{ saving.openingDate | date:'dd/MM/yyyy' }}</span
        >
      } @else {
        <span class="value undefined"> Non renseigné(e) </span>
      }
    </div>
  </ng-template>

  <!-- Interest Rate -->
  <ng-template #interestRate>
    <div class="info priority" @element>
      <span class="title">Taux d'intérêt</span>
      <span class="value">
        {{ saving.interestRate || 0 | omedomNumber }} %
        @if (saving.interestAmount) {
          <span
            [ngClass]="{ 'green': saving.interestAmount > 0, 'orange': saving.interestAmount < 0 }"
            >
            ({{ saving.interestAmount > 0 ? '+' : '-' }} {{
            saving.interestAmount | omedomNumber }} €)
          </span>
        }
      </span>
    </div>
  </ng-template>

  <!-- Deposit vs Withdraw -->
  <ng-template #progress>
    <div class="progress" @element>
      <div class="content">
        <span class="title">Montant déposé</span>
        <span class="title">Montant retiré</span>
      </div>

      <div class="progress-bar">
        <div
          class="progress-value green no-radius"
          [style.width]="deposit + '%'"
        ></div>
        <div
          class="progress-value orange no-radius"
          [style.width]="withdraw + '%'"
        ></div>
      </div>

      <div class="content">
        <span class="value"
          >{{ saving.depositAmount || 0 | omedomNumber }} €</span
          >
          <span class="value"
            >{{ saving.withdrawAmount || 0 | omedomNumber }} €</span
            >
          </div>
        </div>
      </ng-template>

      <!-- Exit constraints -->
      <ng-template #exitConstraints>
        <h3 @element class="margin-top-1 {{layout}}">Contraintes de sortie</h3>
        <div class="container container-{{layout}}">
          <div class="info" @element>
            <span class="title">Date de sortie</span>
            @if (saving.exitConstraint?.minimumDate) {
              <span
                class="value"
                >
                {{ saving.exitConstraint?.minimumDate | date:'dd/MM/yyyy' }}
              </span>
            } @else {
              <span class="value undefined"> Non renseigné(e) </span>
            }
          </div>

          <div class="info" @element>
            <span class="title">Autre(s) contrainte(s)</span>
            @if (saving.exitConstraint?.otherConstraint) {
              <span
                class="value"
                >
                {{ saving.exitConstraint?.otherConstraint }}
              </span>
            } @else {
              <span class="value undefined"> Non renseigné(e) </span>
            }
          </div>
        </div>
      </ng-template>

      <!-- Limit -->
      <ng-template #limit>
        <div class="progress" @element>
          <div class="content">
            <span class="title">Plafond</span>
          </div>

          <div class="progress-bar">
            <div
              class="progress-value"
              [ngClass]="{ 'green': filledLimit < 70, 'yellow': filledLimit < 90, 'orange': filledLimit >= 90}"
              [style.width]="filledLimit + '%'"
            ></div>
          </div>

          <div class="content">
            <div class="limit">
              @if (saving.limit) {
                <span class="value">
                  {{ saving.limit | omedomNumber }} €
                </span>
              } @else {
                <span class="value undefined"> Non renseigné(e) </span>
              }
            </div>
          </div>
        </div>
      </ng-template>

      <!-- Next deposit -->
      <ng-template #nextDeposit>
        <div class="info" @element>
          <span class="title">Prochain dépôt</span>
          <div class="content">
            @if (saving.nextDepositAmount && saving.nextDepositDate) {
              <span class="value" id="next-payment-amount"
                >{{ saving.nextDepositAmount | omedomNumber }} €</span
                >
                <span>le</span>
                <span class="value" id="next-payment-date"
                  >{{ saving.nextDepositDate | date:'dd/MM' }}</span
                  >
                } @else {
                  <span class="value undefined"> Non renseigné(e) </span>
                }
              </div>
            </div>
          </ng-template>

          <!-- Unknown -->

@if (level) {
  <div
    class="dpe-label"
    [ngClass]="'level-' + level.letter"
    [ngStyle]="{ 'background-color': level.color }"
    >
    <div class="dpe-letter">
      {{ level.letter }}
    </div>
  </div>
}

@if (user) {
  @if (nextLevel !== level) {
    <button
      @left
      class="omedom-button-secondary level-actual"
      >
      {{ level }}
    </button>
  }
  @if (nextLevel === level) {
    <button
      @up
      class="omedom-button-secondary level-actual level-progress-container"
      >
      {{ level }}
    </button>
  }
  @if (nextLevel !== level) {
    <div class="level-progress-container" @up>
      <progress [value]="progress" max="100"></progress>
      <span class="level-progress-stat"
        >{{ (this.user.dom ?? 0) - levelValue ?? 0 }} /
        {{ nextLevelValue - levelValue }}</span
        >
      </div>
    }
    @if (nextLevel !== level) {
      <button
        @right
        class="omedom-button-secondary level-next"
        >
        {{ nextLevel }}
      </button>
    }
  }

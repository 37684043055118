@if (!leaseUID) {
  <omedom-stepper
    [steps]="steps"
    [(selectedStep)]="selectedStep"
    (onSave)="save()"
    #stepperComponent
    >
    <form class="flex-1">
      @switch (selectedStep.id) {
        @case (leaseFormStep.lease) {
          <omedom-lease-form-lease
            [lease]="lease"
          ></omedom-lease-form-lease>
        }
        @case (leaseFormStep.rent) {
          <omedom-lease-form-rent
            [lease]="lease"
            [property]="property"
            (addRentClick)="addRent.emit($event)"
          ></omedom-lease-form-rent>
        }
        @case (leaseFormStep.tenant) {
          <omedom-lease-form-tenant
            [lease]="lease"
            [tenants]="tenants"
            [roleState]="roleState"
            [canAddAid]="canAddAid"
          ></omedom-lease-form-tenant>
        }
      }
    </form>
  </omedom-stepper>
} @else {
  <omedom-sub-tab [subTabs]="subTabs" [(selectedSubTab)]="selectedSubTab">
  </omedom-sub-tab>
  <form class="flex-1">
    @switch (selectedSubTab?.id) {
      @case (leaseFormStep.lease) {
        <omedom-lease-form-lease
          [lease]="lease"
        ></omedom-lease-form-lease>
      }
      @case (leaseFormStep.rent) {
        <omedom-lease-form-rent
          [lease]="lease"
          [property]="property"
        ></omedom-lease-form-rent>
      }
      @case (leaseFormStep.tenant) {
        <omedom-lease-form-tenant
          [lease]="lease"
          [tenants]="tenants"
          [roleState]="roleState"
          [canAddAid]="canAddAid"
        ></omedom-lease-form-tenant>
      }
    }
  </form>
  <div class="form-stepper-button-container">
    <button
      class="flex-1 omedom-button omedom-button-secondary"
            (click)="
                exitClicked('Edition d\'une location', 'uil uil-home', null)
            "
      >
      Annuler
    </button>
    <button
      class="omedom-button-green flex-1"
      [disabled]="!isLeaseValid() || !isRentValid() || (pending$ | async)"
      (click)="save()"
      >
      <i class="uil uil-save"></i>
      Enregistrer
      @if (pending$ | async) {
        <ion-spinner></ion-spinner>
      }
    </button>
  </div>
}


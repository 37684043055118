import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import {
    AllChargeCategories,
    AllIncomeCategories,
    ChargeCategoryInfo,
    ChargeCategoryProperty,
    ChargeEntity,
    IncomeCategoryInfo,
    IncomeCategoryProperty,
    IncomeEntity,
    IncomePeriodicity,
    IncomeUpdateType,
    SelectOption,
} from '@omedom/data';
import {
    ChargeService,
    IncomeService,
    PropertyService,
    SocietyService,
    UserService,
} from '@omedom/services';

import { TreasuryEdit } from '../../class';
import { OmedomNumberPipe } from '../../pipes';

@Component({
    selector: 'omedom-treasury-edit',
    templateUrl: './treasury-edit.component.html',
    styleUrls: ['./treasury-edit.component.scss'],
    providers: [DatePipe],
})
export class TreasuryEditComponent extends TreasuryEdit<
    ChargeEntity | IncomeEntity,
    AllChargeCategories | AllIncomeCategories
> {
    treasuryUpdateType = IncomeUpdateType;

    treasuryPeriodicity = IncomePeriodicity;

    @Input()
    isCharge = false;

    constructor(
        userService: UserService,
        propertyService: PropertyService,
        societyService: SocietyService,
        numberPipe: OmedomNumberPipe,
        datePipe: DatePipe,
        activatedRoute: ActivatedRoute,
        private navController: NavController,
        private toastController: ToastController,
        private modalController: ModalController,
        private incomeService: IncomeService,
        private chargeService: ChargeService
    ) {
        super(userService, propertyService, societyService, numberPipe, datePipe, activatedRoute);
    }

    async updateEntity(treasury: Partial<IncomeEntity | ChargeEntity>): Promise<void> {
        if (this.startDate && this.endDate && new Date(this.startDate) > new Date(this.endDate)) {
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 5000,
                message:
                    'Veuillez renseigner une date de début de contrat antérieure à la date de fin',
            });

            await toast.present();
            return;
        }
        try {
            const updatedTreasury = !this.isCharge
                ? await this.incomeService.update(treasury as IncomeEntity)
                : await this.chargeService.update(treasury as ChargeEntity);
            this.modalController.dismiss();
            const toast = await this.toastController.create({
                position: 'top',
                color: 'primary',
                duration: 4000,
                message: 'Vous avez mise à jour un revenu.',
            });

            await toast.present();
        } catch (error) {
            console.error(error);
            const toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
        }
    }

    getTreasury(treasuryUid: string): Promise<IncomeEntity | ChargeEntity> {
        return (
            this.isCharge
                ? this.chargeService.get(treasuryUid)
                : this.incomeService.get(treasuryUid)
        ) as Promise<IncomeEntity | ChargeEntity>;
    }

    buildPeriodicityOptions(treasury: IncomeEntity): SelectOption[] {
        return treasury.periodicity === IncomePeriodicity.punctual
            ? [
                  {
                      id: IncomePeriodicity.punctual,
                      label: 'Ponctuelle',
                  } as SelectOption,
              ]
            : [
                  {
                      id: IncomePeriodicity.monthly,
                      label: 'Mensuelle',
                  } as SelectOption,
                  {
                      id: IncomePeriodicity.bimonthly,
                      label: 'Bimestrielle',
                  } as SelectOption,
                  {
                      id: IncomePeriodicity.quarterly,
                      label: 'Trimestrielle',
                  } as SelectOption,
                  {
                      id: IncomePeriodicity.halfYearly,
                      label: 'Semestrielle',
                  } as SelectOption,
                  {
                      id: IncomePeriodicity.yearly,
                      label: 'Annuelle',
                  } as SelectOption,
              ];
    }

    getCategoryInfo(category: string): IncomeCategoryInfo | ChargeCategoryInfo {
        return this.isCharge
            ? new ChargeCategoryInfo(category as ChargeCategoryProperty)
            : new IncomeCategoryInfo(category as IncomeCategoryProperty);
    }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { MultiFactorAuthentification } from '@omedom/data';
import { UserService } from '@omedom/services';
import { lastValueFrom } from 'rxjs';

interface VerificationForm {
    digit1: FormControl<number | null>;
    digit2: FormControl<number | null>;
    digit3: FormControl<number | null>;
    digit4: FormControl<number | null>;
    digit5: FormControl<number | null>;
    digit6: FormControl<number | null>;
}

/**
 * @description User data interface (email and password) to login after verification
 * @author Brisset Killian
 * @date 17/04/2024
 * @interface UserData
 */
interface UserData {
    /**
     * @description
     * @author Brisset Killian
     * @date 17/04/2024
     * @type {string}
     * @memberof UserData
     */
    email: string;

    /**
     * @description Password of the user to login after verification code validation
     * @author Brisset Killian
     * @date 17/04/2024
     * @type {string}
     * @memberof UserData
     */
    password: string;
}

@Component({
    selector: 'omedom-two-factor-validation',
    templateUrl: './two-factor-validation.component.html',
    styleUrls: ['./two-factor-validation.component.scss'],
})
export class TwoFactorValidationComponent implements OnInit {
    /**
     * @description Multi factor details
     * @author Brisset Killian
     * @date 16/04/2024
     * @type {MultiFactorAuthentification}
     * @memberof TwoFactorValidationComponent
     */
    @Input() multiFactorDetails: MultiFactorAuthentification = {} as MultiFactorAuthentification;

    /**
     * @description User data (email and password) to login after verification
     * @author Brisset Killian
     * @date 17/04/2024
     * @type {UserData}
     * @memberof TwoFactorValidationComponent
     */
    @Input() userData: UserData = {} as UserData;

    /**
     * @description Verification form
     * @type {FormGroup<VerificationForm>}
     * @memberof TwoFactorValidationComponent
     */
    public verificationForm: FormGroup<VerificationForm> = new FormGroup<VerificationForm>({
        digit1: new FormControl<number | null>(null, {
            nonNullable: false,
            validators: [Validators.required],
        }),
        digit2: new FormControl<number | null>(null, {
            nonNullable: false,
            validators: [Validators.required],
        }),
        digit3: new FormControl<number | null>(null, {
            nonNullable: false,
            validators: [Validators.required],
        }),
        digit4: new FormControl<number | null>(null, {
            nonNullable: false,
            validators: [Validators.required],
        }),
        digit5: new FormControl<number | null>(null, {
            nonNullable: false,
            validators: [Validators.required],
        }),
        digit6: new FormControl<number | null>(null, {
            nonNullable: false,
            validators: [Validators.required],
        }),
    });

    /**
     * @description Selected method (email or phone) for verification
     * @author Brisset Killian
     * @date 17/04/2024
     * @type {('email' | 'phone' | null)}
     * @memberof TwoFactorValidationComponent
     */
    public selectedMethod: 'email' | 'phone' | null = null;

    /**
     * @description Verification loading state
     * @author Brisset Killian
     * @date 17/04/2024
     * @memberof TwoFactorValidationComponent
     */
    public verificationLoading = false;

    /**
     * @description Time before resend verification code (in seconds)
     * @author Brisset Killian
     * @date 17/04/2024
     * @memberof TwoFactorValidationComponent
     */
    public waitBeforeResend = 60;

    public lastCodeSent = '';

    constructor(
        private modalController: ModalController,
        private functions: AngularFireFunctions,
        private userService: UserService,
        private router: Router,
        private toast: ToastController
    ) {}

    /**
     * @description Email verification state
     * @author Brisset Killian
     * @date 17/04/2024
     * @readonly
     * @memberof TwoFactorValidationComponent
     */
    public get emailVerification() {
        return (
            this.multiFactorDetails.email &&
            this.multiFactorDetails.email.enabled &&
            this.multiFactorDetails.email.value
        );
    }

    /**
     * @description Phone verification state
     * @author Brisset Killian
     * @date 17/04/2024
     * @readonly
     * @memberof TwoFactorValidationComponent
     */
    public get phoneVerification() {
        return (
            this.multiFactorDetails.phone &&
            this.multiFactorDetails.phone.enabled &&
            this.multiFactorDetails.phone.value
        );
    }

    ngOnInit() {
        this.selectMethod('email');
    }

    /**
     * @description Close the modal component (two factor validation)
     * @author Brisset Killian
     * @date 17/04/2024
     * @memberof TwoFactorValidationComponent
     */
    public async close() {
        await this.modalController.dismiss();
    }

    /**
     * @description Select the verification method (email or phone) for the two factor validation process
     * @author Brisset Killian
     * @date 17/04/2024
     * @param {('email' | 'phone')} method
     * @memberof TwoFactorValidationComponent
     */
    public async selectMethod(method: 'email' | 'phone') {
        this.waitBeforeResend = 60;
        const interval = setInterval(() => {
            if (this.waitBeforeResend === 0) {
                clearInterval(interval);
            }
            this.waitBeforeResend--;
        }, 1000);

        this.selectedMethod = method;

        // send verification code (sendEmailCode function)
        if (method === 'email') {
            const sendEmailCode = this.functions.httpsCallable('sendEmailCode');
            try {
                await lastValueFrom(sendEmailCode({ userUID: this.multiFactorDetails.userUID }));
            } catch (error: any) {
                this.toast.create({
                    message: `Une erreur s'est produite, veuillez réessayer de renvoyer le code.`,
                    color: 'danger',
                    position: 'top',
                    duration: 4000,
                });
                this.waitBeforeResend = 0;
            }
        }
    }

    /**
     * @description Handle key up event on input to fill the next input with the same value (verification code) in the form
     * @author Brisset Killian
     * @date 17/04/2024
     * @param {KeyboardEvent} event
     * @param {number} digit
     * @memberof TwoFactorValidationComponent
     */
    public onKeyUp(event: KeyboardEvent, digit: number) {
        const targetInput = event.target as HTMLInputElement;
        const value = targetInput.value.replace(/\D/g, '');

        let digitToFocus = digit + 1;
        if (value.length > 1) {
            const min = Math.min(value.length, 6);
            digitToFocus = min;
            for (let i = digit - 1; i < min; i++) {
                this.verificationForm.get('digit' + (i + 1).toString())?.setValue(value[i]);
            }
        } else {
            this.verificationForm.get('digit' + digit.toString())?.setValue(value);
        }

        // focus the digitToFocus input with the attr formcontrolname="digit4"
        if (value.length > 0 && digit < 6) {
            const omedomInput = document.querySelector(
                `omedom-input[formcontrolname="digit${digitToFocus}"]`
            );
            // route omedomInput -> ion-item -> ion-input -> input
            const input = omedomInput
                ?.querySelector('ion-item')
                ?.querySelector('ion-input')
                ?.querySelector('input');
            if (input) {
                input.focus();
            }
        }

        const newCode = Object.keys(this.verificationForm.value)
            .map((key) => this.verificationForm.get(key)?.value)
            .join('');

        if (this.verificationForm.valid && newCode.length === 6 && newCode !== this.lastCodeSent) {
            this.verify();
        }
    }

    /**
     * @description Verify the verification code entered by the user to validate the two factor authentication process and login the user if the code is correct
     * @author Brisset Killian
     * @date 17/04/2024
     * @memberof TwoFactorValidationComponent
     */
    public async verify() {
        if (this.verificationLoading) {
            return;
        }
        const verificationCode = Object.keys(this.verificationForm.value)
            .map((key) => this.verificationForm.get(key)?.value)
            .join('');

        this.lastCodeSent = verificationCode;

        const validateEmailCode = this.functions.httpsCallable('validateEmailCode');
        this.verificationLoading = true;
        try {
            const result = await lastValueFrom(
                validateEmailCode({
                    userUID: this.multiFactorDetails.userUID,
                    code: verificationCode,
                })
            );
            if (result.success) {
                await this.userService.loginEmailAndPassword(
                    this.userData.email,
                    this.userData.password
                );
                const controller = await this.toast.create({
                    position: 'top',
                    color: 'primary',
                    duration: 3000,
                    message: `Vous êtes à présent connecté !`,
                });

                await controller.present();

                this.router.navigate(['/']);
            }
            await this.modalController.dismiss();
            this.verificationLoading = false;
        } catch (error: any) {
            const code = error?.code;

            switch (code) {
                case 'functions/invalid-argument':
                    const invalidCtrl = await this.toast.create({
                        position: 'top',
                        color: 'danger',
                        duration: 4000,
                        message: `Code invalide.`,
                    });

                    await invalidCtrl.present();
                    break;
                default:
                    const errorCtrl = await this.toast.create({
                        position: 'top',
                        color: 'danger',
                        duration: 4000,
                        message: `Une erreur s'est produite, veuillez réessayer plus tard.`,
                    });

                    await errorCtrl.present();
                    break;
            }

            this.verificationLoading = false;
        }
    }
}

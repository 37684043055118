import { BankStatusCode } from '../../enums';
import { BankStatusReport, Bridge } from '../../interfaces';
import { RestEntity } from '../rest.entity';

/**
 * @description The link connecting the bank to the user's account in Bridge
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 01/03/2024
 * @export
 * @class BankItemEntity
 * @extends {RestEntity}
 */
export class BankItemEntity extends RestEntity implements Bridge, BankStatusReport {
    /**
     * @description The ID of the item in Bridge
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {number}
     * @memberof BankItemEntity
     */
    public bridgeID!: number;

    /**
     * @description UID of the user in OMEDOM's database
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {string}
     * @memberof BankItemEntity
     */
    public userUID!: string;

    /**
     * @description The ID of the bank in Bridge, the bank the item is linked to
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {number}
     * @memberof BankItemEntity
     */
    public bankID!: number;

    /**
     * @description Status of the item in Bridge, if there is an error, the status code will be different from 0 (BankStatusCode.OK)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {BankStatusCode}
     * @memberof BankItemEntity
     */
    public status!: BankStatusCode;

    /**
     * @description More informations on the status if there is an error, like the error message from the bank's API
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {string}
     * @memberof BankItemEntity
     */
    public statusCodeInfo?: string;

    /**
     * @description Description of the status code in human language if there is an error, like "The credentials were recently updated, and the item's refresh is in progress"
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @type {string}
     * @memberof BankItemEntity
     */
    public statusCodeDescription?: string;

    /**
     * @description Date the item will expire if the user owner don't renew his wubscription
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 28/05/2024
     * @type {Date}
     * @memberof BankItemEntity
     */
    public expirationDate?: Date;

    /**
     * @description If true, the bank item is shared to the pro of the user
     * @author Killian Brisset
     * @date 13/08/2024
     * @type {boolean}
     * @memberof BankItemEntity
     */
    public sharedToPro?: boolean;

    constructor(data: Partial<BankItemEntity>) {
        super(data);

        // Check if status is not defined
        if (!this.status) {
            this.status = BankStatusCode.OK;
        }

        // Check if expiration date is Date
        if (this.expirationDate && !(this.expirationDate instanceof Date)) {
            if ('seconds' in this.expirationDate) {
                this.expirationDate = new Date(this.expirationDate['seconds'] * 1000);
            }
        }
    }

    /**
     * @description Return true if the item es enabled
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/03/2024
     * @returns {boolean}
     * @memberof BankItemEntity
     */
    public isRefreshEnabled(): boolean {
        return this.status === BankStatusCode.OK;
    }
}

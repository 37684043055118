import { animate, style, transition, trigger } from '@angular/animations';

/**
 * @description Animation for each element
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 29/04/2024
 * @export
 */
export const elementAnimation = trigger('element', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(15px)' }),
        animate('0.25s', style({ opacity: 1, transform: 'translateY(0px)' })),
    ]),
]);

import { OmedomNumberPipe } from '../pipes/number.pipe';

/**
 * @description Number format class for Omedom applications
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 09/08/2023
 * @export
 * @class OmedomFormatNumber
 */
export class OmedomFormatNumber {
    /**
     * @description Format a number in an input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @static
     * @param {string} value The value to format
     * @param {OmedomNumberPipe} numberPipe The number pipe
     * @param {string} inputId The input id
     * @returns {*}  {void}
     * @memberof OmedomFormatNumber
     * @example
     * OmedomFormatNumber.format('123456789', new OmedomNumberPipe(), 'my-input-id');
     * // => '123 456 789'
     */
    public static format(
        value: string,
        numberPipe: OmedomNumberPipe,
        inputId: string
    ): void {
        // If the value is not a number, do not format it
        if (isNaN(+numberPipe.parse(value))) {
            return;
        }

        // Format the value
        const formattedText = numberPipe.transform(value);

        // Get the input element
        const ionInput = document.getElementById(
            inputId
        ) as any;

        ionInput.getInputElement().then((input: HTMLInputElement) => {
            // Get the current selection
            const [oldSelectionStart, oldSelectionEnd] = [
                input.selectionStart,
                input.selectionEnd,
            ];

            // Calculate the selection step
            const selectionStep = formattedText.length - input.value.length;

            // Set the formatted value
            input.value = formattedText;

            // Set the selection
            input.setSelectionRange(
                oldSelectionStart ?? 0 + selectionStep,
                oldSelectionEnd ?? 0 + selectionStep
            );
        });
    }

    /**
     * @description Format a number in an input (without ion-input)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @static
     * @param {string} value The value to format
     * @param {OmedomNumberPipe} numberPipe The number pipe
     * @param {string} inputId The input id
     * @returns {*}  {void}
     * @memberof OmedomFormatNumber
     * @example
     * OmedomFormatNumber.formatOmedomInput('123456789', new OmedomNumberPipe(), 'my-input-id');
     * // => '123 456 789'
     */
    public static formatOmedomInput(
        value: string,
        numberPipe: OmedomNumberPipe,
        inputId: string
    ): void {

        // Get the input element
        const omedomInput = document.getElementById(inputId) as HTMLElement;

        // Get the input element
        const input = omedomInput.getElementsByTagName(
            'input'
        )[0] as HTMLInputElement;

        // If the value is not a number, do not format it
        if (isNaN(+numberPipe.parse(value))) {
            // Remove all non-numeric characters
            input.value = value.replace(/\D/g, '');
            // Set the value to the input
            value = input.value;
            // Dispatch the input event for Angular to detect the change
            input.dispatchEvent(new Event('input'));
        }


        // Format the value
        const formattedText = numberPipe.transform(value);



        // Get the current selection
        const [oldSelectionStart, oldSelectionEnd] = [
            input.selectionStart,
            input.selectionEnd,
        ];

        // Calculate the selection step
        const selectionStep = formattedText.length - input.value.length;

        // Set the formatted value
        input.value = formattedText;

        // Set the selection
        input.setSelectionRange(
            oldSelectionStart ?? 0 + selectionStep,
            oldSelectionEnd ?? 0 + selectionStep
        );
    }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { LogEntity } from '@omedom/data';

import { RestService } from './rest.service';

@Injectable({ providedIn: 'root' })
export class LogService extends RestService<LogEntity> {
    constructor(protected override firestore: AngularFirestore) {
        super(firestore, 'logs');
    }
}

<div class="review-container padding-2 margin-bottom-2 margin-x-auto">
    <div>
        <div class="capitalize margin-bottom-1 inline-block margin-right-1">
            @if(review.isAnonymous) { Anonyme } @else {
            {{ review.name }} {{ review.firstname }} }
        </div>

        <div class="inline-block">
            <span class="margin-right-2"
                >le {{ adaptDate(review.created) }}</span
            >
            <span class="rate margin-right-1">{{ review.rate }}</span>
            <omedom-star-rate
                class="margin-bottom-2"
                [rate]="review.rate"
                [readOnly]="true"
            ></omedom-star-rate>
            <!-- @if(!review?.isAnonymous) { -->
        </div>
    </div>

    <div class="comment-text">{{ review.comment }}</div>
</div>

export class OmedomRandom {
    /**
     * @description Function equivalent to Math.random but this one is cryptographic security.
     * @author ANDRE Felix
     * @static
     * @memberof OmedomRandom
     */
    public static getRandomNumber() {
        const crypto = window.crypto;
        const array = new Uint32Array(1);
        crypto.getRandomValues(array); // get secure random number between 0 and 4294967295

        // Get number between 0 and 1, like Math.random
        const randomValue = array[0] / (0xffffffff + 1);
        return randomValue;
    }
}

<div class="avatar-container">
    <div class="avatar-display">
        @if (avatar) {
        <img [src]="avatar" class="avatar" alt="avatar" />
        } @else {
        <div class="avatar">
            <i class="uil" [class]="defaultIcon"></i>
        </div>
        }
    </div>
</div>

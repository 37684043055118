import {
    Component,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { AutocompleteOption } from '@omedom/data';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'omedom-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AutocompleteComponent),
            multi: true,
        },
    ],
    host: {
        tabindex: '0',
    },
})
export class AutocompleteComponent<T> implements ControlValueAccessor, Validator {
    /**
     * @description Placeholder of the input (displayed when input is empty and not focused)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {string}
     * @memberof AutocompleteComponent
     */
    @Input()
    public placeholder?: string;

    /**
     * @description Label of the input (displayed when input is not empty or focused)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {string}
     * @memberof AutocompleteComponent
     */
    @Input()
    public label?: string;

    /**
     * @description Name of the input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {string}
     * @memberof AutocompleteComponent
     */
    @Input()
    public name: string = '';

    /**
     * @description Icon of the input (displayed on the left of the input)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {string}
     * @memberof AutocompleteComponent
     */
    @Input()
    public icon?: string;

    /**
     * @description Icon of the input (displayed on the right of the input)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {string}
     * @memberof AutocompleteComponent
     */
    @Input()
    public iconEnd?: string;

    /**
     * @description Type of the input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @memberof AutocompleteComponent
     */
    public type = 'text';

    /**
     * @description True if the autocomplete is disabled
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {boolean}
     * @memberof AutocompleteComponent
     */
    @Input()
    public required: boolean = false;

    /**
     * @description Debounce time for each input change (in ms, 0 to disable debounce time)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @memberof AutocompleteComponent
     */
    @Input()
    public debounce = 0;

    /**
     * @description Extra class to add to the input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {string}
     * @memberof AutocompleteComponent
     */
    @Input()
    public class?: string;

    /**
     * @description Autocomplete options for the input (if not set, the input is a classic input without autocomplete feature)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {AutocompleteOption<T>[]}
     * @memberof AutocompleteComponent
     */
    @Input()
    public autocomplete?: AutocompleteOption<T>[];

    /**
     * @description Error message to display
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {string}
     * @memberof AutocompleteComponent
     */
    @Input()
    public error?: string;

    /**
     * @description Warning message to display
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {string}
     * @memberof AutocompleteComponent
     */
    @Input()
    public warning?: string;

    /**
     * @description True if the label is bold
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {boolean}
     * @memberof AutocompleteComponent
     */
    @Input()
    public boldLabel?: boolean;

    /**
     * @description Transform data for parent component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @memberof AutocompleteComponent
     */
    @Input()
    public transformValue?: (value: T) => any;

    /**
     * @description True if the input is clearable
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {boolean}
     * @memberof AutocompleteComponent
     */
    @Input()
    public clearable?: boolean = false;

    /**
     * @description Value to display in the input after selection
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {string}
     * @memberof AutocompleteComponent
     */
    public displayedValue: string = '';

    /**
     * @description Value of the input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {*}
     * @memberof AutocompleteComponent
     */
    public value?: T;

    /**
     * @description Disable state (work only with template driven form)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @type {boolean}
     * @memberof AutocompleteComponent
     */
    public isDisabled: boolean = false;

    /**
     * @description Event when user click on icon end of input
     * @author Jérémie Lopez
     * @memberof InputComponent
     */
    @Output()
    public iconEndClick = new EventEmitter<void>();

    /**
     * @description On change callback
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @private
     * @type {Function}
     * @memberof AutocompleteComponent
     */
    private onChangeCallback: Function = () => { };

    /**
     * @description Is focused
     * @author Jérémie Lopez
     * @memberof InputComponent
     */
    public isFocused = new BehaviorSubject<boolean>(false);

    /**
     * @description Filtered autocomplete options
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @memberof AutocompleteComponent
     */
    public filteredAutocomplete$ = new BehaviorSubject<AutocompleteOption<T>[]>([]);

    constructor() {
        this.filteredAutocomplete$.next(this.autocomplete ?? []);
    }

    /**
     * @description On focus event (set isFocused to true and emit focus event to parent component if needed)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @memberof AutocompleteComponent
     */
    @HostListener('blur', ['$event'])
    public onBlur() {
        this.isFocused.next(false);
    }

    /**
     * @description Validate data before send to parent component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @returns {*}  {null}
     * @memberof AutocompleteComponent
     */
    public validate(): null {
        return null;
    }

    /**
     * @description
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @param {Function} fn
     * @memberof AutocompleteComponent
     */
    public registerOnChange(fn: Function): void {
        this.onChangeCallback = fn;
    }

    /**
     * @description Register on touched callback (not used here, but required by ControlValueAccessor interface)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @memberof AutocompleteComponent
     */
    public registerOnTouched(): void { }

    /**
     * @description Apply new value to the input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @param {*} newValue
     * @memberof AutocompleteComponent
     */
    public writeValue(newValue: T | undefined): void {
        if (newValue !== this.value) {
            this.value = newValue;

            // Set displayed value
            if (this.autocomplete) {
                const item = this.autocomplete.find(
                    (val) =>
                        (this.transformValue ? this.transformValue(val.value) : val.value) ===
                        newValue
                );

                if (item) {
                    this.displayedValue = item.label;
                } else {
                    this.displayedValue = '';
                }
            } else {
                this.displayedValue = String(newValue);
            }
        }
    }

    /**
     * @description Set disabled state (work only with template driven form, not reactive form)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @param {boolean} isDisabled
     * @memberof AutocompleteComponent
     */
    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    /**
     * @description Value change event (emit value change to parent component and update form control value if needed)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @param {(T | undefined)} value
     * @memberof AutocompleteComponent
     */
    public valueChange(value: T | undefined): void {
        this.writeValue(value);
        this.onChangeCallback(value);
    }

    /**
     * @description Filter autocomplete options with input value (if input value is empty, display all options, else display only options that contains input value in label or value)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @param {string} value
     * @memberof AutocompleteComponent
     */
    public filterAutocomplete(value: string): void {
        if (this.value) {
            this.valueChange(undefined);
        }

        if (value.length === 0) {
            this.filteredAutocomplete$.next(this.autocomplete ?? []);
        } else {
            this.filteredAutocomplete$.next(
                this.autocomplete?.filter((item) =>
                    item.label.toLowerCase().includes(value.toLowerCase())
                ) ?? []
            );
        }
    }

    /**
     * @description On select item event
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @param {AutocompleteOption<T>} item
     * @memberof AutocompleteComponent
     */
    public selectItem(item: AutocompleteOption<T>): void {
        this.valueChange(this.transformValue ? this.transformValue(item.value) : item.value);
        this.filteredAutocomplete$.next(this.autocomplete ?? []);
        this.isFocused.next(false);
    }

    /**
     * @description Detect blur event
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @memberof AutocompleteComponent
     */
    public detectBlur(): void {
        setTimeout(() => {
            if (this.isFocused.value) {
                this.isFocused.next(false);
            }
        }, 100);
    }

    /**
     * @description Clear selection
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @memberof AutocompleteComponent
     */
    public clear(): void {
        this.valueChange(undefined);
    }

    /**
     * @description Return selected autocomplete label
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/11/2023
     * @readonly
     * @type {string}
     * @memberof AutocompleteComponent
     */
    public get selectedAutocomplete(): string {
        if (this.value) {
            const item = this.autocomplete?.find(
                (item) =>
                    (this.transformValue ? this.transformValue(item.value) : item.value) ===
                    this.value
            );

            if (item) {
                return item.label;
            }
        }

        return '';
    }
}

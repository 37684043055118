import { Action } from '../enums';

/**
 * @description State of a role for an entity by a user
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 10/08/2023
 * @interface RoleState
 */
export interface RoleState {
    [Action.create]?: boolean;
    [Action.read]?: boolean;
    [Action.update]?: boolean;
    [Action.delete]?: boolean;
    [Action.export]?: boolean;
    [Action.import]?: boolean;
    [Action.share]?: boolean;
}

export const defaultRoleState = {
    [Action.create]: false,
    [Action.read]: true,
    [Action.update]: false,
    [Action.delete]: false,
    [Action.export]: true,
    [Action.import]: false,
    [Action.share]: false,
};

export const allRoleState = {
    [Action.create]: true,
    [Action.read]: true,
    [Action.update]: true,
    [Action.delete]: true,
    [Action.export]: true,
    [Action.import]: true,
    [Action.share]: true,
};
